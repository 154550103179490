import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modify-confirmation',
  templateUrl: './modify-confirmation.component.html',
  styleUrls: ['./modify-confirmation.component.scss']
})
export class ModifyConfirmationComponent implements OnInit {
  dialogData: any;

  public _mFormGroup: FormGroup;
  public mMasterRouteName = new FormControl('', Validators.required);

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ModifyConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[]
  ) {
    this.dialogData = data;
    this._mFormGroup = this.fb.group({
      'masterRouteName': this.mMasterRouteName,
    });
  }

  ngOnInit() { }

  discard_special_char_And_String(event) {
    let k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57));
  }

  onCancel() {
    this.dialogRef.close(-1);
  }

  onDeny() {
    this.dialogRef.close(0);
  }

  onAllow() {
    if (this.mMasterRouteName.valid) {
      this.dialogRef.close(this.mMasterRouteName.value);
    }
  }

}
