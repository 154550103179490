import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Atom';
  spinnerType = SPINNER.circle;

  constructor(private ngxService: NgxUiLoaderService,
    private router: Router) {
    // Checking if SSO login request or not
    if (window.location.href.includes('code=')) {
      const hUrl = window.location.href.replace('&', '');
      const urlSplit = hUrl.split('=');

      this.router.navigate(['/sso'], { queryParams: { code: urlSplit[3], session_state: urlSplit[2], clientCode: urlSplit[1].split('#')[0] } });
    }
  }

  ngOnInit() {
    this.ngxService.start();
    setTimeout(() => {
      this.ngxService.stop();
    }, 500);
  }


}
