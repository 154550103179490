import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject } from 'rxjs';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { ApiService } from 'src/app/core/services/api.service';
import { ServiceExportExcel } from 'src/app/core/services/export.service';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ModelSite } from 'src/app/shared/models/site';
import { ModelTableHeader } from 'src/app/shared/models/table-header';

@Component({
  selector: 'app-add-edit-routing-setup',
  templateUrl: './add-edit-routing-setup.component.html',
  styleUrls: ['./add-edit-routing-setup.component.scss']
})
export class AddEditRoutingSetupComponent implements OnInit {

  @Input() userData: any;
  @Output() addEditEvent = new EventEmitter();
  // @Output() login = new EventEmitter<string>();
  public mSite = new FormControl('', Validators.required);
  public mPriorityOptimization = new FormControl('optimization', Validators.required);
  public mTransportSLA = new FormControl('', Validators.required);
  public mAcceptableDiversion = new FormControl('', Validators.required);
  public mAllowTripGeneration = new FormControl(false);
  public mRoutingType = new FormControl('', Validators.required);
  public mAllowProActiveRouting = new FormControl(false);
  public mAllowTripGenerationOutOfBoundry = new FormControl(false);
  public mAllowDriverToMarkNoShow = new FormControl(false);
  public mShift = new FormControl("");
  public mAllowStopTripToVendor = new FormControl(false);
  public mDisablePickUpTimeUpdateByVendor = new FormControl(false);
  shiftDropDownSettings = {};
  public _mFormGroup: FormGroup;
  public routingData: any;
  shiftData: any[] = [];
  shiftMapping: boolean = false;
  public siteData: ModelSite[] = [];
  title: any;
  companyid: number = +this.localStorage.getCompanyId();
  _mColumnsRaw: ModelTableHeader[];
  projectFlag: boolean = true;
  firstStepForProject: boolean;
  mIsEdit: boolean = false;
  public mCity = new FormControl('');
  tempSearchKey: any = "";
  searchSubject$ = new Subject<string>();
  _mPreviousPageNumber: number = 0;
  page: number = 0;
  editData: any;
  isFileUploaded: any = false;
  projectFile: string;
  fileUploadChange: any;
  fileUploadSummary: any = {};
  projectList: any[] = [];
  projectListData: any[] = [];
  searchBarFlag: boolean = false;
  searchEdit: boolean = true;
  auditFlag: boolean = true;
  searchData: any = '';
  private _mCurrentNumber: number;
  public _mColumns: any;
  public _mData: any;
  public _mTotalPage: number;
  public _mSize: number;
  public _mTotalElements: number;
  public _mNumberOfElements: number;
  _mSitesData: any;
  _mRolesData: any;
  userId: any;
  setupFlag: boolean = false;
  monthlyCharges: any;
  roles: any;
  updateSubscriptionId: any;
  isDropdownDisabled: boolean = false;
  isChecked: boolean;
  isWeeklySchedulekChecked: boolean;
  isTripCancelChecked: boolean;
  _mRawData: any[];
  cities: any;
  selectedCity: any;
  sites: any = [];
  _mSearchKey: string = '';
  _mTableOptions = ["Edit"];
  _mIsLoading = true;
  _mIsNoPagination = false;
  toggleStatus = true;
  _mDataRaw: any;
  _mSiteSelected: ModelSite[];
  tableData: any;
  mRoutingTyes: any[] = [{ "value": "ALGORITHM" }, { "value": "FIXED" }];
  cityFlag: boolean = true
  selectedSite: string;
  cityName: any;
  // userData: any;
  // @Input() public userData;

  constructor(private fb: FormBuilder,
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private readonly serviceExportExcel: ServiceExportExcel,
    private readonly localStorage: ServiceLocalStorage,
    public readonly dialog: MatDialog,
    private ngxLoader: NgxUiLoaderService
  ) {

  }

  ngOnInit(): void {
    this._mFormGroup = this.fb.group({
      'site': this.mSite,
      'priorityOptimization': this.mPriorityOptimization,
      'transportSLA': this.mTransportSLA,
      'acceptableDiversion': this.mAcceptableDiversion,
      'allowTripGeneration': this.mAllowTripGeneration,
      'routingType': this.mRoutingType,
      'allowProActiveRouting': this.mAllowProActiveRouting,
      'allowTripGenerationOutOfBoundry': this.mAllowTripGenerationOutOfBoundry,
      'shift': this.mShift,
      'allowStopTripToVendor': this.mAllowStopTripToVendor,
      'disablePickUpTimeUpdateByVendor': this.mDisablePickUpTimeUpdateByVendor,
      'allowDriverToMarkNoShow': this.mAllowDriverToMarkNoShow
    });
    this.shiftDropDownSettings = {
      singleSelection: false,
      idField: "shiftTimeId",
      textField: "shiftTime",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
    // this.addEditEvent.emit(this.userData);

    this.routingData = this.userData;
    if (this.routingData) {
      this.mIsEdit = true;
    }
    else {

      this.mIsEdit = false;
    }
    this.getSites();

    if (this.routingData) {
      if (this.routingData.allowMarkNoShow == null || this.routingData.allowMarkNoShow == undefined) {
        this.routingData.allowMarkNoShow = false;
      }
      if (this.routingData.allowProActiveRouting == null || this.routingData.allowProActiveRouting == undefined) {
        this.routingData.allowProActiveRouting = false;
      }
      if (this.routingData.allowStopTripToVendor == null || this.routingData.allowStopTripToVendor == undefined) {
        this.routingData.allowStopTripToVendor = false;
      }
      if (this.routingData.disablePickUpTimeUpdateByVendor == null || this.routingData.disablePickUpTimeUpdateByVendor == undefined) {
        this.routingData.disablePickUpTimeUpdateByVendor = false;
      }
      if (this.routingData.allowTripGeneration == null || this.routingData.allowTripGeneration == undefined) {
        this.routingData.allowTripGeneration = false;
      }
      if (this.routingData.allowTripGenerationOutOffSiteBoundry == null || this.routingData.allowTripGenerationOutOffSiteBoundry == undefined) {
        this.routingData.allowTripGenerationOutOffSiteBoundry = false;
      }

      this.mSite.setValue(this.routingData.site.siteId);
      this.getAllShifts(this.mSite.value);
      this.mSite.disable();
      // this.getAllShifts(this.mSite.value);
      this.mPriorityOptimization.setValue(this.routingData.priorityOptimization);
      this.mAcceptableDiversion.setValue(this.routingData.acceptableDiversion);
      this.mAllowTripGeneration.setValue(this.routingData.allowTripGeneration);
      this.mTransportSLA.setValue(this.routingData.transportSLA);
      this.mRoutingType.setValue(this.routingData.routingType);
      this.mAllowTripGenerationOutOfBoundry.setValue(this.routingData.allowTripGenerationOutOffSiteBoundry);
      this.mAllowProActiveRouting.setValue(this.routingData.allowProActiveRouting);
      this.mAllowStopTripToVendor.setValue(this.routingData.allowStopTripToVendor);
      this.mDisablePickUpTimeUpdateByVendor.setValue(this.routingData.disablePickUpTimeUpdateByVendor);
      this.mAllowDriverToMarkNoShow.setValue(this.routingData.allowMarkNoShow);
    }

    this.userId = this.localStorage.getUserId();

  }

  getSites() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getActiveSitesbyUser, (success) => {
      this.siteData = success.body;
      // if (this.mIsEdit) {
      //   this.siteData.forEach(site => {
      //     if (site.siteId == this.routingData.site.siteId) {
      //       if (site.vehicleTypes.length == 0) {
      //         this.notifyService.showInfo("No vehicle types found for selected site", null);
      //       }
      //     }
      //   })
      // }
    }, error => {

    }
    )
  }

  onSiteChange($event) {
    // this._mSiteSelected = this.siteData.filter(
    //   site => site.siteId == $event.value
    // );
    this.getAllShifts(this.mSite.value);
  }

  getAllShifts(siteId) {
    if (siteId == null) {
      return;
    }
    this.apiService.doGetRequestWithResponse(ApiConstants.getShiftTimesListBySiteId + '?siteId=' + siteId + '&shiftType=' + 'General', success => {
      if (success.status == 200) {
        this.shiftData = success.body;
        if (this.mIsEdit) {

          this.apiService.doGetRequestWithResponse(ApiConstants.getRoutingConfigShiftsByRoutingId + '?routingId=' + this.routingData.id, success => {
            if (success.status == 200) {
              this.mShift.setValue(success.body);
            } else {
              this.mShift.setValue('');
            }
            this.shiftMapping = true;
          },
            error => {
              this.shiftMapping = true;
              this.notifyService.showError("Error in getting shifts", null);
            }
          );
          // this.mShift.setValue(this.routingData.shifts);
        } else {
          this.shiftMapping = true;
        }
      } else {
        this.mShift.setValue('');
        this.shiftData = [];
        this.shiftMapping = false;
        this.notifyService.showError("No shifts found for the selected site", null);
      }
    },
      error => {
        this.notifyService.showError("Error in getting shifts", null);
      }
    );
  }

  allowTripGenerationToggle() {

    if (this.mAllowTripGeneration.value) {
      if (this.mAllowProActiveRouting.value) {
        this.mAllowProActiveRouting.setValue(false);
        this.notifyService.showWarning("WARNING! Allow Pro active Routing flag is disabled", null);
      }
    } else {
      if (this.mAllowProActiveRouting.value) {
        this.mAllowProActiveRouting.setValue(false);
        this.notifyService.showWarning("WARNING! Allow Pro active Routing flag is disabled", null);
      }
    }
  }

  allowProActiveRoutingToggle() {
    if (!this.mAllowProActiveRouting.value) {
      if (this.mAllowTripGeneration.value) {
        this.mAllowTripGeneration.setValue(false);
        this.notifyService.showWarning("WARNING! Allow trip generation before CutOff flag is disabled", null);
      }
    }
  }

  onItemSelect(event) {
  }

  onSelectAll(event) {

  }

  discard_special_char_And_String1(event) {
    var k;
    k = event.charCode; //         k = event.keyCode;  (Both can be used)
    return k >= 48 && k <= 57;
  }

  getRoles() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getAllRole, (success) => {
      this.roles = success.body;
    },
      (error) => {
      });
  }


  onBackOfProject() {
    this.title = 'Add';
    this.ngxLoader.start();
    this.addEditEvent.emit('');
    // this.firstStepForProject = this.firstStepForProject == true ? false : true;
    // this.projectFlag = this.projectFlag == true ? false : true;
    // this.cityFlag = true;
    this.setupFlag = true;
    this._mFormGroup.reset();
    // this.router.navigate(['./myatom/routing/routing-setup/routing-setup']);
    this.ngxLoader.stop();
  }

  save() {
    if (!this.mIsEdit) {
      if (this.mShift.value == "" || this.mShift.value.length == 0) {
        this.notifyService.showWarning("Please select the shifts", null);
        return;
      }
      let newData = {
        "acceptableDiversion": this.mAcceptableDiversion.value,
        "allowTripGeneration": this.mAllowTripGeneration.value,
        "allowProActiveRouting": this.mAllowProActiveRouting.value,
        "allowTripGenerationOutOffSiteBoundry": this.mAllowTripGenerationOutOfBoundry.value,
        "priorityOptimization": this.mPriorityOptimization.value,
        "site": {
          "siteId": this.mSite.value
        },
        "status": "Active",
        "transportSLA": this.mTransportSLA.value,
        "routingType": this.mRoutingType.value,
        "shifts": this.mShift.value,
        'allowStopTripToVendor': this.mAllowStopTripToVendor.value,
        'disablePickUpTimeUpdateByVendor': this.mDisablePickUpTimeUpdateByVendor.value,
        'allowMarkNoShow': this.mAllowDriverToMarkNoShow.value
      }
      this.apiService.doPostRequestWithResponse(ApiConstants.addRoutingConfig, newData, (success) => {
        if (success.status == 200) {
          this.setupFlag = true;
          this.notifyService.showSuccess(success.body.message, null);
        } else {
          this.setupFlag = true;
          this.notifyService.showWarning(success.body.message, null);
        }
      },
        (error) => {
          this.notifyService.showError(error.message, null);
        }
      );
    }
    else {
      if (this.mShift.value == "" || this.mShift.value.length == 0) {
        this.notifyService.showWarning("Please select the shifts", null);
        return;
      }
      let editData = {
        "id": this.routingData.id,
        "acceptableDiversion": this.mAcceptableDiversion.value,
        "allowTripGeneration": this.mAllowTripGeneration.value,
        "allowProActiveRouting": this.mAllowProActiveRouting.value,
        "allowTripGenerationOutOffSiteBoundry": this.mAllowTripGenerationOutOfBoundry.value,
        "priorityOptimization": this.mPriorityOptimization.value,
        "site": {
          "siteId": this.mSite.value
        },
        "status": this.routingData.status,
        "transportSLA": this.mTransportSLA.value,
        "routingType": this.mRoutingType.value,
        "shifts": this.mShift.value,
        'allowStopTripToVendor': this.mAllowStopTripToVendor.value,
        'disablePickUpTimeUpdateByVendor': this.mDisablePickUpTimeUpdateByVendor.value,
        'allowMarkNoShow': this.mAllowDriverToMarkNoShow.value
      }

      this.apiService.doPutRequestWithResponse(ApiConstants.updateRoutingConfig, editData, (success) => {
        if (success.status == 200) {
          this.setupFlag = true;
          this.notifyService.showSuccess(success.body.message, null);
        } else {
          this.setupFlag = true;
          this.notifyService.showWarning(success.body.message, null);
        }
      },
        (error) => {
          this.notifyService.showError(error.message, null);
        }
      );
    }

  }

}

