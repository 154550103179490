import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-exception',
  templateUrl: './exception.component.html',
  styleUrls: ['./exception.component.scss']
})
export class ExceptionComponent implements OnInit {
  ELEMENT_DATA: any[] = [];
  displayedColumns: string[] = [
    "userId",
    "userName",
    "reason"
  ];
  dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);

  constructor(
    public dialogRef: MatDialogRef<ExceptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.ELEMENT_DATA = data;
    this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
  }

  ngOnInit() {
  }

  onClickCancel() {
    this.dialogRef.close();
  }

}
