<div fxLayout="row" fxLayoutAlign="start center">
    <h4 class="font-size-14" fxFlex>Exception employees</h4>
    <button mat-icon-button color="accent" (click)="onDeny()">
        <mat-icon>clear</mat-icon>
    </button>

</div>
<hr>
<div class="table-responsive">
    <table class="table table-striped font-size-14 mb-0">
        <thead>
            <tr>
                <th> Employee Id </th>
                <th> Employee Name </th>
                <th> Gender </th>
                <th> Contact no </th>
                <th> Address </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data1 of data">
                <td>{{data1.EmployeeId}}</td>
                <td>{{data1.Name}}</td>
                <td>{{data1.Gender}}</td>
                <td>{{data1.ContactNo}}</td>
                <td>{{data1.Address}}</td>
            </tr>
        </tbody>
    </table>
</div>
<hr>
<div class="features" fxLayout="row" fxLayout.lt-md="column">
    <div fxFlex fxLayout="column" fxLayoutGap.xs="28px">
        <div fxLayout="row" fxLayoutGap="20px" fxFlexAlign="end">
            <button class="btn btn-outline-danger" (click)="onDeny()">Deny</button>
            <button class="btn btn-outline-danger" (click)="onAllow()">Allow</button>
        </div>
    </div>
</div>