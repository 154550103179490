import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { AppConstants } from 'src/app/core/constants/appconstants';
import { ApiService } from 'src/app/core/services/api.service';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ServiceSharedData } from 'src/app/core/services/shared-data.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TripMapComponent } from '../trips/trip-view/trip-map/trip-map.component';
import { OncallRestartComponent } from '../oncall-restart/oncall-restart.component';
declare let google: any;
declare let L;

@Component({
  selector: 'app-on-call-map',
  templateUrl: './on-call-map.component.html',
  styleUrls: ['./on-call-map.component.scss']
})
export class OnCallMapComponent {
  modifyshow: boolean = true;
  dialogData1: any[] = [];
  tripsList: any[] = [];
  markers: any[] = [];
  ltlngs = [];
  dialogData: any;
  currentUser: any;
  roleName: any;
  zoneName: any;
  tripStatus: any;
  _mCurrentUser: any;
  liveData: any;
  tripListData: any;
  routeId: any;
  lat = 13.0196;
  lng = 77.5968;
  map: any;
  searchButtonFlag: boolean = true;
  flag: any;
  tripid: any;
  comment: any;


  constructor(
    private router: Router,
    private readonly apiService: ApiService,
    private serviceSharedData: ServiceSharedData,
    public serviceLocalStorage: ServiceLocalStorage,
    private notifyService: NotificationService,
    public dialog: MatDialog,
    private ngxLoader: NgxUiLoaderService,
    public dialogRef: MatDialogRef<TripMapComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.shortingBasedOnRouteOrder(data);
    this.dialogData1.push(data);
    this.dialogData = data;
    if (data.modifyshow) {
      this.modifyshow = false;
    }
    if (data.searchButtonFlag) {
      this.searchButtonFlag = false;
    }
  }

  ngOnInit() {
    this.currentUser = this.serviceLocalStorage.getUserObject();
    this.roleName = this.currentUser.userInfoDetails.role.name;
    this.zoneName = this.data.data.zone == null ? 'NA' : this.data.data.zone.name;
    this.tripsList.push(this.dialogData.data.tripId);
    this.getTripDetails();
    this.getDeviceTripStatus();
    if (this.data.data.zone == null) {
      this.data.data.zone = 'NA';
    }
    if (this.dialogData.params) {
      if (this.dialogData.params.modifyshow) {
        this.modifyshow = false;
      }
    }
    setTimeout(() => {
      if (this.dialogData.mapType === AppConstants.OSRM) {
        this.loadOsmap();
        this.showTripOnOSMap();
      } else {
        this.loadgmap();
        this.showTripOnMap();
      }
    }, 0);
  }

  onClickCancel(): void {
    this.dialogRef.close();
  }

  loadOsmap() {
    this.map = L.map("map", {
      fullscreenControl: true,
      dragging: true
    }).setView([this.dialogData.data.site.landmark.latitude, this.dialogData.data.site.landmark.longitude], 13);
    L.tileLayer(AppConstants.OSM_TILE, { attribution: AppConstants.OSM_CONTRIBUTORS }).addTo(this.map);
  }

  loadgmap() {
    let mapCenter = {
      lat: this.dialogData.data.site.landmark.latitude,
      lng: this.dialogData.data.site.landmark.longitude
    };
    this.map = new google.maps.Map(document.getElementById("map"), {
      center: mapCenter,
      zoom: 12,
      draggable: true,
      zoomControl: true
    });
  }

  showTripOnOSMap() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getdirectionresult + this.dialogData.data.tripId, success => {
      let wps = [];
      if (success.body.OSRM) {
        success.body.OSRM.waypoints.forEach((wp, i) => {
          this.ltlngs.push([wp.location[1], wp.location[0]]);
          wps.push(L.latLng(wp.location[1], wp.location[0]));
        });
        let r = L.Routing.control({
          serviceUrl: AppConstants.OSM_ROUTING_URL,
          showAlternatives: false,
          lineOptions: {
            styles: [{
              color: "#000",
              weight: 3,
              opacity: 0.7
            }]
          },
          fitSelectedRoutes: true,
          waypointMode: "connect",
          addWaypoints: false,
          draggableWaypoints: false,
          autoRoute: true,
          reverseWaypoints: false,
          altLineOptions: {
            styles: [{
              color: "#ed6852",
              weight: 3
            }]
          },
          show: true,
          routeWhileDragging: false,
          createMarker: (i, wp, nWps) => {
            if (success.body.LOGTYPE === "IN") {
              if (i === this.dialogData.data.tripUsers.length) {
                let m = L.marker(wp.latLng, {
                  icon: L.icon({
                    iconUrl: "./assets/images/company.png",
                    iconSize: [40, 40]
                  })
                }).bindPopup(this.dialogData.data.site.landmark.landmark);
                return m;
              }
            } else {
              if (i === 0) {
                let m = L.marker(wp.latLng, {
                  icon: L.icon({
                    iconUrl: "./assets/images/company.png",
                    iconSize: [40, 40]
                  })
                }).bindPopup(this.dialogData.data.site.landmark.landmark);
                return m;
              }
            }
            if (success.body.LOGTYPE === "IN") {
              if (i < this.dialogData.data.tripUsers.length) {
                let m = L.marker(wp.latLng, {
                  icon: L.icon({
                    iconUrl: this.dialogData.data.tripUsers[i].tripUser.userInfoDetails.gender === "Female"
                      ? "./assets/images/livetracking/femaleloc.svg" : "./assets/images/livetracking/maleloc.svg", iconSize: [65, 70]
                  })
                }).bindPopup('<tr><td>UserName : </td<td>' + this.dialogData.data.tripUsers[i].tripUser.userInfoDetails.displayName +
                  '</td><br> <td>AL : </td><td>' + this.dialogData.data.tripUsers[i].landmark.area.area + ', '
                  + this.dialogData.data.tripUsers[i].landmark.landmark + '</td></tr>').bindTooltip(i + 1 + "", {
                    permanent: true,
                    direction: "left",
                    offset: L.point(-10, 0)
                  }).openTooltip();
                return m;
              }
            } else {
              if (i - 1 <= this.dialogData.data.tripUsers.length) {
                let m = L.marker(wp.latLng, {
                  icon: L.icon({
                    iconUrl:
                      this.dialogData.data.tripUsers[i - 1].tripUser.userInfoDetails.gender === "Female"
                        ? "./assets/images/livetracking/femaleloc.svg" : "./assets/images/livetracking/maleloc.svg",
                    iconSize: [65, 70]
                  })
                }).bindPopup('<tr><td>UserName : </td<td>' + this.dialogData.data.tripUsers[i - 1].tripUser.userInfoDetails.displayName +
                  '</td><br> <td>AL : </td><td>' + this.dialogData.data.tripUsers[i - 1].landmark.area.area + ', ' +
                  this.dialogData.data.tripUsers[i - 1].landmark.landmark + '</td></tr>').bindTooltip(i + "", {
                    permanent: true,
                    direction: "left",
                    offset: L.point(-10, 0)
                  })
                  .openTooltip();
                return m;
              }
            }
          }
        }).addTo(this.map);
        r.setWaypoints(wps);
        r.hide();
      } else {
        this.notifyService.showError("OSRM data error.", null);
      }
    }, error => {
      console.log(error)
    });
  }

  getDeviceTripStatus() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getDeviceTripStatus + '?tripId=' + this.data.data.tripId, success => {
      if (success != null) {
        this.tripStatus = success.body.deviceTripStatus;
      } else {
        this.tripStatus = null;
      }
    }, error => {
      console.log(error)
    });
  }

  GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }

  showTripOnMap() {
    let sitecordinates = new google.maps.LatLng(
      this.dialogData.data.site.landmark.latitude,
      this.dialogData.data.site.landmark.longitude
    );
    let path = "./assets/images/company.png";
    var marker = new google.maps.Marker({
      position: sitecordinates,
      icon: {
        url: path,
        scaledSize: new google.maps.Size(40, 40)
      },
      map: this.map,
      title: this.dialogData.data.site.siteName
    });
    let waypts = [];
    this.dialogData.data.tripUsers.forEach(user => {
      let icon = "./assets/images/livetracking/maleloc.svg";
      if (user.tripUser.userInfoDetails.gender == "Female") {
        icon = "./assets/images/livetracking/femaleloc.svg";
      }
      var marker = new google.maps.Marker({
        position: { lat: user.landmark.latitude, lng: user.landmark.longitude },
        icon: { url: icon, labelOrigin: new google.maps.Point(18, -8) },
        draggable: false,
        map: this.map,
        title: user.tripUser.userInfoDetails.displayName + " - " + user.routedOrder,
        label: {
          text: "P" + user.routedOrder,
          color: "#000",
          fontWeight: "bold",
          fontSize: "12px"
        }
      });
      waypts.push({
        location: { lat: user.landmark.latitude, lng: user.landmark.longitude },
        stopover: true
      });
    });
    let startnodelatlng = {
      lat: this.dialogData.data.site.landmark.latitude,
      lng: this.dialogData.data.site.landmark.longitude
    };
    let endnodelatlng = {
      lat: this.dialogData.data.site.landmark.latitude,
      lng: this.dialogData.data.site.landmark.longitude
    };
    if (this.dialogData.data.shift.logType == "IN") {
      startnodelatlng = {
        lat: this.dialogData.data.tripUsers[0].landmark.latitude,
        lng: this.dialogData.data.tripUsers[0].landmark.longitude
      };
    } else {
      endnodelatlng = {
        lat: this.dialogData.data.tripUsers[this.dialogData.data.tripUsers.length - 1].landmark.latitude,
        lng: this.dialogData.data.tripUsers[this.dialogData.data.tripUsers.length - 1].landmark.longitude
      };
    }
    let request = {
      origin: startnodelatlng,
      destination: endnodelatlng,
      waypoints: waypts,
      optimizeWaypoints: false,
      avoidHighways: false,
      avoidTolls: true,
      travelMode: google.maps.DirectionsTravelMode.WALKING
    };
    let directionsService = new google.maps.DirectionsService();
    let directionsDisplay = new google.maps.DirectionsRenderer({
      suppressMarkers: true
    });
    directionsDisplay.setMap(this.map);
    directionsService.route(request, (response, status) => {
      if (status == google.maps.DirectionsStatus.OK) {
        directionsDisplay.setDirections(response);
      }
    });
  }

  getTripDetails() {
    const token = this.serviceLocalStorage.getAuthToken();
    this._mCurrentUser = this.serviceLocalStorage.getUserObject();
    let body = {
      tripIdList: this.tripsList,
      clientCode: this._mCurrentUser.clientCode,
      token: token
    };
    let liveTrak = "https://uat.myatom.app/livetrack/api/vehicle/";
    this.apiService.doPostRequestWithResponseForLiveTrack(liveTrak + ApiConstants.fetchgeoposition, body, success => {
      this.liveData = success.body;
      this.plotVehicle();
      this.setOSMLiveLocation();
    }, error => {
      console.log(error)
    });
  }

  setOSMLiveLocation() {
    if (this.liveData.length > 0) {
      this.liveData.forEach(vehicle => {
        this.markers.forEach(m => {
          if (m.customData.tripId === vehicle.tripId) {
            if (vehicle.status == 'DANGER') {
              m._icon.src = "./assets/images/livetracking/vehicle/FF503F.png"
            } else {
              m._icon.src = "./assets/images/livetracking/vehicle/" + vehicle.vehicleColorCode.replace(/#/g, "") + ".png#"
            }
            const elevationAngle = vehicle.elevation ? parseInt(vehicle.elevation) : 0;
            var newLatLng = new L.LatLng(vehicle.latitude, vehicle.longitude);
            m.setRotationAngle(elevationAngle);
            m.slideTo(newLatLng, {
              duration: 1000,
              keepAtCenter: false
            });
          }
        });
      });
    } else {
      if (this.markers.length > 0) {
        this.markers.forEach(m => {
          this.map.removeLayer(m);
        });
      }
    }
  }

  plotVehicle() {
    this.markers.forEach(marker => {
      marker.setMap(null);
    });
    this.markers = [];
    if (this.liveData && this.liveData.length > 0) {
      this.liveData.forEach(vehicle => {
        this.showSingleRunningTrip(vehicle);
      });
    }
  }

  showSingleRunningTrip(vehicle) {
    this.apiService.doPostRequestWithResponse(ApiConstants.showSingleRunningTrip + vehicle.tripId, '', success => {
      if (success.status == 200) {
        this.tripListData = success.body;
        if (this.tripListData[0] != null) {
          let tripcodes = this.tripListData[0];
          let tripcode = tripcodes.tripDetails.tripCode;
          let tripCodeLength = tripcode.length;
          this.routeId = tripcode.slice(tripCodeLength - 3, tripCodeLength)
          if (vehicle.vehicleStatus !== "stopped") {
            this.addOSMarker(vehicle);
          }
        }
      }
    },
      error => {
      }
    );
  }

  addOSMarker(vehicle) {
    this.map.panTo(new L.LatLng(vehicle.latitude, vehicle.longitude));
    let carMarker = new L.Icon({
      iconUrl: "./assets/images/livetracking/vehicle/" + vehicle.vehicleColorCode.replace(/#/g, "") + ".png#",
      iconSize: [45, 40],
      iconAnchor: [15, 20]
    });
    let marker = new L.marker([vehicle.latitude, vehicle.longitude], {
      icon: carMarker
    }).addTo(this.map).bindPopup($('<b style="cursor:pointer">' + vehicle.vehicleNo + " " + this.routeId + "</b>").click(() => { })[0]);
    marker.customData = vehicle;
    this.markers.push(marker);
  }

  shortingBasedOnRouteOrder(data) {
    return data['data']['tripUsers'].sort((a, b) => a.routedOrder - b.routedOrder);
  }

  stopTrip() {
    this.ngxLoader.start();
    this.flag = 'stop';
    this.apiService.doGetRequestWithResponse(ApiConstants.getStoporRestartTrip + '?tripId=' + this.data.data.tripId + '&flag=' + this.flag + '&comment=' + "", (success) => {
      this.ngxLoader.stop();
      if (success.status == 200) {
        this.notifyService.showSuccess(success.body.message, null);
        this.getTripDetails();
        this.getDeviceTripStatus();
      } else {
        this.notifyService.showWarning("Error", null);
      }
    }, (error) => {
      this.ngxLoader.stop();
      this.notifyService.showError("Failed to add", null);
    });
  }

  tripRestart() {
    let data = this.data.data;
    let dialogRef = this.dialog.open(OncallRestartComponent, {
      data: { data }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getTripDetails();
      this.getDeviceTripStatus();
    });
  }

}
