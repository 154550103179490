<div class="mat-modal-dialog city-modal">
    <h2 mat-dialog-title>
        <span class="font-size-14">Add Area</span>
        <span class="material-icons-outlined"
            style="color:#747679; font-size: 25px; float: right; position: relative; top: 10px; cursor: pointer"
            (click)="onClickCancel()"> close</span>
    </h2>

    <mat-dialog-content>
        <form [formGroup]="_mFormGroup">
            <div class="form-group">
                <label>Area Name<sup class="mandatory">*</sup></label>
                <input class="form-input margin-right-8px" aria-label="Default select example" placeholder="Area Name"
                    maxlength="30" (keypress)="discard_special_char_And_String($event)" autocomplete="off"
                    formControlName="areaName" required>
                <div  class="error-msg" *ngIf="mAreaName.hasError('required')">
                    Please enter area name
                </div>
            </div>
        </form>
    </mat-dialog-content>

    <div mat-dialog-actions>
        <button class="btn customseProceedBtn" (click)="onSubmit()">
            Done
        </button>
    </div>
</div>