import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { AppConstants } from 'src/app/core/constants/appconstants';
import { Constants } from 'src/app/core/constants/constants';
import { RouterConstants } from 'src/app/core/constants/router.constants';
import { ApiService } from 'src/app/core/services/api.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import * as L from "leaflet";
import "leaflet.marker.slideto";
import "leaflet-control-geocoder/dist/Control.Geocoder.js";
import * as GeoSearch from 'leaflet-search';


@Component({
  selector: 'app-add-new-user-details',
  templateUrl: './add-new-user-details.component.html',
  styleUrls: ['./add-new-user-details.component.scss']
})
export class AddNewUserDetailsComponent {

  public _mFormGroup: FormGroup;
  public mFirstName = new FormControl('', Validators.required);
  public mLastName = new FormControl('', Validators.required);
  public mGender = new FormControl('', Validators.required);
  public mEmployeeId = new FormControl('', Validators.required);
  public mEmailAddress = new FormControl('', [Validators.required, Validators.email]);
  public mAddress = new FormControl('');
  public mIsContractEmployee = new FormControl(false);
  public mContactNumber = new FormControl(null, Validators.compose([Validators.required, Validators.pattern('^[9|8|7|6]{1}[0-9]{9}$')]));
  public mLocation = new FormControl('', Validators.required);
  public mSiteName = new FormControl('', Validators.required);
  public mArea = new FormControl('');
  public mLandmark = new FormControl('');
  public mDisplayName = new FormControl('', Validators.required);
  public user: any = { userInfoDetails: { role: {}, site: {} } };

  emailIsValid: boolean = false;
  _mSitesData: any[] = [];
  mapCenter: any;
  map: any = {};
  homePointMarker: any;
  commonPointMarker: any;
  searchBox: any;
  lat = 13.0196;
  lng = 77.5968;
  mapType: any;
  _mSiteSelected: any = '';
  _mGender = [
    { key: 'Male', value: 'Male' },
    { key: 'Female', value: 'Female' },
    { key: 'Other', value: 'Others' }
  ];
  _mLocationData: any[] = [];
  mUserPassword: any = '';
  _mClientCode: any = '';
  _mAreasData: any[] = [];
  _mLandmarksData: any[] = [];
  public image: any = {};

  constructor(
    public readonly fb: FormBuilder,
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    private ngxLoader: NgxUiLoaderService,
    private ng2ImgMaxService: Ng2ImgMaxService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this._mFormGroup = this.fb.group({
      'mFirstName': this.mFirstName,
      'mLastName': this.mLastName,
      'mDisplayName': this.mDisplayName,
      'mGender': this.mGender,
      'mEmployeeId': this.mEmployeeId,
      'mEmailAddress': this.mEmailAddress,
      'mAddress': this.mAddress,
      'mContactNumber': this.mContactNumber,
      'mLocation': this.mLocation,
      'mSiteName': this.mSiteName,
      'mArea': this.mArea,
      'mLandmark': this.mLandmark,
      'mIsContractEmployee': this.mIsContractEmployee,
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.getTempUser(params.id);
    });

    this.mSiteName.disable();
    this.mArea.disable();
    this.mLandmark.disable();
    this.loadOSMap();
  }

  loadOSMap() {
    this.map = new L.map("user-map", { fullscreenControl: true }).setView([13.0196, 77.5968], 10);
    L.tileLayer(Constants.OSM_TILE, { attribution: Constants.OSM_CONTRIBUTORS }).addTo(this.map);
    this.map.addControl(
      new L.Control.Search(Constants.OSM_NOMINATIM_Obj));
    this.loadMap();
    const search = GeoSearch.GeoSearchControl({
      provider: new GeoSearch.OpenStreetMapProvider(),
    });
    this.map.addControl(search);
  }

  loadMap() {
    this.map.on("click", e => {
      if (this.commonPointMarker) {
        if (this.homePointMarker) {
          let latLong = { lat: e.latlng.lat, lng: e.latlng.lng };
          this.getOSGeoLocation(latLong);
          this.homePointMarker.slideTo(new L.LatLng(e.latlng.lat, e.latlng.lng), {
            duration: 1000,
            keepAtCenter: false
          });
        } else {
          this.setOSMarker(e.latlng);
          let latLong = { lat: e.latlng.lat, lng: e.latlng.lng };
          this.getOSGeoLocation(latLong);
        }
      } else {
        this.notifyService.showWarning("Please select location", null);
      }
    });
  }

  omit_number(event) {
    var k;
    k = event.charCode;
    return ((k >= 48 && k <= 57));
  }

  discard_special_char_And_String(event) {
    var k;
    k = event.charCode;
    return (k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32;
  }

  discard_special_char(event) {
    var k;
    k = event.charCode;
    return (k > 64 && k < 91) || (k > 96 && k < 123) || (k >= 48 && k <= 57);
  }

  checkEmail(event) {
    const regExp = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/m);
    this.emailIsValid = false;
    if (event.target.value !== '') {
      if (!regExp.test(event.target.value)) {
        this.emailIsValid = true;
      } else {
        this.emailIsValid = false;
      }
    }
  }

  getTempUser(id) {
    let clientCode = id.split("@")[1];
    let emailId = id.split("@")[0];
    let encodedEmail = encodeURIComponent(emailId);
    this.apiService.doGetWithEmailHead(ApiConstants.getTempUser + encodedEmail, clientCode, (success) => {
      if (success.status == 200) {
        this.mEmailAddress.setValue(success.body.emailAddress);
        this.mEmployeeId.setValue(success.body.employeeId);
        this.mDisplayName.setValue(success.body.displayName);
        if (success.body.contactNumber != null) {
          this.mContactNumber.setValue(success.body.contactNumber);
          this.mUserPassword = success.body.password;
          this.mContactNumber.disable();
          this.mEmailAddress.disable();
          this.mEmployeeId.disable();
        }
        this._mClientCode = success.body.clientCode;
        this.apiService.doGetWithEmailHead(ApiConstants.getlocationlistnew, this._mClientCode, success => {
          if (success.status == 200) {
            this._mLocationData = success.body;
          } else {
            this._mLocationData = [];
          }
        }, error => {
        });
      }
    },
      (error) => {
      });
  }

  onLocationChange() {
    this.getSites();
    let latLong = { lat: 13.05728982565133, lng: 77.5938928127289 };
    this.setNewCommonPointOSMarker(latLong);
  }

  getSites() {
    this.apiService.doGetWithEmailHead(ApiConstants.getsiteslist + this.mLocation.value, this._mClientCode, success => {
      if (success.status == 200) {
        this._mSitesData = success.body;
        this.mSiteName.enable();
      } else {
        this._mSitesData = [];
        this.notifyService.showInfo("No sites Found for selected locations", null);
      }
    }, error => {
      console.log(error)
    });
  }

  getOSGeoLocation(latlng: any) {
    // const control = new L.Control.Geocoder({ geocoder: null });
    // control.options.geocoder.reverse(latlng, 13, results => {
    //     this.mAddress.setValue(results[0].name);
    //     if (results != null) {
    //     } else {
    //         this.notifyService.showInfo("No address available!", null);
    //     }
    // });
    let url = "https://maps.myatom.in/nominatim/reverse?lat=" + latlng.lat + "&lon=" + latlng.lng + "&zoom=-4&addressdetails=1&format=json";
    this.apiService.doGetForOsm(url, (success) => {
      this.mAddress.setValue(success.display_name);
      if (success != null) {
      } else {
        this.notifyService.showInfo("No address available!", null);
      }
    }, error => {
    });
  }

  setOSMarker(location) {
    if (this.homePointMarker) {
      this.homePointMarker.setPosition(location);
    } else {
      this.setNewHomePointOSMarker(location);
    }
  }

  setNewHomePointOSMarker(e) {
    this.homePointMarker = L.marker(
      [e.latlng ? e.latlng.lat : e.lat, e.latlng ? e.latlng.lng : e.lng],
      {
        icon: L.icon({
          iconUrl: "./assets/images/home.png",
          popupAnchor: [20, 5],
          iconSize: [30, 40],
          iconAnchor: [15, 40]
        })
      }
    )
      .addTo(this.map)
      .bindPopup("Home Point");
  }

  setNewCommonPointOSMarker(latlng) {
    this.commonPointMarker = L.marker([latlng.lat, latlng.lng],
      {
        icon: L.icon({
          iconUrl: "./assets/images/yellowmap.png",
          popupAnchor: [20, 5],
          iconSize: [30, 40],
          iconAnchor: [15, 40]
        })
      }
    )
      .addTo(this.map)
      .bindPopup("Common Point");
  }

  onSiteChange() {
    let siteIndex = this._mSitesData.findIndex(ele => ele.siteId == this.mSiteName.value);
    this._mSiteSelected = this._mSitesData[siteIndex];
  }


  getLandmarks() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getLandmarkslist + this.mArea.value, success => {
      if (success.status == 200) {
        this._mLandmarksData = success.body;
        this.mLandmark.enable();
        if (this.commonPointMarker) {
          this.map.removeLayer(this.commonPointMarker);
          this.commonPointMarker = null;
        }
      } else {
        this._mLandmarksData = [];
        this.notifyService.showInfo("No Landmarks Found", null);
      }
    }, error => {
      console.log(error)
    });
  }

  showCommonPointOnMap($event) {
    let latLong = { lat: $event.value.latitude, lng: $event.value.longitude };
    if (this.commonPointMarker) {
      this.getOSGeoLocation(latLong);
      this.commonPointMarker.slideTo(new L.LatLng($event.value.latitude, $event.value.longitude), {
        duration: 1000,
        keepAtCenter: false
      });
    } else {
      this.setNewCommonPointOSMarker(latLong);
    }
  }

  onFileChange($event, doc: string) {
    let files = $event.target.files;
    if (files && files.length > 0) {
      let fileType: string = files[0].type;
      let fileSize: number = files[0].size;
      if (fileType) {
        if (fileType.includes("png") || fileType.includes("jpeg") || fileType.includes("jpg")) {
          if (fileSize < 50000) {
            this.image.name = files[0].name;
            this._compressImage(files[0], fileData => {
              this.image.data = fileData;
              this.user.userInfoDetails.image = fileData.split(',')[1];
            });
          } else {
            this.notifyService.showWarning("Uploaded file size is too large (Max 50KB). File size : " + this.bytesToSize(fileSize), null);
          }
        } else {
          this.notifyService.showWarning("Please upload valid Image ex: png, jpeg and jpg.", null);
        }
      }
    }
  }

  bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return 'n/a';
    let i = (Math.floor(Math.log(bytes) / Math.log(1024)));
    if (i == 0) return bytes + ' ' + sizes[i];
    return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
  };

  _compressImage(file, callback) {
    this.ng2ImgMaxService.resize([file], 600, 600).subscribe(result => {
      this._readImageAndLoad(result, callback);
    }, error => {
      console.error("Resize error:", error);
      this.notifyService.showError("error.message.", null);
    }
    );
  }

  _readImageAndLoad(file, callback) {
    let reader = new FileReader();
    reader.onload = (e: any) => {
      let dataUrl = e.target.result;
      let base64 = dataUrl.split(',')[1];
      callback(dataUrl);
    };
    reader.readAsDataURL(file);
  }

  onSubmit() {
    if (this.mSiteName.value) {
      if (!this.homePointMarker) {
        this.notifyService.showWarning("Please select home lat-long", null);
        return;
      }
      const data = {
        'status': AppConstants.ACTIVE_STATUS,
        'userName': this.mEmailAddress.value,
        'clientCode': this._mClientCode,
        'password': this.mUserPassword,
        'userInfoDetails': {
          "address": this.mAddress.value,
          "authType": "L",
          "contractEmployee": this.mIsContractEmployee.value,
          "contactNumber": this.mContactNumber.value,
          "displayName": this.mDisplayName.value,
          "emailAddress": this.mEmailAddress.value,
          "firstName": this.mFirstName.value,
          "gender": this.mGender.value,
          "lastName": this.mLastName.value,
          "homeLongitude": this.homePointMarker._latlng.lng,
          "homeLatitude": this.homePointMarker._latlng.lat,
          "employeeId": this.mEmployeeId.value,
          "status": AppConstants.ACTIVE_STATUS,
          "image": this.user.userInfoDetails.image,
          "role": {
            "id": 3 // Employee roleId is 3
          },
          "site": this._mSiteSelected,
          // "pickUpDrop": {
          //     "id": this.mLandmark.value.id
          // }
        }
      }

      this.ngxLoader.start();
      this.apiService.doPostWithEmailHead(ApiConstants.userRegistration, data, this._mClientCode, (success) => {
        if (success.status == 201) {
          this.ngxLoader.stop();
          this.notifyService.showSuccess("You have successfully registered!. We have sent the credentials to your mail id, Please login with that credentials", null);
          this.router.navigate(['/' + RouterConstants.auth + '/' + RouterConstants.client]);
        }
      }, (error) => {
        this.ngxLoader.stop();
        this.notifyService.showError(error.message, null);
      });
    } else {
      this.notifyService.showWarning("Please select site", null);
    }
  }

}
