<div class="row" *ngIf="mData.length > 0">
    <div class="col-sm-12">
        <div class="card">
            <ul ngbNav #nav="ngbNav" [activeId]="activeTabId" class="nav-tabs nav-tabs-custom g-nav-tabs-custom"
                style="padding-top: 7px;">
                <li [ngbNavItem]="1">
                    <a ngbNavLink> Adjustment Trips</a>
                    <ng-template ngbNavContent>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="p-3"></div>
            <div class="card-body">
                <div class="row" style="margin: 0;">
                    <div class="col-sm-12 p-0">
                        <div class="table-responsive">
                            <table mat-table [dataSource]="dataSource" class="table table-borderless customtripsTable"
                                matPaginator>
                                <ng-container matColumnDef="date">
                                    <th mat-header-cell *matHeaderCellDef> Date </th>
                                    <td mat-cell *matCellDef="let element"
                                        style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.date}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="tripCode">
                                    <th mat-header-cell *matHeaderCellDef>Trip Code</th>
                                    <td mat-cell *matCellDef="let element"
                                        style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.tripCode}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="siteName">
                                    <th mat-header-cell *matHeaderCellDef> Site </th>
                                    <td mat-cell *matCellDef="let element"
                                        style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.siteName}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="vendorName">
                                    <th mat-header-cell *matHeaderCellDef> Vendor Name </th>
                                    <td mat-cell *matCellDef="let element"
                                        style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.vendorName}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="employeeId">
                                    <th mat-header-cell *matHeaderCellDef> Employee Id </th>
                                    <td mat-cell *matCellDef="let element"
                                        style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.employeeId}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="logType">
                                    <th mat-header-cell *matHeaderCellDef> Log Type </th>
                                    <td mat-cell *matCellDef="let element"
                                        style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.logType}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="nearestShiftTime">
                                    <th mat-header-cell *matHeaderCellDef> Nearest ShiftTime </th>
                                    <td mat-cell *matCellDef="let element"
                                        style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.nearestShiftTime}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="approximateKm">
                                    <th mat-header-cell *matHeaderCellDef> Approximate KM </th>
                                    <td mat-cell *matCellDef="let element"
                                        style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.approximateKm}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Status </th>
                                    <td mat-cell *matCellDef="let element;let i = index;"
                                        style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        <button
                                            *ngIf="element.status =='SENT_FOR_ADMIN_APPROVAL' && !_mCurrentUserRoleFlag"
                                            (click)="pendingStatusRequest('SENT_FOR_VENDOR_APPROVAL', i)"
                                            class="btn button-approve">Approve</button>
                                        <button
                                            *ngIf="element.status =='SENT_FOR_ADMIN_APPROVAL' && !_mCurrentUserRoleFlag"
                                            (click)="pendingStatusRequest('REJECTED_BY_ADMIN', i)"
                                            class="btn button-reject">Reject</button>
                                        <button
                                            style="display: flex; position: relative; left: 35px; padding: 7px 17px;"
                                            *ngIf="element.status =='SENT_FOR_ADMIN_APPROVAL' && !_mCurrentUserRoleFlag"
                                            (click)="pendingStatusRequest('CANCELLED_BY_ADMIN', i)"
                                            class="btn button-reject">Cancel</button>
                                        {{element.status}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="vehicleType">
                                    <th mat-header-cell *matHeaderCellDef> Vehicle Type </th>
                                    <td mat-cell *matCellDef="let element"
                                        style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.vehicleType}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="driverName">
                                    <th mat-header-cell *matHeaderCellDef> Driver Name</th>
                                    <td mat-cell *matCellDef="let element"
                                        style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.driverName}}
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                        </div>
                        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20, 30, 50, 80, 100]"></mat-paginator> -->
                        <ngb-pagination class="d-flex justify-content-end " [collectionSize]="_mTotalElements "
                            [pageSize]="size " [(page)]="page" (pageChange)="onNewPageRequest($event)" [maxSize]="5"
                            [boundaryLinks]="true">
                        </ngb-pagination>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row mt-3" *ngIf="mData.length == 0">
    <div class="col-sm-12 col-md-12">
        <h4 class="tdw-text-align-center tdw-font-normal tdw-margin-4
          mat-color-text--blue-grey-600">
            --No Data Found --</h4>
    </div>
</div>