import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-trip-warning',
  templateUrl: './trip-warning.component.html',
  styleUrls: ['./trip-warning.component.scss']
})
export class TripWarningComponent {

  mData: any = [];

  constructor(
    public dialogRef: MatDialogRef<TripWarningComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
    this.mData = data;
  }
  ngOnInit(): void {
  }

  onClickCancel(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close(this.mData);
  }
}
