import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppConstants } from 'src/app/core/constants/appconstants';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
declare let google: any;
declare let L;

@Component({
  selector: 'app-trip-mirror-map',
  templateUrl: './trip-mirror-map.component.html',
  styleUrls: ['./trip-mirror-map.component.scss']
})
export class TripMirrorMapComponent implements OnInit {
  isOSMFalg: boolean = false;
  map: any = {};
  mapCenter: any;
  marker: any;
  empmarkers: any[] = [];
  searchBox: any;
  tripData: any;
  mapType: any;

  constructor(
    private serviceLocalStorage: ServiceLocalStorage,
    public dialogRef: MatDialogRef<TripMirrorMapComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.tripData = data;
    }
  }

  ngOnInit() {
    this.mapType = this.serviceLocalStorage.getMapType();
    if (this.mapType === AppConstants.OSRM) {
      this.isOSMFalg = true;
      setTimeout(() => {
        this.loadOSmap();
        this.showTripOnOSMap();
      }, 10);
    } else {
      this.isOSMFalg = false;
      setTimeout(() => {
        this.mapCenter = { lat: 13.0196, lng: 77.5968 };
        this.map = new google.maps.Map(document.getElementById("map"), {
          center: this.mapCenter,
          zoom: 12,
          draggable: true,
          zoomControl: true
        });
        var input = document.getElementById("pac-input");
        this.searchBox = new google.maps.places.SearchBox(input);
        this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
        this.searchBox.addListener("places_changed", event => {
          let places = this.searchBox.getPlaces();
          if (places.length == 0) {
            return;
          }
          let bounds = new google.maps.LatLngBounds();
          places.forEach(place => {
            if (!place.geometry) {
              return;
            }
            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          this.map.fitBounds(bounds);
        });
        this.map.addListener("bounds_changed", event => {
          this.searchBox.setBounds(this.map.getBounds());
        });
        this.showTripsOnMap()
      }, 10);
    }
  }

  showTripsOnMap() {
    let waypoints: any = [];
    let location;
    if (this.tripData.tripDetails.shift.logType == "IN") {
      this.tripData.tripDetails.tripUsers.forEach((user) => {
        location = {
          lat: user.landmark.latitude,
          lng: user.landmark.longitude
        };
        waypoints.push({ location: location, stopover: true });
      });
      location = {
        lat: this.tripData.tripDetails.site.landmark.latitude,
        lng: this.tripData.tripDetails.site.landmark.longitude
      };
      waypoints.push({ location: location, stopover: true });
    } else {
      location = {
        lat: this.tripData.tripDetails.site.landmark.latitude,
        lng: this.tripData.tripDetails.site.landmark.longitude
      };
      waypoints.push({ location: location, stopover: true });
      this.tripData.tripDetails.tripUsers.forEach((user) => {
        location = {
          lat: user.landmark.latitude,
          lng: user.landmark.longitude
        };
        waypoints.push({ location: location, stopover: true });
      });
    }
    waypoints.forEach((location, p) => {
      if (this.tripData.tripDetails.shift.logType === "IN") {
        if (p === this.tripData.tripDetails.tripUsers.length) {
          this.markSite(this.tripData.tripDetails.site);
          return;
        }
      } else {
        if (p === 0) {
          this.markSite(this.tripData.tripDetails.site);
          return;
        }
      }
      if (this.tripData.tripDetails.shift.logType === "IN") {
        if (p <= this.tripData.tripDetails.tripUsers.length) {
          this.plotEmployee(location.location, this.tripData.tripDetails.tripUsers[p].gender, this.tripData.tripDetails.tripUsers[p].displayName,
            this.tripData.tripDetails.tripUsers[p].address, this.tripData.tripDetails.tripUsers[p].routedOrder, p + 1);
        }
      } else {
        if (p - 1 <= this.tripData.tripDetails.tripUsers.length) {
          this.plotEmployee(location.location, this.tripData.tripDetails.tripUsers[p - 1].gender, this.tripData.tripDetails.tripUsers[p - 1].displayName,
            this.tripData.tripDetails.tripUsers[p - 1].address, this.tripData.tripDetails.tripUsers[p - 1].routedOrder, p);
        }
      }
    })
    let origin: any;
    let destination: any;
    origin = {
      lat: waypoints[0].location.lat,
      lng: waypoints[0].location.lng
    };
    destination = {
      lat: waypoints[waypoints.length - 1].location.lat,
      lng: waypoints[waypoints.length - 1].location.lng
    };
    var directionsService = new google.maps.DirectionsService();
    var directionsDisplay = new google.maps.DirectionsRenderer({
      draggable: false,
      polylineOptions: { strokeWeight: 3, strokeColor: "#0080FE" },
      suppressMarkers: true
    });
    directionsDisplay.setMap(this.map);
    directionsService.route({
      origin: origin,
      destination: destination,
      waypoints: waypoints,
      optimizeWaypoints: true,
      travelMode: "DRIVING",
      avoidFerries: true,
      avoidHighways: true,
      avoidTolls: true
    }, (response, status) => {
      if (status === "OK") {
        directionsDisplay.setDirections(response);
      } else {
        window.alert("Directions request failed due to " + status);
      }
    }
    );
  }

  loadOSmap() {
    this.map = L.map("map", {
      fullscreenControl: true,
      dragging: true
    }).setView([this.tripData.tripDetails.site.landmark.latitude, this.tripData.tripDetails.site.landmark.longitude], 13);
    L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);
  }

  showTripOnOSMap() {
    let wps = [];
    if (this.tripData.tripDetails.shift.logType == "IN") {
      this.tripData.tripDetails.tripUsers.forEach((wp) => {
        wps.push(L.latLng(wp.landmark.latitude, wp.landmark.longitude)
        );
      });
      wps.push(L.latLng(this.tripData.tripDetails.site.landmark.latitude, this.tripData.tripDetails.site.landmark.longitude));
    } else {
      wps.push(L.latLng(this.tripData.tripDetails.site.landmark.latitude, this.tripData.tripDetails.site.landmark.longitude));
      this.tripData.tripDetails.tripUsers.forEach((wp) => {
        wps.push(L.latLng(wp.landmark.latitude, wp.landmark.longitude));
      });
    }
    let r = L.Routing.control({
      serviceUrl: "https://maps.myatom.in/route/v1",
      showAlternatives: false,
      lineOptions: {
        styles: [
          {
            color: "#242c81",
            weight: 7
          }
        ]
      },
      fitSelectedRoutes: true,
      waypointMode: "connect",
      addWaypoints: false,
      draggableWaypoints: false,
      autoRoute: true,
      reverseWaypoints: false,
      altLineOptions: {
        styles: [
          {
            color: "#ed6852",
            weight: 3
          }
        ]
      },
      show: true,
      routeWhileDragging: false,
      createMarker: (i, wp, nWps) => {
        if (this.tripData.tripDetails.shift.logType === "IN") {
          if (i === this.tripData.tripDetails.tripUsers.length) {
            let m = L.marker(wp.latLng, {
              icon: L.icon({
                iconUrl: "./assets/images/company.png",
                iconSize: [40, 40]
              }),
              title: this.tripData.tripDetails.site.landmark.landmark
            });
            return m;
          }
        } else {
          if (i === 0) {
            let m = L.marker(wp.latLng, {
              icon: L.icon({
                iconUrl: "./assets/images/company.png",
                iconSize: [40, 40]
              }),
              title: this.tripData.tripDetails.site.landmark.landmark
            });
            return m;
          }
        }
        if (this.tripData.tripDetails.shift.logType === "IN") {
          if (i <= this.tripData.tripDetails.tripUsers.length) {
            let m = L.marker(wp.latLng, {
              icon: L.icon({
                iconUrl:
                  this.tripData.tripDetails.tripUsers[i].gender === "Female" ? "./assets/images/livetracking/femaleloc.svg" : "./assets/images/livetracking/maleloc.svg",
                iconSize: [65, 70]
              }),
              title: this.tripData.tripDetails.tripUsers[i].displayName
            }).bindTooltip(i + 1 + "", {
              permanent: true,
              direction: "left",
              offset: L.point(-10, 0)
            })
              .openTooltip();
            return m;
          }
        } else {
          if (i - 1 <= this.tripData.tripDetails.tripUsers.length) {
            let m = L.marker(wp.latLng, {
              icon: L.icon({
                iconUrl:
                  this.tripData.tripDetails.tripUsers[i - 1]
                    .gender === "Female" ? "./assets/images/livetracking/femaleloc.svg" : "./assets/images/livetracking/maleloc.svg",
                iconSize: [65, 70]
              }),
              title: this.tripData.tripDetails.tripUsers[i - 1].displayName
            })
              .bindTooltip(i + "", {
                permanent: true,
                direction: "left",
                offset: L.point(-10, 0)
              })
              .openTooltip();
            return m;
          }
        }

      }
    }).addTo(this.map);
    r.setWaypoints(wps);
    r.hide();
  }

  markSite(site) {
    let sitecordinates = new google.maps.LatLng(
      site.landmark.latitude,
      site.landmark.longitude
    );
    let path = "./assets/images/company.png";
    this.marker = new google.maps.Marker({
      position: sitecordinates,
      icon: {
        url: path,
        scaledSize: new google.maps.Size(40, 40)
      },
      map: this.map,
      title: site.siteName
    });
  }

  plotEmployee(latlng, gender, name, address, routedOrder, index) {
    let path = "./assets/images/livetracking/maleloc.svg";
    if (gender == "Female") {
      path = "./assets/images/livetracking/femaleloc.svg";
    }
    let empmarker = new google.maps.Marker({
      position: latlng,
      title: name + " - " + address,
      map: this.map,
      animation: google.maps.Animation.DROP,
      icon: {
        url: path,
        scaledSize: new google.maps.Size(50, 50),
        labelOrigin: new google.maps.Point(18, -8)
      },
      label: {
        text: "P" + index,
        color: "black",
        fontWeight: "bold",
        fontSize: "12px"
      }
    });
    this.empmarkers.push(empmarker);
  }

  setNewMarker(location, userName) {
    this.marker = new google.maps.Marker({
      position: location,
      draggable: true,
      animation: google.maps.Animation.DROP,
      map: this.map
    });
  }

  close() {
    this.dialogRef.close();
  }

}
