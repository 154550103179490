import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { ApiService } from 'src/app/core/services/api.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ServiceSharedData } from 'src/app/core/services/shared-data.service';

interface IHash {
  [details: string]: string;
}

@Component({
  selector: 'app-trip-generation',
  templateUrl: './trip-generation.component.html',
  styleUrls: ['./trip-generation.component.scss']
})
export class TripGenerationComponent implements OnInit {
  routingContainer: boolean = false;
  mirrorContainer: boolean = false;
  _mTitle: any = "Select Mode";
  vehicleTypes: any[] = [];
  cabs: any[] = [];
  shiftTimings: any[] = [];
  dialogData: any;
  public _mMirrorFormGroup: FormGroup;
  public _Rdate = new FormControl("");
  public _Rtime: any = new FormControl("");
  public _RMirrorType: any = new FormControl("");
  mirrorTypes: any[] = [{ view: "MANUAL", value: "MIRROR_MANUAL" }];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private readonly apiService: ApiService,
    public serviceSharedData: ServiceSharedData,
    private notifyService: NotificationService,
    private ngxLoader: NgxUiLoaderService,
    public dialogRef: MatDialogRef<TripGenerationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.getAllShifts(data.site.siteId);
    this.dialogData = data;
    this._mMirrorFormGroup = this.fb.group({
      date: this._Rdate,
      time: this._Rtime,
      type: this._RMirrorType
    });
  }

  ngOnInit() {
    this._RMirrorType.setValue(this.mirrorTypes[0]);
  }

  getAllShifts(siteId) {
    this.apiService.doGetRequestWithResponse(ApiConstants.getShiftTimesListBySiteId + '?siteId=' + siteId + '&shiftType=' + 'General', success => {
      if (success.status == 200) {
        this.shiftTimings = success.body;
      } else {
        this.shiftTimings = [];
      }
    }, error => {
      console.log(error)
    });
  }

  runRouting() {
    this._mTitle = "Routing Controls";
    this.routingContainer = true;
    this.cabs = this.data.site.vehicleTypes;
  }

  mirror() {
    this._mTitle = "Mirroring Controls";
    this.mirrorContainer = true;
  }

  eventHandler(event, id) {
    let flag = true;
    if (event.target.value != null) {
      this.vehicleTypes.forEach((vehicle, i) => {
        if (vehicle.id === id) {
          flag = false;
          this.vehicleTypes[i].value = event.target.value;
        }
      });
      if (flag) {
        this.vehicleTypes.push({ id: id, value: event.target.value });
      }
    }
  }

  onSubmit() {
    if (this.vehicleTypes.length > 0) {
      const myhash: IHash = {};
      this.vehicleTypes.forEach(vehicle => {
        if (vehicle.value != null && vehicle.value !== "") {
          myhash[vehicle.id] = vehicle.value;
        }
      });
      const data = {
        requiesteddate: this.dialogData.requestedDate + ' 00:00:00',
        shiftId: this.dialogData.shift.SHIFTID,
        siteId: this.dialogData.site.siteId,
        vehicleTypes: myhash
      };
      this.onRoutingSubmit(data);
    } else {
      this.notifyService.showInfo('Please allocate vehicle count for at least one vehicle type', null);
      return;
    }
  }

  onSkip() {
    let data;
    let myhash: IHash = {};
    data = {
      requiesteddate: this.dialogData.requestedDate + " 00:00:00",
      shiftId: this.dialogData.shift.SHIFTID,
      siteId: this.dialogData.site.siteId,
      vehicleTypes: myhash
    };
    this.onRoutingSubmit(data);
  }

  onRoutingSubmit(data) {
    this.ngxLoader.start();
    this.apiService.doPostRequestWithResponse(ApiConstants.tripDetailsGenerate, data, success => {
      if (success.status == 200) {
        this.ngxLoader.stop();
        if (success.body.MSG != 'Error') {
          this.notifyService.showSuccess(success.body.MSG, null);
        }
        success.body.shiftId = data.shiftId;
        this.dialogRef.close(success.body);
      }
    }, error => {
      this.ngxLoader.stop();
      this.notifyService.showError("Failed To Generate", null);
      this.dialogRef.close();
    });
  }

  formatDate(d): any {
    let m = d.getMonth() + 1;
    let dt = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
    let mm = m < 10 ? "0" + m : m;
    return dt + "/" + mm + "/" + d.getFullYear();
  }

  onMirroringSubmit() {
    if (this._mMirrorFormGroup.valid) {
      const tripGenData = {
        site: this.dialogData.site,
        shift: this.dialogData.shift,
        requiesteddate: this.dialogData.requestedDate,
        referenceDate: this.formatDate(this._Rdate.value),
        referenceShift: this._Rtime.value,
        referenceMirroringType: this._RMirrorType.value.value,
        //referenceMirroringType: "MIRROR_MANUAL",
        vehicleTypes: null
      };
      this.serviceSharedData.setData(ServiceSharedData.MIRRORGENDATA.tripGenData, tripGenData);
      const dialogCloseRefData = {
        status: 'onMirroringSubmit',
        shiftId: this.dialogData.shift.SHIFTID
      }
      this.dialogRef.close(dialogCloseRefData);
      this.router.navigate(["./myatom/routing/tripmirror"]);
    }
  }

  intelligenceRouting() {
    const tripGenData = {
      site: this.dialogData.site,
      shift: this.dialogData.shift,
      requiesteddate: this.dialogData.requestedDate, // + " 00:00:00",
      referenceMirroringType: 'IR',
      vehicleTypes: null
    };
    this.serviceSharedData.setData(ServiceSharedData.MIRRORGENDATA.tripGenData, tripGenData);
    this.dialogRef.close();
    this.router.navigate(["/myatom/routing/tripmirror"]);
  }

  routeCompare() {
    let tripData;
    if (this.dialogData) {
      tripData = {
        requestedDate: this.dialogData.requestedDate,
        site: this.dialogData.site,
        shift: this.dialogData.shift
      };
      this.dialogRef.close();
      this.router.navigate(["/myatom/routing/routecompare/", this.dialogData.site, this.dialogData.shift, this.dialogData.requestedDate]);
    }
  }

}
