import { Component, QueryList, ViewChildren } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { ApiService } from 'src/app/core/services/api.service';
import { ServiceExportExcel } from 'src/app/core/services/export.service';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-on-call-trips',
  templateUrl: './on-call-trips.component.html',
  styleUrls: ['./on-call-trips.component.scss']
})
export class OnCallTripsComponent {

  public _mFormGroup: FormGroup;
  public location: any = new FormControl('');
  public site = new FormControl('');
  public fromDate = new FormControl('');
  reportsViewFlag: boolean = false;
  _mSitesData: any[] = [];
  locationList: any[] = [];
  mData: any[] = [];

  dataSource = new MatTableDataSource<any>(this.mData);
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  displayedColumns = ['tripCode', 'employeeId', 'name', 'reportingTime', 'purpose', 'fromLocation', 'toLocation', 'startTime', 'punchIn', 'punchOut', 'duration','status'];
  activeTabId = 1;
  constructor(private readonly apiService: ApiService,
    public notifyService: NotificationService,
    private fb: FormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private readonly serviceExportExcel: ServiceExportExcel
  ) {
    this._mFormGroup = this.fb.group({
      'location': this.location,
      'site': this.site,
      'fromDate': this.fromDate
    });
  }

  ngOnInit(): void {
    this.loadCities();
  }

  loadCities() {
    this.ngxLoader.start();
    this.apiService.doGetRequestWithResponse(ApiConstants.getserchlocationlist, (success) => {
      this.ngxLoader.stop();
      if (success.status == 200) {
        this.locationList = success.body;
      } else {
        this.locationList = []
      }
    },
      error => {
        this.ngxLoader.stop();
        console.log(error.message);
      }
    );
  }

  getSitesBylocId() {
    this._mSitesData = [];
    this.apiService.doGetRequestWithResponse(ApiConstants.getSiteByLocationId + this.location.value, (success) => {
      if (success.status == 200) {
        this._mSitesData = success.body.dataList.content
      } else {
        this._mSitesData = [];
      }
    }, (error) => {
    })
  }

  formatDate(d): any {
    let m = d.getMonth() + 1;
    let dt = (d.getDate() < 10) ? '0' + d.getDate() : d.getDate();
    let mm = (m < 10) ? '0' + m : m;
    return d.getFullYear() + '-' + mm + '-' + dt;
  }

  generateReport(flag) {
    if (this.location.value) {
      if (this.site.value) {
        if (this.fromDate.value) {
          this.apiService.doGetRequestWithResponse(ApiConstants.oncallTrips + this.site.value + '&date=' + this.formatDate(this.fromDate.value), (success) => {
            this.ngxLoader.stop();
            if (success.status == 200) {
              this.reportsViewFlag = true;
              let locarr: any[] = [];
              success.body.forEach(element => {
                locarr.push({
                  "tripCode": element.tripCode,
                  "employeeId": element.employeeId,
                  "name": element.name,
                  "reportingTime": element.reportingTime,
                  "fromLocation": element.fromLocation,
                  "toLocation": element.toLocation,
                  "punchIn": element.punchIn,
                  "punchOut": element.punchOut,
                  "duration": element.duration,
                  "purpose": element.purpose,
                  "startTime": element.startTime,
                  "date": element.date,
                  "status": element.status
                });
              });
              this.mData = locarr;
              this.dataSource.data = locarr;
              setTimeout(() => this.dataSource.paginator = this.paginator.toArray()[0]);
            } else {
              this.reportsViewFlag = false;
              this.mData = [];
              this.notifyService.showInfo("No data found", null);
            }
          }, (error) => {
            this.ngxLoader.stop();
          });
        } else {
          this.notifyService.showWarning("Please select date", null);
        }
      } else {
        this.notifyService.showWarning("Please select site", null);
      }
    } else {
      this.notifyService.showWarning("Please select location", null);
    }
  }

  reset() {
    this.location.setValue('');
    this.site.setValue('');
    this.fromDate.setValue('');
    this.reportsViewFlag = false;
    this.ngOnInit();
  }

  onExportToExcel() {
    if (this.mData != null && this.mData.length > 0) {
      let reportsData = [];
      this.ngxLoader.start();
      this.mData.forEach(reportData => {
        let fileData = {
          TripCode: reportData.tripCode,
          Date: reportData.date,
          EmployeeId: reportData.employeeId,
          EmployeeName: reportData.name,
          ReportingTime: reportData.reportingTime,
          Purpose: reportData.purpose,
          FromLocation: reportData.fromLocation,
          ToLocation: reportData.toLocation,
          PunchIn: reportData.punchIn,
          PunchOut: reportData.punchOut,
          StartTime: reportData.startTime,
          Duration: reportData.duration,
          Status: reportData.status
        }
        this.ngxLoader.stop();
        reportsData.push(fileData);
      });
      this.ngxLoader.stop();
      this.serviceExportExcel.exportAsExcelFile(reportsData, 'On_Call_Trips_Report');
    } else {
      this.ngxLoader.stop();
      this.notifyService.showInfo("No data found.", null);
    }
  }

}
