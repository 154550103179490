import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { RouterConstants } from '../constants/router.constants';
import { ServiceLocalStorage } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  AUTH_TOKEN = "auth-token";
  authToken: any = undefined;

  constructor(
    private router: Router,
    private readonly serviceLocalStorage: ServiceLocalStorage
    ) { }

  checkUserlogin() {
    const jwt: any = this.serviceLocalStorage.getAuthToken();
    if (jwt === null || jwt === undefined) {
      return false;
    } else {
      const helper = new JwtHelperService();
      return !helper.isTokenExpired(jwt);
    }
  }

  logout(): void {
    this.serviceLocalStorage.deleteAll();
    this.router.navigate(['/' + RouterConstants.client]);
  }

}
