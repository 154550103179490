// import { Component } from '@angular/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { AppConstants } from 'src/app/core/constants/appconstants';
import { ApiService } from 'src/app/core/services/api.service';
import { ServiceExportExcel } from 'src/app/core/services/export.service';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-zone-shift-mapping',
  templateUrl: './zone-shift-mapping.component.html',
  styleUrls: ['./zone-shift-mapping.component.scss']
})
export class ZoneShiftMappingComponent implements OnInit {


  public _mFormGroup: FormGroup;
  public mCity = new FormControl('');
  public mSite = new FormControl('');
  public mFilterType = new FormControl('');
  public mFilterTypeValue = new FormControl('');
  public mRouteType = new FormControl('');

  showBy = ['SHIFT TIME'];
  routetype: any = [];
  masterZones = [];
  shifts = [];
  show: boolean;
  dropdownlist: any = [];
  sites: any = [];
  cities: any = [];
  companyid: number = +this.localStorage.getCompanyId();
  selectedSite: any = '';
  rows = [];
  pushedValues: any[] = [];
  unPushedValues: any[] = [];
  selectedtype: string;
  selectedValues: any = [];
  shiftmapping: any = [];
  selroutetype: any = '';
  cityId: any;
  selectedcity: any = '';
  selectedval: any = '';

  cityFlag: boolean = true;
  siteFlag: boolean = false;


  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    private readonly serviceExportExcel: ServiceExportExcel,
    private readonly localStorage: ServiceLocalStorage,
    private ngxLoader: NgxUiLoaderService
  ) {
    this._mFormGroup = this.fb.group({
      'city': this.mCity,
      'site': this.mSite,
      'filterType': this.mFilterType,
      'filterTypeValue': this.mFilterTypeValue,
      'routeType': this.mRouteType
    });
  }

  ngOnInit() {
    this.show = false;
    this.loadRoutePriority();
    if (this.route.snapshot.queryParamMap.get('zoneid') != null) {
      this.selectedtype = this.showBy[0];
      let zone = this.route.snapshot.queryParamMap.get('zoneid')
      this.getZoneByZoneid(zone);
    }
    this.loadCities();


  }

  loadCities() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getLocationsByCompanyIdAndUser + this.companyid, success => {
      this.cityFlag = false;
      this.cities = success.body;
    }, (error) => {



    });
  }

  loadRoutePriority() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getAllRoutePriority, success => {
      if (success.status == 200) {
        this.routetype = success.body;
      }
    }, (error) => {

    });
  }

  // showlist(param) {
  //   if (param.value == this.showBy[0]) {
  //     this.dropdownlist = this.shifts;
  //     this.selectedtype = this.showBy[0];
  //   }
  // }

  getSitesByCityId(cityId) {
    this.mSite.setValue("");
    this.mFilterType.setValue("");
    this.mFilterTypeValue.setValue("");
    this.mRouteType.setValue("");
    this.resetvalues();
    this.cityId = cityId;
    if (this.cityId != undefined) {
      if (this.cityId != '') {
        this.siteFlag = true;
        this.apiService.doGetRequestWithResponse(ApiConstants.getSitesByLocationId + cityId, (success) => {
          this.siteFlag = false;
          if (success != null) {
            this.sites = success.body.dataList.content;
          } else {
            this.sites = null;

          }
        },
          (error) => {
          });
      }

    }
  }
  onsitechange(event) {
    if (event !== undefined) {
      if (event !== '') {
        this.mFilterType.setValue("");
        this.mFilterTypeValue.setValue("");
        this.mRouteType.setValue("");
        this.selectedSite = '';
        this.selectedSite = event;
        this.resetvalues();
        this.getMasterZone();
        this.getShiftTime();
        this.getZoneShiftMapping(0, true);
      }
    }
  }

  getMasterZone() {
    this.masterZones = [];
    this.apiService.doGetRequestWithResponse(ApiConstants.masterZone + this.selectedSite, (success) => {
      if (success.status == 200) {
        success.body.forEach(mzone => {
          this.masterZones.push({
            id: mzone.id,
            value: mzone.name
          })
        });
      } else {
        this.masterZones = [];
      }
    }, (error) => {

      this.masterZones = [];
    })
  }

  getShiftTime() {
    this.shifts = [];
    this.apiService.doGetRequestWithResponse(ApiConstants.getShiftsBySiteAndType + this.selectedSite + '&shifttype=' + "General", (success) => {
      if (success != null) {
        success.body.forEach(shift => {
          this.shifts.push({
            id: shift.shiftTimeId,
            value: shift.shiftTime + " " + shift.logType
          })
        });
        this.dropdownlist = this.shifts;
        this.selectedtype = this.showBy[0];
        this.mFilterType.setValue(this.showBy[0]);
      }
    }, (error) => {
      this.shifts = [];
    })
  }

  showMapping(selectedValue, selroutetype) {
    this.show = true;
    this.pushedValues = [];
    this.unPushedValues = [];
    this.rows = [];
    if (this.mCity.value == null) {
      this.notifyService.showInfo("Please select the city", null);
    } else {
      if (this.mSite.value == null) {
        this.notifyService.showInfo("Please select the site", null);
      } else {
        if (this.mFilterType.value == null) {
          this.notifyService.showInfo("Please select the filter type", null);
        } else {
          if (this.mFilterTypeValue.value == null) {
            this.notifyService.showInfo("Please select filter type value", null);
          } else {
            if (this.mRouteType.value == undefined) {
              this.notifyService.showInfo("Please select route type", null);
            } else
              if (selectedValue != undefined && selectedValue.length > 0) {
                this.selroutetype = selroutetype;
                this.selectedValues = selectedValue;
                this.pushedValues = [];
                this.unPushedValues = [];
                this.rows = [];
                if (this.selectedtype != undefined) {
                  if (this.selectedtype == this.showBy[0]) {
                    this.rows = this.masterZones;
                    if (selectedValue.length == 1) {
                      this.getMappingByShift(selectedValue[0], selroutetype);
                      this.getZoneShiftMapping(selectedValue[0], false);
                    } else {
                      this.getZoneShiftMapping(0, true);
                    }
                  } else if (this.selectedtype == this.showBy[1]) {
                    this.rows = this.masterZones;
                    if (selectedValue.length == 1) {
                      this.getMappingByShift(selectedValue[0], selroutetype);
                    }
                  }
                }
              }
          }
        }
      }
    }
  }
  getMappingByZoneid(zone) {
    this.unPushedValues = [];
    this.pushedValues = [];

    this.apiService.doGetRequestWithResponse(ApiConstants.getbyid + '?zoneid=' + zone, (success) => {
      if (success.status == 200) {
        success.body.zoneShifts.forEach(element => {
          if (this.selroutetype == element.type) {
            let sel = {
              id: element.shift.shiftTimeId,
              value: element.shift.shiftTime + " " + element.shift.logType
            };
            this.pushedValues.push(sel);
            this.rows = this.rows.filter(r => r.id !== sel.id);
            this.unPushedValues.push(sel);
            this.unPushedValues.forEach(x => {
              x.state = 'checked'
            });
          }
        });

      }

    }, (error) => {

    })
  }
  getMappingByShift(shift, selroutetype) {
    this.pushedValues = [];
    this.unPushedValues = [];
    this.apiService.doGetRequestWithResponse(ApiConstants.getzonebyshift + '?shiftid=' + shift + '&siteid=' + this.selectedSite, (success) => {
      if (success.status == 200) {
        success.body.forEach(element => {
          element.zoneShifts.forEach(zoneShift => {
            if (zoneShift.shift.shiftTimeId == shift && zoneShift.type == selroutetype) {
              let sel = {
                id: element.id,
                value: element.name,
              };
              this.pushedValues.push(sel);
              this.rows = this.rows.filter(r => r.id !== sel.id);
              this.unPushedValues.push(sel);
              this.unPushedValues.forEach(x => {
                x.state = 'checked'
              });
            }
          })
        });
      }

    }, (error) => {


    });
  }

  isAllChecked() {
    return this.rows.every(_ => _.state);
  }



  checkAll(checks) {
    if (checks.checked) {
      this.rows.forEach(x => {
        x.state = checks.checked
        this.onValueChecked({ checked: true }, x);
      })
    }
  }

  unCheckAll(checks) {
    if (!checks.checked) {
      this.unPushedValues.forEach(x => {
        x.state = checks.checked
        this.onSelValuesChecked({ checked: false }, x);
      })
    }
  }

  onSelValuesChecked(checked, unselect) {
    if (!checked.checked) {
      this.rows.push(unselect);
      this.unPushedValues = this.unPushedValues.filter(r => r.id !== unselect.id);
      this.pushedValues.push(unselect);
    }
  }
  onValueChecked(checked, sel) {
    if (this.pushedValues.length < 1) {
      if (checked.checked) {
        this.pushedValues.push(sel);
        this.rows = this.rows.filter(r => r.id !== sel.id);
        this.unPushedValues.push(sel);
      }
    } else {
      this.notifyService.showWarning("Sorry, Only one master zone can map to shift time", null);
    }
  }


  onSelValueChecked(checked, unselect) {
    if (!checked.checked) {
      this.rows.push(unselect);
      this.unPushedValues = this.unPushedValues.filter(r => r.id !== unselect.id);
      this.pushedValues = [];
    }
  }

  resetvalues() {
    this.pushedValues = [];
    this.unPushedValues = [];
    this.rows = [];
    this.selectedtype = undefined;
  }

  saveMapping() {
    if (this.selectedtype == undefined) {
      this.notifyService.showInfo("Please select mapping type", null);
    } else if (this.selectedValues.length < 1) {
      this.notifyService.showInfo("please select zone/shift time ", null);
    } else if (this.selroutetype == undefined) {
      this.notifyService.showInfo("please select routet type ", null);
    } else {
      let flag = true;
      if (this.unPushedValues.length == 0) {
        flag = false;
        if (confirm("Do you want to remove mapping?")) {
          flag = true;
        }
      }
      if (flag) {

        let zoneids = [];
        let shiftids = []
        let pushedids = [];
        let selectedid = [];
        this.unPushedValues.forEach(pushid => {
          pushedids.push(+pushid.id);
        });
        this.selectedValues.forEach(selectid => {
          selectedid.push(+selectid);
        });
        if (this.selectedtype == this.showBy[0]) {
          zoneids = pushedids;
          shiftids = selectedid;
        } else if (this.selectedtype == this.showBy[1]) {
          zoneids = pushedids;
          shiftids = selectedid;
        }

        this.apiService.doPostRequestWithResponse(ApiConstants.updateshiftmap + zoneids + '&shiftids=' + shiftids + '&type=' + this.selroutetype, '', (success) => {

          if (success.status == 202) {
            this.notifyService.showSuccess("Mapping updated successfully!", null);
            this.ngOnInit();
            this.resetvalues();
            this.mCity.setValue("");
            this.mSite.setValue("");
            this.mFilterType.setValue("");
            this.mFilterTypeValue.setValue("");
            this.pushedValues = [];
            this.unPushedValues = [];
            this.rows = [];
            this.selectedtype = undefined;
            this.shiftmapping = [];
          }
        }, (error) => {

        });
      }
    }
  }
  getZoneShiftMapping(shift, flag) {
    // let shiftId = this.mFilterTypeValue.value ? this.mFilterTypeValue.value : 0;
    this.apiService.doPostRequestWithResponse(ApiConstants.getzoneshiftmap + '?siteid=' + this.mSite.value + '&shiftId=' + shift, '', (success) => {
      if (success.status == 200) {
        this.shiftmapping = success.body;
      } else if (success.status = 204) {
        this.notifyService.showError("Mapping not found", null);
      } else {
        this.notifyService.showError("Error occured", null);
      }
    }, (error) => {

    })
  }

  exportAsXLSX(): void {
    this.serviceExportExcel.exportAsExcelFile(this.shiftmapping, 'MasterZoneShiftMapping');
  }

  getZoneByZoneid(zone) {
    this.apiService.doGetRequestWithResponse(ApiConstants.getbyid + '?zoneid=' + zone, (success) => {
      if (success.status == 200) {
        this.selectedSite = success.body.site;
        this.getShiftTime();
        this.notifyService.showSuccess("Showing stored mapping", null);
        setTimeout(() => {
          this.showMapping([zone], 'P');
        }, 1000);
      }
    }, (error) => {

    })
  }

  removeMapping(data, status) {
    console.log(data, status)
    let msg: any;
    let msgKey: any;
    if (status == 'Removed') {
      msg = "WARNING: Are you sure you want to remove Mapping?";
      msgKey = status;
    } 
    data.status = msgKey;
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger ms-2'
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      title: msg,
      text: status,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
    }).then(result => {
      if (result.value) {
        this.apiService.doPostRequestWithResponse(ApiConstants.removeZoneShift + data.zoneShiftId,'', (success) => {
          if (success.status == 200) {
            let successmsg = "Mapping removed successfully";
            this.getZoneShiftMapping(0, true);
          } else {
            let successmsg = "Failed to removed mapping";
          }
        }, (error) => {
    
        })
        swalWithBootstrapButtons.fire('Done', 'Mapping removed successfully', 'success');
      } else {
        swalWithBootstrapButtons.fire('Cancelled', 'Failed to removed mapping', 'error');
        if (data.status == AppConstants.ACTIVE_STATUS) {
          data.status = AppConstants.DEACTIVE_STATUS;
        } else {
          data.status = AppConstants.ACTIVE_STATUS;
        }
      }
    });
  }
}