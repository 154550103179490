<div fxLayout="row" fxLayoutAlign="start center">
    <h4 class="ms-2 font-size-14" fxFlex>Map</h4>
    <button mat-icon-button color="accent" (click)="close()">
        <mat-icon>clear</mat-icon>
    </button>
</div>

<div fxLayout="column">
    <input id="pac-input" class="controls" type="text" placeholder="Search your
      place here" *ngIf="!isOSMFalg">
    <div id="map"></div>
</div>