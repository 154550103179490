import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-excel-error-display',
  templateUrl: './upload-excel-error-display.component.html',
  styleUrls: ['./upload-excel-error-display.component.scss']
})
export class UploadExcelErrorDisplayComponent {

  mData: any;
  commentFlag: boolean;
  constructor(public dialogRef: MatDialogRef<UploadExcelErrorDisplayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      this.mData = data;
      if(this.mData[0].comments)
      {
        this.commentFlag = true;
      }
      else
      {
        this.commentFlag = false;
      }
    }
  }

  ngOnInit() {

  }

  onClickCancel(): void {
    this.dialogRef.close();
  }
}

