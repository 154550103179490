import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { AppConstants } from 'src/app/core/constants/appconstants';
import { ApiService } from 'src/app/core/services/api.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ServiceSharedData } from 'src/app/core/services/shared-data.service';
import { ExceptionComponent } from './exception/exception.component';
import { ShowCategoryComponent } from './show-category/show-category.component';
import { TripMirrorMapComponent } from './trip-mirror-map/trip-mirror-map.component';
import { ServiceExportExcel } from 'src/app/core/services/export.service';

@Component({
  selector: 'app-trip-mirror',
  templateUrl: './trip-mirror.component.html',
  styleUrls: ['./trip-mirror.component.scss']
})
export class TripMirrorComponent implements OnInit {
  referenceIRType: any = true;
  buttonFlag: any = true;
  showHideAddress: boolean = true;
  showHideAL: boolean = false;
  nightFlag: boolean = false;
  tripMirroringSetupData: any;
  tripGenData: any;
  tripData: any;
  exceptionList: any = [];
  tripGenDto: any = [];
  venVehData: any[] = [];
  localDataVehicleArr: any[] = [];
  localDataDriverArr: any[] = [];
  displayedRows$: any[] = [];
  additionalUserArr: any = [];
  userList: any[];
  userListFiltered: any[];
  vehicleListData: any[] = [];
  zoneArrayInit: any[] = [];
  saveTripData: any = [];
  vendorList: any;
  _mVehicleTypes: any;
  masterFlag: boolean;
  _mflag: any;
  searchField: any;
  totalEscort: number = 0;
  exceldata: any = [];

  constructor(
    private readonly apiService: ApiService,
    private serviceSharedData: ServiceSharedData,
    private notifyService: NotificationService,
    private ngxLoader: NgxUiLoaderService,
    private router: Router,
    private dialog: MatDialog,
    private serviceExportExcel: ServiceExportExcel,
  ) { }

  ngOnInit() {
    this.tripGenData = this.serviceSharedData.getData(ServiceSharedData.MIRRORGENDATA.tripGenData);
    if (!this.tripGenData) {
      this.router.navigate(["/myatom/routing/trips"]);
    } else {
      this.getMirroringSetup();
      this.isNightShift();
    }
  }

  onClickBack() {
    this.serviceSharedData.setData(ServiceSharedData.TRIP_GENERATION.tripViewFlag, true);
    this.router.navigate(["/myatom/routing/trips"]);
  }

  filterUsers(e) {
    let res = this.additionalUserArr.filter(u => {
      return u.al.area.area.toLowerCase().includes(e.toLowerCase()) || u.al.landmark.toLowerCase().includes(e.toLowerCase());
    });
    this.userList = res;
  }

  isNightShift() {
    let tripGenDto = {
      referenceDate: this.tripGenData.referenceDate + " 00:00:00",
      referenceMirroringType: this.tripGenData.referenceMirroringType,
      referenceShiftId: this.tripGenData.shift.SHIFTID,
      requiesteddate: this.tripGenData.requiesteddate + " 00:00:00",
      shiftId: this.tripGenData.shift.SHIFTID,
      siteId: this.tripGenData.site.siteId
    };
    this.apiService.doPostRequestWithResponse(ApiConstants.isNightShift, tripGenDto, success => {
      if (success.status == 200) {
        this.nightFlag = success.body;
      }
    }, error => {
      console.log(error)
    });
  }

  getMirroringSetup() {
    this.apiService.doGetRequestWithResponse(ApiConstants.tripTemplate + this.tripGenData.site.siteId, sucess => {
      if (sucess.status == 200) {
        this.getAllTrips();
        this.getAllVendorsBasedOnSite();
        this.getAllVehicleType();
        if (sucess.body.status == 'Active') {
          this.tripMirroringSetupData = sucess.body;
          if (this.tripGenData.referenceMirroringType == "IR") {
            this.referenceIRType = false;
          } else { this.referenceIRType = true; }
        } else {
          this.tripMirroringSetupData = null;
        }
      } else {
        this.getAllVehicleType();
        this.getAllTrips();
      }
    }, error => {
      console.log(error)
    })
  }

  getAllTrips() {
    this.ngxLoader.start();
    let reqData;
    if (this.tripGenData.referenceMirroringType == "IR") {
      reqData = {
        referenceMirroringType: this.tripGenData.referenceMirroringType,
        requiesteddate: this.tripGenData.requiesteddate + " 00:00:00",
        shiftId: this.tripGenData.shift.SHIFTID,
        siteId: this.tripGenData.site.siteId,
      };
    } else {
      reqData = {
        referenceDate: this.tripGenData.referenceDate + " 00:00:00",
        referenceMirroringType: this.tripGenData.referenceMirroringType,
        referenceShiftId: this.tripGenData.referenceShift.shiftTimeId,
        requiesteddate: this.tripGenData.requiesteddate + " 00:00:00",
        shiftId: this.tripGenData.shift.SHIFTID,
        siteId: this.tripGenData.site.siteId
      };
    }
    this.apiService.doPostRequestWithResponse(ApiConstants.generateMirroring, reqData, success => {
      if (success.status == 200) {
        this.tripData = success.body.bookings;
        this.exceptionList = success.body.exceptionUsersList;
        this.tripGenDto = success.body.tripGenDto
        let tripVendorDetails = [];
        if (success.body.tripVendorDetails.length > 0) {
          this.buttonFlag = true;
          success.body.tripVendorDetails.forEach((element, i) => {
            if (element.vendorMaster != null && this.venVehData != null) {
              this.venVehData.forEach(data => {
                if (element.vendorMaster.id == data.vendorId) {
                  element.vehicleArrayList = data.vehicles;
                }
              });
            }
            if (element.vehicle != null) {
              element.vehicle = { id: element.vehicle.id, name: element.vehicle.name };
              this.localDataVehicleArr.push({ index: i, vehicleId: element.vehicle.id });
            } else {
              element.vehicleArrayList = [];
            }
            if (element.driver != null && element.vehicleArrayList != null) {
              element.vehicleArrayList.forEach(veh => {
                if (veh.id == element.vehicle.id) {
                  element.driverList = veh.drivers;
                }
              });
              element.driver = { id: element.driver.id, name: element.driver.name };
              this.localDataDriverArr.push({ index: i, driverId: element.driver.id });
            } else {
              element.driverList = [];
            }
            if ((this.tripMirroringSetupData == null) ? true : this.tripMirroringSetupData.vendorCopyStatus == 'No') {
              element.vendorMaster = null;
              element.vehicle = null;
              element.driver = null;
            } else if (this.tripMirroringSetupData.vehicleDriverCopyStatus == 'No') {
              element.vehicle = null;
              element.driver = null;
            }
            tripVendorDetails.push(element)
          });
        }
        this.displayedRows$ = tripVendorDetails;
        this.generateMirror();
      }
    }, error => {
      this.ngxLoader.stop();
      this.router.navigate(["/myatom/routing/trips"]);
    });
  }

  getAllVendorsBasedOnSite() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getAllVendorDropDownBasedOnSite + this.tripGenData.site.siteId, success => {
      if (success.status == 200) {
        this.vendorList = success.body;
        this.vendorList.forEach(vendor => {
          let shiftList: any[] = [];
          shiftList.push({ "shiftTimeId": this.tripGenData.shift.SHIFTID });
          this.apiService.doPostRequestWithResponse(ApiConstants.getVendorAssignedTrips + '?tripDate=' + this.tripGenData.requiesteddate + '&siteid=' +
            this.tripGenData.site.siteId + '&vendorMasterId=' + vendor.id, shiftList, (success) => {
              this.vehicleListData = success.body.vehicleList;
              this.venVehData.push({ vendorId: vendor.id, vehicles: this.vehicleListData });
            }, (error) => {
              console.log(error)
            });
        })
      } else if (success.status == 204) {
        this.notifyService.showInfo("No Vendor Found", null);
      }
    }, error => {
      console.log(error)
    });
  }

  getAllVehicleType() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getActiveVehicleTypes + "?flag=" + false, success => {
      if (success.status == 200) {
        this._mVehicleTypes = success.body;
        this._mVehicleTypes = this._mVehicleTypes.filter(vehicletype => vehicletype.status == AppConstants.ACTIVE_STATUS);
      } else if (success.status == 204) {
        this.notifyService.showInfo("No Vehicles Found", null);
      }
    }, error => {
      this.notifyService.showError(error.message, null);
    });
  }

  generateMirror() {
    this.userList = [];
    const users: any[] = [];
    let tripUsers: any[] = [];
    let tripUserIds = [];
    this.displayedRows$.forEach((trips, t) => {
      this.zoneArrayInit.push("Zone" + t);
      tripUsers = [];
      trips.tripDetails.tripUsers.forEach(tUsers => {
        if (tripUserIds.length == 0 || tripUserIds.indexOf(tUsers.tripUser.id) === -1) {
          tripUserIds.push(tUsers.tripUser.id);
          tripUsers.push({
            bookingId: tUsers.booking.bookingId,
            displayName: tUsers.tripUser.userInfoDetails.displayName,
            employeeId: tUsers.tripUser.userInfoDetails.employeeId,
            address: tUsers.tripUser.userInfoDetails.homePoint === null ? '' : tUsers.tripUser.userInfoDetails.homePoint.landmark,
            id: tUsers.tripUser.id,
            gender: tUsers.tripUser.userInfoDetails.gender,
            userColor: tUsers.tripUser.userInfoDetails.userCategory && tUsers.tripUser.userInfoDetails.userCategory.length > 0 ?
              tUsers.tripUser.userInfoDetails.userCategory[0].categories.colorCode : "",
            isSelected: false,
            landmark: tUsers.landmark,
            al: tUsers.tripUser.userInfoDetails.pickUpDrop,
            routedOrder: tUsers.routedOrder,
            site: this.displayedRows$.length > 0 ? this.displayedRows$[0].tripDetails.site : this.tripGenData.site,
            shift: this.displayedRows$.length > 0 ? this.displayedRows$[0].tripDetails.shift : this.tripGenData.shift
          });
        }
      });
      this.displayedRows$[t].tripDetails.pickdropPoint = trips.tripDetails.shift.logType == "IN" ?
        trips.tripDetails.tripUsers[0].landmark.area.area : trips.tripDetails.tripUsers[trips.tripDetails.tripUsers.length - 1].landmark.area.area;
      if (this.displayedRows$[t].tripDetails.pickdropPoint === 'Not Available') {
        if (this.displayedRows$[t].tripDetails.zone != null) {
          this.displayedRows$[t].tripDetails.pickdropPoint = this.displayedRows$[t].tripDetails.zone.name;
        } else if (this.displayedRows$[t].tripDetails.masterRouteName != null) {
          this.displayedRows$[t].tripDetails.pickdropPoint = this.displayedRows$[t].tripDetails.masterRouteName;
        }
      }
      this.displayedRows$[t].tripDetails.tripUsers = tripUsers;
      this.displayedRows$[t].tripDetails.isSelect = false;
      this.displayedRows$[t].vehicleList = this._mVehicleTypes;
      this.displayedRows$[t].tripDetails.uniqueTripId = t;
      if (trips.tripDetails.escortStatus) this.totalEscort++;
      this.displayedRows$[t].tripDetails.zone = trips.tripDetails.zone ? trips.tripDetails.zone : null
    });
    if (this.tripGenData.referenceMirroringType == "MIRROR_MANUAL") {
      this.tripData.forEach((user, i) => {
        users.push({
          bookingId: user.bookingId,
          displayName: user.subscription.subscribedUser.userInfoDetails.displayName,
          employeeId: user.subscription.subscribedUser.userInfoDetails.employeeId,
          address: user.subscription.subscribedUser.userInfoDetails.homePoint === null ? '' : user.subscription.subscribedUser.userInfoDetails.homePoint.landmark,
          zone: user.zone,
          id: user.subscription.subscribedUser.id,
          gender: user.subscription.subscribedUser.userInfoDetails.gender,
          userColor: user.subscription.subscribedUser.userInfoDetails.userCategory[0] == null ? "#006aa8" : user.subscription.subscribedUser.userInfoDetails.userCategory[0].categories.colorCode,
          isSelected: false,
          landmark: user.subscription.subscribedUser.userInfoDetails.homePoint,
          al: user.subscription.subscribedUser.userInfoDetails.pickUpDrop,
          site: this.displayedRows$.length > 0 ? this.displayedRows$[0].tripDetails.site : this.tripGenData.site,
          shift: this.displayedRows$.length > 0 ? this.displayedRows$[0].tripDetails.shift : this.tripGenData.shift
        });
      });
      this.userList = users;
      this.userListFiltered = users;
      this.additionalUserArr = users.sort((a, b) => a.al.area.area.localeCompare(b.al.area.area));
    } else {
      this.tripData.forEach((user, i) => {
        users.push({
          bookingId: user.bookingId,
          displayName: user.subscription.subscribedUser.userInfoDetails.displayName,
          employeeId: user.subscription.subscribedUser.userInfoDetails.employeeId,
          address: user.subscription.subscribedUser.userInfoDetails.homePoint.landmark,
          id: user.subscription.subscribedUser.id,
          gender: user.subscription.subscribedUser.userInfoDetails.gender,
          userColor: user.subscription.subscribedUser.userInfoDetails.userCategory[0].categories.colorCode,
          isSelected: false,
          landmark: user.subscription.subscribedUser.userInfoDetails.homePoint,
          site: this.displayedRows$.length > 0 ? this.displayedRows$[0].tripDetails.site : this.tripGenData.site,
          shift: this.displayedRows$.length > 0 ? this.displayedRows$[0].tripDetails.shift : this.tripGenData.shift
        });
      });
      this.userListFiltered = users;
      this.userList = users;
      this.additionalUserArr = users.sort((a, b) => a.al.area.area.localeCompare(b.al.area.area));
    }
    this.ngxLoader.stop();
    this.validateTripVehicleCapacity();
  }

  showOrHideAddress(e) {
    if (e.checked) {
      this.showHideAddress = true;
    } else {
      this.showHideAddress = false;
    }
  }

  showOrHideAL(e) {
    if (e.checked) {
      this.showHideAL = true;
    } else {
      this.showHideAL = false;
    }
  }

  addNewTrip() {
    this.ngxLoader.start();
    const tripDetails: any = {};
    tripDetails.tripVendorDetails = [{
      id: null,
      tripDetails: {
        shift: this.displayedRows$.length > 0 ? this.displayedRows$[0].tripDetails.shift : this.userList[0].shift,
        tripCode: null,
        site: this.displayedRows$.length > 0 ? this.displayedRows$[0].tripDetails.site : this.userList[0].site,
        seatCapacity: null,
        seatsOccupied: null,
        seatsOccupiedPercent: null,
        isSelect: false,
        tripUsers: [],
        pickdropPoint: "",
        escortStatus: false,
        tripstatus: "newtrip"
      },
      vehicleType: {
        id: null,
        vehicleType: "",
        seatCapacity: 6,
        status: ""
      },
      vendorMaster: null,
      driver: {}
    }];
    this.displayedRows$.push(tripDetails.tripVendorDetails[0]);
    this.zoneArrayInit.push("Zone" + (this.displayedRows$.length - 1));
    this.ngxLoader.stop();
  }

  confirmAutoSequence() {
    if (confirm("Do you want to auto sequence the trip users?")) {
      return true;
    } else {
      return false;
    }
  }

  submitTrips() {
    let tripSaveData;
    let tripVendor = this.displayedRows$;
    let autoSequence = this.confirmAutoSequence();
    let exceptionBookings = [];
    this.userList.forEach(element => {
      exceptionBookings.push(element.bookingId);
    });
    this.exceptionList.forEach(element => {
      if (element.bookingId) {
        exceptionBookings.push(element.bookingId);
      }
    });
    if (this.tripGenData.referenceMirroringType === 'IR') {
      tripSaveData = {
        tripGenDto: {
          referenceMirroringType: this.tripGenData.referenceMirroringType,
          requiesteddate: this.tripGenData.requiesteddate + " 00:00:00",
          shiftId: this.tripGenData.shift.SHIFTID,
          siteId: this.tripGenData.site.siteId
        },
        tripVendorAssignList: this.displayedRows$,
        bookingIds: exceptionBookings
      };
    } else {
      tripSaveData = {
        tripGenDto: {
          referenceDate: this.tripGenData.referenceDate + " 00:00:00",
          referenceMirroringType: this.tripGenData.referenceMirroringType,
          referenceShiftId: this.tripGenData.shift.SHIFTID,
          requiesteddate: this.tripGenData.requiesteddate + " 00:00:00",
          shiftId: this.tripGenData.shift.SHIFTID,
          siteId: this.tripGenData.site.siteId
        },
        tripVendorAssignList: this.displayedRows$,
        bookingIds: exceptionBookings,
        autoSequence: autoSequence
      };
    }
    if (this.validateTrip(tripSaveData)) {
      this.masterFlag = false;
      this.saveTrips(tripSaveData);
    }
  }

  validateTrip(tripSaveData) {
    this._mflag = true;
    tripSaveData.tripVendorAssignList.forEach((trip, n) => {
      let index = n + 1;
      if (trip.tripDetails.tripUsers.length < 1) {
        this._mflag = false;
        this.notifyService.showWarning("Please add employee to Trip index " + index, null);
        return
      }
      trip.tripDetails.tripUsers.forEach((user, j) => {
        user.routedOrder = j + 1;
      });
      if (trip.vehicleType.id == null) {
        this._mflag = false;
        this.notifyService.showWarning("Please select vehicle type to Trip index " + index, null);
        return
      }
      if (trip.tripDetails.tripUsers.length > trip.vehicleType.seatCapacity) {
        this._mflag = false;
        this.notifyService.showWarning("Vehicle Capacity exceeded. Please recheck the Trip index " + index, null);
        return;
      }
      if (this.nightFlag && trip.tripDetails.tripUsers.length > 0) {
        if (trip.tripDetails.shift.logType == "IN") {
          if (trip.tripDetails.tripUsers[0].gender == "Female" && trip.tripDetails.escortStatus == false) {
            this._mflag = false;
            this.notifyService.showWarning("Please add escort to Trip index " + index, null);
          }
        } else {
          if (trip.tripDetails.tripUsers[trip.tripDetails.tripUsers.length - 1].gender == "Female" && trip.tripDetails.escortStatus == false) {
            this._mflag = false;
            this.notifyService.showWarning("Please add escort to Trip index " + index, null);
          }
        }
      }
    });
    return this._mflag;
  }

  validateExceptionEmps() {
    if (this.userList.length > 0) {
      if (confirm("Are you sure to save with exception employees? ")) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  saveTrips(tripSaveData) {
    if (this.validateExceptionEmps()) {
      tripSaveData.masterFlag = this.masterFlag;
      this.ngxLoader.start();
      this.apiService.doPostRequestWithResponse(ApiConstants.saveMirroring, tripSaveData, success => {
        this.ngxLoader.stop();
        if (success.status == 200) {
          this.serviceSharedData.setData(ServiceSharedData.TRIP_GENERATION.tripViewFlag, true);
          this.serviceSharedData.setData(ServiceSharedData.TRIP_GENERATION.saveTripMirroringFlag, true);
          this.notifyService.showSuccess(success.body.MSG, null);
          this.router.navigate(["/myatom/routing/trips"]);
        }
      }, error => {
        console.log(error)
        this.ngxLoader.stop();
      }
      );
    }
  }

  deleteForEver(item) {
    this.displayedRows$.forEach((element, index) => {
      if (index == item) this.displayedRows$.splice(index, 1);
    });
  }

  updateValue(event, cell, rowIndex) {
    let shiftList: any[] = [];
    shiftList.push({ "shiftTimeId": this.tripGenData.shift.SHIFTID });
    this.apiService.doPostRequestWithResponse(ApiConstants.getVendorAssignedTrips + '?tripDate=' + this.tripGenData.requiesteddate + '&siteid=' +
      this.tripGenData.site.siteId + '&vendorMasterId=' + event.value, shiftList, (success) => {
        this.displayedRows$[rowIndex].vehicleArrayList = success.body.vehicleList;
      }, (error) => {
        console.log(error)
      });
    if (cell == "vehicle") {
      this._mVehicleTypes.forEach(vehicle => {
        if (vehicle.id == event.value) {
          this.displayedRows$[rowIndex].vehicleType.seatCapacity =
            vehicle.seatCapacity;
          this.displayedRows$[rowIndex].vehicleType.id = vehicle.id;
        }
      });
    }
    if (this.vendorList) {
      this.vendorList.forEach(vendor => {
        if (vendor.id == event.value) {
          this.displayedRows$[rowIndex].vendorMaster = { id: vendor.id, name: vendor.name };
        }
      });
    }
    this.displayedRows$.forEach((trips, t) => {
      if (this.displayedRows$[rowIndex].tripDetails.uniqueTripId == trips.tripDetails.uniqueTripId) {
        if (this.displayedRows$[rowIndex].tripDetails.tripUsers.length +
          (trips.tripDetails.escortStatus ? 1 : 0) < this.displayedRows$[rowIndex].vehicleType.seatCapacity) {
          this.zoneArrayInit[t] = "Zone" + t;
        }
      }
    });
  }

  vehicleSearchSubmit(rowIndex, eventVehicle) {
    let empCount = 0;
    if (this.displayedRows$[rowIndex].tripDetails.escortStatus) { empCount = this.displayedRows$[rowIndex].tripDetails.tripUsers.length + 1; }
    else { empCount = this.displayedRows$[rowIndex].tripDetails.tripUsers.length; }
    let vehicleBool = true;
    let locVehicleArr = this.displayedRows$[rowIndex].vehicleArrayList;
    locVehicleArr.forEach(vehicle => {
      if (vehicle.id == eventVehicle.value) {
        if (empCount <= vehicle.vehicleType.seatCapacity) {
          if (vehicle.drivers.length > 0) {
            if (this.localDataVehicleArr.length > 0) {
              this.localDataVehicleArr.forEach(data => {
                if (data.vehicleId == eventVehicle.value && vehicle.drivers.length == 1 && data.index != rowIndex) {
                  vehicleBool = false;
                  let index = data.index + 1;
                  this.notifyService.showInfo("this vehicle is alerady assigned to index of " + index, null);
                  this.displayedRows$[rowIndex].vehicle = null;
                  this.displayedRows$[rowIndex].driverList = [];
                }
              });
            }
            if (vehicleBool) {
              this.displayedRows$[rowIndex].driverList = vehicle.drivers;
              this.displayedRows$[rowIndex].vehicle = { id: vehicle.id, name: vehicle.name };
              let dataCheck = true;
              this.localDataVehicleArr.forEach(dataVeh => {
                if (dataVeh.index == rowIndex) {
                  dataCheck = false;
                  dataVeh.vehicleId = eventVehicle.value;
                }
              })
              if (dataCheck) {
                this.localDataVehicleArr.push({ index: rowIndex, vehicleId: eventVehicle.value });
              };
            }
          } else {
            this.notifyService.showInfo("No Driver found", null);
            this.displayedRows$[rowIndex].vehicle = null;
            this.displayedRows$[rowIndex].driverList = [];
          }
        } else {
          this.notifyService.showInfo("Total employee is more than seat capacity of this vehicle", null);
          this.displayedRows$[rowIndex].vehicle = null;
          this.displayedRows$[rowIndex].driverList = [];
        }
      }
    });
  }

  driverSearchSubmit(rowIndex, driver) {
    let drivers = this.displayedRows$[rowIndex].driverList;
    if (drivers.length == 1) {
      let dataCheck = true;
      this.localDataDriverArr.forEach(datadri => {
        if (datadri.index == rowIndex) {
          dataCheck = false;
          datadri.driverId = driver.value;
        }
      })
      if (dataCheck) { this.localDataDriverArr.push({ index: rowIndex, driverId: driver.value }); }
      this.displayedRows$[rowIndex].driver = { id: drivers[0].id, name: drivers[0].name };
    } else {
      let locDriver = true;
      this.localDataDriverArr.forEach(driverData => {
        if (driverData.driverId == driver.value && driverData.index != rowIndex) {
          locDriver = false;
          let index = driverData.index + 1;
          this.notifyService.showInfo("this driver is alerady assigned to index of " + index, null);
          this.displayedRows$[rowIndex].driver = null;
        }
      });
      if (locDriver) {
        let dataCheck = true;
        this.localDataDriverArr.forEach(dataDri => {
          if (dataDri.index == rowIndex) {
            dataCheck = false;
            dataDri.driverId = driver.value;
          }
        })
        drivers.forEach(dri => {
          if (dri.id == driver.value) {
            this.displayedRows$[rowIndex].driver = { id: dri.id, name: dri.name };
          }
        })
        if (dataCheck) {
          this.localDataDriverArr.push({ index: rowIndex, driverId: driver.value });
        }
      }
    }
  }

  escortChange(index, event, item) {
    this.displayedRows$[index].tripDetails.escortStatus = event;
    if (!event) {
      this.totalEscort--;
      this.displayedRows$.forEach((trips, t) => {
        if (this.displayedRows$[index].tripDetails.uniqueTripId == trips.tripDetails.uniqueTripId) {
          if (this.displayedRows$[index].tripDetails.tripUsers.length +
            (trips.tripDetails.escortStatus ? 1 : 0) < this.displayedRows$[index].vehicleType.seatCapacity) {
            this.zoneArrayInit[t] = "Zone" + t;
          }
        }
      });
    }
    if (event) this.totalEscort++;
  }

  escortClick(trip) {
    if (trip.tripDetails.tripUsers.length == trip.vehicleType.seatCapacity) {
      this.notifyService.showInfo("Please remove anyone employee to enable escort", null);
    }
  }

  showCategory(): void {
    let dialogRef = this.dialog.open(ShowCategoryComponent, {
      data: "hello",
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  openMap(tripDetails) {
    if (tripDetails.tripDetails.tripUsers.length > 0) {
      let dialogRef = this.dialog.open(TripMirrorMapComponent, {
        data: tripDetails,
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
        }
      });
    } else {
      this.notifyService.showWarning("Please add users to trip", null);
    }
  }

  saveAllTrips() {
    let flag = true;
    let i = 1;
    for (let trip of this.displayedRows$) {
      if (!this.validateAllSaveTrips(trip, i)) {
        flag = false;
        break;
      }
      i++;
    }
    if (flag) {
      if (this.confirmAllSaveTrips()) {
        this.saveTripData = [];
        this.ngxLoader.start();
        this.displayedRows$.forEach((trip, mirrorIndex) => {
          let data = this.setAllTrips(trip, mirrorIndex + 1);
          this.saveTripData.push(data);
        });
        if (this.saveTripData.length > 0) {
          this.saveAllTempMirror(this.saveTripData);
        } else {
          this.notifyService.showWarning("Please add trips", null);
          this.ngxLoader.stop();
        }
      }
    }
  }

  validateAllSaveTrips(trip, index) {
    if (trip.tripDetails.tripUsers && trip.tripDetails.tripUsers.length == 0) {
      this.notifyService.showInfo("Please add users to trip no " + index, null);
      return false;
    }
    if (trip.vehicleType.id == null) {
      this.notifyService.showWarning("Please select vehicle type to trip no " + index, null);
      return false;
    }
    if (this.nightFlag && trip.tripDetails.tripUsers.length > 0) {
      if (trip.tripDetails.shift.logType == "IN") {
        if (trip.tripDetails.tripUsers[0].gender == "Female" && trip.tripDetails.escortStatus == false) {
          this.notifyService.showInfo("Please add escort to trip no " + index, null);
          return false;
        }
      } else {
        if (trip.tripDetails.tripUsers[trip.tripDetails.tripUsers.length - 1].gender == "Female" &&
          trip.tripDetails.escortStatus == false) {
          this.notifyService.showInfo("Please add escort to trip no " + index, null);
          return false;
        }
      }
    }
    return true;
  }

  confirmAllSaveTrips() {
    if (confirm("Are you sure you want to temporarily save all trips?")) {
      return true;
    } else {
      return false;
    }
  }

  setAllTrips(trip, mirrorIndex) {
    let employeeIds = [];
    trip.tripDetails.tripUsers.forEach(booking => {
      employeeIds.push(booking.employeeId);
    });
    let data = {
      siteId: this.tripGenData.site.siteId,
      referenceTripVendorId: trip.id,
      referenceTripMirrorId: mirrorIndex,
      referenceDate: this.tripGenData.referenceDate + " 00:00:00",
      referenceShiftId: this.tripGenData.referenceShift.shiftTimeId,
      generationDate: this.tripGenData.requiesteddate + " 00:00:00",
      generationShiftId: this.tripGenData.shift.SHIFTID,
      vendorMaster: trip.vendorMaster,
      vehicleType: trip.vehicleType,
      vehicle: null,
      driver: null,
      employeeIds: employeeIds,
      escort: trip.tripDetails.escortStatus
    };
    return data;
  }

  saveAllTempMirror(data) {
    this.apiService.doPostRequestWithResponse(ApiConstants.saveAllTripMirrorTemp, data, success => {
      if (success.status == 200) {
        // this.checkForExceptionEmployees(data, success.body.message);
        this.notifyService.showSuccess(success.body.message, null);
      } else {
        this.notifyService.showWarning("Trip failed to save", null);
      }
      this.ngxLoader.stop();
    }, error => {
      this.ngxLoader.stop();
      if (error.message == "Request Timed out, Please try again later") {
        this.notifyService.showInfo("Don't worry, trips are getting saved in the background...", null);
      }
    });
  }

  saveTrip(trip, mirrorIndex) {
    if (this.validateSaveTrip(trip)) {
      let employeeIds = [];
      trip.tripDetails.tripUsers.forEach(booking => {
        employeeIds.push(booking.employeeId);
      });
      let data = {
        siteId: this.tripGenData.site.siteId,
        referenceTripVendorId: trip.id,
        referenceTripMirrorId: mirrorIndex,
        referenceDate: this.tripGenData.referenceDate + " 00:00:00",
        referenceShiftId: this.tripGenData.referenceShift.shiftTimeId,
        generationDate: this.tripGenData.requiesteddate + " 00:00:00",
        generationShiftId: this.tripGenData.shift.SHIFTID,
        vendorMaster: trip.vendorMaster,
        vehicleType: trip.vehicleType,
        vehicle: null,
        driver: null,
        employeeIds: employeeIds,
        escort: trip.tripDetails.escortStatus
      };
      this.apiService.doPostRequestWithResponse(ApiConstants.saveTripMirrorTemp, data, success => {
        if (success.status == 200) {
          this.notifyService.showSuccess(success.body.message, null);
        } else {
          this.notifyService.showWarning("Trip failed to save", null);
        }
      }, error => {
        console.log(error)
      });
    }
  }

  validateSaveTrip(trip) {
    if (trip.tripDetails.tripUsers && trip.tripDetails.tripUsers.length == 0) {
      this.notifyService.showInfo("Please add users to trip", null);
      return false;
    }
    if (trip.vehicleType.id == null) {
      this.notifyService.showWarning("Please select vehicle type to trip", null);
      return false;
    }
    if (this.nightFlag && trip.tripDetails.tripUsers.length > 0) {
      if (trip.tripDetails.shift.logType == "IN") {
        if (trip.tripDetails.tripUsers[0].gender == "Female" && trip.tripDetails.escortStatus == false) {
          this.notifyService.showInfo("Please add escort to trip", null);
          return false;
        }
      } else {
        if (trip.tripDetails.tripUsers[trip.tripDetails.tripUsers.length - 1].gender == "Female" && trip.tripDetails.escortStatus == false) {
          this.notifyService.showInfo("Please add escort to trip ", null);
          return false;
        }
      }
    }
    return true;
  }

  validateTripVehicleCapacity() {
    let i = 1;
    for (let trip of this.displayedRows$) {
      if (trip.tripDetails.tripUsers.length > trip.vehicleType.seatCapacity) {
        this.notifyService.showInfo("Vehicle capacity exceeded for few trips", null);
        break;
      }
      i++;
    }
    return;
  }

  selectToMove(index, item) {
    this.displayedRows$.forEach(data => {
      data.tripDetails.isSelect = false;

    })
    this.displayedRows$[index].tripDetails.isSelect = true;
  }

  addUsersToTrip(user) {
    if (this.userList.length > 0) {
      let checkSelection = false;
      let vechileFull = false;
      this.displayedRows$.forEach(data => {
        if (data.tripDetails.isSelect) {
          let totalSeat = data.vehicleType.seatCapacity;
          let totalUser = data.tripDetails.escortStatus ? 1 : 0;
          totalUser = totalUser + data.tripDetails.tripUsers.length;
          if (totalSeat > totalUser) {
            checkSelection = true;
            data.tripDetails.tripUsers.push(user);
            this.tripGenDto.additionalUsers = this.tripGenDto.additionalUsers - 1;
            this.tripGenDto.mirroredUsers = this.tripGenDto.mirroredUsers + 1;
          } else {
            vechileFull = true;
            this.notifyService.showInfo("Vehicle is already full", null);
          }
        }
      });
      if (checkSelection && !vechileFull) {
        this.userList.forEach((element1, index) => {
          if (element1 == user) this.userList.splice(index, 1);
        });
      }
      if (!checkSelection && !vechileFull) {
        this.notifyService.showWarning("Please select any checkbox", null);
      }
    }
  }

  removeUserFromTrips(emp, item) {
    emp.isSelected = false;
    item.forEach((element1, index) => {
      if (element1 == emp) item.splice(index, 1);
    });
    let temp = this.userList;
    this.userList = [];
    this.userList.push(emp);
    temp.forEach(data => {
      this.userList.push(data);
    })
    this.additionalUserArr = this.userList;
    this.tripGenDto.additionalUsers = this.tripGenDto.additionalUsers + 1;
    this.tripGenDto.mirroredUsers = this.tripGenDto.mirroredUsers - 1;
  }

  selectEmpForMove(emp, users) {
    users.forEach(data => {
      data.isSelected = false
    })
    emp.isSelected = true;
  }

  moveUser(users, ud) {
    let i;
    let isCheck = true;
    users.forEach((data, index) => {
      if (data.isSelected) {
        i = index;
        isCheck = false;
      }
    })
    if (isCheck) {
      this.notifyService.showWarning('Please first select any user', null);
    } else if (ud == 'd' && users.length == i + 1) {
      this.notifyService.showWarning(users[i].displayName + " can't go down", null);
    } else if (ud == 'u' && i == 0) {
      this.notifyService.showWarning(users[i].displayName + " can't go up", null);
    } else {
      this.moveUpDownUser(users, i, ud);
    }
  }

  moveUpDownUser(users, index, ud) {
    if (ud == 'u') {
      var tmp = users[index];
      users[index] = users[index - 1];
      users[index - 1] = tmp;
    } else {
      var tmp = users[index];
      users[index] = users[index + 1];
      users[index + 1] = tmp;
    }
  }

  onExceptionDetails() {
    if (this.exceptionList && this.exceptionList.length > 0) {
      let dialogRef = this.dialog.open(ExceptionComponent, {
        disableClose: true,
        height: '600px',
        width: '800px',
        data: this.exceptionList
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
        }
      });
    }
  }
  allExpandState: any;
  userDragEnd() { }

  onUserDropSuccess(u) { }

  userDragStarted(user) { }

  dragEndTrips(event, dragTrip) {
    // if (event == null) {
    //   this.displayedRows$.forEach((trips, t) => {
    //     if (dragTrip.tripDetails.uniqueTripId == trips.tripDetails.uniqueTripId) {
    //       if (dragTrip.tripDetails.tripUsers.length + (trips.tripDetails.escortStatus ? 1 : 0) >= trips.seatCapacity) {
    //         this.zoneArrayInit[t] = "";
    //       }
    //     }
    //   });
    // }
    // setTimeout(() => {
    //   this.ngxLoader.stop();
    // }, 1000);
  }

  onItemDrop(event, employee) { }

  dragStarted(dragTrip) { }

  downloadTripData() {
    this.exceldata = [];
    let number = 1;
    this.displayedRows$.forEach(element => {
      this.exceldata.push({
        tripIndex: element.tripDetails.uniqueTripId + 1,
        vendor: element.vendorMaster.name,
        pickupDropPoint: element.tripDetails.pickdropPoint,
        vehicleType: element.vehicleType.vehicleType,
        escort: element.tripDetails.escortStatus,
        seatCapacity: element.vehicleType.seatCapacity
      });
      this.exceldata.push({
        tripIndex: "EmployeeId",
        vendor: "EmployeeName",
        pickupDropPoint: "Address",
      });
      element.tripDetails.tripUsers.forEach(element1 => {
        this.exceldata.push({
          tripIndex: element1.employeeId,
          vendor: element1.displayName,
          pickupDropPoint: element1.address,
        });
      });
      if (number < this.displayedRows$.length) {
        this.exceldata.push({}, {
          tripIndex: "tripIndex",
          vendor: "vendor",
          pickupDropPoint: "pickupDropPoint",
          vehicleType: "vehicleType",
          escort: "escort",
          seatCapacity: "seatCapacity"
        });
      }
      number++;
    });
    this.serviceExportExcel.exportAsExcelFileForTrips(this.exceldata, "Mirrored_User");
  }

}
