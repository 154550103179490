import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SearchDialogBoxComponent } from './search-dialog-box/search-dialog-box/search-dialog-box.component';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-context-search',
  templateUrl: './context-search.component.html',
  styleUrls: ['./context-search.component.scss']
})

export class ContextSearchComponent {
  contextSearch: boolean;
  employeeFlag: boolean;
  vehicleFlag: boolean;
  driverFlag: boolean;
  constructor(
    public dialogRef: MatDialogRef<ContextSearchComponent>,
    public dialog: MatDialog,
    private readonly localStorage: ServiceLocalStorage,
    private notifyService: NotificationService,
    private ngxLoader: NgxUiLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.data = data;
      if (this.data.filter == 'Employee') {
        this.employeeFlag = true;
        this.vehicleFlag = false;
        this.driverFlag = false;

      }
      if (this.data.filter == 'Vehicle') {
        this.employeeFlag = false;
        this.vehicleFlag = true;
        this.driverFlag = false;

      }
      if (this.data.filter == 'Driver') {
        this.employeeFlag = false;
        this.vehicleFlag = false;
        this.driverFlag = true;

      }
    }
  }
  
  ngOnInit(): void {
  }

  openDialog(data) {
    let flag;
    if (data.target.innerHTML == 'Past Schedules' || data.target.innerHTML == 'Past Trips') {
      flag = 'currents'
    }
    if (data.target.innerHTML == 'Upcoming Trips' || data.target.innerHTML == 'Current Schedules') {
      flag = 'current'
    }
    if (data.target.innerHTML == 'Address') {
      flag = 'address'
    }
    if (data.target.innerHTML == 'Geocode Change') {
      flag = 'geocode'
    }
    if (data.target.innerHTML == 'Running Trips') {
      flag = 'running'
    }
    let reqData = {
      flag: flag,
      option: data.target.innerHTML,
      empId: this.data.empId,
      filter: this.data.filter
    };
    let dialogRef = this.dialog.open(SearchDialogBoxComponent, {
      data: reqData,
      disableClose: true,

    });
  }

  onClickCancel(): void {
    this.dialogRef.close("");
  }


}
