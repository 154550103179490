import { Component, Inject, Input, OnInit } from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder
} from "@angular/forms";
import { ApiService } from "src/app/core/services/api.service";
import { NotificationService } from "src/app/core/services/notification.service";
import { ServiceExportExcel } from "src/app/core/services/export.service";
import { ServiceLocalStorage } from "src/app/core/services/local-storage.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { ApiConstants } from "src/app/core/constants/api-path.constants";

@Component({
  selector: "app-add-fixed-routing",
  templateUrl: "./add-fixed-routing.component.html",
  styleUrls: ["./add-fixed-routing.component.scss"]
})
export class AddFixedRoutingComponent implements OnInit {

  public _mFormGroup: FormGroup;
  // @Output("addCostCenter") addCostCenter: EventEmitter<any> = new EventEmitter();
  shiftTimes: any[];

  @Input() data: any = null;
  public mType = new FormControl("", Validators.required);
  public mRouteName = new FormControl("", Validators.required);
  public mRouteType = new FormControl("", Validators.required);
  public mRouteSearch = new FormControl("", Validators.required);
  public mAreaSearch = new FormControl("");
  public mLandmarkSearch = new FormControl("");
  public mLandmarkType = new FormControl("");

  _mRouteFlag: boolean = false;
  _mAreaFlag: boolean = false;
  _mLandmarkFlag: boolean = false;
  _mRoutesSearchFlag: boolean = false;
  _mLandmarkSearchFlag: boolean = false;
  _mAreaSearchFlag: boolean = false;
  _mRoutesData: any[] = [];
  _mTempRoutesData: any[] = [];
  _mLandmarksData: any[] = [];
  _mAreasData: any[] = [];
  _mSiteId: any;
  _mCityId: any;
  dropdownSettings = {};
  dropdownSettingsForArea = {};
  routeId: any;

  public mIsEdit = false;

  public _mTitle = "Add Fixed Routing";
  public _mTypes = [
    { value: "Route" },
    { value: "Area" },
    { value: "Landmark" }
  ];
  _mRouteTypes: any[] = [
    { key: "P", value: "Primary" },
    { key: "C", value: "Combined" },
    { key: "FC", value: "Fully Combined" }
  ];
  _mLandmarkTypes: any[] = [
    { key: "H", value: "Home" },
    { key: "C", value: "Common" }
  ];

  constructor(
    private fb: FormBuilder,
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    private readonly serviceExportExcel: ServiceExportExcel,
    private readonly localStorage: ServiceLocalStorage,
    public readonly dialog: MatDialog,
    private ngxLoader: NgxUiLoaderService,
    private router: Router,
    private route: ActivatedRoute,

  ) {
    this._mFormGroup = this.fb.group({
      type: this.mType,
      routeName: this.mRouteName,
      routeType: this.mRouteType,
      routeSearch: this.mRouteSearch,
      areaSearch: this.mAreaSearch,
      landmarkSearch: this.mLandmarkSearch,
      landmarkType: this.mLandmarkType
    });

    this.mRouteSearch.valueChanges.subscribe(searchValue => {
      if (this._mTempRoutesData != null) {
        this._mRoutesData = this._mTempRoutesData.filter(route =>
          route.routeName.toLowerCase().includes(searchValue.toLowerCase())
        );
      }
    });
  }

  ngOnInit() {
    let data = this.route.snapshot.params;
    if (data) {
      (this._mSiteId = data.siteId), (this._mCityId = data.cityId);
    }
    this.mType.setValue(this._mTypes[0].value);
    this._mRouteFlag = true;
    this.mRouteSearch.disable();
    this.mLandmarkSearch.disable();
    this.mAreaSearch.disable();
    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "landmark",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 1,
      allowSearchFilter: true
    };

    this.dropdownSettingsForArea = {
      singleSelection: false,
      idField: "id",
      textField: "area",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
  }

  onItemSelect(item: any) {
  }
  onSelectAll(items: any) {
  }

  getAllRoutes() {
    this._mRoutesSearchFlag = true;
    this.apiService.doGetRequestWithResponse(ApiConstants.getroutebysiteidandtype + '?siteid=' +
      this._mSiteId + '&type=' +
      "All",
      success => {
        this._mRoutesSearchFlag = false;
        if (success.status == 200) {
          this._mRoutesData = success.body;
          this._mTempRoutesData = success.body;
        } else {
          this._mRoutesData = [];
          this._mTempRoutesData = [];
          this.notifyService.showInfo("No data found", null);
        }
      },
      error => {
        this._mRoutesSearchFlag = false;

      }
    );
  }

  routeSearchSubmit(route) {
    this.routeId = route.id;
  }

  onLandmarkTypeChange() {
    this.getAllLandmarks();
  }

  getAllLandmarks() {

    this._mLandmarkSearchFlag = true;
    this.apiService.doGetRequestWithResponse(ApiConstants.getlandmarksbylocationidandtype + '?locationid=' +
      this._mCityId + '&type=' +
      this.mLandmarkType.value,
      success => {

        this._mLandmarkSearchFlag = false;
        if (success.status == 200) {
          this._mLandmarksData = success.body;
        } else {
          this._mLandmarksData = [];
          this.notifyService.showInfo("No data found", null);
        }
      },
      error => {

        this._mLandmarkSearchFlag = false;
      }
    );
  }

  getAllAreas() {
    this._mAreaSearchFlag = true;
    this.apiService.doGetRequestWithResponse(ApiConstants.getareabylocationid + '?locationid=' +
      this._mCityId,
      success => {

        this._mAreaSearchFlag = false;
        if (success.status == 200) {
          this._mAreasData = success.body;
        } else {
          this._mAreasData = [];
          this.notifyService.showInfo("No data found", null);
        }
      },
      error => {
        this._mAreaSearchFlag = false;

      }
    );
  }

  onTypeChange() {
    if (this.mType.value == "Route") {
      this._mTitle = "Add Route";
      this._mRouteFlag = true;
      this._mAreaFlag = false;
      this._mLandmarkFlag = false;
      this.mRouteName.enable();
      this.mRouteType.enable();
      this.mAreaSearch.disable();
      this.mLandmarkSearch.disable();
      this.mRouteSearch.disable();
    } else if (this.mType.value == "Area") {
      this.getAllRoutes();
      this.getAllAreas();
      this._mTitle = "Add Area";
      this._mRouteFlag = false;
      this._mAreaFlag = true;
      this._mLandmarkFlag = false;
      this.mAreaSearch.enable();
      this.mRouteSearch.enable();
      this.mLandmarkSearch.disable();
      this.mRouteName.disable();
      this.mRouteType.disable();
    } else if (this.mType.value == "Landmark") {
      this.getAllRoutes();
      this._mTitle = "Add Landmark";
      this._mRouteFlag = false;
      this._mAreaFlag = false;
      this._mLandmarkFlag = true;
      this.mLandmarkSearch.enable();
      this.mRouteSearch.enable();
      this.mRouteName.disable();
      this.mRouteType.disable();
    }
  }

  onCancel() {
    this.router.navigate(["./myatom/routing/routing-setup/fixed-routing-setup"]);
  }

  onSubmit() {
    if (this._mFormGroup.valid) {
      if (this._mRouteFlag) {
        let reqData = {
          routeName: this.mRouteName.value,
          site: {
            siteId: this._mSiteId
          },
          routeType: this.mRouteType.value
        };
        this.apiService.doPostRequestWithResponse(ApiConstants.addRoute, reqData, success => {
          if (success.status == 200) {
            this.notifyService.showSuccess("Route added successfully...", null);
            this.onCancel();
          }
        },
          error => {
            this.notifyService.showWarning('Route Name already exists ', null);
          }
        );
      } else if (this._mLandmarkFlag) {
        if (this.mLandmarkSearch.value.length > 0) {
          this.apiService.doPostRequestWithResponse(ApiConstants.addlandmarkstoroute + '?routeid=' + this.routeId, this.mLandmarkSearch.value, success => {
            if (success.status == 200) {
              this.notifyService.showSuccess(
                "Landmark added successfully...",
                null
              );
              this.onCancel();
            }
          },
            error => {
              this.notifyService.showWarning('Landmark name already exists ', null);
            }
          );
        } else {
          this.notifyService.showInfo("Please select landmark", null);
        }

      } else if (this._mAreaFlag) {
        if (this.mAreaSearch.value.length > 0) {
          this.apiService.doPostRequestWithResponse(ApiConstants.addareastoroute + '?routeid=' + this.routeId, this.mAreaSearch.value, success => {
              if (success.status == 200) {
                this.notifyService.showSuccess("Area added successfully...", null);
                this.onCancel();
              }
            },
            error => {
              this.notifyService.showWarning('Area name already exists ', null);
            }
          );
        } else {
          this.notifyService.showInfo("Please select area", null);
        }
      }
    }
  }
}
