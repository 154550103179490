<div *ngIf="!editFlag">
  <div class="plus-btn-property">
    <button type="button" class="btn add-btn-outline1" (click)="onAddNew()">
      <em class="bx bx-plus me-1"></em>
      Add Routing Setup
    </button>
  </div>

  <div class="row">
    <div class="col-xl-12">
      <div class="row mt-3" *ngIf="cityFlag">
        <div class="col-sm-3">
          <form [formGroup]="_mFormGroup">
            <label>City</label>
            <select class="form-select margin-right-8px" placeholder="City" formControlName="city"
              (change)="oncitychange()">
              <option disabled value=""> Choose Location </option>
              <option *ngFor="let city of cities" [value]="city.locationId">
                {{ city.locationName }}
              </option>
            </select>
          </form>
        </div>
      </div>
      <div class="col-sm-4 mt-5">
        <div class="d-flex justify-content-between" style="width: 250px;">
          <input class="form-control icon s-input b-0" type="text" style="font-size: 13px;"
            placeholder="Search by Site name" [(ngModel)]="searchData" (input)="onSearchKey(searchData)">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <app-basic-table [columnHeaders]="_mColumns" [tableData]="_mData" (onStatusChanged)="onStatusChanged($event)"
          [subcribeEdit]="subcribeEdit" (onEditIconClicked)="onEditIconClicked($event) " [isAuditLog]="auditFlag"
          (onAuditLog)="getAudit($event) ">
        </app-basic-table>
      </div>
    </div>

    <div class="row" *ngIf="_mData.length == 0 &&  _mDataRaw.length > 0">
      <div class="col-sm-12 col-md-12">
        <h4 class="tdw-text-align-center tdw-font-normal tdw-margin-4
              mat-color-text--blue-grey-600">
          --No Data Found --</h4>
      </div>
    </div>
  </div>
</div>
<div *ngIf="editFlag">
  <app-add-edit-routing-setup [userData]="userData" (addEditEvent)="getSetupView($event)"></app-add-edit-routing-setup>
</div>