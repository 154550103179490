<div>
    <div class="row mt-3" *ngIf="_mData.length == 0 && showFlag">
        <div class="col-sm-12 col-md-12">
            <h4 class="tdw-text-align-center tdw-font-normal tdw-margin-4
                                                                  mat-color-text--blue-grey-600">
                --No Data Found --</h4>
        </div>
    </div>

    <div class="row table-width" *ngIf="_mData.length > 0" style="border: none;">
        <div class="col-sm-12 col-md-12">
            <table mat-table [dataSource]="dataSource" *ngIf="(ELEMENT_DATA.length> 0)" class="table-width">
                <ng-container matColumnDef="site">
                    <th mat-header-cell *matHeaderCellDef class="min-width"> Site </th>
                    <td mat-cell *matCellDef="let element"> {{element.site}} </td>
                </ng-container>

                <ng-container matColumnDef="bookedBy">
                    <th mat-header-cell *matHeaderCellDef class="min-width"> Booked by </th>
                    <td mat-cell *matCellDef="let element"> {{element.bookedby}} </td>
                </ng-container>

                <ng-container matColumnDef="employeeId">
                    <th mat-header-cell *matHeaderCellDef class="min-width"> Employee Id </th>
                    <td mat-cell *matCellDef="let element"> {{element.employeeId}} </td>
                </ng-container>

                <ng-container matColumnDef="employeeName">
                    <th mat-header-cell *matHeaderCellDef class="min-width"> Employee Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
                </ng-container>

                <ng-container matColumnDef="traveldate">
                    <th mat-header-cell *matHeaderCellDef class="min-width"> Travel date </th>
                    <td mat-cell *matCellDef="let element"> {{element.travelDate}} </td>
                </ng-container>

                <ng-container matColumnDef="travelTime">
                    <th mat-header-cell *matHeaderCellDef class="min-width"> Travel time </th>
                    <td mat-cell *matCellDef="let element"> {{element.travelTime}} </td>
                </ng-container>

                <ng-container matColumnDef="vendor">
                    <th mat-header-cell *matHeaderCellDef class="min-width"> Vendor </th>
                    <td mat-cell *matCellDef="let element"> {{element.vendor}} </td>
                </ng-container>

                <ng-container matColumnDef="escort">
                    <th mat-header-cell *matHeaderCellDef class="min-width"> Escort </th>
                    <td mat-cell *matCellDef="let element"> {{element.escort}} </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th style="text-align: center;" mat-header-cell *matHeaderCellDef class="min-width"> Status
                    </th>
                    <td style="text-align: center !important; " mat-cell *matCellDef="let element">
                        {{element.status }}

                        <div *ngIf="element.status=='Approved' && _mCurrentUserVendorFlag">
                            <button class="playlist1-btn-solid m-1"  (click)="pendingStatusRequest(element,'EDIT')">Edit</button>
                        </div>

                        <div *ngIf="element.status=='SENT_FOR_VENDOR_APPROVAL' && _mCurrentUserVendorFlag">
                            <button *ngIf="element.vendorAccepted == null" class="playlist1-btn-solid m-1"  (click)="acceptTrip(element)">Accept</button>
                            <span *ngIf="element.vendorAccepted" style="padding: 5px;">Accepted</span>
                            <button class="playlist1-btn-solid m-1"  (click)="pendingStatusRequest(element,'EDIT')">Allocate</button>
                            <button class="playlist1-btn-solid m-1"  (click)="onRejectStatusRequest(element)">Send Back To Admin</button>
                        </div>

                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="_mColumns" class="tdw-bottom-line tdw-card-thin"></tr>
                <tr mat-row *matRowDef="let row; columns: _mColumns;"></tr>
            </table>
        </div>

    </div>
</div>