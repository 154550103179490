<div class="card">
  <ul ngbNav #nav="ngbNav" [activeId]="activeTabId" class="nav-tabs nav-tabs-custom g-nav-tabs-custom"
    style="padding-top: 7px;">
    <li [ngbNavItem]="1">
      <a ngbNavLink> Delete Vendor Allocation</a>
      <ng-template ngbNavContent>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="p-3"></div>
  <div class="card-body">
    <form class="example-form" [formGroup]="_mFormGroup">
      <div class="col-sm-12 col-md-2">
        <label>Site<sup class="mandatory">*</sup></label>
        <select class="form-select" placeholder="Site" formControlName="site">
          <option value="" disabled>Select site
          </option>
          <option *ngFor="let site of _mSitesData" [value]="site.siteId">
            {{ site.siteName }}
          </option>
        </select>
        <div class="error-msg" *ngIf="_mFormGroup.get('site').hasError('required') && _mFormGroup.get('site').touched">
          Please select site
        </div>
      </div>

      <div class="col-sm-12 col-md-2" style="padding-left: 15px;">
        <label>Date<sup class="mandatory">*</sup></label>
        <mat-form-field appearance="outline" class="custom-vendorDateField ">
          <input matInput [matDatepicker]="picker" (click)="picker.open()" readonly placeholder="Date"
            formControlName="date" (dateChange)="dateChanged($event)">
          <mat-datepicker-toggle matSuffix [for]="picker">
            <img matDatepickerToggleIcon src="assets/images/bx-calendar.svg">
          </mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-2" style="padding-left: 30px;">

        <div class="delete-vendor-alloc" style="position: relative; right: 13px;">
          <label>Log Type<sup class="mandatory">*</sup></label>
          <select class="form-select" matNativeControl placeholder="Log type" formControlName="logType"
            (change)="onLogTypeChange()">
            <option value="" disabled>Select log type
            </option>
            <option class="unCheck" *ngFor="let log of logType" [value]="log.key">{{ log.key }}</option>
          </select>
        </div>

        <!-- <mat-form-field appearance="outline" class="delete-vendor-alloc" style="position: relative; right: 13px;">
          <mat-select placeholder="Log type" formControlName="logType" (selectionChange)="onLogTypeChange()">
            <mat-option class="unCheck" *ngFor="let log of logType" [value]="log.key">
              {{ log.key}}
            </mat-option>
          </mat-select> -->
        <mat-error class="error-msg"
          *ngIf="_mFormGroup.get('logType').hasError('required') && (_mFormGroup.get('logType').touched || _mFormGroup.get('logType').dirty)">
          Please select log type
        </mat-error>
        <!-- </mat-form-field> -->
      </div>

      <div class="col-sm-12 col-md-2" style="padding-left: 15px;">
        <label>Shift Time<sup class="mandatory">*</sup></label>
        <ng-multiselect-dropdown class="delete-vendor-alloc-sel" style="position: relative; right: 13px;"
          formControlName="shiftTime" [placeholder]="'ShiftTime'" [settings]="shiftTimeDDSettings"
          [(ngModel)]="selectedShiftTimes" [data]="_mShiftTimeList">
        </ng-multiselect-dropdown>
        <!-- <mat-error  class="form-error-state font-size-10 font-weight-meduim"
         *ngIf="_mFormGroup.get('shiftTime').hasError('required') 
         && (_mFormGroup.get('shiftTime').touched || _mFormGroup.get('shiftTime').dirty)">
          Please select shift time
      </mat-error> -->
      </div>
      <div class="col-sm-12 col-md-2" style="padding-left: 2rem; position: relative; top: 30px;">
        <button class="btn playlist-btn-solid ml-auto" (click)="onShow()" [disabled]="_mFormGroup.invalid">Show</button>
        <button class="btn btn-btn-grey" *ngIf="tripSheetflag" style="position: absolute; margin-left: 28px;"
          (click)="onDelete()">Delete</button>
        <img *ngIf="tripSheetflag" src="./assets/images/excel.svg" (click)="onExportToExcel()"
          matTooltip="Export To Excel" style="position: relative; left: 186px;">
      </div>

    </form>

  </div>
</div>


<mat-accordion *ngIf="tripSheetflag" displayMode="flat" multi class="custom-accordian-del-vendor">
  <section matSort class="mat-header-row bg-white" style="padding: 5px 12px;">
    <span class="cell">Trip Code</span>
    <span class="cell">Vehicle No</span>
    <span class="cell">Vendor</span>
    <span class="cell">Driver</span>
    <span class="cell">Contact No</span>
    <span class="cell">Location</span>
    <span class="cell">Vehicle Type</span>
    <span class="cell">Escort</span>
    <span class="cell">Distance</span>
    <span class="cell">Occupancy</span>
    <span class="cell">Occupancy%</span>
    <span class="cell">SelectAll <mat-checkbox class="checkBox-del" [checked]="checkedFlag"
        (change)="checkAll($event)"></mat-checkbox></span>
    <span *ngIf="!allExpandState" (click)="allExpandState=true">
      <mat-icon matTooltip="Expand all" matTooltipPosition="below">expand_more</mat-icon>
    </span>
    <span *ngIf="allExpandState" (click)="allExpandState=false">
      <mat-icon matTooltip="Collapse all" matTooltipPosition="below">expand_less</mat-icon>
    </span>
  </section>
  <div class="del-vendor-expansion-container">
    <mat-expansion-panel #mep="matExpansionPanel" [disabled]="true" *ngFor="let item of displayedRows$"
      [expanded]="allExpandState" class="panel-wrapper">
      <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
        <span class="mat-cell">{{item.tripCode}}</span>
        <span class="mat-cell">{{item.vehicleNo}} ({{item.alloacateVehicle}})</span>
        <span class="mat-cell">{{item.Vendor}}</span>
        <span class="mat-cell">{{item.Driver}}</span>
        <span class="mat-cell">{{item.Number}}</span>
        <span class="mat-cell text-ellipse" [matTooltip]="[item.pickupDropPoint.length >=14 ? item.pickupDropPoint
          : '']" matTooltipPosition="above">{{item.pickupDropPoint}}</span>
        <span class="mat-cell">{{item.vehicleType}}</span>
        <span class="mat-cell">{{item.escort}}</span>
        <span class="mat-cell">{{item.distance | number :'1.2-2'}} Km</span>
        <span class="mat-cell">{{item.seatsOccupied}}/{{item.seatCapacity}}</span>
        <span class="mat-cell">
          <div>
            {{item.seatsOccupiedPercent}}</div>
        </span>
        <span class="mat-cell">
          <mat-checkbox class="customCheckBox-del-vendor" [checked]="checkedFlag"
            (change)="checkTrip($event,item.tripid)"></mat-checkbox>
        </span>
        <span class="expand-content-width" (click)="mep.expanded=
          !mep.expanded" style="margin-top: 20px;">
          <mat-icon matTooltip="Expand" matTooltipPosition="below" *ngIf="!mep.expanded">expand_more</mat-icon>
          <mat-icon matTooltip="Collapse" matTooltipPosition="below" *ngIf="mep.expanded">expand_less</mat-icon>
        </span>

      </mat-expansion-panel-header>
      <section class="mat-header-row header-del-vendor" style="background-color: #FBFCFF;">
        <span class="font1-del-vendor ms-1">Date- <span class="font2-del-vendor   ms-3"> {{item.date}}</span></span>
        <span class="font1-del-vendor">Escort Name- <span
            class="font2-del-vendor ms-3">{{item.escortname}}</span></span>
        <span class="font1-del-vendor">Travel Time- <span class="font2-del-vendor ms-3"> {{item.triptraveltime}}
            min</span></span>
        <span class="font1-del-vendor">Arv/Dept Time-<span class="font2-del-vendor ms-3">{{item.tatd}}</span></span>
      </section>

      <div class="card">
        <div class="card-body" style="box-shadow: 3px 3px 5px 2px #D9D9D9;overflow-x: auto;">
          <table class="table table-del-vendor ">
            <thead>
              <th>Emp Id </th>
              <th>Name </th>
              <th>Gender </th>
              <th>Address </th>
              <th>Contact </th>
              <th>Distance </th>
              <th>Pick/Drop Time </th>
              <th>Travel Status </th>
              <th>Check IN/OUT </th>
            </thead>

            <tbody>
              <tr *ngFor=" let emp of item.tripemp">
                <td>
                  <span class="badge bfont"
                    [ngStyle]="{'color': emp.userColor, 'background': '#fff', 'border': '1px solid' + emp.userColor}">
                    {{emp.employeeId}}
                  </span>
                </td>
                <td title={{emp.employeeName}}>{{emp.employeeName}}</td>
                <td>{{emp.gender}}</td>
                <td>{{emp.address}}></td>
                <td>{{emp.contact}}</td>
                <td>{{emp.distance}}</td>
                <td>{{emp.time}}</td>
                <td>{{emp.showstatus}}</td>
                <td>{{emp.adtime}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mat-expansion-panel>
  </div>

</mat-accordion>