<div class="mat-modal-dialog trip-modal">
    <h2 mat-dialog-title>
        <div class="titleCss">
            {{_mTitle}}
        </div>

        <span class="material-icons-outlined" style="color:#747679; font-size: 25px; cursor: pointer;"
            (click)="onClickCancel()">close</span>
    </h2>
    <mat-dialog-content>
        <form class="example-form" [formGroup]="_mFormGroup">
            <div class="row mt-3">
                <div class="col-sm-6 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>ShowStatus</mat-label>
                        <mat-select placeholder="Show Status" formControlName="mShowStatus" [multiple]="!mIsEdit"
                            required>
                            <mat-option *ngFor="let data of _mStatus" [value]="data.value">
                                {{ data.value }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-sm-6 col-md-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Reason</mat-label>
                        <input matInput placeholder="Reason" autocomplete="off"
                            (keypress)="discard_special_char_And_String($event)" type="text" maxlength="50"
                            formControlName="mReason" required>
                        <mat-error *ngIf="mReason.hasError('required')">
                            Reason is required
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="hr-line"></div>
                <div mat-dialog-actions align="end">
                    <button (click)="onClickCancel()" class="btn secondary-btn-solid me-3">
                        Cancel
                    </button>
                    <div class="tdw-padding-h-8"></div>
                    <button (click)="onSubmit()" class="btn footerBtn submit-btn-solid ">
                        Submit
                    </button>
                </div>
            </div>
        </form>
    </mat-dialog-content>
</div>