
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { ApiService } from 'src/app/core/services/api.service';
import { ServiceExportExcel } from 'src/app/core/services/export.service';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ModelTableHeader } from 'src/app/shared/models/table-header';


@Component({
  selector: 'app-route-map-setup',
  templateUrl: './route-map-setup.component.html',
  styleUrls: ['./route-map-setup.component.scss']
})
export class RouteMapSetupComponent implements OnInit {


  pdfUrl: any;
  mIsEdit: any;
  searchData: any;
  _mIsLoading = true;
  _mIsNoPagination = false;
  toggleStatus = true;
  _mSitesData: any[] = [];
  _mSitesDataFlag = false;
  _mCategoryData: any[] = [];
  _mCategoryFlag = true;
  _mVehicletypeData: any[] = [];
  _mVehicletypeFlag = true;
  _mVehiclesData: any[] = [];
  _mVehiclesFlag = true;
  masterLoaded: boolean = false;
  zoneLoaded: boolean = false;
  public _mFormGroup: FormGroup;
  public mLocation = new FormControl('');
  public mSite = new FormControl('');
  public mMasterZone = new FormControl('');
  selectedval: any;
  public mZone = new FormControl();
  searchkey: any = '';
  _mTotalPage = 1;
  _mCurrentNumber = 0;
  _mTotalElements = 0;
  _mSize = 0;
  _mColumns: ModelTableHeader[];
  pushedValues: any[] = [];
  unPushedValues: any[] = [];
  pushedids: any[] = [];
  rows = [];
  shifts: any = [];
  cities: any[] = [];

  itemsPerPage = 50;
  itemsShowingFromNo = 0;
  itemsShowingToNo = 0;
  showFlag = false;
  masterzone: any = [];
  _mData: any[] = [];
  _mDataForExportToExcel: any[] = [];


  _mFormattedData: any[] = [];
  selectedSite: any;
  selectedzone: any;

  autoSelectedzone: any;
  zones: any[];
  showzone: boolean;
  polylines: any[] = [];
  zonePoly: any;
  ServiceZone: any;
  plotZone: any;
  show: any;

  selectedType: any;
  selectedType1: any;
  selectedType2: any;
  _mMasterZoneData: any[] = [];

  _mZoneData: any;
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<any>;
  selectedSiteData: any;
  zoneData: any;

  constructor(
    private fb: FormBuilder,
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    private readonly serviceExportExcel: ServiceExportExcel,
    private readonly localStorage: ServiceLocalStorage,

    private ngxLoader: NgxUiLoaderService,

  ) {
    this._mFormGroup = this.fb.group({
      'location': this.mLocation,
      'site': this.mSite,
      'masterZone': this.mMasterZone,
      'zone': this.mZone
    });
    this.filteredOptions = this.mZone.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }
  displayFn(zone): string {
    return zone ? zone.name : zone;
  }



  private _filter(value) {
    let filterValue = value;
    let tempArray = []
    if (this._mZoneData) this._mZoneData.forEach(element => {
      if (element.name.toLowerCase().includes(filterValue))
        tempArray.push(element);
    });
    return tempArray;
  }

  ngOnInit() {
    this.loadCities();
    this.show = false;
  }


  loadCities() {
    let companyid: number = +this.localStorage.getCompanyId();
    this.apiService.doGetRequestWithResponse(ApiConstants.getLocationsByCompanyIdAndUser +
      companyid,
      success => {
        this.cities = success.body;
      },
      error => {
      }
    );
  }
  getSitesByCityId(cityId) {
    this._mSitesDataFlag = true;
    if(cityId == null || cityId == undefined)
    {
      return;
    }
    this.apiService.doGetRequestWithResponse(ApiConstants.getSitesByLocationId +
      cityId,
      success => {
        this._mSitesDataFlag = false;
        if (success != null) {
          this._mSitesData = success.body.dataList.content;
        } else {
          this._mSitesData = [];
          this.notifyService.showInfo("No Sites Found!", null);
        }
      },
      error => {
        this._mSitesDataFlag = false;
      }
    );
  }


  onSelectionChange() {
    if (this.mSite.value != null) {
      this.getMasterZone(this.mSite.value);
    }
  }
  onMasterChange() {
    this.mZone.setValue('');
    if (this.mMasterZone != null) {
      this.getZones(this.mMasterZone.value);
    }
  }

  getMasterZone(siteId) {
    this._mMasterZoneData = [];

    this.masterLoaded = false;
    /*  const params = {
       'siteid': siteId
     }; */
    this.apiService.doGetRequestWithResponse(ApiConstants.masterZone + siteId, success => {

      if (success.status === 200) {
        this._mMasterZoneData = success.body;
        this.mMasterZone.setValue('');
      } else {
        this._mMasterZoneData = [];
      } this.masterLoaded = true;
    }, (error) => {

    });

  }

  getZones(masterZoneId) {
    this.zoneLoaded = false;
    const param1 = {
      'masterzoneid': masterZoneId
    };
    this.apiService.doGetRequestWithResponse(ApiConstants.getZones + masterZoneId, success => {

      if (success.status === 200) {
        this._mZoneData = success.body;

        this.mZone.setValue('');
        /* this.showZone(); */
      } else {
        this._mZoneData = [];
      } this.zoneLoaded = true;
    }, (error) => {

    });
  }


  showMapping(selectedSite, selectedval) {
    this.zoneData = selectedval;
    selectedval = this.mZone.value.id;
    this.show = true;
    this.pushedValues = [];
    this.unPushedValues = [];
    this.rows = [];
    if (selectedval === undefined) {
      this.notifyService.showInfo('Please select pickup point type!', null);
    } else {
      this._mSitesData.forEach(element => {
        if (element.siteId == this.zoneData.masterZone.site.siteId) {
          this.selectedSiteData = element;
        }
      });
      this.selectedType = selectedval;
      this.selectedType1 = this.selectedSiteData;
      this.getAllVehicleTypes();
      this.showFlag = true;
    }
  }


  getAllVehicleTypes() {
    this.pushedValues = [];
    this.unPushedValues = [];
    this.rows = [];
    this.selectedType1.vehicleTypes.forEach(vehicleType => {
      this.rows.push({
        id: vehicleType.id,
        value: vehicleType.vehicleType
      });
    });
    this.getMapping();
  }

  getMapping() {
    this.pushedValues = [];
    this.unPushedValues = [];
    let params = {
      "siteid": this.selectedType1.siteId,
      "zoneid": this.mZone.value.id
    }
    if (this.mSite.value != null) {
      this.apiService.doGetRequestWithResponse(ApiConstants.getvehicleroutemapbysiteidandzoneid + "?siteid=" + this.selectedType1.siteId + "&zoneid=" + this.selectedType, (success) => {
        success.body.forEach(shiftmap => {
          this.rows = this.rows.filter(r => r.id !== shiftmap.id);
          const sel = {
            id: shiftmap.id,
            value: shiftmap.vehicleType
          };
          this.pushedValues.push(sel);
          this.unPushedValues.push(sel);
          this.unPushedValues.forEach(x => {
            x.state = 'checked';
          });
        });

      }, (error) => {
        this.notifyService.showError('Error in Fetching Mapping!', null);

      });
    }

  }

  saveMapping() {
    if (this.selectedType === undefined) {
      this.notifyService.showInfo('Please select pickup point type!', null);
    } else {

      let pushedids = [];
      this.unPushedValues.forEach(pushid => {
        pushedids.push(+pushid.id);
      });
      if (pushedids == null) {
        pushedids = [];
      }
      // let data = { zoneid: this.mZone.value.id, siteid: this.mSite.value, vehicleTypeId: pushedids }
      if (this.mSite.value != null) {
        this.apiService.doPostRequestWithResponse(ApiConstants.addSpecialCategory1 + '?zoneid=' + this.mZone.value.id + '&siteid=' + this.mSite.value +
          '&vehicleTypeId=' + pushedids, '', (success) => {
            if (success.status === 201) {
              this.notifyService.showSuccess('Mapping updated successfully!', null);
              this._mFormGroup.reset();
              // this.ngOnInit();
              // this.mlo
              this.mLocation.setValue('');
              this.mSite.setValue('');
              this.mMasterZone.setValue('');
              this.mZone.setValue('');
              this.pushedValues = [];
              this.unPushedValues = [];
              this.rows = [];
              this.showFlag = false;
            } else if (success.status === 204) {
              this.notifyService.showSuccess('Mapping updated successfully!', null);
              this._mFormGroup.reset();
              // this.ngOnInit();
              // this.mSite.setValue(null);
              // this.mMasterZone.setValue(null);
              // this.mZone.setValue(null);
              this.mLocation.setValue('');
              this.mSite.setValue('');
              this.mMasterZone.setValue('');
              this.mZone.setValue('');
              this.pushedValues = [];
              this.unPushedValues = [];
              this.rows = [];
              this.showFlag = false;
            }
          }, (error) => {
            this.notifyService.showError('Failed to Updated!', null);
          });
      }

    }
  }

  isAllChecked() {
    return this.rows.every(_ => _.state);
  }

  checkAll(checks) {
    if (checks.checked) {
      this.rows.forEach(x => {
        x.state = checks.checked;
        this.onValueChecked({ checked: true }, x);
      });
    }
  }


  onValueChecked(checked, sel) {
    if (checked.checked) {
      this.pushedValues.push(sel);
      this.rows = this.rows.filter(r => r.id !== sel.id);
      this.unPushedValues.push(sel);
    }
  }

  unCheckAll(checks) {
    if (!checks.checked) {
      this.unPushedValues.forEach(x => {
        x.state = checks.checked;
        this.onSelValuesChecked({ checked: false }, x);
      });
    }
  }

  onfieldchange() {
    this.showFlag = false;
  }

  onSelValuesChecked(checked, unselect) {
    if (!checked.checked) {
      this.rows.push(unselect);
      this.unPushedValues = this.unPushedValues.filter(r => r.id !== unselect.id);
      this.pushedValues.push(unselect);
    }
  }

  onSelValueChecked(checked, unselect) {
    if (!checked.checked) {
      this.rows.push(unselect);
      this.unPushedValues = this.unPushedValues.filter(r => r.id !== unselect.id);
      this.pushedValues.push(unselect);
    }
  }

}
