<header id="page-topbar" style="position: absolute; z-index: 1000;">
  <div class="navbar-header">
    <div class="d-flex">
      <div class="navbar-brand-box">
        <a routerLink="/myatom/user/my-profile" class="logo logo-dark">
          <span class="logo-lg">
            <img src="assets/images/atom-logo.svg" alt="" class="myatom-img" height="30">
          </span>
        </a>
      </div>

      <div class="topnav">
        <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">
          <div class="collapse navbar-collapse active" id="topnav-menu-content">
            <ul class="navbar-nav">
              <ng-container *ngFor="let item of menuItems">
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle arrow-none" *ngIf="!item.subItems && item.permission"
                    href="javascript: void(0);" id="topnav-components" [routerLink]="item.link" role="button">
                    {{ item.label | translate }}<div class="arrow-down" *ngIf="hasItems(item)"></div>
                  </a>

                  <a *ngIf="item.subItems && item.permission" class="nav-link dropdown-toggle arrow-none"
                    href="javascript: void(0);" id="topnav-components" role="button">
                    {{item.label | translate }}
                  </a>

                  <div class="dropdown-menu" [ngClass]="{'dropdown-mega-menu-xl px-2':  item.subItems.length > 15}"
                    aria-labelledby="topnav-dashboard" *ngIf="hasItems(item)">
                    <ng-template ngFor let-i="index" let-subitem [ngForOf]="item.subItems">
                      <a *ngIf="item.subItems.length < 16 && !hasItems(subitem) && subitem.permission && !subitem.link"
                        (click)="subitem.label == 'Billing' && subitem.name == 'biling-rediction' ? onSelectBilling() : false"
                        class="col dropdown-item side-nav-link-ref" [routerLink]="subitem.link" role="button">
                        {{subitem.label | translate }}
                      </a>

                      <a *ngIf="item.subItems.length < 16 && !hasItems(subitem) && subitem.permission && subitem.link"
                        class="col dropdown-item side-nav-link-ref" [routerLink]="subitem.link"
                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        {{subitem.label | translate }}
                      </a>

                      <div *ngIf="item.subItems.length > 15">
                        <div *ngIf="i % 3 == 0" class="row">
                          <div class="col-lg-4" *ngIf="item.subItems[i].permission"><a
                              class="dropdown-item side-nav-link-ref" routerLinkActive="active"
                              [routerLink]="subitem.link">{{item.subItems[i].label | translate}}</a></div>
                          <div class="col-lg-4" *ngIf="item.subItems[i +1].permission"><a
                              class="dropdown-item side-nav-link-ref" routerLinkActive="active"
                              [routerLink]="item.subItems[i + 1]?.link">{{item.subItems[i + 1]?.label | translate}}</a>
                          </div>
                          <div class="col-lg-4" *ngIf="item.subItems[i +2].permission"><a
                              class="dropdown-item side-nav-link-ref" routerLinkActive="active"
                              [routerLink]="item.subItems[i + 2]?.link">{{item.subItems[i + 2]?.label | translate}}</a>
                          </div>
                        </div>
                      </div>

                      <div class="dropdown" *ngIf="hasItems(subitem)">
                        <a class="dropdown-item dropdown-toggle" href="javascript: void(0);" *ngIf="subitem.permission"
                          (click)="onMenuClick($event)">{{ subitem.label | translate }}
                          <div class="arrow-down"></div>
                        </a>
                        <div class="dropdown-menu">
                          <ng-template ngFor let-subSubitem [ngForOf]="subitem.subItems">
                            <a class="dropdown-item side-nav-link-ref"
                              *ngIf="!hasItems(subSubitem) && subSubitem.permission" href="javascript: void(0);"
                              [routerLink]="subSubitem.link" routerLinkActive="active">{{
                              subSubitem.label | translate }}</a>
                            <div class="dropdown" *ngIf="hasItems(subSubitem)">
                              <a class="dropdown-item dropdown-toggle" href="javascript: void(0);"
                                *ngIf="subSubitem.permission" (click)="onMenuClick($event)">{{ subSubitem.label |
                                translate }}
                                <div class="arrow-down"></div>
                              </a>
                              <div class="dropdown-menu">
                                <ng-template ngFor let-subSubSubitem [ngForOf]="subSubitem.subItems">
                                  <a class="dropdown-item side-nav-link-ref" href="javascript: void(0);"
                                    *ngIf="subSubSubitem.permission" [routerLink]="subSubSubitem.link"
                                    routerLinkActive="active">{{ subSubSubitem.label | translate }}</a>
                                </ng-template>
                              </div>
                            </div>
                          </ng-template>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </li>
              </ng-container>
            </ul>
          </div>
        </nav>
      </div>
    </div>


    <div *ngIf="(_contextSearch ) && (filterFlag ||employeeFlag ) && (!vendorFlag)" class="search-box"
      style="margin-left: auto;">
      <select class="form-select-contextSearch" [(ngModel)]="filterValue" (change)="filterSelected($event)" required>
        <option value="Filter" class="context-fonts" disabled selected> Filter</option>
        <option *ngFor="let option of mOptions" class="context-fonts" [value]="option.value">{{option.value}}</option>
      </select>
      <img matSuffix src="assets/images/search.svg" size="15px" style="margin-left: 10px;padding-right: 5px;">
      <input *ngIf="filterFlag" type="text" class="search-form-input" placeholder="Search" matInput
        [matAutocomplete]="auto">
      <input *ngIf="employeeFlag" type="text" class="search-form-input" placeholder="EmpId/Emp Name" matInput
        [matAutocomplete]="auto" (input)="searchSubject$.next($event.target.value)">
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="searchSubmit($event)">
        <mat-option class="optionUnCheckMatoption contextSearchEmp" *ngFor="let option of user"
          [value]="option.userInfoDetails.employeeId">
          {{option.userInfoDetails.employeeId}}-{{option.userInfoDetails.displayName}}</mat-option>
      </mat-autocomplete>
    </div>


    <div *ngIf="vehicleFlag && _contextSearch" class="search-box" style="margin-left: auto;">
      <select class="form-select-contextSearch" [(ngModel)]="filterValue" (change)="filterSelected($event)" required>
        <option value="Filter" class="context-fonts" disabled selected> Filter</option>
        <option *ngFor="let option of mOptions" class="context-fonts" [value]="option.value">{{option.value}}</option>
      </select>
      <img matSuffix src="assets/images/search.svg" size="15px" style="margin-left: 10px;padding-right: 5px;">
      <input *ngIf="vendorFlag && filterVehicle && !vendorMasterFlag" type="text" class="search-form-input"
        placeholder="Search" matInput [matAutocomplete]="auto">

      <input *ngIf="vendorFlag && vendorMasterFlag" type="text" class="search-form-input" placeholder="Vehicle No."
        matInput [matAutocomplete]="auto" [(ngModel)]="searchVehicle" (input)="onSearchVehicle(searchVehicle)">

      <input *ngIf="!filterVehicle && !vendorFlag" type="text" class="search-form-input" placeholder="Vehicle No."
        matInput [matAutocomplete]="auto" (input)="searchSubjectVehicle$.next($event.target.value)">
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="searchSubmitVehicle($event)">
        <mat-option class="optionUnCheckMatoption contextSearchEmp" *ngFor="let option of vehicleData"
          [value]="option.vehicleNo">
          {{option.vehicleNo}}</mat-option>
      </mat-autocomplete>
    </div>


    <div *ngIf="driverFlag && _contextSearch" class="search-box" style="margin-left: auto;">
      <select class="form-select-contextSearch" [(ngModel)]="filterValue" (change)="filterSelected($event)" required>
        <option value="Filter" class="context-fonts" disabled selected> Filter</option>
        <option *ngFor="let option of mOptions" class="context-fonts" [value]="option.value">{{option.value}}</option>
      </select>
      <img matSuffix src="assets/images/search.svg" size="15px" style="margin-left: 10px;padding-right: 5px;">
      <input type="text" class="search-form-input" placeholder="Driver Name" matInput [matAutocomplete]="auto"
        [(ngModel)]="searchDriver" (input)="onSearchKey(searchDriver)">
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="searchSubmitDriver($event)">
        <mat-option class="optionUnCheckMatoption contextSearchEmp" *ngFor="let driver of _mData" [value]="driver.name">
          {{driver.name}}</mat-option>
      </mat-autocomplete>
    </div>


    <div class="d-flex">
      <div class="dropdown d-inline-block" ngbDropdown style="top:5px">
        <button type="button" class="btn header-item noti-icon border-none" id="page-header-notifications-dropdown"
          (click)="getLatestNotifications()" ngbDropdownToggle>
          <i class="bx bx-bell bx-tada"></i>
          <span class="badge bg-danger rounded-pill fnt-wt-400">{{count}}</span>
        </button>

        <div *ngIf='notificationFlag' class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0 notification-header-font">Notifications</h6>
              </div>
              <!-- <div class="col-auto">
                        <a href="javascript: void(0);" class="small" (click)="getAllNotification()">View All</a>
                    </div> -->
              <div class="col-auto">
                <a href="javascript: void(0);" class="notification-header-font" (click)="clearNotification()">Clear
                  All</a>
              </div>
            </div>
          </div>
          <ngx-simplebar style="position: relative; height: 268px;">
            <a href="javascript: void(0);" class="text-reset notification-item" *ngFor="let item of notifications">
              <div class="media">
                <div class="avatar-xs me-3" style="width: 1.5rem !important; height: 1.5rem !important;">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <i class="bx bx-badge-check"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1 notification-header-font">{{item.title}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1" style="font-weight: 400; font-family: 'poppins' !important;">{{item.message}}</p>
                    <p class="mb-0" style="font-weight: 400; font-family: 'poppins' !important;"><i
                        class="mdi mdi-clock-outline"></i>{{item.createdAt}}</p>
                  </div>
                </div>
              </div>
            </a>
          </ngx-simplebar>
          <!-- <div class="p-2 border-top d-grid">
                <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">
                    <i class="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">View More..</span>
                </a>
            </div> -->
        </div>
      </div>

      <div class="dropdown d-inline-block me-2" ngbDropdown>
        <button type="button" class="btn header-item border-none" id="page-header-user-dropdown" ngbDropdownToggle>
          <img class="rounded-circle header-profile-user" src="assets/images/avatharmale.png">
          <!-- <span class="d-none d-xl-inline-block ms-1"><small>{{username}}</small></span> -->
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>

        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <a class="dropdown-item d-block" href="javascript: void(0);" (click)="myProfile()"><em
              class="bx bx-user font-size-16 align-middle me-1"></em>
            My Profile</a>
          <a class="dropdown-item d-block" href="javascript: void(0);" (click)="onClickSetting()" *ngIf="settings">
            <em class="bx bx-cog font-size-16 align-middle me-1"></em>
            Settings</a>
          <a class="dropdown-item d-block" href="javascript: void(0);" (click)="onClickConfiguration()"
            *ngIf="configuration">
            <img src="assets/images/settings.svg" class="font-size-16 align-middle me-1" height="15">
            Configurations</a>
          <a class="dropdown-item d-block" href="javascript: void(0);" (click)="changePassword()"><em
              class="bx bx-wallet font-size-16 align-middle me-1"></em>Change
            Password</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger d-block" href="javascript: void(0);" (click)="logout()"><em
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></em>
            Logout</a>
        </div>
      </div>
    </div>
  </div>
</header>