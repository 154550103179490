<div>
    <span class="material-icons-outlined"
        style="color:#747679; font-size: 35px; float: right; position: relative; top: 20px; right: 30px;"
        (click)="onClickCancel()">
        close</span>
    <div class="er_icon1"><i class="fa fa-exclamation-circle fa-2x" aria-hidden="true"></i></div>
    <div class="oh_snap1">File Upload Summary {{
        mData.length }} errors.</div>
</div>
<div class="clearfix"></div>
<div class="max-height">
    <div *ngFor="let data of mData; let i=index">
        <div class="container1">
            <div *ngIf="commentFlag" fxLayout="column" style="margin-left: 15px; font-size: 14px; font-weight: 400; font-style: normal; font-family: 'Poppins';">
                {{i+1}}. {{data.comments}}
            </div>
            <div *ngIf="!commentFlag" fxLayout="column" style="margin-left: 15px; font-size: 14px; font-weight: 400; font-style: normal; font-family: 'Poppins';">
                {{i+1}}. {{data}}
            </div>
        </div>
    </div>
</div>
<div class="tdw-padding-v-8"></div>
<div fxLayout="column">
    <div fxLayout="row" fxFlexAlign="center" style="margin-top: 30px;">
        <div class="tdw-padding-h-8"></div>
        <div class="tdw-padding-h-8"></div>
    </div>
</div>