<div fxLayout="row">
    <h4 class="mat-color-text--grey-700" fxFlex>Color Code for Category</h4>
    <button mat-icon-button color="accent" (click)="onClickCancel()">
        <mat-icon>clear</mat-icon>
    </button>
</div>

<div fxLayout="row" *ngFor="let category of categoryData">
    <mat-card [ngStyle]="{'background':category.colorCode}" class="showCategory"></mat-card>
    <h4 class="categoryShowName">{{category.CategoryName}}</h4>
</div>

<div fxLayout="row">
    <mat-card style="background-color: #FFFF00;" class="showCategory"></mat-card>
    <h4 class="categoryShowName">Out of Site Boundary</h4>
</div>