
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { AppConstants } from 'src/app/core/constants/appconstants';
import { ApiService } from 'src/app/core/services/api.service';
import { ServiceExportExcel } from 'src/app/core/services/export.service';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-shift-pickup-point-mapping',
  templateUrl: './shift-pickup-point-mapping.component.html',
  styleUrls: ['./shift-pickup-point-mapping.component.scss']
})
export class ShiftPickupPointMappingComponent implements OnInit {

  public _mFormGroup: FormGroup;
  public mCity = new FormControl('');
  public mSite = new FormControl('');
  public mFilterType = new FormControl('');
  cityFlag: boolean = true;
  siteFlag: boolean = false;
  cityId: any;
  companyid: number = +this.localStorage.getCompanyId();
  cities: any = [];
  sites: any = [];
  shifts: any = [];
  showBy = [{ name: 'COMMON POINT', type: 'C' }, { name: 'HOME POINT', type: 'H' }];
  selectedSite: any = '';
  rows = [];
  show: boolean;
  tempRows = [];
  pushedValues: any[] = [];
  unPushedValues: any[] = [];
  showFlag: boolean = false
  selectedType: string = 'c';
  shiftmapping: any = [];
  mappingdata: any = [];
  selectedval: any = '';

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute, 
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    private readonly serviceExportExcel: ServiceExportExcel,
    private readonly localStorage: ServiceLocalStorage,
    private ngxLoader: NgxUiLoaderService)
     {
    this._mFormGroup = this.fb.group({
      'city': this.mCity,
      'site': this.mSite,
      'filterType': this.mFilterType
    });
  }

  ngOnInit() {
    this.loadCities();
    this.show=false;
  }
  
  loadCities() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getLocationsByCompanyIdAndUser + this.companyid, success => {
      this.cityFlag = false;
      this.cities = success.body;
    }, (error) => {
    });
  }

  getSitesByCityId(cityId) { 
    if(cityId != undefined) {
    this.showFlag = false;
    this.siteFlag = true;
    this.apiService.doGetRequestWithResponse(ApiConstants.getSitesByLocationId + cityId, (success) => {
      this.siteFlag = false;
      if (success != null) {
        this.sites = success.body.dataList.content;
      } else {
        this.sites = null;
      }
    },
      (error) => {
      });
  }
}

  showMapping(selectedval) {
    this.show = true;
    this.pushedValues = [];
    this.unPushedValues = [];
    this.rows = [];
    if (selectedval == undefined) {
      this.notifyService.showInfo("Please select pickup point type!", null);
    } else {
      this.selectedType = selectedval;
      this.getShiftTime();
      this.showFlag = true;
    }
  }

  getShiftTime() {
    this.shifts = [];
    this.pushedValues = [];
    this.unPushedValues = [];
    this.rows = [];
    this.apiService.doGetRequestWithResponse(ApiConstants.getShiftsBySiteAndType + this.mSite.value +  "&shifttype=" + 'General', (success) => {
      if (success != null) {
          success.body.forEach(shift => {
            this.shifts.push({
              id: shift.shiftTimeId,
              value: shift.shiftTime + " " + shift.logType
            })
          });
          this.rows = this.shifts;
          this.getMapping();
      } else {
        this.notifyService.showError("No data found", null);
      }
    }, (error) => {
      this.shifts = [];
    })
  }


  isAllChecked() {
    return this.rows.every(_ => _.state);
  }

  checkAll(checks) {
    if (checks.checked) {
      this.rows.forEach(x => {
        x.state = checks.checked
        this.onValueChecked({ checked: true }, x);
      })
    }
  }

  onValueChecked(checked, sel) {
    if (checked.checked) {
      this.pushedValues.push(sel);
      this.rows = this.rows.filter(r => r.id !== sel.id);
      this.unPushedValues.push(sel);
    }
  }
  unCheckAll(checks) {
    if (!checks.checked) {
      this.unPushedValues.forEach(x => {
        x.state = checks.checked
        this.onSelValuesChecked({ checked: false }, x);
      })
    }
  }

  onSelValuesChecked(checked, unselect) {
    if (!checked.checked) {
      this.rows.push(unselect);
      this.unPushedValues = this.unPushedValues.filter(r => r.id !== unselect.id);
      this.pushedValues.push(unselect);
    }
  }

  onSelValueChecked(checked, unselect) {
    if (!checked.checked) {
      this.rows.push(unselect);
      this.unPushedValues = this.unPushedValues.filter(r => r.id !== unselect.id);
      this.pushedValues.push(unselect);
    }
  }
  saveMapping() {
    if (this.selectedType == undefined) {
      this.notifyService.showInfo("Please select pickup point type!", null);
    } else {

      this.showFlag = false;
      let pushedids = [];
      this.unPushedValues.forEach(pushid => {
        pushedids.push(+pushid.id);
      });
      if (pushedids == null) {
        pushedids = [];
      }
      if (this.mSite.value != null) {
        this.apiService.doPostRequestWithResponse(ApiConstants.addShiftPickPoint + '?siteid=' + this.selectedSite + '&shifttimeid='+ pushedids +'&type=' + this.selectedType, '', (success) => {

          if (success.status == 201) {
            this.notifyService.showSuccess("Mapping updated successfully!", null);
            this.mCity.setValue(null);
            this.mSite.setValue(null);
            this.mFilterType.setValue("");
            this.pushedValues = [];
            this.unPushedValues = [];
            this.rows = [];
            this.shiftmapping = [];
            this.ngOnInit();
            //location.reload();
          } else if (success.status == 204) {
            this.notifyService.showSuccess("Mapping removed successfully!", null);
            this.ngOnInit();
            this.mCity.setValue("");
            this.mSite.setValue(null);
            this.mFilterType.setValue("");
            this.pushedValues = [];
            this.unPushedValues = [];
            this.rows = [];
            this.shiftmapping = [];
          }
        }, (error) => {
          this.notifyService.showError("Failed to Updated!", null);
        })
      }

    }
  }
  onsitechange(event) {
    this.showFlag = false;
    this.rows = [];
    this.unPushedValues = [];
    this.pushedValues = [];
    this.selectedSite = '';
    this.selectedSite = event;
    
    if (this.mSite.value != null) {
      this.apiService.doGetRequestWithResponse(ApiConstants.addShiftPickPoint  + '?siteid=' +   this.selectedSite,(success) => {
        if (success.status == 200) {
          this.shiftmapping = success.body;
          success.body.forEach(shiftmap => {
            this.mappingdata.push({
              Location: shiftmap.site.location.locationName,
              Site: shiftmap.site.siteName,
              Shift_Time: shiftmap.shift.shiftTime + " " + shiftmap.shift.logType,
              Type: shiftmap.type == "C" ? "COMMON POINT" : "HOME POINT"
            })
          });
        } else {
          this.shiftmapping = [];
        }
      }, (error) => {
        this.notifyService.showError("Error in Fetching Mapping!", null);
      })
    }
  }

  getMapping() {
    this.pushedValues = [];
    this.unPushedValues = [];
    
    if (this.mSite.value != null) {
      this.apiService.doGetRequestWithResponse(ApiConstants.getShiftPickPoint + '?siteid=' +   this.selectedSite +'&type='+ this.selectedType, (success) => {
        if (success.status == 200) {
          success.body.forEach(shiftmap => {
            if (this.selectedType == shiftmap.type) {
              this.rows = this.rows.filter(r => r.id !== shiftmap.shift.shiftTimeId);
              let sel = {
                id: shiftmap.shift.shiftTimeId,
                value: shiftmap.shift.shiftTime + " " + shiftmap.shift.logType
              };
              this.pushedValues.push(sel);


              this.unPushedValues.push(sel);
              this.unPushedValues.forEach(x => {
                x.state = 'checked'
              });
            }
          });
        }
    
      }, (error) => {
        this.notifyService.showError("Error in Fetching Mapping!", null);
       
      });
    }

  }

  onfieldchange() {
    this.showFlag = false;
  }

  exportAsXLSX(): void {
    this.serviceExportExcel.exportAsExcelFile(this.mappingdata, 'ShiftMaping');
  }
}
