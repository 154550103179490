import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/core/services/api.service';

@Component({
  selector: 'app-values-dialog-box',
  templateUrl: './values-dialog-box.component.html',
  styleUrls: ['./values-dialog-box.component.scss']
})
export class ValuesDialogBoxComponent {
  templateflag: boolean;
  tableCreated: boolean;
  date: any;
  tableAuditView: boolean;
  auditData = []
  constructor(
    public dialogRef: MatDialogRef<ValuesDialogBoxComponent>,
    public apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public data?: any,
   
  ) {
    if (data) {
      this.templateflag = true;
      this.data = data;
      this.date = data.updatedDate;
      if (!data.tripUserList) {
      let updatedDate = this.data.pop();
      this.date = updatedDate.updatedDate;
      var datePipe = new DatePipe('en-US');
      this.date = datePipe.transform(this.date, 'dd/MM/yyyy hh:mm a');
      this.data.forEach(element => {
        if ((element.oldValues == null) || (element.oldValues == "")) {
          element.oldValues = 'null';
        }
        if ((element.newValues == null) || (element.newValues == "")) {
          element.newValues = 'null';
        }
      });
       
        this.apiService.gettableCreatedObs().subscribe((res: any) => {
          if (res == true) {
            this.tableCreated = true;
          } else {
            this.tableCreated = false;
          }
        });
        this.apiService.gettableAuditViewObs().subscribe((res: any) => {
          if (res == true) {
            this.tableAuditView = true;
            this.auditData = Object.entries(this.data[0]);
          } else {
            this.tableAuditView = false;
          }
        });
        
      }
    } else {
      this.templateflag = false;
      this.data = [];
    }
  }

  ngOnInit(): void {
  }

  onClickCancel(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.apiService.settableCreatedObs(false);
    this.apiService.settableAuditViewObs(false);
  }

}
