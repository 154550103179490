<mat-card class="mat-card-header">
  <ul ngbNav #nav="ngbNav" [activeId]="activeTabId" class="nav-tabs nav-tabs-custom g-nav-tabs-custom"
    style="padding-top: 7px;">
    <li [ngbNavItem]="1">
      <a ngbNavLink> Manage Master Route</a>
      <ng-template ngbNavContent>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="p-3"></div>
  <form [formGroup]="_mFormGroup">
    <!-- <div fxLayout="row" class="subscription-report-search-container"> -->
    <div class="row mt-3">
      <div class="col-sm-2">
        <label>Location</label>
        <select class="form-select"formControlName="location"
          (ngModelChange)="getSitesByCityId()" required>
          <option disabled value="">Choose city</option>
          <option *ngFor="let city of cities" [value]="city.locationId">
            {{ city.locationName }}
          </option>
        </select>
        <div class="error-msg"
          *ngIf="_mFormGroup.get('location').hasError('required') && _mFormGroup.get('location').touched">
          Please select location
        </div>
      </div>

      <div class="col-sm-2">

        <label>Site</label>
        <select class="form-select" formControlName="site" (change)="getShiftTime()"
          required>
          <option disabled value="">Choose Site</option>
          <option *ngFor="let site of sites" [value]="site.siteId">
            {{ site.siteName}}
          </option>
        </select>
        <div class="error-msg" *ngIf="_mFormGroup.get('site').hasError('required') && _mFormGroup.get('site').touched">
          Please select site
        </div>
      </div>

      <div class="col-sm-2">


        <label>Shift</label>
        <select class="form-select" formControlName="shift" required>
          <option disabled value="">Choose Shift time</option>
          <option *ngFor="let time of shifts" [value]="time.shiftTimeId">
            {{time.shiftTime }}
          </option>
        </select>
        <div class="error-msg"
          *ngIf="_mFormGroup.get('shift').hasError('required') && _mFormGroup.get('shift').touched">
          Please select shift time
        </div>
      </div>
      <div class="col-sm-2">


        <label>Route type</label>
        <select class="form-select" placeholder="Route type" formControlName="routeType" required>
          <option disabled value="">Choose Type</option>
          <option *ngFor="let type of types" [value]="type.value">
            {{ type.view }}
          </option>
        </select>
        <div class="error-msg"
          *ngIf="_mFormGroup.get('routeType').hasError('required') && _mFormGroup.get('routeType').touched">
          Please select route type
        </div>
      </div>

      <div class="col-sm-3 d-flex justify-content-end" style="margin-top: 30px;">
        <button (click)="getallmasterroute()" class="btn playlist-btn-solid">Show
        </button>
      </div>

      <!-- <div class="col-sm-2">
          
            <label type="button" class="btn btn-primary1">
              <em class="material-icons-outlined font-size-22"
                  matTooltip="Update user fields">file_upload</em>
              <input   class="display" [(ngModel)]="uploadMasterFile"
              [ngModelOptions]="{standalone: true}"
              (change)="onFileChange($event)" type="file" placeholder="Company">
          </label>
        </div> -->
      <!-- <div  class="col-sm-2" *ngIf="tripsList?.length> 0">
            <span  (click)="downloadAL()"
            class="material-icons-outlined  iconProperty" >
             file_download
        </span>
         
        </div> -->
    </div>

  </form>

</mat-card>
<div *ngIf="tripsList.length> 0" class="card mt-3">
  <div class="customFont">
    <div class="row mt-3">
      <div class="col-sm-4 border-width1">
        <div class="vender_div" style="height:504px;">
          <div class="panel">
            <div class="panel-heading">
              Exception Employees
              <!-- <label>Search Employees</label> -->
              <mat-form-field class="masterDate exc-emps" appearance="outline">
                <input type="text" [(ngModel)]="searchEmpTxt" placeholder="Search Employees"
                  (input)="searchSubject$.next($event.target.value)" matInput [matAutocomplete]="exceptionAutoEmps">
                <mat-hint *ngIf="searchEmpTxt?.length>1 && userNotFound" class="mat-color-text--red-400"
                  style="font-family: 'poppins';">User not
                  found</mat-hint>
                <mat-progress-bar style="top:6px" class="example-margin" mode="indeterminate" *ngIf="_mUserSearchFlag">
                </mat-progress-bar>
                <img matSuffix src="assets/images/search.svg" height="27" style="margin-top: -22px;padding-right: 5px;">
                <mat-autocomplete #exceptionAutoEmps="matAutocomplete">
                  <mat-option *ngFor="let user of userSearchResult" [value]="user.userInfoDetails.displayName"
                    (onSelectionChange)="userSearchSubmit(user)">
                    <div class="row">
                      <div class="col-md-12">{{user.userInfoDetails.displayName}}</div>
                      <div class="col-md-6">
                        <div class="ex_emp_opt">
                          <span style="background: #d75345;" *ngIf="user.userInfoDetails?.pickUpDrop?.landmark"
                            (click)="setLandmark(user,'P')">Pickup
                            ({{user.userInfoDetails?.pickUpDrop?.landmark}})</span>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="ex_emp_opt"><span style="background:
                              #068c00;" (click)="setLandmark(user,'H')"
                            *ngIf="user.userInfoDetails?.homePoint?.landmark">Home
                            ({{user.userInfoDetails?.homePoint?.landmark}})</span></div>
                      </div>
                    </div>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="panel-body" style="min-height:430px !important;
                height:auto;" dnd-sortable-container [sortableData]="exceptionEmps">
              <ul class="list-group">
                <li *ngFor="let user of exceptionEmps; let i= index" class="list-group-item" dnd-sortable
                  [dragData]="user" [sortableIndex]="i">
                  {{user.tripUser?.userInfoDetails?.employeeId ?
                  user.tripUser?.userInfoDetails?.employeeId :
                  user?.employeeId}} -
                  {{user.tripUser?.userInfoDetails?.displayName ?
                  user.tripUser?.userInfoDetails?.displayName :
                  user?.displayName}} -
                  {{user.landmark.area.area}},{{user.landmark.landmark}}
                  &nbsp;<span *ngIf="typeFlag">
                    <mat-icon matBadge="{{user.status ? user.status : 0}}" matBadgeColor="warn">directions_walk
                    </mat-icon>
                  </span>
                </li>
              </ul>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-8 mt-3">
        <div class="features">
          <div fxFlex fxLayout="column">
            <div fxLayout="row" fxLayoutGap="20px" fxFlexAlign="end">
              <button class="btn playlist-btn-solid" (click)="addTrip()">
                Add
              </button>
              <button class="btn playlist-btn-solid" (click)="save()">Save</button>
            </div>
          </div>
        </div>
        <div class="border-width2">
          <div *ngFor="let trip of tripsList; let i=index">
            <div class="vender_div" style="height:276px;">
              <div class="panel panel-warning">
                <div class="panel-heading">
                  <div class="row">
                    <div class="col-sm-4">
                      Employee :{{trip.tripDetails.tripUsers.length}}
                    </div>
                    <div class="col-sm-4" *ngIf="trip.tripDetails.tripUsers.length>0;
                    else
                    notApplicable">
                      {{trip.tripDetails.shift.logType == "IN" ?
                      trip.tripDetails.tripUsers[0].landmark.area.area+",
                      "+trip.tripDetails.tripUsers[0].landmark.landmark :
                      trip.tripDetails.tripUsers[trip.tripDetails.tripUsers.length
                      - 1].landmark.area.area +", "+
                      trip.tripDetails.tripUsers[trip.tripDetails.tripUsers.length
                      - 1].landmark.landmark}}
                      <span *ngIf="typeFlag">
                        <mat-icon matBadge="{{trip.tripDetails.generatestatus}}"
                          matBadgeColor="warn">drive_eta</mat-icon>
                      </span>
                    </div>
                    <ng-template #notApplicable>
                      <div class="col-sm-4">
                        <span *ngIf="typeFlag">
                          <mat-icon matBadge="{{trip.tripDetails.generatestatus}}"
                            matBadgeColor="warn">drive_eta</mat-icon>
                        </span>
                      </div>
                    </ng-template>
                    <div class="col-sm-2" *ngIf="trip.tripDetails.tripstatus=='Deactive' &&
                    trip.tripDetails.tripUsers?.length; else
                    notActive">
                      <button type="button" class="btn btn-deinfo" (click)="deleteComb(trip)">Active</button>
                    </div>
                    <ng-template #notActive>
                      <div class="col-sm-2" *ngIf="trip.tripDetails.tripUsers?.length">
                        <button type="button" class="btn playlist-btn-solid1"
                          (click)="deleteComb(trip)">Deactive</button>
                      </div>
                    </ng-template>

                    <div class="col-sm-1 mt-2" *ngIf="trip.tripDetails.tripUsers?.length">
                      <span class="pointer" (click)="saveSingleTrip(trip)">
                        <img src="assets/images/save.svg" class="cursor" height="15" matTooltip="Save">
                      </span>
                    </div>

                    <div class="col-sm-1" *ngIf="trip.tripDetails.tripUsers?.length">
                      <mat-icon class="pointer" matTooltip="Preview" (click)="previewDialog(trip)">visibility</mat-icon>
                    </div>



                    <div class="col-sm-2" *ngIf="!trip.tripDetails.tripUsers?.length">
                      <button type="button" class="btn btn-info" (click)="deleteView(i)">Delete</button>
                    </div>
                  </div>
                </div>
                <div class="panel-body" style="min-height:200px !important;
                height:auto;" dnd-sortable-container [sortableData]="trip.tripDetails.tripUsers">
                  <ul class="list-group">
                    <li *ngFor="let user of trip.tripDetails.tripUsers; let i=
                    index" class="list-group-item" (onDragStart)="dragStarted(trip, i)"
                      (onDropSuccess)="onItemDrop(trip,user)" dnd-sortable [dragData]="user" [sortableIndex]="i">
                      {{user.tripUser?.userInfoDetails?.employeeId ?
                      user.tripUser?.userInfoDetails?.employeeId :
                      user?.employeeId}} -
                      {{user.tripUser?.userInfoDetails?.displayName ?
                      user.tripUser?.userInfoDetails?.displayName :
                      user?.displayName}} -
                      {{user.landmark.area.area}}, {{user.landmark.landmark}}
                      &nbsp; <span *ngIf="typeFlag">
                        <mat-icon matBadge="{{user.status ? user.status : 0}}" matBadgeColor="warn">directions_walk
                        </mat-icon>
                      </span>
                    </li>
                  </ul>
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>