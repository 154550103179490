<div class="tdw-padding-h-4"></div>
<div class="panel panel-default" style="margin-top:10px;">
    <div class="panel-body" style="position:relative">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th></th>
                        <th *ngFor="let vehicletype of _vehicleTypeData">
                            {{vehicletype.vehicleType}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let vendor of _vendorData">
                        <td>{{vendor.name}}</td>
                        <td *ngFor="let vehicletype of _vehicleTypeData">
                            <input type="number" [(ngModel)]="_data[vendor.id+vehicletype.id *2]">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end">
        <button class="btn btn-outline-danger" (click)="allocateVendorPercent()" class="margin5 plusbtn">Submit</button>
    </div>
</div>