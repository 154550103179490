<div fxLayout="row" fxLayoutAlign="start center">
    <h4 class="font-size-14" fxFlex>Upload Summary :</h4>
    <button mat-icon-button color="accent" (click)="onClickCancel()">
        <mat-icon>clear</mat-icon>
    </button>
</div>

<hr class="hr1">
<div class="padding"></div>

<div *ngIf="errList.length > 0">
    <mat-card class="mat-card-container">
        <div *ngIf="errList.length>0">
            <div *ngFor="let errorList of errList">
                <p>{{errorList.comments}},</p>
            </div>
        </div>
    </mat-card>
</div>

<div class="padding-top"></div>
<div *ngIf="errList.length==0">
    <mat-card class="mat-card-container">
        Upload Success
    </mat-card>
    <div class="padding"></div>
</div>