<div class="mat-modal-dialog holiday-modal">
    <mat-dialog-content>
        <span class="material-icons-outlined" style="color: #FF0000; position: relative; top: 5px; margin-right: 10px;">
            info</span> {{mData}}
    </mat-dialog-content>

    <div mat-dialog-actions align="end" style="position: relative; right: 15px;">
        <button (click)="onClickCancel()" class="btn footerBtn secondary-btn-solid me-3">
            Deny
        </button>
        <button class="btn footerBtn red-btn-solid" (click)="onSubmit()">
            Allow
        </button>
    </div>
</div>