import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { ApiService } from 'src/app/core/services/api.service';
import { ServiceExportExcel } from 'src/app/core/services/export.service';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ModelTableHeader } from 'src/app/shared/models/table-header';


@Component({
  selector: 'app-special-category-setup',
  templateUrl: './special-category-setup.component.html',
  styleUrls: ['./special-category-setup.component.scss']
})
export class SpecialCategorySetupComponent implements OnInit {

  selectedval: any = '';
  show: any;
  pdfUrl: any;
  mIsEdit: any;
  searchData: any;
  _mIsLoading = true;
  _mIsNoPagination = false;
  toggleStatus = true;
  _mSitesData: any[] = [];
  _mSitesDataFlag = true;
  _mCategoryData: any[] = [];
  _mCategoryFlag = true;
  _mVehicletypeData: any[] = [];
  _mVehicletypeFlag = true;
  _mVehiclesData: any[] = [];
  _mVehiclesFlag = true;
  public _mFormGroup: FormGroup;
  public mLocation = new FormControl('');
  public mSite = new FormControl('');
  public mCategory = new FormControl('');
  public mVehicles = new FormControl('');
  searchkey: any = '';
  selectedSite: any;
  _mTotalPage = 1;
  _mCurrentNumber = 0;
  _mTotalElements = 0;
  _mSize = 0;
  _mColumns: ModelTableHeader[];
  pushedValues: any[] = [];
  unPushedValues: any[] = [];
  pushedids: any[] = [];
  rows = [];

  itemsPerPage = 50;
  itemsShowingFromNo = 0;
  itemsShowingToNo = 0;
  selectedType: any;
  selectedType1: any;
  selectedType2: any;
  showFlag = false;
  cities: any[] = [];

  _mData: any[] = [];
  _mDataForExportToExcel: any[] = [];


  _mFormattedData: any[] = [];
  selectedSiteData: any;
  selectedsite: any;


  constructor(
    private fb: FormBuilder,
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    private readonly serviceExportExcel: ServiceExportExcel,
    private readonly localStorage: ServiceLocalStorage,

    private ngxLoader: NgxUiLoaderService) {

    this._mFormGroup = this.fb.group({
      'location': this.mLocation,
      'site': this.mSite,
      'category': this.mCategory,
      'vehicles': this.mVehicles
    });
  }

  ngOnInit() {
    this.loadCities();
    this.show = false;
    this.getCategory();
  }

  loadCities() {
    let companyid: number = +this.localStorage.getCompanyId();
    this.apiService.doGetRequestWithResponse(ApiConstants.getLocationsByCompanyIdAndUser +
      companyid,
      success => {
        this.cities = success.body;
      },
      error => {
      }
    );
  }
  getSitesByCityId(cityId) {
    this._mSitesDataFlag = true;
    if(cityId == null ||cityId == undefined )
    {
      return;
    }
    this.apiService.doGetRequestWithResponse(ApiConstants.getSitesByLocationId +
      cityId,
      success => {
        this._mSitesDataFlag = false;
        if (success != null) {
          this._mSitesData = success.body.dataList.content;
        } else {
          this._mSitesData = [];
          this.notifyService.showInfo("No Sites Found!", null);
        }
      },
      error => {
        this._mSitesDataFlag = false;
      }
    );
  }
  getCategory() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getAllCategory, (success) => {
      this._mCategoryFlag = false;
      this._mCategoryData = success.body;

    }, (error) => {
      this._mCategoryFlag = false
    });
  }

  showMapping(selectedsite, selectedval) {
    this.show = true;
    this.pushedValues = [];
    this.unPushedValues = [];
    this.rows = [];
    if (selectedval === undefined) {
      this.notifyService.showError('Please select pickup point type!', null);
    } 
    else {
      this._mSitesData.forEach(element => {
        if (element.siteId == this.mSite.value) {
          this.selectedSiteData = element;
        }
      });
      this.selectedType = selectedval;
      this.selectedType1 = this.selectedSiteData;
      this.getAllVehicleTypes();
      this.showFlag = true;
    }
  }

  getAllVehicleTypes() {
    this.pushedValues = [];
    this.unPushedValues = [];
    this.rows = [];
    this.selectedType1.vehicleTypes.forEach(vehicleType => {
      let vehicleSeatCapacity = [];
      for (let i = 1; i < vehicleType.seatCapacity; i++) {
        vehicleSeatCapacity.push({ value: i })
      }
      this.rows.push({
        id: vehicleType.id,
        value: vehicleType.vehicleType,
        seatCapacity: vehicleSeatCapacity,
        allocatedSeats: 1
      });
    });
    this.getMapping();
  }

  getMapping() {
    this.pushedValues = [];
    this.unPushedValues = [];
    let params = {
      "siteid": this.selectedType1.siteId,
      "zoneid": this.selectedType
    }
    if (this.mSite.value != null) {
      this.apiService.doGetRequestWithResponse(ApiConstants.getspecialcategorybysiteidandspecialcategoryid + "?siteid=" + this.selectedType1.siteId + "&specialcategoryid=" + this.selectedType, (success) => {
        if (success.status == 200) {
          success.body.forEach(vehicleType => {
            let vehicleSeatCapacity = [];
            for (let i = 1; i < vehicleType.seatCapacity; i++) {
              vehicleSeatCapacity.push({ value: i })
            }
            this.rows = this.rows.filter(r => r.id !== vehicleType.id);
            const sel = {
              id: vehicleType.id,
              value: vehicleType.vehicleType,
              seatCapacity: vehicleSeatCapacity,
              allocatedSeats: vehicleType.allocatedSeats
            };
            this.pushedValues.push(sel);
            this.unPushedValues.push(sel);
            this.unPushedValues.forEach(x => {
              x.state = 'checked';
            });
          });
        }
      }, (error) => {
        this.notifyService.showError('Error in Fetching Mapping!', null);

      });
    }

  }

  saveMapping() {
    if (this.selectedType === undefined) {
      this.notifyService.showError('Please select pickup point type!', null);
    } else {
      this.showFlag = false;
      let pushedids = [];
      this.unPushedValues.forEach(pushid => {
        pushedids.push({ id: pushid.id, seatCapacity: pushid.allocatedSeats });
      });
      if (pushedids == null) {
        pushedids = [];
      }
      let data = { categoryid: this.mCategory.value, siteid: this.mSite.value, vehicleTypes: pushedids }
      if (this.mSite.value != null) {
        this.apiService.doPostRequestWithResponse(ApiConstants.addSpecialCategory + "?categoryid=" + this.mCategory.value +
          '&siteid=' + this.mSite.value,
          data.vehicleTypes, (success) => {
            if (success.status === 201) {
              this.notifyService.showSuccess('Mapping updated successfully!', null);
              // this._mFormGroup.reset();
              this.ngOnInit();
              // this.mlo
              this.mLocation.setValue('');
              this.mSite.setValue('');
              this.mCategory.setValue('');

            
              this.pushedValues = [];
              this.unPushedValues = [];
              this.rows = [];
            } else if (success.status === 204) {
              this.notifyService.showSuccess('Mapping updated successfully!', null);
              this._mFormGroup.reset();
              this.ngOnInit();
              this.mLocation.setValue('');
              this.mSite.setValue('');
              this.mCategory.setValue('');
              // this.mSite.setValue(null);
              // this.mMasterZone.setValue(null);
              // this.mZone.setValue(null);
              this.pushedValues = [];
              this.unPushedValues = [];
              this.rows = [];
            }
          }, (error) => {
            this.notifyService.showError('Failed to Updated!', null);
          });
      }

    }
  }


  isAllChecked() {
    return this.rows.every(_ => _.state);
  }

  checkAll(checks) {
    if (checks.checked) {
      this.rows.forEach(x => {
        x.state = checks.checked;
        this.onValueChecked({ checked: true }, x);
      });
    }
  }

  onValueChecked(checked, sel) {
    if (checked.checked) {
      this.pushedValues.push(sel);
      this.rows = this.rows.filter(r => r.id !== sel.id);
      this.unPushedValues.push(sel);
    }
  }


  unCheckAll(checks) {
    if (!checks.checked) {
      this.unPushedValues.forEach(x => {
        x.state = checks.checked;
        this.onSelValuesChecked({ checked: false }, x);
      });
    }
  }

  onfieldchange() {
    this.showFlag = false;
  }

  onSelValuesChecked(checked, unselect) {
    if (!checked.checked) {
      this.rows.push(unselect);
      this.unPushedValues = this.unPushedValues.filter(r => r.id !== unselect.id);
      this.pushedValues.push(unselect);
    }
  }

  onSelValueChecked(checked, unselect) {
    if (!checked.checked) {
      this.rows.push(unselect);
      this.unPushedValues = this.unPushedValues.filter(r => r.id !== unselect.id);
      this.pushedValues.push(unselect);
    }
  }

  onSelectedSeatCapacity(value, row) {
    this.unPushedValues.forEach((element, i) => {
      if (element.id == row.id) {
        this.unPushedValues[i].allocatedSeats = value;
      }
    });
  }
}
