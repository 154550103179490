import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse,
  HttpXsrfTokenExtractor,
} from "@angular/common/http";
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { ServiceLocalStorage } from "./local-storage.service";
import { environment } from "src/environments/environment";
import { ApiConstants } from "../constants/api-path.constants";
import { AppConstants } from "../constants/appconstants";
import { RouterConstants } from "../constants/router.constants";
import { ApiService } from "./api.service";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  state: any;

  constructor(
    private readonly router: Router,
    private readonly serviceLocalStorage: ServiceLocalStorage,
    private tokenExtractor: HttpXsrfTokenExtractor,
    private readonly apiService: ApiService,
    private readonly notifyService: NotificationService
  ) {
    this.state = this.router.routerState.snapshot;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let url = request.url;
    let loginUrl = environment.baseUrl + ApiConstants.login;
    let loginOtpUrl = environment.baseUrl + ApiConstants.getLoginOtp;
    let forgotpassword = environment.baseUrl + ApiConstants.forgotPassword;
    let updateUser = environment.baseUrl + ApiConstants.updateUser;
    let getUserById = environment.baseUrl + ApiConstants.getUserById;
    let userRegistration = environment.baseUrl + ApiConstants.userRegistration;
    let renewJwt = environment.baseUrl + ApiConstants.renewJWT;
    let addTempUser = environment.baseUrl + ApiConstants.addTempUser;
    let getTempUser = environment.baseUrl + ApiConstants.getTempUser;
    let getlocationlist = environment.baseUrl + ApiConstants.getlocationlist;
    let getsiteslist = environment.baseUrl + ApiConstants.getsiteslist;
    let getAreaslist = environment.baseUrl + ApiConstants.getAreaslist;
    let getLandmarkslist = environment.baseUrl + ApiConstants.getLandmarkslist;
    let ssoLoginUrl = environment.baseUrl + ApiConstants.getSsoLoginUrl;
    const requestMethod: string = request.method.toLowerCase();

    // if (requestMethod && (requestMethod === 'get' || requestMethod === 'post' || requestMethod === 'delete' || requestMethod === 'put')) {
    //   const headerName = 'X-XSRF-TOKEN';
    //   const token = this.tokenExtractor.getToken() as string;
    //   if (token !== null && !request.headers.has(headerName)) {
    //     request = request.clone({ headers: request.headers.set(headerName, token) });
    //   }
    // }

    if ((!url.includes(loginUrl) && !url.includes(loginOtpUrl) && !url.includes(forgotpassword) && !url.includes(updateUser) &&
      !url.includes(renewJwt) && !url.includes(getUserById) && !url.includes(addTempUser) && !url.includes(getTempUser) &&
      !url.includes(getlocationlist) && !url.includes(getsiteslist) && !url.includes(getAreaslist) && !url.includes(getLandmarkslist)
      && !url.includes(userRegistration) && !url.includes(ssoLoginUrl)) || (url.includes("/livetrack/api/"))) {
      request = request.clone({
        setHeaders: { Authorization: AppConstants.BEARER_STRING + this.serviceLocalStorage.getAuthToken() }
      });
    }


    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        //Take token form login api response and store it
        if (event.status === 200 && (url === loginUrl || url == ssoLoginUrl)) {
          const bearerToken = event.headers.get(AppConstants.AUTH_TOKEN_STRING);
          if (bearerToken !== null && bearerToken.length > 0 && bearerToken.slice(0, AppConstants.BEARER_STRING.length) === AppConstants.BEARER_STRING) {
            this.serviceLocalStorage.setAuthToken(bearerToken.slice(AppConstants.BEARER_STRING.length, bearerToken.length));
            const refreshToken = event.headers.get(AppConstants.Refresh_Token);
            if (refreshToken !== null && refreshToken.length > 0) {
              this.serviceLocalStorage.setRefToken(refreshToken);
            }
          } else {
            console.error("Unable to fetch token");
          }
        }
      }
    }), catchError((response: any) => {
      return this.handleError(response);
    }));
  }

  /**
     * Handle errors and give proper meaning msg to the user
     * @param error : Returns meaningful msg
  */

  handleError(error: HttpErrorResponse) {
    let errorData = {
      message: '',
      status: 0
    };
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorData.message = "A client-side or network error occurred.";
    } else {
      if (error.status == 400) {
        if (error.error.message == 'Malformed Token') {
          errorData.message = "Session Expired, Please login again.";
          this.router.navigate(['/auth']);
        } else if (error.error.message == 'Not Subscribed') {
          errorData = error.error;
        } else if (error.error.message == 'Check ToDate') {
          errorData = error;
        } else {
          errorData.message = error.error ? error.error.message ? error.error.message : error.error.error.message : "Empty error..";
          this.notifyService.showError(errorData.message, null);
        }
      } else if (error.status == 401) {
        var clientCode = localStorage.getItem("clientCode");
        if (error.error.message === "Session Expired") {
          errorData.message = error.error.message;
          this.serviceLocalStorage.deleteAll();
          this.router.navigate(['/' + RouterConstants.auth])
          this.notifyService.showError("Session Expired, Please login again", null);
        }
        if (error.error.message === "Authentication Failed: SSO-MFA Enabled") {
          localStorage.setItem('mfaEnabled', 'true');
          this.router.navigate([
            "/" + RouterConstants.auth + "/" + RouterConstants.empVendor,
          ]);
          return;
        }
        if (error.error.message === "Authentication Failed: MFA Enabled") {
          localStorage.setItem('mfaEnabled', 'true');
          errorData.message = "MFA Enabled - Wrong Credentials";
          return observableThrowError(errorData);
          this.router.navigate(['/' + RouterConstants.auth + '/' + RouterConstants.login])
        }
        if (error.error.message == "Authentication Failed: Bad credentials") {
          errorData.message = "Bad-Credentials";
          if (localStorage.getItem('checkuidPw') == 'true') {
            errorData.message = "Wrong UserName / Password";
            return observableThrowError(errorData);
          }
          this.router.navigate(['/' + RouterConstants.auth + '/' + RouterConstants.login])
        } else if (error.error.message == "Authentication Failed: Not able to find tenant DB") {
          errorData.message = "Wrong Client Code";
          return observableThrowError(errorData);
        } else if (error.error.message == "Authentication Failed: Not able to find tenant DB") {
          errorData.message = "Wrong UserName / Password";
          return observableThrowError(errorData);
        } else if (error.error.message == "Account blocked!") {
          errorData.message = "Account blocked due to multiple attempts. Please try again after 15 mins";
          return observableThrowError(errorData);
        } else if (error.error.message == "User is disabled") {
          errorData.message = "User is disabled. Please Contact Admin"
          return observableThrowError(errorData);
          this.notifyService.showError(errorData.message, null);
        } else if (error.error.message.includes("Authentication Failed: SSO Enabled :")) {
            this.router.navigate([
              "/" + RouterConstants.auth + "/" + RouterConstants.empVendor,
            ]);
          // var ssoLogIn = environment.ssoLogIn;
          // window.location.href = ssoLogIn.replace(new RegExp('{clientCode}', 'g'), clientCode);
          return;
        } else if (error.error.message === "Expired Token") {
          // if (error.error.message === "Expired Token") {
          // if (this.serviceLocalStorage.hasAuthToken()) {
          //   let refreshToken = this.serviceLocalStorage.getRefToken();
          //   let userId = this.serviceLocalStorage.getUserId();
          //   let clientCode = this.serviceLocalStorage.getJsonValue("clientCode");
          //   if (refreshToken == null || userId == null || clientCode == null) {
          //     return;
          //   }
          //   let data = {
          //     "refreshToken": refreshToken,
          //     "userId": userId
          //   }
          //   this.apiService.doPostWithResponseRenewJWT(data, clientCode, '', (success: any) => {
          //     if (success.status == 200) {
          //       let bearerToken = success.body.accessToken;
          //       if (bearerToken !== null && bearerToken.length > 0 && bearerToken.slice(0, AppConstants.BEARER_STRING.length) === AppConstants.BEARER_STRING) {
          //         this.serviceLocalStorage.setAuthToken(bearerToken.slice(AppConstants.BEARER_STRING.length, bearerToken.length));
          //       }
          //     }
          //   }, (error: any) => {
          //     this.notifyService.showWarning("Session expired. Please re-login to continue", null);
          //     this.serviceLocalStorage.deleteAll();
          //     this.router.navigate(['/auth'])
          //   });
          // }
          errorData.message = "Expired Token";
          // this.serviceLocalStorage.deleteAll();
          // this.router.navigate(['/auth']);
        }
        errorData.message = "Unauthourized access";
        // this.serviceLocalStorage.deleteAll();
        // this.router.navigate(['/auth'])
        if (clientCode != null) {
          localStorage.setItem("clientCode", clientCode);
        }
      } else if (error.status == 403) {
        errorData.message = "Error in getAuthentication";
      } else if (error.status == 404) {
        errorData.message = "Server not found";
      } else if (error.status == 406) {
        errorData.message = error.error.message;
      } else if (error.status == 409) {
        errorData.message = error.error.message;
      } else if (error.status == 500) {
        errorData.message = "Server error";
        errorData.status = error.status;
      } else if (error.status == 504) {
        errorData.message = "Request Timed out, Please try again later";
        errorData.status = error.status;
      } else {
        errorData.message = "Network error occured. Please try again later.";
      }
    }
    return observableThrowError(errorData);
  }
}