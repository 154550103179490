<div fxLayout="row">
    <h4 class="font-size-14" fxFlex ></h4>
    <ng-template #elseBlock>Audit Log</ng-template>
    <button mat-icon-button color="primary" (click)="onClickCancel()">
        <mat-icon color="warn">clear</mat-icon>
    </button>
</div>

<mat-divider></mat-divider>

<table class="table font-size-14 leaderboard-table my-4">
    <thead class="py-3">
        <tr>
            <th>
                Site
            </th>
            <th>
                Updated_By
            </th>
            <th>
                Updated_Date_Time
            </th>
            <th>
                Status
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of _mData">
            <td>
                {{item.site.siteName}}
            </td>
            <td>
                {{item.updatedBy.userInfoDetails.firstName}}
            </td>
            <td>
                {{item.updateOn}}
            </td>
            <td>
                {{item.status}}
            </td>
        </tr>
    </tbody>
</table>