<div>
  <div class="card">
    <div class="card-body">
      <!-- <div class="row" fxLayoutAlign="start center">
      <h4 class="mat-color-text--grey-700" fxFlex>{{_mTitle}}</h4>
      <button mat-icon-button color="accent" (click)="onClickCancel()">
        <mat-icon>clear</mat-icon>
      </button>
    </div> -->
      <div class="mat-modal-dialog city-modal">
        <h2 mat-dialog-title>
          <span class="font-size-18">{{_mTitle}}</span>
          <span class="material-icons-outlined" (click)="onClickCancel()"
            style="color:#FF455B; font-size: 25px; float: right; position: relative; top: 5px; cursor: pointer">close</span>
        </h2>
      </div>

      <form [formGroup]="_mFormGroup">
        <div class="row mt-4">
          <div class="col-sm-12 col-md-6">

            <!-- <label>Vehicle</label>
            <select  class="form-select" placeholder="Vehicle" formControlName="vehicle">
              <option value='' hidden disabled>Choose Vehicle</option>
              <option *ngFor="let item of mVehicleList" [value]="item.id">
                {{ item.registrationNo }}
              </option>
            </select> -->
            <!-- <mat-error *ngIf="mVehicle.hasError('required')">
              Please select vehicle
            </mat-error> -->

            <mat-form-field class="filedProp" appearance="outline">
              <mat-label>Vehicle</mat-label>
              <input style="font-size: 12px;" type="text" placeholder="Vehicle" matInput formControlName="vehicle"
                [matAutocomplete]="auto" (input)="searchSubject$.next($event.target.value)">
              <img matSuffix src="assets/images/search.svg" height="27" style="margin-top: -10px;padding-right: 5px;">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let item of mTempVehicleList" [value]="item.registrationNo"
                  (onSelectionChange)="searchSubmit(item)"> {{ item.registrationNo }}
                </mat-option>
                <!-- <mat-option *ngFor="let option of user" [value]="option.userInfoDetails.displayName"
                      (onSelectionChange)="searchSubmit(option)">
                      {{option.userInfoDetails.employeeId}}-{{option.userInfoDetails.displayName}}</mat-option> -->
              </mat-autocomplete>
              <mat-hint style="white-space: nowrap;">Search key >= 3 characters.</mat-hint>
            </mat-form-field>
          </div>

          <div class="col-sm-12 col-md-6">
            <!-- <label>Driver</label>
            <select  class="form-select" placeholder="Driver" formControlName="driver">
              <option value='' hidden disabled>Choose Driver</option>
              <option *ngFor="let item of mDriverList" [value]="item.id">
                {{ item.name }}
              </option>
            </select> -->
            <!-- <mat-error *ngIf="mDriver.hasError('required')">
              Please select driver
            </mat-error> -->

            <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
              <mat-label>Driver</mat-label>
              <mat-select placeholder="Driver" formControlName="driver">
                <mat-option *ngFor="let item of mDriverList" [value]="item.id">
                  {{ item.name }}
                </mat-option>
              </mat-select>
              <!-- <mat-error *ngIf="mVehicleType.hasError('required')">
            Please select vehicle type
          </mat-error> -->
            </mat-form-field>

          </div>

        </div>
        <div class="hr-line"></div>
        <div class="row mt-4">
          <div class="col-sm-12 mt-5">

            <!-- <button mat-raised-button (click)="onClickCancel()" class="tdw-hover-button-secondary
                        tdw-button-padding-8 tdw-border-2">
            CANCEL
          </button> -->

            <button mat-raised-button (click)="onMap()" class="col-md-4 btn red-btn-solid" style="float: right;">
              MAP
            </button>
          </div>

        </div>
      </form>
    </div>
  </div>
</div>