<div>
  <div class="d-flex">
    <button type="button" class="btn btn-primary1" (click)="onCancel()">
      <span class="back-light"><em class="mdi mdi-arrow-left me-2"></em> Back </span>
    </button>
    <div>
      <h4 class="mt-2 font-size-14"><span>/ {{_mTitle}}</span></h4>
    </div>
  </div>

  <div class="hr-line"></div>

  <form [formGroup]="_mFormGroup">
    <div class="column">
      <div class="row mt-3">
        <div class="col-sm-3">
          <label>Type</label>
          <select class="form-select margin-right-8px" placeholder="Type" formControlName="type"
            (ngModelChange)="onTypeChange()" required>
            <option disabled value="">Choose Type</option>
            <option *ngFor="let type of _mTypes" [value]="type.value">
              {{ type.value }}
            </option>
          </select>
        </div>
        <div *ngIf="_mRouteFlag" class="col-sm-3">
          <label>Route name</label>
          <input class="form-input margin-right-8px" matInput placeholder="Route name" autocomplete="off"
            formControlName="routeName">
        </div>

        <div class="col-sm-3" *ngIf="_mAreaFlag ||
          _mLandmarkFlag">
          <label>Search Route</label>
          <mat-form-field appearance="outline" class="fixedDate">
            <input matInput placeholder="Route Name" [matAutocomplete]="routeauto" formControlName="routeSearch">
            <img matSuffix src="assets/images/search.svg" height="27" style="margin-top: -22px;padding-right: 5px;">
            <mat-autocomplete #routeauto="matAutocomplete">
              <mat-option *ngFor="let route of _mRoutesData" [value]="route.routeName"
                (onSelectionChange)="routeSearchSubmit(route)">{{route.routeName
                }}</mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="mRouteSearch.hasError('required')">
              Please select route
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3" *ngIf="_mLandmarkFlag">
          <label>Landmark type</label>
          <select class="form-select margin-right-8px" placeholder="Type" formControlName="landmarkType"
            (ngModelChange)="onLandmarkTypeChange()" required>
            <option disabled value="">Choose Landmark Type</option>
            <option *ngFor="let landmarkType of _mLandmarkTypes" [value]="landmarkType.key">
              {{ landmarkType.value }}
            </option>
          </select>
        </div>

        <div class="col-sm-3 mt-4" *ngIf="_mRouteFlag">
          <label>Route type</label>
          <select class="form-select margin-right-8px" placeholder="Select RouteType" formControlName="routeType"
            autocomplete="off" required>
            <option disabled value="">Choose RouteType</option>
            <option *ngFor="let routeType of _mRouteTypes" [value]="routeType.key">
              {{ routeType.value}}
            </option>
          </select>
        </div>

        <div class="col-sm-3" *ngIf="_mAreaFlag">
          <label>Search Area</label>
          <ng-multiselect-dropdown  class="areaMultiSel" [placeholder]="'Search
            Area'" formControlName="areaSearch" [data]="_mAreasData" [settings]="dropdownSettingsForArea"
            (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
          </ng-multiselect-dropdown>
        </div>

          <div class="col-sm-3" *ngIf="_mLandmarkFlag">
            <label>Search Landmark</label>
          <ng-multiselect-dropdown class="areaMultiSel" [placeholder]="'Search
            landmarks'" formControlName="landmarkSearch" [data]="_mLandmarksData" [settings]="dropdownSettings"
            (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
          </ng-multiselect-dropdown>
        </div>
      </div>
      <div class="col-12 mt-5" style="text-align: end;">
        <button type="button" class="btn btn-danger1 w-lg" (click)="onSubmit()">
          Save
        </button>
      </div>
    </div>
  </form>

</div>