import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from "@angular/forms";
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { AppConstants } from 'src/app/core/constants/appconstants';
import { NotificationService } from 'src/app/core/services/notification.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiService } from 'src/app/core/services/api.service';
import { AddVehicleComponent } from 'src/app/pages/operations/booking-layout/on-call-booking/add-on-call-booking/add-vehicle/add-vehicle/add-vehicle.component';
import { MapDriverVehicleComponent } from 'src/app/pages/operations/booking-layout/on-call-booking/add-on-call-booking/map-driver-vehicle/map-driver-vehicle/map-driver-vehicle.component';
import { AddDriverComponent } from 'src/app/pages/operations/booking-layout/on-call-booking/add-on-call-booking/add-driver/add-driver/add-driver.component';

@Component({
  selector: 'app-on-call-booking-alloc',
  templateUrl: './on-call-booking-alloc.component.html',
  styleUrls: ['./on-call-booking-alloc.component.scss']
})
export class OnCallBookingAllocComponent {

  public _mFormGroup: FormGroup;
  user: any;
  usersNotFound: boolean;
  _mSitesData: any;
  _mVendors: any[] = [];
  _mEscort: any[] = [];
  _mProjects: any[] = [];
  _mBookingTypes: any[] = [];
  _mData: any;
  _BookedForUser: any;
  _mVehicleTypes: any[] = [];
  currentStatus: any = "";
  pastDate = new Date();
  toDatePickerMin = new Date(this.pastDate.getTime() - (15 * 24 * 60 * 60 * 1000));
  _mUserData: any[] = [];
  _mTempUserData: any[] = [];
  empSearchFlag: boolean = false;
  employeeName: any;
  empRoleFlag: boolean = false;
  editFag: boolean = false;
  specialBookingId: any;
  bookedById: any;
  _mEditReportingTimes: any[] = AppConstants.reportingTimes;
  minDate: Date;
  webToken: any;
  fromAdd: any;
  toAdd: any;
  fromLocation: any;
  toLocation: any;
  locationTypes = ['Home', 'Office', 'Other Location'];
  selectedFromLocType: String;
  selectedToLocType: String;
  _mTempLandmarksData: any[] = [];
  _mLandmarksData: any[] = [];
  fromOther: boolean = false;
  toOther: boolean = false;
  empFlag: boolean = false;
  mVehicleList: any[] = [];
  mDriverList: any[] = [];
  allowAdminDriverAloc: boolean = false;
  mUserData: any;
  _mTempVehicle: any[] = [];
  _mTempDriver: any[] = [];
  mTempVehicleList: any[] = [];
  selectedVehicleId: any;
  onCallFlag: boolean = true;

  public mUserName = new FormControl("", Validators.required);
  public mEmployeeId = new FormControl();
  public mSites = new FormControl("", Validators.required);
  public mVendor = new FormControl("", Validators.required);
  public mBookingType = new FormControl("", Validators.required);
  public mTravelDate = new FormControl(new Date(), Validators.required);
  public mLogType = new FormControl("", Validators.required);
  public mReportingTime = new FormControl("", Validators.required);
  public mEscortRequired = new FormControl(false, Validators.required);
  public mEscort = new FormControl("", Validators.required);
  public mReason = new FormControl("");
  public mProject = new FormControl("", Validators.required);
  public mVehicleType = new FormControl("", Validators.required);
  public mfromLoc = new FormControl("", Validators.required);
  public mToLoc = new FormControl("", Validators.required);
  public mFromLandmark = new FormControl("", Validators.required);
  public mToLandmark = new FormControl("", Validators.required);
  public vehicle = new FormControl("");
  public mDriver = new FormControl("");
  public mActiveVehicleTypeRequired = new FormControl(false);
  public mNoOfEmp = new FormControl("", Validators.required);
  public mLocation = new FormControl("", Validators.required);
  allowtochoosebylocation: boolean = false;

  reportingTimes: any[] = [];
  searchSubject$ = new Subject<string>();

  reportingTime =
    new Date(this.mTravelDate.value).getHours() +
    "" +
    new Date(this.mTravelDate.value).getMinutes();
    locationList: any[] = [];

  constructor(
    private fb: FormBuilder,
    private serviceLocalStorage: ServiceLocalStorage,
    public dialogRef: MatDialogRef<OnCallBookingAllocComponent>,
    public dialog: MatDialog,
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ngxLoader: NgxUiLoaderService,
  ) {
    this._mData = this.serviceLocalStorage.getUserObject();
    this.mUserData = data.userData;
    if (data != null) {
      if (data.userData != null && data.vendorAccessFlag == true) {
        this.specialBookingId = data.userData.id;
        this.bookedById = data.userData.bookedBy.id;
        this.empRoleFlag = true;
        this.empFlag = data.employeeAccessFlag;
        this.editFag = true;
        this.mUserName.disable();
        this.mSites.disable();
        this.mLocation.disable();
        this.mLogType.disable();
        this.mTravelDate.disable();
        this.mReportingTime.disable();
        this.mFromLandmark.disable();
        this.mToLandmark.disable();
        this.mfromLoc.disable();
        this.mToLoc.disable();
        this.mReason.disable();
        this.mProject.disable();
        this.mBookingType.disable();
        this.mVendor.disable();
        this.mTravelDate.setValue(new Date(this.formatDatePickerDate(data.userData.travelDate.split(" ")[0])));
        this.mUserName.setValue(data.userData.bookedFor.userInfoDetails.firstName);
        this.mEmployeeId.setValue(data.userData.bookedFor.userInfoDetails.employeeId);
        this.mLocation.setValue(data.userData.site.location.locationId);
        this.mSites.setValue(data.userData.site.siteId);
        this.allowToChooseByLocation();
        this.allDriverAlocForAdmin();
        this.mBookingType.setValue(data.userData.BookingType);
        this.mVendor.setValue((data.userData.vendorMaster) ? data.userData.vendorMaster.id : null);
        this.mEscortRequired.setValue(data.userData.escortRequired);
        this.mEscort.setValue((data.userData.escort) ? data.userData.escort.id : null);
        if (!data.userData.escortRequired) {
          this.mEscort.disable();
        }
        this.mLogType.setValue(data.userData.pickDrop);
        this.mVehicleType.setValue((data.userData.vehicleType) ? data.userData.vehicleType.id : null);
        this.mProject.setValue(data.userData.project.projectId);
        this.mReason.setValue(data.userData.reason);
        this.mReportingTime.setValue(data.userData.reportingTime);
        this._BookedForUser = data.userData.bookedFor;
        this.selectedFromLocType = data.userData.fromLocType;
        this.mfromLoc.setValue(data.userData.fromLocType);
        this.selectedToLocType = data.userData.toLocType;
        this.mToLoc.setValue(data.userData.toLocType);
        this.vehicle.setValue((data.userData.vehicle) ? data.userData.vehicle.registrationNo : null);
        this.selectedVehicleId = (data.userData.vehicle) ? data.userData.vehicle.id : null
        this.mDriver.setValue((data.userData.driver) ? data.userData.driver.id : null);
        this.mNoOfEmp.setValue(data.userData.noOfEmployees);
        this.mNoOfEmp.disable();
        if (data.userData.fromLocType == 'Other Location') {
          this.mFromLandmark.setValue(data.userData.fromAddress);
        }
        if (data.userData.toLocType == 'Other Location') {
          this.mToLandmark.setValue(data.userData.toAddress);
        }
        if (data.userData.vehicleType != null) {
          // this.mVehicleType.disable();
          // this.mActiveVehicleTypeRequired.disable();
          this.mActiveVehicleTypeRequired.setValue(data.userData.vehicleType.isOnCallBooking);
          this.onCallFlag=data.userData.vehicleType.isOnCallBooking;
        }

      } else {
        this.mVehicleType.disable();
        this.mActiveVehicleTypeRequired.disable();
        this.mVendor.disable();
        this.mEscortRequired.disable();
        this.mEscort.disable();
        this.mLogType.disable();
      }
    }
    this.mLogType.disable();

    this._mFormGroup = this.fb.group({
      userName: this.mUserName,
      employeeId: this.mEmployeeId,
      location: this.mLocation,
      site: this.mSites,
      vendor: this.mVendor,
      bookingType: this.mBookingType,
      travelDate: this.mTravelDate,
      logType: this.mLogType,
      reportingTime: this.mReportingTime,
      escortRequired: this.mEscortRequired,
      escort: this.mEscort,
      reason: this.mReason,
      project: this.mProject,
      vehicleType: this.mVehicleType,
      fromLoc: this.mfromLoc,
      toLoc: this.mToLoc,
      fromLandmark: this.mFromLandmark,
      toLandmark: this.mToLandmark,
      vehicle: this.vehicle,
      driver: this.mDriver,
      activeVehicleType: this.mActiveVehicleTypeRequired,
      noOfEmp: this.mNoOfEmp
    });

    this.vehicle.valueChanges.subscribe(searchValue => {
      if (searchValue) {
        this.mTempVehicleList = this.mVehicleList.filter(vehicle => vehicle.registrationNo.toLowerCase().includes(searchValue.toLowerCase()));
      }
    });

    this.mFromLandmark.disable();
    this.mToLandmark.disable();

  }

  ngOnInit(): void {
    this.getSites();
    // this.requestServerAllVendor();
    this.getProjects();
    this.getBookingTypes();
    this.getTime();
    this.mEscortRequired.enable();
    this.mEmployeeId.disable();
    // if(this.mUserData.onCallFlag == true)
    // {
      // this.requestServerAllVehiclesOnCall();
    // }
    // else
    // {
    //   this.requestServerAllVehicleTypes();
    //   this.getVehicleAndDrivers();
    // }
  }

  allDriverAlocForAdmin() {
    this.apiService.doGetRequestWithResponse(ApiConstants.allowDriverAlocForAdmin + this.mSites.value,
      success => {
        this.allowAdminDriverAloc = success.body.allowDriverAloc;
        if (this.allowAdminDriverAloc) {
          this.vehicle.disable();
          this.mDriver.disable();
          this.mVehicleType.disable();
          this.mEscortRequired.disable();
          this.mEscort.disable();
        } else {
          this.vehicle.enable();
          this.mDriver.enable();
          this.mVehicleType.enable();
          this.mEscortRequired.enable();
          // this.mEscort.enable();
        }
      },
      error => {
      }
    );
  }

  requestServerAllVehicleTypes() {
    if (this.allowtochoosebylocation) {
      this.getVehicleTypeByLocationId();
    } else {
      this.apiService.doGetRequestWithResponse(ApiConstants.getVehicleTypesBySiteId + "?siteId=" + this.mSites.value,
      success => {
        if (success.status == 200) {
          this._mVehicleTypes = success.body;
          this._mVehicleTypes = this._mVehicleTypes.filter(
            vehicletype => vehicletype.status == AppConstants.ACTIVE_STATUS
          );
        } else if (success.status == 204) {
          this.notifyService.showInfo("No Vehicles Found", null);
        }
      },
      error => {
        this.notifyService.showError(error.message, null);
      }
    );
    }
  }

  getTime() {
    this.reportingTimes = [];
    AppConstants.reportingTimes.forEach(element => {
      if (
        element.value.slice(0, 2) + "" + element.value.slice(3, 5) >
        this.reportingTime.toString()
      ) {
        this.reportingTimes.push(element);
      }
    });
  }

  getProjects() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getAllProjects,
      success => {
        this._mProjects = success.body.dataList.content;
      },
      error => {
      }
    );
  }

  getBookingTypes() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getSpecialbookingType,
      success => {
        this._mBookingTypes = success.body;
      },
      error => {
      }
    );
  }

  dateChanged() {
    if (
      this.formatDate(this.mTravelDate.value) == this.formatDate(new Date())
    ) {
      this.reportingTime = new Date().getHours() + "" + new Date().getMinutes();
    } else {
      this.reportingTime =
        new Date(this.mTravelDate.value).getHours() +
        "" +
        new Date(this.mTravelDate.value).getMinutes();
    }
    this.mReportingTime.setValue("");
    this.getTime();
  }

  onClickCancel(): void {
    this.dialogRef.close();
  }

  onEscortToggle() {
    if (this.mEscortRequired.value) {
      this.mEscort.enable();
    } else {
      this.mEscort.disable();
      this.mEscort.setValue("");
    }
  }

  searchSubmit(userSelected) {
    this.mEmployeeId.setValue(userSelected.userInfoDetails.employeeId);
    this.mSites.setValue(userSelected.userInfoDetails.site.siteId);
    this.getAllPrePopulatedLandmarks(this.mSites.value);
    this.requestServerEscortListBySite(userSelected.userInfoDetails.site.siteId);
    if (userSelected.userInfoDetails.userProject != null) {
      this.mProject.setValue(userSelected.userInfoDetails.userProject[0].projects.projectId);
    }

    this._BookedForUser = userSelected;
  }

  getSites() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getsitesbyuserid,
      success => {
        if (success != null) {
          this._mSitesData = success.body;
        }
      },
      error => {
      }
    );
  }

  requestServerAllVendor() {
    if (this.allowtochoosebylocation) {
      this.apiService.doGetRequestWithResponse(ApiConstants.getVendorMasterByLocation + "?locationId=" + this.mLocation.value,
      success => {
        if (success != null) {
          this._mVendors = success.body;
        } else {
          this._mVendors = [];
          this.notifyService.showInfo("No Vendors Found", null);
        }
      },
      error => {
        this.notifyService.showError(error.message, null);
      }
      );
    } else {
      this.apiService.doGetRequestWithResponse(ApiConstants.getAllVendorDropDownBasedOnSite + this.mSites.value,
        success => {
          if (success != null) {
            this._mVendors = success.body;
            this.mVendor.setValue( this.data.userData.vendorMaster.id);
          } else {
            this._mVendors = [];
            this.notifyService.showInfo("No Vendors Found", null);
          }
        },
        error => {
          this.notifyService.showError(error.message, null);
        }
      );
    }
  }

  onSiteChange(option) {
    this.requestServerEscortListBySite(option.value);
    this.mEscort.setValue("");
    this.getAllPrePopulatedLandmarks(option.value);
    this.getVehicleAndDrivers();
  }

  onVendorChange(option) {
    this.getVehicleAndDrivers();
  }

  onVehicleTypeChange(option) {
    this.vehicle.setValue('');
    this.mDriver.setValue('');
    this.selectedVehicleId = '';
    this.mVehicleList=[];
    this.mTempVehicleList=[];
    this.mDriverList=[];
    if(this.onCallFlag == true)
    {
      this.requestServerAllVehiclesOnCall();
    }
    else
    {
      this.getVehicleAndDrivers();
    }
  }

  getVehicleAndDrivers() {
    if (this.allowtochoosebylocation) {
      this.getVehiclesByVendorMasterAndVehicleType();
    } else {
      if (this.mSites.value && this.mVendor.value && this.mVehicleType.value) {
        this.mVehicleList = [];
        this.mTempVehicleList = [];
        // this.vehicle.setValue('');
        // this.selectedVehicleId = '';
        this.mDriverList = [];
        // this.mDriver.setValue('');
        this.apiService.doGetRequestWithResponse(ApiConstants.getVehicleBySiteVendorVehicleType + '?siteid=' + this.mSites.value + '&vendorid=' + this.mVendor.value + '&vehicletypeid=' + this.mVehicleType.value, success => {
          if (success) {
            let vehicles = success.body;
            this.mVehicleList = vehicles.filter(vehicle => vehicle.vehicleType.id == this.mVehicleType.value);
            this.mTempVehicleList = this.mVehicleList;
            if (!(this.mVehicleList.length > 0)) {
              this.notifyService.showInfo("No vehicles found", null);
            } else if (this.mUserData.vehicle !=null && !this.mUserData.vehicleType.onCallBooking){
              // this.vehicle.setValue(this.mUserData.vehicle.registrationNo);
              // this.selectedVehicleId = this.mUserData.vehicle.id;
              this._mTempVehicle = this.mVehicleList.filter(vehicle => vehicle.id == this.mUserData.vehicle.id);
              this.mDriverList = this._mTempVehicle[0].drivers;
              // this.mDriver.setValue(this.mUserData.driver.id)
            }
          } else {
            this.mVehicleList = [];
            this.mTempVehicleList = [];
            this.notifyService.showInfo("No vehicles found", null);
          }
        },
          error => {
            this.mVehicleList = [];
            this.mTempVehicleList = [];
            this.notifyService.showError("Error in getting vehicles", null);
          })
      } else {
      }
    }
  }
  

  updateValue(data, cell) {
    let changedValue: any = data.id;
    if (cell == 'vehicle') {
      this.mDriverList = [];
      this.mDriver.setValue('');
      this.selectedVehicleId=data.id;
      this._mTempVehicle = this.mVehicleList.filter(vehicle => vehicle.id == changedValue);
      this.mDriverList = this._mTempVehicle[0].drivers;
      if (!(this.mDriverList.length > 0)) {
        this.notifyService.showInfo("No drivers found", null);
      }
    }
    if (cell == 'driver') {
      this._mTempDriver = this.mDriverList.filter(driver => driver.id == changedValue);
    }
  }

  requestServerEscortListBySite(siteId) {
    this._mEscort = [];
    if (this.allowtochoosebylocation) {
      this.getEscortListByLocation();
    } else {
      this.apiService.doGetRequestWithResponse(ApiConstants.getEscortListBySiteId +
        siteId,
        success => {
          if (success) {
            this._mEscort = success.body;
          }
        },
        error => {
        }
      );
    }
  }


  formatDatePickerDate(d) {
    let date = d.split("/");
    return date[1] + "/" + date[0] + "/" + date[2];
  }


  formatDate(d): any {
    let m = d.getMonth() + 1;
    let dt = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
    let mm = m < 10 ? "0" + m : m;
    return dt + "/" + mm + "/" + d.getFullYear();
  }

  fromLoc() {
  }

  toLoc() {
  }

  onFromLocationTypechange($event) {
    this.selectedFromLocType = $event.value;
    if (this.selectedFromLocType == 'Other Location') {
      this.fromOther = true;
      this.mFromLandmark.setValue("");
      if (this._mTempLandmarksData.length > 0) {
        this.mFromLandmark.enable();
      } else {
        this.notifyService.showInfo("No Landmarks Found", null);
      }
    } else {
      this.fromLocation = null;
      this.fromOther = false;
      this.mFromLandmark.disable();
    }
  }

  onToLocationTypechange($event) {
    this.selectedToLocType = $event.value;
    if (this.selectedToLocType == 'Other Location') {
      this.toOther = true;
      this.mToLandmark.setValue("");
      if (this._mTempLandmarksData.length > 0) {
        this.mToLandmark.enable();
      } else {
        this.notifyService.showInfo("No Landmarks Found", null);
      }
    } else {
      this.toLocation = null;
      this.toOther = false;
      this.mToLandmark.disable();
    }

  }

  fromLandmarkSearchSubmit(landmark) {
    this.fromLocation = { lat: landmark.latitude, lng: landmark.longitude, add: landmark.landmark };
  }

  toLandmarkSearchSubmit(landmark) {
    this.toLocation = { lat: landmark.latitude, lng: landmark.longitude, add: landmark.landmark };
  }

  getAllPrePopulatedLandmarks(site) {
    if (this.allowtochoosebylocation) {
      this.getAllPrePopulatedLandmarksByLocation();
    } else {
      this.apiService.doGetRequestWithResponse(ApiConstants.getAllPrePopulatedLandmarks + site, success => {
        if (success.status === 200) {
          this._mLandmarksData = success.body;
          this._mTempLandmarksData = success.body;
        } else {
          this._mLandmarksData = [];
          this._mTempLandmarksData = [];
        }
      },
        error => {
  
        }
      );
    }
  }

  allocateVehicleDriver() {
    let vehicle = null;
    let driver = null;
    let vehicleType = null;
    if (this.vehicle.value) {
      if (!this.mDriver.value) {
        this.notifyService.showError("Please select driver", null);
        return;
      }
      vehicle = { id: this.selectedVehicleId };
      driver = { id: this.mDriver.value };
      vehicleType = { id: this.mVehicleType.value };
      this.currentStatus = "Approved";
    } else {
      this.notifyService.showError("Please select vehicle and driver", null);
      return;
    }

    let data = this.mUserData;
    data.status = 'Approved'
    data.bookedFor.userInfoDetails.userProject = null;
    data.bookedFor.userInfoDetails.userCategory = null;
    data.vehicle = vehicle;
    data.driver = driver;
    data.vehicleType = vehicleType;

    this.ngxLoader.start();
    this.apiService.doPutRequestWithResponse(ApiConstants.updateSpecialbooking, data,
      success => {
        if (success) {
          this.ngxLoader.stop();
          this.dialogRef.close(success);
        }
      },
      error => {
        this.ngxLoader.stop();
      });
  }

  addDriver() {
    if (!(this.mVendor.value)) {
      this.notifyService.showError("Please Select Vendor Master", null);
      return;
    }
    let vendor = this._mVendors.find(v => v.id == this.mVendor.value);
    let dialogRef = this.dialog.open(AddDriverComponent, {
      data: vendor,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
    
      }
    });
  }

  addVehicle() {
    if (!(this.mVendor.value)) {
      this.notifyService.showError("Please Select Vendor Master", null);
      return;
    }
    let vendor = this._mVendors.find(v => v.id == this.mVendor.value);
    let dialogRef = this.dialog.open(AddVehicleComponent, {
      data: vendor,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if(this.mActiveVehicleTypeRequired.value){
        this.requestServerAllVehiclesOnCall();
      }else{
        this.getVehicleAndDrivers();
      }
    });
  }

  mapDriverVehicle() {
    if (!(this.mVendor.value)) {
      this.notifyService.showError("Please Select Vendor Master", null);
      return;
    }
    if (!(this.mVehicleType.value)) {
      this.notifyService.showError("Please Select Vehicle Type", null);
      return;
    }

    // let vendor = this._mVendors.find(v => v.id == this.mVendor.value);
    let data1 = {
      vendorMasterId: this.mVendor.value,
      vehicleTypeId: this.mVehicleType.value
    }

    let dialogRef = this.dialog.open(MapDriverVehicleComponent, {
      data: data1,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if(this.mActiveVehicleTypeRequired.value){
        this.requestServerAllVehiclesOnCall();
      }else{
        this.getVehicleAndDrivers();
      }
      
    });
  }

  requestServerAllVehiclesOnCall() {
    // let vendor = this._mVendors.find(v => v.id == this.mVendor.value);
    // let vendorMasterId = vendor.vendorMaster.id;
    
    if (this.mVendor.value && this.mVehicleType.value) {
      this.apiService.doGetRequestWithResponse(ApiConstants.getActiveOnCallVehicles + "?vendorMasterId=" + this.mVendor.value 
      +  "&vehicleTypeId=" +this.mVehicleType.value,
        success => {
          
          if (success.body) {
            this.mVehicleList = success.body;
            this.mTempVehicleList = success.body;
            if (this.mUserData != null && this.mUserData != undefined && this.mUserData.vehicle != null && this.mUserData.vehicle.registrationNo != undefined && this.mUserData.vehicle.registrationNo != null) {
              // this.vehicle.setValue(this.mUserData.vehicle.registrationNo);
              // this.selectedVehicleId = this.mUserData.vehicle.id;
              this._mTempVehicle = this.mVehicleList.filter(vehicle => vehicle.id == this.mUserData.vehicle.id);
              this.mDriverList = this._mTempVehicle[0].drivers;
              // this.mDriver.setValue(this.mUserData.driver.id)
            }
          } else {
            this.mVehicleList = [];
            this.mDriverList = [];
            this.mTempVehicleList = [];
            this.notifyService.showError("No Vehicles Found", null);
          }
        },
        error => {
          this.mVehicleList = [];
          this.mTempVehicleList = [];
          this.notifyService.showError(error.message, null);
        }
      );
    }
    
  }

  requestServerAllActiveOnCallVehicleTypes() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getActiveVehicleTypes +  "?flag=" + true,
      success => {
        if (success.status == 200) {
          this._mVehicleTypes = success.body;
            
          // this._mVehicleTypes = this._mVehicleTypes.filter(
          //   vehicletype => vehicletype.status == AppConstants.ACTIVE_STATUS
          // );
        } else if (success.status == 204) {
          this.notifyService.showInfo("No Vehicles Found", null);
        }
      },
      error => {
        this.notifyService.showError(error.message, null);
      }
    );

  }

  omit_special_char_And_String(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k >= 48 && k <= 57));
  }

  onVehicleTypeToggle() {
    this.mVehicleType.setValue('');
    this.mDriver.setValue('');
    this.vehicle.setValue('');
    this.selectedVehicleId='';
    this.mTempVehicleList=[];
      this.mVehicleList=[];
    this._mVehicleTypes=[];
    this.mDriverList=[];
    if (this.mActiveVehicleTypeRequired.value == true) {
      this.onCallFlag = true;
      this.requestServerAllActiveOnCallVehicleTypes();
      this.requestServerAllVehiclesOnCall();
    } else {
      this.onCallFlag = false;
      this.requestServerAllVehicleTypes();
      this.getVehicleAndDrivers();
    }
  }

  allowToChooseByLocation() {
    this.apiService.doGetRequestWithResponse(ApiConstants.allowToChooseByLocation,
      success => {
        if (success.body != null) {
          this.allowtochoosebylocation = success.body.allowToChooseByLocation;
        } else {
          this.allowtochoosebylocation = false;
        }
        this.loadCities();
        this.requestServerEscortListBySite(this.mSites.value);
        this.getAllPrePopulatedLandmarks(this.mSites.value);
        this.requestServerAllVendor();
        if(this.mActiveVehicleTypeRequired.value){
          this.requestServerAllActiveOnCallVehicleTypes();
        } else{
          this.requestServerAllVehicleTypes();
        }
        if (this.mVendor.value && this.mVehicleType.value) {
          if(this.mActiveVehicleTypeRequired.value){
            this.requestServerAllVehiclesOnCall();
          } else{
            this.getVehicleAndDrivers();
          }
        }
      },
      error => {
        this.allowtochoosebylocation = false;
        this.notifyService.showError(error.message, null);
      }
    );
  }

  loadCities() {
    this.ngxLoader.start();
    if (this.allowtochoosebylocation) {
      this.apiService.doGetRequestWithResponse(ApiConstants.getAllLocationList, (success) => {
        this.ngxLoader.stop();
        if (success.status == 200) {
          this.locationList = success.body;
        } else {
          this.locationList = []
        }
      },
        error => {
          this.ngxLoader.stop();
          console.log(error.message);
        }
      );
    } else {
      this.apiService.doGetRequestWithResponse(ApiConstants.getserchlocationlist, (success) => {
        this.ngxLoader.stop();
        if (success.status == 200) {
          this.locationList = success.body;
        } else {
          this.locationList = []
        }
      },
        error => {
          this.ngxLoader.stop();
          console.log(error.message);
        }
      );
    }
  }

  getEscortListByLocation() {
    this._mEscort = [];
    this.apiService.doGetRequestWithResponse(ApiConstants.getEscortListByLocation + "?locationId=" +
    this.mLocation.value,
      success => {
        if (success) {
          this._mEscort = success.body;
        }
      },
      error => {
      }
    );
  }

  getAllPrePopulatedLandmarksByLocation() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getAllPrePopulatedLandmarksByLocation + "?locationId=" + this.mLocation.value, success => {
      if (success.status === 200) {
        this._mLandmarksData = success.body;
        this._mTempLandmarksData = success.body;
      } else {
        this._mLandmarksData = [];
        this._mTempLandmarksData = [];
      }
    },
      error => {

      }
    );
  }

  getVehicleTypeByLocationId() {
    if (this.mLocation.value) {
      this.apiService.doGetRequestWithResponse(ApiConstants.getVehicleTypeByLocationId + "?locationId=" + this.mLocation.value,
        success => {
          if (success.status == 200) {
            this._mVehicleTypes = success.body;
            // this._mVehicleTypes = this._mVehicleTypes.filter(
            //   vehicletype => vehicletype.status == AppConstants.ACTIVE_STATUS
            // );
          } else if (success.status == 204) {
            this.notifyService.showInfo("No Vehicles Found", null);
          }
        },
        error => {
          this.notifyService.showError(error.message, null);
        }
      );
    } else {
      this.notifyService.showError("Plese Select Location", null);
    }

  }

  getVehiclesByVendorMasterAndVehicleType() {
    if (this.mVendor.value && this.mVehicleType.value) {
      this.mVehicleList = [];
      this.mTempVehicleList = [];
      this.mDriverList = [];
      this.apiService.doGetRequestWithResponse(ApiConstants.getVehiclesByVendorMasterAndVehicleType + '?vendorMasterId=' + this.mVendor.value + '&vehicleTypeId=' + this.mVehicleType.value, success => {
        if (success) {
          let vehicles = success.body;
          this.mVehicleList = vehicles.filter(vehicle => vehicle.vehicleType.id == this.mVehicleType.value);
          this.mTempVehicleList = this.mVehicleList;
          if (!(this.mVehicleList.length > 0)) {
            this.notifyService.showInfo("No vehicles found", null);
          } else if (this.mUserData.vehicle !=null && !this.mUserData.vehicleType.onCallBooking){
            this._mTempVehicle = this.mVehicleList.filter(vehicle => vehicle.id == this.mUserData.vehicle.id);
            this.mDriverList = this._mTempVehicle[0].drivers;
          }
        } else {
          this.mVehicleList = [];
          this.mTempVehicleList = [];
          this.notifyService.showInfo("No vehicles found", null);
        }
      },
        error => {
          this.mVehicleList = [];
          this.mTempVehicleList = [];
          this.notifyService.showError("Error in getting vehicles", null);
        })
    } else {
    }
  }

}
