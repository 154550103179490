import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-zone-rename',
  templateUrl: './zone-rename.component.html',
  styleUrls: ['./zone-rename.component.scss']
})
export class ZoneRenameComponent implements OnInit {
  zonename: string;
  editedZone: any;

  constructor(
    public dialogRef: MatDialogRef<ZoneRenameComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notifyService: NotificationService,
  ) {
    this.zonename = data;
  }

  ngOnInit() { }

  onClickCancel(): void {
    this.dialogRef.close();
  }

  savezonename(editedZone) {
    this.notifyService.showInfo(editedZone, null);
    if (editedZone == "" || editedZone == this.zonename || editedZone == undefined) {
      this.notifyService.showWarning("Please enter the new zone name!", null);
    } else {
      this.dialogRef.close(editedZone);
    }
  }

}
