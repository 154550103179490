<h2 mat-dialog-title>
    <span class="material-icons-outlined"
        style="color:#747679; font-size: 25px; float: right; position: relative; top: 10px; cursor: pointer"
        (click)="onClickCancel()"> close</span>
</h2>

<mat-dialog-content *ngIf='dataFlag'>
    <div class="row" style="margin: 0px;">
        <div class="col-sm-12 mt-3" style="overflow-x:auto;">
            <div *ngIf="tripsFlag" class="table-width-searchDialogBox">
                <table mat-table [dataSource]="dataSource" *ngIf="(ELEMENT_DATA.length> 0)"
                    class="table-width searchDialogBox">
                    <ng-container matColumnDef="empName">
                        <th mat-header-cell *matHeaderCellDef>Employee Name</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font"
                            style="white-space: nowrap !important;">
                            {{element.empName}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="tripCode">
                        <th mat-header-cell *matHeaderCellDef>Trip Id</th>
                        <td mat-cell *matCellDef="let element" class="mat-cell-searchDialogBox"
                            matTooltip={{element.tripCode}} (click)="mapview(element.tripId)">

                            {{element.tripCode}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="tripDate">
                        <th mat-header-cell *matHeaderCellDef>Trip Date</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.tripDate}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="logIn">
                        <th mat-header-cell *matHeaderCellDef>Login Time</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.logIn}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="vehicle">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Vehicle</th>
                        <td mat-cell *matCellDef="let element" class="min-width1">
                            {{element.vehicle}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="driver">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Driver</th>
                        <td mat-cell *matCellDef="let element" class="min-width1">
                            {{element.driver}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="logoutTime">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Logout Time
                        </th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.logoutTime}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="showStatus">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Show Status</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.showStatus}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="tripStatus">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Trip Status</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.tripStatus}}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"
                        class="tdw-bottom-line mat-color--grey-100 tdw-card-thin"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>

            <div *ngIf="driverDocsFlag" class="table-width-searchDialogBox">
                <table mat-table [dataSource]="dataSourceDriver" *ngIf="(ELEMENT_DATA_DRIVER.length> 0)"
                    class="table-width searchDialogBox">

                    <ng-container matColumnDef="driverName">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Driver Name</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.driverName}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="govtIdType">
                        <th mat-header-cell *matHeaderCellDef>Govt Id Type</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.govtIdType}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="govtIdNo">
                        <th mat-header-cell *matHeaderCellDef>Govt Id Number</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.govtIdNo}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="licenseNo">
                        <th mat-header-cell *matHeaderCellDef>License Number</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font"
                            style="white-space: nowrap !important;">
                            {{element.licenseNo}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="licenseExpDate">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">License Expiry Date</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.licenseExpDate}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="badgeNo">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Driver Badge Number</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.badgeNo}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="badgeExpDate">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Badge Expiry Date</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.badgeExpDate}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="policeVerification">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Police Verification
                        </th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.policeVerification}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="pvcExpDate">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Police Verification Expiry Date</th>
                        <td mat-cell *matCellDef="let element" class="min-width1">
                            {{element.pvcExpDate}}
                        </td>
                    </ng-container>



                    <ng-container matColumnDef="BGC">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">BGC</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.BGC}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="BGCExpDate">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">BGC Expiry Date</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.BGCExpDate}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="medicalCheck">
                        <th mat-header-cell *matHeaderCellDef>Medical Check</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.medicalCheck}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="medicalExpDate">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Medical Expiry Date</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.medicalExpDate}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="vehicle">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Primary Vehicle</th>
                        <td mat-cell *matCellDef="let element" class="min-width1">
                            {{element.vehicle}}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsDriverDocs"
                        class="tdw-bottom-line mat-color--grey-100 tdw-card-thin"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsDriverDocs;"></tr>
                </table>
            </div>

            <div *ngIf="vehicleDocsFlag" class="table-width-searchDialogBox">
                <table mat-table [dataSource]="dataSourceVehicle" *ngIf="(ELEMENT_DATA.length> 0)"
                    class="table-width searchDialogBox">
                    <ng-container matColumnDef="vehicleNo">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Vehicle Number</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.vehicleNo}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="manufacturingDate">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Manufacturing Date</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.manufacturingDate}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="registrationDate">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Registration Date</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.registrationDate}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="chassisNo">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Chasis Number
                        </th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.chassisNo}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="roadTaxNo">
                        <th mat-header-cell *matHeaderCellDef>Road Tax #</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.roadTaxNo}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="roadTaxExpDate">
                        <th mat-header-cell *matHeaderCellDef>Road Tax Expiry</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.roadTaxExpDate}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="insuranceNo">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Insurance #</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.insuranceNo}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="insuranceExpDate">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Insurance Expiry Date</th>
                        <td mat-cell *matCellDef="let element" class="min-width1">
                            {{element.insuranceExpDate}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="fitnessNo">
                        <th mat-header-cell *matHeaderCellDef>Fitness</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font"
                            style="white-space: nowrap !important;">
                            {{element.fitnessNo}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="fitnessExpDate">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Fitness Expiry</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.fitnessExpDate}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="emission">
                        <th mat-header-cell *matHeaderCellDef class="custom-font"> Emission Date</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.emission}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="emissionExpDate">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Emission Expiry Date</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.emissionExpDate}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="permitNo">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Permit Number</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.permitNo}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="permitExpiryDate">
                        <th mat-header-cell *matHeaderCellDef>Permit Expiry Date</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.permitExpiryDate}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="driverName">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Primary Driver</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.driverName}}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsVehicleDocs"
                        class="tdw-bottom-line mat-color--grey-100 tdw-card-thin"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsVehicleDocs;"></tr>
                </table>
            </div>

            <div *ngIf="addressFlag" class="table-width-searchDialogBox">
                <table mat-table [dataSource]="dataSourceAddress" *ngIf="(ELEMENT_DATA.length> 0)"
                    class="table-width searchDialogBox">

                    <ng-container matColumnDef="empId">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Employee Id</th>
                        <td mat-cell *matCellDef="let element" class="min-width1">
                            {{element.empId}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="empName">
                        <th mat-header-cell *matHeaderCellDef>Employee Name</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.empName}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="area">
                        <th mat-header-cell *matHeaderCellDef>Area</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font"
                            style="white-space: nowrap !important;">
                            {{element.area}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="landmark">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Landmark</th>
                        <td mat-cell *matCellDef="let element" class="min-width1">
                            {{element.landmark}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="address">
                        <th mat-header-cell *matHeaderCellDef>Address</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.address}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="empCategory">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Employee Category</th>
                        <td mat-cell *matCellDef="let element" class="min-width1">
                            {{element.empCategory}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Status</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.status}}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsEmployeeAddressDocs"
                        class="tdw-bottom-line mat-color--grey-100 tdw-card-thin"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsEmployeeAddressDocs;"></tr>
                </table>
            </div>


            <div *ngIf="geocodeFlag" class="table-width-searchDialogBox">
                <table mat-table [dataSource]="dataSourceGeocode" *ngIf="(ELEMENT_DATA.length> 0)"
                    class="table-width searchDialogBox">

                    <ng-container matColumnDef="employeeId">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Employee Id</th>
                        <td mat-cell *matCellDef="let element" class="min-width1">
                            {{element.employeeId}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="updatedAddress">
                        <th mat-header-cell *matHeaderCellDef>Last Updated Address</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.updatedAddress}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="area">
                        <th mat-header-cell *matHeaderCellDef>Area</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font"
                            style="white-space: nowrap !important;">
                            {{element.area}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="landmark">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Landmark</th>
                        <td mat-cell *matCellDef="let element" class="min-width1">
                            {{element.landmark}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="lastUpdatedDate">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Last Updated Date</th>
                        <td mat-cell *matCellDef="let element" class="min-width1">
                            {{element.lastUpdatedDate}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Status</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            {{element.status}}
                        </td>
                    </ng-container>

                    <ng-container *ngIf="pendingFlag" matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Action</th>
                        <td mat-cell *matCellDef="let element" class="actionIcons">
                            <img src="./assets/icons/like.png" style="size: 10px;" matTooltip="Approve"
                                (click)="onApproveStatusRequest(element)" style="cursor: pointer">
                            <img src="./assets/icons/dislike.png" style="size: 10px" matTooltip="Reject"
                                (click)="onRejectStatusRequest(element)" style="cursor: pointer">
                        </td>
                    </ng-container>

                    <ng-container *ngIf="!pendingFlag" matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef class="custom-font">Action</th>
                        <td mat-cell *matCellDef="let element" class="table-data-font">
                            NA
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsGeocodeChange"
                        class="tdw-bottom-line mat-color--grey-100 tdw-card-thin"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsGeocodeChange;"></tr>
                </table>
            </div>

            <div *ngIf="!tripsFlag && !driverDocsFlag && !vehicleDocsFlag && !addressFlag && !geocodeFlag">
                <app-basic-table [columnHeaders]="_mColumns" [tableData]="_mData">
                </app-basic-table>
            </div>
        </div>

    </div>
</mat-dialog-content>