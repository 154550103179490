<form [formGroup]="_mFormGroup">
  <div class="row mt-3">
    <div class="col-sm-2">

      <label>Location</label>
      <select class="form-select margin-right-8px" placeholder="Select Location" formControlName="location"
        (ngModelChange)="getSitesByCityId($event)">
        <option disabled value="">Choose City</option>
        <option *ngFor="let city of cities" [value]="city.locationId">
          {{ city.locationName }}
        </option>
      </select>
      <div class="error-msg"
        *ngIf="_mFormGroup.get('location').hasError('required') && _mFormGroup.get('location').touched">
        Please select location
      </div>
    </div>
    <div class="col-sm-2">
      <label>Site</label>
      <select class="form-select margin-right-8px" formControlName="site" (change)="onSelectionChange()">
        <option disabled value="">Choose Site</option>
        <option *ngFor="let site of _mSitesData" [value]="site.siteId">
          {{ site.siteName }}
        </option>
      </select>
    </div>
    <div class="col-sm-2">

      <label>Master Zone</label>
      <select class="form-select margin-right-8px" placeholder="Master Zones" formControlName="masterZone"
        (change)="onMasterChange()">
        <option disabled value="">Choose MasterZone</option>
        <option *ngFor="let masterZone of _mMasterZoneData" [value]="masterZone.id">
          {{masterZone.name}}
        </option>
      </select>

    </div>
    <div class="col-sm-2">
      <label>Zone</label>
      <mat-form-field class="routeDate" appearance="outline">
        <input type="text" placeholder="Zones" aria-label="Number" [(ngModel)]="selectedval" matInput
          [formControl]="mZone" [matAutocomplete]="auto">
        <img matSuffix src="assets/images/search.svg" height="27" style="margin-top: -22px;padding-right: 5px;">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option class="serOption" *ngFor="let zone of filteredOptions | async" [value]="zone">
            {{zone.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

    </div>
    <div class="col-sm-2"></div>
    <div class="col-sm-2" style="position: relative; top: 27px; left: 11px;">
      <button class="btn playlist-btn-solid" [disabled]="(this.mZone.value&&this.mMasterZone&&this.mSite) ?
          false : true" (click)="showMapping(selectedSite,selectedval)">Show
        Mapping</button>
    </div>
  </div>
</form>
<div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="none stretch">
  <div class="tdw-padding-h-20"></div>
</div>
<div class="hr-line"></div>
<div class="tdw-padding-v-4"></div>
<div *ngIf="show" fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="none
      stretch">
  <div class="container">
    <div class="container-top" *ngIf="rows.length>0">
      <mat-checkbox class="routeCheckBox" [checked]="isAllChecked()" (change)="checkAll($event)"></mat-checkbox>Select
      All
    </div>
    <span class="items mat-color--white tdw-card-thin tdw-border-2
          tdw-font-14" *ngFor="let row of rows">
      <mat-checkbox class="routeCheckBox" [(ngModel)]="row.state" (change)="onValueChecked($event,row)"></mat-checkbox>
      {{row.value}}
    </span>
  </div>
  <div class="container">
    <div class="container-top" *ngIf="unPushedValues.length>0">
      <mat-checkbox class="routeCheckBox" [checked]="!isAllChecked() || isAllChecked()"
        (change)="unCheckAll($event)"></mat-checkbox>
      UnSelect
      All
    </div>
    <span class="items mat-color--white tdw-card-thin tdw-border-2
          tdw-font-14" *ngFor="let row of unPushedValues">
      <mat-checkbox class="routeCheckBox" [(ngModel)]="row.state"
        (change)="onSelValueChecked($event,row)"></mat-checkbox>
      {{row.value}}
    </span>
  </div>
</div>
<div fxLayout="column">
  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end" style="float:
        right; padding-right: 0%">
    <button *ngIf="showFlag" class="btn playlist-btn-solid1" (click)="saveMapping()">Save
      Mapping
    </button>
  </div>