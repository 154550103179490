import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
// import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { AppConstants } from "src/app/core/constants/appconstants";
import { ServiceLocalStorage } from "src/app/core/services/local-storage.service";
declare let google: any;
declare let L;

@Component({
  selector: "app-preview-dialog",
  templateUrl: "./preview-dialog.component.html",
  styleUrls: ["./preview-dialog.component.scss"]
})
export class PreviewDialogComponent implements OnInit {
  dialogData: any;
  lat = 13.0196;
  lng = 77.5968;
  map: any;
  _mTitle: any;
  constructor(
    private serviceLocalStorage: ServiceLocalStorage,
    public dialogRef: MatDialogRef<PreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogData = data;
  }

  ngOnInit() {
    if (this.serviceLocalStorage.getMapType() === AppConstants.OSRM) {
      setTimeout(() => {
        this.loadOsmap();
        this.showTripOnOSMap();
      }, 0);
    } else {
      setTimeout(() => {
        this.loadgmap();
        this.showTripOnMap();
      }, 0);
    }
  }
  onClickCancel(): void {
    this.dialogRef.close();
  }

  loadOsmap() {
    this.map = L.map("map", {
      fullscreenControl: true,
      dragging: true
    }).setView(
      [
        this.dialogData.tripDetails.site.landmark.latitude,
        this.dialogData.tripDetails.site.landmark.longitude
      ],
      13
    );
    L.tileLayer(AppConstants.OSM_TILE, { attribution: AppConstants.OSM_CONTRIBUTORS }).addTo(this.map);
  }

  showTripOnOSMap() {
    let wps = [];
    if (this.dialogData.tripDetails.shift.logType == "IN") {
      this.dialogData.tripDetails.tripUsers.forEach(wp => {
        wps.push(L.latLng(wp.landmark.latitude, wp.landmark.longitude));
      });
      wps.push(
        L.latLng(
          this.dialogData.tripDetails.site.landmark.latitude,
          this.dialogData.tripDetails.site.landmark.longitude
        )
      );
    } else {
      wps.push(
        L.latLng(
          this.dialogData.tripDetails.site.landmark.latitude,
          this.dialogData.tripDetails.site.landmark.longitude
        )
      );
      this.dialogData.tripDetails.tripUsers.forEach(wp => {
        wps.push(L.latLng(wp.landmark.latitude, wp.landmark.longitude));
      });
    }
    let r = L.Routing.control({
      serviceUrl: AppConstants.OSM_ROUTING_URL,
      showAlternatives: false,
      lineOptions: {
        styles: [
          {
            color: "#242c81",
            weight: 3
          }
        ]
      },
      fitSelectedRoutes: true,
      waypointMode: "connect",
      addWaypoints: false,
      draggableWaypoints: false,
      autoRoute: true,
      reverseWaypoints: false,
      altLineOptions: {
        styles: [
          {
            color: "#ed6852",
            weight: 3
          }
        ]
      },
      show: true,
      routeWhileDragging: false,
      createMarker: (i, wp, nWps) => {
        if (this.dialogData.tripDetails.shift.logType === "IN") {
          if (i === this.dialogData.tripDetails.tripUsers.length) {
            let m = L.marker(wp.latLng, {
              icon: L.icon({
                iconUrl: "./assets/images/company.png",
                iconSize: [40, 40]
              })
            }).bindPopup(this.dialogData.tripDetails.site.siteName + ', ' + this.dialogData.tripDetails.site.landmark.landmark);
            return m;
          }
        } else {
          if (i === 0) {
            // || i === nWps - 1
            let m = L.marker(wp.latLng, {
              icon: L.icon({
                iconUrl: "./assets/images/company.png",
                iconSize: [40, 40]
              })
            }).bindPopup(this.dialogData.tripDetails.site.siteName + ', ' + this.dialogData.tripDetails.site.landmark.landmark);
            return m;
          }
        }
        if (this.dialogData.tripDetails.shift.logType === "IN") {
          if (i <= this.dialogData.tripDetails.tripUsers.length) {
            let m = L.marker(wp.latLng, {
              icon: L.icon({
                iconUrl: "../assets/images/home.png",
                iconSize: [30, 40]
              })
            }).bindPopup(this.dialogData.tripDetails.tripUsers[i].landmark.landmark)
              .bindTooltip(i + 1 + "", {
                permanent: true,
                direction: "left",
                offset: L.point(-10, 0)
              })
              .openTooltip();
            return m;
          }
        } else {
          if (i - 1 <= this.dialogData.tripDetails.tripUsers.length) {
            let m = L.marker(wp.latLng, {
              icon: L.icon({
                iconUrl: "../assets/images/home.png",
                iconSize: [30, 40]
              })
            }).bindPopup(this.dialogData.tripDetails.tripUsers[i - 1].landmark.landmark)
              .bindTooltip(i + "", {
                permanent: true,
                direction: "left",
                offset: L.point(-10, 0)
              })
              .openTooltip();
            return m;
          }
        }
      }
    }).addTo(this.map);
    r.setWaypoints(wps);
    r.hide();
  }

  loadgmap() {
    let mapCenter = { lat: this.lat, lng: this.lng };
    this.map = new google.maps.Map(document.getElementById("map"), {
      center: mapCenter,
      zoom: 12,
      draggable: true,
      zoomControl: true
    });
  }
  showTripOnMap() {
    let sitecordinates = new google.maps.LatLng(
      this.dialogData.tripDetails.site.landmark.latitude,
      this.dialogData.tripDetails.site.landmark.longitude
    );
    let path = "./assets/images/company.png";
    var marker = new google.maps.Marker({
      position: sitecordinates,
      icon: {
        url: path,
        scaledSize: new google.maps.Size(40, 40)
      },
      map: this.map
    }).bindPopup(this.dialogData.tripDetails.site.siteName);
    let waypts = [];
    this.dialogData.tripDetails.tripUsers.forEach(landmark => {
      let icon = "./assets/images/home.png";
      var marker = new google.maps.Marker({
        position: {
          lat: landmark.landmark.latitude,
          lng: landmark.landmark.longitude
        },
        icon: icon,
        draggable: false,
        map: this.map,
        title: landmark.landmark.landmark + " - " + landmark.routedOrder,
        label: {
          text: "L" + landmark.routedOrder,
          color: "black",
          fontWeight: "bold",
          fontSize: "15px"
        }
      });
      waypts.push({
        location: {
          lat: landmark.landmark.latitude,
          lng: landmark.landmark.longitude
        },
        stopover: true
      });
    });
    let startnodelatlng = {
      lat: this.dialogData.tripDetails.site.landmark.latitude,
      lng: this.dialogData.tripDetails.site.landmark.longitude
    };
    let endnodelatlng = {
      lat: this.dialogData.tripDetails.site.landmark.latitude,
      lng: this.dialogData.tripDetails.site.landmark.longitude
    };
    if (this.dialogData.tripDetails.tripUsers.length > 0) {
      if (this.dialogData.tripDetails.shift.logType == "IN") {
        startnodelatlng = {
          lat: this.dialogData.tripDetails.tripUsers[0].landmark.latitude,
          lng: this.dialogData.tripDetails.tripUsers[0].landmark.longitude
        };
      } else {
        endnodelatlng = {
          lat: this.dialogData.tripDetails.tripUsers[
            this.dialogData.tripDetails.tripUsers.length - 1
          ].landmark.latitude,
          lng: this.dialogData.tripDetails.tripUsers[
            this.dialogData.tripDetails.tripUsers.length - 1
          ].landmark.longitude
        };
      }
    }

    let request = {
      origin: startnodelatlng,
      destination: endnodelatlng,
      waypoints: waypts,
      optimizeWaypoints: true,
      avoidHighways: false,
      travelMode: google.maps.DirectionsTravelMode.DRIVING
    };
    let directionsService = new google.maps.DirectionsService();
    let directionsDisplay = new google.maps.DirectionsRenderer({
      suppressMarkers: true
    });
    directionsDisplay.setMap(this.map);
    directionsService.route(request, (response, status) => {
      if (status == google.maps.DirectionsStatus.OK) {
        directionsDisplay.setDirections(response);
      }
    });
  }
}
