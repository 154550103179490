<div class="container-fluid">
    <div class="card">
        <ul ngbNav #nav="ngbNav" [activeId]="activeTabId" class="nav-tabs nav-tabs-custom g-nav-tabs-custom" style="padding-top: 7px;">
            <li [ngbNavItem]="1">
              <a ngbNavLink>  On Call Trips</a>
              <ng-template ngbNavContent>
            </ng-template>
        </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="p-3"></div>
        <div class="card-body">
            <form [formGroup]="_mFormGroup">
                <div class="row">
                    <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                        <label class="font-size-font">Location<sup class="mandatory">*</sup></label>
                        <select class="form-select" placeholder="Location" formControlName="location"
                            (change)="getSitesBylocId()" required>
                            <option value="" disabled>Select Location</option>
                            <option *ngFor="let city of locationList" [value]="city.locationId">
                                {{ city.locationName }}
                            </option>
                        </select>
                        <div  class="error-msg"
                        *ngIf="_mFormGroup.get('location').hasError('required') && _mFormGroup.get('location').touched">
                        Please select location
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                        <label class="font-size-font">Site<sup class="mandatory">*</sup></label>
                        <select class="form-select" placeholder="Site" formControlName="site" required>
                            <option value="" disabled>Select Site</option>
                            <option *ngFor="let data of _mSitesData" [value]="data.siteId">
                                {{ data.siteName}}
                            </option>
                        </select>
                        <div class="error-msg"
                        *ngIf="_mFormGroup.get('site').hasError('required') && _mFormGroup.get('site').touched">
                        Please select site
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                        <label class="font-size-font">Date<sup class="mandatory">*</sup></label>
                        <mat-form-field appearance="outline" class="on-call-tripDate">
                            <input matInput [matDatepicker]="picker" (click)="picker.open()" readonly
                                placeholder="Choose Date" formControlName="fromDate">
                            <mat-datepicker-toggle matSuffix [for]="picker">
                                <img matDatepickerToggleIcon src="assets/images/bx-calendar.svg">
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6 col-md-6" style="position: relative; top: 30px;">
                        <div style="display: flex; gap: 20px; justify-content: end;">
                            <button class="btn playlist-btn-solid" (click)="generateReport(true)">Submit</button>
                            <button class="btn grey-btn-solid " (click)="reset()">Reset</button>
                        </div>
                    </div>
                </div>
            </form>

            <div class="row mt-3" *ngIf="reportsViewFlag">
                <div class="col-sm-12 col-md-12">
                    <div class="col-sm-2" style="float: right; width: fit-content;">
                        <!-- <button class="btn download-btn-solid" style="text-align: center;"
                            (click)="onExportToExcel()">
                            Export To Excel
                        </button> -->
                        <img src="./assets/images/excel.svg" (click)="onExportToExcel()" matTooltip="Export To Excel">
                    </div>
                </div>
            </div>

            <div class="row mt-3" *ngIf="reportsViewFlag">
                <div class="col-sm-12 p-0">
                    <div class="table-responsive">
                        <table mat-table [dataSource]="dataSource" class="table table-borderless oncalltripsTable"
                            matPaginator>
                            <ng-container matColumnDef="tripCode">
                                <th mat-header-cell *matHeaderCellDef> Trip Code </th>
                                <td mat-cell *matCellDef="let element"
                                    style="font-size: 13px; font-family: poppins; font-weight: 400; white-space: nowrap !important;">
                                    {{element.tripCode}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="employeeId">
                                <th mat-header-cell *matHeaderCellDef> Employee Id </th>
                                <td mat-cell *matCellDef="let element"
                                    style="font-size: 13px; font-family: poppins; font-weight: 400; white-space: nowrap !important;">
                                    {{element.employeeId}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef> Employee Name </th>
                                <td mat-cell *matCellDef="let element"
                                    style="font-size: 13px; font-family: poppins; font-weight: 400; white-space: nowrap !important;">
                                    {{element.name}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="reportingTime">
                                <th mat-header-cell *matHeaderCellDef>Reporting Time</th>
                                <td mat-cell *matCellDef="let element"
                                    style="font-size: 13px; font-family: poppins; font-weight: 400; white-space: nowrap !important;">
                                    {{element.reportingTime}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="purpose">
                                <th mat-header-cell *matHeaderCellDef> Purpose </th>
                                <td mat-cell *matCellDef="let element"
                                    style="font-size: 13px; font-family: poppins; font-weight: 400; white-space: nowrap !important;">
                                    {{element.purpose}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="fromLocation">
                                <th mat-header-cell *matHeaderCellDef> From Location </th>
                                <td mat-cell *matCellDef="let element"
                                    style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                    {{element.fromLocation}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="toLocation">
                                <th mat-header-cell *matHeaderCellDef> To Location </th>
                                <td mat-cell *matCellDef="let element"
                                    style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                    {{element.toLocation}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="startTime">
                                <th mat-header-cell *matHeaderCellDef> Start time </th>
                                <td mat-cell *matCellDef="let element"
                                    style="font-size: 13px; font-family: poppins; font-weight: 400; white-space: nowrap !important;">
                                    {{element.startTime}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="punchIn">
                                <th mat-header-cell *matHeaderCellDef> Punch In </th>
                                <td mat-cell *matCellDef="let element"
                                    style="font-size: 13px; font-family: poppins; font-weight: 400; white-space: nowrap !important;">
                                    {{element.punchIn}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="punchOut">
                                <th mat-header-cell *matHeaderCellDef> Punch Out </th>
                                <td mat-cell *matCellDef="let element"
                                    style="font-size: 13px; font-family: poppins; font-weight: 400; white-space: nowrap !important;">
                                    {{element.punchOut}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="duration">
                                <th mat-header-cell *matHeaderCellDef> Duration</th>
                                <td mat-cell *matCellDef="let element"
                                    style="font-size: 13px; font-family: poppins; font-weight: 400; white-space: nowrap !important;">
                                    {{element.duration}} </td>
                            </ng-container>

                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef> Status</th>
                                <td mat-cell *matCellDef="let element"
                                    style="font-size: 13px; font-family: poppins; font-weight: 400; white-space: nowrap !important;">
                                    {{element.status}} </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                    <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]"></mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>