import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { ApiService } from 'src/app/core/services/api.service';
import { ServiceExportExcel } from 'src/app/core/services/export.service';

@Component({
  selector: 'app-upload-apl-status',
  templateUrl: './upload-apl-status.component.html',
  styleUrls: ['./upload-apl-status.component.scss']
})
export class UploadAplStatusComponent implements OnInit {
  showDiv: boolean = false;
  _mTitle: any = "Upload Summary";
  errList: any[] = [];
  totalrecordscount: number;
  totaluploadcount: number;
  fileid: number = 0;
  errordata: any[] = [];

  constructor(
    private readonly apiService: ApiService,
    private serviceExportExcel: ServiceExportExcel,
    public dialogRef: MatDialogRef<UploadAplStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.uploadErrorsList) {
      this.showDiv = true;
      this.errList = data.uploadErrorsList;
      this.fileid = data.fileId;
    }
    this.totalrecordscount = data.itemsRead;
    this.totaluploadcount = data.itemsSaved;
  }

  ngOnInit() {
  }

  onClickCancel(): void {
    this.dialogRef.close();
  }

  onScroll($event) {
  }

  requiestExcel() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getuploaderror + this.fileid, (success) => {
      if (success.status == 200) {
        let tempArr: any[] = success.body;
        tempArr.forEach(arr => {
          let fieldData = {
            city: arr.city,
            landmark: arr.landmark,
            latitude: arr.latitude,
            longitude: arr.longitude,
            type: arr.type,
            comments: arr.comments
          }
          this.errordata.push(fieldData);
        });
        this.exportAsXLSX();
      }
    }, (error) => {
      console.log(error)
    })
  }

  exportAsXLSX(): void {
    this.serviceExportExcel.exportAsExcelFile(this.errordata, 'ALUploadErrors');
  }

}
