export class VendorPercentageList {
    vendorId: number;
    percentage: number;
}

export class VehicleTypePercentageList {
    vehicleType: number;
    vendorPercentageList: VendorPercentageList[] = [];
}

export class PercentModel {
    siteid: number;
    shiftid: number;
    tripDate: string;
    vehicleTypePercentageList: VehicleTypePercentageList[] = [];
}

