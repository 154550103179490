<div class="row">
  <div class="col-sm-12 col-md-12">
    <form [formGroup]="_mFormGroup">
      <div class="d-flex customShift-pickupFlexBox" style="align-items: flex-end;">
        <div>
          <label>City</label>
          <select class="form-select margin-right-8px" placeholder="City" formControlName="city"
            (ngModelChange)="getSitesByCityId($event)">
            <option disabled value="">Choose Location</option>
            <option *ngFor="let city of cities" [value]="city.locationId">
              {{ city.locationName }}
            </option>
          </select>

        </div>
        <div>
          <label>Site</label>
          <select class="form-select margin-right-8px" placeholder="Select Site" [disabled]="(this.mCity.value) ?
            false : true" formControlName="site" (ngModelChange)="onsitechange($event)">
            <option disabled value="">Choose Site</option>
            <option *ngFor="let site of sites" [value]="site.siteId">
              {{ site.siteName}}
            </option>
          </select>
        </div>


        <div>
          <label>Filter Type</label>
          <select class="form-select margin-right-8px" [(ngModel)]="selectedval" placeholder="Filter Type"
            formControlName="filterType" (ngModelChange)="onfieldchange()">
            <option disabled value="">Choose Filter Type</option>
            <option *ngFor="let shown of showBy" [value]="shown.type">
              {{shown.name }}
            </option>
          </select>
        </div>

        <div class="col-12 mt-5 " style="text-align: end;justify-content: space-between;">
          <button class="btn playlist-btn-solid" [disabled]="(mCity.value && mSite.value &&
          mFilterType.value) ?
          false : true" (click)="showMapping(mFilterType.value)">Show Mapping</button>
          <button *ngIf="shiftmapping.length > 0" class="btn playlist-btn-solid marginLeft"
            (click)="exportAsXLSX()">Export
            Excel
          </button>
          <button *ngIf="showFlag" class="btn playlist-btn-solid marginLeft" (click)="saveMapping()">Save
            Mapping
          </button>
        </div>
      </div>
    </form>
  </div>
</div>


<div *ngIf="show" class="row mt-4">
  <!-- <div class="col-sm-12 col-md-12"> -->
  <div class="container">
    <div class="container-top" *ngIf="rows.length > 0">
      <mat-checkbox class="shift-pickup-check-box" [checked]="isAllChecked()"
        (change)="checkAll($event)"></mat-checkbox><span
        style="font-size: 14px; font-weight: 500; font-family: 'poppins' !important;">Select
        All</span>
    </div>
    <span class="items mat-color--white tdw-border-2
          tdw-font-14" *ngFor="let row of rows">
      <mat-checkbox class="shift-pickup-check-box" [(ngModel)]="row.state"
        (change)="onValueChecked($event,row)"></mat-checkbox>
      <span style="font-size: 13px; font-weight: 400; font-family: 'poppins' !important;">{{row.value}}</span>
    </span>
  </div>
  <div class="container">
    <div class="container-top" *ngIf="unPushedValues.length > 0">
      <mat-checkbox class="shift-pickup-check-box" [checked]="!isAllChecked() || isAllChecked()"
        (change)="unCheckAll($event)"></mat-checkbox> <span
        style="font-size: 14px; font-weight: 500; font-family: 'poppins' !important;">UnSelect
        All</span>
    </div>
    <span class="items mat-color--white tdw-border-2
          tdw-font-14" *ngFor="let row of unPushedValues">
      <mat-checkbox class="shift-pickup-check-box" [(ngModel)]="row.state"
        (change)="onSelValueChecked($event,row)"></mat-checkbox>
      <span style="font-size: 13px; font-weight: 400; font-family: 'poppins' !important;">{{row.value}}</span>
    </span>
  </div>
</div>

<div class="row mt-3" *ngIf="shiftmapping.length > 0">
  <div class="col-sm-12 col-md-12">
    <ng-container>
      <table fxFlex class="responsive tdw-position-relative
tdw-padding-6">
        <thead class="tdw-border-shift mat-color--grey-100 tdw-card-thin">
          <th class="tdw-padding-h-12 tdw--text-align-right sort-remove
tdw-padding-6 header-width1 shift-pickup-header">
            <ng-container>
              Location
            </ng-container>
          </th>
          <th class="tdw-padding-h-12 tdw--text-align-right sort-remove
tdw-padding-6 header-width1 shift-pickup-header">
            <ng-container>
              Site
            </ng-container>
          </th>

          <th class="tdw-padding-h-12 tdw--text-align-right sort-remove
tdw-padding-6 header-width1 shift-pickup-header">
            <ng-container>
              Shift Time
            </ng-container>
          </th>
          <th class="tdw-padding-h-12 tdw--text-align-right sort-remove
tdw-padding-6 header-width1 shift-pickup-header">
            <ng-container>
              Pick/Drop Type
            </ng-container>
          </th>

        </thead>
        <tbody class="mat-color--white tdw-border-2">
          <ng-container>
            <tr class="" *ngFor="let mapp of shiftmapping">
              <th class="tdw-padding-10-6
    tdw-font-14 tdw-font-open-sans tdw-font-normal
    mat-color-text--grey-800 shift-pickup-body">
                <ng-container>
                  {{mapp.site.location.locationName}}
                </ng-container>
              </th>

              <th class="tdw-padding-10-6
    tdw-font-14 tdw-font-open-sans tdw-font-normal
    mat-color-text--grey-800 shift-pickup-body">
                <ng-container>
                  {{mapp.site.siteName}}
                </ng-container>
              </th>

              <th class="tdw-padding-10-6
    tdw-font-14 tdw-font-open-sans tdw-font-normal
    mat-color-text--grey-800 shift-pickup-body">
                <ng-container>
                  {{mapp.shift.shiftTime}} {{mapp.shift.logType}}
                </ng-container>
              </th>

              <th class="tdw-padding-10-6
    tdw-font-14 tdw-font-open-sans tdw-font-normal
    mat-color-text--grey-800 shift-pickup-body">
                <ng-container>
                  {{mapp.type=="C"?"COMMON POINT":"HOME POINT"}}
                </ng-container>
              </th>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </ng-container>
  </div>
</div>