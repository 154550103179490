import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppConstants } from 'src/app/core/constants/appconstants';
import { ApiService } from 'src/app/core/services/api.service';
import { ServiceExportExcel } from 'src/app/core/services/export.service';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ServiceSharedData } from 'src/app/core/services/shared-data.service';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { UploadAplComponent } from './upload-apl/upload-apl.component';
import { BoundaryAuditComponent } from './boundary-audit/boundary-audit.component';
import { LandmarkComponent } from './landmark/landmark.component';
import { ZoneRenameComponent } from './zone-rename/zone-rename.component';
import { round } from "lodash";
import $ from "jquery";
import * as L from "leaflet";
import "leaflet-draw";
import 'leaflet.markercluster';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
declare var MarkerClusterer: any;
declare let google: any;

@Component({
  selector: 'app-master-routing',
  templateUrl: './master-routing.component.html',
  styleUrls: ['./master-routing.component.scss']
})
export class MasterRoutingComponent implements OnInit {

  public _mFormGroup: FormGroup;
  public location = new FormControl('', Validators.required);
  public site = new FormControl('', Validators.required);
  
  isOsm: boolean = false;
  cityLoading: boolean = true;
  siteLoading: boolean = false;
  editZoneFlag: boolean = false;
  polygonEditingFlag: boolean = false;
  addZoneFlag: boolean = false;
  viewMasterZone: boolean = false;
  showzone: boolean = false;
  isGeofenceChanged: boolean = false;
  viewStatsDetail: boolean = false;
  masterZoneData: boolean = false;
  isStats: boolean = false;
  showBoundary: boolean = true;
  editSiteBoundryFlag: boolean = true;
  isMasterZoneEdit: boolean = false;
  viewAllZone: boolean = false;
  isGeofence: boolean;
  cities: any;
  sites: any;
  citySelected: any;
  selectedCity: any;
  selectedCityValue: any = '';
  searchBox: any;
  newZone: any;
  newzone2: any;
  zoneName: any;
  editingPoly: any;
  masterZoneName: any;
  selectedzone: any;
  autoSelectedzone: any;
  markerClusterer: any;
  drawingManager: any;
  editablePolydata: any;
  editableLayer: any;
  newZoneLayer: any;
  editedZone: any;
  alFilter: any;
  alSearchFilter;
  editzonebounds: any = [];
  masterzone: any = [];
  polydata: any = {};
  map: any = {};
  zones: any = [];
  zonebounds: any = [];
  als_markers = [];
  siteMarker: any = [];
  sitePolydata: any[] = [];
  polylines: any[] = [];
  landMarkList: any[] = [];
  geocodes = [];
  zonePoly: any[] = [];
  editPath: any[] = [];
  allZones: any[] = [];
  editingZoneId: any = 0;
  exceptionPointC: number = 0;
  homePointC: number = 0;
  commonPointC: number = 0;
  totalAlC: number = 0;
  selectedSite: number;
  selectedSiteObj: any = {};
  markerpos = { lat: 0, lng: 0 };
  lat = 13.0196;
  lng = 77.5968;
  activeTabId = 1;
  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    private ngxLoader: NgxUiLoaderService,
    private serviceLocalStorage: ServiceLocalStorage,
    private cd: ChangeDetectorRef,
    private serviceExportExcel: ServiceExportExcel,
    public serviceSharedData: ServiceSharedData
  ) {
    this._mFormGroup = this.fb.group({
      'location': this.location,
      'site': this.site,
      
    });
   }

  ngOnInit() {
    this.loadCities();
    if (window.navigator && window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        position => {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
        }, error => {
          switch (error.code) {
            case 1:
              break;
            case 2:
              break;
            case 3:
              break;
          }
        });
    }
    this.cd.markForCheck();
    setTimeout(() => {
      if (AppConstants.OSRM === this.serviceLocalStorage.getMapType()) {
        this.isOsm = true;
        this.initOSMap();
      } else {
        this.initGMap();
      }
    }, 0);
  }

  omit_special_char_And_String(event) {
    var k;
    k = event.charCode; //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  loadCities() {
    let companyid: number = +this.serviceLocalStorage.getCompanyId();
    this.cityLoading = true;
    this.apiService.doGetRequestWithResponse(ApiConstants.getLocationsByCompanyIdAndUser + companyid, success => {
      this.cityLoading = false;
      this.cities = success.body;
    }, error => {
      this.cityLoading = false;
      console.log(error)
    });
  }

  onCityChange(locationId) {
    this.selectedCity = this.cities.find(x => x.locationId == locationId);
    this.getSitesByCityId(locationId);
  }

  getSitesByCityId(cityId) {
    this.siteLoading = true;
    this.apiService.doGetRequestWithResponse(ApiConstants.getSitesByLocationId + cityId, success => {
      this.siteLoading = false;
      if (success != null) {
        this.sites = success.body.dataList.content;
        this.plotSite();
      } else {
        this.isGeofence = false;
        this.sites = null;
        this.polylines.forEach(polylin => {
          polylin.setMap(null);
        });
        this.polylines = [];
        this.siteMarker.forEach(sitemarker => {
          sitemarker.setMap(null);
        });
        this.siteMarker = [];
      }
    }, error => {
      console.log(error)
      this.siteLoading = false;
      this.notifyService.showInfo("No Sites Found!", null);
    });
  }

  onSiteChange(event) {
    let objIndex = this.sites.findIndex(ele => ele.siteId == event.target.value);
    this.viewMasterZone = false;
    this.masterZoneName = "";
    this.addZoneFlag = false;
    this.zoneName = null;
    this.zones = [];
    this.showzone = false;
    this.isOsm ? this.loadOsmap() : this.loadgmap();
    if (this.polylines.length) {
      this.polylines.forEach(polylin => {
        this.isOsm ? this.map.removeLayer(polylin) : polylin.setMap(null);
      });
    }
    if (this.isOsm) {
      this.map.panTo(new L.LatLng(this.sites[objIndex].landmark.latitude, this.sites[objIndex].landmark.longitude));
    } else {
      this.map.setCenter({
        lat: this.sites[objIndex].landmark.latitude,
        lng: this.sites[objIndex].landmark.longitude
      });
    }
    this.selectedSite = this.sites[objIndex].siteId;
    this.selectedSiteObj = this.sites[objIndex];
    this.sitePolydata = [];
    this.isGeofenceChanged = false;
    this.showBoundaries(this.selectedSiteObj);
    this.getMasterZone();
    if (this.sites[objIndex].boundary) {
      this.isGeofence = true;
    } else {
      this.masterzone = [];
      this.isGeofence = false;
    }
    this.viewStatsDetail = false;
    this.landmarkCountbytype(this.selectedCity.locationId, this.selectedSite);
  }

  getMasterZone() {
    this.apiService.doGetRequestWithResponse(ApiConstants.masterZone + this.selectedSite, success => {
      if (success.status == 200) {
        this.masterZoneData = true;
        this.masterzone = success.body;
        this.selectedzone = this.masterzone[0];
        this.autoSelectedzone = this.masterzone[0].id;
      } else {
        this.masterZoneData = false;
        this.masterzone = [];
        this.selectedzone = null;
        this.autoSelectedzone = null;
      }
    }, error => {
      console.log(error)
      this.masterzone = [];
    });
  }

  saveMasteZone(masterZone) {
    if (masterZone != "" && masterZone != undefined) {
      // this.notifyService.showInfo("Updating details", null);
      this.apiService.doPostRequestWithResponse(ApiConstants.addMasterZone + masterZone + '&siteid=' + this.selectedSite, '', success => {
        if (success.status == 201) {
          this.getMasterZone();
          this.notifyService.showSuccess("Master Zone Created", null);
        } else if (success.status == 208) {
          this.notifyService.showWarning("Name already exist", null);
        } else {
          this.notifyService.showWarning("No Access", null);
        }
      }, error => {
        console.log(error)
      });
    } else {
      this.notifyService.showWarning("Please enter zone name", null);
    }
  }

  landmarkCountbytype(locationId, siteId) {
    this.clearMarkers();
    this.apiService.doGetRequestWithResponse(ApiConstants.landmarkCountbytype + locationId + '&siteId=' + siteId, success => {
      let counterData: any;
      if (success.status === 200) {
        counterData = success.body;
        this.exceptionPointC = counterData.Exceptions;
        this.homePointC = counterData.HOME;
        this.commonPointC = counterData.COMMON;
        this.totalAlC = counterData.TOTAL;
        if (this.totalAlC > 0) {
          this.isStats = true;
        } else {
          this.isStats = false;
          this.notifyService.showInfo("No landmarks found for selected site", null);
        }
      }
    }, error => {
      console.log(error)
    });
  }

  getAreaLandmarkByType(type) {
    this.ngxLoader.start();
    const data = {
      location: this.selectedCity.locationId,
      type: type,
      siteId: this.selectedSite
    };
    this.apiService.doGetRequestWithResponse(ApiConstants.getlandmarkbytype + data.location + '&type=' + data.type + '&siteId=' + data.siteId, success => {
      this.ngxLoader.stop();
      if (success.status === 200) {
        this.viewStatsDetail = true;
        this.landMarkList = success.body;
        this.clearMarkers();
        this.markLoadedLandmakrs();
      } else {
        this.landMarkList = [];
        this.viewStatsDetail = false;
        this.notifyService.showInfo("No landmarks found for selected site", null);
      }
    }, error => {
      this.ngxLoader.stop();
      console.log(error)
    });
  }

  markLoadedLandmakrs() {
    this.markerClusterer = L.markerClusterGroup();
    this.landMarkList.forEach(landmark => {
      if (landmark.latitude && landmark.longitude) {
        let path = "./assets/images/marker_pin.png";
        if (landmark.type == "H") {
          path = "./assets/images/marker_pin_green.png";
        } else if (landmark.type == "S") {
          path = "./assets/images/marker_pin_yellow.png";
        }
        if (this.isOsm) {
          const loc = L.latLng(landmark.latitude, landmark.longitude);
          const marker = new L.marker(loc, {
            icon: new L.Icon({
              iconUrl: path,
              popupAnchor: [0, 0],
              iconSize: [30, 30],
              iconAnchor: [15, 30]
            })
          }).bindPopup(landmark.area.area + "-" + landmark.landmark);
          this.markerClusterer.addLayer(marker);
          let name = landmark.area.area + "-" + landmark.landmark;
          this.geocodes.push({ name: name, id: landmark.id });
          this.als_markers.push(this.markerClusterer);
        } else {
          const infowindow = new google.maps.InfoWindow({
            content: landmark.area.area + "-" + landmark.landmark
          });
          const marker = new google.maps.Marker({
            position: { lat: landmark.latitude, lng: landmark.longitude },
            icon: path,
            draggable: false,
            map: this.map
          });
          let name = landmark.area.area + "-" + landmark.landmark;
          this.geocodes.push({ name: name, id: landmark.id });
          google.maps.event.addListener(marker, "click", event => {
            infowindow.open(this.map, marker);
          });
          this.als_markers.push(marker);
        }
      }
    });
    if (this.isOsm) {
      this.map.addLayer(this.markerClusterer);
    } else {
      const mcOptions = {
        imagePath: "./assets/images/m"
      };
      this.markerClusterer = new MarkerClusterer(this.map, this.als_markers, mcOptions);
    }
  }

  deleteLandmark(landmarkId) {
    if (confirm("Do you want to remove this geo-code?")) {
      this.apiService.doGetRequestWithResponse(ApiConstants.deleteLandmark + landmarkId, success => {
        if (success.status == 200) {
          this.landmarkCountbytype(
            this.selectedCity.locationId,
            this.selectedSite
          );
          this.notifyService.showSuccess("Removed Successfully", null);
        } else if (success.status == 202) {
          this.notifyService.showInfo("Some employees have mapped", null);
        }
      }, error => {
        console.log(error)
      });
    }
  }

  clearMarkers() {
    this.als_markers.forEach(marker => {
      if (this.isOsm) {
        this.map.removeLayer(marker);
      } else {
        marker.setMap(null);
        this.markerClusterer.clearMarkers();
      }
    });
    this.als_markers = [];
  }

  initOSMap() {
    this.map = L.map("map", {
      fullscreenControl: true,
      dragging: true
    }).setView([this.lat, this.lng], 13);
    L.tileLayer(AppConstants.OSM_TILE, { attribution: AppConstants.OSM_CONTRIBUTORS }).addTo(this.map);
  }

  initGMap() {
    const mapCenter = new google.maps.LatLng(this.lat, this.lng);
    this.map = new google.maps.Map(document.getElementById("map"), {
      center: mapCenter,
      zoom: 12,
      draggable: true,
      zoomControl: true
    });
    const input = document.getElementById("pac-input");
    this.searchBox = new google.maps.places.SearchBox(input);
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

    this.searchBox.addListener("places_changed", event => {
      const places = this.searchBox.getPlaces();
      if (places.length == 0) {
        return;
      }
      const bounds = new google.maps.LatLngBounds();
      places.forEach(place => {
        if (!place.geometry) {
          return;
        }
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
          var NE = bounds.getNorthEast();
          var SW = bounds.getSouthWest();
          var NW = new google.maps.LatLng(NE.lat(), SW.lng());
          var SE = new google.maps.LatLng(SW.lat(), NE.lng());
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      this.map.fitBounds(bounds);
    });
  }

  hideBoundary(flag) {
    this.showBoundary = flag;
    if (!flag) {
      this.polylines.forEach(poly => {
        if (this.isOsm) {
          this.map.removeLayer(poly);
        } else {
          poly.visible = false;
          poly.setMap(this.map);
        }
      });
    } else {
      this.polylines.forEach(poly => {
        if (this.isOsm) {
          this.map.addLayer(poly);
        } else {
          poly.visible = true;
          poly.setMap(this.map);
        }
      });
    }
  }

  removeNewZoneControl() {
    if (this.newZone) {
      this.map.removeControl(this.newZone);
    }
  }

  onReset() {
    this.isOsm ? this.loadOsmap() : this.loadgmap();
    this.zones = [];
    this.zonebounds = [];
    this.editingZoneId = 0;
    this.editZoneFlag = false;
    this.polygonEditingFlag = false;
    this.addZoneFlag = false;
    this.zoneName = null;
  }

  loadOsmap() {
    this.map.on("click", e => {
      if (this.isGeofence == false) {
        const loc = L.latLng(e.latLng.lat, e.latLng.lng);
        const marker = new L.marker(loc, {
          icon: new L.Icon({
            iconUrl: "./assets/images/marker_pin.png",
            popupAnchor: [0, 0],
            iconSize: [40, 40],
            iconAnchor: [20, 40]
          })
        }).addTo(this.map).bindPopup("New");
        this.markerpos = {
          lat: e.latLng.lat,
          lng: e.latLng.lng
        };
        this.als_markers.push(marker);
      }
    });
    if (this.sites != null) {
      this.plotSite();
    }
    if (this.selectedSiteObj.landmark != undefined) {
      this.map.panTo(
        new L.LatLng(
          this.selectedSiteObj.landmark.latitude,
          this.selectedSiteObj.landmark.longitude
        )
      );
      this.showBoundaries(this.selectedSiteObj);
    }
  }

  loadgmap() {
    this.map.addListener("bounds_changed", event => {
      this.searchBox.setBounds(this.map.getBounds());
    });
    google.maps.event.addListener(this.map, "click", event => {
      if (this.isGeofence == false) {
        var marker = new google.maps.Marker({
          position: event.latLng,
          icon: "./assets/images/marker_pin.png",
          draggable: true,
          animation: google.maps.Animation.DROP,
          map: this.map,
          title: "New"
        });
        this.markerpos = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng()
        };
        this.als_markers.push(marker);
      }
    });
    if (this.sites != null) {
      this.plotSite();
    }
    if (this.selectedSiteObj.landmark != undefined) {
      this.map.setCenter({
        lat: this.selectedSiteObj.landmark.latitude,
        lng: this.selectedSiteObj.landmark.longitude
      });
      this.showBoundaries(this.selectedSiteObj);
    }
  }

  plotSite() {
    if (this.isOsm) {
      this.siteMarker.forEach(siteM => {
        this.map.removeLayer(siteM);
      });
      this.siteMarker = [];
      this.sites.forEach(site => {
        let loc = L.latLng(site.landmark.latitude, site.landmark.longitude);
        let marker = new L.marker(loc, {
          icon: new L.Icon({
            iconUrl: "./assets/images/company.png",
            popupAnchor: [0, 0],
            iconSize: [40, 40],
            iconAnchor: [20, 40]
          })
        }).addTo(this.map).bindPopup(site.siteName);
        this.siteMarker.push(marker);
      });
    } else {
      this.siteMarker.forEach(sitemarker => {
        sitemarker.setMap(null);
      });
      this.sites.forEach(site => {
        let sitecordinates = new google.maps.LatLng(
          site.landmark.latitude,
          site.landmark.longitude
        );
        var infowindow = new google.maps.InfoWindow({
          content: site.siteName
        });
        let path = "./assets/images/company.png";
        var marker = new google.maps.Marker({
          position: sitecordinates,
          icon: {
            url: path,
            scaledSize: new google.maps.Size(40, 40)
          },
          map: this.map
        });
        google.maps.event.addListener(marker, "click", event => {
          infowindow.open(this.map, marker);
        });
        this.siteMarker.push(marker);
      });
    }
  }

  clearMap() {
    if (this.isOsm) {
      this.map.remove();
      this.initOSMap();
    } else {
      this.map = {};
      this.initGMap();
    }
    this.onSiteChange(this.selectedSiteObj);
  }

  onUploadAPL() {
    let dialogRef = this.dialog.open(UploadAplComponent, {
      width: "400px",
      data: this.selectedCity.locationId,
      disableClose: false
    });
    dialogRef.afterClosed().subscribe(result => {
      this.landmarkCountbytype(this.selectedCity.locationId, this.selectedSite);
    });
  }

  showBoundaries(site) {
    this.sitePolydata = [];
    let path: any = [];
    let polydata;
    let boundary = site.boundary ? site.boundary : "";
    if (this.editingPoly) {
      boundary = this.converPolyArrayToString(this.editingPoly);
    }
    if (boundary.length) {
      let boundaryArr: any = boundary.split("),(");
      if (this.isOsm) {
        this.polylines.forEach(polylin => {
          this.map.removeLayer(polylin);
        });
        this.polylines = [];
        boundaryArr.forEach(element => {
          if (element.includes("(")) element = element.split("(")[1];
          if (element.includes(")")) element = element.split(")")[0];
          path.push({
            lat: parseFloat(element.split(",")[0]),
            lng: parseFloat(element.split(",")[1])
          });
        });
        const editableLayers = new L.FeatureGroup();
        this.map.addLayer(editableLayers);
        editableLayers.addLayer(
          L.polygon(path).setStyle({
            color: "#143454",
            fillColor: "#58abff",
            weight: 2
          })
        );
        this.sitePolydata.push({
          id: site.siteId,
          polydata: editableLayers
        });
        this.polylines.push(editableLayers);
      } else {
        let infoWindow = new google.maps.InfoWindow();
        this.polylines.forEach(polylin => {
          polylin.setMap(null);
        });
        this.polylines = [];
        boundaryArr.forEach(element => {
          if (element.includes("(")) element = element.split("(")[1];
          if (element.includes(")")) element = element.split(")")[0];
          let coordinates = new google.maps.LatLng(
            element.split(",")[0],
            element.split(",")[1]
          );
          path.push(coordinates);
        });
        if (this.selectedSite == site.siteId) {
          polydata = new google.maps.Polygon({
            paths: path,
            map: this.map,
            editable: false,
            strokeWeight: 1,
            fillColor: "#FFF176"
          });
        } else {
          polydata = new google.maps.Polygon({
            paths: path,
            map: this.map,
            editable: false,
            strokeWeight: 1
          });
        }
        polydata.addListener("mousemove", event => {
          let timer = setTimeout(function () {
            infoWindow.close();
          }, 1000);
          infoWindow.setContent(site.siteName);
          infoWindow.setPosition(event.latLng);
        });
        this.sitePolydata.push({ id: site.siteId, polydata: polydata });
        this.polylines.push(polydata);
      }
    }
  }

  converPolyArrayToString(polyarray) {
    let polyString = "";
    polyarray.forEach(obj => {
      if (this.isOsm) {
        polyString += ",(" + obj.lat + "," + obj.lng + ")";
      } else {
        polyString += ",(" + obj.lat() + "," + obj.lng() + ")";
      }
    });
    polyString = polyString.replace(",", "");
    return polyString;
  }

  downloadGeoCodes() {
    const geoCodeArray: any[] = [];
    if (this.landMarkList.length > 0) {
      this.landMarkList.forEach(landMark => {
        let fileData = {
          Area: landMark.area.area,
          Landmark: landMark.landmark,
          lat: landMark.latitude,
          lng: landMark.longitude,
          type: landMark.type
        };
        geoCodeArray.push(fileData);
      });
      this.exportAsXLSX(geoCodeArray);
    }
  }

  exportAsXLSX(xlData): void {
    this.serviceExportExcel.exportAsExcelFile(xlData, "Landmarks");
  }

  createGeofence() {
    this.polygonEditingFlag = true;
    if (!this.isOsm) {
      this.createDrawingManager();
      this.setDrawingManager();
      google.maps.event.addListener(this.drawingManager, "overlaycomplete", event => {
        this.drawingManager.setDrawingMode(null);
        let path = event.overlay.getPath().getArray();
        let polydata = new google.maps.Polygon({
          paths: path,
          editable: true,
          map: this.map,
          fillColor: "#FFF176"
        });
        this.polylines.push(polydata);
        this.editingPoly = polydata.getPath().getArray();
        this.isGeofenceChanged = true;
        google.maps.event.addListener(polydata.getPath(), "set_at", () => {
          this.editingPoly = polydata.getPath().getArray();
          this.isGeofenceChanged = true;
        });
        google.maps.event.addListener(polydata.getPath(), "insert_at", () => {
          this.editingPoly = polydata.getPath().getArray();
          this.isGeofenceChanged = true;
        });
        google.maps.event.addListener(polydata.getPath(), "remove_at", () => {
          this.editingPoly = polydata.getPath().getArray();
          this.isGeofenceChanged = true;
        });
      }
      );
    } else {
      if (this.editSiteBoundryFlag) {
        this.editSiteBoundryFlag = false;
        const editableLayers = new L.FeatureGroup();
        this.map.addLayer(editableLayers);
        const polydata = new L.Control.Draw({
          edit: {
            featureGroup: editableLayers,
            remove: false
          },
          draw: {
            polygon: {
              allowIntersection: true
            },
            polyline: false,
            circle: false,
            rectangle: false,
            marker: false
          }
        });
        this.map.addControl(polydata);
        let element: HTMLElement = document.getElementsByClassName("leaflet-draw-draw-polygon")[0] as HTMLElement;
        element.click();
        this.map.on("draw:created", e => {
          this.isGeofenceChanged = true;
          this.polylines.push(polydata);
          this.editingPoly = e.layer._latlngs[0];
          this.map.removeControl(polydata);
          editableLayers.addLayer(e.layer);
        });
      }
    }
  }

  createDrawingManager() {
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: []
      }
    });
    this.drawingManager.setMap(this.map);
  }

  setDrawingManager() {
    this.drawingManager.setOptions({
      drawingControlOptions: {
        position: google.maps.ControlPosition.BOTTOM_LEFT,
        drawingModes: ["marker"]
      },
      drawingControl: true
    });
  }

  saveGeofence() {
    let geofence = "";
    if (this.editingPoly) {
      geofence = this.converPolyArrayToString(this.editingPoly);
      let userId = parseInt(this.serviceLocalStorage.getUserId().toString());
      this.ngxLoader.start();
      this.apiService.doPostRequestWithResponse(ApiConstants.updateBoundary + '?siteid=' + this.selectedSite + '&userid=' + userId + '&boundary=' + geofence, '', success => {
        this.ngxLoader.stop();
        if (success.status == 200) {
          this.isGeofence = true;
          this.isGeofenceChanged = false;
          this.notifyService.showSuccess("Geo-fence Updated Successfully!", null);
          this.onReset();
        }
      }, error => {
        this.ngxLoader.stop();
        this.notifyService.showError("Error in saving geo-fence!", null);
      });
    } else {
      geofence = "NULL";
      this.isGeofence = true;
      this.isGeofenceChanged = false;
      this.onReset();
    }
  }

  addBoundryLayer(poly, editableLayers) {
    this.map.addLayer(editableLayers);
    this.polydata = new L.Control.Draw({
      edit: {
        featureGroup: editableLayers,
        remove: false
      },
      draw: false
    });
    const polygonArray = [];
    poly.polydata._layers[
      poly.polydata._leaflet_id + 1
    ]._latlngs[0].forEach(poly => {
      polygonArray.push({
        lat: parseFloat(poly.lat),
        lng: parseFloat(poly.lng)
      });
    });
    editableLayers.addLayer(L.polygon(polygonArray));
  }

  editGeofence() {
    this.polygonEditingFlag = true;
    this.polylines.forEach(polylin => {
      this.isOsm ? this.map.removeLayer(polylin) : polylin.setMap(null);
    });
    this.polylines = [];
    this.sitePolydata.forEach(poly => {
      let flag: boolean = false;
      let polydata;
      if (poly.id === this.selectedSite) {
        flag = true;
        if (this.isOsm) {
          if (Object.keys(this.polydata).length > 0) {
            this.map.removeControl(this.polydata);
          }
          const editableLayers = new L.FeatureGroup();
          this.addBoundryLayer(poly, editableLayers);
          this.map.addControl(this.polydata);
          let element: HTMLElement = document.getElementsByClassName("leaflet-draw-edit-edit")[0] as HTMLElement;
          element.click();
          this.map.on("draw:editstop", e => {
            this.map.removeControl(this.polydata);
            this.map.removeLayer(editableLayers);
            this.showBoundaries(this.selectedSiteObj);
          });
          this.map.on("draw:edited", e => {
            this.isGeofenceChanged = true;
            e.layers.eachLayer(layer => { this.editingPoly = layer.editing.latlngs[0][0]; });
            this.polylines.push(this.polydata);
            this.map.removeControl(this.polydata);
          });
        } else {
          polydata = new google.maps.Polygon({
            paths: poly.polydata.getPath(),
            map: this.map,
            fillColor: "#FFF176",
            editable: flag
          });
          this.polylines.push(polydata);
          if (flag) {
            google.maps.event.addListener(polydata.getPath(), "set_at", () => {
              this.isGeofenceChanged = true;
              this.editingPoly = polydata.getPath().getArray();
            });
            google.maps.event.addListener(polydata.getPath(), "insert_at", () => {
              this.isGeofenceChanged = true;
              this.editingPoly = polydata.getPath().getArray();
            });
            google.maps.event.addListener(polydata.getPath(), "remove_at", () => {
              this.editingPoly = polydata.getPath().getArray();
              this.isGeofenceChanged = true;
            });
          }
        }
      } else {
        if (!this.isOsm) {
          polydata = new google.maps.Polygon({
            paths: poly.polydata.getPath(),
            map: this.map,
            editable: flag
          });
          this.polylines.push(polydata);
          if (flag) {
            google.maps.event.addListener(polydata.getPath(), "set_at", () => {
              this.isGeofenceChanged = true;
              this.editingPoly = polydata.getPath().getArray();
            });
            google.maps.event.addListener(
              polydata.getPath(), "insert_at", () => {
                this.isGeofenceChanged = true;
                this.editingPoly = polydata.getPath().getArray();
              });
            google.maps.event.addListener(
              polydata.getPath(), "remove_at", () => {
                this.editingPoly = polydata.getPath().getArray();
                this.isGeofenceChanged = true;
              });
          }
        }
      }
    });
  }

  deleteBoundary() {
    this.editingPoly = null;
    this.ngxLoader.start();
    let userId = parseInt(this.serviceLocalStorage.getUserId().toString());
    this.apiService.doPostRequestWithResponse(ApiConstants.deleteBoundary + this.selectedSite + '&userid=' + userId, '', success => {
      this.ngxLoader.stop();
      if (success.status == 200) {
        this.isGeofence = false;
        this.isGeofenceChanged = false;
        this.onReset();
        this.notifyService.showSuccess("Site Boundary Deleted Successfully!", null);
      }
    }, error => {
      console.log(error)
      this.ngxLoader.stop();
    });
  }

  showAudit() {
    this.ngxLoader.start();
    this.apiService.doPostRequestWithResponse(ApiConstants.getBoundaryAudit + this.selectedSite, '', success => {
      this.ngxLoader.stop();
      if (success.status === 200) {
        this.dialog.open(BoundaryAuditComponent, {
          data: success.body,
          width: '100vh',
          disableClose: true
        });
      } else {
        this.ngxLoader.stop();
        this.notifyService.showInfo("Audit not found", null);
      }
    }, error => {
      this.ngxLoader.stop();
      console.log(error)
    });
  }

  clearLandMarkList() {
    this.landMarkList = [];
  }

  masterZoneChanged(zone) {
    this.zones = [];
    this.showzone = false;
    this.zonePoly.forEach(polylin => {
      this.isOsm ? this.map.removeLayer(polylin) : polylin.setMap(null);
    });
    this.masterzone.forEach(mzone => {
      if (mzone.id == zone.value) {
        this.selectedzone = mzone;
      }
    });
  }

  editMasterZone() {
    this.isMasterZoneEdit = true;
  }

  removeMasterZone() {
    if (confirm("Do you want to delete the master zone set up?")) {
      this.apiService.doDeleteRequestWithResponse(ApiConstants.deleteMasterZone + this.selectedzone.id, success => {
        if (success.status == 202) {
          this.onReset();
          this.notifyService.showSuccess("Master Zone removed", null);
        } else {
          this.notifyService.showError("Error in updating", null);
        }
      }, error => {
        console.log(error)
      });
    }
  }

  cancelMasterZoneEdit() {
    this.isMasterZoneEdit = false;
    this.autoSelectedzone = this.selectedzone.id;
  }

  updateMasterZone(zone) {
    if (zone != "" && zone != undefined) {
      // this.notifyService.showInfo("Updating", null);
      this.apiService.doPutRequestWithResponse(ApiConstants.addMasterZone + zone + '&zoneid=' + this.selectedzone.id, '', success => {
        if (success.status == 200) {
          this.getMasterZone();
          this.autoSelectedzone = this.selectedzone.id;
          this.isMasterZoneEdit = false;
          this.notifyService.showSuccess("Master Zone name updated", null);
        } else if (success.status == 208) {
          this.notifyService.showWarning("Zone name already exist, please change the name!", null);
        } else {
          this.notifyService.showWarning("Updation failed", null);
        }
      }, error => {
        console.log(error)
      });
    } else {
      this.notifyService.showWarning("Please enter master zone name", null);
    }
  }

  showZones() {
    this.zonePoly.forEach(polylin => {
      this.isOsm ? this.map.removeLayer(polylin) : polylin.setMap(null);
    });
    this.zonePoly = [];
    if (this.zones.length > 0) {
      this.showzone = true;
      this.plotZone();
    } else {
      this.ngxLoader.start();
      this.apiService.doGetRequestWithResponse(ApiConstants.getZones + this.selectedzone.id, success => {
        this.ngxLoader.stop();
        if (success.status == 200) {
          success.body.forEach(element => {
            element.viewFlag = false;
            this.apiService.doGetRequestWithResponse(ApiConstants.getzonebyid + element.id, success => {
              this.ngxLoader.stop();
              if (success.status == 200) {
                this.newzone2 = success.body;
                element.commonpoint = this.newzone2[0][1];
                element.homepoint = this.newzone2[0][2];
                this.showzone = true;
              } else if (success.status == 204) {
                this.notifyService.showInfo("Zone not found", null);
              } else {
                this.notifyService.showWarning("No Access!", null);
              }
            }, error => {
              this.ngxLoader.stop();
              console.log(error)
            });
            this.zones.push(element);
          });
          this.showzone = true;
          this.plotZone();
        } else if (success.status == 204) {
          this.notifyService.showInfo("Zone not found", null);
        } else {
          this.notifyService.showWarning("No Access!", null);
        }
      }, error => {
        this.ngxLoader.stop();
        console.log(error)
      });
    }
  }

  removeEditControlAndLayer() {
    if (this.editablePolydata) {
      this.map.removeLayer(this.editableLayer);
      this.map.removeControl(this.editablePolydata);
    }
  }

  plotZone() {
    this.editPath = [];
    this.zones.forEach(zploy => {
      if (zploy.viewFlag) {
        let path: any = [];
        let boundaryArr: any = zploy.zonepoly.split("),(");
        let coordinates;
        boundaryArr.forEach(element => {
          if (element.includes("(")) element = element.split("(")[1];
          if (element.includes(")")) element = element.split(")")[0];
          if (this.isOsm) {
            coordinates = L.latLng(element.split(",")[0], element.split(",")[1]);
          } else {
            coordinates = new google.maps.LatLng(element.split(",")[0], element.split(",")[1]);
          }
          path.push(coordinates);
          if (this.editZoneFlag == true) {
            if (this.editingZoneId == zploy.id) {
              this.editPath.push(coordinates);
            }
          }
        });
        let editableFlag = false;
        if (this.editZoneFlag == true) {
          if (this.editingZoneId == zploy.id) {
            editableFlag = true;
          }
        }
        if (editableFlag) {
          if (this.isOsm) {
            this.editableLayer = new L.FeatureGroup();
            this.map.addLayer(this.editableLayer);
            this.editablePolydata = new L.Control.Draw({
              edit: {
                featureGroup: this.editableLayer,
                remove: false
              },
              draw: false
            });
            this.editableLayer.addLayer(L.polygon(this.editPath));
            this.map.addControl(this.editablePolydata);
            let element: HTMLElement = document.getElementsByClassName("leaflet-draw-edit-edit")[0] as HTMLElement;
            element.click();
            this.map.on("draw:editstop", e => {
              this.map.removeControl(this.editablePolydata);
              this.removeNewZoneControl();
            });
            this.map.on("draw:edited", e => {
              this.zonePoly.push(this.editablePolydata);
              e.layers.eachLayer(layer => {
                this.editzonebounds = layer.editing.latlngs[0][0];
              });
            });
          } else {
            let polydata = new google.maps.Polygon({
              paths: this.editPath,
              editable: this.editZoneFlag,
              map: this.map,
              fillColor: "#e8e5d0"
            });
            this.zonePoly.push(polydata);
            this.editzonebounds = polydata.getPath().getArray();
            google.maps.event.addListener(polydata.getPath(), "set_at", () => {
              this.editzonebounds = polydata.getPath().getArray();
            });
            google.maps.event.addListener(polydata.getPath(), "insert_at", () => {
              this.editzonebounds = polydata.getPath().getArray();
            });
            google.maps.event.addListener(polydata.getPath(), "remove_at", () => {
              this.editzonebounds = polydata.getPath().getArray();
            });
          }
        } else {
          if (this.isOsm) {
            const editableLayers = new L.FeatureGroup();
            this.map.addLayer(editableLayers);
            editableLayers.addLayer(
              L.polygon(path).setStyle({
                color: "#143454",
                fillColor: "#58abff",
                weight: 2
              })
            )
            editableLayers.bindPopup(zploy.name);
            this.zonePoly.push(editableLayers);
          } else {
            let polydata = new google.maps.Polygon({
              paths: path,
              editable: editableFlag,
              map: this.map,
              fillColor: "#e8e5d0"
            });
            polydata.addListener("rightclick", event => {
              let infoWindow = new google.maps.InfoWindow();
              infoWindow.setContent(zploy.name);
              infoWindow.setPosition(event.latLng);
              infoWindow.open(this.map);
            });
            this.zonePoly.push(polydata);
            this.zonebounds = polydata.getPath().getArray();
            google.maps.event.addListener(polydata.getPath(), "set_at", () => {
              this.zonebounds = polydata.getPath().getArray();
              if (this.editZoneFlag)
                this.editzonebounds = polydata.getPath().getArray();
            });
            google.maps.event.addListener(polydata.getPath(), "insert_at", () => {
              this.zonebounds = polydata.getPath().getArray();
              if (this.editZoneFlag)
                this.editzonebounds = polydata.getPath().getArray();
            });
            google.maps.event.addListener(polydata.getPath(), "remove_at", () => {
              this.zonebounds = polydata.getPath().getArray();
              if (this.editZoneFlag)
                this.editzonebounds = polydata.getPath().getArray();
            });
          }
        }
      }
    });
  }

  hideZones() {
    this.showzone = false;
    this.zonePoly.forEach(polylin => {
      this.isOsm ? this.map.removeLayer(polylin) : polylin.setMap(null);
    });
  }

  deleteZone(zoneid) {
    if (confirm("Do you want to delete the zone?")) {
      this.apiService.doDeleteRequestWithResponse(ApiConstants.zone + zoneid, success => {
        if (success.status == 202) {
          this.showZones();
          this.notifyService.showSuccess("Zone deleted!", null);
        } else {
          this.notifyService.showInfo("Zone not found!", null);
        }
      }, error => {
        console.log(error)
      });
    }
  }

  enablecreatezone() {
    if (this.selectedzone != undefined && this.masterzone.length > 0) {
      if (!this.polygonEditingFlag) {
        this.polygonEditingFlag = true;
        if (this.isOsm) {
          this.newZoneLayer = new L.FeatureGroup();
          this.map.addLayer(this.newZoneLayer);
          this.newZone = new L.Control.Draw({
            edit: {
              featureGroup: this.newZoneLayer,
              remove: false
            },
            draw: {
              polygon: {
                allowIntersection: true
              },
              polyline: false,
              circle: false,
              rectangle: false,
              marker: false
            }
          });
          this.map.addControl(this.newZone);
          let element: HTMLElement = document.getElementsByClassName("leaflet-draw-draw-polygon")[0] as HTMLElement;
          element.click();
          this.map.on("draw:drawstop", e => {
            this.map.removeControl(this.newZone);
          });
          this.map.on("draw:created", e => {
            this.zonebounds = e.layer._latlngs[0];
            this.newZoneLayer.addLayer(e.layer);
            this.map.removeControl(this.newZone);
          });
        } else {
          this.createDrawingMaager();
          this.setDrawingManager();
          google.maps.event.addListener(this.drawingManager, "overlaycomplete", event => {
            this.drawingManager.setDrawingMode(null);
            let path = event.overlay.getPath().getArray();
            let polydata = new google.maps.Polygon({
              paths: path,
              editable: true,
              map: this.map,
              fillColor: "#e8e5d0"
            });
            this.zonebounds = polydata.getPath().getArray();
            google.maps.event.addListener(
              polydata.getPath(), "set_at", () => {
                this.zonebounds = polydata.getPath().getArray();
              });
            google.maps.event.addListener(
              polydata.getPath(), "insert_at", () => {
                this.zonebounds = polydata.getPath().getArray();
              });
            google.maps.event.addListener(polydata.getPath(), "remove_at", () => {
              this.zonebounds = polydata.getPath().getArray();
            }
            );
          }
          );
        }
      }
    } else {
      this.notifyService.showWarning("Please select Master Zone", null);
    }
  }

  createDrawingMaager() {
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: []
      }
    });
    this.drawingManager.setMap(this.map);
  }

  savezone(zoneName) {
    if (this.selectedzone != undefined && this.masterzone.length > 0) {
      if (this.zonebounds.length > 0) {
        if (zoneName != null) {
          this.ngxLoader.start();
          let zoneboundary = this.converPolyArrayToString(this.zonebounds);
          let param = {
            masterZone: { id: this.selectedzone.id },
            name: zoneName,
            zonepoly: zoneboundary
          };
          this.apiService.doPostRequestWithResponse(ApiConstants.addZone, param, success => {
            this.ngxLoader.stop();
            if (success.status == 200) {
              this.onReset();
              this.notifyService.showSuccess("Zone Created Successfully!", null);
              this.map.removeLayer(this.newZoneLayer);
            } else if (success.status == 208) {
              this.notifyService.showInfo("Zone name already exist, please change the name!", null);
            }
          }, error => {
            this.ngxLoader.stop();
            console.log(error)
          });
        } else {
          this.notifyService.showInfo("Zone name should not be empty", null);
        }
      } else {
        this.notifyService.showInfo("Please draw the Zone on map", null);
      }
    } else {
      this.notifyService.showWarning("Please select Master Zone", null);
    }
  }

  upadatezone() {
    let zoneboundary;
    if (this.editingZoneId != 0 && this.editZoneFlag) {
      if (this.editzonebounds[0].lat) {
        zoneboundary = this.converPolyArrayToString(this.editzonebounds);
      } else {
        zoneboundary = this.editzonebounds;
      }
      this.ngxLoader.start();
      this.apiService.doPutRequestWithResponse(ApiConstants.zone + this.editingZoneId + '&zonepoly=' + zoneboundary + '&zoneName=' + this.zoneName, '', success => {
        this.ngxLoader.stop();
        if (success.status == 200) {
          this.map.removeLayer(this.editableLayer);
          this.onReset();
          this.notifyService.showSuccess("Zone Updated Successfully!", null);
          this.map.removeControl(this.editablePolydata);
        } else if (success.status == 208) {
          this.notifyService.showInfo("Zone name already exist, please change the name!", null);
        }
      }, error => {
        this.ngxLoader.stop();
        console.log(error)
      });
    }
  }

  editZone(zoneid, zone, i) {
    this.zoneName = zone.name;
    this.zonebounds = [];
    this.editzonebounds = [];
    this.zonebounds.push(zone.zonepoly);
    this.editzonebounds.push(zone.zonepoly);
    this.editZoneFlag = true;
    this.editingZoneId = zoneid;
    this.zones[i].viewFlag = true;
    this.hideZones();
    this.plotZone();
    this.addZoneFlag = true;
  }

  onShowAllZone() {
    this.viewAllZone = !this.viewAllZone;
    this.zones.viewFlag = !this.zones.viewFlag;
    if (this.viewAllZone) {
      this.allZones = [];
      this.zones.viewFlag = !this.zones.viewFlag;
      this.zones.forEach(zploy => {
        this.zones.viewFlag = true;
        let path: any = [];
        let boundaryArr: any = zploy.zonepoly.split("),(");
        let coordinates;
        boundaryArr.forEach(element => {
          if (element.includes("(")) element = element.split("(")[1];
          if (element.includes(")")) element = element.split(")")[0];
          if (this.isOsm) {
            coordinates = L.latLng(
              element.split(",")[0],
              element.split(",")[1]
            );
          } else {
            coordinates = new google.maps.LatLng(
              element.split(",")[0],
              element.split(",")[1]
            );
          }
          path.push(coordinates);
        });
        if (this.isOsm) {
          const editableLayers = new L.FeatureGroup();
          this.map.addLayer(editableLayers);
          editableLayers.addLayer(
            L.polygon(path).setStyle({
              color: "#8B0000",
              fillColor: "#58abff",
              opacity: 0.6,
              fillOpacity: 0.65,
              stroke: "#66ccc",
              fill: "#ee9900",
              fillopacity: 0.4,
              weight: 2,
              strokeopacity: 1,
              strokewidth: 2,
              strokelinecap: round,
              strokelinejoin: round
            })
          );
          editableLayers.bindPopup(
            $('<b style="cursor:pointer"> <tr><td><b>Zone Name -</b> </td><td>' + zploy.name + '</td></tr><br><tr><td><b>Common Pin -</b> </td><td>' +
              zploy.commonpoint + '</td></tr></br><tr><td><b>Home Pin -</b> </td><td>' + zploy.homepoint + "</td></tr></b>'").click(() => { })[0]
          );
          this.allZones.push(editableLayers);
        } else {
          let polydata = new google.maps.Polygon({
            paths: path,
            editable: false,
            map: this.map,
            fillColor: "#e8e5d0"
          });
          polydata.addListener("rightclick", event => {
            let infoWindow = new google.maps.InfoWindow();
            infoWindow.setContent(zploy.name);
            infoWindow.setPosition(event.latLng);
            infoWindow.open(this.map);
          });
          this.allZones.push(polydata);
        }
      });
    }
    if (!this.viewAllZone) {
      this.allZones.forEach(zone => {
        this.isOsm ? this.map.removeLayer(zone) : zone.setMap(null);
      });
    }
  }

  onShowZone(i) {
    this.zones[i].viewFlag = !this.zones[i].viewFlag;
    this.hideZones();
    this.plotZone();
  }

  onAddApl(l) {
    if (this.selectedCity && this.selectedSiteObj) {
      // this.apiService.doGetRequestWithResponse(ApiConstants.getarealist + this.selectedCity.locationId, success => {
        this.apiService.doGetRequestWithResponse(ApiConstants.getAreaListByLocation + this.selectedCity.locationId, success => {
        if (success.status == 200) {
          let aplData = {
            area: success.body,
            landmark: l == undefined ? null : l,
            site: this.selectedSiteObj
          };
          let dialogRef = this.dialog.open(LandmarkComponent, {
            data: aplData,
            disableClose: true
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
            }
          });
        } else {
          this.notifyService.showInfo("No areas found", null);
        }
      }, error => {
        console.log(error)
      });
    } else {
      this.notifyService.showWarning("Please select city/site", null);
    }
  }

  onLocationArrow(l) {
    this.map.setView([l.latitude, l.longitude], 17);
  }

  cloneMasterZone() {
    let dialogRef = this.dialog.open(ZoneRenameComponent, {
      width: "450px",
      data: this.selectedzone.name,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ngxLoader.start();
        this.apiService.doPostRequestWithResponse(ApiConstants.cloneMasterZone + this.selectedzone.id + '&zonename=' + result, '', success => {
          if (success.status == 200) {
            this.getMasterZone();
            this.notifyService.showSuccess("Clone successful", null);
            this.ngxLoader.stop();
          } else if (success.status == 208) {
            this.ngxLoader.stop();
            this.notifyService.showInfo("Zone name already exist, please change the name!", null);
          } else {
            this.ngxLoader.stop();
            this.notifyService.showError("Error in clonning", null);
          }
        }, error => {
          this.notifyService.showError("Error in clonning", null);
        });
      }
    });
  }

  onMouseOverOnMap() {
    if (!this.isOsm) {
      document.getElementById("pac-input").style.display = "block";
    }
  }

}
