import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { AppConstants } from 'src/app/core/constants/appconstants';
import { ApiService } from 'src/app/core/services/api.service';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-add-vendor-adjustment-trips',
  templateUrl: './add-vendor-adjustment-trips.component.html',
  styleUrls: ['./add-vendor-adjustment-trips.component.scss']
})
export class AddVendorAdjustmentTripsComponent {

  public _mFormGroup: FormGroup;
  usersNotFound: boolean;
  _mSitesData: any;
  _mVendors: any;
  _mEscort: any[] = [];
  _mBookingTypes: any[] = [];
  _mData: any;
  _BookedForUser: any;
  _mVehicleTypes: any[] = [];
  currentStatus: any = "";
  pastDate = new Date();
  toDatePickerMin = new Date(new Date().setDate(this.pastDate.getDate() - 30));
  max = new Date();
  toDatePickerMax = new Date(new Date().setDate(this.max.getDate() - 0));
  user: any[] = [];
  _mEditReportingTimes: any[] = AppConstants.reportingTimes;
  reportingTimes: any[] = [];

  public mUserName = new FormControl("", Validators.required);
  public mEmployeeId = new FormControl();
  public mSites = new FormControl("", Validators.required);
  public mApproxKm = new FormControl("", Validators.required);
  public mTravelDate = new FormControl(new Date(), Validators.required);
  public mLogType = new FormControl("", Validators.required);
  public mNearestShiftTime = new FormControl("", Validators.required);
  public mEscortRequired = new FormControl(false, Validators.required);
  public mEscort = new FormControl("", Validators.required);
  public mReason = new FormControl("");
  public mVehicleNumber = new FormControl("", Validators.required);
  public mVehicleType = new FormControl("", Validators.required);
  public mVendor = new FormControl("", Validators.required);
  public mDriverName = new FormControl("", Validators.required);

  nearestShiftTime =
    new Date(this.mTravelDate.value).getHours() +
    "" +
    new Date(this.mTravelDate.value).getMinutes();
  empSearchFlag: boolean = false;
  searchSubject$ = new Subject<string>();
  _driverList: any[] = [];
  mVehicleList: any;
  logType = [
    { key: "IN", value: "IN" },
    { key: "OUT", value: "OUT" }
  ];

  constructor(
    private fb: FormBuilder,
    private serviceLocalStorage: ServiceLocalStorage,
    public dialogRef: MatDialogRef<AddVendorAdjustmentTripsComponent>,
    public dialog: MatDialog,
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ngxLoader: NgxUiLoaderService,
  ) { }

  ngOnInit() {
    this._mFormGroup = this.fb.group({
      userName: this.mUserName,
      employeeId: this.mEmployeeId,
      site: this.mSites,
      travelDate: this.mTravelDate,
      logType: this.mLogType,
      approxKm: this.mApproxKm,
      // vehicleNo:this.mVehicleNumber,
      nearestShiftTime: this.mNearestShiftTime,
      escortRequired: this.mEscortRequired,
      reason: this.mReason,
      vehicleType: this.mVehicleType,
      vendor: this.mVendor,
      escort: this.mEscort,
      driverName: this.mDriverName,
      vehicleNumber: this.mVehicleNumber,
    });

    this.getSites();
    let a = this.mVendor.value;
    let b = this.mSites.value;
    this.getBookingTypes();
    this.getTime();
    this._mData = this.serviceLocalStorage.getUserObject();
    this.mEmployeeId.disable();
    this.onEscortToggle();
    if (new Date().getHours() < 10 && new Date().getMinutes() < 10) {
      this.nearestShiftTime =
        "0" + new Date().getHours() + "0" + new Date().getMinutes();
    } else if (new Date().getHours() < 10) {
      this.nearestShiftTime =
        "0" + new Date().getHours() + "" + new Date().getMinutes();
    } else if (new Date().getMinutes() < 10) {
      this.nearestShiftTime =
        new Date().getHours() + "0" + new Date().getMinutes();
    } else {
      this.nearestShiftTime = new Date().getHours() + "" + new Date().getMinutes();
    }

    this.searchSubject$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(searchValue => {
      this.empSearchFlag = true;
      if (searchValue.length < 3) {
        this.empSearchFlag = false;
      }
      let spocId = null;
      if (this._mData.userInfoDetails.role.name === 'SPOC') {
        spocId = this._mData.id
      } else {
        spocId = 0;
      }
      if (searchValue.length >= 3) {
        this.apiService.doGetRequestWithResponse(ApiConstants.getuserbyfirstname + '?searchkey=' + searchValue + '&spocId=' + spocId, success => {
          this.empSearchFlag = false;
          this.user = success.body;
        },
          error => {
            this.empSearchFlag = false;
          }
        );
      }
    });
  }


  onSiteChange(option) {
    this.mVendor.setValue('');
    this.getVendorBySite(option.value);
    this.mEscort.setValue("");
    this.getEscortListBySite(option.value);
    this.getVehicleTypes(option.value);
  }

  getSites() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getsitesbyuserid,
      success => {
        if (success != null) {
          this._mSitesData = success.body;
        }
      },
      error => {
      }
    );
  }

  onVehicleChange() {
    this.getDriverList();
  }

  getDriverList() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getDriverListByVehicleId + this.mVehicleNumber.value, (success) => {
      if (success.status == 200) {
        this._driverList = success.body;
      }
      else {
        this._driverList = [];
        this.notifyService.showInfo("No drivers found", null);
      }
    }, error => {
      this.notifyService.showError("Error in getting drivers", null);
    }
    )
  }

  getBookingTypes() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getSpecialbookingType,
      success => {
        this._mBookingTypes = success.body;
      },
      error => {
      }
    );
  }

  onEscortToggle() {
    if (this.mEscortRequired.value) {
      this.mEscort.enable();
    } else {
      this.mEscort.disable();
      this.mEscort.setValue("");
    }
  }

  searchSubmit(option) {
    this.mEmployeeId.setValue(option.userInfoDetails.employeeId);
    this.mSites.setValue(option.userInfoDetails.site.siteId);
    this.getVendorBySite(option.userInfoDetails.site.siteId);
    this.getEscortListBySite(option.userInfoDetails.site.siteId);
    this.getVehicleTypes(option.userInfoDetails.site.siteId);
    this._BookedForUser = option;
  }

  getVendorBySite(siteId) {
    this.apiService.doGetRequestWithResponse(ApiConstants.getAllVendorDropDownBasedOnSiteAndUser + siteId, success => {
      if (success.status == 200) {
        this._mVendors = success.body;
      } else {
        this._mVendors = [];
        this.notifyService.showInfo("No Vendors Found", null);
      }
    },
      error => {
        this.notifyService.showError(error.message, null);
      }
    );
  }

  onVehicleTypeChange() {
    this.getVehicleList();
  }

  getVehicleList() {
    if (this.mVendor.value && this.mVendor.value != "") {
      this.apiService.doGetRequestWithResponse(ApiConstants.getVehicleNo + this.mSites.value + '&vendormasterid=' + this.mVendor.value + '&vehicletypeid=' + this.mVehicleType.value, success => {
        if (success) {
          // this.mVehicleList = success.body.filter(vehicle => vehicle.vehicleType.id == this.mVehicleType.value);
          this.mVehicleList = success.body;
        } else {
          this.mVehicleList = [];
          this.notifyService.showInfo("No vehicles found", null);
        }
      },
        error => {
          this.mVehicleList = [];
          this.notifyService.showWarning("Error in getting vehicles", null);
        })
    } else {
      this.notifyService.showWarning("Please select the vendor", null);
    }
  }

  dateChanged() {
    if (this.formatDate(this.mTravelDate.value) == this.formatDate(new Date())) {
      this.nearestShiftTime = new Date().getHours() + "" + new Date().getMinutes();
    } else {
      this.nearestShiftTime =
        new Date(this.mTravelDate.value).getHours() +
        "" +
        new Date(this.mTravelDate.value).getMinutes();
    }
    this.mNearestShiftTime.setValue("");
    this.getTime();
  }

  getEscortListBySite(siteId) {
    this._mEscort = [];
    this.apiService.doGetRequestWithResponse(ApiConstants.getEscortListBySiteId + siteId, success => {
      if (success) {
        this._mEscort = success.body;
      } else {
        this._mEscort = [];
        this.notifyService.showInfo("No Escort Found", null);
      }
    },
      error => {
      }
    );
  }

  getVehicleTypes(siteId) {
    this.apiService.doGetRequestWithResponse(ApiConstants.getVehicleTypeBySite + "?siteId=" + siteId, success => {
      if (success.status == 200) {
        this._mVehicleTypes = success.body;
      } else if (success.status == 204) {
        this.notifyService.showInfo("No Vehicles Found", null);
      }
    },
      error => {
        this.notifyService.showError(error.message, null);
      }
    );
  }

  getTime() {
    this.reportingTimes = [];
    AppConstants.reportingTimes.forEach(element => {
      if (
        element.value.slice(0, 2) + "" + element.value.slice(3, 5) >
        this.nearestShiftTime.toString()
      ) {
        this.reportingTimes.push(element);
      }
    });
  }

  formatDatePickerDate(d) {
    let date = d.split("/");
    return date[1] + "/" + date[0] + "/" + date[2];
  }


  formatDate(d): any {
    let m = d.getMonth() + 1;
    let dt = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
    let mm = m < 10 ? "0" + m : m;
    return dt + "/" + mm + "/" + d.getFullYear();
  }

  onSubmit() {
    let escort;
    if (this.mEscortRequired.value) {
      escort = {
        id: this.mEscort.value
      };
    }

    let newData = null;
    newData = {
      "bookedFor": {
        "id": this._BookedForUser.id,
      },
      "bookedBy": {
        "id": this._mData.id
      },
      "escort": this.mEscortRequired.value == true ? escort : null,
      "escortRequired": this.mEscortRequired.value == true ? "true" : "false",
      "reason": this.mReason.value,
      "pickDrop": this.mLogType.value,
      "site": {
        "siteId": this.mSites.value
      },
      "vehicleType": {
        "id": this.mVehicleType.value
      },
      "vehicle": {
        "id": this.mVehicleNumber.value
      },
      "nearestTime": this.mNearestShiftTime.value,
      "status": "SENT_FOR_ADMIN_APPROVAL",
      "travelDate": this.formatDate(this.mTravelDate.value),
      "driver": {
        "id": this.mDriverName.value,
      },
      "approximateKm": +(this.mApproxKm.value)
    };

    this.ngxLoader.start();
    this.apiService.doPostRequestWithResponse(ApiConstants.adjustmentTrip,
      newData,
      success => {
        this.ngxLoader.stop();
        if (success.status === 201) {
          this.dialogRef.close(success);
          this.notifyService.showSuccess(success.body.message, null);
        }
        else {
          this.notifyService.showWarning("Error while adding trips", null);
        }
      },
      error => {
        this.ngxLoader.stop();
      }
    );
  }

  onClickCancel(): void {
    this.dialogRef.close();
  }

}
