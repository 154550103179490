import { Component, Input, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Location } from '@angular/common';
import { ApiService } from 'src/app/core/services/api.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ModelVehicleType } from 'src/app/shared/models/vehicle/vehicle-type';
import { ModelVendorMaster } from 'src/app/shared/models/vendor/vendor-master';
import { PercentModel, VehicleTypePercentageList, VendorPercentageList } from 'src/app/shared/models/percent-model';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';

@Component({
  selector: 'app-allocate-vendor-ratio',
  templateUrl: './allocate-vendor-ratio.component.html',
  styleUrls: ['./allocate-vendor-ratio.component.scss']
})
export class AllocateVendorRatioComponent implements OnInit {
  _vendorData: ModelVendorMaster[] = [];
  _vehicleTypeData: ModelVehicleType[] = [];
  _percentData: PercentModel = new PercentModel();
  _siteId: number;
  _shiftId: number;
  _tripDate: Date;
  _data: any[] = [];
  _valid: boolean = true;
  @Input("siteid") set siteId(value: number) {
    this._siteId = value;
  }

  @Input("shiftid") set shiftId(value: number) {
    this._shiftId = value;
  }

  @Input("tripdate") set tripDate(value: Date) {
    this._tripDate = value;
  }

  constructor(
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    private ngxLoader: NgxUiLoaderService,
    private location: Location) { }

  ngOnInit() {
    this.getVendors();
    this.getVehicleTypesBySite();
  }

  getVendors() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getAllVendorMasterForDropDown, (success) => {
      if (success.status == 200) {
        this._vendorData = success.body;
      } else {
        this.notifyService.showInfo("Oops ...Vendor Master Not found", null);
      }
    }, (error) => {
      console.log(error)
    });
  }

  getVehicleTypesBySite() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getSitesById + this._siteId, (success) => {
      if (success.body.dataList.content != null) {
        this._vehicleTypeData = success.body.dataList.content[0].vehicleTypes;
      }
    }, (error) => {
      console.log(error)
    });
  }

  allocateVendorPercent() {
    this.ngxLoader.start();
    this.apiService.doGetRequestWithResponse(ApiConstants.getgeneratedtripcount + this._tripDate + '&shiftid=' + this._shiftId + '&siteid='
      + this._siteId, (success) => {
        if (success.status == 200) {
          this._valid = false;
        }
      }, (error) => {
        console.log(error)
      });
    let _inData: number[] = [];
    this._data.forEach(d => {
      _inData.push(d);
    });
    if (this._valid) {
      if (_inData.length > 0 && _inData.length == this._vendorData.length * this._vehicleTypeData.length) {
        let array_of_sets = [];
        while (_inData.length) {
          array_of_sets.push(_inData.splice(0, this._vendorData.length));
        }
        for (let i = 0; i < array_of_sets.length; i++) {
          let sum = array_of_sets[i].reduce(this.addPercentage, 0);
          if (sum != 100) {
            this.ngxLoader.stop();
            this.notifyService.showInfo("Total percentage should be 100", null);
            return;
          }
        };
        let vehicleTypePercentageLists: VehicleTypePercentageList[] = [];
        let vendorPercentageLists: VendorPercentageList[] = [];
        let counter = 0;
        for (let j = 0; j < this._vehicleTypeData.length; j++) {
          let vehicleTypePercentage = new VehicleTypePercentageList();
          for (let k = 0; k < this._vendorData.length; k++) {
            let vendorPercentage = new VendorPercentageList();
            vendorPercentage.vendorId = this._vendorData[k].id;
            vendorPercentage.percentage = array_of_sets[j][k];
            vendorPercentageLists.push(vendorPercentage);
          }
          for (let k = 0; k < this._vendorData.length; k++) {
            vehicleTypePercentage.vendorPercentageList.push(vendorPercentageLists[counter++]);
          }
          vehicleTypePercentage.vehicleType = this._vehicleTypeData[j].id;
          vehicleTypePercentageLists.push(vehicleTypePercentage);
        };
        this._percentData.vehicleTypePercentageList = vehicleTypePercentageLists;
        this._percentData.siteid = this._siteId;
        this._percentData.shiftid = this._shiftId;
        this._percentData.tripDate = this._tripDate + " 00:00:00";
        this.apiService.doPostRequestWithResponse(ApiConstants.allocatevendorbyvehicletyperatio, this._percentData, (success) => {
          this.ngxLoader.stop();
          if (success.status == 200) {
            this.ngOnInit();
            this.notifyService.showSuccess("Vendor allocated successfully", null);
            this.location.back();
          }
        }, (error) => {
          this.ngxLoader.stop();
        });
      } else {
        this.ngxLoader.stop();
        this.notifyService.showInfo("Enter valid percentage", null);
      }
    } else {
      this.ngxLoader.stop();
      this.notifyService.showInfo("Trip already allocated", null);
      this.location.back();
    }
  }

  addPercentage(a, b) {
    return a + b;
  }

}
