<div class="container-fluid">
    <div class="row">
        <div class="col-xl-12">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-body">
                        <ul ngbNav #nav="ngbNav" [activeId]="activeTabId" class="nav-tabs nav-tabs-custom g-nav-tabs-custom">
                            <li [ngbNavItem]="1">
                                <a ngbNavLink (click)="onTabChange('routing-setup')">Routing Setup</a>
                                <ng-template ngbNavContent>
                                    <router-outlet></router-outlet>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="2">
                                <a ngbNavLink (click)="onTabChange('fixed-routing-setup')">Fixed Routing Setup</a>
                                <ng-template ngbNavContent>
                                    <router-outlet></router-outlet>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="3">
                                <a ngbNavLink (click)="onTabChange('route-map-setup')">Route Map Setup</a>
                                <ng-template ngbNavContent>
                                    <router-outlet></router-outlet>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="4">
                                <a ngbNavLink (click)="onTabChange('special-category-setup')">Special Category Setup</a>
                                <ng-template ngbNavContent>
                                    <router-outlet></router-outlet>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="5">
                                <a ngbNavLink (click)="onTabChange('navigation-setup')">Navigation Setup</a>
                                <ng-template ngbNavContent>
                                    <router-outlet></router-outlet>
                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="nav" class="p-3"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>