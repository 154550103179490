<div class="mat-modal-dialog cancel-modal p-0">
    <h2 mat-dialog-title>
        <mat-icon style="color:red; float: right; cursor: pointer; position: relative; top: 20px;" (click)="onClickCancel()">close</mat-icon>
    </h2>
    <mat-dialog-content>
        <p class="logout" style="text-align: center; color:#000000DE;font-family: poppins,sans-serif;    font-weight: 500!important;"> Do you want to logout ?</p>
    </mat-dialog-content>
    <div mat-dialog-actions align="end">
        <button type="button" style="border: none;" class="btn footerBtn funcBtn" (click)="onConfirmation()">Yes</button>
        <button type="button" style="border: none;" class="btn footerBtn cancel" (click)="onClickCancel()">No</button>
    </div>
</div>