import { Component, OnInit, ViewChild, ChangeDetectorRef, HostListener, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { AppConstants } from 'src/app/core/constants/appconstants';
import { ApiService } from 'src/app/core/services/api.service';
import { ServiceExportExcel } from 'src/app/core/services/export.service';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { TripPushBackComponent } from '../../reports/trip-push-back/trip-push-back.component';
import { MatDialog } from '@angular/material/dialog';
import { AuditLogComponent } from 'src/app/shared/audit-log/audit-log/audit-log.component';
import { TripMapDialogComponent } from './trip-map-dialog/trip-map-dialog.component';
import { PushBackToAdminComponent } from './push-back-to-admin/push-back-to-admin.component';
import { TripMapComponent } from '../trips/trip-view/trip-map/trip-map.component';
@Component({
  selector: 'app-track-trips',
  templateUrl: './track-trips.component.html',
  styleUrls: ['./track-trips.component.scss']
})
export class TrackTripsComponent {
  public _mFormGroup: FormGroup;
  public _mFormGroupForTrackTrips: FormGroup;
  public mSite = new FormControl('');
  public mDate = new FormControl('');
  public mLogType = new FormControl('');
  public mShiftTime = new FormControl('');
  public mVehicle = new FormControl('', Validators.required);
  public mDriver = new FormControl('', Validators.required);
  public mEscort = new FormControl('');
  public mTravelTime = new FormControl('');
  _mIsLoading: boolean = false;
  _mShiftTimes: any[];
  _mData: any[] = [];
  _mSitesData: any[] = [];
  selectedSite: any = '';
  _mTempShiftTimes: any;
  tripSummaryFlag: boolean = false;
  searchTrip: any;
  firstStep: boolean = false;
  vehicleSearch: any[] = [];
  driverSearch: any[] = [];
  currentDate = new Date();
  selectedTripsForAllocation: any[] = [];
  registrationNo: string;
  escortId: any;
  driverSearchs: any[] = [];
  tempTripId: any;
  updateTravelTimeFlag: boolean = true;
  mTitle: any = 'Address';
  address: any = true;
  areaLandmark: any = false;
  addressOrAL: boolean = false;
  showOrHideAL: boolean = false;
  showHideAddress: boolean = false;

  _mPickDropValue = [
    {
      "label": "IN",
      "key": "IN"
    },
    {
      "label": "OUT",
      "key": "OUT"
    }
  ];
  tripDetailsList: boolean;
  allocatedTripFlag: boolean;
  unAllocatedTripFlag: boolean;
  maskingEnabled: boolean = false;
  showHideLandmark: boolean = false;
  showContact: boolean = false;
  tripDetailsAddData: any[];
  driverDetailsAddData: any[];
  escortListAddData: any[];
  vendorMasterId: any;
  assignTripReqData: { tripDate: any; siteId: string; shiftTimeId: any; vendorMasterId: any; };
  reg: number;
  assignVehicle: boolean;
  tripDetailsData: any;
  vehicleListData: any;
  escortListData: any;
  tripDateValidate: boolean;
  userTripsTemp: any;
  _mIsCheckedList: any[];
  selectedTrips: any[];
  tempSiteId: string;
  currentUserId: any;
  mapType: any;
  vendorId: any;
  assignedTripCountArr: any = [];
  routingConfigTime: any;
  allocatedTripArr: any;
  vehicleSearchs: any[] = [];
  unAllocatedTripArr: any;
  exceldata: any[];
  tempVehicleListData: any = [];
  activeTabId = 1;

  constructor(
    private fb: FormBuilder,
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    private ngxLoader: NgxUiLoaderService,
    private cd: ChangeDetectorRef,
    private readonly serviceExportExcel: ServiceExportExcel,
    public dialog: MatDialog,
    private readonly localStorage: ServiceLocalStorage,
    private elementRef: ElementRef
  ) {

    this._mFormGroup = this.fb.group({
      "site": this.mSite,
      "date": this.mDate,
      "logType": this.mLogType,
      "shiftTime": this.mShiftTime
    });

    this._mFormGroupForTrackTrips = this.fb.group({
      'vehicle': this.mVehicle,
      'driver': this.mDriver,
      'escort': this.mEscort,
      // 'travelTime': this.mTravelTime,
    });

  }

  ngOnInit() {
    this.checkMaskingEnabled();
    this.getActiveSites();
    this.getCurrentUser();
  }

  getCurrentUser() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getCurrentUser,
      success => {
        this.currentUserId = success.body.id;
        this.mapType = success.body.userInfoDetails.site.location.company.mapType;
        this.getVendorByUserId(this.currentUserId);
      }, (error) => {

      })

  }
  getVendorByUserId(currentUserId) {
    this.apiService.doGetRequestWithResponse(ApiConstants.getVendorByUserId + currentUserId,
      success => {
        if (success.body != null) {
          this.vendorMasterId = success.body.vendorMaster.id;
          this.vendorId = success.body.id;
        }
      }, (error) => {

      })

  }

  getActiveSites() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getSitesforVendorTrackTrip,
      success => {
        if (success.status == 200) {
          this._mSitesData = success.body;

        } else {
          this._mSitesData = [];
        }
      }, (error) => {
      })
  }


  onLogTypeChange() {
    this.ngxLoader.start();
    this.tripDetailsList = false;
    this.tripSummaryFlag = false;
    this.allocatedTripFlag = false;
    this.unAllocatedTripFlag = false;
    this.apiService.doGetRequestWithResponse(ApiConstants.getShiftListBasedOnSiteIdAndLogTypes + '?siteid=' + this.mSite.value + '&logType=' + this.mLogType.value,
      (success) => {
        this.ngxLoader.stop();
        if (success != null) {
          this._mShiftTimes = success.body;
        } else {
          this._mShiftTimes = [];
          this.notifyService.showInfo("No Shifts Found!", null);
        }
      },
      error => {
        this.ngxLoader.stop();
      }
    );
  }

  formatDate(d): any {
    let m = d.getMonth() + 1;
    let dt = (d.getDate() < 10) ? '0' + d.getDate() : d.getDate();
    let mm = (m < 10) ? '0' + m : m;
    return dt + '/' + mm + '/' + d.getFullYear()
  }

  onSubmit() {
    if (this.maskingEnabled) {
      this.showHideLandmark = false;
      this.showContact = false;
      this.showOrHideAL = true;
      this.addressOrAL = false;
    }
    this.tripDetailsAddData = [];
    this.vehicleSearch = [];
    this.driverSearch = [];
    this.driverDetailsAddData = [];
    this.escortListAddData = [];
    if (this.mSite.value) {
      if (this.mDate.value) {
        if (this.mLogType.value) {
          if (this.mShiftTime.value) {
            let shiftList: any[] = []
            shiftList.push({ "shiftTimeId": this.mShiftTime.value });
            let siteId = this.mSite.value
            let vendorReqData = {
              "tripDate": this.formatDate(this.mDate.value),
              "siteId": siteId,
              "shiftTimeId": shiftList[0]["shiftTimeId"],
              "vendorMasterId": this.vendorMasterId
            }
            this.assignTripReqData = vendorReqData;
            this.tripSummaryFlag = false;
            this.allocatedTripFlag = false;
            this.unAllocatedTripFlag = false;
            this.getAssignedVendorTripCount(vendorReqData);
            this.ngxLoader.start();
            this.apiService.doPostRequestWithResponse(ApiConstants.getVendorAssignedTrips + '?tripDate=' + this.formatDate(this.mDate.value)
              + '&siteid=' + siteId + '&vendorMasterId=' + this.vendorMasterId, shiftList, (success) => {
                this.ngxLoader.stop();
                if (success.body.tripData.length != 0) {
                  this.reg = 0;
                  this.assignVehicle = false;
                  this.getRoutingConfigBySiteId();
                  this.ngxLoader.stop();
                  this.tripDetailsList = true;
                  this.tripSummaryFlag = true;
                  this.tripDetailsData = success.body.tripData;
                  this.vehicleListData = success.body.vehicleList;
                  this.escortListData = success.body.escortList;
                  if (this.formatDate1(this.mDate.value) < this.formatDate1(this.currentDate)) {
                    this.tripDateValidate = false;
                  } else {
                    this.ngxLoader.stop();
                    this.tripDateValidate = true;
                  }
                  this.ngxLoader.stop();
                  this.tripDetailsData.forEach((tripDetails, i) => {
                    let vehicle: any[] = [];
                    let escort: any[] = [];
                    tripDetails.checked = false;

                    if (tripDetails.vehicle != null) {
                      vehicle = this.vehicleListData.filter(vehicles => vehicles.id == tripDetails.vehicle.id);
                      if (tripDetails.escortStatus == 'Yes') {
                        escort = this.escortListData.filter(escorts => escorts.id == tripDetails.escort.id);
                        this.escortListAddData.push({ id: escort[0].id, regno: escort[0].name, trip: tripDetails.tripCode, deviceTripStatus: tripDetails.tripVendorAssign.DeviceTripStatus });
                      }

                      if (vehicle.length > 0) {
                        this.tripDetailsAddData.push({ regno: vehicle[0].registrationNo, trip: tripDetails.tripCode, deviceStatus: tripDetails.tripVendorAssign.DeviceTripStatus });
                        this.driverDetailsAddData.push({ mob: tripDetails.driver.contactNumber, trip: tripDetails.tripCode, deviceStatus: tripDetails.tripVendorAssign.DeviceTripStatus });
                        this.tripDetailsData[i].driverList = vehicle[0].drivers;
                      } else {
                        this.tripDetailsData[i].driverList = null;
                      }
                      this.vehicleSearch[i] = tripDetails.vehicle.registrationNo;
                      this.driverSearch[i] = tripDetails.driver.name + '(' + tripDetails.driver.contactNumber + ')';
                    }

                  });
                  this.tripDetailsData.forEach((tripDetails) => {
                    tripDetails.tripUserList.forEach((user) => {
                      let userCategory = user.tripUser.userInfoDetails.userCategory[0] || null;
                      user.userColor = userCategory ? userCategory.categories.colorCode : "#006aa8";
                      if (userCategory.categories.colorCode == '#a8009a' && userCategory.categories.CategoryName == "Expectant Mother") {
                        tripDetails.tripsColor = '#a8009a'; // Set trip Id Color. 
                      }
                    });
                  });

                  this.userTripsTemp = this.tripDetailsData;
                  this._mIsCheckedList = [];
                  this.tripDetailsData.forEach((element, i) => {
                    this._mIsCheckedList.push(false);
                  });
                  this.selectedTrips = [];
                  this.tempSiteId = this.mSite.value;

                } else {
                  this.tripSummaryFlag = false;
                  this.tripDetailsList = false;
                  this.tempSiteId = '';
                  this.tripDetailsData = [];
                  this.vehicleListData = [];
                  this.escortListData = [];
                  this.notifyService.showInfo("No trips found.", null);
                  this.ngxLoader.stop();
                }
                this.cd.markForCheck();
              }, (error) => {
                this.ngxLoader.stop();
                this.cd.markForCheck();
              });
          } else {
            this.ngxLoader.stop();
            this.notifyService.showWarning("Please select the Shift time", null);
          }
        } else {
          this.ngxLoader.stop();
          this.notifyService.showWarning("Please select the Log type", null);
        }
      } else {
        this.ngxLoader.stop();
        this.notifyService.showWarning("Please select the Date", null);
      }
    } else {
      this.ngxLoader.stop();
      this.notifyService.showWarning("Please select the site", null);
    }
  }

  getAssignedVendorTripCount(data: any) {
    this.apiService.doGetRequestWithResponse(ApiConstants.vendorAssignedTripCount + '?tripDate=' + data.tripDate
      + '&siteid=' + data.siteId + '&shiftTimeId=' + data.shiftTimeId + '&vendorMasterId=' + data.vendorMasterId,
      success => {
        if (success.status == 200) {
          this.assignedTripCountArr = success.body;
        } else {
          this.assignedTripCountArr = [];
          this.tripSummaryFlag = false;
        }
      }, (error) => {

      })
  }

  getRoutingConfigBySiteId() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getRoutingConfigBySiteId + '?siteId=' + this.mSite.value
      + '&shiftTimeId=' + this.mShiftTime.value,
      success => {
        if (success.status == 200) {
          this.routingConfigTime = success.body.time;
        }
      }, (error) => {

      })
  }

  formatDate1(d: any) {
    let m = d.getMonth() + 1;
    let dt = (d.getDate() < 10) ? '0' + d.getDate() : d.getDate();
    let mm = (m < 10) ? '0' + m : m;
    return d.getFullYear() + '' + mm + '' + dt
  }

  onSelectAll(trips, escort) {
    trips.forEach(trip => {
      this.onCheckAll(trip, escort);
    });
  }

  onCheckAll(trip, escort) {
    if (this.selectedTripsForAllocation.length > 0) {
      const oIndex1 = this.selectedTripsForAllocation.findIndex(ele => ele.id == trip.tripVendorAssign.id);
      if (oIndex1 !== -1) {
        return;
      }
    }

    let flag = true;
    let k = true;
    let regno = '';
    let ename = '';
    let dname = '';
    let mobnum = '';
    let atrip = '';
    let tripData = null;
    if (trip.tripVendorAssign.vehicle != null) {
      if (trip.tripVendorAssign.driver != null) {


        const oIndex2 = this.selectedTripsForAllocation.findIndex(ele => ele.vehicle == trip.tripVendorAssign.vehicle.id);
        if (oIndex2 !== -1) {
          this.notifyService.showWarning("Vehicle / Driver already selected", null);
          return;
        }

        trip.driverList.forEach(driver => {
          if (trip.tripVendorAssign.driver.id == driver.id) {
            regno = driver.registrationNo;
            dname = driver.name;
            mobnum = driver.contactNumber;
          }
        });
        if (trip.escortStatus === 'Yes') {
          k = false;
          if (trip.tripVendorAssign.escort != null) {
            escort.forEach(escorts => {
              if (trip.tripVendorAssign.escort.id == escorts.id) {
                ename = escorts.name;
                atrip = escorts.trip;
                k = true;
              }
            });
          }
        }
        this.tripDetailsAddData.forEach(tadd => {
          if (this.registrationNo == tadd.regno && trip.tripCode != tadd.trip) {
            flag = false;
            regno = tadd.regno;
            atrip = tadd.trip;
          }
        });
        if (flag) {
          this.driverDetailsAddData.forEach(tadd => {
            if (mobnum == tadd.mob && trip.tripCode != tadd.trip) {
              flag = false;
              regno = dname;
              atrip = tadd.trip;
            }
          });
          if (trip.escortStatus === 'Yes') {
            if (trip.tripVendorAssign.escort != null) {
              this.escortListAddData.forEach(tadd => {
                if (tadd.id == trip.tripVendorAssign.escort.id && trip.tripCode != tadd.trip && tadd.deviceTripStatus != 'stopped') {
                  flag = false;
                  regno = tadd.regno;
                  atrip = tadd.trip;
                  this.escortId = 0;
                }
              });
            }
          }
        }
        if (flag && k) {
          if (trip.escortStatus === 'No') {
            trip.tripVendorAssign.vehicle.registrationNo = this.registrationNo;
            tripData = {
              "id": trip.tripVendorAssign.id,
              "vehicle": trip.tripVendorAssign.vehicle.id,
              "driver": trip.tripVendorAssign.driver.id,
              "escort": 0,
            }
          } else if (trip.escortStatus === 'Yes') {
            const oIndex3 = this.selectedTripsForAllocation.findIndex(ele => ele.escort == trip.tripVendorAssign.escort.id);
            if (oIndex3 !== -1) {
              this.notifyService.showWarning("Escort already selected", null);
              return;
            }
            if (k) {
              trip.tripVendorAssign.vehicle.registrationNo = this.registrationNo;
              tripData = {
                "id": trip.tripVendorAssign.id,
                "vehicle": trip.tripVendorAssign.vehicle.id,
                "driver": trip.tripVendorAssign.driver.id,
                "escort": trip.tripVendorAssign.escort.id,
              }
            }
          } else {
            this.notifyService.showWarning("Please select escort for trip  : " + trip.tripCode, null);
          }

        }
        else {
          if (!k) {
            this.notifyService.showWarning("Please select escort for trip  : " + trip.tripCode, null);
            k = true;
          }
          else {
            this.notifyService.showWarning(regno + " is already assigned to " + atrip, null);
          }
        }
      }

      else {
        this.notifyService.showWarning("Please select driver for trip  : " + trip.tripCode, null);
      }
    } else {
      this.notifyService.showWarning("Please select vehicle for trip  : " + trip.tripCode, null);
    }
    if (tripData != null) {
      trip.checked = true;
      this.selectedTripsForAllocation.push(tripData);
    }
  }

  onCheck(event, trip, escort) {
    if (this.selectedTripsForAllocation.length > 0) {
      const oIndex1 = this.selectedTripsForAllocation.findIndex(ele => ele.id == trip.tripVendorAssign.id);
      if (oIndex1 !== -1) {
        this.selectedTripsForAllocation.splice(oIndex1, 1);
        return;
      }
    }

    let flag = true;
    let k = true;
    let regno = '';
    let ename = '';
    let dname = '';
    let mobnum = '';
    let atrip = '';
    let tripData = null;
    if (trip.tripVendorAssign.vehicle != null) {
      if (trip.tripVendorAssign.driver != null) {


        const oIndex2 = this.selectedTripsForAllocation.findIndex(ele => ele.vehicle == trip.tripVendorAssign.vehicle.id);
        if (oIndex2 !== -1) {
          this.notifyService.showWarning("Vehicle / Driver already selected", null);
          event.checked = false;
          return;
        }

        trip.driverList.forEach(driver => {
          if (trip.tripVendorAssign.driver.id == driver.id) {
            regno = driver.registrationNo;
            dname = driver.name;
            mobnum = driver.contactNumber;
          }
        });
        if (trip.escortStatus === 'Yes') {
          k = false;
          if (trip.tripVendorAssign.escort != null) {
            escort.forEach(escorts => {
              if (trip.tripVendorAssign.escort.id == escorts.id) {
                ename = escorts.name;
                atrip = escorts.trip;
                k = true;
              }
            });
          }
        }
        this.tripDetailsAddData.forEach(tadd => {
          if (this.registrationNo == tadd.regno && trip.tripCode != tadd.trip) {
            flag = false;
            regno = tadd.regno;
            atrip = tadd.trip;
          }
        });
        if (flag) {
          this.driverDetailsAddData.forEach(tadd => {
            if (mobnum == tadd.mob && trip.tripCode != tadd.trip) {
              flag = false;
              regno = dname;
              atrip = tadd.trip;
            }
          });
          if (trip.escortStatus === 'Yes') {
            if (trip.tripVendorAssign.escort != null) {
              this.escortListAddData.forEach(tadd => {
                if (tadd.id == trip.tripVendorAssign.escort.id && trip.tripCode != tadd.trip) {
                  flag = false;
                  regno = tadd.regno;
                  atrip = tadd.trip;
                  this.escortId = 0;
                }
              });
            }
          }
        }
        if (flag && k) {
          if (trip.escortStatus === 'No') {
            trip.tripVendorAssign.vehicle.registrationNo = this.registrationNo;
            tripData = {
              "id": trip.tripVendorAssign.id,
              "vehicle": trip.tripVendorAssign.vehicle.id,
              "driver": trip.tripVendorAssign.driver.id,
              "escort": 0,
            }
          } else if (trip.escortStatus === 'Yes') {
            const oIndex3 = this.selectedTripsForAllocation.findIndex(ele => ele.escort == trip.tripVendorAssign.escort.id);
            if (oIndex3 !== -1) {
              this.notifyService.showWarning("Escort already selected", null);
              event.checked = false;
              return;
            }
            if (k) {
              trip.tripVendorAssign.vehicle.registrationNo = this.registrationNo;
              tripData = {
                "id": trip.tripVendorAssign.id,
                "vehicle": trip.tripVendorAssign.vehicle.id,
                "driver": trip.tripVendorAssign.driver.id,
                "escort": trip.tripVendorAssign.escort.id,
              }
            }
          } else {
            this.notifyService.showWarning("Please select escort", null);
          }

        }
        else {
          if (!k) {
            this.notifyService.showWarning("Please select escort", null);
            k = true;
          }
          else {
            this.notifyService.showWarning(regno + " is already assigned to " + atrip, null);
          }
        }
      }

      else {
        this.notifyService.showWarning("Please select driver", null);
      }
    } else {
      this.notifyService.showWarning("Please select vehicle", null);
    }

    if (tripData != null) {
      trip.checked = true;
      this.selectedTripsForAllocation.push(tripData);
    } else {
      event.checked = false;
    }
  }

  searchFilter(event) {
    this.tempVehicleListData = this.vehicleListData.filter(data => data.registrationNo.toLowerCase().includes(event.target.value.toLowerCase()));
  }

  clearDriver(item, index, tripIndex) {
    if (!item) {
      this.driverSearch[index] = '';
      this.tripDetailsData[tripIndex].driverList = null;
    }
    if (this.unAllocatedTripFlag) {
      this.driverSearchs[index] = '';
    }
  }

  omit_special_char_And_String(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 32 || (k >= 48 && k <= 57));
  }

  omit_special_char_And_String1(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return (k == 58 || (k >= 48 && k <= 57));
  }

  vehicleSearchSubmit(trips, vehicle1, rowIndex) {
    let vehicle = this.vehicleListData.find(data => data.registrationNo === vehicle1.option.value);
    this.vehicleSearch
    let firstTimeVehicle = true;
    if (this.reg >= 0) {
      if (this.tripDetailsData[rowIndex].tripVendorAssign.vehicle == null || this.tripDetailsData[rowIndex].tripVendorAssign.vehicle != null && this.tripDetailsData[rowIndex].tripVendorAssign.vehicle.id != vehicle.id) {
        if (trips.escortStatus == "No" && trips.tripUserList.length < vehicle.vehicleType.seatCapacity + 1 || trips.escortStatus == "Yes" && trips.tripUserList.length < vehicle.vehicleType.seatCapacity) {
          this.tripDetailsData[rowIndex].driver = null;
          this.tripDetailsData[rowIndex].tripVendorAssign.driver = null;
          this.tripDetailsData[rowIndex].driverList = [];
          this.reg++;
          firstTimeVehicle = false;
          if (vehicle.drivers.length > 0) {
            this.assignVehicle = true;
            this.tripDetailsData[rowIndex].tripVendorAssign.vehicle = { id: vehicle.id };
            this.registrationNo = vehicle.registrationNo;
            this.tripDetailsData[rowIndex].driverList = vehicle.drivers;
            if (vehicle.drivers.length == 1) {
              this.driverSearch[rowIndex] = vehicle.drivers[0].name;
              this.tripDetailsData[rowIndex].tripVendorAssign.driver = { id: vehicle.drivers[0].id };
            }
            else { this.driverSearch[rowIndex] = ''; }
          } else {
            this.driverSearch[rowIndex] = '';
            this.notifyService.showInfo("No driver found", null);
          }
        }
        else {
          if (this.reg == 0 || this.reg == 1) {
            this.reg++;
            firstTimeVehicle = false;
            this.tripDetailsData[rowIndex].driverList = [];
            this.tripDetailsData[rowIndex].tripVendorAssign.driver = null;
            this.notifyService.showWarning("Vehicle type is " + vehicle.vehicleType.vehicleType + " . please select vehicle type of " + trips.vehicleType, null);
          }
        }
      }
    }
    if (firstTimeVehicle) {
      this.reg = 1;
    }
  }

  getIndex(item) {
    return this.tripDetailsData.indexOf(item);
  }

  driverSearchSubmit(trips, driver, rowIndex) {
    this.tripDetailsData[rowIndex].tripVendorAssign.driver = { id: driver.id };
    this.reg = 1;
  }

  setDriverAndSendSMS(trip, escort, rowIndex) {
    let trips = trip;
    let flag = true;
    let k = true;
    let regno = '';
    let ename = '';
    let dname = '';
    let mobnum = '';
    let atrip = '';
    if (trip.tripVendorAssign.vehicle != null) {
      if (trip.tripVendorAssign.driver != null) {
        trip.driverList.forEach(vehicles => {
          if (trip.tripVendorAssign.driver.id == vehicles.id) {
            regno = vehicles.registrationNo;
            dname = vehicles.name;
            mobnum = vehicles.contactNumber;
          }
        });
        if (trip.escortStatus === 'Yes') {
          k = false;
          if (trip.tripVendorAssign.escort != null) {
            escort.forEach(escorts => {
              if (trip.tripVendorAssign.escort.id == escorts.id) {
                ename = escorts.name;
                atrip = escorts.trip;
                k = true;
              }
            });
          }
        }
        this.tripDetailsAddData.shift();
        this.tripDetailsAddData.forEach(tadd => {
          if (this.registrationNo == tadd.regno && trip.tripCode != tadd.trip && tadd.deviceStatus != 'stopped') {
            flag = false;
            regno = tadd.regno;
            atrip = tadd.trip;
          }
        });

        this.driverDetailsAddData.shift();
        if (flag) {
          this.driverDetailsAddData.forEach(tadd => {
            if (mobnum == tadd.mob && trip.tripCode != tadd.trip && tadd.deviceStatus != 'stopped') {

              flag = false;
              regno = dname;
              atrip = tadd.trip;
            }
          });

          if (trip.escortStatus === 'Yes') {
            if (trip.tripVendorAssign.escort != null) {
              this.escortListAddData.forEach(tadd => {
                if (tadd.id == trip.tripVendorAssign.escort.id && trip.tripCode != tadd.trip && tadd.deviceTripStatus != 'stopped') {
                  flag = false;
                  regno = tadd.regno;
                  atrip = tadd.trip;
                  this.escortId = 0;
                }
              });
            }
          }
        }
        if (flag && k) {
          if (trip.escortStatus === 'No') {
            trip.tripVendorAssign.vehicle.registrationNo = this.registrationNo;
            let data = {
              "id": trip.tripVendorAssign.id,
              "vehicle": trip.tripVendorAssign.vehicle.id,
              "driver": trip.tripVendorAssign.driver.id,
              "escort": 0,
            }
            this.ngxLoader.start();

            this.apiService.doPostRequestWithResponse(ApiConstants.updateVendorTripAssignV3, data, (success) => {
              if (success.status == 200) {
                let trips = trip;
                this.getAssignedVendorTripCount(this.assignTripReqData);
                this.loadData();
                this.ngxLoader.stop();
                this.notifyService.showSuccess("Successfully updated vehicle trip assign", null);
              } else {
                this.notifyService.showWarning("Failed to update vehicle trip assign", null);
              }
            }, (error) => {
              this.ngxLoader.stop();
              console.log(error)
            });
          } else if (trip.escortStatus === 'Yes') {
            if (k) {
              trip.tripVendorAssign.vehicle.registrationNo = this.registrationNo;
              let data = {
                "id": trip.tripVendorAssign.id,
                "vehicle": trip.tripVendorAssign.vehicle.id,
                "driver": trip.tripVendorAssign.driver.id,
                "escort": trip.tripVendorAssign.escort.id,
              }
              this.ngxLoader.start();

              this.apiService.doPostRequestWithResponse(ApiConstants.updateVendorTripAssignV3, data, (success) => {
                if (success.status == 200) {
                  let trips = trip;
                  this.getAssignedVendorTripCount(this.assignTripReqData);
                  this.loadData();
                  this.ngxLoader.stop();
                  this.notifyService.showSuccess("Successfully updated vehicle trip assign", null);
                } else {
                  this.notifyService.showWarning("Failed to update vehicle trip assign", null);
                }
              }, (error) => {
                this.ngxLoader.stop();
                console.log(error)
              });
            }
          } else {
            this.notifyService.showWarning("Please select escort", null);
          }

        }
        else {
          if (!k) {
            this.notifyService.showWarning("Please select escort", null);
            k = true;
          }
          else {
            this.notifyService.showWarning(regno + " is already assigned to " + atrip, null);
          }
        }
      }

      else {
        this.notifyService.showWarning("Please select driver", null);
      }
    } else {
      this.notifyService.showWarning("Please select vehicle", null);
    }
  }


  loadData() {
    let shiftList: any[] = [];
    shiftList.push({ "shiftTimeId": this.assignTripReqData.shiftTimeId });
    this.apiService.doPostRequestWithResponse(ApiConstants.getVendorAssignedTrips + '?tripDate=' + this.assignTripReqData.tripDate
      + '&siteid=' + this.assignTripReqData.siteId + '&vendorMasterId=' + this.assignTripReqData.vendorMasterId, shiftList, (success) => {
        if (success.body.tripData.length != 0) {
          this.reg = 0;
          this.assignVehicle = false;
          this.getRoutingConfigBySiteId();
          this.ngxLoader.stop();
          if (this.allocatedTripFlag) {
            this.tripDetailsList = false;
          } else {
            this.tripDetailsList = true;
          }
          this.tripSummaryFlag = true;
          this.tripDetailsData = success.body.tripData;
          this.tripDetailsData.forEach((tripDetails) => {
            tripDetails.tripUserList.forEach((user) => {
              let userCategory = user.tripUser.userInfoDetails.userCategory[0] || null;
              user.userColor = userCategory ? userCategory.categories.colorCode : "#006aa8";
              if (user.userColor && userCategory.categories.CategoryName == "Expectant Mother") {
                tripDetails.tripsColor = user.userColor; // Set trip Id Color. 
              }
            });
          });
          this.vehicleListData = success.body.vehicleList;
          this.escortListData = success.body.escortList;
          if (this.formatDate1(this.mDate.value) < this.formatDate1(this.currentDate)) {
            this.tripDateValidate = false;
          } else {
            this.tripDateValidate = true;
          }
          this.tripDetailsData.forEach((tripDetails, i) => {
            let vehicle: any[] = [];
            let escort: any[] = [];
            tripDetails.checked = false;
            if (tripDetails.vehicle != null) {
              vehicle = this.vehicleListData.filter(vehicles => vehicles.id == tripDetails.vehicle.id);
              if (tripDetails.escortStatus == 'Yes') {
                escort = this.escortListData.filter(escorts => escorts.id == tripDetails.escort.id);
                this.escortListAddData.push({ id: escort[0].id, regno: escort[0].name, trip: tripDetails.tripCode, deviceTripStatus: tripDetails.tripVendorAssign.DeviceTripStatus });
              }
              if (vehicle.length > 0) {
                this.tripDetailsAddData.push({ regno: vehicle[0].registrationNo, trip: tripDetails.tripCode, deviceStatus: tripDetails.tripVendorAssign.DeviceTripStatus });
                this.driverDetailsAddData.push({ mob: tripDetails.driver.contactNumber, trip: tripDetails.tripCode, deviceStatus: tripDetails.tripVendorAssign.DeviceTripStatus });
                this.tripDetailsData[i].driverList = vehicle[0].drivers;
              } else {
                this.tripDetailsData[i].driverList = null;
              }
              this.vehicleSearch[i] = tripDetails.vehicle.registrationNo;
              this.driverSearch[i] = tripDetails.driver.name + '(' + tripDetails.driver.contactNumber + ')';
            }

          });
          this.userTripsTemp = this.tripDetailsData;
          this._mIsCheckedList = [];
          this.tripDetailsData.forEach((element, i) => {
            this._mIsCheckedList.push(false);
          });
          this.selectedTrips = [];
          this.tempSiteId = this.mSite.value;
          this.unAllocatedTripFlag = false;
        } else {
          this.tripSummaryFlag = false;
          this.tripDetailsList = false;
          this.tempSiteId = '';
          this.tripDetailsData = [];
          this.vehicleListData = [];
          this.escortListData = [];
          this.notifyService.showInfo("No trips found.", null);
        }
        this.cd.markForCheck();
      }, (error) => {
        this.cd.markForCheck();
        console.log(error)
      });
  }

  updateEscortValue(event, rowIndex) {
    var changedValue = event.value
    this.tripDetailsData[rowIndex].tripVendorAssign.escort = { id: changedValue }
    this.escortId = changedValue;
  }


  formatDateTime(d): any {
    let m = d.getMonth() + 1;
    let dt = (d.getDate() < 10) ? '0' + d.getDate() : d.getDate();
    let mm = (m < 10) ? '0' + m : m;

    let hour = (d.getHours() < 10) ? '0' + d.getHours() : d.getHours();
    let minutes = (d.getMinutes() < 10) ? '0' + d.getMinutes() : d.getMinutes();
    let seconds = (d.getSeconds() < 10) ? '0' + d.getSeconds() : d.getSeconds();
    return dt + '/' + mm + '/' + d.getFullYear() + " " + hour + ":" + minutes + ":" + seconds;
  }

  sendBackToAdmin(trips) {
    if (trips.tripVendorAssign.DeviceTripStatus != 'not started') {
      this.notifyService.showWarning("Trip is already " + trips.tripVendorAssign.DeviceTripStatus, null);
      return;
    }
    let data = {
      message: "Are you sure want to send back to admin ?",
      title: "Send back to admin"
    };
    // let alert: TdwModelAlert = new TdwModelAlert("Send back to admin", msg, null);

    let dialogRef = this.dialog.open(PushBackToAdminComponent, {
      data: data,
      disableClose: true,
      width: '450px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let tripPushBackData = {
          "status": "VENDOR_PUSHBACKED",
          "reason": result.reasonType,
          "updatedOn": this.formatDateTime(new Date()),
          "tripVendorAssign": {
            "id": trips.tripVendorAssign.id
          },
          "vendor": {
            "id": this.vendorId
          }
        }
        trips.tripPushBack = tripPushBackData;
        trips.tripVendorAssign.vehicle = null;
        trips.tripVendorAssign.driver = null;
        trips.tripVendorAssign.escort = null;
        trips.tripVendorAssign.vendorMaster = null;

        this.apiService.doPostRequestWithResponse(ApiConstants.sendBackToAdmin, trips, (success) => {
          if (success.body != null) {
            this.escortListAddData = [];
            this.tripDetailsAddData = [];
            this.driverDetailsAddData = [];
            this.onSubmit();
            this.notifyService.showSuccess("Successfully sent back to admin...", null);
          }
        }, (error) => {

        });
      }
    });
  }

  setTime(event, trips) {
    this.tempTripId = trips.tripId;
    this.tripDetailsData.forEach(element => {
      if (element.tripId == trips.tripId) {
        element.tripTravelTime = event.target.value;
      }
    });
  }

  updateTravelTime(trip, rowIndex) {
    let travelTime: number = parseInt(trip.tripTravelTime);
    if (travelTime > 0) {
      if (trip.tripTravelTime < this.routingConfigTime) {

        if (trip.tripId == this.tempTripId && travelTime > 0) {
          let totaEmpTimeDiff;
          if (trip.tripTravelTime > trip.tripTravelTime) {
            totaEmpTimeDiff = trip.tripTravelTime - travelTime
          } else {
            totaEmpTimeDiff = travelTime - trip.tripTravelTime;
          }

          let employeeTravelTimeList = [];
          employeeTravelTimeList.push({ "id": trip.tripUserList[0].id, "pickupDropTime": this.convertMinsToHrsMins((parseInt(trip.shiftTime.slice(0, 2)) * 60 + parseInt(trip.shiftTime.slice(3, 5)) - travelTime)) })
          trip.tripUserList.forEach((element, i) => {
            if (element.pickupDropTime != null) {
              if (i != 0) {
                let empTravelTime;
                if (trip.tripTravelTime > trip.tripTravelTime) {
                  let averageEmpTravelTime;
                  if (totaEmpTimeDiff > 0) {
                    averageEmpTravelTime = ((trip.tripTravelTime - travelTime) / trip.tripUserList.length) + 0.5;
                  } else {
                    averageEmpTravelTime = 0;
                  }
                  empTravelTime = this.convertMinsToHrsMins((parseInt(element.pickupDropTime.slice(0, 2)) * 60 + parseInt(element.pickupDropTime.slice(3, 5))) + Math.round(averageEmpTravelTime));
                  totaEmpTimeDiff = totaEmpTimeDiff - Math.round(averageEmpTravelTime);
                } else {
                  let averageEmpTravelTime;
                  if (totaEmpTimeDiff > 0) {
                    averageEmpTravelTime = ((travelTime - trip.tripTravelTime) / trip.tripUserList.length) + 0.5;
                  } else {
                    averageEmpTravelTime = 0;
                  }
                  empTravelTime = this.convertMinsToHrsMins((parseInt(element.pickupDropTime.slice(0, 2)) * 60 + parseInt(element.pickupDropTime.slice(3, 5))) - Math.round(averageEmpTravelTime));
                  totaEmpTimeDiff = totaEmpTimeDiff - Math.round(averageEmpTravelTime);
                }
                employeeTravelTimeList.push({ "id": element.id, "pickupDropTime": empTravelTime });
              }
            }
          });


          trip.tripUserList.forEach((tripUser, i) => {
            let timeTaken: number = 0;
            let st: any = this.tripDetailsData[rowIndex].shiftTime;
            let travel_time: number = this.convertMinsToHrsMins((parseInt(trip.shiftTime.slice(0, 2)) * 60 + parseInt(trip.shiftTime.slice(3, 5)) - travelTime))
            let td: any = this.tripDetailsData[rowIndex].tripDistance;
            let tt: any = this.tripDetailsData[rowIndex].tripTravelTime;
            let avg_speed: number = (td / (travelTime)) * 60;
            (this.tripDetailsData[rowIndex]);
            let dist: any = (this.tripDetailsData[rowIndex].tripUserList[i].empTripDistance)


            if (i == 0) {
              this.tripDetailsData[rowIndex].tripUserList[i].pickupDropTime = this.convertMinsToHrsMins((parseInt(this.tripDetailsData[rowIndex].shiftTime.slice(0, 2)) * 60 + parseInt(this.tripDetailsData[rowIndex].shiftTime.slice(3, 5)) - travelTime))
            }
            else {
              timeTaken = ((this.tripDetailsData[rowIndex].tripUserList[i].empTripDistance) / (avg_speed)) * 60;
              this.tripDetailsData[rowIndex].tripUserList[i].pickupDropTime = this.convertMinsToHrsMins((parseInt(this.tripDetailsData[rowIndex].tripUserList[i - 1].pickupDropTime.slice(0, 2)) * 60 + parseInt(this.tripDetailsData[rowIndex].tripUserList[i - 1].pickupDropTime.slice(3, 5)) + Math.round(timeTaken)))
            }
          }
          );
          this.tripDetailsData[rowIndex].tripTravelTime = (trip.tripTravelTime).toString();
          let updatedList = [];
          trip.tripUserList.forEach((tripUser, i) => {
            updatedList.push({ "id": this.tripDetailsData[rowIndex].tripUserList[i].id, "pickupDropTime": (this.tripDetailsData[rowIndex].tripUserList[i].pickupDropTime) });
          });
          this.ngxLoader.start();

          this.apiService.doPutRequestWithResponse(ApiConstants.updateTripTravelTime + '?tripid=' + trip.tripId
            + '&triptraveltime=' + trip.tripTravelTime + '&siteId=' + this.mSite.value, updatedList, (success) => {
              this.ngxLoader.stop();
              if (success.status == 200) {
                this.notifyService.showSuccess("Successfully updated Travel time.", null);
              }
            }, (error) => {
              this.ngxLoader.stop();
            }
          );
        }
        else if (trip.travelTime == null) {
          this.notifyService.showWarning("Please enter travel time....", null);
        }
      } else {
        this.notifyService.showWarning("Travel time Should be less than routing config time(" + this.routingConfigTime + ")", null);
      }
    } else {
      this.notifyService.showWarning("Invalid travel time....", null);
    }
  }

  convertMinsToHrsMins(minutes): any {
    let h = Math.floor(minutes / 60);
    let m = minutes % 60;
    let hh = h < 10 ? '0' + h : h;
    let mm = m < 10 ? '0' + m : m;
    return hh + ':' + mm + ':00';
  }

  discard_special_char_And_String(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k >= 48 && k <= 57));
  }

  updateEmployeeTravelTime(trip, rowIndex) {
    let orgShiftTime = parseInt(trip.shiftTime.slice(0, 2)) * 60 + parseInt(trip.shiftTime.slice(3, 5));
    if (trip.logType == "IN") {
      if (orgShiftTime < 180) {
        orgShiftTime = orgShiftTime + 1440;
        let tripTravelTime = orgShiftTime - parseInt(trip.tripUserList[0].pickupDropTime.slice(0, 2)) * 60 + parseInt(trip.tripUserList[0].pickupDropTime.slice(3, 5));
        let shiftTime = parseInt(trip.shiftTime.slice(0, 2)) * 60 + parseInt(trip.shiftTime.slice(3, 5));
        for (let i = 0; i < trip.tripUserList.length; i++) {
          if (trip.tripUserList[i].pickupDropTime != null) {
            let empTravelTime = parseInt(trip.tripUserList[i].pickupDropTime.slice(0, 2)) * 60 + parseInt(trip.tripUserList[i].pickupDropTime.slice(3, 5));
            if (empTravelTime < 180) {
              empTravelTime = empTravelTime + 1440;
            }
            if (orgShiftTime > empTravelTime) {
              if (i == 0 ? true : (shiftTime < empTravelTime)) {
                if (i == trip.tripUserList.length - 1) {
                  if (this.updateTravelTimeFlag) {
                    let data = {
                      "tripId": trip.tripId,
                      "tripTravelTime": tripTravelTime,
                      "tripUserList": trip.tripUserList
                    }
                    this.ngxLoader.start();
                    this.apiService.doPutRequestWithResponse(ApiConstants.updateTripTotalTime + '?siteid=' + this.tempSiteId, data, (success) => {
                      this.ngxLoader.stop();
                      if (success.status == 200) {
                        this.notifyService.showSuccess("Updated successfully", null);
                        this.tripDetailsData[rowIndex].tripTravelTime = tripTravelTime.toString();
                        for (let i = 0; i < this.tripDetailsData[rowIndex].tripUserList.length; i++) {
                          this.tripDetailsData[rowIndex].tripUserList[i].pickupDropTime = data.tripUserList[i].pickupDropTime;
                        }
                      }
                    }, (error) => {
                      this.ngxLoader.stop();
                    }
                    );
                  } else {
                    this.notifyService.showWarning("Invalid Time", null);
                  }
                }
                shiftTime = parseInt(trip.tripUserList[i].pickupDropTime.slice(0, 2)) * 60 + parseInt(trip.tripUserList[i].pickupDropTime.slice(3, 5));
              } else {
                this.notifyService.showWarning("Time Should be greater than " + trip.tripUserList[i - 1].pickupDropTime + " for employeeId - " + trip.tripUserList[i].tripUser.userInfoDetails.employeeId + "", null);
                break;
              }
            } else {
              this.notifyService.showWarning("Time Should be less than ShiftTime for employeeId - " + trip.tripUserList[i].tripUser.userInfoDetails.employeeId + "", null);
              break;
            }
          } else {
            this.notifyService.showWarning("Please add employee travel time for employeeId - " + trip.tripUserList[i].tripUser.userInfoDetails.employeeId + "", null);
            break;
          }
        }
      } else {
        let shiftTime = parseInt(trip.shiftTime.slice(0, 2)) * 60 + parseInt(trip.shiftTime.slice(3, 5));
        for (let i = 0; i < trip.tripUserList.length; i++) {
          if (trip.tripUserList[i].pickupDropTime != null) {
            let empTravelTime = parseInt(trip.tripUserList[i].pickupDropTime.slice(0, 2)) * 60 + parseInt(trip.tripUserList[i].pickupDropTime.slice(3, 5));
            if (orgShiftTime > empTravelTime) {
              if (i == 0 ? true : (shiftTime < empTravelTime)) {
                if (i == trip.tripUserList.length - 1) {
                  if (this.updateTravelTimeFlag) {
                    let travelTime = parseInt(trip.tripUserList[0].pickupDropTime.slice(0, 2)) * 60 + parseInt(trip.tripUserList[0].pickupDropTime.slice(3, 5));
                    let data = {
                      "tripId": trip.tripId,
                      "tripTravelTime": orgShiftTime - travelTime,
                      "tripUserList": trip.tripUserList
                    }


                    this.ngxLoader.start();
                    this.apiService.doPutRequestWithResponse(ApiConstants.updateTripTotalTime + '?siteid=' + this.tempSiteId, data, (success) => {
                      this.ngxLoader.stop();
                      if (success.status == 200) {
                        this.notifyService.showSuccess("Updated successfully", null);
                        this.tripDetailsData[rowIndex].tripTravelTime = (orgShiftTime - travelTime).toString();
                        for (let i = 0; i < this.tripDetailsData[rowIndex].tripUserList.length; i++) {
                          this.tripDetailsData[rowIndex].tripUserList[i].pickupDropTime = data.tripUserList[i].pickupDropTime;
                        }
                      }
                    }, (error) => {
                      this.ngxLoader.stop();
                    }
                    );
                  } else {
                    this.notifyService.showWarning("Invalid Time", null);
                  }
                }
                shiftTime = parseInt(trip.tripUserList[i].pickupDropTime.slice(0, 2)) * 60 + parseInt(trip.tripUserList[i].pickupDropTime.slice(3, 5));
              } else {
                this.notifyService.showWarning("Time Should be greater than " + trip.tripUserList[i - 1].pickupDropTime + " for employeeId - " + trip.tripUserList[i].tripUser.userInfoDetails.employeeId + "", null);
                break;
              }
            } else {
              this.notifyService.showWarning("Time Should be less than ShiftTime for employeeId - " + trip.tripUserList[i].tripUser.userInfoDetails.employeeId + "", null);
              break;
            }
          } else {
            this.notifyService.showWarning("Please add employee travel time for employeeId - " + trip.tripUserList[i].tripUser.userInfoDetails.employeeId + "", null);
            break;
          }
        }
      }
    }
  }

  onTimeValidate(event, user, trips, rowIndex) {
    this.updateTravelTimeFlag = false;
    let shiftTime = parseInt(trips.shiftTime.slice(0, 2)) * 60 + parseInt(trips.shiftTime.slice(3, 5));
    let userhh = parseInt(event.target.value.slice(0, 2)) * 60 + parseInt(event.target.value.slice(3, 5));
    if (event.target.value.match(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)) {
      if (trips.logType == "IN") {
        if (shiftTime < 180) {  // 00:00 240
          if (userhh < 180) {
            userhh = userhh + 1440;
          }
          shiftTime = shiftTime + 1440;
          for (let i = 0; i < trips.tripUserList.length; i++) {
            if (shiftTime > userhh) {
              if (user.id === trips.tripUserList[i].id) {
                this.tripDetailsData[rowIndex].tripUserList[i].pickupDropTime = event.target.value + ':00';
                this.updateTravelTimeFlag = true;
                break;
              }
              let usershh = parseInt(trips.tripUserList[i].pickupDropTime.slice(0, 2)) * 60 + parseInt(trips.tripUserList[i].pickupDropTime.slice(3, 5));
              if (usershh < 180) {
                usershh = usershh + 1440;
              }
              if (usershh < userhh) {
              } else {
                this.notifyService.showWarning("Time Should greater than - " + trips.tripUserList[i].tripUser.userInfoDetails.employeeId + " employee time", null);
                break;
              }
            } else {
              this.notifyService.showWarning("Time Should be less than ShiftTime ", null);
              break;
            }
          }
        } else {
          for (let i = 0; i < trips.tripUserList.length; i++) {
            if (shiftTime > userhh) {
              if (user.id === trips.tripUserList[i].id) {
                this.tripDetailsData[rowIndex].tripUserList[i].pickupDropTime = event.target.value + ':00';
                this.updateTravelTimeFlag = true;
                break;
              }
              let usershh = parseInt(trips.tripUserList[i].pickupDropTime.slice(0, 2)) * 60 + parseInt(trips.tripUserList[i].pickupDropTime.slice(3, 5));
              if (shiftTime > 1139) {
                if (usershh < userhh) {
                } else {
                  this.notifyService.showWarning("Time Should greater than - " + trips.tripUserList[i].tripUser.userInfoDetails.employeeId + " employee time", null);
                  break;
                }
              }
            } else {
              this.notifyService.showWarning("Time Should be less than ShiftTime ", null);
              break;
            }
          }
        }
      }
    } else {
      this.notifyService.showWarning("Invalid time format for - " + user.tripUser.userInfoDetails.employeeId + "", null);
    }
  }

  resendOtp(user, trip) {
    this.ngxLoader.start();
    this.apiService.doGetRequestWithResponse(ApiConstants.resendOtp + '?tripId=' + trip.tripId + '&userId=' + user.tripUser.id
      + '&logType=' + trip.logType,
      success => {
        this.notifyService.showSuccess(success.body.message, null);
        this.ngxLoader.stop();
      }, (error) => {
        this.notifyService.showWarning("Failed", null);
        this.ngxLoader.stop();
      })
  }

  showAllocatedTrips() {
    if (this.tripDetailsData.length > 0) {
      this.allocatedTripFlag = true;
      this.unAllocatedTripFlag = false;
      this.tripDetailsList = false
      this.tripDateValidate = true;
      this.assignVehicle = false;
      let inputArr = this.tripDetailsData;
      let requiredData = inputArr.filter(ele => ele.hasOwnProperty("driver"));
      this.allocatedTripArr = requiredData;
      this.vehicleSearch = [];
      this.driverSearch = [];
      this.allocatedTripArr.forEach((element, i) => {
        this.vehicleSearch[i] = element.vehicle.registrationNo;
        this.driverSearch[i] = element.driver.name + '(' + element.driver.contactNumber + ')';
      })
    }
  }

  showUnAllocatedTrips() {
    if (this.tripDetailsData.length > 0) {
      this.allocatedTripFlag = false;
      this.tripDetailsList = false;
      this.unAllocatedTripFlag = true;
      let inputArr1 = this.tripDetailsData;
      let requiredData = inputArr1.filter(ele => !ele.hasOwnProperty("driver"));
      this.vehicleSearchs[0] = '';
      this.driverSearchs[0] = '';
      this.vehicleSearch = [];
      this.driverSearch = [];
      this.unAllocatedTripArr = requiredData;
      // this.unAllocatedTripArr.forEach((element,i)=>
      // {
      //   this.vehicleSearch[i] = element.vehicle.registrationNo;
      //   this.driverSearch[i] = element.driver.name + '(' + element.driver.contactNumber + ')';
      // })
    }

  }

  onSubmitAloc() {
    this.ngxLoader.start();
    this.apiService.doPostRequestWithResponse(ApiConstants.updateTripVendorAllocations, this.selectedTripsForAllocation, (success) => {
      this.ngxLoader.stop();
      if (success.status == 200) {
        this.onSubmit();
        this.notifyService.showSuccess("Successfully updated vehicle trip assign", null);
      } else {
        this.notifyService.showWarning("Failed to update vehicle trip assign", null);
      }
    }, (error) => {
      this.ngxLoader.stop();
      console.log(error)
    });
  }

  download(): void {
    this.exceldata = [];
    let data: any = {};
    let number = 1;
    if (this.tripDetailsData.length > 0) {
      this.ngxLoader.start();
      this.tripDetailsData.forEach(element => {
        this.exceldata.push({
          tripCode: element.tripCode,
          vehicleNo: element.vehicle == null ? "NA" : element.vehicle.registrationNo,
          vendor: element.tripVendorAssign.vendorMaster.name,
          driver: element.driver == null ? "NA" : element.driver.name,
          contactNo: element.driver == null ? "NA" : element.driver.contactNumber,
          vehicleType: element.vehicleType,
          escortRequired: element.escortStatus,
          distance: element.tripDistance,
          seatCapacity: element.tripVendorAssign.vehicleType.seatCapacity,
          seatOccupied: element.tripUserList.length,
          allocateVehicle: element.tripVendorAssign.timeElapsed,
          tripTravelTime: element.tripTravelTime,
          site: element.tripVendorAssign.tripDetails.site.siteName,
          shift: element.shiftTime + " " + element.logType,
          date: element.tripDate,
        });

        this.exceldata.push({
          tripCode: "EmployeeId",
          vehicleNo: "EmployeeName",
          vendor: "Gender",
          driver: "Distance",
          contactNo: "Time",
          vehicleType: "Medical",
          escortRequired: "TravelStatus",
          distance: "Contact",
          seatCapacity: "Address",
          seatOccupied: '',
          allocateVehicle: '',
          tripTravelTime: '',
          site: '',
          shift: '',
          date: '',
        });
        element.tripUserList.forEach(element => {
          this.exceldata.push({
            tripCode: element.tripUser.userInfoDetails.employeeId,
            vehicleNo: element.tripUser.userInfoDetails.displayName,
            vendor: element.tripUser.userInfoDetails.gender,
            driver: element.empTripDistance,
            contactNo: element.pickupDropTime,
            vehicleType: element.tripUser.userInfoDetails.userCategory[0].categories.CategoryName,
            escortRequired: element.showstatus ? element.showstatus : 'NO SHOW',
            distance: element.tripUser.userInfoDetails.contactNumber,
            seatCapacity: element.landmark.landmark,
          });
        });
        if (number < this.tripDetailsData.length) {
          this.exceldata.push(
            {},
            {
              tripCode: "tripCode",
              vehicleNo: "vehicleNo",
              vendor: "vendor",
              driver: "driver",
              contactNo: "contactNo",
              vehicleType: "vehicleType",
              escortRequired: "escortRequired",
              distance: "distance",
              seatCapacity: "seatCapacity",
              seatOccupied: "seatOccupied",
              allocateVehicle: "allocateVehicle",
              tripTravelTime: "tripTravelTime",
              site: "site",
              shift: "shift",
              date: "date",
            }
          );
        }
        number++;
      });
    }
    else {
      this.notifyService.showInfo("No data found", null);
    }
    this.serviceExportExcel.exportAsExcelFile(this.exceldata, "trips");
    this.ngxLoader.stop();
  }


  downloadAllocated(): void {
    this.exceldata = [];
    let data: any = {};
    let number = 1;
    if (this.allocatedTripArr.length > 0) {
      this.ngxLoader.start();
      this.allocatedTripArr.forEach(element => {
        this.exceldata.push({
          tripCode: element.tripCode,
          vehicleNo: element.vehicle == null ? "NA" : element.vehicle.registrationNo,
          vendor: element.tripVendorAssign.vendorMaster.name,
          driver: element.driver == null ? "NA" : element.driver.name,
          contactNo: element.driver == null ? "NA" : element.driver.contactNumber,
          vehicleType: element.vehicleType,
          escortRequired: element.escortStatus,
          distance: element.tripDistance,
          seatCapacity: element.tripVendorAssign.vehicleType.seatCapacity,
          seatOccupied: element.tripUserList.length,
          allocateVehicle: element.tripVendorAssign.timeElapsed,
          tripTravelTime: element.tripTravelTime,
          site: element.tripVendorAssign.tripDetails.site.siteName,
          shift: element.shiftTime + " " + element.logType,
          date: element.tripDate
        });

        this.exceldata.push({
          tripCode: "EmployeeId",
          vehicleNo: "EmployeeName",
          vendor: "Gender",
          driver: "Distance",
          contactNo: "Time",
          vehicleType: "Medical",
          escortRequired: "TravelStatus",
          distance: "Contact",
          seatCapacity: "Address",
          seatOccupied: '',
          allocateVehicle: '',
          tripTravelTime: '',
          site: '',
          shift: '',
          date: '',
        });
        element.tripUserList.forEach(element => {
          this.exceldata.push({
            tripCode: element.tripUser.userInfoDetails.employeeId,
            vehicleNo: element.tripUser.userInfoDetails.displayName,
            vendor: element.tripUser.userInfoDetails.gender,
            driver: element.empTripDistance,
            contactNo: element.pickupDropTime,
            vehicleType: element.tripUser.userInfoDetails.userCategory[0].categories.CategoryName,
            escortRequired: element.showstatus ? element.showstatus : 'NO SHOW',
            distance: element.tripUser.userInfoDetails.contactNumber,
            seatCapacity: element.landmark.landmark
          });
        });
        if (number < this.allocatedTripArr.length) {
          this.exceldata.push(
            {},
            {
              tripCode: "tripCode",
              vehicleNo: "vehicleNo",
              vendor: "vendor",
              driver: "driver",
              contactNo: "contactNo",
              vehicleType: "vehicleType",
              escortRequired: "escortRequired",
              distance: "distance",
              seatCapacity: "seatCapacity",
              seatOccupied: "seatOccupied",
              allocateVehicle: "allocateVehicle",
              tripTravelTime: "tripTravelTime",
              site: "site",
              shift: "shift",
              date: "date"
            }
          );
        }
        number++;
      });
    }
    else {
      this.notifyService.showInfo("No data found", null);
    }
    this.serviceExportExcel.exportAsExcelFile(this.exceldata, "AllocatedTrips");
    this.ngxLoader.stop();
  }

  downloadUnAllocated(): void {
    this.exceldata = [];
    let data: any = {};
    let number = 1;
    if (this.unAllocatedTripArr.length > 0) {
      this.ngxLoader.start();
      this.unAllocatedTripArr.forEach(element => {
        this.exceldata.push({
          tripCode: element.tripCode,
          vehicleNo: element.vehicle == null ? "NA" : element.vehicle.registrationNo,
          vendor: element.tripVendorAssign.vendorMaster.name,
          driver: element.driver == null ? "NA" : element.driver.name,
          contactNo: element.driver == null ? "NA" : element.driver.contactNumber,
          vehicleType: element.vehicleType,
          escortRequired: element.escortStatus,
          distance: element.tripDistance,
          seatCapacity: element.tripVendorAssign.vehicleType.seatCapacity,
          seatOccupied: element.tripUserList.length,
          allocateVehicle: element.tripVendorAssign.timeElapsed,
          tripTravelTime: element.tripTravelTime,
          site: element.tripVendorAssign.tripDetails.site.siteName,
          shift: element.shiftTime + " " + element.logType,
          date: element.tripDate
        });

        this.exceldata.push({
          tripCode: "EmployeeId",
          vehicleNo: "EmployeeName",
          vendor: "Gender",
          driver: "Distance",
          contactNo: "Time",
          vehicleType: "Medical",
          escortRequired: "TravelStatus",
          distance: "Contact",
          seatCapacity: "Address",
          seatOccupied: '',
          allocateVehicle: '',
          tripTravelTime: '',
          site: '',
          shift: '',
          date: '',
        });
        element.tripUserList.forEach(element => {
          this.exceldata.push({
            tripCode: element.tripUser.userInfoDetails.employeeId,
            vehicleNo: element.tripUser.userInfoDetails.displayName,
            vendor: element.tripUser.userInfoDetails.gender,
            driver: element.empTripDistance,
            contactNo: element.pickupDropTime,
            vehicleType: element.tripUser.userInfoDetails.userCategory[0].categories.CategoryName,
            escortRequired: element.showstatus ? element.showstatus : 'NO SHOW',
            distance: element.tripUser.userInfoDetails.contactNumber,
            seatCapacity: element.landmark.landmark,
          });
        });
        if (number < this.unAllocatedTripArr.length) {
          this.exceldata.push(
            {},
            {
              tripCode: "tripCode",
              vehicleNo: "vehicleNo",
              vendor: "vendor",
              driver: "driver",
              contactNo: "contactNo",
              vehicleType: "vehicleType",
              escortRequired: "escortRequired",
              distance: "distance",
              seatCapacity: "seatCapacity",
              seatOccupied: "seatOccupied",
              allocateVehicle: "allocateVehicle",
              tripTravelTime: "tripTravelTime",
              site: "site",
              shift: "shift",
              date: "date"
            }
          );
        }
        number++;
      });
    }
    else {
      this.notifyService.showInfo("No data found", null);
    }
    this.serviceExportExcel.exportAsExcelFile(this.exceldata, "UnallocatedTrips");
    this.ngxLoader.stop();
  }

  getAudit(e) {
    this.ngxLoader.start();
    this.apiService.doGetRequestWithResponse(ApiConstants.getTripViewAuditById + '?id=' + e.tripId, (success) => {
      this.ngxLoader.stop();
      if (success.status == 200) {
        this.dialog.open(AuditLogComponent, {
          autoFocus: false,
          maxHeight: '130vh',
          maxWidth: '200vh',
          data: success.body,
          disableClose: true
        });
      } else {
        this.notifyService.showInfo("No audit logs found", null);
      }
    }, (error) => {
      this.ngxLoader.stop();
      this.notifyService.showError("Error while loading audit log.", null);
    });
  }


  checkMaskingEnabled() {
    let clientCode = localStorage.getItem("clientCode")
    this.apiService.doGetRequestWithResponse(ApiConstants.checkMaskingEnabled + clientCode, (success) => {
      if (success.status === 200) {
        this.maskingEnabled = success.body.maskingEnabled;
        if (!this.maskingEnabled) {
          this.showHideAddress = true;
          this.showContact = true;
          this.addressOrAL = false;
          this.showOrHideAL = true;
          if (this.showOrHideAL == true) {
            this.showHideAddress = false;
          }
        }
      }
    }, (error) => {
      if (!this.maskingEnabled) {
        this.showHideAddress = true;
        this.showContact = true;
      }
    });
  }

  showOrHideTitle(e) {
    this.showOrHideAL = (e == true) ? true : false;
    if (e) {
      if (this.mTitle == 'Address') {
        this.showHideAddress = this.showHideAddress == true ? false : true;
        this.showHideLandmark = false;
      } else {
        this.showHideAddress = false;
        this.showHideLandmark = this.showHideLandmark == true ? false : true;
      }
    } else {
      this.showHideAddress = this.showHideAddress == false ? true : false;
      this.showHideLandmark = this.showHideLandmark == false ? true : false;
    }
  }

  showOrHideAddress(e) {
    if (e) {
      this.mTitle = 'Landmark';
      this.areaLandmark = true;
      this.address = false;
      if (this.showOrHideAL) {
        this.showHideLandmark = false;
      } else {
        this.showHideLandmark = true;
      }
    } else {
      this.mTitle = 'Address';
      this.address = true;
      this.areaLandmark = false;
      if (this.showOrHideAL) {
        this.showHideAddress = false;
      } else {
        this.showHideAddress = true;
      }
    }
  }

  showOrHideContact(e) {
    if (e.checked) {
      this.showContact = true;
    } else {
      this.showContact = false;
    }
  }


  maptrackview(tripid) {
    this.ngxLoader.start();
    this.apiService.doGetRequestWithResponse(ApiConstants.gettripbytripid + '?tripid=' + tripid, (success) => {
      this.ngxLoader.stop();
      if (success.status == 200) {
        let reqData = {
          params: {
            modifyshow: true,
            date: this.currentDate
          },
          data: success.body,
          mapType: this.mapType
        };
        this.dialog.open(TripMapComponent, {
          width: '1250px',
          height: '470px',
          data: reqData,
          disableClose: true
        });
      } else {
        this.notifyService.showInfo("No Trips Found", null);
      }
    }, (error) => {
      this.ngxLoader.stop();
      this.notifyService.showError("Error while loading trips data", null);
    });
  }

  removeVDAllocation(trips) {
    let tripId = trips.tripId;
    this.apiService.doPostRequestWithResponse(ApiConstants.removeVehicleAllocation + '?tripId=' + tripId, null, (success) => {
      if (success.status == 200) {
        this.notifyService.showSuccess(success.body.message, null);
        trips.driver = null;
        trips.driverList = [];
        trips.vehicle = null;
        trips.tripVendorAssign.driver = null;
        trips.tripVendorAssign.vehicle = null;
        this.onSubmit();
      } else {
        this.notifyService.showError("Failed to update", null);
      }
    }, (error) => {

    })
  }

  getExtractedSubstring(originalString): string {
    return originalString.substring(12);
  }

}
