<form [formGroup]="_mFormGroup">
  <div class="rs_total_filter">
    <div class="row mt-3">
      <div class="col-sm-3">

        <label>Location</label>
        <select class="form-select margin-right-8px" placeholder="City" formControlName="city"
          (ngModel)="_mSelectedCityId" (change)="getSitesByCityId()" required>
          <option disabled value="">Choose Location</option>
          <option *ngFor="let city of _mCities" [value]="city.locationId">
            {{ city.locationName }}
          </option>
        </select>
        <div class="error-msg" *ngIf="_mFormGroup.get('city').hasError('required') && _mFormGroup.get('city').touched">
          Please select city
        </div>
        <!-- <mat-error *ngIf="mCity.hasError('required')">
          Please select city.
        </mat-error> -->

      </div>
      <div class="col-sm-3">
        <label>Site</label>
        <select class="form-select margin-right-8px" placeholder="Site" [disabled]="!_mSelectedCityFlag"
          formControlName="site" required>
          <option disabled value="">Choose Site</option>
          <option *ngFor="let site of _mSites" [value]="site.siteId">
            {{ site.siteName}}
          </option>
        </select>
        <div class="error-msg" *ngIf="_mFormGroup.get('site').hasError('required') && _mFormGroup.get('site').touched">
          Please select site
        </div>
        <!-- <mat-error *ngIf="mSite.hasError('required')">
          Please select site.
        </mat-error> -->
        <!-- <mat-progress-bar style="top:6px" class="example-margin"
          mode="indeterminate" *ngIf="_mSelectedCityFlag && !_mSites">
        </mat-progress-bar> -->

      </div>
      <div class="col-sm-3">

        <label>Route type</label>
        <select class="form-select margin-right-8px" placeholder="Select RouteType" formControlName="routeType"
          required>
          <option disabled value="">Choose Route type</option>
          <option *ngFor="let routeType of _mRouteTypes" [value]="routeType.key">
            {{ routeType.value}}
          </option>
        </select>
        <div class="error-msg"
          *ngIf="_mFormGroup.get('routeType').hasError('required') && _mFormGroup.get('routeType').touched">
          Please select routeType
        </div>
        <!-- <mat-error *ngIf="mRouteType.hasError('required')">
          Please select route type.
        </mat-error> -->

      </div>
      <!-- <div class="col-sm-2" style="margin-top: 25px">
        <button class="btn playlist-btn-solid" style="height: 35px;" [disabled]="(this.mZone.value&&this.mMasterZone&&this.mSite) ?
            false : true" (click)="showMapping(selectedSite,selectedval)">Show
          Mapping</button>
      </div> -->
      <div class="col-sm-3 d-flex justify-content-end" style="margin-top: 25px">
        <button type="button" [disabled]="_mFormGroup.invalid" style="height: 35px;" class="btn playlist-btn-solid"
          (click)="onSubmit()">
          Submit
        </button>
      </div>
    </div>
  </div>
  <div class="rs_main_heading mt-3" style="padding:0px">
    <div style="float:left;padding:13px 0px 0px 15px; font-size: 14px; font-weight: 500; font-family: 'poppins';">Route
      List</div>
    <span style="font-size: 14px; font-weight: 500; font-family: 'poppins';" (click)="onDownloadHAL()">Download
      H-AL</span>
    <span style="font-size: 14px; font-weight: 500; font-family: 'poppins';" (click)="onDownloadAL()">Download
      C-AL</span>
    <span style="font-size: 14px; font-weight: 500; font-family: 'poppins';" (click)="downloadRouteTemplate()">Download
      Route Template</span>
    <span style="font-size: 14px; font-weight: 500; font-family: 'poppins';" (click)="downloadRoutes()">Download Routes
      Download</span>
    <label style="font-size: 14px; font-weight: 500; font-family: 'poppins';">Upload<input class="tdw-display-none"
        (change)="onFileChange($event)" [ngModelOptions]="{standalone: true}" [(ngModel)]="routesFile"
        type="file"></label>
    <span style="font-size: 14px; font-weight: 500; font-family: 'poppins';" (click)="onAdd()">Add</span>
  </div>

  <div class="rs_total_r_list">
    <div class="row">
      <div class="col-md-3 no-padding" style="padding-left:14px !important;">
        <div class="rs_r_inner_div">
          <div class="rs_r_header">
            <label>Search route</label>
            <mat-form-field appearance="outline" class="fixed-route-date">
              <input matInput placeholder="Search" formControlName="routeSearchkey" autocomplete="off">
              <img matSuffix src="assets/images/search.svg" height="27" style="margin-top: -22px;padding-right: 5px;">
            </mat-form-field>
          </div>
          <ul class="rs_r_route_list">
            <ng-container *ngIf="_mRoutesData.length > 0; else noData">
              <li class="main_li fix-route-td" (mouseover)="hoverFunction($event)" (click)="getAreaByRouteId(route.id)"
                (mouseout)="hoverOutFunction($event)" *ngFor="let route of
              _mRoutesData">{{route.routeName}}
                <span *ngIf="(route.routeType == 'P') ? true : false" class="rs_Primary">Primary</span>
                <span *ngIf="(route.routeType == 'C') ? true : false" class="rs_combaind">Combined</span>
                <span *ngIf="(route.routeType == 'FC') ? true : false" class="rs_fully_combaind">Fully Combined</span>
                <div>
                  <ul class="rs_list_icons">
                    <li><mat-icon matSuffix (click)="onDelete(route,'route')">delete</mat-icon></li>
                  </ul>
                </div>
              </li>
            </ng-container>
            <ng-template #noData>
              <h4 class="tdw-text-align-center tdw-font-normal tdw-margin-4
              mat-color-text--blue-grey-600">-- No Data Found --</h4>
            </ng-template>
          </ul>
        </div>
      </div>
      <div class="col-md-2 no-padding">
        <div class="rs_r_inner_div">
          <div class="rs_r_header">
            <label>Search Area</label>
            <mat-form-field appearance="outline" class="fixed-route-date">
              <input matInput placeholder="Search" formControlName="areaSearchkey" autocomplete="off">
              <img matSuffix src="assets/images/search.svg" height="27" style="margin-top: -22px;padding-right: 5px;">
            </mat-form-field>

          </div>
          <ul class="rs_r_route_list">
            <ng-container *ngIf="_mAreasData.length > 0; else noData">
              <li class="main_li fix-route-td" (mouseover)="hoverFunction($event)" (click)="getLandmarkByAreaId(area.id)"
                (mouseout)="hoverOutFunction($event)" *ngFor="let area of
              _mAreasData">{{area.area}}
                <div>
                  <ul class="rs_list_icons">
                    <li><mat-icon matSuffix (click)="onDelete(area,'area')">delete</mat-icon></li>
                  </ul>
                </div>
              </li>
            </ng-container>
            <ng-template #noData>
              <h4 class="tdw-text-align-center tdw-font-normal tdw-margin-4
              mat-color-text--blue-grey-600">-- No Data Found --</h4>
            </ng-template>
          </ul>
        </div>
      </div>
      <div class="col-md-7">
        <div class="rs_r_header">
          <label>Search Landmark</label>
          <mat-form-field appearance="outline" class="fixed-route-date">
            <input matInput placeholder="Search by landmark" formControlName="landmarkSearchkey" autocomplete="off" (input)="onSearchKey(mLandmarkSearchKey.value)">
            <img matSuffix src="assets/images/search.svg" height="27" style="margin-top: -22px;padding-right: 5px;">
          </mat-form-field>
        </div>
        <div class="rs_r_route_list">
          <table mat-table [dataSource]="dataSource" class="table" *ngIf="ELEMENT_DATA.length > 0;">
            <ng-container matColumnDef="landmark">
              <th mat-header-cell *matHeaderCellDef class="fix-route-th">Landmark</th>
              <td mat-cell *matCellDef="let element" class="left-padding fix-route-td">
                {{element.landmark}} </td>
            </ng-container>
            <ng-container matColumnDef="latitude">
              <th mat-header-cell *matHeaderCellDef class="fix-route-th"> Latitude </th>
              <td mat-cell *matCellDef="let element" class="fix-route-td"> {{element.latitude}} </td>
            </ng-container>
            <ng-container matColumnDef="longitude">
              <th mat-header-cell *matHeaderCellDef class="fix-route-th"> Longitude </th>
              <td mat-cell *matCellDef="let element" class="fix-route-td"> {{element.longitude}}
              </td>
            </ng-container>
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef class="fix-route-th">Type </th>
              <td mat-cell *matCellDef="let element" class="fix-route-td"> {{element.type}} </td>
            </ng-container>
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef class="fix-route-th">Action</th>
              <td mat-cell class="table_delete" *matCellDef="let element">
                <mat-icon (click)="onDelete(element,'landmark')" matSuffix>delete</mat-icon>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <div class="row" *ngIf="ELEMENT_DATA.length == 0">
            <div class="col-sm-12 col-md-12">
                <h4 class="tdw-text-align-center tdw-font-normal tdw-margin-4
                  mat-color-text--blue-grey-600">
                    --No Data Found --</h4>
            </div>
        </div>
        </div>

      </div>
    </div>
  </div>
</form>
<!-- <div *ngIf="addFlag">
  <app-add-fixed-routing [data]="data"></app-add-fixed-routing>
</div> -->