
import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router, Event } from '@angular/router';

@Component({
  selector: 'app-routing-setup-layout',
  templateUrl: './routing-setup-layout.component.html',
  styleUrls: ['./routing-setup-layout.component.scss']
})
export class RoutingSetupLayoutComponent implements OnInit {
  activeTab = null;
  activeTabId = 1;
  first: any = true;

  constructor(
    private router: Router
  ){
    this.activeTab = this.router.url.split('/').pop();
    this.router.events.subscribe((val: Event) => {
      if (!this.first) {
        if (val instanceof NavigationStart) {
          if (this.activeTab !== val.url.split('/').pop()) {
            this.activeTab = val.url.split('/').pop();
            if (this.activeTab === 'routing-setup') {
              this.activeTabId = 1;
            } else if (this.activeTab === 'fixed-routing-setup') {
              this.activeTabId = 2;
            } else if (this.activeTab === 'route-map-setup') {
              this.activeTabId = 3;
            } else if (this.activeTab === 'special-category-setup') {
              this.activeTabId = 4;
            }
            else if (this.activeTab === 'navigation-setup') {
              this.activeTabId = 5;
            }
          }
        }
      }
    });
  }

  ngOnInit(): void {
    this.onTabActivate(this.activeTab);
  }

  onTabActivate(activeTab) {
    if (activeTab != null) {
      if (activeTab === 'routing-setup') {
        this.activeTabId = 1;
        this.router.navigate(["/myatom/routing/routing-setup/routing-setup"]);
      } else if (activeTab === 'fixed-routing-setup') {
        this.activeTabId = 2;
        this.router.navigate(["/myatom/routing/routing-setup/fixed-routing-setup"]);
      } else if (activeTab === 'route-map-setup') {
        this.activeTabId = 3;
        this.router.navigate(["/myatom/routing/routing-setup/route-map-setup"]);
      } else if (activeTab === 'special-category-setup') {
        this.activeTabId = 4;
        this.router.navigate(["/myatom/routing/routing-setup/special-category-setup"]);
      }
      else if (activeTab === 'navigation-setup') {
        this.activeTabId = 5;
        this.router.navigate(["/myatom/routing/routing-setup/navigation-setup"]);
      }
    }
  }

  onTabChange(tabName: any) {
    this.activeTab = tabName;
    this.onTabActivate(tabName);
  }

}

