import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy
} from "@angular/core";
// import { PreviewDialogComponent } from "../preview-dialog/preview-dialog.component";
// import { element } from "@angular/core/src/render3/instructions";
// import * as XLSX from "ts-xlsx";
// import { UploadIrLibraryComponent } from "./upload-ir-library/upload-ir-library.component";
// import { UploadTripsStatusComponent } from "../../apl-config/trip-view/upload-trips-status/upload-trips-status.component";
// import { debounceTime, distinctUntilChanged } from "rxjs/operators";
// import { Subject } from "rxjs/Subject";
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder
} from "@angular/forms";
import { ApiService } from "src/app/core/services/api.service";
import { NotificationService } from "src/app/core/services/notification.service";
import { ServiceExportExcel } from "src/app/core/services/export.service";
import { ServiceLocalStorage } from "src/app/core/services/local-storage.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ApiConstants } from "src/app/core/constants/api-path.constants";
import { Subject, debounceTime, distinctUntilChanged } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { PreviewDialogComponent } from "./preview-dialog/preview-dialog.component";



@Component({
  selector: "app-manage-master-route",
  templateUrl: "./manage-master-route.component.html",
  styleUrls: ["./manage-master-route.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageMasterRouteComponent implements OnInit {
  public _mFormGroup: FormGroup;
  public mLocation = new FormControl("", Validators.required);
  public mSite = new FormControl("", Validators.required);
  public mShift = new FormControl("", Validators.required);
  public mRouteType = new FormControl("", Validators.required);
  searchEmpTxt:any;
  cities: any;
  sites: any;
  dropdownlist: any = [];
  shifts = [];
  exceptionEmps: any = [];
  addingNumber = -100;
  vehicleList: any = [];
  tripsList: any[] = [];
  exceldata: any[] = [];
  masterRouteData: any;
  toDropTrip: any;
  _tripIndex: number;
  typeFlag: boolean = false;
  arrayBuffer: any;
  file: any;
  tripsListData: any[] = [];
  userSearchResult: any;
  userNotFound: boolean;
  uploadMasterFile: any;
  types: any = [
    { value: "MASTER_ROUTING", view: "ROUTING" },
    { value: "MASTER_IR", view: "IR" },
  
  
  ];
  searchSubject$ = new Subject<string>();
  _mUserSearchFlag: boolean = false;
  _mLocadCityFlag: boolean = false;
  _mLoadSiteFlag: boolean = false;
  _mLoadShiftTimesFlag: boolean = false;
  draggedUser: any;
  draggedUserIndex: any;
  activeTabId = 1;
  constructor(
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    private readonly serviceExportExcel: ServiceExportExcel,
    private readonly localStorage: ServiceLocalStorage,
    public readonly dialog: MatDialog,
    private ngxLoader: NgxUiLoaderService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef
  ) {
    this.searchSubject$
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(searchKey => {
        this._mUserSearchFlag = true;
        if (searchKey && searchKey.length > 2) {
          this.apiService.doGetRequestWithResponse(ApiConstants.getexceptionemployeesearch +'?searchkey='+
            searchKey,
            success => {
              this._mUserSearchFlag = false;
              this.userSearchResult = success.body;
              if (this.userSearchResult == null) {
                this.userNotFound = true;
              } else {
                this.userNotFound = false;
              }
              this.cd.markForCheck();
            },
            error => {
              this._mUserSearchFlag = false;
            }
          );
        } else {
          this.userSearchResult = [];
          this._mUserSearchFlag = false;
        }
      });

    this._mFormGroup = this.fb.group({
      location: this.mLocation,
      site: this.mSite,
      shift: this.mShift,
      routeType: this.mRouteType
    });
  }

  ngOnInit() {
    this.loadCities();
    this.mSite.disable();
    this.mShift.disable();
  }

  getSite() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getSitesById +
      this.mSite.value,
      success => {
        this.vehicleList = success.dataList.content[0].vehicleTypes;
      },
      error => {
        this.notifyService.showError("Unable to fetch vehicles!", null);
      }
    );
  }

  loadCities() {
    this._mLocadCityFlag = true;
    let companyid: number = +this.localStorage.getCompanyId();
    this.apiService.doGetRequestWithResponse(ApiConstants.getserchlocationlist ,
      success => {
        this._mLocadCityFlag = false;
        if (success.status == 200) {
          this.cd.markForCheck();
          this.cities = success.body;
        } else {
          this.cities = [];
        }
      },
      error => {
        this._mLocadCityFlag = false;
        this.notifyService.showError(error, null);
      }
    );
  }

  setLandmark(user, type) {
    this.cd.markForCheck();
    let selectedUser = user.userInfoDetails;
    selectedUser.landmark =
      type == "P"
        ? user.userInfoDetails.pickUpDrop
        : user.userInfoDetails.homePoint;
    var i = this.exceptionEmps.length;
    while (i--) {
      if (this.exceptionEmps[i].landmark.id === selectedUser.landmark.id) {
        this.exceptionEmps.splice(i, 1);
      }
    }
    selectedUser.status = null;
    this.exceptionEmps.push(selectedUser);
    this.cd.markForCheck();
  }

  userSearchSubmit(user) {
    this.cd.markForCheck();
    this.tripsList.forEach((trips, i) => {
      trips.tripDetails.tripUsers.forEach((users, j) => {
        if (!users.tripUser) {
          if (users.employeeId === user.userInfoDetails.employeeId) {
            this.exceptionEmps.splice(this.exceptionEmps.indexOf(user), 1);
            this.notifyService.showError("User already exist in list!", null);
          }
        } else {
          if (
            users.tripUser.userInfoDetails.employeeId ===
            user.userInfoDetails.employeeId
          ) {
            this.exceptionEmps.splice(this.exceptionEmps.indexOf(user), 1);
            this.notifyService.showError("User already exist in list!", null);
          }
        }
      });
    });
  }

  downloadAL(): void {
    // let data: any = {};
    // let number = 1;
    // this.tripsListData = [];
    // if (this.tripsList.length > 0) {
    //   let tempArr: any[] = this.tripsList;
    //   tempArr.forEach(arr => {
    //     arr.tripDetails.tripUsers.forEach(element => {
    //       this.tripsListData.push({
    //         landmark: element.landmark.landmark,
    //         noOfTimesTravel: element.status
    //       });
    //     });
    //     this.tripsListData.push(data);
    //     if (number < this.tripsList.length) {
    //       this.tripsListData.push({
    //         landmark: "landmark",
    //         noOfTimesTravel: "noOfTimesTravel"
    //       });
    //     }
    //     number++;
    //   });
    //   this.serviceExportExcel.exportAsExcelFileForTrips(
    //     this.tripsListData,
    //     "Routes"
    //   );
    // } else {
    //   this.notifyService.showError("No data found", null);
    // }
  }

  onFileChange($event) {
    // this.file = $event.target.files[0];
    // let missingUsers = [];
    // let fileReader = new FileReader();
    // fileReader.onload = e => {
    //   this.arrayBuffer = fileReader.result;
    //   var data = new Uint8Array(this.arrayBuffer);
    //   var arr = new Array();
    //   for (var i = 0; i != data.length; ++i)
    //     arr[i] = String.fromCharCode(data[i]);
    //   var bstr = arr.join("");
    //   var workbook = XLSX.read(bstr, { type: "binary" });
    //   var first_sheet_name = workbook.SheetNames[0];
    //   var worksheet = workbook.Sheets[first_sheet_name];
    //   //
    //   missingUsers = XLSX.utils.sheet_to_json(worksheet, { raw: true });
    //   let dialogRef = this.dialog.open(UploadIrLibraryComponent, {
    //     disableClose: true
    //   });
    //   dialogRef.afterClosed().subscribe(result => {
    //     if (result == "Allow") {
    //       this.upload($event);
    //     }
    //   });
    // };
    // fileReader.readAsArrayBuffer(this.file);
  }

  upload($event) {
    // let type = "IR_LIBRARY_BATCH";
    // let files = $event.target.files;
    // if (files && files.length > 0) {
    //   let fileName: string = files[0].name;
    //   let fileExt = fileName.split(".").pop();
    //   if (fileExt) {
    //     if (fileExt.includes("xlsx") || fileExt.includes("xls")) {
    //       let uploadExcelUrl =
    //         RequestUtils.BASE_URL +
    //         this.requestUtils.excelDataUpload().uploadExcel;
    //       let userId = parseInt(
    //         this.localStorage.getUserId().toString()
    //       );
    //       this.uploadService.uploadExcelFile(
    //         files[0],
    //         userId,
    //         uploadExcelUrl,
    //         type,
    //         success => {
              
    //           this.uploadTripStatus(success);
    //           this.uploadMasterFile = "";
    //         },
    //         error => {
              
              
    //           this.notifyService.openSnackBar("Error occured", null);
    //           this.uploadMasterFile = "";
    //         }
    //       );
    //     } else {
          
    //       this.notifyService.openSnackBar("There seems to be a problem with the file that you uploaded. Please upload a Valid File", null);
    //       this.uploadMasterFile = "";
    //     }
    //   } else {
        
    //     this.notifyService.openSnackBar("There seems to be a problem with the file that you uploaded. Please upload a Valid File", null);
    //     this.uploadMasterFile = "";
    //   }
    // }
  }

  getSitesByCityId() {
    this._mLoadSiteFlag = true;
    this.apiService.doGetRequestWithResponse(ApiConstants.getserchsitelist +
      this.mLocation.value,
      success => {
        this._mLoadSiteFlag = false;
        if (success.status == 200) {
          this.mSite.enable();
          this.sites = success.body;
        } else {
          this.sites = [];
          this.notifyService.showInfo("No Sites Found!", null);
        }
      },
      error => {
        this._mLoadSiteFlag = false;
      }
    );
  }

  getShiftTime() {
    this.shifts = [];
    this._mLoadShiftTimesFlag = true;
    this.apiService.doGetRequestWithResponse(ApiConstants.getShiftTimesListBySiteId + '?siteId=' +
      this.mSite.value + '&shiftType=' +
      "General",
      success => {
        this._mLoadShiftTimesFlag = false;
        if (success.status == 200) {
          this.mShift.enable();
          this.shifts = success.body;
        } else {
          this.notifyService.showInfo("No Shifts Found!", null);
          this.shifts = [];
        }
      },
      error => {
        this._mLoadShiftTimesFlag = false;
      }
    );
  }

  getallmasterroute() {
    if (this.mLocation.valid) {
      if (this.mSite.valid) {
        if (this.mShift.valid) {
          if (this.mRouteType.valid) {
            this.typeFlag = false;
            this.apiService.doGetRequestWithResponse(ApiConstants.getallmasterroute + '?shiftid=' +
              this.mShift.value +'&siteid=' +
              this.mSite.value + '&type=' +
              this.mRouteType.value,
              success => {
                 
                if (success.status == 200) {
                  this.tripsList = success.body.tripVendorDetails;
                  this.masterRouteData = success.body;
                  if (this.mRouteType.value == "MASTER_IR") {
                    this.typeFlag = true;
                  }
                } else {
                  this.tripsList = [];
                  this.notifyService.showInfo(
                    "No Master route Found!.",
                    null
                  );
                }
                this.cd.markForCheck();
              },
              error => {
                
              }
            );
          } else {
            this.notifyService.showInfo("Please select route type.", null);
          }
        } else {
          this.notifyService.showInfo("Please select shift time.", null);
        }
      } else {
        this.notifyService.showInfo("Please select site.", null);
      }
    } else {
      this.notifyService.showInfo("Please select the city.", null);
    }
    this.cd.markForCheck();
  }

  addTrip() {
    let tripCode = this.tripsList.length;
    let newTrip = {
      id: null,
      tripDetails: {
        tripUsers: [],
        tripId: null,
        tripCode: tripCode + 1,
        tripVendorAssign: null,
        site: this.tripsList[0].tripDetails.site,
        shift: this.tripsList[0].tripDetails.shift,
        escortStatus: null,
        tripDate: null,
        tripDistance: null,
        tripTravelTime: null,
        createDateTime: null,
        modifiedDate: null,
        tripstatus: null,
        zone: this.tripsList[0].tripDetails.zone,
        generatestatus: 1
      },
      vendorMaster: null,
      status: "Active",
      createDateTime: null,
      modifiedDate: null,
      vehicle: null,
      vehicleType: null,
      driver: null,
      onTimeStatus: null,
      adminComments: null,
      vendorComments: null,
      deviceImei: null,
      startDateTime: null,
      stopDateTime: null,
      downloadStatus: null,
      timeElapsed: null,
      distanceCovered: null,
      escort: null,
      escortBoardedStatus: null,
      escortBoardedDataTime: null,
      escortBoardedLatitude: null,
      escortBoardedLongitude: null,
      DeviceTripStatus: null,
      forceStop: null,
      deviceTripStatus: null
    };
    this.tripsList.splice(0, 0, newTrip);
    this.cd.markForCheck();
  }

  deleteView(index) {
    this.cd.markForCheck();
    this.tripsList.splice(index, 1);
    this.cd.markForCheck();
  }

  previewDialog(tripDetails) {
    this.tripsList.forEach((element, i) => {
      let tripOrder = 1;
      element.tripDetails.tripUsers.forEach((element, j) => {
        this.tripsList[i].tripDetails.tripUsers[j].routedOrder = tripOrder;
        tripOrder++;
      });
    });
    if (tripDetails.tripDetails.tripUsers.length > 0) {
      let dialogRef = this.dialog.open(PreviewDialogComponent, {
        width: "700px",
        height: "500px",
        data: tripDetails,
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
        }
      });
    } else {
      this.notifyService.showInfo("No landmark Found!.", null);
    }
  }

  deleteComb(tripDetails) {
    let idList = [];
    idList.push(tripDetails.id);
    
    this.apiService.doPostRequestWithResponse(ApiConstants.deletemasterroute + '?routeType='+
      this.mRouteType.value,
      idList,
      success => {
        if (success) {
          
          this.notifyService.showSuccess("Updated successfully", null);
          this.getallmasterroute();
        }
      },
      error => {
        
      }
    );
  }

  dragStarted(trip, index) {
    this.cd.markForCheck();
    this.toDropTrip = trip;
    this._tripIndex = index;
  }

  dragEndTrips(event, trip) {}

  onItemDrop(trip, user) {
    this.cd.reattach();
    this.tripsList.forEach((trips, i) => {
      let tripOrder = 1;
      trips.tripDetails.tripUsers.forEach((users, j) => {
        this.tripsList[i].tripDetails.tripUsers[j].routedOrder = tripOrder;
        tripOrder++;
      });
    });
  }

  save() {
    this.masterRouteData.tripVendorDetails = [];
    
    this.tripsList.forEach(element => {
      if (element.tripDetails.tripUsers.length > 0) {
        this.masterRouteData.tripVendorDetails.push(element);
      }
    });
    this.apiService.doPostRequestWithResponse(ApiConstants.saveallmasterroute,
      this.masterRouteData,
      success => {
        if (success) {
          
          this.notifyService.showSuccess("Saved successfully", null);
        }
      },
      error => {
        
        this.notifyService.showError("Saved failed", null);
      }
    );
  }

  saveSingleTrip(trip) {
    this.masterRouteData.tripVendorDetails = [];
    
    if (trip.tripDetails.tripUsers.length > 0) {
      this.masterRouteData.tripVendorDetails.push(trip);
    }
    this.apiService.doPostRequestWithResponse(ApiConstants.saveallmasterroute,
      this.masterRouteData,
      success => {
        if (success) {
          
          this.notifyService.showSuccess("Saved successfully", null);
        }
      },
      error => {
        
        this.notifyService.showSuccess("Saved failed", null);
      }
    );
  }

  uploadTripStatus(tripData) {
  //   let dialogRef = this.dialog.open(UploadTripsStatusComponent, {
  //     data: tripData,
  //     disableClose: true
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       this.ngOnInit();
  //     }
  //   });
  // }
}
}
