import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RouterConstants } from '../constants/router.constants';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  routerConstant = RouterConstants;
  constructor(
    private authService: AuthenticationService,
    private router: Router) { }

  canActivate(): boolean | import('@angular/router').UrlTree | import('rxjs').Observable<boolean | 
    import('@angular/router').UrlTree> | Promise<boolean | import('@angular/router').UrlTree> {
    return this.authService.checkUserlogin() ? true : this.router.navigateByUrl(this.routerConstant.auth + '/' + this.routerConstant.client);
  }
  
}