import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { AppConstants } from 'src/app/core/constants/appconstants';
import { MatDialog } from '@angular/material/dialog';
import { AuditLogComponent } from 'src/app/shared/audit-log/audit-log/audit-log.component';
import { ModelTableHeader } from 'src/app/shared/models/table-header';
import { ApiService } from 'src/app/core/services/api.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ServiceExportExcel } from 'src/app/core/services/export.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

export interface PeriodicElement {
  id: number;
  landmark: string;
  latitude: number;
  longitude: number;
  type: string;
}


@Component({
  selector: 'app-fixed-routing-setup',
  templateUrl: './fixed-routing-setup.component.html',
  styleUrls: ['./fixed-routing-setup.component.scss']
})
export class FixedRoutingSetupComponent implements OnInit {
  displayedColumns: string[] = ['landmark', 'latitude', 'longitude', 'type', 'id'];
  _mRouteTypes: any[] = [{ key: "All", value: "All" }, { key: "P", value: "Primary" }, { key: "C", value: "Combined" }, { key: "FC", value: "Fully Combined" }];

  addFlag: boolean = false;
  ELEMENT_DATA: PeriodicElement[] = [];
  landMarkData: any[] = [];


  data: any;
  dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);

  public _mFormGroup: FormGroup;
  public mCity = new FormControl('', Validators.required);
  public mSite = new FormControl('', Validators.required);
  public mRouteType = new FormControl('');
  public mRouteSearchKey = new FormControl('');
  public mAreaSearchKey = new FormControl('');
  public mLandmarkSearchKey = new FormControl('');

  companyid: number = +this.localStorage.getCompanyId();
  _mSites: any = [];
  _mCities: any = [];
  _mSelectedCityId: any;
  _mSelectedCityFlag: boolean = false;
  errorResult = [];
  routesFile: string;
  _mTempRouteId: any;
  _mTempAreaId: any;

  _mRoutesData: any[] = [];
  _mTempRoutesData: any[] = [];

  _mAreasData: any[] = [];
  _mTempAreasData: any[] = [];


  constructor(
    private fb: FormBuilder,
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    private readonly serviceExportExcel: ServiceExportExcel,
    private readonly localStorage: ServiceLocalStorage,
    public readonly dialog: MatDialog,
    private ngxLoader: NgxUiLoaderService,
    private router: Router,

  ) {
    this._mFormGroup = this.fb.group({
      'city': this.mCity,
      'site': this.mSite,
      'routeType': this.mRouteType,
      'routeSearchkey': this.mRouteSearchKey,
      'areaSearchkey': this.mAreaSearchKey,
      'landmarkSearchkey': this.mLandmarkSearchKey
    });

    this.mRouteSearchKey.valueChanges.subscribe(searchValue => {
      if (this._mTempRoutesData != null) {
        this._mRoutesData = this._mTempRoutesData.filter(route => route.routeName.toLowerCase().includes(searchValue.toLowerCase()));
      }
    });

    this.mAreaSearchKey.valueChanges.subscribe(searchValue => {
      if (this._mTempAreasData != null) {
        this._mAreasData = this._mTempAreasData.filter(area => area.area.toLowerCase().includes(searchValue.toLowerCase()));
      }
    });

    this.mLandmarkSearchKey.valueChanges.subscribe(searchValue => {
      if (this.ELEMENT_DATA != null) {
        this.dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA.filter(landmark => landmark.landmark.toLowerCase().includes(searchValue.toLowerCase())));
      } 
    });
  }

  ngOnInit() {
    this.loadCities();
  }

  loadCities() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getLocationsByCompanyIdAndUser + this.companyid, success => {
      this._mCities = success.body;
      for (let city of this._mCities) {
        this._mSelectedCityFlag = true;
        this._mSelectedCityId = city.locationId
        break;
      }
      // this.getSitesByCityId(this._mSelectedCityId);
    }, (error) => {
    });
  }

  getAreaByRouteId(routeId) {
    this.apiService.doGetRequestWithResponse(ApiConstants.getAreaByRouteId + '?routeid=' + routeId, (success) => {
      if (success.status == 200) {
        this._mTempRouteId = routeId;
        this._mAreasData = success.body;
        this._mTempAreasData = success.body;
      } else {
        this._mAreasData = [];
        this._mTempAreasData = [];
        this.notifyService.showInfo("No data found", null);
      }
    }, (error) => {
      this.notifyService.showError(error.message, null);
    })
  }


  getLandmarkByAreaId(areaId) {
    this._mTempAreaId = areaId
    this.apiService.doGetRequestWithResponse(ApiConstants.getLandmarksByAreaId + '?areaid=' + areaId + '&routeid=' + this._mTempRouteId, (success) => {
      if (success.status == 200) {
        this.ELEMENT_DATA = success.body;
        this.landMarkData = success.body
        this.dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
      } else {
        this.ELEMENT_DATA = [];
        this.landMarkData = [];
        this.dataSource.data = [];
        this.notifyService.showInfo("No data found", null);
      }
    }, (error) => {
      this.notifyService.showInfo(error.message, null);
    })
  }


  getSitesByCityId() {
    this._mSites = [];
    this.apiService.doGetRequestWithResponse(ApiConstants.getSitesByLocationId + this.mCity.value, success => {
      if (success.status == 200) {
        this._mSites = success.body.dataList.content;
      } else {
        this._mSites = [];
        this.notifyService.showError("No Sites Found!", null);
      }
    },
      error => {
      }
    );
  }

  onDownloadAL() {
    if (this.mSite.value) {
      let data = {
        location: this.mCity.value,
        type: AppConstants.LANDMARK_TYPE_COMMON,
        siteId: this.mSite.value //dummy value, not required in backend for type == total filter
      }
      this.apiService.doGetRequestWithResponse(ApiConstants.getalllandmarksbylocation + data.location + '&type=' + data.type, (success) => {
        if (success.status == 200) {
          let fileData = [];
          success.body.forEach(element => {
            fileData.push({
              Area: element.area.area,
              Landmark: element.landmark,
              FormattedAddress: element.formattedAddress,
              Latitude: element.latitude,
              Longitude: element.longitude,
              Type: element.type
            })
          })
          this.serviceExportExcel.exportAsExcelFile(fileData, 'AL');
        }
      }, (error) => {
        console.log(error)
      })
    } else {
      this.notifyService.showInfo("please select site", null);
    }
  }

  onDownloadHAL() {
    if (this.mSite.value) {
      let data = {
        location: this.mCity.value,
        type: AppConstants.LANDMARK_TYPE_HOME
      }
      this.apiService.doGetRequestWithResponse(ApiConstants.getalllandmarksbylocation + data.location + '&type=' + data.type, (success) => {
        if (success.status == 200) {
          let fileData = [];
          success.body.forEach(element => {
            fileData.push({
              Area: element.area.area,
              Landmark: element.landmark,
              FormattedAddress: element.formattedAddress,
              Latitude: element.latitude,
              Longitude: element.longitude,
              Type: element.type
            })
          })
          this.serviceExportExcel.exportAsExcelFile(fileData, 'AL');
        }
      }, (error) => {
        console.log(error)
      })
    } else {
      this.notifyService.showInfo("please select site", null);
    }
  }

  downloadRouteTemplate() {
    if (this.mSite.value) {
      this.apiService.doGetRequestWithResponse(ApiConstants.getFixedRouteTemplate, success => {
        if (success.status == 200) {
          var bin = atob(success.body.template);
          var ab = this.s2ab(bin);
          var blob = new Blob([ab], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'RouteTemplate.xlsx';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      },
        error => {
          this.notifyService.showError(error.body.error, null);

        });
    }
    else {
      this.notifyService.showInfo("please  select site", null);
    }
  }


  downloadRoutes() {
    if (this.mSite.value) {
      this.apiService.doGetRequestWithResponse(ApiConstants.getRoutes + '?siteid=' + this.mSite.value, success => {
        if (success.status == 200) {
          var bin = atob(success.body.downloadroutes);
          var ab = this.s2ab(bin);
          var blob = new Blob([ab], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'Routes.xlsx';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      },
        error => {
          this.notifyService.showError(error.body.error, null);

        });
    }
    else {
      this.notifyService.showInfo("please  select site", null);
    }
  }

  s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

  expFile(data) {
    var fileText = data;
    var fileName = "routesUploadErrors.txt"
    this.saveTextAsFile(fileText, fileName);
  }

  onFileChange($event) {
    if (this.mSite.value) {
      let files = $event.target.files;
      if (files && files.length > 0) {
        let siteid = this.mSite.value;
        let fileType: string = files[0].type;
        if (fileType) {
          this.routesFile = "";
          if (fileType.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
            let data = "";
            let formdata: FormData = new FormData();
            formdata.append('file', files[0]);
            formdata.append('siteid', siteid);
            this.ngxLoader.start();
            this.apiService.doPostFile(ApiConstants.uploadRoutes, formdata, (success) => {
              this.errorResult = success;
              if (this.errorResult.length == 0) {
                this.ngxLoader.stop();
                this.notifyService.showSuccess("Routes Uploaded Successfully", null);
              } else {
                this.ngxLoader.stop();
                this.notifyService.showSuccess("Routes Upload Completed with " + this.errorResult.length + " errors...", null);
                for (let obj of this.errorResult) {
                  data = data + obj + "\r\n";
                }
                this.expFile(data);
              }
            },
              (error) => {
                this.ngxLoader.stop();
              });
          }
          else {
            this.ngxLoader.stop();
            this.routesFile = "";
            this.notifyService.showError("There seems to be a problem with the file that you uploaded. Please upload a Valid File..", null);
          }

        } else {
          this.ngxLoader.stop();
          this.routesFile = "";
          this.notifyService.showError("There seems to be a problem with the file that you uploaded. Please upload a Valid File..", null);
        }
      }
    } else {
      this.ngxLoader.stop();
      this.routesFile = "";
      this.notifyService.showError("Please Select Site...", null);
    }
  }



  saveTextAsFile(data, filename) {

    if (!data) {
      console.error('Console.save: No data')
      return;
    }

    if (!filename) filename = 'console.json'

    var blob = new Blob([data], { type: 'text/plain' }),
      link = document.createElement('a')
    // FOR IE:

    const nav = window.navigator as any
    if (nav && nav.msSaveOrOpenBlob) {
      nav.msSaveOrOpenBlob(blob, filename);
    }
    else {

      link.download = filename;
      link.href = window.URL.createObjectURL(blob);
      link.dataset.downloadurl = ['text/plain', link.download, link.href].join(':');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }


  onSubmit() {
    if (this._mFormGroup.valid) {
      this._mAreasData = [];
      this._mTempAreasData = [];
      this.ELEMENT_DATA = [];
      this.dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);

      this.apiService.doGetRequestWithResponse(ApiConstants.getroutebysiteidandtype + '?siteid=' + this.mSite.value + '&type=' + this.mRouteType.value, (success) => {

        if (success.status == 200) {
          this._mRoutesData = success.body;
          this._mTempRoutesData = success.body;
        } else {
          this._mRoutesData = [];
          this._mTempRoutesData = [];
          this.notifyService.showError("No data found", null);
        }
      }, (error) => {

      })
    }
  }

  onAdd() {
    if (this.mSite.valid) {
      this.data = {
        siteId: this.mSite.value,
        cityId: this.mCity.value,
      };
      this.addFlag = true;
      this.router.navigate(['./myatom/routing/routing-setup/add-fixed-routing-setup', this.data]);
    }
    else {
      this.notifyService.showInfo('select site for adding route', null);
    }
  }

  onDelete(element, type) {
    let msg;
    let msgKey = AppConstants.DELETE_MSG;
    if (type == "landmark") {
      msg = "Are you sure want to Delete - " + element.landmark + "?";
    } else if (type == "area") {
      msg = "Are you sure want to Delete - " + element.area + "?";
    } else if (type == "route") {
      msg = "Are you sure want to Delete - " + element.routeName + "?";
    }
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger ms-2'
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      title: msg,
      text: msgKey,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
    }).then(result => {
      if (result.value) {
        swalWithBootstrapButtons.fire('Done', msgKey, 'success');
        this.onStatusToggled(element, type);
      }
    })
  }

  onStatusToggled(element, type) {
    if (type == "landmark") {
      this.apiService.doPostRequestWithResponse(ApiConstants.deletelandmarkfromroute + '?routeid=' + this._mTempRouteId + '&areaid=' + this._mTempAreaId + '&landmarkid=' + element.id, '', (success) => {
        if (success.status == 200) {
          this.ELEMENT_DATA = success.body;
          this.dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
          this.notifyService.showSuccess("Deleted successfully", null);
        }
      }, (error) => {

      });
    } else if (type == "area") {

      this.apiService.doPostRequestWithResponse(ApiConstants.deleteareafromroute + '?routeid=' + this._mTempRouteId + '&areaid=' + this._mTempAreaId, '', (success) => {

        if (success.status == 200) {
          this._mAreasData = success.body;
          this._mTempAreasData = success.body;
          this.ELEMENT_DATA = [];
          this.dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
          this.notifyService.showSuccess("Deleted successfully", null);
        }
      }, (error) => {
      })
    } else if (type == "route") {

      this.apiService.doPostRequestWithResponse(ApiConstants.deleteroutebyrouteid + '?routeid=' + this._mTempRouteId + '&areaid=' + this._mTempAreaId, '', (success) => {

        if (success.status == 200) {
          this._mRoutesData = success.body;
          this._mTempRoutesData = success.body;
          this._mAreasData = [];
          this._mTempAreasData = [];
          this.ELEMENT_DATA = [];
          this.dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
          this.notifyService.showSuccess("Deleted successfully", null);
        }
      }, (error) => {


      })
    }

  }

  getParentNode(element) {
    while (!element.classList.contains("main_li")) {
      element = element.parentNode;
    }
    return element;
  }

  hoverFunction($event) {
    let element = this.getParentNode($event.target);
    if (element.getElementsByClassName("rs_list_icons")[0]) {
      element.getElementsByClassName("rs_list_icons")[0].classList.add("showBtn");
    }

  }

  hoverOutFunction($event) {
    let element = this.getParentNode($event.target);
    if (element.getElementsByClassName("rs_list_icons")[0]) {
      element.getElementsByClassName("rs_list_icons")[0].classList.remove("showBtn");
    }
  }

  onSearchKey(searchKey) {
    this.ELEMENT_DATA = this.landMarkData.filter(data => data.landmark.toLowerCase().includes(searchKey.toLowerCase()));
  }
}