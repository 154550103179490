import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiService } from '../../../core/services/api.service';
import { ServiceLocalStorage } from '../../../core/services/local-storage.service';
import { NotificationService } from '../../../core/services/notification.service';
import { ServiceExportExcel } from '../../../core/services/export.service';
import { MatDialog } from '@angular/material/dialog';
import { ApiConstants } from '../../../core/constants/api-path.constants';
import { of } from "rxjs";
import { MatAccordion } from '@angular/material/expansion';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

export interface PeriodicElement {
  employeeId: string,
  employeeName: string,
  gender: string,
  address: string,
  contact: string,
  distance: string,
  time: string,
  showstatus: string,
  adtime: string,
}


@Component({
  selector: 'app-delete-vendor-allocation',
  templateUrl: './delete-vendor-allocation.component.html',
  styleUrls: ['./delete-vendor-allocation.component.scss']
})
export class DeleteVendorAllocationComponent implements OnInit {
  shiftTimeDDSettings = {
    itemsShowLimit: 1,
    idField: "shiftTimeId",
    textField: "shiftTime",
    allowSearchFilter: true,
    searchPlaceholderText: "Search"
  };
  ELEMENT_DATA: PeriodicElement[] = [];
  displayedColumns: string[] =
    // ['date', 'tripCode', 'logType', 'time', 'vehicleType', 'vehicleNo', 'vendor', 'pickUpDropTime', 'actualReporting', 'status', 'plannedUsers', 'travelled', 'noShow'];
    ['employeeId',
      'employeeName',
      'gender',
      'address',
      'contact',
      'distance',
      'time',
      'showstatus',
      'adtime']

      dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
      @ViewChild(MatSort) sort: MatSort;
      @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();

  _mSitesDataFlag: boolean = true;
  _mSitesData: any[] = [];
  _mData: any[] = [];

  tripDetailsList: boolean = false;
  _mIsCheckedList: boolean[] = [];
  date = new Date();
  // _mSitesDataFlag: boolean = false;
  tripSheetflag: boolean = false;
  checkedFlag: boolean = false;

  arrayBuffer: any;
  _mReturnUrl: any;
  siteid: number;
  shiftid: number;
  tripsheet: any;
  tripViewList: any[] = [];
  checkedTrips: any = [];
  tripUsersId: any[] = [];
  tempTripUsersId: any[] = [];
  exceptionEmployee: any[] = [];
  checkedTripsLength: any;
  displayedRows$: any[] = [];


  selectedShiftTimes: any[] = [];
  _mShiftTimeList: any[] = [];
  _mFormGroup: FormGroup;
  private mSite = new FormControl('', Validators.required);
  private mDate = new FormControl('', Validators.required);
  private mLogType = new FormControl('', Validators.required);
  private mShiftTime = new FormControl('', Validators.required);
  userId: any;
  public shiftTimeData: any[] = [];
  logType = [{ "key": "IN" }, { "key": "OUT" }]
  @ViewChild(MatAccordion) accordion: MatAccordion;
  allExpandState: any;
  activeTabId = 1;
  constructor(
    private fb: FormBuilder,
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    private ngxLoader: NgxUiLoaderService,
    private readonly localStorage: ServiceLocalStorage,
    private serviceExportExcel: ServiceExportExcel,
    private dialog: MatDialog
  ) {
    this._mFormGroup = this.fb.group({
      'site': this.mSite,
      'date': this.mDate,
      'logType': this.mLogType,
      'shiftTime': this.mShiftTime
    });
  }

  ngOnInit(): void {
    this.userId = this.localStorage.getUserId();
    this.getActiveSites()
  }

  getActiveSites() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getActiveSitesbyUser + '?userId=' + this.userId, (success) => {
      this._mSitesDataFlag = false;
      if (success.status == 200) {
        this._mSitesData = success.body;
      } else {
        this._mSitesData = [];
      }
    }, (error) => {
      this._mSitesDataFlag = false;
    })
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loadData();
    });
  }

  loadData() {
    this.tripViewList = [];
    this.displayedRows$ = [];
    const rows$ = of(this.tripViewList);
    this.displayedRows$ = this.tripViewList;
  }

  getIndex(item) {
    return this.tripViewList.indexOf(item);
  }

  onLogTypeChange() {
    this.ngxLoader.start();
    this.tripDetailsList = false;
    let url = ApiConstants.getShiftTimesBySiteIdShiftTypeAndLogType
      + "?siteid=" + this.mSite.value + "&logtype=" + this.mLogType.value + "&shifttype=" + 'General';
    this.apiService.doGetRequestWithResponse(url, (success) => {
      this.ngxLoader.stop();
      if (success) {
        this.shiftTimeData = success.body;
        this._mShiftTimeList = this.shiftTimeData;
      } else {
        this.notifyService.showInfo("No shift times found", null);
      }
    }, (error) => {
      this.notifyService.showError(error, null);
      this.ngxLoader.stop();
    })
  }

  minDate = new Date();
  dateChanged(data) {
    this.mDate = data.value;
  }

  onShow() {
    this.ngxLoader.start();
    if (this.mSite.value) {
      if (this.mDate) {
        if (this.mLogType.value) {
          if (this.mShiftTime.value) {
            let shiftList: any[] = [];
            let sTime: any[] = [];
            sTime = [...this.mShiftTime.value]
            sTime.forEach(ele => {
              shiftList.push(ele.shiftTimeId);
            })
            this.tripViewList = [];
            this.displayedRows$ = [];
            const rows$ = of(this.tripViewList);
            this.displayedRows$ = this.tripViewList;
            this.apiService.doPostRequestWithResponse(ApiConstants.deleteVendorAllocation + this.mSite.value + '&date=' + this.formatDate(this.mDate), shiftList, (success) => {
              if (success.status == 200) {
                this._mData = success.body;
                this.ELEMENT_DATA = success.body;
                this.dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
                this.tripsheet = success.body;
                this.checkAll(false);
                this.tripSheetflag = true;
                this.ngxLoader.stop();
                this.tripsheet.forEach(element => {
                  let tripemp: any = [];
                  element.tripDetails.tripUsers.forEach(tripuser => {
                    let vflag = false;
                    tripemp.push({
                      employeeId:
                        tripuser.tripUser.userInfoDetails.employeeId == null
                          ? ""
                          : tripuser.tripUser.userInfoDetails.employeeId,
                      employeeName:
                        tripuser.tripUser.userInfoDetails.displayName == null
                          ? ""
                          : tripuser.tripUser.userInfoDetails.displayName,
                      gender:
                        tripuser.tripUser.userInfoDetails.gender == null
                          ? "NA"
                          : tripuser.tripUser.userInfoDetails.gender,
                      userid: tripuser.tripUser.id,
                      bookingid: tripuser.booking ? tripuser.booking.bookingId : null,
                      address:
                        tripuser.landmark.landmark == null
                          ? "NA"
                          : tripuser.landmark.landmark,
                      distance:
                        tripuser.empTripDistance == null
                          ? "0"
                          : tripuser.empTripDistance,
                      //time: tripuser.pickupDropTime != 0 ? : tripuser.pickupDropTime.splice(0, -3),
                      time: tripuser.pickupDropTime
                        ? tripuser.pickupDropTime.slice(0, -3)
                        : "00:00",
                      userColor:
                        tripuser.tripUser.userInfoDetails.userCategory[0] == null
                          ? "#006aa8"
                          : tripuser.tripUser.userInfoDetails.userCategory[0]
                            .categories.colorCode,
                      medical: vflag,
                      showstatus:
                        tripuser.showStatus == null ? "NO SHOW" : tripuser.showStatus,
                      contact:
                        tripuser.tripUser.userInfoDetails.contactNumber == null
                          ? "NA"
                          : tripuser.tripUser.userInfoDetails.contactNumber,
                      adtime:
                        element.tripDetails.shift.logType == "IN"
                          ? tripuser.arrivalDateTime
                          : tripuser.boardedDateTime
                    });
                  });

                  this.tripViewList.push({
                    tripid:
                      element.tripDetails.tripId == null
                        ? ""
                        : element.tripDetails.tripId,
                    tripCode:
                      element.tripDetails.tripCode == null
                        ? "NA"
                        : element.tripDetails.tripCode,
                    vehicleNo:
                      element.vehicle == null ? "NA" : element.vehicle.registrationNo,
                    Vendor:
                      element.vendorMaster == null ? "NA" : element.vendorMaster.name,
                    Driver: element.driver == null ? "NA" : element.driver.name,
                    Number:
                      element.driver == null ? "NA" : element.driver.contactNumber,
                    pickupDropPoint:
                      element.tripDetails.shift.logType == "IN"
                        ? element.tripDetails.tripUsers[0].landmark.area == null
                          ? "NA"
                          : element.tripDetails.tripUsers[0].landmark.area.area
                        : element.tripDetails.tripUsers[tripemp.length - 1].landmark
                          .area == null
                          ? "NA"
                          : element.tripDetails.tripUsers[tripemp.length - 1].landmark
                            .area.area,
                    vehicleType: element.vehicleType.vehicleType,
                    escort: element.tripDetails.escortStatus == false ? "NO" : "YES",
                    distance:
                      element.tripDetails.tripDistance == null
                        ? "0"
                        : element.tripDetails.tripDistance,
                    seatCapacity: element.vehicleType.seatCapacity,
                    seatsOccupied:
                      tripemp.length == null
                        ? "0"
                        : tripemp.length + (element.tripDetails.escortStatus ? 1 : 0),
                    tripemp: tripemp,
                    escortname: element.escort == null ? "" : element.escort.name,
                    alloacateVehicle:
                      element.vehicle == null
                        ? "NA"
                        : element.vehicle.vehicleType.vehicleType,
                    triptraveltime: element.tripDetails.tripTravelTime,
                    shift:
                      element.tripDetails.shift.shiftTime +
                      " " +
                      element.tripDetails.shift.logType,
                    date: element.tripDetails.tripDate.split(" ")[0],
                    seatsOccupiedPercent: (
                      (element.tripDetails.escortStatus
                        ? 1
                        : 0 + tripemp.length / element.vehicleType.seatCapacity) * 100
                    ).toFixed(2),
                    tatd:
                      element.tripDetails.shift.logType == "IN"
                        ? element.startDateTime
                        : element.stopDateTime
                  });
                });

              } else {
                this._mData = [];
                this.ELEMENT_DATA = [];
                this.dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
                this.tripSheetflag = false;
                this.ngxLoader.stop();
                this.notifyService.showWarning("No Trips Found", null);
              }
            },
              error => {
                this.ngxLoader.stop();
                this.notifyService.showWarning("No Trips Found", null);
              }
            );
          } else {
            this.notifyService.showWarning("Please select the Shift time", null);
          }
        } else {
          this.notifyService.showWarning("Please select the Log type", null);
        }
      } else {
        this.notifyService.showWarning("Please select the Date", null);
      }
    } else {
      this.notifyService.showWarning("Please select the site", null);
    }

  }


  checkTrip(event, tripid) {
    if (event.checked) {
      this.checkedTrips.push(tripid);
    } else {
      let index: number = this.checkedTrips.indexOf(tripid);
      if (index !== -1) {
        this.checkedTrips.splice(index, 1);
      }
    }
  }

  checkAll(event) {
    this.checkedTrips = [];
    if (event.checked) {
      this.tripViewList.forEach(trip => {
        this.checkedTrips.push(trip.tripid);
        this.checkedTripsLength = this.checkedTrips.length;
      })
      this.checkedFlag = true;
    } else {
      this.tripViewList.forEach(trip => {
        let index: number = this.checkedTrips.indexOf(trip.tripid);
        if (index !== -1) {
          this.checkedTrips.splice(index, 1);
        }
      })
      this.checkedFlag = false;
    }
  }


  onExportToExcel(): void {
    let exceldata = [];
    let data: any = {};
    let number = 1;
    if (this.tripViewList.length > 0) {
      this.notifyService.showInfo("Please wait...", null);
      this.tripViewList.forEach(element => {
        exceldata.push({
          tripCode: element.tripCode,
          vehicleNo: element.vehicleNo,
          vendor: element.Vendor,
          driver: element.Driver,
          contactNo: element.Number,
          location: element.pickupDropPoint,
          vehicleType: element.vehicleType,
          escortRequired: element.escort,
          escortName: element.escortname,
          distance: element.distance,
          seatCapacity: element.seatCapacity,
          seatOccupied: element.seatsOccupied,
          allocateVehicle: element.alloacateVehicle,
          tripTravelTime: element.triptraveltime,
          site: this._mSitesData[0].siteName,
          shift: element.shift,
          date: element.date,
          seatOccupiedPercent: element.seatsOccupiedPercent
        });

        exceldata.push({
          tripCode: "EmployeeId",
          vehicleNo: "EmployeeName",
          vendor: "Gender",
          driver: "Distance",
          contactNo: "Time",
          location: "Medical",
          vehicleType: "TravelStatus",
          escortRequired: "Contact",
          escortName: "Address"
        });
        element.tripemp.forEach(element => {
          exceldata.push({
            tripCode: element.employeeId,
            vehicleNo: element.employeeName,
            vendor: element.gender,
            driver: element.distance,
            contactNo: element.time,
            location: element.medical,
            vehicleType: element.showstatus,
            escortRequired: element.contact,
            escortName: element.address
          });
        });
        if (number < this.tripViewList.length) {
          exceldata.push(
            {},
            {
              tripCode: "tripCode",
              vehicleNo: "vehicleNo",
              vendor: "vendor",
              driver: "driver",
              contactNo: "contactNo",
              location: "location",
              vehicleType: "vehicleType",
              escortRequired: "escortRequired",
              escortName: "escortName",
              distance: "distance",
              seatCapacity: "seatCapacity",
              seatOccupied: "seatOccupied",
              allocateVehicle: "allocateVehicle",
              tripTravelTime: "tripTravelTime",
              site: "site",
              shift: "shift",
              date: "date",
              seatOccupiedPercent: "seatOccupiedPercent"
            }
          );
        }
        number++;
      });
      this.serviceExportExcel.exportAsExcelFile(exceldata, "VendorAssignedTrips");
    } else {
      this.notifyService.showInfo("No data found", null);
    }
  }

  onDelete() {
    if (this.checkedTrips.length < 1) {
      this.notifyService.showWarning("Select atleast one trip to delete", null);
    } else {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success mt-5',
          cancelButton: 'btn btn-danger ms-2 mt-5'
        },
        buttonsStyling: false
      });
      swalWithBootstrapButtons.fire({
        title: 'WARNING : Are you sure want to delete the selected Trips?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
      }).then((result) => {
        if (result.value) {
          this.ngxLoader.start();
          this.apiService.doPostRequestWithResponse(ApiConstants.deleteAllocatedtrip, this.checkedTrips, (success) => {
            this.ngxLoader.stop();
            if (success.status == 202) {
              this.checkedTrips = [];
              this.notifyService.showSuccess(success.body.message, null)
              this.onShow();
            }
          }, (error) => {
            this.ngxLoader.stop();
          })
        } else if (result.dismiss) {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your selected Trips are safe :)',
            'error'
          )
        }
      })
    }
  }

  percentage4BgColor(p) {
    if (p <= 30) {
      return "#f32727";
    }
    if (p > 30 && p <= 50) {
      return "#ff6262";
    }
    if (p > 50 && p <= 90) {
      return "#cc9595";
    }
  }

  formatDate(d): any {
    let m = d.getMonth() + 1;
    let dt = (d.getDate() < 10) ? '0' + d.getDate() : d.getDate();
    let mm = (m < 10) ? '0' + m : m;
    //return d.getFullYear() + '-' + mm + '-' + dt;
    return dt + '/' + mm + '/' + d.getFullYear();
  }

}