import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UiSwitchModule } from 'ngx-ui-switch';
import { BasicTableComponent } from './basic-table/basic-table.component';
import { AuditLogComponent } from './audit-log/audit-log/audit-log.component';
import { ALSearchFilter, AllocatedTripFilter, DDSearchFilterUsersPipe, DriverSearchFilter, SearchFilterPipe, SearchFilterUsersPipe, VehicleDriverSearchFilter, VehicleSearchFilter, ZoneSearchFilter, TripSearchFilter } from './utils/search-filter-pipe';
import { UploadExcelErrorDisplayComponent } from './upload-excel-error-display/upload-excel-error-display.component';
import { ValuesDialogBoxComponent } from './audit-log/audit-log/values-component/values-dialog-box/values-dialog-box.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    BasicTableComponent, 
    AuditLogComponent, 
    SearchFilterPipe,
    SearchFilterUsersPipe,
    DDSearchFilterUsersPipe,
    DriverSearchFilter,
    ZoneSearchFilter,
    ALSearchFilter,
    VehicleSearchFilter,
    AllocatedTripFilter,
    VehicleDriverSearchFilter,
    TripSearchFilter,
    UploadExcelErrorDisplayComponent,
    ValuesDialogBoxComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgbTooltipModule,
    UiSwitchModule.forRoot({ size: 'small' })
  ],
  exports: [
    BasicTableComponent,
    SearchFilterPipe,
    SearchFilterUsersPipe,
    DDSearchFilterUsersPipe,
    DriverSearchFilter,
    ZoneSearchFilter,
    ALSearchFilter,
    VehicleSearchFilter,
    AllocatedTripFilter,
    VehicleDriverSearchFilter,
    TripSearchFilter
  ]
})
export class SharedModule { }
