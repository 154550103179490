import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class ServiceSharedData {
    static GLOBAL = {
        sites: "sites",
        vendors: "vendors"
    }

    static VEHICLE = {
        currentListPageNo: "vehicle.PageNo",
        currentListTotalPage: "vehicle.totalPage",
        currentListHeaders: "vehicle.headers",
        currentList: "vehicle.currentList",
        activeSite: "vehicle.activeSite",
        activeVendor: "vehicle.activendor",
        editVehicle: "vehicle.edit",
        isEdit: "vehicle.isEdit",
        isNavigatedForEdit: "vehicle.isNavigatedForEdit",
        isEditSubmitted: "vehicle.isEditSubmitted"
    }

    static TEMPVEHICLE = {
        vehicle: "vehicle"
    }

    static TEMPSITE = {
        site: "site"
    }

    static MASTERCONFIG = {
        currentListPageNo: "masterConfig.PageNo",
        currentListTotalPage: "masterConfig.totalPage",
        currentListHeaders: "masterConfig.headers",
        currentList: "masterConfig.currentList",
        editMasterConfig: "masterConfig.edit",
        isEdit: "masterConfig.isEdit",
        isEditSubmitted: "masterConfig.isEditSubmitted"
    }

    static SUBSCRIPTION_SETUP = {
        currentListPageNo: "subscriptionSetup.PageNo",
        currentListTotalPage: "subscriptionSetup.totalPage",
        currentListHeaders: "subscriptionSetup.headers",
        currentList: "subscriptionSetup.currentList",
        editSubscriptionSetup: "subscriptionSetup.edit",
        isEdit: "subscriptionSetup.isEdit",
        isEditSubmitted: "subscriptionSetup.isEditSubmitted"
    }

    static SUBSCRIPTION = {
        currentListPageNo: "subscription.PageNo",
        currentListTotalPage: "subscription.totalPage",
        currentListHeaders: "subscription.headers",
        currentList: "subscription.currentList",
        editSubscription: "subscription.edit",
        isEdit: "subscription.isEdit",
        isEditSubmitted: "subscription.isEditSubmitted"
    }

    static VIEWROLE = {
        currentListPageNo: "viewrole.PageNo",
        currentListTotalPage: "viewrole.totalPage",
        currentListHeaders: "viewrole.headers",
        currentList: "viewrole.currentList",
        editSubscription: "viewrole.edit",
        isEdit: "viewrole.isEdit",
        isEditSubmitted: "viewrole.isEditSubmitted"
    }

    static USER = {
        currentListPageNo: "user.PageNo",
        currentListTotalPage: "user.totalPage",
        currentListHeaders: "user.headers",
        currentList: "user.currentList",
        activeSite: "user.activeSite",
        activeRole: "user.activeRole",
        editUser: "user.edit",
        isEdit: "user.isEdit",
        isEditSubmitted: "user.isEditSubmitted",
        isProfileEdit: "user.isProfileEdit"
    }

    static ADHOC = {
        currentListPageNo: "adhoc.PageNo",
        currentListTotalPage: "adhoc.totalPage",
        currentListHeaders: "adhoc.headers",
        currentList: "adhoc.currentList",
        editUser: "adhoc.edit",
        isEdit: "adhoc.isEdit",
        isEditSubmitted: "adhoc.isEditSubmitted"
    }

    static BOOKING_SETUP = {
        currentListPageNo: "user.PageNo",
        currentListTotalPage: "user.totalPage",
        currentListHeaders: "user.headers",
        currentList: "user.currentList",
        activeSite: "user.activeSite",
        activeRole: "user.activeRole",
        editUser: "user.edit",
        isEdit: "user.isEdit",
        isEditSubmitted: "user.isEditSubmitted"
    }

    static VEHICLE_SITE = {
        currentListPageNo: "vehicle-site.PageNo",
        currentListTotalPage: "vehicle-site.totalPage",
        currentList: "vehicle-site.currentList",
        activeVehicleIndex: "vehicle-site.activeIndex",
        activeVehicleSites: "vehicle-site.activeSites",
        isEdit: "vehicle-site.isEdit",
        isMappingDone: "vehicle-site.isMappingDone"
    }

    static VEHICLE_DRIVER = {
        currentListPageNo: "vehicle-driver.PageNo",
        currentListTotalPage: "vehicle-driver.totalPage",
        vendorMasters: "vehicle-driver.vendorMasters",
        activeVendorMaster: "vehicle-driver.activeVendor",
        currentList: "vehicle-driver.currentList",
        activeVehicleIndex: "vehicle-driver.activeIndex",
        activeVehicleDrivers: "vehicle-driver.activeDrivers",
        isEdit: "vehicle-driver.isEdit",
        isMappingDone: "vehicle-driver.isMappingDone"
    }

    static DRIVER_VEHICLE = {
        currentListPageNo: "driver-vehicle.PageNo",
        currentListTotalPage: "driver-vehicle.totalPage",
        vendorMasters: "driver-vehicle.vendorMasters",
        activeVendorMaster: "driver-vehicle.activeVendor",
        currentList: "driver-vehicle.currentList",
        activeDriverIndex: "driver-vehicle.activeIndex",
        activeDriverVehicles: "driver-vehicle.activeVehicles",
        isEdit: "driver-vehicle.isEdit",
        isMappingDone: "driver-vehicle.isMappingDone"
    }

    static COMPANY_SETUP = {
        mapSitestoLocation: "company-setup.locSites",
        currentSiteEdit: "company-setup.currentSiteEdit",
        isSiteEdit: "company-setup.isSiteEdit",
        isSiteEditDone: "company-setup.isSiteEditDone",
        currentActiveSiteId: "company-setup.currentActiveSiteId"
    }

    static SHIFT_TIME = {
        selectedItems: "shift-time.selectedItems",
        isSelectionDone: "shift-time.isSelectionDone"
    }

    static CURRENT_USER = {
        company: "current-user.company"
    }

    static TRIP_GENERATION = {
        locationsList: "trip-generation.locationsList",
        sitesList: "trip-generation.sitesList",
        location: "trip-generation.location",
        site: "trip-generation.site",
        date: "trip-generation.date",
        shiftType: "trip-generation.shiftType",
        shiftId: "trip-generation.shiftId",
        tripViewFlag: "trip-generation.tripViewFlag",
        saveTripMirroringFlag: "trip-generation.saveTripMirroringFlag"
    }

    static SELECT_VEHICLE = {
        selectedItems: "select-vehicle.selectedItems",
        isSelectionDone: "select-vehicle.isSelectionDone",
        vendorMaster: "select-vehicle.vendorMaster"
    }

    static DRIVER = {
        currentListPageNo: "driver.PageNo",
        currentListTotalPage: "driver.totalPage",
        currentListHeaders: "driver.headers",
        currentList: "driver.currentList",
        activeSite: "driver.activeSite",
        activeVendor: "driver.activendor",
        editDriver: "driver.edit",
        isEdit: "driver.isEdit",
        isNavigatedForEdit: "driver.isNavigatedForEdit",
        isEditSubmitted: "driver.isEditSubmitted"
    }

    static VENDORMASTER = {
        currentListPageNo: "vendormaster.PageNo",
        currentListTotalPage: "vendormaster.totalPage",
        currentListHeaders: "vendormaster.headers",
        currentList: "vendormaster.currentList",
        activeSite: "vendormaster.activeSite",
        editVendormaster: "vendormaster.edit",
        isEdit: "vendormaster.isEdit",
        isNavigatedForEdit: "vendormaster.isNavigatedForEdit",
        isEditSubmitted: "vendormaster.isEditSubmitted"
    }

    static NOTIFICATION = {
        currentListPageNo: "notification.PageNo",
        currentListTotalPage: "notification.totalPage",
        currentListHeaders: "notification.headers",
        currentList: "notification.currentList",
        editNotification: "notification.edit",
        isEdit: "notification.isEdit",
        isEditSubmitted: "notification.isEditSubmitted"
    }

    static MIRRORGENDATA = {
        tripGenData: "tripGenData"
    }

    static TRIP_MIRRORING_SETUP = {
        currentListPageNo: "tripMirroringSetup.PageNo",
        currentListTotalPage: "tripMirroringSetup.totalPage",
        currentListHeaders: "tripMirroringSetup.headers",
        currentList: "tripMirroringSetup.currentList",
        editTripMirroringSetup: "tripMirroringSetup.edit",
        isEdit: "tripMirroringSetup.isEdit",
        isEditSubmitted: "tripMirroringSetup.isEditSubmitted"
    }

    static VENDOR_ALLOCATION_SETUP = {
        currentListPageNo: "vendorAllocationSetup.PageNo",
        currentListTotalPage: "vendorAllocationSetup.totalPage",
        currentListHeaders: "vendorAllocationSetup.headers",
        currentList: "vendorAllocationSetup.currentList",
        editVendorAllocationSetup: "vendorAllocationSetup.edit",
        isEdit: "vendorAllocationSetup.isEdit",
        isEditSubmitted: "vendorAllocationSetup.isEditSubmitted"
    }

    static siteShiftMappingId: any;
    AUTH_TOKEN = "auth-token";
    authToken: string = undefined;
    data: any[];

    constructor() {
        this.data = [];
    }

    setData(key: string, pData: any) {
        this.data[key] = pData;
    }

    getData(key) {
        if (key in this.data) {
            return this.data[key];
        }
        else undefined;
    }

    setMapData(key: string, mapKey: string, pData: any): boolean {
        if (!(mapKey))
            return false;
        let map;
        if (this.data[key]) {
            map = this.data[key];
        } else {
            this.data[key] = {};
            map = this.data[key];
        }
        map[mapKey] = pData;
        return true;

    }


    getMapData(key: string, mapKey: string) {
        if (key in this.data) {
            let data = this.data[key];
            if (mapKey in data) {
                return data[mapKey];
            }
        }
        else undefined;
    }

    removeMap(key: string, mapKey: string) {

        if (key in this.data && this.data[key]) {
            if (mapKey in this.data[key] && this.data[key][mapKey]) {
                delete this.data[key][mapKey];
            }
        }
    }

    remove(key) {

        if (key in this.data && this.data[key]) {
            delete this.data[key];
        }
    }

    validateNumber(e) {
        if (!((e.keyCode > 95 && e.keyCode < 106)
            || (e.keyCode > 47 && e.keyCode < 58)
            || e.keyCode == 8)) {
            return false;
        }
    }

    omit_special_char(event) {
        let k;
        k = event.charCode;  //         k = event.keyCode;  (Both can be used)
        return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
    }

}
