
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject, Subscription } from 'rxjs';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { AppConstants } from 'src/app/core/constants/appconstants';
import { ApiService } from 'src/app/core/services/api.service';
import { ServiceExportExcel } from 'src/app/core/services/export.service';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ModelTableHoverDetail, ModelTableHoverDetailItem, ModelTableOptionSelected } from 'src/app/shared/models/models';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { AuditLogComponent } from 'src/app/shared/audit-log/audit-log/audit-log.component';
import { ModelTableHeader } from 'src/app/shared/models/table-header';
import { ModelSite } from 'src/app/shared/models/site';
import { Router } from '@angular/router';

@Component({
  selector: 'app-routing-setup',
  templateUrl: './routing-setup.component.html',
  styleUrls: ['./routing-setup.component.scss']
})
export class RoutingSetupComponent implements OnInit {

  public mCity = new FormControl('', Validators.required);
  public mSite = new FormControl('', Validators.required);
  public mPriorityOptimization = new FormControl('optimization', Validators.required);
  public mTransportSLA = new FormControl('', [Validators.required, Validators.pattern("^[0-9]*")]);
  public mAcceptableDiversion = new FormControl('', [Validators.required, Validators.pattern("^[0-9]*")]);
  public mAllowTripGeneration = new FormControl(false);
  public mRoutingType = new FormControl('', Validators.required);
  public mAllowProActiveRouting = new FormControl(false);
  public mAllowTripGenerationOutOfBoundry = new FormControl(false);
  public mAllowDriverToMarkNoShow = new FormControl(false);
  public mShift = new FormControl("");
  public mAllowStopTripToVendor = new FormControl(false);
  shiftDropDownSettings = {};
  public routingData: any;
  shiftData: any[] = [];
  shiftMapping: boolean = false;
  public siteData: ModelSite[] = [];
  title: any;
  public _mFormGroup: FormGroup;
  public _mFormGroup1: FormGroup;
  _mColumnsRaw: ModelTableHeader[];
  firstStepForProject: boolean;
  mIsEdit: boolean = false;
  tempSearchKey: any = "";
  searchSubject$ = new Subject<string>();
  _mPreviousPageNumber: number = 0;
  page: number = 0;
  editData: any;
  isFileUploaded: any = false;
  projectFile: string;
  fileUploadChange: any;
  fileUploadSummary: any = {};
  projectList: any[] = [];
  projectListData: any[] = [];
  searchBarFlag: boolean = false;
  searchEdit: boolean = true;
  searchData: any = '';
  public _mColumns: any = [];
  public _mData: any = [];
  _mSitesData: any;
  editFlag: boolean = false;
  _mRolesData: any;
  userId: any
  monthlyCharges: any;
  roles: any;
  updateSubscriptionId: any;
  isDropdownDisabled: boolean = false;
  isChecked: boolean;
  isWeeklySchedulekChecked: boolean;
  isTripCancelChecked: boolean;
  private _mTitle: string;
  _mRawData: any[];
  cities: any;
  sites: any = [];
  _mSearchKey: string = '';
  _mTableOptions = ["Edit"];
  _mIsLoading = true;
  _mIsNoPagination = false;
  toggleStatus = true;
  _mDataRaw: any = [];
  _mSiteSelected: ModelSite[];
  tableData: any;
  mRoutingTyes: any[] = [{ "value": "ALGORITHM" }, { "value": "FIXED" }];
  cityFlag: boolean = true;
  userData: any;
  @Input() cityName: any;
  subcribeEdit: boolean = false;
  auditFlag: boolean = false;

  constructor(private fb: FormBuilder,
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    private readonly serviceExportExcel: ServiceExportExcel,
    private router: Router,
    private readonly localStorage: ServiceLocalStorage,
    public readonly dialog: MatDialog,
    private ngxLoader: NgxUiLoaderService
  ) {
    this._mFormGroup = this.fb.group({
      'city': this.mCity
    });
  }

  ngOnInit(): void {
    this.loadCities();
  }

  loadCities() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getLocationsByCompanyIdAndUser + this.localStorage.getCompanyId(), success => {
      this.cities = success.body;
      this.mCity.setValue(success.body[0].locationId);
      if (this.mCity.value) {
        this.getAll();
      }
    }, (error) => {
    });
  }

  oncitychange() {
    this.getAll();
  }

  getAll() {
    this.cityFlag = true;
    this.firstStepForProject = false;
    this.ngxLoader.start();
    this.apiService.doGetRequestWithResponse(ApiConstants.getAllRoutingConfigBylocationIdv3 + this.mCity.value, (success) => {
      if (success.status == 200) {
        this.ngxLoader.stop();
        this._mDataRaw = success.body;
        this._mColumns = [
          {
            'label': 'Site',
            'key': 'siteName'
          },
          {
            'label': 'Priority optimization',
            'key': 'priorityOptimization'
          },
          {
            'label': 'Acceptable diversion',
            'key': 'acceptableDiversion'
          },
          {
            'label': ' Allow TripGeneration',
            'key': 'allowTripGeneration'
          },

          {
            "label": 'Transport SLA',
            'key': 'transportSLA'
          },


          {
            'label': 'Routing type',
            'key': 'routingType'
          },


          {
            'label': 'Status',
            'key': 'status'
          },
        ];
        this.subcribeEdit = true;
        this.auditFlag = true;
        this._mData = this.setDataLocally(this._mDataRaw);
        this.tableData = this._mData;
        this.setLocalHeaders();
      } else {
        this.ngxLoader.stop();
        this.tableData = [];
        this._mData = [];
        this._mColumns = [];
        this._mDataRaw = [];
        this.subcribeEdit = false;
        this.auditFlag = false;
        this.notifyService.showInfo("No data found", null);
      }
    }, (error) => {
      this.ngxLoader.stop();
      this.notifyService.showError(error.message, null);
      this._mIsLoading = false;
    })
  }

  setDataLocally(data) {
    let routingConfigs: any = [];
    data.forEach((routingConfig, i) => {
      let routingConfigLoc: any = {};
      routingConfigLoc.id = routingConfig.id;
      routingConfigLoc.siteId = routingConfig.site.siteId;
      routingConfigLoc.siteName = routingConfig.site.siteName;
      routingConfigLoc.priorityOptimization = routingConfig.priorityOptimization;
      routingConfigLoc.acceptableDiversion = routingConfig.acceptableDiversion;
      routingConfigLoc.allowTripGeneration = routingConfig.allowTripGeneration;
      routingConfigLoc.transportSLA = routingConfig.transportSLA;
      routingConfigLoc.routingType = routingConfig.routingType;
      routingConfigLoc.status = routingConfig.status;
      // routingConfigLoc.shifts = routingConfig.shifts;
      routingConfigs.push(routingConfigLoc);
    });
    return routingConfigs;
  }

  setLocalHeaders() {
    this._mColumns.forEach((obj, i) => {
      (obj.key == 'site.siteName') ? obj.key = 'siteName' : null;
    });
  }

  onAddNew() {
    this.userData = '';
    this.editFlag = true;
  }


  onStatusChanged(data) {
    let msg = "WARNING : Are you sure you want to Disable this Routing Setup?";
    let msgKey = AppConstants.DEACTIVATE_MSG;
    if (data.option.status == AppConstants.DEACTIVE_STATUS) {
      msg = "WARNING : Are you sure you want to Enable Routing Setup?";
      msgKey = AppConstants.ACTIVATE_MSG;
    }
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger ms-2'
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      title: msg,
      text: msgKey,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
    }).then(result => {
      if (result.value) {
        swalWithBootstrapButtons.fire('Done', msgKey, 'success');
        this.onStatusToggled(data);
      } else {
        swalWithBootstrapButtons.fire('Cancelled', 'Deactivated', 'error');
        this.getAll();
      }
    });
  }


  discard_special_char_And_String(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }


  onStatusToggled(data) {
    let status = data.option.status == AppConstants.ACTIVE_STATUS ? AppConstants.DEACTIVE_STATUS : AppConstants.ACTIVE_STATUS;
    data.option.status = status;
    this.ngxLoader.start();
    this.apiService.doPostRequestWithResponse(ApiConstants.enabledisableRouting + '?id=' + data.option.id + '&status=' + status, '', (success) => {
      if (success) {
        this.ngxLoader.stop();
        this.notifyService.showSuccess(success.body.message, null);
        this.getAll();

      }
    }, (error) => {
      this.ngxLoader.stop();
      console.log(error)
      this.getAll();

    });
  }

  getSetupView($event) {
    this.editFlag = false;
  }

  onEditIconClicked($event: ModelTableOptionSelected) {
    if ($event.index >= 0) {
      this.editFlag = true;
      this.userData = this._mDataRaw[$event.index];
    }
  }

  getAudit(e) {
    this.ngxLoader.start();
    this.apiService.doGetRequestWithResponse(ApiConstants.routingSetupAudit + e.option.id, (success) => {
      this.ngxLoader.stop();
      if (success.status == 200) {
        let data = success.body;
        let object = Object.entries(e.option);
        data.data.push(object[2]);
        this.dialog.open(AuditLogComponent, {
          autoFocus: false,
          maxHeight: '80vh',
          data: data,
          disableClose: true
        });
      } else {
        this.notifyService.showWarning("No audit logs found", null);
      }
    }, (error) => {
      this.ngxLoader.stop();
      this.notifyService.showWarning("No audit logs found.", null);
    });
  }

  onSearchKey(searchKey) {
    if (searchKey) {
      this._mData = this.tableData.filter(data => data.siteName.toLowerCase().includes(searchKey.toLowerCase()));
      if (this._mData.length == 0) {
        this._mColumns = [];
        this.subcribeEdit = false;
        this.auditFlag = false;
      }
    } else {
      this.getAll();
    }

  }


}



