import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NewRegistrationRoutingModule } from './new-registration-routing.module';
import { AddNewUserDetailsComponent } from './add-new-user-details/add-new-user-details.component';
import { FlexModule, FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiSwitchModule } from 'ngx-ui-switch';
import { MaterialModule } from 'src/app/material.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { RoutingModule } from '../pages/routing/routing.module';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [
    AddNewUserDetailsComponent
  ],
  imports: [
    CommonModule,
    NewRegistrationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FlexModule,
    FlexLayoutModule,
    MaterialModule,
    UiSwitchModule,
    TranslateModule,
    RouterModule,
    RoutingModule,
    SharedModule,
    HttpClientModule
  ]
})
export class NewRegistrationModule { }
