<div *ngIf="showDiv">
    <div fxLayout="row" fxLayoutAlign="start center" (scroll)="onScroll($event)">
        <span class="font-size-14 ms-3 mt-2" fxFlex>{{_mTitle}}</span>
        <span class="material-icons-outlined"
            style="color:#747679; font-size: 25px; float: right; position: relative; cursor: pointer"
            (click)="onClickCancel()"> close</span>
    </div>
    <hr>
    <div class="font-size-13 ms-3 mt-2">
        <span>Total Records-{{totalrecordscount}}</span>
    </div>

    <div class="font-size-13 ms-3 mt-2">
        <span>Saved Records-{{totaluploadcount}}</span>
    </div>

    <div *ngIf="errList.length > 0">
        <div class="col-md-3" fxLayout="row" fxLayoutAlign="start center">
            <span class="font-size-13 ms-3 mt-2 me-2">Errors:</span>
            <span class="material-icons-outlined" matTooltip="Download as Excel" (click)="requiestExcel()">
                file_download
            </span>
        </div>
        <div class="font-size-13 ms-3 mt-2" *ngFor="let errorList of errList">
            <span>{{errorList.comments}}</span>
        </div>
    </div>

    <div class="font-size-13 ms-3 mt-2" *ngIf="errList.length == 0">
        <span>Upload Success</span>
    </div>
</div>