import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { AppConstants } from 'src/app/core/constants/appconstants';
import { ApiService } from 'src/app/core/services/api.service';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AuditLogComponent } from 'src/app/shared/audit-log/audit-log/audit-log.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-navigation-setup',
  templateUrl: './navigation-setup.component.html',
  styleUrls: ['./navigation-setup.component.scss']
})
export class NavigationSetupComponent implements OnInit {
  projectFlag: boolean = true;
  firstStepForProject: boolean = false;
  title: any;
  cities: any;
  pageNo: number = 0;
  public _mData: any = [];
  public _mTotalPage: number;
  public _mSize: number;
  public _mTotalElements: number;
  public _mNumberOfElements: number;
  public _mColumns: any = [];
  public _mDataRaw: any = [];
  public mCity = new FormControl('');
  public mSite = new FormControl('');
  public mRoutingType = new FormControl('');
  public mShift = new FormControl('');
  mSites: any;
  public _mFormGroup: FormGroup;
  public _mFormGroup1: FormGroup;
  selectedCity: number;
  shiftData: any;
  companyid: number = +this.localStorage.getCompanyId();
  selectedTime: any;
  selectedTimeId: any[] = [];
  editFlag: boolean = false;
  shiftMapping: boolean = false;
  shiftFlag: boolean = false;
  editData: any;
  ELEMENT_DATA: any[] = [];
  searchData: any = '';
  isEmpty: boolean = false;
  selectedSite: any;
  mRoutingTypes: any[] = [{ "value": "COMMON" }, { "value": "HOME POINT" }, { "value": "ALGORITHM" }, { "value": "FIXED" }]
  subcribeEdit: boolean = false;
  auditFlag: boolean = false;

  constructor(private fb: FormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private readonly apiService: ApiService,
    private readonly localStorage: ServiceLocalStorage,
    private notifyService: NotificationService,
    public readonly dialog: MatDialog) { }

  ngOnInit(): void {
    this._mFormGroup = this.fb.group({
      'city': this.mCity
    });
    this._mFormGroup1 = this.fb.group({
      'site': this.mSite,
      'routingType': this.mRoutingType,
      'shift': this.mShift
    })
    this.getAllCities();
    this.getSites();
  }
  addProject() {
    this.title = 'Add';
    this.ngxLoader.start();
    this.editFlag = false;
    this.shiftFlag = false;
    this.firstStepForProject = true;
    this.projectFlag = false;
    this.mSite.setValue('');
    this.mRoutingType.setValue('');
    this.mShift.setValue('');
    this.ngxLoader.stop();
  }

  onEditIconClicked(data) {
    this.title = 'Edit';
    this.editFlag = true;
    this.projectFlag = false;
    this.firstStepForProject = true;
    this.shiftFlag = true;
    this.ngxLoader.start();
    this.editData = this._mDataRaw[data.index];
    this.mSite.setValue(this.editData.site.siteId);
    this.mRoutingType.setValue(data.option.routingType);
    this.mShift.setValue('');
    this.getShiftsTimes(this.editData.site.siteId);
    this.ngxLoader.stop();
  }

  onBackOfProject() {
    this.title = 'Add';
    this.ngxLoader.start();
    this.firstStepForProject = this.firstStepForProject == true ? false : true;
    this.projectFlag = this.projectFlag == true ? false : true;
    this.ngxLoader.stop();
  }

  getAllCities() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getLocationsByCompanyIdAndUser + this.companyid, (success) => {
      this.cities = success.body;
        this.mCity.setValue(this.cities[0].locationId);
        this.selectedCity = this.cities[0].locationId;
        if (this.mCity.value) {
          this.onLocationChange(this.mCity.value);
        }
    }, (error) => {
      this.notifyService.showError(error.message, null);
    })
  }

  onLocationChange(locationId) {
    this.apiService.doGetRequestWithResponse(ApiConstants.getNavigationSetupByLocationId + "?locationId=" + locationId + "&page=" + this.pageNo + "&searchKey=" + "", (success) => {
      if (success.status == 200) {
        this._mDataRaw = success.body.dataList.content;
        this._mData = this.setDataLocally(this._mDataRaw);
        this.ELEMENT_DATA = this._mData;
        this.subcribeEdit = true;
        this.auditFlag = true;
        this._mTotalPage = success.body.dataList.totalPages;
        this._mSize = success.body.dataList.size;
        this._mTotalElements = success.body.dataList.totalElements;
        this._mNumberOfElements = success.body.dataList.numberOfElements;
        this._mColumns = success.body.headers;
        this.isEmpty = false;
        this.setLocalHeaders();
      } else {
        this._mData = [];
        this._mDataRaw = [];
        this._mTotalPage = 0;
        this._mSize = 0;
        this._mTotalElements = 0;
        this._mNumberOfElements = 0;
        this._mColumns = [];
        this.ELEMENT_DATA = [];
        this.isEmpty = true;
        this.subcribeEdit = false;
        this.auditFlag = false;
        this.notifyService.showInfo("No Data Found", null);
      }
    }, (error) => {
      this.notifyService.showError(error.message, null);
    })
  }

  setDataLocally(data) {
    let navigationSetupList: any = [];
    data.forEach((navigation, i) => {
      let navigationSetup: any = {};
      navigationSetup.id = navigation.id;
      navigationSetup.siteId = navigation.site.siteId;
      navigationSetup.siteName = navigation.site.siteName;
      navigationSetup.routingType = navigation.routingType;
      navigationSetup.status = navigation.status;
      navigationSetupList.push(navigationSetup);
    });
    return navigationSetupList;
  }
  setLocalHeaders() {
    this._mColumns.forEach((obj, i) => {
      (obj.key == 'site.siteName') ? obj.key = 'siteName' : null;
    });
  }
  getSites() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getSite, success => {
      if (success.status == 200) {
        this.mSites = success.body.dataList.content;
      } else {
        this.mSites = [];
      }
    }, error => {
      this.notifyService.showError(error.message, null);
    })
  }
  getShiftsTimes(event) {
    let siteId = event;
    this.shiftFlag = true;
    this.apiService.doGetRequestWithResponse(ApiConstants.getShiftTimesListBySiteId + "?siteId=" + siteId + "&shiftType=" + 'General', success => {
      if (success.status == 200) {
        this.shiftData = success.body;
        if (this.editFlag) {
          this.apiService.doGetRequestWithResponse(ApiConstants.getShiftTimeListByNavigationId + "?navSetupId=" + this.editData.id, success => {
            if (success.status == 200) {
              this.mShift.setValue(success.body);
            }
          }, error => {
            this.notifyService.showError(error.message, null);
          })
        }
      } else {
        this.shiftData = [];
      }
    }, error => {
      this.notifyService.showError(error.message, null);
    })
  }

  addNavigation() {
    let newData = {
      "site": {
        "siteId": this.mSite.value
      },
      "status": "Active",
      "routingType": this.mRoutingType.value,
      "shifts": this.mShift.value
    }

    this.apiService.doPostRequestWithResponse(ApiConstants.addNavigation, newData, (success) => {
      if (success.status == 201) {
        if (success.body.message === "Setup Already Exists") {
          this.projectFlag = true;
          this.firstStepForProject = false;
          this.mCity.setValue(this.selectedCity.toString());
          this.onLocationChange(this.selectedCity);
          this.notifyService.showWarning(success.body.message, null);
        } else {
          this.notifyService.showSuccess(success.body.message, null);
          this.projectFlag = true;
          this.firstStepForProject = false;
          this.mCity.setValue(this.selectedCity.toString());
          this.onLocationChange(this.selectedCity);
        }
      }
    }, (error) => {
      // this.notifyService.showError(error.message, null); 
    });
  }

  updateNavigation() {
    let editData = {
      "id": this.editData.id,
      "site": {
        "siteId": this.mSite.value
      },
      "status": this.editData.status,
      "routingType": this.mRoutingType.value,
      "shifts": this.mShift.value,
    }
    console.log("value::", editData)
    this.apiService.doPostRequestWithResponse(ApiConstants.updateNavigation, editData, (success) => {
      this.notifyService.showSuccess(success.body.message, null);
      this.projectFlag = true;
      this.firstStepForProject = false;
      this.onLocationChange(this.editData.site.location.locationId);
    },
      (error) => {
        this.notifyService.showError(error.message, null);
      }
    );
  }
  onSubmit() {
    if (!this.editFlag) {
      this.addNavigation();
    } else {
      this.updateNavigation();
    }
  }

  onStatusChanged(data) {
    let msg = "WARNING : Are you sure want to Disable this setup ?";
    let msgKey = AppConstants.DEACTIVATE_MSG;
    if (data.option.status == AppConstants.DEACTIVE_STATUS) {
      msg = "WARNING : Are you sure want to Enable this setup ?";
      msgKey = AppConstants.ACTIVATE_MSG;
    }
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger ms-2'
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      title: msg,
      text: msgKey,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
    }).then(result => {
      if (result.value) {
        swalWithBootstrapButtons.fire('Done', msgKey, 'success');
        this.onStatusToggled(data);
      } else {
        swalWithBootstrapButtons.fire('Cancelled', 'Deactivated', 'error');
        this.projectFlag = true;
        this.firstStepForProject = false;
        this.onLocationChange(this.selectedCity);
      }
    });
  }

  onStatusToggled(data) {
    let reqData: any = {};
    reqData.id = data.option.id;
    this.ngxLoader.start();
    reqData.status =
      data.option.status === AppConstants.ACTIVE_STATUS
        ? AppConstants.DEACTIVE_STATUS
        : AppConstants.ACTIVE_STATUS;
    this.ngxLoader.start();
    this.apiService.doPostRequestWithResponse(ApiConstants.enabledisableNavigation + '?id=' + reqData.id + '&status=' + reqData.status, "",
      (success) => {
        if (success) {
          this.ngxLoader.stop();
          this.notifyService.showSuccess(success.body.message, null);
          this.onLocationChange(this.mCity.value);
        }
      },
      (error) => {
        this.ngxLoader.stop();
        this.notifyService.showError(error.message, null);
        this.onLocationChange(this.mCity.value);
      }
    );
  }


  onAuditLogClicked(e) {
    this.ngxLoader.start();
    this.apiService.doGetRequestWithResponse(ApiConstants.navigationAudit + e.option.id, (success) => {
      if (success.status == 200) {
        this.ngxLoader.stop();
        this.dialog.open(AuditLogComponent, {
          autoFocus: false,
          maxHeight: '80vh',
          data: success.body,
          disableClose: true
        });
      } else {
        this.notifyService.showWarning("No audit logs found", null);
      }
    }, (error) => {
      this.ngxLoader.stop();
      this.notifyService.showWarning("No audit logs found.", null);
    }
    );
  }

  shiftDropDownSettings = {
    singleSelection: false,
    idField: "shiftTimeId",
    textField: "shiftTime",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 1,
    allowSearchFilter: true
  };

  onSelectAll(event) {
  }
  onItemSelect(event) {

  }

  onSearchKey(searchKey) {
    if (searchKey) {
      this._mData = this.ELEMENT_DATA.filter(data => data.siteName.toLowerCase().includes(searchKey.toLowerCase()));
      if (this._mData.length == 0) {
        this._mColumns = [];
        this.subcribeEdit = false;
        this.auditFlag = false;
      }
    } else {
      this.onLocationChange(this.mCity.value);
    }
  }

}
