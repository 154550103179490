
import { Component } from '@angular/core';
import { NavigationStart, Event, Router } from '@angular/router';
@Component({
  selector: 'app-shift-mapping-layout',
  templateUrl: './shift-mapping-layout.component.html',
  styleUrls: ['./shift-mapping-layout.component.scss']
})
export class ShiftMappingLayoutComponent {
  activeTab = null;
  activeTabId = 1;
  first: any = true;

  constructor(
    private router: Router
  ) {
    this.activeTab = this.router.url.split('/').pop();
    this.router.events.subscribe((val: Event) => {
      if (!this.first) {
        if (val instanceof NavigationStart) {
          if (this.activeTab !== val.url.split('/').pop()) {
            this.activeTab = val.url.split('/').pop();
            if (this.activeTab === 'zone-shift-mapping') {
              this.activeTabId = 1;
            } else if (this.activeTab === 'shift-pickup-point-mapping') {
              this.activeTabId = 2;
            } 
          }
        }
      }
    });
  }

  ngOnInit(): void {
    this.onTabActivate(this.activeTab);
    this.first = false;
  }

  onTabActivate(activeTab) {
    if (activeTab != null) {
      if (this.activeTab === 'zone-shift-mapping') {
        this.activeTabId = 1;
        this.router.navigate(["/myatom/routing/shift-mapping/zone-shift-mapping"]);
      } else if (this.activeTab === 'shift-pickup-point-mapping') {
        this.activeTabId = 2;
        this.router.navigate(["/myatom/routing/shift-mapping/shift-pickup-point-mapping"]);
      }
    }
  }

  onTabChange(tabName: any) {
    this.activeTab = tabName;
    this.onTabActivate(tabName);
  }
}

