<div class="d-flex">
    <button type="button" class="btn btn-primary1" (click)="onClickBack()">
        <span class="back-light"><em class="mdi mdi-arrow-left me-2"></em> Back </span>
    </button>
    <div>
        <h4 class="mt-2 font-size-14">
            <span>/ {{(tripGenData?.referenceMirroringType?.view ? tripGenData.referenceMirroringType.view :
                tripGenData?.referenceMirroringType)}}</span>
        </h4>
    </div>
</div>

<div class="row mt-3">
    <div class="card">
        <div class="card-body">
            <div class="row align-items-center">
                <!-- <div class="row">
                    <div class="cc1"></div> Normal
                    <div class="cc2"></div> Expectant Mother
                    <div class="cc3"></div> Hearing Impaired
                    <div class="cc4"></div> Additional Support
                    <div class="cc5"></div> PWD
                    <div class="cc6"></div> Out Of State Boundary
                </div> -->
            </div>
            <div class="row mt-2">
                <div class="table-responsive">
                    <table class="table table-borderless  table-body  font-size-14 mb-0">
                        <thead>
                            <tr>
                                <th>Total Trips</th>
                                <th>Escort Trips</th>
                                <th>Female Emp</th>
                                <th>Total Emp</th>
                                <th>Additional Emp</th>
                                <th>Mirrored Emp</th>
                                <th>Reference Date</th>
                                <th>Reference Shift</th>
                                <th>Requested Shift</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ displayedRows$?.length }}</td>
                                <td>{{ totalEscort }}</td>
                                <td>{{ tripGenData?.shift.femaleEmp }}</td>
                                <td>{{ tripGenData?.shift?.totalEmp }}</td>
                                <td>{{ tripGenDto.additionalUsers ? tripGenDto.additionalUsers : 0 }}</td>
                                <td>{{ tripGenDto.mirroredUsers ? tripGenDto.mirroredUsers : 0 }}</td>
                                <td>{{ (tripGenData?.referenceDate ? tripGenData?.referenceDate : 'NA') }}</td>
                                <td>{{ tripGenData?.referenceShift?.shiftTime ? tripGenData?.referenceShift?.shiftTime:
                                    'NA' }}</td>
                                <td>{{ tripGenData?.shift?.time }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-sm-4">
                    <div class="d-flex justify-content-between" style="width: 500px;">
                        <input class="form-control icon s-input b-0" type="text" autocomplete="off"
                            placeholder="Search by Employee Id/Name">
                    </div>
                </div>
                <div class="col-sm-7">
                    <div class="float-end">
                        <label class="me-2">Show/Hide Address</label>&nbsp;&nbsp;
                        <ui-switch class="me-5" defaultBoColor="#dfdfdf" color="#34c38f" [(ngModel)]="showHideAddress"
                            style="position: relative; bottom: 3px;" (change)="showOrHideAddress($event)">
                        </ui-switch>

                        <label class="me-2">Show/Hide AL</label>&nbsp;&nbsp;
                        <ui-switch defaultBoColor="#dfdfdf" color="#34c38f" [(ngModel)]="showHideAL"
                            style="position: relative; bottom: 3px;" (change)="showOrHideAL($event)">
                        </ui-switch>


                    </div>
                </div>
                <div class="col-sm-1" style="margin-top: -20px;">
                    <div class="dropdown d-inline-block" ngbDropdown>
                        <button type="button" class="btn header-item border-none" id="page-header-user-dropdown"
                            ngbTooltip="Color Info" style="border: none;" ngbDropdownToggle>
                            <img src="assets/images/infor.svg">
                        </button>

                        <div class="dropdown-menu dropdown-menu-end" style="width: 400%;" ngbDropdownMenu>
                            <a class="dropdown-item d-block" href="javascript: void(0);">
                                <div class="cc1 me-3"></div>Normal
                            </a>
                            <a class="dropdown-item d-block" href="javascript: void(0);">
                                <div class="cc2 me-3"></div>Expectant Mother
                            </a>
                            <a class="dropdown-item d-block" href="javascript: void(0);">
                                <div class="cc6 me-3"></div>Hearing Impaired
                            </a>
                            <a class="dropdown-item d-block" href="javascript: void(0);">
                                <div class="cc4 me-3"></div>Additional Support
                            </a>
                            <a class="dropdown-item d-block" href="javascript: void(0);">
                                <div class="cc5 me-3"></div>PWD
                            </a>
                            <a class="dropdown-item d-block" href="javascript: void(0);">
                                <div class="cc3 me-3"></div>Out Of State Boundary
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="row">
    <div class="card">
        <div class="card-body">
            <div class="button-items" fxLayout="row" fxLayoutAlign="end">
                <button class="btn red-btn-solid" aria-pressed="true" (click)="onExceptionDetails()" *ngIf="buttonFlag">
                    Exception Users - {{exceptionList.length}}
                </button>
                <button class="btn red-btn-solid" (click)="submitTrips()" *ngIf="buttonFlag">Submit</button>
                <button class="btn red-btn-solid" (click)="addNewTrip()">Add</button>
                <button class="btn red-btn-solid" (click)="saveAllTrips()" *ngIf="buttonFlag">Save All</button>
                <button (click)="downloadTripData()" style="border: none;" class="btn bg-white" *ngIf="displayedRows$.length > 0">
                <img src="./assets/images/download.svg" matTooltip="Download Mirrored Data"></button>
            </div>
            <div class="row">
                <div class="col-md-5 col-sm-12">
                    <h4 class="header">Additional Users</h4>
                    <div class="p-3 grey-bg">
                        <div class="search-input-conntainer mb-2">
                            <input type="text" class="form-control icon s-input" placeholder="Search By Area/Landmark"
                                autocomplete="off" (ngModelChange)="filterUsers($event)" [(ngModel)]="searchField">
                        </div>

                        <div class="area">
                            <div class="panel-body" dnd-sortable-container [sortableData]="userList"
                                [dropZones]="zoneArrayInit">
                                <ul class="list-group">
                                    <li *ngFor="let user of userList; let i= index" [ngStyle]="{'font-size': '12px'}"
                                        class="list-group-item" dnd-sortable [dragData]="user" [sortableIndex]="i"
                                        (onDragStart)="userDragStarted(user)" (onDropSuccess)="onUserDropSuccess(user)"
                                        [dragEnabled]="false" (onDragEnd)="userDragEnd()">
                                        {{user.displayName}} - {{user.employeeId}} -
                                        <span *ngIf="showHideAddress">{{user.address}}</span>
                                        <span *ngIf="showHideAL"><br>A - {{user.al.area.area}} - L -
                                            {{user.al.landmark}}</span>
                                        <br> Z - {{user.zone ? user.zone.name: 'NA'}}
                                        <button class="btn btn-outline1" (click)="addUsersToTrip(user)">
                                            <em class="bx bx-plus"></em>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-7 col-sm-12 mt-3">
                    <div class="grey-bg">
                        <mat-accordion displayMode="flat" multi class="custom-accordian">
                            <section matSort class="mat-elevation-z2 mat-header-row">
                                <span class="cell" mat-sort-header="TripID">Trip Index</span>
                                <span class="cell" mat-sort-header="vendor"
                                    *ngIf="referenceIRType ? tripMirroringSetupData?.vendorCopyStatus == 'Yes' ? true : false : false">Vendor</span>
                                <span class="cell" mat-sort-header="pickupDropPoint">Pickup/Drop Point</span>
                                <span class="cell" mat-sort-header="vehicleType">Vehicle Type</span>
                                <span class="cell"
                                    *ngIf="referenceIRType ? tripMirroringSetupData?.vehicleDriverCopyStatus == 'Yes' ? true : false : false">Vehicle
                                    No</span>
                                <span class="cell"
                                    *ngIf="referenceIRType? tripMirroringSetupData?.vehicleDriverCopyStatus == 'Yes' ? true: false : false">Driver</span>
                                <span class="cell" mat-sort-header="escortStatus">Escort</span>
                                <span class="cell" mat-sort-header="seatCapacity">Seat Capacity</span>
                                <span class="cell" mat-sort-header="seatsOccupied">Seats Occupied</span>
                                <span class="cell" mat-sort-header="seatsOccupiedPercent">Seats Occupied (%)</span>
                                <span class="cell" mat-sort-header="seatsOccupiedPercent"></span>
                                <span class="cell" mat-sort-header="seatsOccupiedPercent"></span>
                                <span class="expand-close-width" *ngIf="!allExpandState" (click)="allExpandState=true">
                                    <mat-icon matTooltip="Expand all" matTooltipPosition="below">expand_more</mat-icon>
                                </span>
                                <span class="expand-close-width" *ngIf="allExpandState" (click)="allExpandState=false">
                                    <mat-icon matTooltip="Collapse all"
                                        matTooltipPosition="below">expand_less</mat-icon>
                                </span>
                            </section>

                            <div class="trip-expansion-container">
                                <mat-expansion-panel #mep="matExpansionPanel" [disabled]="true"
                                    [expanded]="allExpandState" *ngFor="let item of displayedRows$; let d=index">
                                    <mat-expansion-panel-header>
                                        <span class="mat-cell" (click)="openMap(item)">
                                            <i class="material-icons">near_me</i>
                                            <span>{{d+1}}</span>
                                        </span>

                                        <span class="mat-cell"
                                            *ngIf="referenceIRType ? tripMirroringSetupData?.vendorCopyStatus == 'Yes' ? true : false : false">
                                            <mat-select placeholder="Vendor" class="font-size-12"
                                                (selectionChange)="updateValue($event, 'vendor',d)"
                                                [value]="item?.vendorMaster?.id" required>
                                                <mat-option *ngFor="let vendor of vendorList" [value]="vendor.id"
                                                    class="font-size-12">
                                                    {{vendor.name}}
                                                </mat-option>
                                            </mat-select>
                                        </span>

                                        <span class="mat-cell text-muted">{{item.tripDetails.pickdropPoint}}</span>

                                        <span class="mat-cell">
                                            <mat-select placeholder="Vehicle type" class="font-size-12"
                                                (selectionChange)="updateValue($event,'vehicle',d)"
                                                [value]="item?.vehicleType?.id" required>
                                                <mat-option *ngFor="let vehicle of _mVehicleTypes" class="font-size-12"
                                                    [disabled]="item.tripDetails.tripUsers?.length>vehicle.seatCapacity"
                                                    [matTooltip]="item.tripDetails.tripUsers?.length>vehicle.seatCapacity ? 'Please remove some employees to select this vehicle!' : ''"
                                                    [value]="vehicle.id">
                                                    {{vehicle.vehicleType}}
                                                </mat-option>
                                            </mat-select>
                                        </span>

                                        <span class="mat-cell"
                                            *ngIf="referenceIRType ? tripMirroringSetupData?.vehicleDriverCopyStatus == 'Yes' ? true : false : false">
                                            <mat-select placeholder="Vehicle no" class="font-size-12"
                                                [value]="item?.vehicle?.id"
                                                (selectionChange)="vehicleSearchSubmit(d,$event)" required>
                                                <mat-option *ngFor="let vehicle of item?.vehicleArrayList"
                                                    class="font-size-12" [value]="vehicle.id">
                                                    {{vehicle.registrationNo}}
                                                </mat-option>
                                            </mat-select>
                                        </span>

                                        <span class="mat-cell"
                                            *ngIf=" referenceIRType ? tripMirroringSetupData?.vehicleDriverCopyStatus == 'Yes' ? true: false : false">
                                            <mat-select placeholder="Driver" required [value]="item?.driver?.id"
                                                class="font-size-12" (selectionChange)="driverSearchSubmit(d,$event)">
                                                <mat-option *ngFor="let driver of item?.driverList" class="font-size-12"
                                                    [value]="driver.id">
                                                    {{driver.name}}
                                                </mat-option>
                                            </mat-select>
                                        </span>

                                        <span class="mat-cell">
                                            <ui-switch defaultBoColor="#dfdfdf" color="#34c38f" style="top: -5px;"
                                                (click)="escortClick(item)" [checked]="item.tripDetails.escortStatus"
                                                (change)="escortChange(d,$event,item)"
                                                [disabled]="item.tripDetails.tripUsers?.length == item.vehicleType.seatCapacity">
                                            </ui-switch>
                                        </span>

                                        <span class="mat-cell">{{item.vehicleType.seatCapacity}}</span>

                                        <span class="mat-cell">{{item.tripDetails.tripUsers?.length +
                                            (item.tripDetails.escortStatus ? 1 : 0)}}</span>

                                        <span class="mat-cell">{{(item.tripDetails.tripUsers?.length +
                                            (item.tripDetails.escortStatus ? 1 : 0)) / item.vehicleType.seatCapacity *
                                            100 | number : '1.0-1'}} %</span>

                                        <span class="mat-cell">
                                            <a *ngIf="item.tripDetails.tripUsers.length == 0 && !item.tripDetails.escortStatus"
                                                (click)="deleteForEver(d)">
                                                <mat-icon>delete_forever</mat-icon>
                                            </a>
                                        </span>

                                        <button class="btn red-btn-solid me-2"
                                            (click)="saveTrip(item, d+1)">Save</button>


                                        <!-- <button class="btn-outline-primary" *ngIf="!(item.tripDetails.isSelect)"
                                            (click)="selectToMove(d,item.tripDetails)">
                                            <mat-icon>select_all</mat-icon>
                                        </button> -->

                                        <mat-checkbox class="checkBox-trip-mirror" *ngIf="!(item.tripDetails.isSelect)"
                                            [(ngModel)]="item.tripDetails.isSelect"
                                            (change)="selectToMove(d,item.tripDetails)">
                                        </mat-checkbox>

                                        <mat-checkbox class="checkBox-trip-mirror" *ngIf="(item.tripDetails.isSelect)"
                                            [(ngModel)]="item.tripDetails.isSelect"
                                            (change)="selectToMove(d,item.tripDetails)">
                                        </mat-checkbox>



                                        <!-- <button *ngIf="(item.tripDetails.isSelect)" class="btn-outline-primary"
                                            (click)="selectToMove(d,item.tripDetails)">
                                            <mat-icon>select_all</mat-icon>
                                        </button> -->

                                        <span class="expand-content-width" (click)="mep.expanded = !mep.expanded;">
                                            <mat-icon *ngIf="!mep.expanded">expand_more</mat-icon>
                                            <mat-icon *ngIf="mep.expanded">expand_less</mat-icon>
                                        </span>
                                    </mat-expansion-panel-header>

                                    <div [style.height.px]="item.vehicleType.seatCapacity * 120"
                                        [style.border]="item.tripDetails.tripUsers.length + (item.tripDetails.escortStatus ? 1 : 0) >= item.vehicleType.seatCapacity ? '3px solid #ef1414':'3px solid #fff'"
                                        dnd-sortable-container [sortableData]="item.tripDetails.tripUsers"
                                        [dropZones]="['Zone'+d]">
                                        <ul class="list-group">
                                            <li class="list-group-item"
                                                [ngStyle]="{'background':'#FF0000','color':'#fff'}"
                                                *ngIf="item.tripDetails.tripUsers.length + (item.tripDetails.escortStatus ? 1 : 0) >= item.vehicleType.seatCapacity">
                                                Trip reached max seat capacity </li>

                                            <li *ngIf="item.tripDetails.escortStatus" class="list-group-item"
                                                [ngStyle]="{'background':'#0093ff','color':'#fff'}"> Escort
                                            </li>

                                            <li *ngFor="let emp of item.tripDetails.tripUsers; let j= index"
                                                class="list-group-item" dnd-sortable [sortableIndex]="j"
                                                (onDropSuccess)="onItemDrop(item,emp)" [dragEnabled]="false"
                                                [dragData]="item.tripDetails.tripUsers"
                                                (onDragStart)="dragStarted(item)"
                                                (onDragEnd)="dragEndTrips($event,item)">
                                                {{emp.displayName}} - {{emp.employeeId}} -
                                                <span *ngIf="showHideAddress">{{emp.address}}</span>
                                                <span *ngIf="showHideAL"><br>A - {{emp.al.area.area}} - L -
                                                    {{emp.al.landmark}}</span>
                                                <br> Z - {{emp.zone ? emp.zone.name : "NA"}}
                                                <span (click)="removeUserFromTrips(emp,item.tripDetails.tripUsers)">
                                                    <img src="assets/images/icons/close icon.jpg"
                                                        matTooltip="Remove Employee">
                                                </span>
                                                <button class="buttonup float-end"
                                                    *ngIf="item.tripDetails.tripUsers.length > 1 && (!emp.isSelected)"
                                                    (click)="selectEmpForMove(emp,item.tripDetails.tripUsers)">
                                                    <mat-icon>check_box_outline_blank</mat-icon>
                                                </button>
                                            </li>

                                            <li class="list-group-item1" *ngIf="item.tripDetails.tripUsers.length > 1">
                                                <button class="buttonup"
                                                    (click)="moveUser(item.tripDetails.tripUsers,'d')">
                                                    <mat-icon style="color: #ffffff;">arrow_downward</mat-icon>
                                                </button>
                                                <button class="buttonup"
                                                    (click)="moveUser(item.tripDetails.tripUsers,'u')">
                                                    <mat-icon style="color: #ffffff;">arrow_upward</mat-icon>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </mat-expansion-panel>
                            </div>
                        </mat-accordion>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>