import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { ApiService } from 'src/app/core/services/api.service';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.scss']
})
export class AreaComponent implements OnInit {
  areaData: any[] = [];
  public _mFormGroup: FormGroup;
  public mAreaName = new FormControl('', Validators.required);

  constructor(
    public fb: FormBuilder,
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    private ngxLoader: NgxUiLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AreaComponent>,
  ) {
    this.areaData = data;
    this._mFormGroup = this.fb.group({
      'areaName': this.mAreaName,
    });
  }

  ngOnInit() {
  }

  onClickCancel(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    let flag = true;
    this.areaData.forEach(area => {
      if (area.area == this.mAreaName.value) {
        flag = false;
      }
    });
    let data = {
      'location': {
        'locationId': this.areaData[0].location.locationId,
        'locationName': this.areaData[0].location.locationName
      },
      'area': this.mAreaName.value
    }
    if (flag) {
      this.ngxLoader.start();
      this.apiService.doPostRequestWithResponse(ApiConstants.getArea, data, (success) => {
        this.ngxLoader.stop();
        if (success.status === 201) {
          this.dialogRef.close(success);
          this.notifyService.showSuccess(success.body.message, null);
        } else if (success.status === 200) {
          this.notifyService.showSuccess(success.body.message, null);
        }
      }, (error) => {
        this.ngxLoader.stop();
        this.notifyService.showInfo('Area is alerady exist', null);
      });
    } else {
      this.ngxLoader.stop();
      this.notifyService.showInfo('Area is alerady exist', null);
    }
  }

  discard_special_char_And_String(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

}
