export class ModelTableOptionSelected {
    constructor(public index: number, public option: string) { }
}

export class ModelTableOptionSelectedEdit {
    constructor(public index: number, public data: any) { }
}

export class ModelTableHoverDetailItem {
    constructor(public key: any, public value: string, public parentKey: string) { }
}

export class ModelTableHoverDetail {
    constructor(public items: ModelTableHoverDetailItem[]) { }
}

export class ModelTableHoverDetailItemWithIndex {
    constructor(public item: ModelTableHoverDetailItem, public index: number) { }
}

export class ModelTableCheckedItem {
    constructor(public index: number, public isChecked, public data: any) { }
}

export class ModelTableOptionSelectedPause {
    constructor(public index: number, public data: any, public status: any) { }
}

export class ModelTableOptionSelectedResume {
    constructor(public index: number, public data: any) { }
}

export class ModelTableOptionSelectedDelete {
    constructor(public index: number, public data: any) { }
}

export class ModelTablePendingRequest {
    constructor(public index: any, public status: any) {
    }
}