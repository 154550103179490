<div class="mat-modal-dialog city-modal">
    <h2 mat-dialog-title>
        <span class="font-size-14">Clone New Zone</span>
        <span class="material-icons-outlined"
            style="color:#747679; font-size: 25px; float: right; position: relative; top: 10px; cursor: pointer"
            (click)="onClickCancel()"> close</span>
    </h2>

    <mat-dialog-content>
        <div class="form-group">
            <label>Zone Name<sup class="mandatory">*</sup></label>
            <input type="text" placeholder="Zone Name" [value]="zonename" autocomplete="off"
                (input)="editedZone=$event.target.value;" required>
        </div>
    </mat-dialog-content>

    <div mat-dialog-actions>
        <button class="btn customseProceedBtn" (click)="savezonename(editedZone)">
            Submit
        </button>
    </div>
</div>