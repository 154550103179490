<div class="mat-modal-dialog vehicle-count-modal">
    <h2  mat-dialog-title>
        <span class="mainHeading">{{msg}}</span>
        <span class="material-icons-outlined" (click)="onClickCancel()"
        style="color:#747679; font-size: 25px; float: right; position: relative; top: 20px; cursor: pointer">close</span>
    </h2>
</div>
<hr>
<mat-dialog-content> 
    <div class="row">
        <div class="table-height" style="background-color: #F5FCFF;">
            <table class="table table-responsive">
                <tr>
                    <th class="font-size-14 vTh" style="color: #000000;">Vendor Name</th>
                    <th class="font-size-14 vTh" style="color: #000000;" *ngFor="let item of vechileNames">{{item}}</th>
                </tr>
                <tr *ngFor="let vh of vechileTypeCount">
                    <td class="font-size-13 vTd" style="color: #495057;">{{vh.name}}</td>
                    <td class="font-size-13 vTd" style="color: #495057;" *ngFor="let x of vechileNames">{{vh[x]}}</td>
                </tr>
            </table>
        </div>
    </div>
</mat-dialog-content>