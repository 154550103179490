<div class="container-fluid">
  <div class="card">
    <ul ngbNav #nav="ngbNav" [activeId]="activeTabId" class="nav-tabs nav-tabs-custom g-nav-tabs-custom"
      style="padding-top: 7px;">
      <li [ngbNavItem]="1">
        <a ngbNavLink> On Call </a>
        <ng-template ngbNavContent>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="p-3"></div>
    <div class="card-body">
      <form [formGroup]="_mFormGroup">
        <div class="row">
          <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <label class="font-size-font">Location<sup class="mandatory">*</sup></label>
            <select class="form-select" placeholder="Location" formControlName="location" (change)="getSitesBylocId()"
              required>
              <option value="" disabled>Select Location</option>
              <option *ngFor="let city of locationList" [value]="city.locationId">
                {{ city.locationName }}
              </option>
            </select>
            <div class="error-msg"
              *ngIf="_mFormGroup.get('location').hasError('required') && _mFormGroup.get('location').touched">
              Please select location
            </div>
          </div>
          <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <label class="font-size-font">Site<sup class="mandatory">*</sup></label>
            <select class="form-select" placeholder="Site" formControlName="site" required>
              <option value="" disabled>Select Site</option>
              <option *ngFor="let data of _mSitesData" [value]="data.siteId">
                {{ data.siteName}}
              </option>
            </select>
            <div class="error-msg"
              *ngIf="_mFormGroup.get('site').hasError('required') && _mFormGroup.get('site').touched">
              Please select site
            </div>
          </div>

          <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <label class="font-size-font">Date<sup class="mandatory">*</sup></label>
            <mat-form-field appearance="outline" class="on-call">
              <input matInput [matDatepicker]="picker" (click)="picker.open()" readonly placeholder="Choose Date"
                formControlName="fromDate">
              <!-- [min]="startDate" -->
              <mat-datepicker-toggle matSuffix [for]="picker">
                <img matDatepickerToggleIcon src="assets/images/bx-calendar.svg">
              </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>


          <div class="col-sm-6 col-md-6" style="position: relative; top: 30px;">
            <div style="display: flex; gap: 20px; justify-content: end;">
              <button class="btn on-call-red-btn-solid" (click)="generateReport(true)">Submit</button>
              <button class="btn on-call-grey-btn-solid " (click)="reset()">Reset</button>
            </div>
          </div>

        </div>
      </form>

      <div class="row" *ngIf="reportsViewFlag && ELEMENT_DATA.length > 0">
        <div class="col-sm-12">
          <div class="row" style="margin: 0; margin-top: 20px;">
            <div class="col-sm-12 p-0">
              <div class="table-responsive">
                <table mat-table [dataSource]="dataSource" class="table table-borderless on-call-table" matPaginator>
                  <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef> Date</th>
                    <td mat-cell *matCellDef="let element">
                      {{element.date}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="bookedSite">
                    <th mat-header-cell *matHeaderCellDef class="min-width"> Booked Site</th>
                    <td mat-cell *matCellDef="let element"> {{element.bookedSite}} </td>
                  </ng-container>

                  <ng-container matColumnDef="tripId">
                    <th mat-header-cell *matHeaderCellDef class="min-width"> Trip Id
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.tripId}} </td>
                  </ng-container>

                  <ng-container matColumnDef="punchInTime">
                    <th mat-header-cell *matHeaderCellDef class="min-width">
                      Punch In Time </th>
                    <td mat-cell *matCellDef="let element"> {{element.punchInTime}} </td>
                  </ng-container>

                  <ng-container matColumnDef="vehicle">
                    <th mat-header-cell *matHeaderCellDef class="min-width"> Vehicle
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.vehicle}} </td>
                  </ng-container>

                  <ng-container matColumnDef="driver">
                    <th mat-header-cell *matHeaderCellDef class="min-width">
                      Driver </th>
                    <td mat-cell *matCellDef="let element"> {{element.driver}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="vendor">
                    <th mat-header-cell *matHeaderCellDef class="min-width"> Vendor
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.vendor}} </td>
                  </ng-container>


                  <ng-container matColumnDef="reportingTime">
                    <th mat-header-cell *matHeaderCellDef class="min-width">Reporting Time </th>
                    <td mat-cell *matCellDef="let element"> {{element.reportingTime}} </td>
                  </ng-container>

                  <ng-container matColumnDef="punchOutTime">
                    <th mat-header-cell *matHeaderCellDef class="min-width">Punch Out Time </th>
                    <td mat-cell *matCellDef="let element"> {{element.punchOutTime}} </td>
                  </ng-container>

                  <ng-container matColumnDef="tripCode">
                    <th mat-header-cell *matHeaderCellDef class="min-width"> Trip Code
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.tripCode}} </td>
                  </ng-container>

                  <ng-container matColumnDef="empName">
                    <th mat-header-cell *matHeaderCellDef class="min-width">
                      Emp Name
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.empName}}
                    </td>
                  </ng-container>


                  <ng-container matColumnDef="comment">
                    <th mat-header-cell *matHeaderCellDef class="min-width"> Comment
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.comment}} </td>
                  </ng-container>

                  <ng-container matColumnDef="tripStatus">
                    <th mat-header-cell *matHeaderCellDef class="min-width"> Trip Status
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.tripStatus}} </td>
                  </ng-container>

                  <ng-container matColumnDef="adminAction">
                    <th mat-header-cell *matHeaderCellDef class="min-width"> Admin Action
                    </th>
                    <td mat-cell *matCellDef="let element" style="display: flex; justify-content: center;">
                      <img src="assets/icons/eye.svg" (click)=mapview(element.tripId) style="height: 30px;">
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              </div>
              <!-- <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]"></mat-paginator> -->
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>