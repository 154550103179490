import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { AppConstants } from 'src/app/core/constants/appconstants';
import { ApiService } from 'src/app/core/services/api.service';
import { ServiceExportExcel } from 'src/app/core/services/export.service';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UploadAplStatusComponent } from '../upload-apl-status/upload-apl-status.component';

@Component({
  selector: 'app-upload-apl',
  templateUrl: './upload-apl.component.html',
  styleUrls: ['./upload-apl.component.scss']
})
export class UploadAplComponent implements OnInit {
  showDiv: boolean = false;
  city: number;
  exceldata: any = [];
  @Output("onFileUploaded")
  mOnFileUploaded = new EventEmitter<any>();
  downloadTemplateBaseLink: string = AppConstants.DOWNLOAD_TEMPLATE_BASE_LINK;

  constructor(
    private dialog: MatDialog,
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    private ngxLoader: NgxUiLoaderService,
    private serviceLocalStorage: ServiceLocalStorage,
    private serviceExportExcel: ServiceExportExcel,
    public dialogRef: MatDialogRef<UploadAplComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dataResponse: any
  ) {
    if (dataResponse) {
      this.city = dataResponse;
    }
  }

  ngOnInit() { }

  onFileChange($event) {
    this.ngxLoader.start();
    let files = $event.target.files;
    let type = "LANDMARK_BATCH";
    if (files && files.length > 0) {
      let fileName: string = files[0].name;
      let fileExt = fileName.split(".").pop();
      if (fileExt) {
        if (fileExt.includes("xlsx") || fileExt.includes("xls")) {
          let userId: any = parseInt(this.serviceLocalStorage.getUserId().toString());
          let formdata: FormData = new FormData();
          formdata.append('file', files[0]);
          formdata.append('type', type);
          formdata.append('userid', userId);
          this.apiService.doPostFile(ApiConstants.upload, formdata, success => {
            this.ngxLoader.stop();
            this.notifyService.showSuccess("Excel UplodedCount is = " + success.itemsSaved + " ", null);
            this.onFileUpload(success);
            this.dialogRef.close();
            this.mOnFileUploaded.emit(true);
          }, error => {
            this.ngxLoader.stop();
            console.log(error)
          });
        } else {
          this.ngxLoader.stop();
          this.notifyService.showError("There seems to be a problem with the file that you uploaded. Please upload a Valid File", null);
        }
      } else {
        this.ngxLoader.stop();
        this.notifyService.showError("There seems to be a problem with the file that you uploaded. Please upload a Valid File", null);
      }
    }
  }

  onFileUpload(uplodedResult) {
    this.errList(uplodedResult);
  }

  errList(errlist) {
    this.showDiv = true;
    let dialogRef = this.dialog.open(UploadAplStatusComponent, {
      disableClose: true,
      height: "450px",
      width: "550px",
      data: errlist
    });
  }

  downloadAL(): void {
    let data: any = {};
    data.type = "TOTAL";
    data.siteId = 1; //dummy value, not required in backend for type == total filter
    this.apiService.doGetRequestWithResponse(ApiConstants.getlandmarkbytype + this.city + '&type=' + data.type + '&siteId=' + data.siteId, success => {
      success.body.forEach(element => {
        this.exceldata.push({
          Area: element.area.area,
          Landmark: element.landmark,
          FormattedAddress: element.formattedAddress,
          Latitude: element.latitude,
          Longitude: element.longitude,
          Type: element.type
        });
      });
      this.serviceExportExcel.exportAsExcelFile(this.exceldata, "AL");
    }, error => {
      console.log(error)
    });
  }

}
