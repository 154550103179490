import { Injectable, Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "searchfilter"
})
@Injectable({ providedIn: "root" })
export class SearchFilterPipe implements PipeTransform {
  transform(items: any[], search: string): any[] {
    if (!items) {
      return [];
    }
    if (!search) {
      return items;
    }
    search = search.toLowerCase();
    return items.filter(it => {
      return it.subscribedUser.userInfoDetails.displayName.toLowerCase().includes(search);
    });
  }
}

@Pipe({
  name: "searchfilterUsers"
})
@Injectable({ providedIn: "root" })
export class SearchFilterUsersPipe implements PipeTransform {
  transform(items: any[], search: string): any[] {
    if (!items) {
      return [];
    }
    if (!search) {
      return items;
    }
    search = search.toLowerCase();
    return items.filter(it => {
      return it.displayName.toLowerCase().includes(search);
    });
  }
}

@Pipe({
  name: "ddSearchfilterUsers"
})
@Injectable({ providedIn: "root" })
export class DDSearchFilterUsersPipe implements PipeTransform {
  transform(items: any[], search: string): any[] {
    if (!items) {
      return [];
    }
    if (!search) {
      return items;
    }
    search = search.toLowerCase();
    return items.filter(it => {
      return it.userInfoDetails.displayName.toLowerCase().includes(search);
    });
  }
}

@Pipe({
  name: "driverSearchFilter"
})
@Injectable({ providedIn: "root" })
export class DriverSearchFilter implements PipeTransform {
  transform(items: any[], search: string): any[] {
    if (!items) {
      return [];
    }
    if (!search) {
      return items;
    }
    search = search.toLowerCase();
    return items.filter(it => {
      return it.registrationNo.toLowerCase().includes(search);
    });
  }
}

@Pipe({
  name: "zoneSearchFilter"
})
@Injectable({ providedIn: "root" })
export class ZoneSearchFilter implements PipeTransform {
  transform(items: any[], search: string): any[] {
    if (!items) {
      return [];
    }
    if (!search) {
      return items;
    }
    search = search.toLowerCase();
    return items.filter(it => {
      return it.name.toLowerCase().includes(search);
    });
  }
}


@Pipe({
  name: "vehicleSearchFilter"
})
@Injectable({ providedIn: "root" })
export class VehicleSearchFilter implements PipeTransform {
  transform(items: any[], search: string): any[] {
    if (!items) {
      return [];
    }
    if (!search) {
      return items;
    }
    search = search.toLowerCase();
    return items.filter(it => {
      return it.registrationNo.toLowerCase().includes(search);
    });
  }
}

@Pipe({
  name: 'vehicleDriverSearchFilter'
})
@Injectable({ providedIn: 'root' })
export class VehicleDriverSearchFilter implements PipeTransform {
  transform(items: any[], search: string): any[] {
    if (!items) {
      return [];
    }
    if (!search) {
      return items;
    }
    search = search.toLowerCase();
    return items.filter(it => {
      return it.name.toLowerCase().includes(search);
    });
  }
}



@Pipe({
  name: 'alSearchFilter'
})
@Injectable({ providedIn: 'root' })
export class ALSearchFilter implements PipeTransform {
  transform(items: any[], search: string): any[] {
    if (!items) {
      return [];
    }
    if (!search) {
      return items;
    }
    search = search.toLowerCase();
    return items.filter(it => {
      return (it.landmark.toLowerCase().includes(search) || it.area.area.toLowerCase().includes(search));
    });
  }
}

@Pipe({
  name: "tripSearchFilter"
})
@Injectable({ providedIn: "root" })
export class TripSearchFilter implements PipeTransform {
  transform(items: any[], search: string): any[] {
    if (!items) {
      return [];
    }
    if (!search) {
      return items;
    }
    search = search.toLowerCase();
    return items.filter(it => {
      var isTrue = false;
      for (var k in it.tripemp) {
        if (it.tripemp[k].employeeName.toLowerCase().indexOf(search) > -1 || it.tripemp[k].employeeId.toLowerCase().indexOf(search) > -1 || it.zone.toLowerCase().indexOf(search) > -1 || it.tripemp[k].employeeId.toLowerCase().indexOf(search) > -1 || it.vehicleType.toLowerCase().indexOf(search) > -1 || it.tripemp[k].address.toLowerCase().includes(search)) {
          isTrue = true;
        }
        if (isTrue) {
          return it;
        }
      }
    });
  }
}

@Pipe({
  name: "allocatedTripFilter"
})
@Injectable({ providedIn: "root" })
export class AllocatedTripFilter implements PipeTransform {
  transform(items: any[], search: string): any[] {
    if (!items) {
      return [];
    }
    if (!search) {
      return items;
    }
    search = search.toLowerCase();
    return items.filter(it => {
      var isTrue = false;
      for (var k in it.tripUserList) {
        if (it.tripUserList[k].tripUser.userInfoDetails.firstName.toLowerCase().indexOf(search) > -1 || it.tripUserList[k].tripUser.userInfoDetails.employeeId.toLowerCase().indexOf(search) > -1) {
          isTrue = true;
        }
        if (isTrue) {
          return it;
        }
      }
    });
  }
}
