import { Component, Inject } from '@angular/core';
import { SelectControlValueAccessor } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { ApiService } from 'src/app/core/services/api.service';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { TripMapComponent } from 'src/app/pages/routing/trips/trip-view/trip-map/trip-map.component';
import Swal from 'sweetalert2';

export interface PeriodicElement {
  'driver': string,
  'empName': string,
  'logIn': string,
  'logoutTime': string,
  'showStatus': string,
  'tripCode': string,
  'tripDate': string,
  'tripStatus': string,
  'vehicle': string,
}

export interface addressElement {
  "empId": string,
  "area": string,
  "address": string,
  "lastUpdatedDate": string,
  "landmark": string,
  "status": string,
}

export interface geocodeElement {
  "area": string,
  "updatedAddress": string,
  "lastUpdatedDate": string,
  "action": string,
  "employeeId": string,
  "landmark": string,
  "status": string,
}
export interface driverDocsElement {
  "licenseNo": string,
  "govtIdType": string,
  "medicalCheck"
  "govtIdNo": string,
  "vehicle": string,
  "pvcExpDate": string,
  "policeVerification": string,
  "licenseExpDate": string,
  "driverName": string,
  "BGCExpDate": string,
  "badgeNo": string,
  "badgeExpDate": string,
  "medicalExpDate": string,
  "BGC": string,
}

export interface vehicleDocsElement {
  "fitnessNo": string,
  "roadTaxNo": string,
  "roadTaxExpDate": string,
  "permitExpiryDate": string,
  "insuranceExpDate": string,
  "permitNo": string,
  "vehicleNo": string,
  "chassisNo": string,
  "insuranceNo": string,
  "emission": string,
  "emissionExpDate": string,
  "registrationDate": string,
  "manufacturingDate": string,
  "driverName": string,
  "fitnessExpDate": string,
}

@Component({
  selector: 'app-search-dialog-box',
  templateUrl: './search-dialog-box.component.html',
  styleUrls: ['./search-dialog-box.component.scss']
})

export class SearchDialogBoxComponent {
  dataFlag: boolean = false;
  _mData: any = [];
  _mColumns: any;
  mapType: any;
  ELEMENT_DATA = [];
  ELEMENT_DATA_DRIVER = [];
  tripsFlag: boolean = false;
  driverDocsFlag: boolean = false;
  vehicleDocsFlag: boolean = false;
  addressFlag: boolean = false;
  geocodeFlag: boolean = false;
  pendingFlag: boolean = false;
  displayedColumns: string[] =
    [
      'empName',
      'tripCode',
      'tripDate',
      'logIn',
      'driver',
      'vehicle',
      'logoutTime',
      'showStatus',
      'tripStatus',

    ];
  displayedColumnsGeocodeChange: string[] =
    [
      "employeeId",
      "updatedAddress",
      "area",
      "landmark",
      "lastUpdatedDate",
      "status",
      "action",
    ];

  displayedColumnsEmployeeAddressDocs: string[] =
    [
      "empId",
      "empName",
      "area",
      "landmark",
      "address",
      "empCategory",
      "status"
    ];

  displayedColumnsVehicleDocs: string[] =
    [
      "vehicleNo",
      "manufacturingDate",
      "registrationDate",
      "chassisNo",
      "roadTaxNo",
      "roadTaxExpDate",
      "insuranceNo",
      "insuranceExpDate",
      "fitnessNo",
      "fitnessExpDate",
      "emission",
      "emissionExpDate",
      "permitNo",
      "permitExpiryDate",
      "driverName",
    ];

  displayedColumnsDriverDocs: string[] =
    [
      "driverName",
      "govtIdType",
      "govtIdNo",
      "licenseNo",
      "licenseExpDate",
      "badgeNo",
      "badgeExpDate",
      "pvcExpDate",
      "policeVerification",
      "BGC",
      "BGCExpDate",
      "medicalCheck",
      "medicalExpDate",
      "vehicle",
    ];

  dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
  dataSourceAddress = new MatTableDataSource<addressElement>(this.ELEMENT_DATA);
  dataSourceVehicle = new MatTableDataSource<vehicleDocsElement>(this.ELEMENT_DATA);
  dataSourceDriver = new MatTableDataSource<driverDocsElement>(this.ELEMENT_DATA_DRIVER);
  dataSourceGeocode = new MatTableDataSource<geocodeElement>(this.ELEMENT_DATA);


  constructor(
    public dialogRef: MatDialogRef<SearchDialogBoxComponent>,
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    private ngxLoader: NgxUiLoaderService,
    private dialog: MatDialog,
    private serviceLocalStorage: ServiceLocalStorage,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    if (data) {
      this.data = data;
    }
    else {
      this.data = [];
    }
  }

  ngOnInit(): void {
    if (this.data) {
      this.mapType = this.serviceLocalStorage.getMapType();
      this.getSearchResults();
    }
  }

  getSearchResults() {
    if (this.data.filter == 'Employee') {
      this.ngxLoader.start();
      if (this.data.option == 'Current Schedules' || this.data.option == 'Past Schedules') {
        this.apiService.doGetRequestWithResponse(ApiConstants.getContextSearchEmployeeSchedule + "?empId=" + this.data.empId + '&flag=' + this.data.flag, success => {
          this.ngxLoader.stop();
          if (success.status == 200) {
            if (success.body.length > 0) {
              this.dataFlag = true;
              this._mData = success.body;
              this._mColumns = [
                {
                  label: "Employee Name",
                  key: "empName"
                },
                {
                  label: "Trip Id",
                  key: "tripCode"
                },
                {
                  label: "Trip Date",
                  key: "tripDate"
                },
                {
                  label: "Login Time",
                  key: "logIn"
                },
                {
                  label: "Show",
                  key: "show"
                },
                {
                  label: "Logout Time",
                  key: "logoutTime"
                },
                {
                  label: "Show Status",
                  key: "showStatus"
                },
                {
                  label: "Safe Drop",
                  key: "safeDrop"
                },

              ]
            } else {
              this.dataFlag = false;
              this.notifyService.showInfo("No data found", null);
              this.dialogRef.close("close");
            }
          }
          else {
            this.dataFlag = false;
            this.notifyService.showInfo("No data found", null);
            this.dialogRef.close("close");
          }
        },
          error => {
            this.ngxLoader.stop();
            this.notifyService.showError(error, null);
          });
      }
      if (this.data.option == 'Upcoming Trips' || this.data.option == 'Past Trips') {
        this.tripsFlag = true;
        this.apiService.doGetRequestWithResponse(ApiConstants.getContextSearchEmployeeTrips + "?empId=" + this.data.empId + '&flag=' + this.data.flag, success => {
          this.ngxLoader.stop();
          if (success.status == 200) {
            if (success.body.length > 0) {
              this.dataFlag = true;
              this._mData = success.body;
              this.ELEMENT_DATA = success.body;
              this.dataSource.data = success.body;
            }
            else {
              this.dataFlag = false;
              this._mData = [];
              this.ELEMENT_DATA = [];
              this.notifyService.showInfo("No data found", null);
              this.dialogRef.close("close");
            }

          } else {
            this.dataFlag = false;
            this._mData = [];
            this.ELEMENT_DATA = [];
            this.notifyService.showInfo("No data found", null);
            this.dialogRef.close("close");
          }
        },
          error => {
            this.ngxLoader.stop();
            this.notifyService.showError(error, null);
          });
      }

      if (this.data.option == 'Geocode Change') {
        this.apiService.doGetRequestWithResponse(ApiConstants.getContextSearchEmployeeGeocode + "?empId=" + this.data.empId + '&flag=' + this.data.flag, success => {
          this.ngxLoader.stop();
          if (success.status == 200) {
            this.dataFlag = true;
            this.geocodeFlag = true;
            this._mData = success.body;
            success.body.forEach(element => {
              if (element.status == 'Pending') {
                this.pendingFlag = true;
              }
              else {
                this.pendingFlag = false;
              }
            });
            this.ELEMENT_DATA = success.body;
            this.dataSourceGeocode.data = success.body;

          } else if (success.status == 204) {
            this.dataFlag = false;
            this.geocodeFlag = false;
            this._mData = [];
            this.ELEMENT_DATA = [];
            this.notifyService.showInfo("No data found", null);
            this.dialogRef.close("close");
          }
        },
          error => {
            this.ngxLoader.stop();
            this.notifyService.showError(error, null);
          });
      }

      if (this.data.option == 'Address') {
        this.apiService.doGetRequestWithResponse(ApiConstants.getContextSearchEmployeeAddress + "?empId=" + this.data.empId + '&flag=' + this.data.flag, success => {
          this.ngxLoader.stop();
          if (success.status == 200) {
            let data = [];
            data.push(success.body);
            if (data.length > 0) {
              this.dataFlag = true;
              this.addressFlag = true;
              this._mData = success.body;
              this.ELEMENT_DATA = data;
              this.dataSourceAddress.data = data;
            }
            else {
              this.dataFlag = false;
              this.addressFlag = false;
              this._mData = [];
              this.ELEMENT_DATA = [];
              this.notifyService.showInfo("No data found", null);
              this.dialogRef.close("close");
            }

          }
          else if (success.status == 204) {
            this.addressFlag = false;
            this.dataFlag = false;
            this.notifyService.showInfo("No data found", null);
            this.dialogRef.close("close");
          }
        },
          error => {
            this.ngxLoader.stop();
            this.notifyService.showError(error, null);
          });
      }
    }

    if (this.data.filter == 'Vehicle') {
      if (this.data.option == 'Documents') {
        this.ngxLoader.start();
        this.apiService.doGetRequestWithResponse(ApiConstants.getContextSearchVehicleDocuments + "?vehicleRegNo=" + this.data.empId + '&flag=' + 'document', success => {
          this.ngxLoader.stop();
          if (success.status == 200) {
            if (success.body.length > 0) {
              this.dataFlag = true;
              this.vehicleDocsFlag = true;
              this._mData = success.body;
              success.body.forEach(element => {
                if (element.emission) {
                  let array = [];
                  array = element.emission.split('T');
                  element.emission = array[0];
                }
              });
              this.ELEMENT_DATA = success.body;
              this.dataSourceVehicle.data = success.body;
            }
            else {
              this.dataFlag = false;
              this.vehicleDocsFlag = false;
              this._mData = [];
              this.ELEMENT_DATA = [];
              this.notifyService.showInfo("No data found", null);
              this.dialogRef.close("close");
            }

          } else {
            this.vehicleDocsFlag = false;
            this.dataFlag = false;
            this.notifyService.showInfo("No data found", null);
            this.dialogRef.close("close");
          }
        },
          error => {
            this.ngxLoader.stop();
            this.notifyService.showError(error, null);
          });

      }
      else {
        if (this.data.option == 'Upcoming Trips' || this.data.option == 'Running Trips') {
          this.ngxLoader.start();
          this.apiService.doGetRequestWithResponse(ApiConstants.getContextSearchVehicleTrips + "?vehicleRegNo=" + this.data.empId + '&flag=' + this.data.flag, success => {
            this.ngxLoader.stop();
            if (success.status == 200) {
              this.dataFlag = true;
              this._mData = success.body;
              this._mColumns = [
                {
                  label: "Vehicle Number",
                  key: "vehicleNo"
                },

                {
                  label: "Trip Date",
                  key: "tripDate"
                },
                {
                  label: "Trip Id",
                  key: "tripCode"
                },
                // {
                //   label: "Trip Code",
                //   key: "tripCode"
                // },

                {
                  label: "Driver Name",
                  key: "driverName"
                },
                {
                  label: "Shift Time",
                  key: "shiftTime"
                },
                // {
                //   label: "Logout Time",
                //   key: "logoutTime"
                // },
                {
                  label: "Show Count",
                  key: "showCount"
                },
                {
                  label: "No Show Count",
                  key: "noShowCount"
                },
                // {
                //   label: "Vehicle",
                //   key: "vehicle"
                // },
                {
                  label: "Planned KM",
                  key: "plannedKm"
                },
                {
                  label: "Trip Status",
                  key: "tripStatus"
                },

              ]
            } else {
              this.dataFlag = false;
              this.notifyService.showInfo("No data found", null);
              this.dialogRef.close("close");
            }
          },
            error => {
              this.ngxLoader.stop();
              this.notifyService.showError(error, null);
            });
        }

        if (this.data.option == 'Past Trips') {
          this.ngxLoader.start();
          this.apiService.doGetRequestWithResponse(ApiConstants.getContextSearchVehicleTrips + "?vehicleRegNo=" + this.data.empId + '&flag=' + this.data.flag, success => {
            this.ngxLoader.stop();
            if (success.status == 200) {
              this.dataFlag = true;
              this._mData = success.body;
              this._mColumns = [
                {
                  label: "Vehicle Number",
                  key: "vehicleNo"
                },

                {
                  label: "Trip Date",
                  key: "tripDate"
                },

                {
                  label: "Trip Id",
                  key: "tripCode"
                },


                {
                  label: "Driver Name",
                  key: "driverName"
                },
                {
                  label: "Shift Time",
                  key: "shiftTime"
                },
                // {
                //   label: "Logout Time",
                //   key: "logoutTime"
                // },
                {
                  label: "Show Count",
                  key: "showCount"
                },
                {
                  label: "No Show Count",
                  key: "noShowCount"
                },
                // {
                //   label: "Vehicle",
                //   key: "vehicle"
                // },
                {
                  label: "Planned KM",
                  key: "plannedKm"
                },
                // {
                //   label: "Trip Status",
                //   key: "tripStatus"
                // },

              ]
            } else {
              this.dataFlag = false;
              this.notifyService.showInfo("No data found", null);
              this.dialogRef.close("close");
            }
          },
            error => {
              this.ngxLoader.stop();
              this.notifyService.showError(error, null);
            });
        }

      }
    }

    if (this.data.filter == 'Driver') {
      if (this.data.option == 'Documents') {
        this.ngxLoader.start();
        this.apiService.doGetRequestWithResponse(ApiConstants.getContextSearchDriverDocuments + "?driverName=" + this.data.empId + '&flag=' + 'document', success => {
          this.ngxLoader.stop();
          if (success.status == 200) {
            if (success.body.length > 0) {
              this.dataFlag = true;
              this.driverDocsFlag = true;
              this._mData = success.body;
              this.ELEMENT_DATA_DRIVER = success.body;
              this.dataSourceDriver.data = success.body;
              this.ngxLoader.stop();
            }
            else {
              this.driverDocsFlag = false;
              this.dataFlag = false;
              this.notifyService.showInfo("No data found", null);
              this.dialogRef.close("close");
              this.ngxLoader.stop();
            }
          }
          else {
            this.driverDocsFlag = false;
            this.dataFlag = false;
            this.notifyService.showInfo("No data found", null);
            this.dialogRef.close("close");
            this.ngxLoader.stop();
          }
        },
          error => {
            this.ngxLoader.stop();
            this.notifyService.showError(error, null);
          });

      }
      else {
        if (this.data.option == 'Upcoming Trips' || this.data.option == 'Running Trips') {
          this.ngxLoader.start();
          this.apiService.doGetRequestWithResponse(ApiConstants.getContextSearchDriverTrips + "?driverName=" + this.data.empId + '&flag=' + this.data.flag, success => {
            this.ngxLoader.stop();
            if (success.status == 200) {
              this.dataFlag = true;
              this._mData = success.body;
              this._mColumns = [
                {
                  label: "Vehicle Number",
                  key: "vehicleNo"
                },
                {
                  label: "Trip Date",
                  key: "tripDate"
                },
                {
                  label: "Trip Id",
                  key: "tripCode"
                },
                // {
                //   label: "Trip Code",
                //   key: "tripCode"
                // },

                {
                  label: "Driver Name",
                  key: "driverName"
                },
                {
                  label: "Shift Time",
                  key: "shiftTime"
                },
                // {
                //   label: "Logout Time",
                //   key: "logoutTime"
                // },
                {
                  label: "Show Count",
                  key: "showCount"
                },
                {
                  label: "No Show Count",
                  key: "noShowCount"
                },
                // {
                //   label: "Vehicle",
                //   key: "vehicle"
                // },
                {
                  label: "Planned KM",
                  key: "plannedKm"
                },
                {
                  label: "Trip Status",
                  key: "tripStatus"
                },

              ]
            } else {
              this.dataFlag = false;
              this.notifyService.showInfo("No data found", null);
              this.dialogRef.close("close");
            }
          },
            error => {
              this.ngxLoader.stop();
              this.notifyService.showError(error, null);
            });
        }
      }
      if (this.data.option == 'Past Trips') {
        this.ngxLoader.start();
        this.apiService.doGetRequestWithResponse(ApiConstants.getContextSearchDriverTrips + "?driverName=" + this.data.empId + '&flag=' + this.data.flag, success => {
          this.ngxLoader.stop();
          if (success.status == 200) {
            this.dataFlag = true;
            this._mData = success.body;
            this._mColumns = [
              {
                label: "Vehicle Number",
                key: "vehicleNo"
              },
              {
                label: "Trip Date",
                key: "tripDate"
              },
              {
                label: "Trip Id",
                key: "tripCode"
              },
              // {
              //   label: "Trip Code",
              //   key: "tripCode"
              // },

              {
                label: "Driver Name",
                key: "driverName"
              },
              {
                label: "Shift Time",
                key: "shiftTime"
              },
              // {
              //   label: "Logout Time",
              //   key: "logoutTime"
              // },
              {
                label: "Show Count",
                key: "showCount"
              },
              {
                label: "No Show Count",
                key: "noShowCount"
              },
              // {
              //   label: "Vehicle",
              //   key: "vehicle"
              // },
              {
                label: "Planned KM",
                key: "plannedKm"
              },
              // {
              //   label: "Trip Status",
              //   key: "tripStatus"
              // },

            ]
          } else {
            this.dialogRef.close("close");
            this.dataFlag = false;
            this.notifyService.showInfo("No data found", null);

          }
        },
          error => {
            this.ngxLoader.stop();
            this.notifyService.showError(error, null);
          });
      }
    }

  }

  onClickCancel(): void {
    this.dialogRef.close("");
  }

  mapview(tripid) {
    this.ngxLoader.start();
    this.apiService.doGetRequestWithResponse(ApiConstants.gettripbytripid + '?tripid=' + tripid, success => {
      let reqData = {
        data: success.body,
        mapType: this.mapType,
        modifyshow: true,
        searchButtonFlag: true
      };
      this.dialog.open(TripMapComponent, {
        width: '1250px',
        disableClose: true,
        data: reqData
      });
      this.ngxLoader.stop();
    }, error => {
      this.notifyService.showInfo("No Trips Found", null);
      this.ngxLoader.stop();
    });
  }

  modifiedColorContextSearch(p) {
    if (p.tripstatus == "MODIFIED") {
      return "#0056FD"; // Blue
    }
    for (let id in p.tripemp) {
      if (p.tripemp[id].outOfSiteBoundry != null && p.tripemp[id].outOfSiteBoundry) {
        return '#FF455B'; // red
      }
    }
    if (p.tripstatus == "VENDOR_PUSHBACKED") {
      return "#9CA3AF"; //Grey
    }
    else {
      return '#FFA450'; // orange
    }
  }


  modifiedBgColorContextSearch(p) {
    if (p.tripstatus == "MODIFIED") {
      return "#F2F6FF"; // light blue
    } else if (p.tripstatus == "VENDOR_PUSHBACKED") {
      return "#F1F1F1"; // light grey
    } else {
      return '#FFF6F1'; // light orange
    }
  }

  onApproveStatusRequest(item) {
    let msg = "WARNING : Are you sure you want to Approve Request for " + item.employeeId + "?";
    let msgKey = "Approved";
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger ms-2'
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      title: msg,
      text: msgKey,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
    }).then(result => {
      if (result.value) {
        item.status = "Approved";
        this.apiService.doPostRequestWithResponse(ApiConstants.updatePendigGeoCode + '?id=' + item.geoCodeReqId + '&status=' +
          item.status, '',
          success => {
            if (success.status == 200 || success.status == 204) {
              this.notifyService.showSuccess("GeoCode Updated successfully...", null);
              this.getSearchResults();
            }
            else {
              this.notifyService.showError("Error in Updating GeoCode", null);
            }
          },
          error => {

          }
        );
        swalWithBootstrapButtons.fire('Done', msgKey, 'success');
      }
      else {

        return;
      }

    });

  }

  onRejectStatusRequest(item) {
    let msg = "WARNING : Are you sure you want to Reject Request for " + item.employeeId + "?";
    let msgKey = "Rejected";
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger ms-2'
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      title: msg,
      text: msgKey,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
    }).then(result => {
      if (result.value) {
        item.status = "REJECTED_BY_ADMIN";
        this.apiService.doPostRequestWithResponse(ApiConstants.updatePendigGeoCode + '?id=' + item.geoCodeReqId + '&status=' +
          item.status, '',
          success => {
            if (success.status == 200 || success.status == 204) {
              this.notifyService.showSuccess("GeoCode Updated successfully...", null);
              this.getSearchResults();
            }
            else {
              this.notifyService.showError("Error in Updating GeoCode", null);
            }
          },
          error => {

          }
        );
        swalWithBootstrapButtons.fire('Done', msgKey, 'success');
      }
      else {

        return;
      }

    })
  }


}
