<div class="container-fluid">
  <div class="row">
    <div class="col-xl-12">
      <div class="card">
        <ul ngbNav #nav="ngbNav" [activeId]="activeTabId" class="nav-tabs nav-tabs-custom g-nav-tabs-custom"
          style="padding-top: 7px;">
          <li [ngbNavItem]="1">
            <a ngbNavLink>Track Trips</a>
            <ng-template ngbNavContent>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <form [formGroup]="_mFormGroup">
                <div class="d-flex customtracktrips-admin" style="align-items: flex-end;">
                  <div>
                    <label>Site</label>
                    <select class="form-select" placeholder="Site" formControlName="site">
                      <option value="" disabled>Select Site
                      </option>
                      <option *ngFor="let site of _mSitesData" [value]="site.siteId">
                        {{ site.siteName }}
                      </option>
                    </select>
                  </div>
                  <div style="position: relative; top: 22px;">
                    <label>Date</label>
                    <mat-form-field appearance="outline" class="tracktripsField-admin">
                      <input matInput (click)="picker.open()" formControlName="date" [matDatepicker]="picker"
                        placeholder="Date" readonly="readonly">
                      <mat-datepicker-toggle matSuffix [for]="picker">
                        <img matDatepickerToggleIcon src="assets/images/bx-calendar.svg">
                      </mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div>
                    <label>Log type</label>
                    <select class="form-select" (change)="onLogTypeChange()" formControlName="logType">
                      <option value="" disabled>Select Log</option>
                      <option *ngFor="let pickDrop of _mPickDropValue" [value]="pickDrop.key">
                        {{ pickDrop.label }}
                      </option>
                    </select>
                  </div>
                  <div>
                    <label>Shift time</label>
                    <select class="form-select" formControlName="shiftTime">
                      <option value="" disabled>Select Shift</option>
                      <option *ngFor="let shiftTime of _mShiftTimes" [value]="shiftTime.shiftTimeId">
                        {{ shiftTime.shiftTime }}
                      </option>
                    </select>
                  </div>
                  <div style="display: flex; gap: 35px; justify-content: flex-end;">
                    <button class="btn track-btn-solid-admin" (click)="onSubmit()">Show</button>
                    <img style="position: relative;" src="./assets/images/excel.svg" (click)="download()"
                      matTooltip="Export To Excel" *ngIf="tripDetailsList">
                    <img style="position: relative;" src="./assets/images/excel.svg" (click)="downloadAllocated()"
                      matTooltip="Export To Excel" *ngIf="allocatedTripFlag">
                    <img style="position: relative;" src="./assets/images/excel.svg" (click)="downloadUnAllocated()"
                      matTooltip="Export To Excel" *ngIf="unAllocatedTripFlag">
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="row mt-4" *ngIf="tripSummaryFlag">
            <div class="col-sm-12 col-md-12">
              <table class="responsive summary-admin tdw-position-relative
              tdw-padding-6 admintrack-tripszIndex">
                <thead class="tdw-bottom-line mat-color--grey-100 tdw-card-thin animated
                bounce">
                  <th class="tdw-padding-h-12 tdw--text-align-right sort-remove
                  tdw-padding-6 font-size-summary-admin">
                    Summary
                  </th>
                  <th class="tdw-padding-h-12 tdw--text-align-right sort-remove
                  tdw-padding-6 font-size-summary-admin">
                    Total Assigned Trips : {{assignedTripCountArr[0].assignedTrips}}
                  </th>
                  <th class="tdw-padding-h-12 tdw--text-align-right sort-remove
                  tdw-padding-6 pointer font-size-summary-admin" (click)="showAllocatedTrips()">
                    Allocated Trips: {{assignedTripCountArr[0].allocatedTrips}}
                  </th>
                  <th class="tdw-padding-h-12 tdw--text-align-right sort-remove
                  tdw-padding-6 pointer font-size-summary-admin" (click)="showUnAllocatedTrips()">
                    UnAllocated Trips : {{assignedTripCountArr[0].unAllocatedTrips}}
                  </th>

                  <th>
                    <div class="dropdown-container" ngbDropdown>
                      <button type="button" class="btn" id="page-header-user-dropdown" style="border: none;"
                        title="Color Info" ngbDropdownToggle>
                        <img src="assets/images/infor.svg" alt="Info Icon">
                      </button>
                      <div class="dropdown-menu dropdown-menu-end" container="body" style="width: 145%;"
                        ngbDropdownMenu>
                        <a class="dropdown-item d-block" href="javascript:void(0);">
                          <div class="tt1-admin me-3"></div>Normal
                        </a>
                        <a class="dropdown-item d-block" href="javascript:void(0);">
                          <div class="tt2-admin me-3"></div>Expectant Mother
                        </a>
                        <a class="dropdown-item d-block" href="javascript:void(0);">
                          <div class="tt3-admin me-3"></div>Hearing Impaired
                        </a>
                        <a class="dropdown-item d-block" href="javascript:void(0);">
                          <div class="tt4-admin me-3"></div>Additional Support
                        </a>
                        <a class="dropdown-item d-block" href="javascript:void(0);">
                          <div class="tt5-admin me-3"></div>PWD
                        </a>
                      </div>
                    </div>
                  </th>
                </thead>
              </table>
            </div>
          </div>

          <div *ngIf="tripDetailsList">
            <div class="row">
              <div class="col-sm-3 mt-3">
                <div class="d-flex justify-content-between" style="width: 250px;">
                  <input class="form-control icon s-input b-0" type="text" style="font-size: 13px;"
                    placeholder="Search by EmpId/Name" [(ngModel)]="searchTrip">
                </div>
              </div>
              <div class="col-sm-2 mt-2">
                <ui-switch defaultBoColor="#dfdfdf" color="#34c38f" [(ngModel)]="showOrHideAL"
                  [checked]="(showOrHideAL == true) ? true : false" style="position: relative; top: 10px;"
                  (valueChange)="showOrHideTitle($event)"></ui-switch>
                <span
                  style="position: relative; left: 15px; top: 5px; font-size: 14px;font-weight: 500;font-family: 'Poppins';">Show/Hide
                  {{mTitle}}</span>
              </div>
              <div class="col-sm-2 mt-2">
                <span
                  style="position: relative; left: 15px; top: 5px; font-size: 14px;font-weight: 500;font-family: 'Poppins';">Address</span>
                <ui-switch defaultBoColor="#dfdfdf" color="#34c38f" [(ngModel)]="addressOrAL"
                  [checked]="(addressOrAL == true) ? true : false" style="position: relative; top: 10px; left: 18px;"
                  (valueChange)="showOrHideAddress($event)"></ui-switch>
                <span
                  style="position: relative; left: 25px; top: 5px; font-size: 14px;font-weight: 500;font-family: 'Poppins';">A/L</span>
              </div>
              <div class="col-sm-2 mt-2">
                <ui-switch style="position: relative; top: 10px;" defaultBoColor="#dfdfdf" color="#34c38f"
                  [(ngModel)]="showContact" [checked]="(showContact == true) ? true : false"
                  (valueChange)="showOrHideContact($event)"></ui-switch>
                <span
                  style="position: relative; left: 15px; top: 5px; font-size: 14px;font-weight: 500;font-family: 'Poppins';">Show/Hide
                  Contact</span>
              </div>

              <div class="col-sm-3 mt-3">
                <button class="btn-solid-select-all-admin" style="position: relative; right: 2px;"
                  (click)="onSelectAll(tripDetailsData, escortListData)">Select
                  All</button>
              </div>
            </div>

            <!-- loop starts here -->


            <div *ngFor="let trips of tripDetailsData |
        allocatedTripFilter : searchTrip; let i=index;">
              <div class="card p-0 mb-2">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-12 col-md-12" style="background-color: #FBFCFF !important;padding-top: 10px; margin-left: 1px;margin-right: 1px; 
                        height: 45px; box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14), 
                        0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 1px -2px rgba(0, 0, 0, 0.2);">
                      <div class="summarytracktrips-admin">
                        <div>
                          <span style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Trip
                            ID
                            : </span><span class="trip-code-admin"
                            [ngStyle]="trips.tripsColor ? 
                              {'color': trips.tripsColor, 'background': '#fff', 'border': '1px solid' + trips.tripsColor, 
                              'font-size': '13px', 'font-weight': '400', 'font-family': 'Poppins'} : 
                              {'color': '#7C7D84', 'font-size': '13px', 'font-weight': '400', 'font-family': 'Poppins'}"
                            (click)=maptrackview(trips.tripId)>{{getExtractedSubstring(trips.tripCode)}}</span>
                        </div>
                        <div>
                          <span style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Trip
                            Date
                            : </span><span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">
                            {{trips.tripDate}}</span>
                        </div>
                        <div>
                          <span style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Shift
                            : </span><span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">
                            {{trips.logType}}-{{trips.shiftTime}}</span>
                        </div>
                        <div>
                          <span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Vehicle
                            Type
                            : </span><span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">
                            {{trips.vehicleType}}</span>
                        </div>
                        <div>
                          <span style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Trip
                            Status
                            : </span><span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">
                            {{trips.tripVendorAssign.DeviceTripStatus}}</span>
                        </div>
                        <div>
                          <span style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Escort
                            Trip
                            : </span><span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">
                            {{trips.escortStatus}}</span>
                        </div>

                        <div>
                          <span class="action-container-admin">
                            <a href="javascript: void(0);" title="Audit" (click)="getAudit(trips)">
                              <img src="assets/images/icons/audit.svg">
                            </a>
                            <mat-checkbox class="tracktripsCheckbox-admin" type="checkbox" [checked]="trips.checked"
                              style="position: relative; bottom: 9px; left: 0px;"
                              (change)="onCheck($event.target, trips, escortListData)"></mat-checkbox>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-sm-12 col-md-12">
                      <form [formGroup]="_mFormGroupForTrackTrips">
                        <div class="row">
                          <div class="col-sm-2 col-md-2">
                            <mat-form-field appearance="outline" class="escort-tracktrip-admin"
                              *ngIf="_mVendors.length > 1">
                              <mat-select placeholder="Vendor Master" [value]="trips?.assignedVendorMaster?.id"
                                (selectionChange)="onVendorSelect($event,
                                      getIndex(trips))">
                                <mat-option *ngFor="let vendor of _mVendors"
                                  style=" font-size: 13px !important; font-weight: 400 !important;" [value]="vendor.id">
                                  {{vendor.name}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="escort-tracktrip-admin"
                              *ngIf="_mVendors.length == 1">
                              <mat-select placeholder="Vendor Master" [value]="trips?.assignedVendorMaster?.id"
                                (selectionChange)="onVendorSelect($event,
                                      getIndex(trips))">
                                <mat-option *ngFor="let vendor of _mVendors"
                                  style=" font-size: 13px !important; font-weight: 400 !important;" [value]="vendor.id">
                                  {{vendor.name}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="col-sm-2 col-md-2">
                            <mat-form-field appearance="outline" class="customFieldInSideVehicle-admin">
                              <input type="text" [ngModelOptions]="{standalone: true}" (keyup)="clearDriver(vehicleSearch[i],i,
                          getIndex(trips))" (keypress)="omit_special_char_And_String($event)" maxlength="12"
                                [(ngModel)]="vehicleSearch[i]" placeholder="Vehicle Number" matInput
                                [matAutocomplete]="vehicleNoAuto" (input)="searchFilter($event)" required [disabled]="(trips.tripVendorAssign.DeviceTripStatus
                          == 'not started') ? false : true"
                                (click)="trips?.assignedVendorMaster?.id ? getDriverData(trips.assignedVendorMaster.id) : null">
                              <img matSuffix src="assets/images/search.svg" height="27"
                                style="margin-top: -22px;padding-right: 5px;">
                              <mat-autocomplete #vehicleNoAuto="matAutocomplete"
                                (optionSelected)="vehicleSearchSubmit(trips,$event,getIndex(trips))">
                                <mat-option *ngFor="let vehicle of
                                vehicleListData | vehicleSearchFilter  : vehicleSearch[i]"
                                  style=" font-size: 13px !important; font-weight: 400 !important;"
                                  [value]="vehicle.registrationNo">{{vehicle.registrationNo}}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>

                          <div class="col-sm-2 col-md-2">
                            <mat-form-field appearance="outline" class="customFieldInSideVehicle-admin">
                              <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="driverSearch[i]"
                                (keypress)="omit_special_char_And_String($event)" maxlength="30"
                                placeholder="Choose Driver" matInput [matAutocomplete]="driverName" required [disabled]="(trips.tripVendorAssign.DeviceTripStatus
                          == 'not started') ? false : true">
                              <img matSuffix src="assets/images/search.svg" height="27"
                                style="margin-top: -22px;padding-right: 5px;">
                              <mat-autocomplete #driverName="matAutocomplete">
                                <mat-option *ngFor="let driver of
                            trips.driverList | vehicleDriverSearchFilter : driverSearch[i]" (onSelectionChange)="driverSearchSubmit(trips,driver,
                            getIndex(trips))" style=" font-size: 13px !important; font-weight: 400 !important;"
                                  [value]="driver.name">{{driver.name}}</mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>

                          <div class="col-sm-2 col-md-2">
                            <mat-form-field appearance="outline" class="escort-tracktrip-admin">
                              <mat-select placeholder="Escort User" [value]="trips?.escort?.id" [disabled]="((trips.tripVendorAssign.DeviceTripStatus
                              == 'not started') ? false : true) ? true :
                              (trips.escortStatus == 'No') ? true : false" (selectionChange)="updateEscortValue($event,
                                      getIndex(trips))">
                                <mat-option *ngFor="let escort of
                                          escortListData"
                                  style=" font-size: 13px !important; font-weight: 400 !important;" [value]="escort.id">
                                  {{escort.name}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>

                          <div class="col-sm-2 col-md-2" *ngIf="trips.logType =='IN'">
                            <mat-form-field appearance="outline" class="escort-tracktrip-admin">
                              <input placeholder="Travel time (min)" value="{{trips.tripTravelTime}}" type="text"
                                maxlength="4" (keypress)="discard_special_char_And_String($event)"
                                (keyup)="setTime($event,trips)" matInput>
                              <img matSuffix src="assets/images/save.svg" height="17"
                                style="margin-bottom: 22px; padding-right: 5px; cursor: pointer;" (click)="updateTravelTime(trips,
                          getIndex(trips))" matTooltip="Update">
                            </mat-form-field>
                          </div>

                          <div class="col-sm-2 col-md-2">
                            <div
                              style="font-size: 13px; font-weight: 400; font-family: 'poppins' !important; position: relative; top: 5px">
                              Trip Distance
                              :
                              {{trips.tripDistance | number :'1.2-2'}} Km</div>
                          </div>
                        </div>

                        <div class="row mt-3">
                          <div class="col-sm-2 col-md-2" *ngIf="trips.logType
                          =='OUT'">
                            <div
                              style="font-size: 13px; font-weight: 400; font-family: 'poppins' !important; position: relative; top: 5px">
                              Travel Time :
                              {{trips.tripTravelTime}}</div>
                          </div>

                          <div class="col-sm-2 col-md-2"
                            *ngIf="tripDateValidate && assignVehicle && ((trips?.driver) ?false : true)">
                            <button (click)="setDriverAndSendSMS(trips,escortListData,
                          getIndex(trips))" class="btn red-btn-solid-other-admin" style="padding: 7px 36px;">
                              Set Driver & Send SMS
                            </button>
                          </div>

                          <div class="col-sm-2 col-md-2" *ngIf="tripDateValidate && !((trips?.driver) ?false : true)">
                            <button (click)="setDriverAndSendSMS(trips,escortListData,
                          getIndex(trips))" class="btn red-btn-solid-other-admin" style="padding: 7px 63px;">
                              Re-Send SMS
                            </button>
                          </div>

                          <div class="col-sm-2 col-md-2">
                            <button class="btn red-btn-solid-other-admin" (click)="removeVDAllocation(trips)"
                              *ngIf="tripDateValidate && !((trips?.driver) ?false : true)" style="padding: 7px 45px;">
                              Remove Allocation
                            </button>
                          </div>
                        </div>

                        <div class="row mt-1">
                          <div class="col-sm-12 col-md-12">
                            <table class="table table-track-trips-admin m-0">
                              <thead>
                                <th>Employee Id</th>
                                <th>Name</th>
                                <th>Number</th>
                                <th>Gender</th>
                                <th *ngIf="address">Address</th>
                                <th *ngIf="areaLandmark">Area</th>
                                <th *ngIf="areaLandmark">Landmark</th>
                                <th>Show Status </th>
                                <th>Distance</th>
                                <th>
                                  <span
                                    style="display: flex; justify-content: space-between; width: 100%; align-items: center;">
                                    <span>Time</span>
                                    <button class="btn red-btn-solid-time-admin" *ngIf="trips.logType =='IN'" (click)="updateEmployeeTravelTime(trips,
                            getIndex(trips))">Update</button>
                                  </span>
                                </th>
                                <th *ngIf="tripDateValidate &&
                            ((trips?.driver) ?
                            true : false)" style="text-align: center;"> Send SMS</th>
                              </thead>
                              <tbody>
                                <tr *ngFor="let users of trips.tripUserList">
                                  <td> <span class="badge bfont-admin"
                                      [ngStyle]="{'color': users.userColor, 'background': '#fff', 'border': '1px solid' + users.userColor}">
                                      {{users.tripUser.userInfoDetails.employeeId}}
                                    </span>
                                  </td>
                                  <td>{{users.tripUser.userInfoDetails.firstName}}</td>
                                  <td *ngIf="!showContact">{{users.tripUser.userInfoDetails.contactNumber.substring(0,
                                    1)}}*******{{users.tripUser.userInfoDetails.contactNumber.substring(8, 10)}}</td>
                                  <td *ngIf="showContact">
                                    {{users.tripUser.userInfoDetails.contactNumber}}
                                  </td>
                                  <td>
                                    {{users.tripUser.userInfoDetails.gender == null ? "NA" :
                                    users.tripUser.userInfoDetails.gender == 'Male' ? 'M' :
                                    users.tripUser.userInfoDetails.gender == 'Female' ? 'F' : 'O'}}
                                  </td>
                                  <!-- <td>
                                    {{users.landmark.area.area}}
                                  </td>
                                  <td *ngIf="!showHideLandmark">
                                    {{users.landmark.landmark.substring(0, 5)}}*********
                                  </td>
                                  <td class="landmark-wrap-text-admin" *ngIf="showHideLandmark">
                                    {{users.landmark.landmark}}
                                  </td> -->
                                  <td class="landmark-wrap-text-admin" *ngIf="address && !showHideAddress">
                                    {{ users.landmark.type == "H" ? (users.landmark.area !== null ?
                                    users.landmark.area.area : "NA") + ", " +
                                    (users.landmark.landmark !== null ? users.landmark.landmark : "NA") :
                                    users.userLandmark !== null ?
                                    users.userLandmark.area.area + ", " + users.userLandmark.landmark :
                                    users.tripUser.userInfoDetails.address == null ? "NA" :
                                    users.tripUser.userInfoDetails.address}}
                                  </td>
                                  <td *ngIf="showHideAddress && mTitle == 'Address'">
                                    {{users.landmark.type == "H" ? (users.landmark.area !== null ?
                                    users.landmark.area.area.substring(0, 5) : "NA") : users.userLandmark !== null ?
                                    users.userLandmark.area.area.substring(0, 5) :
                                    users.tripUser.userInfoDetails.address == null ? "NA" :
                                    users.tripUser.userInfoDetails.address.substring(0, 5)}}*********
                                  </td>
                                  <td *ngIf="areaLandmark">
                                    {{users.landmark.type == "C" ? (users.landmark.area !== null ?
                                    users.landmark.area.area : "NA") : users.userLandmark !== null ?
                                    users.userLandmark.area.area : users.tripUser.userInfoDetails.pickUpDrop ==
                                    null ? "NA" : users.tripUser.userInfoDetails.pickUpDrop.area.area}}
                                  </td>
                                  <td *ngIf="showHideLandmark && mTitle == 'Landmark'">
                                    {{users.landmark.type == "C" ? (users.landmark.landmark !== null ?
                                    users.landmark.landmarksubstring(0, 5) : "NA") : users.userLandmark
                                    !== null ? users.userLandmark.landmarksubstring(0, 5) :
                                    users.tripUser.userInfoDetails.pickUpDrop == null ? "NA" :
                                    users.tripUser.userInfoDetails.pickUpDrop.landmark.substring(0, 5)}}*********
                                  </td>
                                  <td class="landmark-wrap-text-admin" *ngIf="areaLandmark && !showHideLandmark">
                                    {{users.landmark.type == "C" ? users.landmark.landmark : users.userLandmark
                                    !== null ? users.userLandmark.landmark :
                                    users.tripUser.userInfoDetails.pickUpDrop == null ? "NA" :
                                    users.tripUser.userInfoDetails.pickUpDrop.landmark}}
                                  </td>
                                  <td>
                                    {{users.showStatus}}
                                  </td>
                                  <td>
                                    {{users.empTripDistance | number
                                    :'1.2-2'}} Km
                                  </td>
                                  <td *ngIf="trips.logType=='OUT'">
                                    {{users.pickupDropTime ?
                                    users.pickupDropTime.slice(0,5) :
                                    'NA'}}
                                  </td>
                                  <td>
                                    <input class="input-field-admin me-1" placeholder="Time* (Eg: 00:00)"
                                      *ngIf="trips.logType =='IN'" (keypress)="omit_special_char_And_String1($event)"
                                      autocomplete="off" maxlength="5"
                                      value="{{users.pickupDropTime ?users.pickupDropTime.slice(0,5) : ''}}"
                                      (change)="onTimeValidate($event, users, trips, getIndex(trips))">
                                    <!-- <span *ngIf="trips.logType =='IN'" (click)="updateEmployeeTravelTime(emp)">
                              <img src="assets/images/save.svg" style="cursor: pointer;"  height="15" matTooltip="Update">
                            </span> -->
                                  </td>
                                  <td style="text-align: center;" *ngIf="tripDateValidate &&
                              ((trips?.driver) ?
                              true : false)">
                                    <button class="red-btn-solid-resend-admin"
                                      (click)="resendOtp(users, trips)">Resend_OTP</button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-2 col-md-2 mt-3">
              <button class="btn red-btn-solid-other-admin" (click)="onSubmitAloc()">Submit Allocation
              </button>
            </div>
          </div>

          <!-- Allocated Trips -->

          <div *ngIf="allocatedTripFlag">
            <div class="row">
              <div class="col-sm-3 mt-3">
                <div class="d-flex justify-content-between" style="width: 250px;">
                  <input class="form-control icon s-input b-0" type="text" style="font-size: 13px;"
                    placeholder="Search by EmpId/Name" [(ngModel)]="searchTrip">
                </div>
              </div>
              <div class="col-sm-2 mt-2">
                <ui-switch defaultBoColor="#dfdfdf" color="#34c38f" [(ngModel)]="showOrHideAL"
                  [checked]="(showOrHideAL == true) ? true : false" style="position: relative; top: 10px;"
                  (valueChange)="showOrHideTitle($event)"></ui-switch>
                <span
                  style="position: relative; left: 15px; top: 5px; font-size: 14px;font-weight: 500;font-family: 'Poppins';">Show/Hide
                  {{mTitle}}</span>
              </div>
              <div class="col-sm-2 mt-2">
                <span
                  style="position: relative; left: 15px; top: 5px; font-size: 14px;font-weight: 500;font-family: 'Poppins';">Address</span>
                <ui-switch defaultBoColor="#dfdfdf" color="#34c38f" [(ngModel)]="addressOrAL"
                  [checked]="(addressOrAL == true) ? true : false" style="position: relative; top: 10px; left: 18px;"
                  (valueChange)="showOrHideAddress($event)"></ui-switch>
                <span
                  style="position: relative; left: 25px; top: 5px; font-size: 14px;font-weight: 500;font-family: 'Poppins';">A/L</span>
              </div>
              <div class="col-sm-2 mt-2">
                <ui-switch style="position: relative; top: 10px;" defaultBoColor="#dfdfdf" color="#34c38f"
                  [(ngModel)]="showContact" [checked]="(showContact == true) ? true : false"
                  (valueChange)="showOrHideContact($event)"></ui-switch>
                <span
                  style="position: relative; left: 15px; top: 5px; font-size: 14px;font-weight: 500;font-family: 'Poppins';">Show/Hide
                  Contact</span>
              </div>

              <div class="col-sm-3 mt-3">
                <button class="btn-solid-select-all-admin" style="position: relative; right: 2px;"
                  (click)="onSelectAll(tripDetailsData, escortListData)">Select
                  All</button>
              </div>
            </div>

            <!-- loop starts here -->


            <div *ngFor="let trips of allocatedTripArr |
          allocatedTripFilter : searchTrip; let i=index;">
              <div class="card p-0 mb-2">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-12 col-md-12" style="background-color: #FBFCFF !important;padding-top: 10px; margin-left: 1px;margin-right: 1px; 
                        height: 45px; box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14), 
                        0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 1px -2px rgba(0, 0, 0, 0.2);">
                      <div class="summarytracktrips-admin">
                        <div>
                          <span style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Trip
                            ID
                            : </span><span class="trip-code-admin"
                            [ngStyle]="trips.tripsColor ? 
                              {'color': trips.tripsColor, 'background': '#fff', 'border': '1px solid' + trips.tripsColor, 
                              'font-size': '13px', 'font-weight': '400', 'font-family': 'Poppins'} : 
                              {'color': '#7C7D84', 'font-size': '13px', 'font-weight': '400', 'font-family': 'Poppins'}"
                            (click)=maptrackview(trips.tripId)>{{getExtractedSubstring(trips.tripCode)}}</span>
                        </div>
                        <div>
                          <span style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Trip
                            Date
                            : </span><span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">
                            {{trips.tripDate}}</span>
                        </div>
                        <div>
                          <span style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Shift
                            : </span><span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">
                            {{trips.logType}}-{{trips.shiftTime}}</span>
                        </div>
                        <div>
                          <span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Vehicle
                            Type
                            : </span><span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">
                            {{trips.vehicleType}}</span>
                        </div>
                        <div>
                          <span style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Trip
                            Status
                            : </span><span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">
                            {{trips.tripVendorAssign.DeviceTripStatus}}</span>
                        </div>
                        <div>
                          <span style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Escort
                            Trip
                            : </span><span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">
                            {{trips.escortStatus}}</span>
                        </div>

                        <div>
                          <span class="action-container-admin">
                            <a href="javascript: void(0);" title="Audit" (click)="getAudit(trips)">
                              <img src="assets/images/icons/audit.svg">
                            </a>
                            <mat-checkbox class="tracktripsCheckbox-admin" type="checkbox" [checked]="trips.checked"
                              style="position: relative; bottom: 9px; left: 0px;"
                              (change)="onCheck($event.target, trips, escortListData)"></mat-checkbox>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-sm-12 col-md-12">
                      <form [formGroup]="_mFormGroupForTrackTrips">
                        <div class="row">
                          <div class="col-sm-2 col-md-2">
                            <mat-form-field appearance="outline" class="escort-tracktrip-admin"
                              *ngIf="_mVendors.length > 1">
                              <mat-select placeholder="Vendor Master" [value]="trips?.assignedVendorMaster?.id"
                                (selectionChange)="onVendorSelect($event,
                                      getIndex(trips))">
                                <mat-option *ngFor="let vendor of _mVendors"
                                  style=" font-size: 13px !important; font-weight: 400 !important;" [value]="vendor.id">
                                  {{vendor.name}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="escort-tracktrip-admin"
                              *ngIf="_mVendors.length == 1">
                              <mat-select placeholder="Vendor Master" [value]="trips?.assignedVendorMaster?.id"
                                (selectionChange)="onVendorSelect($event,
                                      getIndex(trips))">
                                <mat-option *ngFor="let vendor of _mVendors"
                                  style=" font-size: 13px !important; font-weight: 400 !important;" [value]="vendor.id">
                                  {{vendor.name}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="col-sm-2 col-md-2">
                            <mat-form-field appearance="outline" class="customFieldInSideVehicle-admin">
                              <input type="text" [ngModelOptions]="{standalone: true}" (keyup)="clearDriver(vehicleSearch[i],i,
                          getIndex(trips))" (keypress)="omit_special_char_And_String($event)" maxlength="12"
                                [(ngModel)]="vehicleSearch[i]" placeholder="Vehicle Number" matInput
                                [matAutocomplete]="vehicleNoAuto" (input)="searchFilter($event)" required [disabled]="(trips.tripVendorAssign.DeviceTripStatus
                          == 'not started') ? false : true">
                              <img matSuffix src="assets/images/search.svg" height="27"
                                style="margin-top: -22px;padding-right: 5px;">
                              <mat-autocomplete #vehicleNoAuto="matAutocomplete"
                                (optionSelected)="vehicleSearchSubmit(trips,$event,getIndex(trips))">
                                <mat-option *ngFor="let vehicle of
                                vehicleListData | vehicleSearchFilter  : vehicleSearch[i]"
                                  style=" font-size: 13px !important; font-weight: 400 !important;"
                                  [value]="vehicle.registrationNo">{{vehicle.registrationNo}}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>

                          <div class="col-sm-2 col-md-2">
                            <mat-form-field appearance="outline" class="customFieldInSideVehicle-admin">
                              <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="driverSearch[i]"
                                (keypress)="omit_special_char_And_String($event)" maxlength="30"
                                placeholder="Choose Driver" matInput [matAutocomplete]="driverName" required [disabled]="(trips.tripVendorAssign.DeviceTripStatus
                          == 'not started') ? false : true">
                              <img matSuffix src="assets/images/search.svg" height="27"
                                style="margin-top: -22px;padding-right: 5px;">
                              <mat-autocomplete #driverName="matAutocomplete">
                                <mat-option *ngFor="let driver of
                            trips.driverList | vehicleDriverSearchFilter : driverSearch[i]" (onSelectionChange)="driverSearchSubmit(trips,driver,
                            getIndex(trips))" style=" font-size: 13px !important; font-weight: 400 !important;"
                                  [value]="driver.name">{{driver.name}}</mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>

                          <div class="col-sm-2 col-md-2">
                            <mat-form-field appearance="outline" class="escort-tracktrip-admin">
                              <mat-select placeholder="Escort User" [value]="trips?.escort?.id" [disabled]="((trips.tripVendorAssign.DeviceTripStatus
                              == 'not started') ? false : true) ? true :
                              (trips.escortStatus == 'No') ? true : false" (selectionChange)="updateEscortValue($event,
                                      getIndex(trips))">
                                <mat-option *ngFor="let escort of
                                          escortListData"
                                  style=" font-size: 13px !important; font-weight: 400 !important;" [value]="escort.id">
                                  {{escort.name}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>

                          <div class="col-sm-2 col-md-2" *ngIf="trips.logType =='IN'">
                            <mat-form-field appearance="outline" class="escort-tracktrip-admin">
                              <input placeholder="Travel time (min)" value="{{trips.tripTravelTime}}" type="text"
                                maxlength="4" (keypress)="discard_special_char_And_String($event)"
                                (keyup)="setTime($event,trips)" matInput>
                              <img matSuffix src="assets/images/save.svg" height="17"
                                style="margin-bottom: 22px; padding-right: 5px; cursor: pointer;" (click)="updateTravelTime(trips,
                          getIndex(trips))" matTooltip="Update">
                            </mat-form-field>
                          </div>

                          <div class="col-sm-2 col-md-2">
                            <div
                              style="font-size: 13px; font-weight: 400; font-family: 'poppins' !important; position: relative; top: 5px">
                              Trip Distance
                              :
                              {{trips.tripDistance | number :'1.2-2'}} Km</div>
                          </div>
                        </div>

                        <div class="row mt-3">
                          <div class="col-sm-2 col-md-2" *ngIf="trips.logType
                          =='OUT'">
                            <div
                              style="font-size: 13px; font-weight: 400; font-family: 'poppins' !important; position: relative; top: 5px">
                              Travel Time :
                              {{trips.tripTravelTime}}</div>
                          </div>

                          <div class="col-sm-2 col-md-2"
                            *ngIf="tripDateValidate && assignVehicle && ((trips?.driver) ?false : true)">
                            <button (click)="setDriverAndSendSMS(trips,escortListData,
                          getIndex(trips))" class="btn red-btn-solid-other-admin" style="padding: 7px 36px;">
                              Set Driver & Send SMS
                            </button>
                          </div>

                          <div class="col-sm-2 col-md-2" *ngIf="tripDateValidate && !((trips?.driver) ?false : true)">
                            <button (click)="setDriverAndSendSMS(trips,escortListData,
                          getIndex(trips))" class="btn red-btn-solid-other-admin" style="padding: 7px 63px;">
                              Re-Send SMS
                            </button>
                          </div>

                          <div class="col-sm-2 col-md-2">
                            <button class="btn red-btn-solid-other-admin" (click)="removeVDAllocation(trips)"
                              *ngIf="tripDateValidate && !((trips?.driver) ?false : true)" style="padding: 7px 45px;">
                              Remove Allocation
                            </button>
                          </div>
                        </div>

                        <div class="row mt-1">
                          <div class="col-sm-12 col-md-12">
                            <table class="table table-track-trips-admin m-0">
                              <thead>
                                <th>Employee Id</th>
                                <th>Name</th>
                                <th>Number</th>
                                <th>Gender</th>
                                <th *ngIf="maskingEnabled && address">Address</th>
                                <th *ngIf="areaLandmark">Area</th>
                                <th *ngIf="areaLandmark">Landmark</th>
                                <th>Show Status </th>
                                <th>Distance</th>
                                <th>
                                  <span
                                    style="display: flex; justify-content: space-between; width: 100%; align-items: center;">
                                    <span>Time</span>
                                    <button class="btn red-btn-solid-time-admin" *ngIf="trips.logType =='IN'" (click)="updateEmployeeTravelTime(trips,
                            getIndex(trips))">Update</button>
                                  </span>
                                </th>
                                <th *ngIf="tripDateValidate &&
                            ((trips?.driver) ?
                            true : false)" style="text-align: center;"> Send SMS</th>
                              </thead>

                              <tbody>
                                <tr *ngFor="let users of trips.tripUserList">
                                  <td> <span class="badge bfont-admin"
                                      [ngStyle]="{'color': users.userColor, 'background': '#fff', 'border': '1px solid' + users.userColor}">
                                      {{users.tripUser.userInfoDetails.employeeId}}
                                    </span>
                                  </td>
                                  <td>{{users.tripUser.userInfoDetails.firstName}}</td>
                                  <td *ngIf="!showContact">{{users.tripUser.userInfoDetails.contactNumber.substring(0,
                                    1)}}*******{{users.tripUser.userInfoDetails.contactNumber.substring(8, 10)}}</td>
                                  <td *ngIf="showContact">
                                    {{users.tripUser.userInfoDetails.contactNumber}}
                                  </td>
                                  <td>
                                    {{users.tripUser.userInfoDetails.gender == null ? "NA" :
                                    users.tripUser.userInfoDetails.gender == 'Male' ? 'M' :
                                    users.tripUser.userInfoDetails.gender == 'Female' ? 'F' : 'O'}}
                                  </td>
                                  <!-- <td>
                                    {{users.landmark.area.area}}
                                  </td>
                                  <td *ngIf="!showHideLandmark">
                                    {{users.landmark.landmark.substring(0, 5)}}*********
                                  </td>
                                  <td class="landmark-wrap-text-admin" *ngIf="showHideLandmark">
                                    {{users.landmark.landmark}}
                                  </td> -->
                                  <td class="landmark-wrap-text-admin" *ngIf="address && !showHideAddress">
                                    {{ users.landmark.type == "H" ? (users.landmark.area !== null ?
                                    users.landmark.area.area : "NA") + ", " +
                                    (users.landmark.landmark !== null ? users.landmark.landmark : "NA") :
                                    users.userLandmark !== null ?
                                    users.userLandmark.area.area + ", " + users.userLandmark.landmark :
                                    users.tripUser.userInfoDetails.address == null ? "NA" :
                                    users.tripUser.userInfoDetails.address}}
                                  </td>
                                  <td *ngIf="showHideAddress && mTitle == 'Address'">
                                    {{users.landmark.type == "H" ? (users.landmark.area !== null ?
                                    users.landmark.area.area.substring(0, 5) : "NA") : users.userLandmark !== null ?
                                    users.userLandmark.area.area.substring(0, 5) :
                                    users.tripUser.userInfoDetails.address == null ? "NA" :
                                    users.tripUser.userInfoDetails.address.substring(0, 5)}}*********
                                  </td>
                                  <td *ngIf="areaLandmark">
                                    {{users.landmark.type == "C" ? (users.landmark.area !== null ?
                                    users.landmark.area.area : "NA") : users.userLandmark !== null ?
                                    users.userLandmark.area.area : users.tripUser.userInfoDetails.pickUpDrop ==
                                    null ? "NA" : users.tripUser.userInfoDetails.pickUpDrop.area.area}}
                                  </td>
                                  <td *ngIf="showHideLandmark && mTitle == 'Landmark'">
                                    {{users.landmark.type == "C" ? (users.landmark.landmark !== null ?
                                    users.landmark.landmark.substring(0, 5) : "NA") : users.userLandmark
                                    !== null ? users.userLandmark.landmark.substring(0, 5) :
                                    users.tripUser.userInfoDetails.pickUpDrop == null ? "NA" :
                                    users.tripUser.userInfoDetails.pickUpDrop.landmark.substring(0, 5)}}*********
                                  </td>
                                  <td class="landmark-wrap-text-admin" *ngIf="areaLandmark && !showHideLandmark">
                                    {{users.landmark.type == "C" ? users.landmark.landmark : users.userLandmark
                                    !== null ? users.userLandmark.landmark :
                                    users.tripUser.userInfoDetails.pickUpDrop == null ? "NA" :
                                    users.tripUser.userInfoDetails.pickUpDrop.landmark}}
                                  </td>
                                  <td>
                                    {{users.showStatus}}
                                  </td>
                                  <td>
                                    {{users.empTripDistance | number
                                    :'1.2-2'}} Km
                                  </td>
                                  <td *ngIf="trips.logType=='OUT'">
                                    {{users.pickupDropTime ?
                                    users.pickupDropTime.slice(0,5) :
                                    'NA'}}
                                  </td>
                                  <td>
                                    <input class="input-field-admin me-1" placeholder="Time* (Eg: 00:00)"
                                      *ngIf="trips.logType =='IN'" (keypress)="omit_special_char_And_String1($event)"
                                      autocomplete="off" maxlength="5"
                                      value="{{users.pickupDropTime ?users.pickupDropTime.slice(0,5) : ''}}"
                                      (change)="onTimeValidate($event, users, trips, getIndex(trips))">
                                    <!-- <span *ngIf="trips.logType =='IN'" (click)="updateEmployeeTravelTime(emp)">
                              <img src="assets/images/save.svg" style="cursor: pointer;"  height="15" matTooltip="Update">
                            </span> -->
                                  </td>
                                  <td style="text-align: center;" *ngIf="tripDateValidate &&
                              ((trips?.driver) ?
                              true : false)">
                                    <button class="red-btn-solid-resend-admin"
                                      (click)="resendOtp(users, trips)">Resend_OTP</button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-2 col-md-2 mt-3">
              <button class="btn red-btn-solid-other-admin" (click)="onSubmitAloc()">Submit Allocation
              </button>
            </div>
          </div>

          <!-- Allocated Trips End -->
          <!-- UnAllocated Trips -->

          <div *ngIf="unAllocatedTripFlag">
            <div class="row">
              <div class="col-sm-3 mt-3">
                <div class="d-flex justify-content-between" style="width: 250px;">
                  <input class="form-control icon s-input b-0" type="text" style="font-size: 13px;"
                    placeholder="Search by EmpId/Name" [(ngModel)]="searchTrip">
                </div>
              </div>
              <div class="col-sm-2 mt-2">
                <ui-switch defaultBoColor="#dfdfdf" color="#34c38f" [(ngModel)]="showOrHideAL"
                  [checked]="(showOrHideAL == true) ? true : false" style="position: relative; top: 10px;"
                  (valueChange)="showOrHideTitle($event)"></ui-switch>
                <span
                  style="position: relative; left: 15px; top: 5px; font-size: 14px;font-weight: 500;font-family: 'Poppins';">Show/Hide
                  {{mTitle}}</span>
              </div>
              <div class="col-sm-2 mt-2">
                <span
                  style="position: relative; left: 15px; top: 5px; font-size: 14px;font-weight: 500;font-family: 'Poppins';">Address</span>
                <ui-switch defaultBoColor="#dfdfdf" color="#34c38f" [(ngModel)]="addressOrAL"
                  [checked]="(addressOrAL == true) ? true : false" style="position: relative; top: 10px; left: 18px;"
                  (valueChange)="showOrHideAddress($event)"></ui-switch>
                <span
                  style="position: relative; left: 25px; top: 5px; font-size: 14px;font-weight: 500;font-family: 'Poppins';">A/L</span>
              </div>
              <div class="col-sm-2 mt-2">
                <ui-switch style="position: relative; top: 10px;" defaultBoColor="#dfdfdf" color="#34c38f"
                  [(ngModel)]="showContact" [checked]="(showContact == true) ? true : false"
                  (valueChange)="showOrHideContact($event)"></ui-switch>
                <span
                  style="position: relative; left: 15px; top: 5px; font-size: 14px;font-weight: 500;font-family: 'Poppins';">Show/Hide
                  Contact</span>
              </div>

              <div class="col-sm-3 mt-3">
                <button class="btn-solid-select-all-admin" style="position: relative; right: 2px;"
                  (click)="onSelectAll(tripDetailsData, escortListData)">Select
                  All</button>
              </div>
            </div>

            <!-- loop starts here -->


            <div *ngFor="let trips of unAllocatedTripArr |
      allocatedTripFilter : searchTrip; let i=index;">
              <div class="card p-0 mb-2">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-12 col-md-12" style="background-color: #FBFCFF !important;padding-top: 10px; margin-left: 1px;margin-right: 1px; 
                        height: 45px; box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14), 
                        0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 1px -2px rgba(0, 0, 0, 0.2);">
                      <div class="summarytracktrips-admin">
                        <div>
                          <span style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Trip
                            ID
                            : </span><span class="trip-code-admin"
                            [ngStyle]="trips.tripsColor ? 
                              {'color': trips.tripsColor, 'background': '#fff', 'border': '1px solid' + trips.tripsColor, 
                              'font-size': '13px', 'font-weight': '400', 'font-family': 'Poppins'} : 
                              {'color': '#7C7D84', 'font-size': '13px', 'font-weight': '400', 'font-family': 'Poppins'}"
                            (click)=maptrackview(trips.tripId)>{{getExtractedSubstring(trips.tripCode)}}</span>
                        </div>
                        <div>
                          <span style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Trip
                            Date
                            : </span><span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">
                            {{trips.tripDate}}</span>
                        </div>
                        <div>
                          <span style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Shift
                            : </span><span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">
                            {{trips.logType}}-{{trips.shiftTime}}</span>
                        </div>
                        <div>
                          <span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Vehicle
                            Type
                            : </span><span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">
                            {{trips.vehicleType}}</span>
                        </div>
                        <div>
                          <span style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Trip
                            Status
                            : </span><span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">
                            {{trips.tripVendorAssign.DeviceTripStatus}}</span>
                        </div>
                        <div>
                          <span style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">Escort
                            Trip
                            : </span><span
                            style="color: #7C7D84; font-size: 13px; font-weight: 400;font-family: 'Poppins';">
                            {{trips.escortStatus}}</span>
                        </div>

                        <div>
                          <span class="action-container-admin">
                            <a href="javascript: void(0);" title="Audit" (click)="getAudit(trips)">
                              <img src="assets/images/icons/audit.svg">
                            </a>
                            <mat-checkbox class="tracktripsCheckbox-admin" type="checkbox" [checked]="trips.checked"
                              style="position: relative; bottom: 9px; left: 0px;"
                              (change)="onCheck($event.target, trips, escortListData)"></mat-checkbox>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-sm-12 col-md-12">
                      <form [formGroup]="_mFormGroupForTrackTrips">
                        <div class="row">
                          <div class="col-sm-2 col-md-2">
                            <mat-form-field appearance="outline" class="escort-tracktrip-admin"
                              *ngIf="_mVendors.length > 1">
                              <mat-select placeholder="Vendor Master" [value]="trips?.assignedVendorMaster?.id"
                                (selectionChange)="onVendorSelect($event,
                                      getIndex(trips))">
                                <mat-option *ngFor="let vendor of _mVendors"
                                  style=" font-size: 13px !important; font-weight: 400 !important;" [value]="vendor.id">
                                  {{vendor.name}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="escort-tracktrip-admin"
                              *ngIf="_mVendors.length == 1">
                              <mat-select placeholder="Vendor Master" [value]="trips?.assignedVendorMaster?.id"
                                (selectionChange)="onVendorSelect($event,
                                      getIndex(trips))">
                                <mat-option *ngFor="let vendor of _mVendors"
                                  style=" font-size: 13px !important; font-weight: 400 !important;" [value]="vendor.id">
                                  {{vendor.name}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="col-sm-2 col-md-2">
                            <mat-form-field appearance="outline" class="customFieldInSideVehicle-admin">
                              <input type="text" [ngModelOptions]="{standalone: true}" (keyup)="clearDriver(vehicleSearch[i],i,
                          getIndex(trips))" (keypress)="omit_special_char_And_String($event)" maxlength="12"
                                [(ngModel)]="vehicleSearch[i]" placeholder="Vehicle Number" matInput
                                [matAutocomplete]="vehicleNoAuto" (input)="searchFilter($event)" required [disabled]="(trips.tripVendorAssign.DeviceTripStatus
                          == 'not started') ? false : true">
                              <img matSuffix src="assets/images/search.svg" height="27"
                                style="margin-top: -22px;padding-right: 5px;">
                              <mat-autocomplete #vehicleNoAuto="matAutocomplete"
                                (optionSelected)="vehicleSearchSubmit(trips,$event,getIndex(trips))">
                                <mat-option *ngFor="let vehicle of
                                vehicleListData | vehicleSearchFilter  : vehicleSearch[i]"
                                  style=" font-size: 13px !important; font-weight: 400 !important;"
                                  [value]="vehicle.registrationNo">{{vehicle.registrationNo}}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>

                          <div class="col-sm-2 col-md-2">
                            <mat-form-field appearance="outline" class="customFieldInSideVehicle-admin">
                              <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="driverSearch[i]"
                                (keypress)="omit_special_char_And_String($event)" maxlength="30"
                                placeholder="Choose Driver" matInput [matAutocomplete]="driverName" required [disabled]="(trips.tripVendorAssign.DeviceTripStatus
                          == 'not started') ? false : true">
                              <img matSuffix src="assets/images/search.svg" height="27"
                                style="margin-top: -22px;padding-right: 5px;">
                              <mat-autocomplete #driverName="matAutocomplete">
                                <mat-option *ngFor="let driver of
                            trips.driverList | vehicleDriverSearchFilter : driverSearch[i]" (onSelectionChange)="driverSearchSubmit(trips,driver,
                            getIndex(trips))" style=" font-size: 13px !important; font-weight: 400 !important;"
                                  [value]="driver.name">{{driver.name}}</mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>

                          <div class="col-sm-2 col-md-2">
                            <mat-form-field appearance="outline" class="escort-tracktrip-admin">
                              <mat-select placeholder="Escort User" [value]="trips?.escort?.id" [disabled]="((trips.tripVendorAssign.DeviceTripStatus
                              == 'not started') ? false : true) ? true :
                              (trips.escortStatus == 'No') ? true : false" (selectionChange)="updateEscortValue($event,
                                      getIndex(trips))">
                                <mat-option *ngFor="let escort of
                                          escortListData"
                                  style=" font-size: 13px !important; font-weight: 400 !important;" [value]="escort.id">
                                  {{escort.name}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>

                          <div class="col-sm-2 col-md-2" *ngIf="trips.logType =='IN'">
                            <mat-form-field appearance="outline" class="escort-tracktrip-admin">
                              <input placeholder="Travel time (min)" value="{{trips.tripTravelTime}}" type="text"
                                maxlength="4" (keypress)="discard_special_char_And_String($event)"
                                (keyup)="setTime($event,trips)" matInput>
                              <img matSuffix src="assets/images/save.svg" height="17"
                                style="margin-bottom: 22px; padding-right: 5px; cursor: pointer;" (click)="updateTravelTime(trips,
                          getIndex(trips))" matTooltip="Update">
                            </mat-form-field>
                          </div>

                          <div class="col-sm-2 col-md-2">
                            <div
                              style="font-size: 13px; font-weight: 400; font-family: 'poppins' !important; position: relative; top: 5px">
                              Trip Distance
                              :
                              {{trips.tripDistance | number :'1.2-2'}} Km</div>
                          </div>
                        </div>

                        <div class="row mt-3">
                          <div class="col-sm-2 col-md-2" *ngIf="trips.logType
                          =='OUT'">
                            <div
                              style="font-size: 13px; font-weight: 400; font-family: 'poppins' !important; position: relative; top: 5px">
                              Travel Time :
                              {{trips.tripTravelTime}}</div>
                          </div>

                          <div class="col-sm-2 col-md-2"
                            *ngIf="tripDateValidate && assignVehicle && ((trips?.driver) ?false : true)">
                            <button (click)="setDriverAndSendSMS(trips,escortListData,
                          getIndex(trips))" class="btn red-btn-solid-other-admin" style="padding: 7px 36px;">
                              Set Driver & Send SMS
                            </button>
                          </div>

                          <div class="col-sm-2 col-md-2" *ngIf="tripDateValidate && !((trips?.driver) ?false : true)">
                            <button (click)="setDriverAndSendSMS(trips,escortListData,
                          getIndex(trips))" class="btn red-btn-solid-other-admin" style="padding: 7px 63px;">
                              Re-Send SMS
                            </button>
                          </div>

                          <div class="col-sm-2 col-md-2">
                            <button class="btn red-btn-solid-other-admin" (click)="removeVDAllocation(trips)"
                              *ngIf="tripDateValidate && !((trips?.driver) ?false : true)" style="padding: 7px 45px;">
                              Remove Allocation
                            </button>
                          </div>
                        </div>

                        <div class="row mt-1">
                          <div class="col-sm-12 col-md-12">
                            <table class="table table-track-trips-admin m-0">
                              <thead>
                                <th>Employee Id</th>
                                <th>Name</th>
                                <th>Number</th>
                                <th>Gender</th>
                                <th *ngIf="maskingEnabled && address">Address</th>
                                <th *ngIf="areaLandmark">Area</th>
                                <th *ngIf="areaLandmark">Landmark</th>
                                <th>Show Status </th>
                                <th>Distance</th>
                                <th>
                                  <span
                                    style="display: flex; justify-content: space-between; width: 100%; align-items: center;">
                                    <span>Time</span>
                                    <button class="btn red-btn-solid-time-admin" *ngIf="trips.logType =='IN'" (click)="updateEmployeeTravelTime(trips,
                            getIndex(trips))">Update</button>
                                  </span>
                                </th>
                                <th *ngIf="tripDateValidate &&
                            ((trips?.driver) ?
                            true : false)" style="text-align: center;"> Send SMS</th>
                              </thead>

                              <tbody>
                                <tr *ngFor="let users of trips.tripUserList">
                                  <td> <span class="badge bfont-admin"
                                      [ngStyle]="{'color': users.userColor, 'background': '#fff', 'border': '1px solid' + users.userColor}">
                                      {{users.tripUser.userInfoDetails.employeeId}}
                                    </span>
                                  </td>
                                  <td>{{users.tripUser.userInfoDetails.firstName}}</td>
                                  <td *ngIf="!showContact">{{users.tripUser.userInfoDetails.contactNumber.substring(0,
                                    1)}}*******{{users.tripUser.userInfoDetails.contactNumber.substring(8, 10)}}</td>
                                  <td *ngIf="showContact">
                                    {{users.tripUser.userInfoDetails.contactNumber}}
                                  </td>
                                  <td>
                                    {{users.tripUser.userInfoDetails.gender == null ? "NA" :
                                    users.tripUser.userInfoDetails.gender == 'Male' ? 'M' :
                                    users.tripUser.userInfoDetails.gender == 'Female' ? 'F' : 'O'}}
                                  </td>
                                  <!-- <td>
                                    {{users.landmark.area.area}}
                                  </td>
                                  <td *ngIf="!showHideLandmark">
                                    {{users.landmark.landmark.substring(0, 5)}}*********
                                  </td>
                                  <td class="landmark-wrap-text-admin" *ngIf="showHideLandmark">
                                    {{users.landmark.landmark}}
                                  </td> -->
                                  <td class="landmark-wrap-text-admin" *ngIf="address && !showHideAddress">
                                    {{ users.landmark.type == "H" ? (users.landmark.area !== null ?
                                    users.landmark.area.area : "NA") + ", " +
                                    (users.landmark.landmark !== null ? users.landmark.landmark : "NA") :
                                    users.userLandmark !== null ?
                                    users.userLandmark.area.area + ", " + users.userLandmark.landmark :
                                    users.tripUser.userInfoDetails.address == null ? "NA" :
                                    users.tripUser.userInfoDetails.address}}
                                  </td>
                                  <td *ngIf="showHideAddress && mTitle == 'Address'">
                                    {{users.landmark.type == "H" ? (users.landmark.area !== null ?
                                    users.landmark.area.area.substring(0, 5) : "NA") : users.userLandmark !== null ?
                                    users.userLandmark.area.area.substring(0, 5) :
                                    users.tripUser.userInfoDetails.address == null ? "NA" :
                                    users.tripUser.userInfoDetails.address.substring(0, 5)}}*********
                                  </td>
                                  <td *ngIf="areaLandmark">
                                    {{users.landmark.type == "C" ? (users.landmark.area !== null ?
                                    users.landmark.area.area : "NA") : users.userLandmark !== null ?
                                    users.userLandmark.area.area : users.tripUser.userInfoDetails.pickUpDrop ==
                                    null ? "NA" : users.tripUser.userInfoDetails.pickUpDrop.area.area}}
                                  </td>
                                  <td *ngIf="showHideLandmark && mTitle == 'Landmark'">
                                    {{users.landmark.type == "C" ? (users.landmark.landmark !== null ?
                                    users.landmark.landmark.substring(0, 5) : "NA") : users.userLandmark
                                    !== null ? users.userLandmark.landmark.substring(0, 5) :
                                    users.tripUser.userInfoDetails.pickUpDrop == null ? "NA" :
                                    users.tripUser.userInfoDetails.pickUpDrop.landmark.substring(0, 5)}}*********
                                  </td>
                                  <td class="landmark-wrap-text-admin" *ngIf="areaLandmark && !showHideLandmark">
                                    {{users.landmark.type == "C" ? users.landmark.landmark : users.userLandmark
                                    !== null ? users.userLandmark.landmark :
                                    users.tripUser.userInfoDetails.pickUpDrop == null ? "NA" :
                                    users.tripUser.userInfoDetails.pickUpDrop.landmark}}
                                  </td>
                                  <td>
                                    {{users.showStatus}}
                                  </td>
                                  <td>
                                    {{users.empTripDistance | number
                                    :'1.2-2'}} Km
                                  </td>
                                  <td *ngIf="trips.logType=='OUT'">
                                    {{users.pickupDropTime ?
                                    users.pickupDropTime.slice(0,5) :
                                    'NA'}}
                                  </td>
                                  <td>
                                    <input class="input-field-admin me-1" placeholder="Time* (Eg: 00:00)"
                                      *ngIf="trips.logType =='IN'" (keypress)="omit_special_char_And_String1($event)"
                                      autocomplete="off" maxlength="5"
                                      value="{{users.pickupDropTime ?users.pickupDropTime.slice(0,5) : ''}}"
                                      (change)="onTimeValidate($event, users, trips, getIndex(trips))">
                                    <!-- <span *ngIf="trips.logType =='IN'" (click)="updateEmployeeTravelTime(emp)">
                              <img src="assets/images/save.svg" style="cursor: pointer;"  height="15" matTooltip="Update">
                            </span> -->
                                  </td>
                                  <td style="text-align: center;" *ngIf="tripDateValidate &&
                              ((trips?.driver) ?
                              true : false)">
                                    <button class="red-btn-solid-resend-admin"
                                      (click)="resendOtp(users, trips)">Resend_OTP</button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-2 col-md-2 mt-3">
              <button class="btn red-btn-solid-other-admin" (click)="onSubmitAloc()">Submit Allocation
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>