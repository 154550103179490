<div class="container-fluid">
    <div class="card">
        <ul ngbNav  class="nav-tabs nav-tabs-custom g-nav-tabs-custom">
            <div style="padding-top: 8px; padding-bottom: 8px; margin-left: 17px; font-family: poppins; font-weight: 500;font-size: 15px;">
               Vendor Trip View
            </div>
          </ul>
        <div class="card-body">
            <div class="row">
                <div class="col-sm-2">
                    <label>Location<sup class="mandatory">*</sup></label>
                    <select class="form-select" [(ngModel)]="selectedCity" (change)="getSitesByCityId($event)">
                        <option value="" disabled>Select Location</option>
                        <option *ngFor="let city of cities" [value]="city.locationId">{{ city.locationName }}</option>
                    </select>
                </div>

                <div class="col-sm-2">
                    <label>Site<sup class="mandatory">*</sup></label>
                    <!-- <select class="form-select" [(ngModel)]="selectedSite" (change)="onChangeSite($event)"
                        [disabled]="!selectedCity">
                        <option value="" disabled>Select site</option>
                        <option *ngFor="let site of sites" [value]="site">{{ site.siteName }}</option>
                        <option *ngIf="sites.length == 0">No Site Found</option>
                    </select> -->
                    <mat-form-field class="customField" appearance="outline">
                        <mat-select placeholder="Site" class="font-size-13" [disabled]="!selectedCity" [(ngModel)]="selectedSite">
                            <mat-option class="font-size-13" *ngFor="let site of sites" [value]="site">
                              {{ site.siteName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-sm-2">
                    <label>Date<sup class="mandatory">*</sup></label>
                    <mat-form-field appearance="outline" class="customDateFieldNew">
                        <input matInput (click)="picker.open()" [matDatepicker]="picker" placeholder="Date"
                            [(ngModel)]="tripDate" readonly="readonly">
                        <mat-datepicker-toggle matSuffix [for]="picker">
                            <img matDatepickerToggleIcon src="assets/images/bx-calendar.svg">
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="col-sm-2">
                    <label>Shift type<sup class="mandatory">*</sup></label>
                    <select class="form-select" [(ngModel)]="selectedShiftType">
                        <option value="" disabled>Select Shift type</option>
                        <option *ngFor="let shift of shiftTypes" [value]="shift">
                            {{ shift }}
                        </option>
                    </select>
                </div>

                <div class="col-sm-2 pt-1">
                    <button class="btn red-btn-solid mt-4" (click)="showTrips(true)">Show</button>
                </div>
            </div>
        </div>
    </div>

    <div class="card error-card" *ngIf="headErrorMsg">
        <div class="card-body">{{headErrorMsg}}</div>
    </div>

    <div class="col-lg-12" *ngIf="tripList.length> 0">
        <div class="card">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table leaderboard-table font-size-14 mb-0">
                        <thead>
                            <tr>
                                <th>Time</th>
                                <th>Scheduled Emp</th>
                                <th>Female Emp</th>
                                <th>Adhoc Count</th>
                                <th>Total Trips</th>
                                <th>Escort Trip</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let trip of tripList">
                                <td>{{trip.time}}</td>
                                <td>{{trip.totalEmp}}</td>
                                <td>{{trip.femaleEmp}}</td>
                                <td>{{trip.totalAdhocEmp}}</td>
                                <td>{{trip.totalTrips}}</td>
                                <td>{{trip.escortTrip}}</td>
                                <td>
                                    <p *ngIf="trip.MSG!=''">{{trip.MSG}}</p>
                                    <!-- <button class="btn btn-light btn-rounded"
                                        *ngIf="trip.totalTrips <=0 && trip.MSG != 'Generating'" (click)="generate(trip)"
                                        [disabled]="trip.totalEmp<=0 || headErrorFlag">
                                        {{trip.MSG=='Error' ? 'Regenerate' : 'Generate'}}</button> -->
                                    <!-- <span class="moveright5"
                                        *ngIf="trip.totalTrips>0 && trip.MSG=='Generated'">Processing...</span> -->
                                    <button class="btn btn-light btn-rounded"
                                        *ngIf="trip.totalTrips>0 && trip.MSG=='' && !tripViewFlag"
                                        [disabled]="trip.totalEmp<=0 || headErrorFlag"
                                        (click)="viewDetailedTrip(trip.SHIFTID)">View</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>