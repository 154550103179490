<div class="ms-3">
    <div fxLayout="row" fxLayoutAlign="start center">
        <h4 class="font-size-14" fxFlex>Exception Users</h4>
        <button mat-icon-button color="accent" (click)="onClickCancel()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <div class="hr-line"></div>
    <div class="row" *ngIf="(ELEMENT_DATA.length> 0)">
        <div class="table-responsive">
            <table class="table table-striped mb-0 font-size-14">
                <thead>
                    <tr>
                        <th>Employee Id</th>
                        <th>Employee Name</th>
                        <th>Reason</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let element of ELEMENT_DATA">
                        <td>{{ element.userId }}</td>
                        <td>{{ element.userName }}</td>
                        <td>{{ element.reason }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>