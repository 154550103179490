<div class="row mt-3">
<div class="dialog" style="float:inline-end;">
    <span class="fontBody" style="margin-left: 15px;">{{value[0]}} - {{value[1]}}</span>
    <button mat-icon-button color="primary" (click)="onClickCancel()">
        <mat-icon color="warn">clear</mat-icon>
    </button>
</div>
</div>

<mat-divider></mat-divider>

<div class="row" style="width: 750px !important;height: 500px!important;">
<div class="row mt-3" style="margin: 5px;" [formGroup]="_mFormGroup">
<div class="col-sm-3">
  <label>Search Employee</label>
  <mat-form-field class="audit-log-field" appearance="outline">
    <input type="text" placeholder="Search Employee" matInput formControlName="userName"
      [matAutocomplete]="auto" (input)="searchSubject$.next($event.target.value)">
    <img matSuffix src="assets/images/search.svg" height="27" style="margin-top: -22px;padding-right: 5px;">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option class="audit-uncheck" *ngFor="let option of userInfoSearchResult" [value]="option.firstName"
        (onSelectionChange)="searchSubmit(option)">
        {{option.employeeId}}-{{option.firstName}}</mat-option>
    </mat-autocomplete>
    <!-- <mat-hint style="position: relative; top: 5px; right: 15px;">Search key >= 3 characters.</mat-hint> -->
  </mat-form-field>

 </div>

<div class="col-sm-3" style="margin-left: 10px;">
  <label>From date </label>
  <mat-form-field appearance="outline" class="auditDate">
      <input matInput [matDatepicker]="picker" (click)="picker.open()"
          (dateChange)="dateChanged($event)" readonly placeholder="From date"
          formControlName="fromDate">
      <mat-datepicker-toggle matSuffix [for]="picker">
          <img matDatepickerToggleIcon
              src="../../../../../assets/images/bx-calendar.svg">
      </mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</div>


<div class="col-sm-3">
  <label>To date </label>
  <mat-form-field appearance="outline" class="auditDate">
      <input matInput  [min]="minDate" [matDatepicker]="picker1"
          (click)="picker1.open()"  (dateChange)="filterResults()" readonly placeholder="To date"
          formControlName="toDate">
      <mat-datepicker-toggle matSuffix [for]="picker1">
          <img matDatepickerToggleIcon src="assets/images/bx-calendar.svg">
      </mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
  </mat-form-field>
</div>
</div>

<div *ngIf="_mColumns">
  <table fxFlex class="trips-table customfont responsive tdw-position-relative">
    <thead class="tdw-bottom-line mat-color--grey-100 tdw-card-thin customfont">

      <th class="cell-padding tdw--text-align-right
                     th-padding header-width">
        <ng-container>
          Employee Id
        </ng-container>
      </th>
      <th class="cell-padding tdw--text-align-right
                    sort-remove th-padding header-width">
        <ng-container>
          Employee Role
        </ng-container>
      </th>
      <th class="cell-padding tdw--text-align-right
                    sort-remove th-padding header-width">
        <ng-container>
          Employee Name
        </ng-container>
      </th>

      <th class="cell-padding tdw--text-align-right
                    sort-remove th-padding header-width">
        <ng-container>
          Updated by Date Time
        </ng-container>
      </th>
      <th class="cell-padding tdw--text-align-right
                    sort-remove th-padding header-width">
        <ng-container>
          Action
        </ng-container>
      </th>
    </thead>
    <tbody class="mat-color--white tdw-card-thin tdw-border-2 customfont2">
      <ng-container *ngIf="_commondata">
        <tr *ngFor="let data of _commondata; let i = index" (click)="auditDetails(data, i)">
          <td class="tdw-bottom-line-light cell-padding
                        tdw-font-14 tdw-font-open-sans
                        tdw-font-normal mat-color-text--grey-800">
            <ng-container>
              {{data.updatedByEmpID}}
            </ng-container>
          </td>

          <td class="tdw-bottom-line-light cell-padding
                        tdw-font-14 tdw-font-open-sans
                        tdw-font-normal mat-color-text--grey-800">
            <ng-container>
              {{data.EmployeeRole}}
            </ng-container>
          </td>

          <td class="tdw-bottom-line-light cell-padding
                        tdw-font-14 tdw-font-open-sans
                        tdw-font-normal mat-color-text--grey-800">
            <ng-container>
              {{data.updatedBy}} </ng-container>
          </td>

          <td class="tdw-bottom-line-light cell-padding
                        tdw-font-14 tdw-font-open-sans
                        tdw-font-normal mat-color-text--grey-800">
            <ng-container>
              {{data.updatedDate | date : "EEE MMM-dd-yyyy h:mm a"}}
            </ng-container>
          </td>
          <td class="tdw-bottom-line-light cell-padding
                        tdw-font-14 tdw-font-open-sans
                        tdw-font-normal mat-color-text--grey-800">
            <ng-container>
              {{data.revisionType}}
            </ng-container>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
</div>