<div class="mat-modal-dialog trip-modal">
    <h2 mat-dialog-title>
        <div fxLayout="row" fxLayoutAlign="start center">
            <h4 class="font-size-14" fxFlex>Please confirm</h4>
        </div>
        <span class="material-icons-outlined" style="color:#747679; font-size: 25px; cursor: pointer;" (click)="onCancel()">close</span>
    </h2>
    
    <mat-dialog-content>
        <h4 class="font-size-14">{{dialogData.text}}</h4>
        <form class="example-form" [formGroup]="_mFormGroup">
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <label>Master Route Name<sup class="mandatory">*</sup></label>
                    <input class="form-input" aria-label="Default select example"
                        placeholder="Master route name" maxlength="30" formControlName="masterRouteName"
                        (keypress)="discard_special_char_And_String($event)" autocomplete="off" required>
                    <div class="error-msg"
                        *ngIf="mMasterRouteName.hasError('required') && mMasterRouteName.touched">
                        Please enter master route name
                    </div>
                </div>
            </div>
            <hr>
            <div class="features" fxLayout="row" fxLayout.lt-md="column">
                <div fxFlex fxLayout="column" fxLayoutGap.xs="28px">
                    <div fxLayout="row" fxLayoutGap="20px" fxFlexAlign="end">
                        <button class="btn btn-btn-grey" (click)="onDeny()">Deny</button>
                        <button class="btn red-btn-solid" (click)="onAllow()">Allow</button>
                    </div>
                </div>
            </div>
        </form>
    </mat-dialog-content>
</div>