<!-- <div class="plus-btn-property" *ngIf="projectFlag">
    <button type="button" class="btn add-btn-outline1" (click)="addProject()">
        <em class="bx bx-plus me-1"></em>
        Add General Setup
    </button>
</div>

<div class="col-sm-12" *ngIf="firstStepForProject">
    <div class="d-flex">
        <button type="button" class="btn btn-primary1" (click)="onBackOfProject()">
            <span class="back-light"><em class="mdi mdi-arrow-left me-2"></em>Back</span>
        </button>
        <h4 class="addEditBtn" style="margin-top: 10px;">/ {{title}} General Booking Setup</h4>
    </div>
</div> -->

<!-- <div class="row">
    <div class="col-xl-12"> -->
<!-- <div class="row">
            <div class="col-sm-5">
                <div class="d-flex justify-content-between mt-3" *ngIf='projectFlag' style="width: 250px;">
                    <input class="form-control icon s-input b-0" type="text" style="font-size: 13px;"
                        placeholder="Search by Site name" [(ngModel)]="searchData" (input)="onSearchKey(searchData)">
                </div>
            </div>
        </div> -->
<!-- <div class="">
            <div class="col-sm-12 mt-3"> -->
<div class="row mt-3">
  <div class="col-sm-12 col-md-12">
    <form [formGroup]="_mFormGroup">
      <div class="row mt-4">
        <div class="col-sm-2">
          <label>City<sup class="mandatory">*</sup></label>
          <select class="form-select" aria-label="Default select example" [(ngModel)]="selectedcity"
            formControlName="city" (ngModelChange)="getSitesByCityId($event)">
            <option disabled value="">Choose Location</option>
            <option *ngFor="let city of cities" [value]="city.locationId">
              {{ city.locationName }}
            </option>
          </select>
        </div>
        <div class="col-sm-2">

          <label>Site<sup class="mandatory">*</sup></label>
          <select class="form-select" aria-label="Default select example" [disabled]="(this.mCity.value) ?
                                    false : true" formControlName="site" [(ngModel)]="selectedSite"
            (ngModelChange)="onsitechange($event)">
            <option disabled value="">Choose Site</option>
            <option *ngFor="let site of sites" [value]="site.siteId">
              {{ site.siteName}}
            </option>
          </select>
        </div>

        <div class="col-sm-2">
          <label>Shift Time<sup class="mandatory">*</sup></label>
          <mat-form-field appearance="outline" class="zoneShiftMapping">
            <mat-select [(ngModel)]="selectedval" multiple placeholder="Choose Shift Time"
              formControlName="filterTypeValue">
              <mat-option class="zoneshiftMatSelectCheckbox" *ngFor="let type of shifts" [value]="type.id">
                {{type.value}}
              </mat-option>
            </mat-select>

          </mat-form-field>
        </div>

        <div class="col-sm-2">

          <label>Route Type<sup class="mandatory">*</sup></label>
          <select class="form-select" aria-label="Default select example" [(ngModel)]="selroutetype"
            formControlName="routeType">
            <option disabled selected value="">Choose Route Type</option>
            <option *ngFor="let type of routetype" [value]="type.type">
              {{type.name }}
            </option>
          </select>
        </div>


        <div class="col-sm-4" style="text-align: right;">
          <button class="btn playlist-btn-solid"
            [disabled]="!(mCity.value && mSite.value && mFilterType.value && mFilterTypeValue.value && mRouteType.value)"
            (click)="showMapping(selectedval, selroutetype)">Show Mapping</button>
        </div>


        <div class="row" style="float: right;">
          <div class="col-sm-2">
            <button class="btn playlist-btn-solid2" *ngIf="unPushedValues.length>0 || rows.length>0"
              (click)="saveMapping()">Save
              Mapping</button>
          </div>
        </div>
      </div>


    </form>
  </div>
</div>
<!-- </div> -->

<!-- <div fxLayout="row" fxLayoutGap="12px" -->
<div *ngIf="show" class="row mt-3">
  <!-- <div class="col-sm-12 col-md-12"> -->

  <div class="container">
    <div class="container-top" *ngIf="rows.length>0">
      <span style="font-size: 14px; font-weight: 500; font-family: 'poppins' !important;">Master Zones</span>
    </div>
    <span class="items mat-color--white tdw-card-thin tdw-border-2
                            tdw-font-14" *ngFor="let row of rows">
      <mat-checkbox class="zoneCheckBox" [(ngModel)]="row.state" (change)="onValueChecked($event,row)"></mat-checkbox>
      <span style="font-size: 13px; font-weight: 400; font-family: 'poppins' !important;">{{row.value}}</span>
    </span>
  </div>
  <div class="container mt-3">
    <div class="container-top" *ngIf="unPushedValues.length>0">
      <span style="font-size: 14px; font-weight: 500; font-family: 'poppins' !important;">Selected Master zone.</span>
    </div>
    <span class="items mat-color--white tdw-card-thin tdw-border-2
                            tdw-font-14" *ngFor="let row of unPushedValues">
      <mat-checkbox class="zoneCheckBox" [(ngModel)]="row.state"
        (change)="onSelValueChecked($event,row)"></mat-checkbox>
        <span style="font-size: 13px; font-weight: 400; font-family: 'poppins' !important;">{{row.value}}</span>
    </span>
  </div>
</div>
<div class="col-sm-12" style="text-align: end;">
  <img src="./assets/images/excel.svg" style="margin-left: 20px; position: relative;
  right: 29px;" *ngIf="shiftmapping.length>0" (click)="exportAsXLSX()" matTooltip="Export To Excel">
  <!-- <button class="btn playlist-btn-solid1" *ngIf="shiftmapping.length>0" (click)="exportAsXLSX()">Export
    Excel</button> -->
</div>


<div class="row mt-2" *ngIf="shiftmapping.length > 0">
  <div class="col-sm-12 col-md-12">
    <div class="table-height">
      <table class="customers-zone-shift">
        <tr style="border-bottom: 1px solid #EFF2F7 !important; background: #ffffff !important;">
          <th>Zone Name</th>
          <th>Shift Time</th>
          <th>Shift Log</th>
          <th>Route Type</th>
          <th>Remove Mapping</th>
        </tr>
        <tr *ngFor="let data of shiftmapping">
          <td>{{data.zoneName}}</td>
          <td>{{data.shiftTime}}</td>
          <td>{{data.shiftLog}}</td>
          <td>{{data.type}}</td>
          <td> <img src="./assets/images/remove.svg" (click)="removeMapping(data, 'Removed')"
              matTooltip="Remove Mapping"></td>
        </tr>
      </table>
    </div>
  </div>
</div>