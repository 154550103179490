import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { ApiService } from 'src/app/core/services/api.service';
import { ServiceSharedData } from 'src/app/core/services/shared-data.service';

@Component({
  selector: 'app-exception-users',
  templateUrl: './exception-users.component.html',
  styleUrls: ['./exception-users.component.scss']
})
export class ExceptionUsersComponent implements OnInit {
  dialogData: any;
  exceptionUsers: any[];
  deletedExceptionUsers = [];
  normalExceptionUsers = [];
  addedExceptionUsers = [];

  constructor(
    private router: Router,
    private readonly apiService: ApiService,
    private serviceSharedData: ServiceSharedData,
    public dialogRef: MatDialogRef<ExceptionUsersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogData = data;
  }

  ngOnInit() {
    this.apiService.doPostRequestWithResponse(ApiConstants.getAllException + this.dialogData.date + '&shiftid=' + this.dialogData.shiftid + '&siteid='
      + this.dialogData.siteid, '', success => {
      if (success.body) {
        this.exceptionUsers = success.body;
        success.body.forEach(ele => {
          if (ele.isDeleted === false && ele.isAddedEmployee === false) {
            this.normalExceptionUsers.push(ele);
          } else if (ele.isDeleted === true) {
            this.deletedExceptionUsers.push(ele);
          } else if (ele.isAddedEmployee === true) {
            this.addedExceptionUsers.push(ele);
          }
        })
      } else {
        this.exceptionUsers = [];
        this.normalExceptionUsers = [];
        this.deletedExceptionUsers = [];
        this.addedExceptionUsers = [];
      }
    }, error => {
      console.log(error)
    });
  }

  onClickCancel(): void {
    this.dialogRef.close();
  }

  modify(user) {
    this.dialogRef.close();
    this.serviceSharedData.setData("trips", [user.tripId]);
    this.router.navigate(["/myatom/routing/tripmodify", this.dialogData.siteid, this.dialogData.shiftid, this.dialogData.date]);
  }

}
