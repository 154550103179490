<div fxLayout="row" fxLayoutAlign="start center">
    <h4 class="font-size-14 ms-3 mt-3" fxFlex>{{mTitle}}</h4>
    <span class="material-icons-outlined"
        style="color:#747679; font-size: 25px; float: right; position: relative; cursor: pointer"
        (click)="onClickCancel()"> close</span>
</div>

<div class="hr-line"></div>

<div fxLayout="row">
    <mat-card class="map-card">
        <div id="apl-map"></div>
    </mat-card>

    <mat-card class="map-card">
        <form class="row" [formGroup]="_mFormGroup">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-5">
                        <label>Area<sup class="mandatory">*</sup></label>
                        <!-- <select class="form-select" aria-label="Default select example" placeholder="Area"
                            formControlName="area">
                            <option value='' disabled>Choose Area</option>
                            <option *ngFor="let area of mAreaData" [value]="area.id">
                                {{ area.area }}
                            </option>
                        </select> -->
                        <mat-form-field class="customField-land" appearance="outline">
                            <input type="text" placeholder="Choose area" matInput autocomplete="off"
                                [matAutocomplete]="processauto" formControlName="area">
                            <img matSuffix src="assets/images/search.svg" height="27"
                                style="margin-top: -22px;padding-right: 5px;">
                            <mat-autocomplete #processauto="matAutocomplete">
                                <mat-option *ngFor="let option of mAreaData" [value]="option.area "
                                    (onSelectionChange)="onAreaChange(option)">{{ option.area }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>

                    <div class="col-md-2">
                        <button class="btn red-btn-plus" (click)="onAddArea()">
                            <em class="bx bx-plus" matTooltip="Add Area"></em>
                        </button>
                    </div>

                    <div class="col-md-5">
                        <label>Landmark type<sup class="mandatory">*</sup></label>
                        <select class="form-select" aria-label="Default select example" placeholder="Landmark type"
                            formControlName="landmarkType">
                            <option value='' disabled>Choose Landmark</option>
                            <option *ngFor="let type of landmarkTypes" [value]="type.value">
                                {{ type.viewValue }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="row" *ngIf="landmarkGeoLocating">
                </div>

                <div class="row mt-3">
                    <mat-form-field class="example-full-width text-field-width-for-address" appearance="outline"
                        required>
                        <mat-label>Landmark address</mat-label>
                        <textarea matInput #input maxlength="250" placeholder="Landmark address" type="text"
                            formControlName="landmarkName" class="max-height"></textarea>
                        <mat-hint style="float: right">{{input.value?.length || 0}}/250</mat-hint>
                    </mat-form-field>
                </div>

                <div class="row mt-3">
                    <mat-form-field class="example-full-width text-field-width-for-address" appearance="outline">
                        <mat-label>Formatted address</mat-label>
                        <textarea matInput #input maxlength="250" readonly placeholder="Formatted Landmark address"
                            type="text" formControlName="formattedaddress" class="max-height"></textarea>
                        <mat-hint style="float: right">{{input.value?.length || 0}}/250</mat-hint>
                    </mat-form-field>
                </div>

                <div class="hr-line"></div>
                <div class="float-end">
                    <button class="btn red-btn-solid" (click)="onSubmit()">
                        SUBMIT
                    </button>
                </div>
            </div>
        </form>
    </mat-card>
</div>