<div *ngIf="!setupFlag">
  <div class="col-sm-12">
    <div class="d-flex">
      <button type="button" class="btn btn-primary1" (click)="onBackOfProject()">
        <span class="back-light"><em class="mdi mdi-arrow-left me-2"></em>Back</span>
      </button>
      <h4 class="addEditBtn" style="margin-top: 10px;">/ {{title}} Routing Setup</h4>
    </div>
  </div>


  <div class="row mt-3">
    <div class="col-sm-12">
      <form [formGroup]="_mFormGroup">
        <div class="row mt-4">
          <div class="col-sm-4">
            <label>Site</label>
            <select class="form-select margin-right-8px" placeholder="Select Site" formControlName="site" required
              (change)="onSiteChange($event)">
              <option disabled value="">Choose Site</option>
              <option *ngFor="let site of siteData" [value]="site.siteId">
                {{ site.siteName}}
              </option>
            </select>
            <div class="error-msg"
              *ngIf="_mFormGroup.get('site').hasError('required') && _mFormGroup.get('site').touched">
              Please select site
            </div>
          </div>
          <div class="col-sm-4">

            <label>Routing Type</label>
            <select class="form-select margin-right-8px" placeholder="Select Routing type" formControlName="routingType"
              required>
              <option disabled value="">Choose Routing Type</option>
              <option *ngFor="let type of mRoutingTyes" [value]="type.value">
                {{ type.value}}
              </option>
            </select>
            <div class="error-msg"
              *ngIf="_mFormGroup.get('routingType').hasError('required') && _mFormGroup.get('routingType').touched">
              Please select routing type
            </div>
          </div>

          <div class="col-sm-4">
            <label>Transport SLA</label>
            <input class="form-input margin-right-8px" matInput placeholder="Tranport SLA (min)"
              (keypress)="discard_special_char_And_String1($event)" autocomplete="off" formControlName="transportSLA"
              required>
            <div class="error-msg"
              *ngIf="_mFormGroup.get('transportSLA').hasError('required') && _mFormGroup.get('transportSLA').touched">
              Please enter transport sla
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-sm-4">
            <span style="font-size: 14px; font-weight: 500; font-family: 'Poppins';">Trip generation
              priority : </span>

            <mat-radio-group color="#34c38f" name="priorityOptimization" formControlName="priorityOptimization"
              required><br>
              <mat-radio-button defaultBoColor="#dfdfdf" color="#34c38f" value="optimization"
                style="position: relative; right: 10px;"><span
                  style="position: relative; left: 5px; top: 5px; font-size: 14px; font-weight: 500; font-family: 'Poppins';">Optimization</span></mat-radio-button>&nbsp;&nbsp;
              <mat-radio-button defaultBoColor="#dfdfdf" color="#34c38f" value="travelTime"
                style="position: relative; right: 10px;"><span
                  style="position: relative; left: 5px; top: 5px; font-size: 14px; font-weight: 500; font-family: 'Poppins';">Travel
                  Time</span></mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col-sm-4">
            <label>Acceptable Diversion (Km)</label>
            <input class="form-input margin-right-8px" matInput placeholder="Acceptable Diversion (Km)"
              (keypress)="discard_special_char_And_String1($event)" autocomplete="off"
              formControlName="acceptableDiversion" required>
            <div class="error-msg"
              *ngIf="_mFormGroup.get('acceptableDiversion').hasError('required') && _mFormGroup.get('acceptableDiversion').touched">
              Please enter acceptable diversion
            </div>
            <!-- <mat-error *ngIf="mAcceptableDiversion.hasError('pattern')">
              Please enter a valid input
            </mat-error> -->

          </div>
          <div class="col-sm-4">
            <ui-switch defaultBoColor="#dfdfdf" color="#34c38f" formControlName="allowTripGeneration"
              style="position: relative; top: 27px;" (click)="allowTripGenerationToggle()"></ui-switch>
            <span
              style="position: relative; right: -15px; top: 32px; font-size: 14px; font-weight: 500; font-family: 'Poppins';">Allow
              trip generation before CutOff</span>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-sm-4">
            <ui-switch defaultBoColor="#dfdfdf" color="#34c38f" formControlName="allowProActiveRouting"
              (click)="allowProActiveRoutingToggle()" style="position: relative; top: 27px;"></ui-switch>
            <span
              style="position: relative; right: -15px; top: 32px; font-size: 14px; font-weight: 500; font-family: 'Poppins';">Allow
              pro active routing</span>
          </div>
          <div class="col-sm-4">
            <ui-switch defaultBoColor="#dfdfdf" color="#34c38f" formControlName="allowTripGenerationOutOfBoundry"
              style="position: relative; top: 27px;"></ui-switch>
            <span
              style="position: relative; right: -15px; top: 32px; font-size: 14px; font-weight: 500; font-family: 'Poppins';">Allow
              trip generation outside site boundary</span>
          </div>
          <div class="col-sm-4">
            <ui-switch defaultBoColor="#dfdfdf" color="#34c38f" formControlName="allowStopTripToVendor"
              style="position: relative; top: 27px;"></ui-switch>
            <span
              style="position: relative; right: -15px; top: 32px; font-size: 14px; font-weight: 500; font-family: 'Poppins';">Allow
              vendor to stop trip</span>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-sm-4">
            <ui-switch defaultBoColor="#dfdfdf" color="#34c38f" formControlName="allowDriverToMarkNoShow"
              style="position: relative; top: 27px;"></ui-switch>
            <span
              style="position: relative; right: -15px; top: 32px; font-size: 14px; font-weight: 500; font-family: 'Poppins';">Allow
              driver to mark no show</span>
          </div>
          <div class="col-sm-4">
            <ui-switch defaultBoColor="#dfdfdf" color="#34c38f" formControlName="disablePickUpTimeUpdateByVendor"
              style="position: relative; top: 27px;"></ui-switch>
            <span
              style="position: relative; right: -15px; top: 32px; font-size: 14px; font-weight: 500; font-family: 'Poppins';">Disable
              PickUp Time Update By Vendor</span>
          </div>
          <div class="col-sm-4" *ngIf="shiftMapping">
            <label>Shift</label>
            <ng-multiselect-dropdown class="route-multiShift" [placeholder]="'Choose Shift'" formControlName="shift"
              [settings]="shiftDropDownSettings" [data]="shiftData" (onSelect)="onItemSelect($event)"
              (onSelectAll)="onSelectAll($event)">
            </ng-multiselect-dropdown>
          </div>
        </div>
        <div class="col-12 mt-5" style="text-align: end;">
          <button type="button" [disabled]="_mFormGroup.invalid" class="btn btn-danger1 w-lg" (click)="save()">
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<div *ngIf="setupFlag">
  <app-routing-setup></app-routing-setup>
</div>