import { Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { ApiService } from 'src/app/core/services/api.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { OnCallMapComponent } from 'src/app/pages/routing/on-call-map/on-call-map.component';


export interface PeriodicElement {
  date: string,
  bookedSite: string,
  tripId: string,
  punchInTime: string,
  vehicle: string,
  driver: string,
  vendor: string,
  reportingTime: string,
  punchOutTime: string,
  empName: string,
  comment: string,
  tripStatus: string,
  adminAction: number,
}

@Component({
  selector: 'app-on-call',
  templateUrl: './on-call.component.html',
  styleUrls: ['./on-call.component.scss']
})
export class OnCallComponent {

  ELEMENT_DATA: PeriodicElement[] = [];
  displayedColumns: string[] =
    [
      'date',
      'bookedSite',
      'tripId',
      'punchInTime',
      'vehicle',
      'driver',
      'vendor',
      'reportingTime',
      'punchOutTime',
      'tripCode',
      'empName',
      'comment',
      'tripStatus',
      'adminAction'
    ]
  dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();

  public _mFormGroup: FormGroup;
  public location: any = new FormControl('');
  public site = new FormControl('');
  public fromDate = new FormControl('');
  
  _mData: any[] = [];
  reportsViewFlag: boolean = false;
  _mSitesData: any[] = [];
  locationList: any[] = [];
  mData: any[] = [];
  minDate = new Date();
  maxDate = new Date();
  siteid: number;
  shiftid: number;
  date: any;
  mapType: any;
  flag: any;
  startDate: Date;


  activeTabId = 1;
  constructor(private readonly apiService: ApiService,
    public notifyService: NotificationService,
    private fb: FormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private dialog: MatDialog,
    private serviceLocalStorage: ServiceLocalStorage,
  ) {
    this._mFormGroup = this.fb.group({
      'location': this.location,
      'site': this.site,
      "fromDate": this.fromDate,
    });
  }

  ngOnInit(): void {
    this.loadCities();
    this.mapType = this.serviceLocalStorage.getMapType();
    this.startDate = new Date();
  }

  loadCities() {
    this.ngxLoader.start();
    this.apiService.doGetRequestWithResponse(ApiConstants.getserchlocationlist, (success) => {
      this.ngxLoader.stop();
      if (success.status == 200) {
        this.locationList = success.body;
      } else {
        this.locationList = []
      }
    },
      error => {
        this.ngxLoader.stop();
        console.log(error.message);
      }
    );
  }

  getSitesBylocId() {
    this._mSitesData = [];
    this.apiService.doGetRequestWithResponse(ApiConstants.getSiteByLocationId + this.location.value, (success) => {
      if (success.status == 200) {
        this._mSitesData = success.body.dataList.content
      } else {
        this._mSitesData = [];
      }
    }, (error) => {
    })
  }

  formatDate(d): any {
    let m = d.getMonth() + 1;
    let dt = (d.getDate() < 10) ? '0' + d.getDate() : d.getDate();
    let mm = (m < 10) ? '0' + m : m;
    return d.getFullYear() + '-' + mm + '-' + dt;
  }

  
  reset() {
    this.location.setValue('');
    this.site.setValue('');
    this.fromDate.setValue('');
    this.ngOnInit();
    this._mData = [];
    this.ELEMENT_DATA = [];
    this.reportsViewFlag = false;
  }

  generateReport(flag) {
    this.ngxLoader.start();
    this.apiService.doGetRequestWithResponse(ApiConstants.getOncallAdminView + '?siteId=' + this.site.value + '&date=' + this.formatDate(this.fromDate.value), (success) => {
      this.ngxLoader.stop();
      if (success.status == 200) {
        this._mData = success.body;
        this.ELEMENT_DATA = success.body;
        this.dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
        this.dataSource.sort = this.sort;
        this.reportsViewFlag = true;
      } else {
        this._mData = [];
        this.ELEMENT_DATA = [];
        this.dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
        this.dataSource.sort = this.sort;
        this.reportsViewFlag = true;
        this.notifyService.showInfo("No data found", null);
      }
    }, (error) => {
      this.ngxLoader.stop();
    })

  }
  mapview(tripId) {
    this.ngxLoader.start();
    this.apiService.doGetRequestWithResponse(ApiConstants.gettripbytripid + '?tripid=' + tripId, success => {
      let reqData = {
        params: {
          siteId: this.site.value,
          date: this.fromDate.value
        },
        data: success.body,
        mapType: this.mapType
      };
      let dialogRef = this.dialog.open(OnCallMapComponent, {
        width: '1250px',
        disableClose: true,
        data: reqData
      });
      dialogRef.afterClosed().subscribe(result => {
        this.generateReport(true);
      });
      this.ngxLoader.stop();
    }, error => {
      this.notifyService.showInfo("No Trips Found", null);
      this.ngxLoader.stop();
    });
  }


}
