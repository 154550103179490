import { Component, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiService } from 'src/app/core/services/api.service';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AddVendorAdjustmentTripsComponent } from './add-vendor-adjustment-trips/add-vendor-adjustment-trips.component';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-adjustment-trips-vendor',
  templateUrl: './adjustment-trips-vendor.component.html',
  styleUrls: ['./adjustment-trips-vendor.component.scss']
})
export class AdjustmentTripsVendorComponent {

  _mColumns: any[] = [];
  _mData: any[] = [];
  firstStep: boolean = false;
  secondStep: boolean = false;
  _mCurrentUserData: any;

  dataSource = new MatTableDataSource<any>(this._mData);
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  displayedColumns = ['date', 'tripCode', 'siteName', 'vendorName', 'employeeId', 'reason', 'logType', 'nearestShiftTime', 'approximateKm', 'escortRequired', 'vehicleType', 'vehicleNo', 'driverName', 'status'];

  constructor(
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    public readonly serviceLocalStorage: ServiceLocalStorage,
    private ngxLoader: NgxUiLoaderService,
    private fb: FormBuilder,
    public readonly dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this._mCurrentUserData = this.serviceLocalStorage.getUserObject();
    this.getAll();
  }

  openDialog(userEdit): void {
    let data = null;
    if (this._mCurrentUserData.userInfoDetails.role.id == 3) {
      data = {
        employeeAccessFlag: true,
        userData: this._mCurrentUserData
      };
    } else if (this._mCurrentUserData.userInfoDetails.role.id == 4) {
      data = {
        employeeAccessFlag: true,
        userData: null
      };
    } else if (
      (this._mCurrentUserData.userInfoDetails.role.id == 1 ||
        this._mCurrentUserData.userInfoDetails.role.id == 2) &&
      userEdit != null
    ) {
      data = {
        employeeAccessFlag: false,
        userData: userEdit
      };
    } else {
      data = null;
    }
    let dialogRef = this.dialog.open(AddVendorAdjustmentTripsComponent, {
      disableClose: true,
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAll();
      }
    });
  }

  getAll() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getAdjustmentTrips , success => {
      if (success.status == 200) {
        // this.mData = success.body;
        let locarr: any[] = [];
        success.body.forEach(element => {
          locarr.push({
            "id": element.id, "date": element.date, "tripCode": element.tripCode, "siteName": element.siteName, "vendorName": element.vendorName,
            "employeeId": element.employeeId, "reason": element.reason, "logType": element.logType, "nearestShiftTime": element.nearestShiftTime, "approximateKm": element.approximateKm, "escortRequired": element.escortRequired, "vehicleType": element.vehicleType, "vehicleNo": element.vehicleNo, "driverName": element.driverName, "status": element.status,
          });
        });
        this._mData = locarr;
        this.dataSource.data = locarr;
      }
      else {
        this._mData = [];
      }
    },
    (error)=>
    {
    });
  }

}
