<div class="plus-btn-property" *ngIf="projectFlag">
    <button type="button" class="btn add-btn-outline1" (click)="addProject()">
      <em class="bx bx-plus me-1"></em>
      Add Navigation Setup
    </button>
  </div>
  
  <div class="col-sm-12" *ngIf="firstStepForProject">
    <div class="d-flex">
      <button style="border: none !important;" class="btn btn-primary1" (click)="onBackOfProject()">
        <span class="back-light"><em class="mdi mdi-arrow-left me-2"></em>Back</span>
      </button>
      <h4 class="addEditBtn" style="margin-top: 10px;">/ {{title}} Navigation Setup</h4>
    </div>
  </div>
  <div *ngIf="projectFlag" class="row">
    <div class="col-sm-3">
      <form [formGroup]="_mFormGroup">
        <!-- {{selectedcity}} -->
        <label>City</label>
        <select class="form-select margin-right-8px" placeholder="City" formControlName="city"
          (ngModel)="selectedCity" (ngModelChange)="onLocationChange($event)">
          <option disabled value="">Choose City</option>
          <option *ngFor="let city of cities" [value]="city.locationId">
            {{ city.locationName }}
          </option>
        </select>
      </form>
    </div>
  </div>
  <div class="d-flex justify-content-between mt-4" *ngIf='ELEMENT_DATA.length > 0 && !firstStepForProject'
    style="width: 250px;">
    <input class="form-control icon s-input b-0" type="text" placeholder="Search by Site Name" [(ngModel)]="searchData"
        (input)="onSearchKey(searchData)">
</div>
  <div class="row">
    <div class="col-sm-12">
      <div class="row mt-3" style="height: auto;" *ngIf="firstStepForProject"> 
        <div class="col-sm-12"> 
          <form [formGroup]="_mFormGroup1">
            <div class="row">
              <div class="col-sm-3">
                <label>Site</label>
                <select class="form-select margin-right-8px" placeholder="Select Site" formControlName="site" required (ngModelChange)="getShiftsTimes($event)">
                  <option disabled value="">Choose Site</option>
                  <option *ngFor="let site of mSites" [value]="site.siteId" >
                    {{ site.siteName}}
                  </option>
                </select>
                <div  class="error-msg"
                *ngIf="_mFormGroup1.get('site').hasError('required') && _mFormGroup1.get('site').touched">
                Please select site
              </div>
                <!-- <mat-error class="form-error-state font-size-10 font-weight-meduim" *ngIf="mSite.hasError('required')">
                  Please select Site
                </mat-error> -->
              </div>
              <div class="col-sm-3">
                <label>Navigation Type</label>
                <select class="form-select margin-right-8px" placeholder="Select Navigation Type" formControlName="routingType" required>
                  <option disabled value="">Choose Navigation Type</option>
                  <option *ngFor="let type of mRoutingTypes" [value]="type.value">
                    {{ type.value}}
                  </option>
                </select>
                <div  class="error-msg"
                *ngIf="_mFormGroup1.get('routingType').hasError('required') && _mFormGroup1.get('routingType').touched">
                Please select navigation type
              </div>
                <!-- <mat-error class="form-error-state font-size-10 font-weight-meduim"  *ngIf="mRoutingType.hasError('required')">
                  Please select Navigation Type
                </mat-error> -->
              </div>
              <div class="col-sm-3" *ngIf = "shiftFlag">
                <label>Shifts</label>
                <ng-multiselect-dropdown class="navigation-multisel" style="width:100%;padding-top: 20px;"
                [placeholder]="'Shifts'" formControlName="shift" [settings]="shiftDropDownSettings" [data]="shiftData"
                (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
              </ng-multiselect-dropdown>
              </div>
              <div class="col-12 mt-5" style="text-align: end;">
                <button type="button" [disabled]="!_mFormGroup1.valid" class="btn btn-danger1" (click)="onSubmit()">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf = "projectFlag && !isEmpty">
    <div class="col-sm-12">
      <app-basic-table [columnHeaders]="_mColumns" [tableData]="_mData" (onStatusChanged)="onStatusChanged($event)" [subcribeEdit]="subcribeEdit" (onEditIconClicked)="onEditIconClicked($event)" [isAuditLog]="auditFlag" (onAuditLog)="onAuditLogClicked($event)"></app-basic-table>
    </div>
  </div>

  <div class="row" *ngIf="_mData.length == 0 && _mDataRaw.length > 0">
    <div class="col-sm-12 col-md-12">
      <h4 class="tdw-text-align-center tdw-font-normal tdw-margin-4
            mat-color-text--blue-grey-600">
        --No Data Found --</h4>
    </div>
  </div>