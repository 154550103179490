import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { ApiService } from 'src/app/core/services/api.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-oncall-restart',
  templateUrl: './oncall-restart.component.html',
  styleUrls: ['./oncall-restart.component.scss']
})
export class OncallRestartComponent {
  public _mFormGroup: FormGroup;
  public mComments = new FormControl('', Validators.required);

  public _mTitle = " On Call Restart Trip";
  flag: any;
  tripStatus: any;

  constructor(
    public dialogRef: MatDialogRef<OncallRestartComponent>,
    private fb: FormBuilder,
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    private ngxLoader: NgxUiLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this._mFormGroup = this.fb.group({
      'mComments': this.mComments
    });
  }

  ngOnInit(): void {
    this.getDeviceTripStatus();
  }

  onConfirmation(): void {
    if (this._mFormGroup.valid) {
      this.tripRestart1();
    } else {
      this.notifyService.showWarning("Comments fields can't be empty", null);
    }
  }

  onClickCancel(): void {
    this.dialogRef.close("");
  }

  getDeviceTripStatus() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getDeviceTripStatus + '?tripId=' + this.data.data.tripId, success => {
      if (success != null) {
        this.tripStatus = success.body.deviceTripStatus;
      } else {
        this.tripStatus = null;
      }
    }, error => {
      console.log(error)
    });
  }

  tripRestart1() {
    this.ngxLoader.start();
    this.flag = 'restart';
    this.apiService.doGetRequestWithResponse(ApiConstants.getStoporRestartTrip + '?tripId=' + this.data.data.tripId + '&flag=' + this.flag + '&comment=' + this.mComments.value, (success) => {
      this.ngxLoader.stop();
      if (success.status == 200) {
        this.dialogRef.close(success);
        this.notifyService.showSuccess(success.body.message, null);
        this.getDeviceTripStatus();
      } else {
        this.notifyService.showWarning("Error", null);
      }
    }, (error) => {
      this.ngxLoader.stop();
      this.dialogRef.close("");
      this.notifyService.showError("Failed to add", null);
    });
  }
  
}
