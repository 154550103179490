import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { ApiService } from 'src/app/core/services/api.service';

@Component({
  selector: 'app-upload-trip-status',
  templateUrl: './upload-trip-status.component.html',
  styleUrls: ['./upload-trip-status.component.scss']
})
export class UploadTripStatusComponent implements OnInit {
  errList: any[] = [];
  totalrecordscount: number;
  totaluploadcount: number;
  fileid: number = 0;
  errordata: any[] = [];

  constructor(
    private readonly apiService: ApiService,
    public dialogRef: MatDialogRef<UploadTripStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.getTripErrorList(data);
  }

  ngOnInit() {
  }

  getTripErrorList(data) {
    if (!data.uploadErrorsList) {
      let fileId: any;
      fileId = data.fileId;
      this.apiService.doGetRequestWithResponse(ApiConstants.gettripuploaderrorlist + fileId, (success) => {
        if (success.body.length > 0) {
          this.errList = success.body;
          this.totalrecordscount = data.itemsRead;
          this.totaluploadcount = data.itemsSaved;
        }
      }, (error) => {
        console.log(error)
      })
    } else {
      if (data.uploadErrorsList) {
        this.errList = data.uploadErrorsList;
        this.fileid = data.fileId;
      }
      this.totalrecordscount = data.itemsRead;
      this.totaluploadcount = data.itemsSaved;
    }
  }

  onClickCancel(): void {
    if (this.errList.length == 0) {
      this.dialogRef.close("success");
    } else {
      this.dialogRef.close();
    }
  }

}
