<div class="mat-modal-dialog trip-modal">
    <h2 mat-dialog-title>
        <span class="font-size-18" style="font-weight: 500; font-family: 'poppins' !important; color: #000000;">{{_mTitle}}</span>
        <span class="material-icons-outlined"
            style="color:#FF455B; font-size: 25px; float: right; position: relative; top: 20px; cursor: pointer"
            (click)="dialogRef.close()"> close</span>
    </h2>
    <mat-divider></mat-divider>
    <mat-dialog-content>
        <div class="">
            <div class="button-items" *ngIf="(!routingContainer && !mirrorContainer)">
                <button type="button" class="btn trip-btn-solid" style=" padding: 8px 25px;" (click)="runRouting()">Run
                    routing</button>
                <button type="button" class="btn trip-btn-solid" style=" padding: 8px 45px;"
                    (click)="mirror()">Mirror</button>
                <button type="button" class="btn trip-btn-solid" style=" padding: 8px 55px;"
                    (click)="intelligenceRouting()">IR</button>
            </div>
        </div>

        <!-- routing view starts -->
        <div class="routing-container" *ngIf="routingContainer">
            <form class="example-form">
                <div class="row mb-3">
                    <ng-container *ngFor="let cab of cabs; let i=index">
                        <div class="col-sm-4">
                            <label>{{cab.vehicleType}}<sup class="mandatory">*</sup></label>
                            <input class="form-input" type="number" aria-label="Default select example"
                                placeholder="{{cab.vehicleType}}" autocomplete="off"
                                (keydown)="serviceSharedData.validateNumber($event)" maxlength="3"
                                (keyup)="eventHandler($event, cab.id)" [disabled]="cabs.length === 1"
                                [name]="cab.vehicleType">
                            <div *ngIf="(i === cabs.length-1) ? false :true" class="me-5"></div>
                        </div>
                    </ng-container>
                </div>
                <mat-divider></mat-divider>
                <div class="mt-3" fxLayout="row" fxLayout.lt-md="column">
                    <div fxFlex fxLayout="column" fxLayoutGap.xs="28px">
                        <div fxLayout="row" fxLayoutGap="20px" fxFlexAlign="end">
                            <button class="btn trip-btn-solid" style=" padding: 8px 55px;" (click)="onSkip()">SKIP</button>
                            <button class="btn trip-btn-solid" style=" padding: 8px 45px;" (click)="onSubmit()">SUBMIT</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <!-- routing view ends -->

        <!-- mirror view starts -->
        <div *ngIf="mirrorContainer">
            <form class="example-form" [formGroup]="_mMirrorFormGroup">
                <div class="row mb-3">
                    <div class="col-sm-4">
                        <label>Reference Date<sup class="mandatory">*</sup></label>
                        <mat-form-field appearance="outline" class="customDateFieldNew">
                            <input matInput (click)="topicker.open()" [matDatepicker]="topicker"
                                placeholder="Reference Date" formControlName="date" readonly="readonly" required>
                            <mat-datepicker-toggle matSuffix [for]="topicker">
                                <img matDatepickerToggleIcon src="assets/images/bx-calendar.svg">
                            </mat-datepicker-toggle>
                            <mat-datepicker #topicker></mat-datepicker>
                            <mat-error *ngIf="_Rdate.hasError('required')">
                                Please select date
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-sm-4">
                        <label style="white-space: nowrap;">Refference Shift Timings<sup
                                class="mandatory">*</sup></label>
                        <!-- <select class="form-select" formControlName="time" required>
                            <option value="" disabled>Select Shift Timings</option>
                            <option *ngFor="let shift of shiftTimings" [value]="shift">{{shift.shiftTime}}
                                {{shift.logType}}</option>
                        </select>
                        <div style="font-family: poppins; color: red;font-size: 11px;margin-top: 3px;"
                            *ngIf="_Rtime.hasError('required')">
                            Please select time
                        </div> -->
                        <mat-form-field class="customField" appearance="outline">
                            <mat-select class="font-size-13" placeholder="Refference Shift Timings"
                                formControlName="time" required>
                                <mat-option class="font-size-13" *ngFor="let shift of shiftTimings" [value]="shift">
                                    {{shift.shiftTime}} {{shift.logType}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="_Rtime.hasError('required')">
                                Please select time
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-sm-4">
                        <label>Mirror Type<sup class="mandatory">*</sup></label>
                        <select class="form-select" formControlName="type" required>
                            <option value="" disabled>Select Mirror Type</option>
                            <option *ngFor="let type of mirrorTypes" [value]="type">{{type.view}}</option>
                        </select>
                        <div style="font-family: poppins; color: red;font-size: 11px;margin-top: 3px;"
                            *ngIf="_RMirrorType.hasError('required')">
                            Please select Type
                        </div>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="mt-3" fxLayout="row" fxLayout.lt-md="column">
                    <div fxFlex fxLayout="column" fxLayoutGap.xs="28px">
                        <div fxLayout="row" fxLayoutGap="20px" fxFlexAlign="end">
                            <button class="btn red-btn-solid" (click)="dialogRef.close()">Cancel</button>
                            <button class="btn red-btn-solid" (click)="onMirroringSubmit()">Submit</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <!-- mirror view ends -->
    </mat-dialog-content>
</div>