<form [formGroup]="_mFormGroup">
  <div class="row mt-3">
    <div class="col-sm-2">
      <label>Location</label>
      <select class="form-select margin-right-8px" placeholder="Select Location" formControlName="location"
        (ngModelChange)="getSitesByCityId($event)">
        <option disabled value="">Choose City</option>
        <option *ngFor="let city of cities" [value]="city.locationId">
          {{ city.locationName }}
        </option>
      </select>
    </div>

    <div class="col-sm-2">
      <label>Site</label>
      <select class="form-select margin-right-8px" formControlName="site">
        <option disabled value="">Choose Site</option>
        <option *ngFor="let site of _mSitesData" [value]="site.siteId">
          {{ site.siteName }}
        </option>
      </select>
    </div>

    <div class="col-sm-2">
      <label>Select Category</label>
      <select class="form-select margin-right-8px" [(ngModel)]="selectedval" placeholder="Select Category"
        formControlName="category">
        <option disabled value="">Choose Category</option>
        <option *ngFor="let category of _mCategoryData" [value]="category.id">
          {{category.categoryName }}
        </option>
      </select>
    </div>

    <div class="col-sm-4"></div>
    <div class="col-sm-2" style="position: relative; top: 27px; left: 11px;">
      <button class="btn playlist-btn-solid" [disabled]="(!mCategory.value || !mSite.value)" (click)="showMapping(selectedSite, selectedval)">Show Mapping</button>
    </div>  
  </div>
</form>

<div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="none stretch">
  <div class="tdw-padding-h-20"></div>
</div>
<div class="hr-line"></div>
<div class="tdw-padding-v-4"></div>
<div *ngIf="show" fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="none stretch">
  <div class="container">
    <div class="container-top" *ngIf="rows.length>0">
      <mat-checkbox class="splCategoryCheckBox" [checked]="isAllChecked()" (change)="checkAll($event)"></mat-checkbox><span style="font-size: 14px; font-weight: 500; font-family: 'poppins' !important;">Select
      All</span>
    </div>
    <span class="items mat-color--white tdw-card-thin tdw-border-2
          tdw-font-14" *ngFor="let row of rows">
      <mat-checkbox class="splCategoryCheckBox" [(ngModel)]="row.state" (change)="onValueChecked($event,row)"></mat-checkbox>
      <span style="font-size: 13px; font-weight: 400; font-family: 'poppins' !important;">{{row.value}}</span>
    </span>
  </div>
  <div class="container">
    <div class="container-top" *ngIf="unPushedValues.length>0">
      <mat-checkbox class="splCategoryCheckBox" [checked]="!isAllChecked() || isAllChecked()" (change)="unCheckAll($event)"></mat-checkbox><span style="font-size: 14px; font-weight: 500; font-family: 'poppins' !important;">UnSelect
      All</span>
    </div>
    <span class="items mat-color--white tdw-card-thin tdw-border-2
          tdw-font-14" *ngFor="let row of unPushedValues">
      <div fxLayout="row">
        <mat-checkbox class="splCategoryCheckBox" [(ngModel)]="row.state" (change)="onSelValueChecked($event,row)"></mat-checkbox>
        <div class="tdw-padding-h-4"></div>
        <span style="font-size: 13px; font-weight: 400; font-family: 'poppins' !important; position: relative; top: 10px;">{{row.value}}</span>
        <div class="tdw-padding-h-24"></div>
        <span style="font-size: 13px; font-weight: 400; font-family: 'poppins' !important; position: relative; top: 9px;">seat capacity for medical employee :-</span>
        <mat-select class="seat-capacity valueText" placeholder="Seat Capacity"
          (selectionChange)="onSelectedSeatCapacity($event.value, row)" [value]="row?.allocatedSeats" required>
          <mat-option class="splCategoryUnCheckMatoption" *ngFor="let seatCapacity of row.seatCapacity" [value]="seatCapacity.value">
            {{ seatCapacity.value }}
          </mat-option>
        </mat-select>
      </div>
    </span>
  </div>
</div>
<div fxLayout="column">
  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end" style="float:
        right; padding-right: 0%">
    <button *ngIf="showFlag" class="btn playlist-btn-solid" (click)="saveMapping()">Save
      Mapping
    </button>
  </div>