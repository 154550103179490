import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouterConstants } from './core/constants/router.constants';
import { AuthGuardService } from './core/services/auth-guard.service';
import { LayoutsComponent } from './layouts/layouts.component';

const routes: Routes = [
  { path: RouterConstants.default, redirectTo: '/' + RouterConstants.auth + '/' + RouterConstants.client, pathMatch: 'full' },
  { path: RouterConstants.auth, loadChildren: () => import('./auth/auth.module').then(mod => mod.AuthModule) },
  { path: RouterConstants.sso, loadChildren: () => import('./sso/sso.module').then(mod => mod.SsoModule) },
  { path: RouterConstants.default, redirectTo: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.userProfile.default, pathMatch: 'full' },
  { path: RouterConstants.newRegister, loadChildren: () => import('./new-registration/new-registration.module').then(mod => mod.NewRegistrationModule) },
  {
    path: RouterConstants.layoutContainer, component: LayoutsComponent, canActivate: [AuthGuardService],
    children: [
      { path: RouterConstants.default, redirectTo: RouterConstants.userProfile.default, pathMatch: "full" },
      { path: RouterConstants.userProfile.default, loadChildren: () => import('./pages/pages.module').then(mod => mod.PagesModule) },
    ]
  },
  { path: RouterConstants.dashboardEmail.baseUrl, loadChildren: () => import('./dashboard-email/dashboard.module').then((mod) => mod.DashboardModule) },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
