import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { interval } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { ApiService } from 'src/app/core/services/api.service';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ServiceSharedData } from 'src/app/core/services/shared-data.service';
// import { RoutingErrorComponent } from './routing-error/routing-error.component';
// import { TripGenerationComponent } from './trip-generation/trip-generation.component';

@Component({
  selector: 'app-vendor-trip-view',
  templateUrl: './vendor-trip-view.component.html',
  styleUrls: ['./vendor-trip-view.component.scss']
})
export class VendorTripViewComponent implements OnInit, OnDestroy {
  startTimer: boolean = false;
  tripViewFlag: boolean = false;
  headErrorFlag: boolean = false;
  successFlag: boolean = true;
  errorFlag: boolean = true;
  singleList: any[] = [];
  errorSingleList: any[] = [];
  errorList: any[] = [];
  tripList: any[] = [];
  tripvehs: any[] = [];
  cities: any[] = [];
  sites: any[] = [];
  sub: Subscription;
  selectedCityId: any;
  selectedCity: any = '';
  selectedSite: any = '';
  selectedShiftType: any;
  tripDate: any;
  generatedShift: any;
  headErrorMsg: any;
  shiftTypes: any[] = ["IN", "OUT", "ALL"];

  constructor(
    private readonly apiService: ApiService,
    private serviceSharedData: ServiceSharedData,
    private serviceLocalStorage: ServiceLocalStorage,
    private notifyService: NotificationService,
    private ngxLoader: NgxUiLoaderService,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    setTimeout(() => {
      let locationsList = this.serviceSharedData.getData(ServiceSharedData.TRIP_GENERATION.locationsList);
      let sitesList = this.serviceSharedData.getData(ServiceSharedData.TRIP_GENERATION.sitesList);
      let location = this.serviceSharedData.getData(ServiceSharedData.TRIP_GENERATION.location);
      let site = this.serviceSharedData.getData(ServiceSharedData.TRIP_GENERATION.site);
      let date = this.serviceSharedData.getData(ServiceSharedData.TRIP_GENERATION.date);
      let shifttype = this.serviceSharedData.getData(ServiceSharedData.TRIP_GENERATION.shiftType);
      let tripViewFlag = this.serviceSharedData.getData(ServiceSharedData.TRIP_GENERATION.tripViewFlag);
      const saveTripMirroringFlag = this.serviceSharedData.getData(ServiceSharedData.TRIP_GENERATION.saveTripMirroringFlag);
      if (locationsList && sitesList && location && site && date && shifttype && tripViewFlag) {
        this.cities = locationsList;
        this.sites = sitesList;
        this.selectedCityId = location;
        this.selectedCity = location
        this.selectedSite = site;
        this.tripDate = date;
        this.selectedShiftType = shifttype;
        if (saveTripMirroringFlag != null && saveTripMirroringFlag) {
          this.generatedShift = this.serviceSharedData.getData(ServiceSharedData.TRIP_GENERATION.shiftId);
          this.startTimer = true;
          this.showTrips(true);
        } else {
          this.startTimer = false;
          this.showTrips(false);
        }
        this.startInterval();
        this.serviceSharedData.setData(ServiceSharedData.TRIP_GENERATION.tripViewFlag, false);
        this.serviceSharedData.setData(ServiceSharedData.TRIP_GENERATION.saveTripMirroringFlag, false);
      } else {
        this.selectedShiftType = this.shiftTypes[2];
        this.loadCities();
        this.startInterval();
      }
    }, 0);
  }

  ngOnDestroy() {
    this.stopInterval();
  }

  startInterval() {
    this.sub = interval(12000).subscribe(val => {
      if (this.startTimer) this.showTrips(true);
    });
  }

  stopInterval() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  loadCities() {
    let companyid: number = +this.serviceLocalStorage.getCompanyId();
    this.apiService.doGetRequestWithResponse(ApiConstants.getLocationsByCompanyIdAndUser + companyid, success => {
      if (success.status == 200) {
        this.cities = success.body;
      } else {
        this.cities = [];
        this.notifyService.showInfo("No Location Found!", null)
      }
    }, error => {
      console.log(error)
    });
  }

  getSitesByCityId(cityId) {
    this.selectedCityId = cityId;
    this.apiService.doGetRequestWithResponse(ApiConstants.getSiteByLocationId + cityId.target.value, success => {
      if (success.status == 200) {
        this.sites = success.body.dataList.content;
      } else {
        this.sites = [];
        this.notifyService.showInfo("No Site Found!", null)
      }
    }, error => {
      console.log(error)
    });
  }

  onChangeSite(event) {
    this.selectedSite = this.sites.filter(site => site.siteId == event.target.value);
  }

  formatDate(d): any {
    let m = d.getMonth() + 1;
    let dt = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
    let mm = m < 10 ? "0" + m : m;
    return dt + "/" + mm + "/" + d.getFullYear();
  }

  showTrips(timer) {
    this.errorList = [];
    if (this.tripDate && this.selectedSite) {
      if (!timer)
      this.ngxLoader.start();
      this.apiService.doGetRequestWithResponse(ApiConstants.tripstats + this.formatDate(this.tripDate) + '&siteid=' + this.selectedSite.siteId +
        '&logType=' + this.selectedShiftType, success => {
          this.tripList = [];
          if (success.status == 200) {
            success.body.forEach(element => {
              if (element) {
                if (!element.ERROR) {
                  this.tripList.push({
                    time: element.SHIFT,
                    totalEmp: element.BOOKING,
                    femaleEmp: element.FEMALE,
                    totalTrips: element.TRIPS,
                    totalAdhocEmp: element.ADHOCBOOKING,
                    escortTrip: element.SECURITY,
                    SHIFTID: element.SHIFTID,
                    MSG: element.MSG,
                    ERRORMSG: element.ERRORMSG
                  });
                  this.tripvehs.push({
                    Indica: element.Indica,
                    TT: element.TT
                  });
                  if (element.totalTrips > 0 && element.MSG == "")
                    this.tripViewFlag = true;
                  if (element.TRIPS > 0 && (element.MSG === "" && element.SHIFTID === this.generatedShift)) {
                    this.startTimer = false;
                  }
                } else {
                  this.errorList.push({
                    ERROR: element.ERROR,
                    SHIFTLOG: element.SHIFTLOG,
                    SITENAME: element.SITENAME,
                    TRIPDATE: element.TRIPDATE,
                    ERROR1: element.ERROR1,
                    ERROR2: element.ERROR2,
                    ERROR3: element.ERROR3
                  });
                }
              }
            });
            this.ngxLoader.stop();
            this.getErrorHead();
            // this.showTripGenSuccess();
          } else {
            this.ngxLoader.stop();
            this.notifyService.showInfo("No Data Found", null);
          }
        }, error => {
          if (error.status == 500) {
            this.notifyService.showInfo("Please wait for a moment..", null);
          } else {
            this.ngxLoader.stop();
            this.notifyService.showInfo("Please wait..", null);
          }
          this.startTimer = true;
        });
    } else {
      this.notifyService.showWarning("Site / Date is not selected", null);
    }
  }

  getErrorHead() {
    if (this.tripList[0] != null && this.tripList[0].ERRORMSG) {
      this.headErrorMsg = this.tripList[0].ERRORMSG;
      this.headErrorFlag = true;
    }
  }

  // showTripGenSuccess() {
  //   if (this.errorList) {
  //     this.successFlag = false;
  //     this.errorFlag = false;
  //     this.singleList = [];
  //     this.errorSingleList = [];
  //     this.errorList.forEach(element => {
  //       if (element && element.ERROR != null) {
  //         if (element.ERROR == "SUCC") {
  //           this.successFlag = true;
  //           this.tripViewFlag = true;
  //           this.singleList.push({
  //             ERROR: element.ERROR,
  //             SHIFTLOG: element.SHIFTLOG,
  //             SITENAME: element.SITENAME,
  //             TRIPDATE: element.TRIPDATE,
  //             ERROR1: element.ERROR1,
  //             ERROR2: element.ERROR2,
  //             ERROR3: element.ERROR3
  //           });
  //         } else if (element.ERROR == "ERR") {
  //           this.errorFlag = true;
  //           this.errorSingleList.push({
  //             ERROR: element.ERROR,
  //             SHIFTLOG: element.SHIFTLOG,
  //             SITENAME: element.SITENAME,
  //             TRIPDATE: element.TRIPDATE,
  //             ERROR1: element.ERROR1,
  //             ERROR2: element.ERROR2,
  //             ERROR3: element.ERROR3
  //           });
  //         }
  //       }
  //     });
  //     if (this.successFlag) {
  //       this.tripViewFlag = false;
  //       this.openErrorDialog(this.singleList, true);
  //     } else if (this.errorFlag) {
  //       this.openErrorDialog(this.errorSingleList, false);
  //     }
  //   }
  // }

  // openErrorDialog(data: any[], type: boolean): void {
  //   let d = { type: type, data: data };
  //   this.successFlag = false;
  //   this.errorFlag = false;
  //   let dialogRef = this.dialog.open(RoutingErrorComponent, {
  //     data: d,
  //     disableClose: true,
  //     width: "500px"
  //   });
  // }

  viewDetailedTrip(shiftid) {
    this.serviceSharedData.setData(ServiceSharedData.TRIP_GENERATION.locationsList, this.cities);
    this.serviceSharedData.setData(ServiceSharedData.TRIP_GENERATION.sitesList, this.sites);
    this.serviceSharedData.setData(ServiceSharedData.TRIP_GENERATION.location, this.selectedCityId);
    this.serviceSharedData.setData(ServiceSharedData.TRIP_GENERATION.site, this.selectedSite);
    this.serviceSharedData.setData(ServiceSharedData.TRIP_GENERATION.date, this.tripDate);
    this.serviceSharedData.setData(ServiceSharedData.TRIP_GENERATION.shiftType, this.selectedShiftType);
    this.router.navigate(["/myatom/routing/vendertripview", this.selectedSite.siteId, shiftid, this.formatDate(this.tripDate)]);
  }

  // generate(shift) {
  //   this.errorFlag = true;
  //   let tripData;
  //   if (this.tripDate) {
  //     tripData = {
  //       requestedDate: this.formatDate(this.tripDate),
  //       site: this.selectedSite,
  //       shift: shift
  //     };
  //     this.openDialog(tripData);
  //   } else {
  //     this.notifyService.showWarning("date/site is empty!", null);
  //   }
  // }

  // openDialog(data: any): void {
  //   let dialogRef = this.dialog.open(TripGenerationComponent, {
  //     width: "auto",
  //     data: data,
  //     disableClose: true
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       if (result.status === 'onMirroringSubmit') {
  //         this.generatedShift = result.shiftId;
  //         this.serviceSharedData.setData(ServiceSharedData.TRIP_GENERATION.locationsList, this.cities);
  //         this.serviceSharedData.setData(ServiceSharedData.TRIP_GENERATION.sitesList, this.sites);
  //         this.serviceSharedData.setData(ServiceSharedData.TRIP_GENERATION.location, this.selectedCityId);
  //         this.serviceSharedData.setData(ServiceSharedData.TRIP_GENERATION.site, this.selectedSite);
  //         this.serviceSharedData.setData(ServiceSharedData.TRIP_GENERATION.date, this.tripDate);
  //         this.serviceSharedData.setData(ServiceSharedData.TRIP_GENERATION.shiftType, this.selectedShiftType);
  //         this.serviceSharedData.setData(ServiceSharedData.TRIP_GENERATION.shiftId, result.shiftId);
  //       } else {
  //         this.generatedShift = result.shiftId;
  //         this.startTimer = true;
  //         setTimeout(() => {
  //           this.showTrips(false);
  //         }, 5);
  //       }
  //     }
  //   });
  // }

}
