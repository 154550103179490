import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-boundary-audit',
  templateUrl: './boundary-audit.component.html',
  styleUrls: ['./boundary-audit.component.scss']
})
export class BoundaryAuditComponent implements OnInit {
  _mData: any[];

  constructor(
    public dialogRef: MatDialogRef<BoundaryAuditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      this._mData = data;
    }
  }

  ngOnInit() {
  }

  onClickCancel(): void {
    this.dialogRef.close();
  }

}
