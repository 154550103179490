<div>
  <div class="card">
    <div class="card-body">
    <div class="row" fxLayoutAlign="start center">
      <h4 class="mat-color-text--grey-700" fxFlex>{{_mTitle}}</h4>
      <button mat-icon-button color="accent" (click)="onClickCancel()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
   
   
    <form [formGroup]="_mFormGroup" class="example-form">
      <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
  
            <!-- <label>Driver Name</label>
            <input class="form-input" placeholder="Driver name" (keypress)="discard_special_char_And_String($event)" type="text"
              autocomplete="off" maxlength="25" formControlName="driverName" required> -->
            <!-- <mat-error *ngIf="mDriverName.hasError('required')">
              Please enter name
            </mat-error> -->

            <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
              <mat-label>Driver Name</mat-label>
              <input matInput placeholder="Driver name" (keypress)="discard_special_char_And_String($event)" type="text"
              autocomplete="off" maxlength="25" formControlName="driverName" required>
              <mat-error *ngIf="mDriverName.hasError('required')">
              Please enter name
            </mat-error>
            </mat-form-field>
        
  
        </div>
  
        <div class="col-sm-12 col-md-6">
<!--         
          <label>Contact number</label>
          <input  class="form-input" placeholder="Contact number" type="tel" (keypress)="discard_special_char_And_String($event)"
            autocomplete="off" maxlength="10" formControlName="contactNumber" required> -->
          <!-- <mat-icon matSuffix class="mat-color-text--green-400">phone</mat-icon> -->
          <!-- <mat-error *ngIf="mContactNumber.hasError('required') ||
                      mContactNumber.hasError('pattern')">
            Please enter a valid contact number
          </mat-error>
        -->

        <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
          <mat-label>Contact number</mat-label>
          <input matInput placeholder="Contact number" type="tel" (keypress)="discard_special_char_And_String($event)"
          autocomplete="off" maxlength="10" formControlName="contactNumber" required>
          <mat-error *ngIf="mContactNumber.hasError('required') ||
                      mContactNumber.hasError('pattern')">
            Please enter a valid contact number
          </mat-error>
        </mat-form-field>
  
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
          
            <!-- <label>License number</label>
            <input  class="form-input" placeholder="License number" maxlength="20" (keypress)="discard_special_char($event)"
              autocomplete="off" formControlName="licenseNumber" required> -->
            <!-- <mat-error *ngIf="mLicenseNumber.hasError('required')">
              Please enter license number
            </mat-error> -->

            <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
              <mat-label>License number</mat-label>
              <input matInput placeholder="License number" maxlength="20" (keypress)="discard_special_char($event)"
              autocomplete="off" formControlName="licenseNumber" required>
              <mat-error *ngIf="mLicenseNumber.hasError('required')">
                Please enter license number
              </mat-error>
            </mat-form-field>
         
        </div>
        </div>
  
      
        <div class="hr-line"></div>
        <div class="row mt-4">
        <div class="col-sm-12 mt-5">
  
          <!-- <button mat-raised-button (click)="onClickCancel()" class="tdw-hover-button-secondary
                        tdw-button-padding-8 tdw-border-2">
            CANCEL
          </button> -->
        
          <button mat-raised-button  (click)="onSubmit()" class="col-md-4 btn red-btn-solid"  style="float: right;" >
            SUBMIT
          </button>
        </div>
        </div>
      <!-- </div> -->
    </form>
  </div>
  </div>
</div>