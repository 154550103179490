
    <div class="row">
        <div class="col-xl-12">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-body">
                        <ul ngbNav #nav="ngbNav" [activeId]="activeTabId"
                            class="nav-tabs nav-tabs-custom g-nav-tabs-custom">
                            <li [ngbNavItem]="1">
                                <a ngbNavLink (click)="onTabChange('zone-shift-mapping')">Zone Shift Mapping </a>
                                <ng-template ngbNavContent>
                                    <router-outlet></router-outlet>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="2">
                                <a ngbNavLink (click)="onTabChange('shift-pickup-point-mapping')">Shift Pickup Point Mapping </a>
                                <ng-template ngbNavContent>
                                    <router-outlet></router-outlet>
                                </ng-template>
                            </li>
                          
                        </ul>
                        <div [ngbNavOutlet]="nav" class="p-3"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>