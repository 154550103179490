import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { AppConstants } from 'src/app/core/constants/appconstants';
import { ApiService } from 'src/app/core/services/api.service';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AreaComponent } from '../area/area.component';
import * as L from 'leaflet';
import * as GeoSearch from 'leaflet-geosearch';
import 'leaflet-routing-machine';
import 'leaflet-fullscreen';
// import 'leaflet-control-geocoder/dist/Control.Geocoder.js';
import 'leaflet-draw';
import { Constants } from 'src/app/core/constants/constants';
declare let google: any;

@Component({
  selector: 'app-landmark',
  templateUrl: './landmark.component.html',
  styleUrls: ['./landmark.component.scss']
})
export class LandmarkComponent implements OnInit {
  isEditFlag: boolean = false;
  isOsm: boolean = false;
  landmarkGeoLocating: boolean = false;
  dialogData: any;
  mTitle: any;
  map: any;
  marker: any;
  mlat: any;
  mlng: any;
  directionsDisplay: any;
  directionsService: any;
  LandmarkRoute: any;
  public landmarkTypes = []
  mAreaData: any[] = [];
  processOnSelect: any;

  public _mFormGroup: FormGroup;
  public mArea = new FormControl("", Validators.required);
  public mLandmarkName = new FormControl("", Validators.required);
  public mFormattedAddress = new FormControl("");
  public mLandmarkType = new FormControl("", Validators.required);

  constructor(
    private fb: FormBuilder,
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    private ngxLoader: NgxUiLoaderService,
    private serviceLocalStorage: ServiceLocalStorage,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<LandmarkComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.mArea.valueChanges.subscribe(searchValue => {
      if (searchValue) {
        this.mAreaData = data.area.filter(area => area.area.toLowerCase().includes(searchValue.toLowerCase()));
      }
    });
    this._mFormGroup = this.fb.group({
      landmarkName: this.mLandmarkName,
      area: this.mArea,
      formattedaddress: this.mFormattedAddress,
      landmarkType: this.mLandmarkType
    });
    this.dialogData = data;
    this.mAreaData = data.area;
    if (data.landmark.area != null) {
      this.landmarkTypes = [
        { value: "C", viewValue: "Common point" },
        { value: "H", viewValue: "Home point" }
      ];
      this.mLandmarkType.disable();
      this.mTitle = "Edit APL";
      this.isEditFlag = true;
    } else {
      this.isEditFlag = false;
      this.mTitle = "Add APL";
      this.mLandmarkType.setValue("C");
      this.landmarkTypes = [
        { value: "C", viewValue: "Common point" }
      ];
      this.mLandmarkType.setValue('C');
      this.mLandmarkType.disable();
    }
  }

  ngOnInit() {
    setTimeout(() => {
      if (Constants.OSRM === this.serviceLocalStorage.getMapType()) {
        this.isOsm = true;
        this.loadOsmap();
      } else {
        this.isOsm = false;
        this.directionsDisplay = new google.maps.DirectionsRenderer({
          suppressMarkers: true
        });
        this.directionsService = new google.maps.DirectionsService();
        this.loadgmap();
      }
    }, 0);
  }

  onClickCancel(): void {
    this.dialogRef.close();
  }

  loadOsmap() {
    this.map = L.map("apl-map", {
      fullscreenControl: true,
      dragging: true
    }).setView([
      this.dialogData.site.landmark.latitude,
      this.dialogData.site.landmark.longitude
    ], 13);
    L.tileLayer(Constants.OSM_TILE, { attribution: Constants.OSM_CONTRIBUTORS }).addTo(this.map);
    // this.map.addControl(new L.Control.Search(AppConstants.OSM_NOMINATIM_Obj));
    // const search = GeoSearch.GeoSearchControl({
    //   provider: new GeoSearch.OpenStreetMapProvider(),
    // });
    this.map.addControl(
      new L.Control.Search(Constants.OSM_NOMINATIM_Obj)
    );
    this.loadOSMap1();
    // this.map.addControl(search);
    if (this.isEditFlag) {
      let landmarkLocation = L.latLng(this.dialogData.landmark.latitude, this.dialogData.landmark.longitude);
      this.mArea.setValue(this.dialogData.landmark.area.area);
      this.processOnSelect = this.dialogData.landmark.area;
      this.mLandmarkType.setValue(this.dialogData.landmark.type);
      this.mLandmarkName.setValue(this.dialogData.landmark.landmark);
      this.mFormattedAddress.setValue(this.dialogData.landmark.formattedAddress);
      this.setMarker(landmarkLocation);
    }
    this.plotSite();
    this.showBoundaries();
  }

  loadOSMap1() {
    this.map.on("click", e => {
      if (this.marker) {
        let latLong;
        this.marker.setLatLng(L.latLng(e.latlng.lat, e.latlng.lng));
        latLong = {
          lat: this.marker._latlng.lat,
          lng: this.marker._latlng.lng
        };
        this.getOSGeoLocation1(latLong);
      } else {
        let latLong = { lat: e.latlng.lat, lng: e.latlng.lng };
        this.getOSGeoLocation1(latLong);
      }
    });
  }

  getOSGeoLocation1(latlng: any) {
    let url = "https://maps.myatom.in/nominatim/reverse?lat=" + latlng.lat + "&lon=" + latlng.lng + "&zoom=-4&addressdetails=1&format=json";
    this.apiService.doGetForOsm(url, (success) => {
      this.mLandmarkName.setValue(success.display_name);
      this.mFormattedAddress.setValue(success.display_name);
      if (success) {
        let latLong = {
          lat: success.lat,
          lng: success.lon
        };
        this.setMarker1(latLong);
      }
      this.showBoundaries();
    }, error => {
    });

  }

  loadgmap() {
    let mapCenter = { lat: this.dialogData.site.landmark.latitude, lng: this.dialogData.site.landmark.longitude };
    this.map = new google.maps.Map(document.getElementById("apl-map"), {
      center: mapCenter,
      zoom: 12,
      draggable: true,
      zoomControl: true
    });
    if (this.isEditFlag) {
      const landmarkLocation = new google.maps.LatLng(
        this.dialogData.landmark.latitude,
        this.dialogData.landmark.longitude
      );
      this.setMarker(landmarkLocation);
      this.mArea.setValue(this.dialogData.landmark.area.area);
      this.processOnSelect = this.dialogData.landmark.area;
      this.mLandmarkType.setValue(this.dialogData.landmark.type);
      this.mLandmarkName.setValue(this.dialogData.landmark.landmark);
      this.mFormattedAddress.setValue(this.dialogData.landmark.formattedAddress);
    }
    this.plotSite();
    this.showBoundaries();
  }

  plotSite() {
    let sitecordinates;
    var marker;
    let path = "./assets/images/company.png";
    if (this.isOsm) {
      sitecordinates = new L.latLng(this.dialogData.site.landmark.latitude, this.dialogData.site.landmark.longitude);
      marker = new L.marker(sitecordinates, {
        icon: new L.Icon({
          iconUrl: path,
          popupAnchor: [0, 10],
          iconSize: [40, 40],
          iconAnchor: [20, 40]
        })
      }).addTo(this.map).bindPopup(this.dialogData.site.siteName);
    } else {
      sitecordinates = new google.maps.LatLng(this.dialogData.site.landmark.latitude, this.dialogData.site.landmark.longitude);
      var infowindow = new google.maps.InfoWindow({
        content: this.dialogData.site.siteName
      });
      marker = new google.maps.Marker({
        position: sitecordinates,
        icon: {
          url: path,
          scaledSize: new google.maps.Size(40, 40)
        },
        map: this.map
      });
      google.maps.event.addListener(marker, "click", event => {
        infowindow.open(this.map, marker);
      });
    }
  }

  showBoundaries() {
    let boundary = this.dialogData.site.boundary ? this.dialogData.site.boundary : "";
    let path: any = [];
    let boundaryArr: any = boundary.split("),(");
    let polydata;
    if (this.isOsm) {
      boundaryArr.forEach(element => {
        if (element.includes("(")) element = element.split("(")[1];
        if (element.includes(")")) element = element.split(")")[0];
        path.push({
          lat: parseFloat(element.split(",")[0]),
          lng: parseFloat(element.split(",")[1])
        });
      });
      const editableLayers = new L.FeatureGroup();
      this.map.addLayer(editableLayers);
      polydata = new L.Control.Draw();
      editableLayers.addLayer(L.polygon(path));
      let latLong;
      editableLayers.on("click", e => {
        if (this.marker) {
          this.marker.setLatLng(L.latLng(e.latlng.lat, e.latlng.lng));
          latLong = {
            lat: this.marker._latlng.lat,
            lng: this.marker._latlng.lng
          };
          this.getOSGeoLocation(latLong);
          this.plotDirectionServiceOSM(this.marker._latlng.lat, this.marker._latlng.lng);
        } else {
          this.mlat = e.latlng.lat;
          this.mlng = e.latlng.lng;
          latLong = {
            lat: e.latlng.lat,
            lng: e.latlng.lng
          };
          this.getOSGeoLocation(latLong);
          this.setMarker(L.latLng(e.latlng));
        }
      });
    } else {
      boundaryArr.forEach(element => {
        if (element.includes("(")) element = element.split("(")[1];
        if (element.includes(")")) element = element.split(")")[0];
        let coordinates = new google.maps.LatLng(
          element.split(",")[0],
          element.split(",")[1]
        );
        path.push(coordinates);
      });
      let infoWindow = new google.maps.InfoWindow();
      polydata = new google.maps.Polygon({
        paths: path,
        map: this.map,
        editable: false,
        strokeWeight: 1,
        fillColor: "#FFF176"
      });
      polydata.addListener("click", event => {
        if (this.marker) {
          this.marker.setPosition(event.latLng);
          this.getGeoLocation(this.marker.position.lat(), this.marker.position.lng());
          this.plotDirectionService(this.marker.position.lat(), this.marker.position.lng());
        } else {
          this.mlat = event.latLng.lat();
          this.mlng = event.latLng.lng();
          this.getGeoLocation(event.latLng.lat(), event.latLng.lng());
          this.setMarker(event.latLng);
        }
      });
      polydata.addListener("mousemove", event => {
        setTimeout(() => {
          infoWindow.close();
        }, 1000);
        infoWindow.setContent(this.dialogData.site.siteName);
        infoWindow.setPosition(event.latLng);
      });
    }
  }

  getOSGeoLocation(latlng: any) {
    this.landmarkGeoLocating = true;
    if (navigator.geolocation) {
      // const control = new L.Control.Geocoder({ geocoder: null });
      // control.options.geocoder.reverse(latlng, 13, results => {
      //   this.landmarkGeoLocating = false;
      //   if (results.length > 0) {
      //     this.mLandmarkName.setValue(results[0].name);
      //     this.mFormattedAddress.setValue(results[0].name);
      //   } else {
      //     this.mLandmarkName.setValue('');
      //     this.mFormattedAddress.setValue('');
      //     this.notifyService.showInfo("No address available!", null);
      //   }
      // });
      let url = "https://maps.myatom.in/nominatim/reverse?lat=" + latlng.lat + "&lon=" + latlng.lng + "&zoom=-4&addressdetails=1&format=json";
      this.apiService.doGetForOsm(url, (success) => {
        this.landmarkGeoLocating = false;
        this.mLandmarkName.setValue(success.display_name);
        this.mFormattedAddress.setValue(success.display_name);
      }, error => {
      });

    }
  }

  getGeoLocation(lat: number, lng: number) {
    this.landmarkGeoLocating = true;
    if (navigator.geolocation) {
      // let geocoder = new google.maps.Geocoder();
      let latlng = new google.maps.LatLng(lat, lng);
      // let request = { latLng: latlng };
      // geocoder.geocode(request, (results, status) => {
      //   this.landmarkGeoLocating = false;
      //   if (status == google.maps.GeocoderStatus.OK) {
      //     let result = results[0];
      //     if (results.length > 0) {
      //       this.mLandmarkName.setValue(result.formatted_address);
      //       this.mFormattedAddress.setValue(result.formatted_address);
      //     } else {
      //       this.mLandmarkName.setValue('');
      //       this.mFormattedAddress.setValue('');
      //       alert("No address available!");
      //     }
      //   }
      // });
      let url = "https://maps.myatom.in/nominatim/reverse?lat=" + latlng.lat + "&lon=" + latlng.lng + "&zoom=-4&addressdetails=1&format=json";
      this.apiService.doGetForOsm(url, (success) => {
        this.landmarkGeoLocating = false;
        this.mLandmarkName.setValue(success.display_name);
        this.mFormattedAddress.setValue(success.display_name);
      }, error => {
      });
    }
  }

  setMarker(location) {
    if (this.isOsm) {
      this.setNewOSMarker(location);
      this.plotDirectionServiceOSM(this.marker._latlng.lat, this.marker._latlng.lng);
    } else {
      this.setNewMarker(location);
      this.plotDirectionService(this.marker.position.lat(), this.marker.position.lng());
    }
  }

  setMarker1(location) {
    if (this.isOsm) {
      this.setNewOSMarker(location);
    } else {
      this.setNewMarker(location);
      this.plotDirectionService(this.marker.position.lat(), this.marker.position.lng());
    }
  }

  setNewMarker(location) {
    this.marker = new google.maps.Marker({
      position: location,
      title: "Common Point",
      icon: "./assets/images/marker_pin.png",
      map: this.map
    });
  }

  setNewOSMarker(location) {
    this.marker = new L.marker(location, {
      icon: new L.Icon({
        iconUrl: "./assets/images/marker_pin.png",
        popupAnchor: [0, 0],
        iconSize: [30, 30],
        iconAnchor: [15, 30]
      })
    }).addTo(this.map).bindPopup("Common Point");
  }

  plotDirectionService(lat, lng) {
    this.directionsDisplay.setMap(null);
    let origin = new google.maps.LatLng(lat, lng);
    let sitegeo = new google.maps.LatLng(this.dialogData.site.landmark.latitude, this.dialogData.site.landmark.longitude);
    this.directionsDisplay.setMap(this.map);
    let request = {
      origin: origin,
      destination: sitegeo,
      travelMode: google.maps.TravelMode["DRIVING"],
      provideRouteAlternatives: true
    };
    this.directionsService.route(request, (response, status) => {
      if (status == google.maps.DirectionsStatus.OK) {
        let shortest: number = Number.MAX_VALUE;
        let shortestRoute: any;
        response.routes.forEach(route => {
          if (route.legs[0].distance.value < shortest) {
            shortest = route.legs[0].distance.value;
            shortestRoute = route;
          }
        });
        response.routes = [];
        response.routes.push(shortestRoute);
        this.directionsDisplay.setDirections(response);
      } else {
        this.notifyService.showInfo("No route found to navigate!", null);
      }
    });
  }

  plotDirectionServiceOSM(lat, lng) {
    const wps = [L.latLng(lat, lng), L.latLng(this.dialogData.site.landmark.latitude, this.dialogData.site.landmark.longitude)];
    this.LandmarkRoute ? this.LandmarkRoute.getPlan().setWaypoints([]) : "";
    this.LandmarkRoute = L.Routing.control({
      serviceUrl: Constants.OSM_ROUTING_URL,
      showAlternatives: false,
      lineOptions: {
        styles: [{
          color: "#000",
          weight: 3,
          opacity: 0.7
        }]
      },
      fitSelectedRoutes: true,
      waypointMode: "connect",
      addWaypoints: false,
      draggableWaypoints: false,
      autoRoute: true,
      reverseWaypoints: false,
      altLineOptions: {
        styles: [{
          color: "#ed6852",
          weight: 3
        }]
      },
      show: false,
      createMarker: () => {
        return null;
      },
      routeWhileDragging: false
    }).addTo(this.map);
    this.LandmarkRoute.setWaypoints(wps);
    this.LandmarkRoute.hide();
  }

  onAddArea(): void {
    let dialogRef = this.dialog.open(AreaComponent, {
      width: '300px',
      data: this.mAreaData,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this.apiService.doGetRequestWithResponse(ApiConstants.getarealist +this.mAreaData[0].location.locationId, success => {
        this.apiService.doGetRequestWithResponse(ApiConstants.getAreaListByLocation + this.mAreaData[0].location.locationId, success => {
          if (success.status == 200) {
            this.mAreaData = success.body;
          }
        }, error => {
          console.log(error)
        });
        this.mAreaData = this.dialogData.area;
      }
    });
  }

  onSubmit() {
    if (this.mArea.valid) {
      let areas = [];
      areas = this.mAreaData.filter(a => a.id == this.mArea.value);
      if (this.isEditFlag) {
        let landmark;
        landmark = this.dialogData.landmark;
        landmark.area = this.processOnSelect;
        landmark.type = this.mLandmarkType.value;
        landmark.landmark = this.mLandmarkName.value;
        (landmark.formattedAddress = this.mFormattedAddress.value),
          (landmark.latitude = this.isOsm ? this.marker._latlng.lat : this.marker.position.lat()),
          (landmark.longitude = this.isOsm ? this.marker._latlng.lng : this.marker.position.lng()),
          this.ngxLoader.start();
        this.apiService.doPutRequestWithResponse(ApiConstants.addLandmark, landmark, success => {
          this.ngxLoader.stop();
          if (success.status == 200) {
            this.dialogRef.close(success);
            this.notifyService.showSuccess("Updated successfully!", null);
          } else {
            this.notifyService.showInfo("Landmark not found successfully!", null);
          }
        }, error => {
          this.ngxLoader.stop();
          console.log(error)
        });
      } else {
        let data = {
          area: this.processOnSelect,
          landmark: this.mLandmarkName.value,
          type: this.mLandmarkType.value,
          latitude: this.isOsm ? this.marker._latlng.lat : this.marker.position.lat(),
          longitude: this.isOsm ? this.marker._latlng.lng : this.marker.position.lng(),
          formattedAddress: this.mFormattedAddress.value
        };
        this.ngxLoader.start();
        this.apiService.doPostRequestWithResponse(ApiConstants.addLandmark, data, success => {
          this.ngxLoader.stop();
          if (success.status == 201) {
            this.dialogRef.close(success);
            this.notifyService.showSuccess("Landmark added successfully", null);
          }
        }, error => {
          this.ngxLoader.stop();
          this.notifyService.showError(error.message, null);
        });
      }
    } else {
      this.notifyService.showWarning("Please select area", null);
    }
  }

  onAreaChange(searchData) {
    this.processOnSelect = searchData;
  }


}
