import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-missing-employee-exception',
  templateUrl: './missing-employee-exception.component.html',
  styleUrls: ['./missing-employee-exception.component.scss']
})
export class MissingEmployeeExceptionComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<MissingEmployeeExceptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[]) {
  }

  ngOnInit() {
  }

  onDeny() {
    this.dialogRef.close("Deny");
  }

  onAllow() {
    this.dialogRef.close("Allow");
  }

}
