<div class="row">
<div class="dialog">
    <button mat-icon-button color="primary" (click)="onClickCancel()" style="float:inline-end" >
        <mat-icon color="warn">clear</mat-icon>
    </button>
</div>
</div>
<mat-divider></mat-divider>

<div class="row" style="margin: 10px; font: black;"> Details </div>
<div class="row"  style="margin: 20px 10px 0px 10px!important; font: black; font-size: 13px !important;font-family: 'Poppins' !important;">Updated Date & Time - {{date}} </div>
<!-- <div class="mat-table" style="margin: 5px 20px 20px 20px; width: 500px !important;height: 300px !important;"> -->
    <div class="row" style="height: 300px!important;display: flex;">
    <div *ngIf="templateflag && data.length > 0  && !data.tripUserList && !tableCreated ">
        <table class="popup-table" >
            <tr class="headingFontAudit">
                <th class="headingFontAudit">Field</th>
                <th class="headingFontAudit">Description</th>
                <!-- <th>New Values</th> -->
            </tr>
            <tr *ngFor="let val of data" class="fontBodyAudit" >
                <td> {{val.fieldNames}} </td>
                <td> {{val.fieldNames}} is changed from "{{val.oldValues}}" to "{{val.newValues}}" </td>
                <!-- <td> {{val.newValues}} </td> -->
            </tr>
        </table>
    </div>

    <div *ngIf="templateflag && data.length > 0  && !data.tripUserList && tableCreated && !tableAuditView">
        <table class="popup-table" >
            <tr class="headingFontAudit">
                <th class="headingFontAudit">Field</th>
                <th class="headingFontAudit">Value</th>
                <!-- <th>New Values</th> -->
            </tr>
            <tr *ngFor="let val of data" class="fontBodyAudit">
                <td> {{val.fieldNames}} </td>
                <td> {{val.newValues}} </td>
                <!-- <td> {{val.newValues}} </td> -->
            </tr>

        </table>
    </div>

    <div *ngIf="templateflag && data.tripUserList && data.tripUserList.length > 0 && !tableCreated">
        <table class="popup-table" >
            <tr class="headingFont">
                <th>Updated Date Time</th>
                <th>Employee Id</th>
                <th>Employee Name</th>
                <th>Routed Order</th>
                <th>Show Status</th>
                <th>Current Status</th>
                <th>PickUp-Drop Time</th>
            </tr>
            <tr *ngFor="let tripUser of data.tripUserList" class="fontBody">
                <td>{{tripUser.updatedTime}}</td>
                <td>{{tripUser.employeeId}}</td>
                <td>{{tripUser.employeeName}}</td>
                <td>{{tripUser.routedOrder}}</td>
                <td>{{tripUser.showStatus}}</td>
                <td>{{tripUser.currentStatus}}</td>
                <td>{{tripUser.pickUpDropTime}}</td>
            </tr>
           
        </table>
    </div>

    <div *ngIf="((data.length == 0) && (!data.tripUserList))" style="margin: 50px;">
        No data found
    </div> 

     <div *ngIf="((data.tripUserList) && (data.tripUserList.length == 0))" style="margin: 50px;">
        No data found
    </div>

    <div *ngIf="templateflag && data.length > 0  && !data.tripUserList && tableCreated && tableAuditView">
        <table class="popup-table" >
            <tr class="headingFontAudit">
                <th class="headingFontAudit">Field</th>
                <th class="headingFontAudit">Value</th>
                <!-- <th>New Values</th> -->
            </tr>
            <tr *ngFor="let val of auditData" class="fontBodyAudit">
                <td> {{val[0]}} </td>
                <td> {{val[1]}} </td>
                <!-- <td> {{val.newValues}} </td> -->
            </tr>

        </table>
    </div>
</div>