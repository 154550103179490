<table class="table font-size-14 leaderboard-table my-4">
    <thead class="py-3">
        <tr>
            <th *ngIf="_mIsCheckable">
                <mat-checkbox color="secondary" [checked]="_mIsAllChecked"
                    (change)="onUpdateCheckAll($event)"></mat-checkbox>
            </th>
            <th *ngFor="let header of columnHeaders" [title]="header.label">
                {{header.label}}
            </th>
            <th *ngIf="_mDirectEdit" title="Edit">
                Edit
            </th>
            <th *ngIf="_mAction" title="Action">
                Action
            </th>
            <th *ngIf="_isAuditLog" title="Audit">
                Audit
            </th>
            <th *ngIf="_mPause" title="Pause/Resume">
                Pause/Resume
            </th>
            <th *ngIf="_mDelete" title="Delete">
                Delete
            </th>
            <th *ngIf="_isDownload" title="Download-QR">
                Download-QR
            </th>
            <th *ngIf="_mSubscribeCancel" title="Cancel">Cancel
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of tableData;let i=index">
            <td *ngIf="_mIsCheckable">
                <ng-container>
                    <mat-checkbox color="secondary" [checked]="_mIsCheckedList[getIndex(item)]"
                        (change)="onUpdateCheckItem($event, item)">
                    </mat-checkbox>
                </ng-container>
            </td>

            <td *ngFor="let header of columnHeaders" [title]="getTitle(header.key, item)">

                <ng-container
                    *ngIf="!(header.key === 'callRecordingUrl' || header.key === 'status' || header.key === 'action' || header.key === 'requesterRoles' || header.key === 'recipientRoles' || header.key === 'approverRoles' || header.key ==='bcc_Roles' || header.key ==='ccRoles' || header.key ==='menus' || (item[header.key] != null && item[header.key].items != null && item[header.key].items?.length>0)) ">
                    {{item[header.key]}}
                </ng-container>

                <ng-container *ngIf="header.key === 'approverRoles'">
                    <span>
                        <ng-container *ngIf="item[header.key].items.length > 0 && item[header.key].items[0].value">
                            {{item[header.key].items[0].value}}</ng-container>
                        <span *ngIf="item[header.key].items.length > 0 && item[header.key].items.length > 1">...</span>
                    </span>
                </ng-container>

                <ng-container *ngIf="header.key === 'requesterRoles'">
                    <span>
                        <ng-container *ngIf="item[header.key].items.length > 0 && item[header.key].items[0].value">
                            {{item[header.key].items[0].value}}</ng-container>
                        <span *ngIf="item[header.key].items.length > 0 && item[header.key].items.length > 1">...</span>
                    </span>
                </ng-container>

                <ng-container *ngIf="header.key === 'recipientRoles'">
                    <span>
                        <ng-container *ngIf="item[header.key].items.length > 0 && item[header.key].items[0].value">
                            {{item[header.key].items[0].value}}</ng-container>
                        <span *ngIf="item[header.key].items.length > 0 && item[header.key].items.length > 1">...</span>
                    </span>
                </ng-container>

                <ng-container *ngIf="header.key === 'bcc_Roles'">
                    <span>
                        <ng-container *ngIf="item[header.key].items.length > 0 && item[header.key].items[0].value">
                            {{item[header.key].items[0].value}}</ng-container>
                        <span *ngIf="item[header.key].items.length > 0 && item[header.key].items.length > 1">...</span>
                    </span>
                </ng-container>

                <ng-container *ngIf="header.key === 'ccRoles'">
                    <span>
                        <ng-container *ngIf="item[header.key].items.length > 0 && item[header.key].items[0].value">
                            {{item[header.key].items[0].value}}</ng-container>
                        <span *ngIf="item[header.key].items.length > 0 && item[header.key].items.length > 1">...</span>
                    </span>
                </ng-container>

                <ng-container *ngIf="header.key === 'menus'">
                    <span>
                        <ng-container *ngIf="item[header.key].items.length > 0 && item[header.key].items[0].value">
                            {{item[header.key].items[0].value}}</ng-container>
                        <span *ngIf="item[header.key].items.length > 0 && item[header.key].items.length > 1">...</span>
                    </span>
                </ng-container>

                <ng-container *ngIf="header.key === 'approve' && _misAdhocBooking">
                    <!-- <button class="playlist-btn-solids m-1">Approve</button> -->
                    <img src="./assets/icons/like.png" matTooltip="Approve"
                        (click)="onPendingStatusRequest(item,'Approved')" style="cursor: pointer;margin-right: 15px;">
                </ng-container>

                <ng-container *ngIf="header.key === 'approve' && _misAdhocBooking">
                    <img src="./assets/icons/dislike.png" matTooltip="Reject"
                        (click)="onPendingStatusRequest(item,'Canceled')" style="cursor: pointer">

                </ng-container>

                <ng-container *ngIf="header.key === 'userCount'">
                    <span (click)="onAuditLogClicked(item)">
                        {{item.userCount}}
                    </span>
                </ng-container>

                <ng-container *ngIf="!header.isTableCell && header.key === 'status' && _mToggleStatus">
                    {{item[header.key]}}</ng-container>

                <ng-container *ngIf="!header.isTableCell && header.key === 'status' && !_mToggleStatus">
                    <ui-switch style="position: relative; top: -3px;" defaultBoColor="#dfdfdf" color="#34c38f"
                        [checked]="(item[header.key] == 'Active') ? true : false" (click)="statusToggle(item)">
                    </ui-switch>
                </ng-container>

                <ng-container *ngIf="header.key === 'action'">
                    <span class="list-inline-item" *ngIf="item.edit == 'Edit' ? true : isEditIcon">
                        <a href="javascript: void(0);" title="Edit" (click)="onEditIconClicked(item)">
                            <img src="assets/images/icons/edit.svg">
                        </a>
                    </span>

                    <span class="list-inline-item" *ngIf="item.edit == 'EDIT'">
                        <a href="javascript: void(0);" title="Edit" (click)="onEditIconClicked(item)">
                            <img src="assets/images/icons/edit.svg">
                        </a>
                    </span>

                    <span class="list-inline-item" *ngIf="item.edit == 'Template'">
                        <a href="javascript: void(0);" title="Template" (click)="onEditIconClicked(item)">
                            <img src="assets/images/icons/template.svg">
                        </a>
                    </span>

                    <span class="list-inline-item" *ngIf="_mIsReply">
                        <a href="javascript: void(0);" title="Reply" (click)="onReplyIconClicked(item)">
                            <img src="assets/images/icons/reply.svg">
                        </a>
                    </span>
                </ng-container>

                <ng-container
                    *ngIf="(item[header.key] != null && item[header.key].items != null && item[header.key].items?.length>0)">
                    <span class="" color="accent" [matMenuTriggerFor]="detailMenu" #menuTrigger="matMenuTrigger"
                        (mouseenter)="openDetailMenu(menuTrigger, item, header)">
                        <ng-container *ngIf="item[header.key].items[0].value">
                            {{item[header.key].items[0].value}}</ng-container>
                        ...
                    </span>
                </ng-container>

                <ng-container *ngIf="header.key === 'callRecordingUrl'">
                    <span class="list-inline-item">
                        <span *ngIf="item[header.key] == 'NA'">NA</span>
                        <span style="margin-top: 5px;" *ngIf="item[header.key] !== 'NA'">
                            <a href={{item[header.key]}} target="_blank" title="Call Recording URL">Call Recording</a>
                        </span>
                    </span>
                </ng-container>
            </td>

            <td *ngIf="_mDirectEdit">
                <ng-container>
                    <span class="list-inline-item">
                        <a href="javascript: void(0);" title="Edit" (click)="onEditIconClicked(item)">
                            <img src="assets/images/icons/edit.svg">
                        </a>
                    </span>
                </ng-container>
            </td>

            <td class="list-inline-item" *ngIf="_mAction">
                <ng-container>
                    <span class="list-inline-item">
                        <a href="javascript: void(0);" title="Edit" (click)="onEditIconClicked(item)">
                            <img src="assets/images/icons/edit.svg">
                        </a>
                    </span>
                    <span class="list-inline-item">
                        <a *ngIf="item.status!='Canceled'" title="Cancel" (click)="onPendingStatusRequest(item,
                            'ADHOC')">
                            <img src="assets/images/Cancel.jpg">
                        </a>
                    </span>


                </ng-container>
            </td>

            <td *ngIf="_isAuditLog">
                <ng-container>
                    <span class="list-inline-item">
                        <a href="javascript: void(0);" title="Audit" (click)="onAuditLogClicked(item)">
                            <img src="assets/images/icons/audit.svg">
                        </a>
                    </span>
                </ng-container>
            </td>

            <td *ngIf="_mPause">
                <ng-container>
                    <span class="list-inline-item">
                        <a href="javascript: void(0);" [title]="item.jobStatus" (click)="onPause(item)">
                            <img *ngIf="item.jobStatus == 'SCHEDULED'" src="assets/images/Resume.png">
                            <img *ngIf="item.jobStatus == 'PAUSED'" src="assets/images/Pause.png">
                        </a>
                    </span>
                </ng-container>
            </td>

            <td *ngIf="_mDelete">
                <ng-container>
                    <span class="list-inline-item">
                        <a href="javascript: void(0);" title="Delete" (click)="onDelete(item)">
                            <img src="assets/images/Delete.png">
                        </a>
                    </span>
                </ng-container>
            </td>

            <td *ngIf="_mSubscribeCancel">
                <ng-container>
                    <span class="list-inline-item">
                        <a href="javascript: void(0);" title="Cancel" (click)="onSubscribeCancel(item)">
                            <img src="assets/images/Cancel.jpg">
                        </a>
                    </span>
                </ng-container>
            </td>

            <!-- <td class="list-inline-item" *ngIf="_mAction">
                <ng-container>
                    <span class="list-inline-item">
                            <a href="javascript: void(0);" title="Edit" (click)="onEditIconClicked(item)">
                                <img src="assets/images/icons/edit.svg">
                            </a>
                        </span>
                            <span class="list-inline-item">
                            <a  *ngIf="item.status!='Canceled'"   title="Edit" (click)="onPendingStatusRequest(item,
                            'ADHOC')">
                                <img src="assets/images/Cancel.jpg">
                            </a>
                            </span>
                     
                   
                </ng-container>
            </td> -->

            <td *ngIf="_isDownload">
                <ng-container>
                    <span class="list-inline-item">
                        <a href="javascript: void(0);" (click)="onDownloadIconClicked(item)">
                            <span class="material-icons">
                                cloud_download
                            </span></a>
                    </span>
                </ng-container>
            </td>

        </tr>

        <mat-menu #detailMenu="matMenu">
            <div (mouseleave)="_closeDetailMenu()">
                <button mat-menu-item (click)="onDetailMenuSelected(detail)" *ngFor="let detail of _mDetails">{{
                    detail.value }}</button>
            </div>
        </mat-menu>
    </tbody>
</table>