import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import SecureStorage from 'secure-web-storage';
import * as CryptoJS from 'crypto-js';
const SECRET_KEY: any = 'K6w{`CAL5eVuLJR';

@Injectable({
  providedIn: "root"
})
export class ServiceLocalStorage {
  private permissionSubj = new Subject();
  currentUser: any;

  AUTH_TOKEN = "auth-token";
  authToken: any = undefined;

  PERMISSION_OBJECT = "permission-object";
  permissionobject: any = {};

  USER_ID = "user-id";
  userid: any;

  ROLE_ID = "role-id";
  roleid: any;

  ROLE_Name = "role-name";
  roleName: any;

  COMPANY_ID = "company-id";
  companyid: any;

  LOCATION_ID = "location-id";
  locationid: any;

  SITE_ID = "site-id";
  siteid: any;

  COMPANY_WeeklyOff = "company-weeklyoff";
  companyweeklyoff: any;

  DATABASE_NAME = 'database-name';
  databasename: any;

  MAP_TYPE = "map-type";
  maptype: any;

  TRIP_GEN_DATA = "trip_gen_data";
  tripgendata: any;

  _mRouteName = "route-name";
  routeName: any;

  _mSubRouteName = "sub-route-name";
  subRouteName: any;

  USER_OBJECT = "user-object";
  userobject: any;

  USER_EDIT_ID = "user-edit-id";
  usereditid: any;

  REF_TOKEN = "Refresh-Token";
  refreshToken: any;

  CURRENT_PAGE_NUMBER = "current-page-number";
  currentpagenumber: any;

  REQUEST_ID = "request-id";
  requestid: any;

  dashboardFlag = "";
  constructor() { }

  public profileImage: BehaviorSubject<any> = new BehaviorSubject('');
  getProfileObs(): Observable<any> {
    return this.profileImage.asObservable();
  }

  setProfileObs(profileUrl: any) {
    this.profileImage.next(profileUrl);
  }

  public userName: BehaviorSubject<any> = new BehaviorSubject('');
  getFirstNameObs(): Observable<any> {
    return this.userName.asObservable();
  }

  setFirstNameObs(nameDisplay: any) {
    this.userName.next(nameDisplay);
  }

  getAuthToken(): String {
    if (this.authToken == undefined || this.authToken == null) {
      this.authToken = this.getJsonValue(this.AUTH_TOKEN);
      // this.authToken = localStorage.getItem(this.AUTH_TOKEN);
    }
    return this.authToken;
  }

  setAuthToken(token: string) {
    this.setJsonValue(this.AUTH_TOKEN, token);
    // localStorage.setItem(this.AUTH_TOKEN, token);
    this.authToken = token;
  }

  hasAuthToken(): boolean {
    if (this.authToken == undefined || this.authToken == null) {
      this.authToken = this.getJsonValue('this.AUTH_TOKEN');
      // this.authToken = localStorage.getItem(this.AUTH_TOKEN);
    }
    return this.authToken !== undefined && this.authToken !== null && this.authToken.length > 0 ? true : false;
  }

  getRefToken(): String {
    if (this.refreshToken == undefined || this.refreshToken == null) {
      this.refreshToken = this.getJsonValue(this.REF_TOKEN);
    }
    return this.refreshToken;
  }

  setRefToken(refreshToken: string) {
    this.setJsonValue(this.REF_TOKEN, refreshToken);
    this.refreshToken = refreshToken;
  }

  deleteToken(): void {
    this.authToken = undefined;
    localStorage.removeItem(this.AUTH_TOKEN);
  }

  deleteAll(): void {
    this.authToken = undefined;
    localStorage.clear();
    // Clear the localStorage
    this.clearToken();
  }

  getRoleId(): string {
    if (this.roleid == undefined || this.roleid == null) {
      // this.roleid = localStorage.getItem(this.ROLE_ID);
      this.roleid = this.getJsonValue(this.ROLE_ID);
    }
    return this.roleid;
  }

  setRoleId(roleid: string) {
    // localStorage.setItem(this.ROLE_ID, roleid);
    this.setJsonValue(this.ROLE_ID, roleid);
    this.roleid = roleid;
  }

  getRoleName(): string {
    if (this.roleName == undefined || this.roleName == null) {
      // this.roleName = localStorage.getItem(this.ROLE_Name);
      this.roleName = this.getJsonValue(this.ROLE_Name);
    }
    return this.roleName;
  }

  setRoleName(roleName: string) {
    // localStorage.setItem(this.ROLE_Name, roleName);
    this.setJsonValue(this.ROLE_Name, roleName);
    this.roleName = roleName;
  }

  getPermissionSub() {
    return this.permissionSubj;
  }

  setPermissionObject(permissionobject: any) {
    localStorage.setItem(this.PERMISSION_OBJECT, permissionobject);
    this.permissionobject = permissionobject;
    this.permissionSubj.next(permissionobject);
  }

  getPermissionObject(): any {
    if (this.permissionobject == undefined || this.permissionobject == null) {
      this.permissionobject = localStorage.getItem(this.PERMISSION_OBJECT);
    }
    this.permissionSubj.next(this.permissionobject);
    return this.permissionobject;
  }

  getUserId(): string {
    if (this.userid == undefined || this.userid == null) {
      this.userid = this.getJsonValue(this.USER_ID);
      // this.userid = localStorage.getItem(this.USER_ID);
    }
    return this.userid;
  }

  setUserId(userid: string) {
    this.setJsonValue(this.USER_ID, userid);
    // localStorage.setItem(this.USER_ID, userid);
    this.userid = userid;
  }

  getLocationId(): string {
    if (this.locationid == undefined || this.locationid == null) {
      this.locationid = this.getJsonValue(this.LOCATION_ID);
      // this.locationid = localStorage.getItem(this.LOCATION_ID);
    }
    return this.locationid;
  }

  setLocationId(locationid: string) {
    this.setJsonValue(this.LOCATION_ID, locationid);
    // localStorage.setItem(this.LOCATION_ID, locationid);
    this.locationid = locationid;
  }

  getSiteId(): string {
    if (this.siteid == undefined || this.siteid == null) {
      this.siteid = this.getJsonValue(this.SITE_ID);
      // this.siteid = localStorage.getItem(this.SITE_ID);
    }
    return this.siteid;
  }

  setSiteId(siteid: string) {
    this.setJsonValue(this.SITE_ID, siteid);
    // localStorage.setItem(this.SITE_ID, siteid);
    this.siteid = siteid;
  }

  getCompanyId(): string {
    if (this.companyid == undefined || this.companyid == null) {
      this.companyid = this.getJsonValue(this.COMPANY_ID);
      // this.companyid = localStorage.getItem(this.COMPANY_ID);
    }
    return this.companyid;
  }

  setCompanyId(companyid: string) {
    this.setJsonValue(this.COMPANY_ID, companyid);
    // localStorage.setItem(this.COMPANY_ID, companyid);
    this.companyid = companyid;
  }

  getCompanyWeeklyOff(): string {
    if (this.companyweeklyoff == undefined || this.companyweeklyoff == null) {
      this.companyweeklyoff = this.getJsonValue(this.COMPANY_WeeklyOff);
      // this.companyweeklyoff = localStorage.getItem(this.COMPANY_WeeklyOff);
    }
    return this.companyweeklyoff;
  }

  setcompanyWeeklyOff(companyweeklyoff: string) {
    this.setJsonValue(this.COMPANY_WeeklyOff, companyweeklyoff);
    // localStorage.setItem(this.COMPANY_WeeklyOff, companyweeklyoff);
    this.companyweeklyoff = companyweeklyoff;
  }

  getDatabaseName(): string {
    if (this.databasename == undefined || this.databasename == null) {
      this.databasename = this.getJsonValue(this.DATABASE_NAME);
      // this.databasename = localStorage.getItem(this.DATABASE_NAME);
    }
    return this.databasename;
  }

  setDatabaseName(databaseName: string) {
    this.setJsonValue(this.DATABASE_NAME, databaseName);
    // localStorage.setItem(this.DATABASE_NAME, databaseName);
    this.databasename = databaseName;
  }

  getMapType(): string {
    if (this.maptype == undefined || this.maptype == null) {
      this.maptype = this.getJsonValue(this.MAP_TYPE);
      // this.maptype = localStorage.getItem(this.MAP_TYPE);
    }
    return this.maptype;
  }

  setMapType(mapType: string) {
    this.setJsonValue(this.MAP_TYPE, mapType);
    // localStorage.setItem(this.MAP_TYPE, mapType);
    this.maptype = mapType;
  }

  getTripGenerationData(): string {
    if (this.tripgendata == undefined || this.tripgendata == null) {
      this.tripgendata = localStorage.getItem(this.TRIP_GEN_DATA);
    }
    return this.tripgendata;
  }

  setTripGenerationData(tripgendata: string) {
    localStorage.setItem(this.TRIP_GEN_DATA, tripgendata);
    this.tripgendata = tripgendata;
  }

  getRouteName(): string {
    if (this.routeName == undefined || this.routeName == null) {
      this.routeName = this.getJsonValue(this._mRouteName);
      // this.routeName = localStorage.getItem(this._mRouteName);
    }
    return this.routeName;
  }

  setRouteName(routeName: string) {
    this.setJsonValue(this._mRouteName, routeName);
    // localStorage.setItem(this._mRouteName, routeName);
    this.routeName = routeName;
  }

  getSubRouteName(): string {
    if (this.subRouteName == undefined || this.subRouteName == null) {
      this.subRouteName = this.getJsonValue(this._mSubRouteName);
      // this.subRouteName = localStorage.getItem(this._mSubRouteName);
    }
    return this.subRouteName;
  }

  setSubRouteName(subRouteName: string) {
    this.setJsonValue(this._mSubRouteName, subRouteName);
    // localStorage.setItem(this._mSubRouteName, subRouteName);
    this.subRouteName = subRouteName;
  }

  getUserObject(): string {
    let decryptObj = this.getJsonValue(this.USER_OBJECT);
    // let decryptObj = localStorage.getItem(this.USER_OBJECT);
    this.userobject = JSON.parse(decodeURIComponent(decryptObj));
    return this.userobject;
  }

  setUserObject(userobject: string) {
    let object = encodeURIComponent(JSON.stringify(userobject));
    this.setJsonValue(this.USER_OBJECT, object);
    // localStorage.setItem(this.USER_OBJECT, object);
    this.userobject = object;
  }

  getUserEditId(): string {
    if (this.usereditid == undefined || this.usereditid == null) {
      this.usereditid = this.getJsonValue(this.USER_EDIT_ID);
      // this.usereditid = localStorage.getItem(this.USER_EDIT_ID);
    }
    return this.usereditid;
  }

  setUserEditId(usereditid: string) {
    this.setJsonValue(this.USER_EDIT_ID, usereditid);
    // localStorage.setItem(this.USER_EDIT_ID, usereditid);
    this.usereditid = usereditid;
  }

  getRequestParamId(): string {
    if (this.requestid == undefined || this.requestid == null) {
      this.requestid = localStorage.getItem(this.REQUEST_ID);
    }
    return this.requestid;
  }

  setRequestParamId(requestId: any) {
    localStorage.setItem(this.REQUEST_ID, requestId);
    this.requestid = requestId;
  }

  getCurrentPageNumber(): string {
    if (this.currentpagenumber == undefined || this.currentpagenumber == null) {
      this.currentpagenumber = localStorage.getItem(this.CURRENT_PAGE_NUMBER);
    }
    return this.currentpagenumber;
  }

  setCurrentPageNumber(currentpagenumber: string) {
    localStorage.setItem(this.CURRENT_PAGE_NUMBER, currentpagenumber);
    this.currentpagenumber = currentpagenumber;
  }

  public secureStorage = new SecureStorage(localStorage, {
    hash: function hash(key: any): any {
      key = CryptoJS.SHA256(key, SECRET_KEY);
      return key.toString();
    },
    // Encrypt the localstorage data
    encrypt: function encrypt(data: any) {
      data = CryptoJS.AES.encrypt(data, SECRET_KEY);
      data = data.toString();
      return data;
    },
    // Decrypt the encrypted data
    decrypt: function decrypt(data: any) {
      data = CryptoJS.AES.decrypt(data, SECRET_KEY);
      data = data.toString(CryptoJS.enc.Utf8);
      return data;
    }
  });

  // Set the json data to local storage
  setJsonValue(key: string, value: any) {
    this.secureStorage.setItem(key, value);
  }

  // Get the json value from local storage
  getJsonValue(key: string) {
    return this.secureStorage.getItem(key);
  }

  // Clear the local storage
  clearToken() {
    return this.secureStorage.clear();
  }

}