<div class="card">
    <div class="card-body">
        <h2 mat-dialog-title>
            <mat-icon style="color:#FF455B; float: right; cursor: pointer; position: relative; bottom: 8px; left: 25px;" (click)="onClickCancel()">close</mat-icon>
                <div style="font-size: 18px; font-weight: 400; font-family: 'poppins'; margin-bottom: 10px;">Exception Employees</div>
        </h2>           
        <div class="padding">
            <div class="exception-users-list" *ngIf="!normalExceptionUsers?.length">No Exception Employees</div>
            <div class="exception-users-list" *ngFor="let user of normalExceptionUsers">
                {{user.tripUser.userInfoDetails.employeeId}} - {{user.tripUser.userInfoDetails.displayName}}
                <mat-icon *ngIf="user.tripCode!='NA'" (click)="modify(user)">chevron_right</mat-icon>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center">
            <h4  mat-dialog-title fxFlex style="font-size: 18px; font-weight: 400; font-family: 'poppins';">Added Employees</h4>
        </div>

        <!-- <hr class="hr1"> -->
        <div class="padding">
            <div class="exception-users-list" *ngIf="!addedExceptionUsers?.length">No Employees Added</div>
            <div class="exception-users-list" *ngFor="let user of addedExceptionUsers">
                {{user.tripUser.userInfoDetails.employeeId}} - {{user.tripUser.userInfoDetails.displayName}} -
                {{user.tripCode}}
                <mat-icon *ngIf="user.tripCode!='NA'" (click)="modify(user)">chevron_right</mat-icon>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center">
            <h4 mat-dialog-title fxFlex style="font-size: 18px; font-weight: 400; font-family: 'poppins';">Deleted Employees</h4>
        </div>

        <!-- <hr class="hr1"> -->
        <div class="padding">
            <div class="exception-users-list" *ngIf="!deletedExceptionUsers?.length">No Employees Deleted</div>
            <div class="exception-users-list" *ngFor="let user of deletedExceptionUsers">
                {{user.tripUser.userInfoDetails.employeeId}} - {{user.tripUser.userInfoDetails.displayName}} -
                {{user.tripCode}}
                <mat-icon *ngIf="user.tripCode!='NA'" (click)="modify(user)">chevron_right</mat-icon>
            </div>
        </div>

    </div>
</div>
<!-- <div class="mat-modal-dialog trip-modal">
    <h2 mat-dialog-title>
        <div>
            Exception Employees
        </div>

        <span class="material-icons-outlined" style="color:#747679; font-size: 25px; cursor: pointer;"
            (click)="onClickCancel()">close</span>
    </h2>
    <mat-dialog-content>
        <div class="padding">
            <div class="exception-users-list" *ngIf="!normalExceptionUsers?.length">No Exception Employees</div>
            <div class="exception-users-list" *ngFor="let user of normalExceptionUsers">
                {{user.tripUser.userInfoDetails.employeeId}} - {{user.tripUser.userInfoDetails.displayName}}
                <mat-icon *ngIf="user.tripCode!='NA'" (click)="modify(user)">chevron_right</mat-icon>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center">
            <h4 class="mat-color-text--grey-700" fxFlex>Added Employees</h4>
        </div>

        <hr class="hr1">
        <div class="padding">
            <div class="exception-users-list" *ngIf="!addedExceptionUsers?.length">No Employees Added</div>
            <div class="exception-users-list" *ngFor="let user of addedExceptionUsers">
                {{user.tripUser.userInfoDetails.employeeId}} - {{user.tripUser.userInfoDetails.displayName}} -
                {{user.tripCode}}
                <mat-icon *ngIf="user.tripCode!='NA'" (click)="modify(user)">chevron_right</mat-icon>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center">
            <h4 class="mat-color-text--grey-700" fxFlex>Deleted Employees</h4>
        </div>

        <hr class="hr1">
        <div class="padding">
            <div class="exception-users-list" *ngIf="!deletedExceptionUsers?.length">No Employees Deleted</div>
            <div class="exception-users-list" *ngFor="let user of deletedExceptionUsers">
                {{user.tripUser.userInfoDetails.employeeId}} - {{user.tripUser.userInfoDetails.displayName}} -
                {{user.tripCode}}
                <mat-icon *ngIf="user.tripCode!='NA'" (click)="modify(user)">chevron_right</mat-icon>
            </div>
        </div>
    </mat-dialog-content>
</div> -->