import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject } from 'rxjs';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { ApiService } from 'src/app/core/services/api.service';
import { ServiceExportExcel } from 'src/app/core/services/export.service';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { OnCallBookingAllocComponent } from './on-call-booking-alloc/on-call-booking-alloc.component';

@Component({
  selector: 'app-on-call-booking',
  templateUrl: './on-call-booking.component.html',
  styleUrls: ['./on-call-booking.component.scss']
})
export class OnCallBookingComponent {

  addFlag: boolean = true;
  showFlag: boolean = true;
  _mData: any = [];
  _mCurrentUserData: any;
  _mEmpId: number = null;
  _mCurrentUserRoleFlag: boolean = false;
  _mCurrentUserVendorFlag: boolean = false;
  _mColumns: String[] =
    [
      'site',
      'bookedBy',
      'employeeId',
      'employeeName',
      'traveldate',
      'travelTime',
      'vendor',
      'escort',
      'status'
    ];
  _mSpocId: any = null;
  _mTotalPage: number = 1;
  _mCurrentNumber: number = 0;
  searchData: any;
  _mIsLoading = true;
  isSpecialBooking: boolean = true;
  _mIsNoPagination = false;
  _mRawData: any[] = [];
  tempSearchKey: any = '';
  _mPreviousPageNumber: number = 0;
  searchSubject$ = new Subject<string>();
  ELEMENT_DATA: any[] = [];

  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    public readonly serviceLocalStorage: ServiceLocalStorage,
    private ngxLoader: NgxUiLoaderService,
    private readonly serviceExportExcel: ServiceExportExcel,
    private fb: FormBuilder,
    public readonly dialog: MatDialog,
  ) {

  }

  ngOnInit(): void {
    this.getCurrentUser();
  }

  getCurrentUser() {
    this._mCurrentUserData = this.serviceLocalStorage.getUserObject();
    if (this._mCurrentUserData != null) {

      if (
        this._mCurrentUserData.userInfoDetails.role.name == 'Vendor'
      ) {
        this._mCurrentUserVendorFlag = true;
      }

      this._mEmpId =
        this._mCurrentUserData.userInfoDetails.role.id == 1 ||
          this._mCurrentUserData.userInfoDetails.role.id == 2 ||
          this._mCurrentUserData.userInfoDetails.role.id == 4
          ? ""
          : this._mCurrentUserData.userInfoDetails.id;
      this.getAll(this._mCurrentUserData.id);
    }
  }

  getAll(userId) {
    if (this._mCurrentUserData.userInfoDetails.role.name == "SPOC") {
      this._mSpocId = this._mCurrentUserData.userInfoDetails.id;
    } else {
      this._mSpocId = "";
    }
    this.apiService.doGetRequestWithResponse(ApiConstants.getSpecialBookingForVendor + userId,
      success => {
        this._mIsLoading = false;
        if (success.status == 200) {
          this._mRawData = success.body;
          this._mData = this.setDataLocally(this._mRawData);
          // this._mCurrentNumber = success.body.dataList.number;
          // this._mTotalPage = success.body.dataList.totalPages;
          this.ELEMENT_DATA = this._mData;
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        } else {
          this._mRawData = [];
          this._mData = [];
          this.ELEMENT_DATA = [];
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this._mTotalPage = 0;
        }
      },
      error => {
        this._mIsLoading = false
      }
    );
  }

  setDataLocally(data: any[]) {
    let specialBookings: any[] = [];
    data.forEach((booking, i) => {
      let specialBookingLoc = {
        id: booking.id,
        site: booking.site.siteName,
        bookedby: booking.bookedBy.userInfoDetails.firstName,
        employeeId: booking.bookedFor.userInfoDetails.employeeId,
        firstName: booking.bookedFor.userInfoDetails.firstName,
        travelDate: booking.travelDate,
        travelTime: booking.reportingTime,
        pickDrop: booking.pickDrop,
        vendor: booking.vendorMaster
          ? booking.vendorMaster.name
          : "Yet to Allocate",
        escort: booking.escortRequired ? "YES" : "No",
        status: booking.status,
        vendorAccepted: booking.vendorAccepted
      };
      specialBookings.push(specialBookingLoc);
    });
    return specialBookings;
  }

  openDialog(element): void {
    let data = null;

    element = this._mRawData.find(data => data.id === element.id);

    if (this._mCurrentUserVendorFlag) { 
      data = {
        employeeAccessFlag: false,
        vendorAccessFlag: true,
        userData: element
      };

      let dialogRef = this.dialog.open(OnCallBookingAllocComponent, {
        disableClose: true,
        data: data,
        height: '500px',
        width: '55%'
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.notifyService.showSuccess('Updated Successfully', null);
          this.getAll(this._mCurrentUserData.id);
        }
      });
    }
  }

  pendingStatusRequest(element: any, status: any) {
    
      if ( status == "EDIT" ) {
        this.openDialog(this._mRawData.find(data => data.id === element.id));
      }
    
  }

  updateSpecialBooking(element) {
    let data = this._mRawData.find(data => data.id === element.id);
    data.status = element.status;
    data.bookedFor.userInfoDetails.userProject = null;
    data.bookedFor.userInfoDetails.userCategory = null;
    this.apiService.doPutRequestWithResponse(ApiConstants.updateSpecialBookingStatus,
      data,
      success => {
        if (success) {
          this.getAll(this._mCurrentUserData.id);
          this.notifyService.showSuccess("Updated successfully", null);
        }
      },
      error => {
        this.notifyService.showError(error, null);
      }
    );
  }

  onRejectStatusRequest(item) {
    this.ngxLoader.start();
    item = this._mRawData.find(data => data.id === item.id);
    item.status = "REJECTED_BY_VENDOR";
    item.bookedFor.userInfoDetails.userProject = null;
    item.bookedFor.userInfoDetails.userCategory = null;
    item.vehicleList = null;
    item.vehicle = null;
    item.driver = null;
    this.apiService.doPutRequestWithResponse(ApiConstants.updateSpecialbooking,item, (success) => {
      this.ngxLoader.stop();
      if (success) {
        this.notifyService.showInfo("Updated successfully", null);
        this.getAll(this._mCurrentUserData.id);
      }
    }, (error) => {
      this.ngxLoader.stop();
      this.notifyService.showError(error, null);
    });
  }

  acceptTrip(element) {
    this.apiService.doGetRequestWithResponse(ApiConstants.onCallBookingAcceptedByVendorMaster + "?bookingId=" + element.id, success => {
        if (success.status == 200) {
          this.getAll(this._mCurrentUserData.id);
          this.notifyService.showSuccess("Updated successfully", null);
        }
      },
      error => {
        this.notifyService.showError(error, null);
      }
    );
  }

}
