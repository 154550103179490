import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-trip-user-time',
  templateUrl: './trip-user-time.component.html',
  styleUrls: ['./trip-user-time.component.scss']
})
export class TripUserTimeComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TripUserTimeComponent>
  ) { }

  ngOnInit() {
  }

  onGoogleTime() {
    this.dialogRef.close("GoogleTime");
  }

  onManualTime() {
    this.dialogRef.close("ManualTime");
  }

  onDeny() {
    this.dialogRef.close();
  }

}
