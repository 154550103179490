<div class="mat-modal-dialog city-modal-on-call">
    <h2 mat-dialog-title>
        <span>{{_mTitle}}</span>
        <span class="material-icons-outlined"
            style="color:#747679; font-size: 25px; float: right; position: relative; top: 10px; cursor: pointer"
            (click)="onClickCancel()"> close</span>
    </h2>

    <mat-dialog-content>
        <form [formGroup]="_mFormGroup">
            <div class="form-group">
                <label>Comments<sup class="mandatory">*</sup></label>
                <input type="text" name="mComments" placeholder="Comments" autocomplete="off" maxlength="50"
                    formControlName="mComments" required>
                <div  class="error-msg"
                *ngIf="_mFormGroup.get('mComments').hasError('required') && _mFormGroup.get('mComments').touched">
                        Comments is mandatory.
    
                </div>
            </div>
        </form>
    </mat-dialog-content>

    <div mat-dialog-actions>
        <button class="btn on-call-ProceedBtn" (click)="onConfirmation()">
            Done
        </button>
    </div>
</div>