<div class="mat-modal-dialog pushBack-dialog-modal">
  <h2 mat-dialog-title *ngIf="_mData.title">
    <mat-icon class="cancelIcon" svgIcon="cancelIcon"></mat-icon>
    {{_mData.title}}
  </h2>
  <mat-dialog-content>
    <p>{{_mData.message}}</p>
    <div class="row d-flex" style="text-align: center;">
      <div class="col-sm-12 col-md-12">
        <form class="example-form" [formGroup]="_mFormGroup">
          <mat-form-field appearance="outline" class="customFieldInSideAdmin">
            <mat-select placeholder="Choose Reason" formControlName="reasonType" required>
              <mat-option style=" font-size: 13px !important; font-weight: 400 !important;"
                *ngFor="let type of _mReasonTypes" [value]="type.value">
                {{ type.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
    </div>
  </mat-dialog-content>

  <div mat-dialog-actions align="end">
    <button (click)="onClickCancel()" *ngIf="!_mData.isAlert" class="btn footerBtn deny-btn-solid me-3">
      Cancel
    </button>
    <button class="btn footerBtn red-btn-solid" style="padding: 8px 30px !important;" (click)="onSubmit()">
      Submit
    </button>
  </div>
</div>