<div fxLayout="row mt-3" fxLayoutAlign="start center" style="margin: 10px;">
    <h4 class="mat-color-text--grey-700" fxFlex>Preview</h4>
    <button mat-icon-button color="accent" (click)="onClickCancel()">
      <mat-icon>clear</mat-icon>
    </button>

  </div>
<mat-card class="map-card mt-3">
  <!-- <ul ngbNav #nav="ngbNav" class="nav-tabs nav-tabs-custom g-nav-tabs-custom">
  </ul> -->
  <div id="map"></div>
</mat-card>