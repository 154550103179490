import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppConstants } from 'src/app/core/constants/appconstants';
import { ApiService } from 'src/app/core/services/api.service';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import "leaflet-routing-machine";
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
declare let google: any;
declare let L;

@Component({
  selector: 'app-trip-edit-zone',
  templateUrl: './trip-edit-zone.component.html',
  styleUrls: ['./trip-edit-zone.component.scss']
})
export class TripEditZoneComponent implements OnInit {
  currentZone;
  mapCenter: any;
  map: any = {};
  zonebounds: any = [];
  zonePoly: any[] = [];
  zones: any[] = [];
  marker: any;
  searchZone: any;
  editzonebounds: any = [];
  editZoneFlag = false;
  polydata;
  isOsm: boolean = false;
  _layer: any;
  toggleEditZone: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private readonly apiService: ApiService,
    private serviceLocalStorage: ServiceLocalStorage,
    private notifyService: NotificationService,
    private ngxLoader: NgxUiLoaderService
  ) { }

  ngOnInit() {
    setTimeout(() => {
      if (AppConstants.OSRM === this.serviceLocalStorage.getMapType()) {
        this.isOsm = true;
      }
      this.getTripZones();
    }, 10);
  }

  getTripZones() {
    const tripId = this.route.snapshot.params.tripId;
    if (tripId != null) {
      this.apiService.doGetRequestWithResponse(ApiConstants.getZoneByTripId + tripId, success => {
        const data = success.body;
        data.isEdit = false;
        this.currentZone = data;
        this.getZoneList(success.body);
        this.plotZone(success.body, false);
        this.getTripUsers(tripId);
      }, error => {
        console.log(error)
      }
      );
    }
  }

  drawMap(trip) {
    if (!this.isOsm) {
      this.mapCenter = {
        lat: parseFloat(trip[0].lat),
        lng: parseFloat(trip[0].lng)
      };
      this.map = new google.maps.Map(document.getElementById("zonemap"), {
        center: this.mapCenter,
        zoom: 13,
        draggable: true,
        zoomControl: true
      });
    } else {
      if (Object.entries(this.map).length !== 0) {
        this.map.remove();
      }
      this.map = L.map("zonemap", {
        fullscreenControl: true
      }).setView([parseFloat(trip[0].lat), parseFloat(trip[0].lng)], 13);
      L.tileLayer(AppConstants.OSM_TILE, { attribution: AppConstants.OSM_CONTRIBUTORS }).addTo(this.map);
      this.map.addControl(new L.Control.Search(AppConstants.OSM_NOMINATIM_Obj));
    }
  }

  plotZone(trip, fromZoneList) {
    let polygonArray = [];
    let polygonData;
    polygonData = trip.zonepoly.replace("(", "");
    polygonData = polygonData.split("),(").join("|");
    polygonData = polygonData.replace(")", "");
    polygonData.split("|").forEach(poly => {
      polygonArray.push({
        lat: parseFloat(poly.split(",")[0]),
        lng: parseFloat(poly.split(",")[1])
      });
    });
    fromZoneList ? "" : this.drawMap(polygonArray);
    if (!this.isOsm) {
      let editableFlag = false;
      this.polydata = new google.maps.Polygon({
        paths: polygonArray,
        editable: editableFlag,
        map: this.map,
        strokeWeight: 3,
        strokeOpacity: 0.8,
        fillOpacity: 0.2,
        fillColor: "#fffccc"
      });
      this.polydata._backEndData = { fromZone: fromZoneList, data: trip };
      this.polydata.addListener("rightclick", event => {
        let infoWindow = new google.maps.InfoWindow();
        infoWindow.setContent(trip.name);
        infoWindow.setPosition(event.latLng);
        infoWindow.open(this.map);
      });
      this.zonePoly.push(this.polydata);
      this.zonebounds = this.polydata.getPath().getArray();
      google.maps.event.addListener(this.polydata.getPath(), "set_at", () => {
        this.zonebounds = this.polydata.getPath().getArray();
        if (this.editZoneFlag)
          this.editzonebounds = this.polydata.getPath().getArray();
      });
      google.maps.event.addListener(
        this.polydata.getPath(),
        "insert_at",
        () => {
          this.zonebounds = this.polydata.getPath().getArray();
          if (this.editZoneFlag)
            this.editzonebounds = this.polydata.getPath().getArray();
        }
      );
      google.maps.event.addListener(
        this.polydata.getPath(),
        "remove_at",
        () => {
          this.zonebounds = this.polydata.getPath().getArray();
          if (this.editZoneFlag)
            this.editzonebounds = this.polydata.getPath().getArray();
        }
      );
    } else {
      this.plotOSZone(polygonArray, trip, fromZoneList);
    }
  }

  plotOSZone(polygonArray, trip, fromZoneList) {
    this.removeEditControlOSM();
    var editableLayers = new L.FeatureGroup();
    this.map.addLayer(editableLayers);
    this.polydata = new L.Control.Draw({
      position: "topleft",
      draw: {
        polygon: false,
        polyline: false,
        circle: false,
        rectangle: false,
        marker: false
      },
      edit: {
        featureGroup: editableLayers,
        remove: false
      }
    });
    this.polydata._backEndData = {
      fromZone: fromZoneList,
      data: trip,
      leafletId: editableLayers._leaflet_id
    };
    editableLayers.addLayer(L.polygon(polygonArray));
    this.zonePoly.push(this.polydata);
    this.map.on("draw:created", e => {
      this.editZoneFlag = true;
      this._layer = e.layer;
      editableLayers.addLayer(this._layer);
      this.editzonebounds = this._layer._latlngs[0];
    });
    this.map.on("draw:edited", e => {
      this.editZoneFlag = true;
      var layers = e.layers;
      layers.eachLayer(layer => {
        this._layer = layer;
      });
      editableLayers.addLayer(this._layer);
      this.editzonebounds = this._layer._latlngs[0];
    });
  }

  getZoneList(data) {
    this.apiService.doGetRequestWithResponse(ApiConstants.getZonesByMasterZoneId + data.masterZone.id + '&siteId=' + data.masterZone.site.siteId +
      '&locationId=' + data.masterZone.site.location.locationId + '&zoneId=' + data.id, success => {
        this.zones = success.body;
      }, error => {
        console.log(error)
      });
  }

  getTripUsers(tripId) {
    const tripIds = [tripId];
    this.apiService.doPostRequestWithResponse(ApiConstants.gettripsbytripids + tripIds, '', success => {
      this.setRoute(success.body[0][0], tripId);
    }, error => {
      console.log(error)
    });
  }

  setRoute(users, tripId) {
    let location;
    let waypoints: any = [];
    let origin: any;
    let destination: any;
    origin = {
      lat: users.tripDetails.site.landmark.latitude,
      lng: users.tripDetails.site.landmark.longitude
    };
    destination = {
      lat: users.tripDetails.site.landmark.latitude,
      lng: users.tripDetails.site.landmark.longitude
    };
    users.tripDetails.tripUsers.forEach((user, u) => {
      location = {
        lat: user.landmark.latitude,
        lng: user.landmark.longitude
      };
      waypoints.push({ location: location, stopover: true });
      this.isOsm ? "" : this.plotEmployees(user, u + 1);
    });
    this.markSite(users.tripDetails.site);
    if (users.tripDetails.shift.logType == "IN") {
      let index = waypoints.length;
      origin = {
        lat: users.tripDetails.tripUsers[0].landmark.latitude,
        lng: users.tripDetails.tripUsers[0].landmark.longitude
      };
    }
    if (users.tripDetails.shift.logType == "OUT") {
      destination = {
        lat:
          users.tripDetails.tripUsers[users.tripDetails.tripUsers.length - 1]
            .landmark.latitude,
        lng:
          users.tripDetails.tripUsers[users.tripDetails.tripUsers.length - 1]
            .landmark.longitude
      };
    }
    if (!this.isOsm) {
      var directionsService = new google.maps.DirectionsService();
      var directionsDisplay = new google.maps.DirectionsRenderer({
        draggable: false,
        polylineOptions: { strokeWeight: 3, strokeColor: "#0080FE" },
        suppressMarkers: true
      });
      directionsDisplay.setMap(this.map);
      directionsDisplay.setOptions({ suppressMarkers: true });
      directionsService.route({
        origin: origin,
        destination: destination,
        waypoints: waypoints,
        optimizeWaypoints: true,
        travelMode: google.maps.DirectionsTravelMode.WALKING,
        avoidHighways: true
      }, (response, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          directionsDisplay.setDirections(response);
        } else {
          window.alert("Directions request failed due to " + status);
        }
      }
      );
    } else {
      this.generateRoute(users.tripDetails, tripId);
    }
  }

  generateRoute(users, tripId) {
    this.apiService.doGetRequestWithResponse(ApiConstants.getdirectionresult + tripId, success => {
      let wps = [];
      success.body.OSRM.waypoints.forEach((wp, i) => {
        wps.push(L.latLng(wp.location[1], wp.location[0]));
      });
      let r = L.Routing.control({
        serviceUrl: AppConstants.OSM_ROUTING_URL,
        showAlternatives: false,
        lineOptions: {
          styles: [
            {
              color: "#000",
              weight: 3,
              opacity: 0.7
            }
          ]
        },
        fitSelectedRoutes: true,
        waypointMode: "connect",
        addWaypoints: false,
        draggableWaypoints: false,
        autoRoute: true,
        reverseWaypoints: false,
        altLineOptions: {
          styles: [
            {
              color: "#ed6852",
              weight: 3
            }
          ]
        },
        show: true,
        routeWhileDragging: false,
        createMarker: (i, wp, nWps) => {
          if (success.body.LOGTYPE === "IN") {
            if (i === users.tripUsers.length) {
              let m = L.marker(wp.latLng, {
                icon: L.icon({
                  iconUrl: "./assets/images/company.png",
                  iconSize: [40, 40]
                }),
                title: users.site.landmark.landmark
              });
              return m;
            }
          } else {
            if (i === 0) {
              let m = L.marker(wp.latLng, {
                icon: L.icon({
                  iconUrl: "./assets/images/company.png",
                  iconSize: [40, 40]
                }),
                title: users.site.landmark.landmark
              });
              return m;
            }
          }
          if (success.body.LOGTYPE === "IN") {
            if (i < users.tripUsers.length) {
              let m = L.marker(wp.latLng, {
                icon: L.icon({
                  iconUrl: users.tripUsers[i].tripUser.userInfoDetails.gender === "Female"
                    ? "./assets/images/livetracking/femaleloc.svg" : "./assets/images/livetracking/maleloc.svg",
                  iconSize: [65, 70]
                }),
                title: users.tripUsers[i].tripUser.userInfoDetails.displayName
              })
                .bindTooltip(i + 1 + "", {
                  permanent: true,
                  direction: "left",
                  offset: L.point(-10, 0)
                })
                .openTooltip();
              return m;
            }
          } else {
            if (i - 1 <= users.tripUsers.length) {
              let m = L.marker(wp.latLng, {
                icon: L.icon({
                  iconUrl: users.tripUsers[i - 1].tripUser.userInfoDetails.gender === "Female"
                    ? "./assets/images/livetracking/femaleloc.svg" : "./assets/images/livetracking/maleloc.svg",
                  iconSize: [65, 70]
                }),
                title:
                  users.tripUsers[i - 1].tripUser.userInfoDetails
                    .displayName
              }).bindTooltip(i + "", {
                permanent: true,
                direction: "left",
                offset: L.point(-10, 0)
              })
                .openTooltip();
              return m;
            }
          }
        }
      }).addTo(this.map);
      r.setWaypoints(wps);
      r.hide();
    }, error => {
      console.log(error)
    });
  }

  markSite(site) {
    if (!this.isOsm) {
      let sitecordinates = new google.maps.LatLng(
        site.landmark.latitude,
        site.landmark.longitude
      );
      let path = "./assets/images/company.png";
      this.marker = new google.maps.Marker({
        position: sitecordinates,
        icon: {
          url: path,
          scaledSize: new google.maps.Size(40, 40)
        },
        map: this.map,
        title: site.siteName
      });
    }
  }

  plotEmployees(tripUser, index) {
    let location = {
      lat: tripUser.landmark.latitude,
      lng: tripUser.landmark.longitude
    };
    let path = "./assets/images/livetracking/maleloc.svg";
    if (tripUser.tripUser.userInfoDetails.gender == "Female") {
      path = "./assets/images/livetracking/femaleloc.svg";
    }
    this.marker = new google.maps.Marker({
      position: location,
      draggable: false,
      title: tripUser.tripUser.userInfoDetails.displayName + " - " + tripUser.landmark.area.area,
      icon: {
        url: path,
        labelOrigin: new google.maps.Point(18, -8)
      },
      animation: google.maps.Animation.DROP,
      map: this.map,
      label: {
        text: "P" + index,
        color: "#000",
        fontWeight: "bold",
        fontSize: "12px"
      }
    });
  }

  zoneListChanged(e, zone) {
    this.zones.forEach((inZone, z) => {
      if (zone.id === inZone.id) {
        this.zones[z].isEditClicked = e.checked;
      }
    });
    if (e.checked) {
      this.plotZone(zone, true);
    } else {
      this.zonePoly.forEach((zones, zIndex) => {
        if (
          zones._backEndData.fromZone &&
          zones._backEndData.data.id === zone.id
        ) {
          if (this.isOsm) {
            this.map.eachLayer(layer => {
              if (
                this.zonePoly[zIndex]._backEndData.leafletId ===
                layer._leaflet_id
              ) {
                this.map.removeLayer(layer);
                this.map.removeControl(zones);
              }
            });
          } else {
            this.zonePoly[zIndex].setMap(null);
          }
        }
      });
    }
  }

  removeEditControlOSM() {
    if (this.isOsm) {
      this.zonePoly.forEach(zone => {
        this.map.removeControl(zone);
      });
    }
  }

  editZone(zone) {
    this.removeEditControlOSM();
    this.editzonebounds = [];
    this.editzonebounds.push(zone.zonepoly);
    this.editZoneFlag = true;
    this.currentZone.isEdit = true;
    this.zones.forEach((inZone, z) => {
      this.zones[z].isEdit = false;
      if (inZone.id === zone.id) {
        this.zones[z].isEdit = true;
        this.currentZone.isEdit = false;
      }
    });
    this.zonePoly.forEach((innerZone, zIndex) => {
      if (this.isOsm) {
        this.map.removeControl(innerZone);
        this.map.eachLayer(layer => {
          if (innerZone._backEndData.data.id === zone.id && this.zonePoly[zIndex]._backEndData.leafletId === layer._leaflet_id) {
            this.map.addControl(innerZone);
            let element: HTMLElement = document.getElementsByClassName("leaflet-draw-edit-edit")[0] as HTMLElement;
            element.click();
          }
        });
      } else {
        this.zonePoly[zIndex].setEditable(false);
        if (innerZone._backEndData.data.id === zone.id) {
          this.zonePoly[zIndex].setEditable(true);
        }
      }
    });
  }

  saveZone(zone) {
    if (this.editZoneFlag) {
      this.ngxLoader.start();
      const zoneboundary = this.convertPolyArrayToString(this.editzonebounds);
      this.apiService.doPutRequestWithResponse(ApiConstants.zone + zone.id + '&zonepoly=' + zoneboundary + '&zoneName=' + zone.name, '', success => {
        if (success.status == 200) {
          this.ngxLoader.stop();
          this.editZoneFlag = false;
          this.zonebounds = [];
          this.notifyService.showSuccess("Zone saved successfully.", null);
          this.getTripZones();
          this.removeEditControlOSM();
        }
      }, error => {
        this.ngxLoader.stop();
        this.notifyService.showError("Error, Failed to update!", null);
      });
    }
  }

  convertPolyArrayToString(polyarray) {
    let polyString = "";
    polyarray.forEach(obj => {
      if (this.isOsm) {
        polyString += ",(" + obj.lat + "," + obj.lng + ")";
      } else {
        polyString += ",(" + obj.lat() + "," + obj.lng() + ")";
      }
    });
    polyString = polyString.replace(",", "");
    return polyString;
  }

}
