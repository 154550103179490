import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-alert',
  templateUrl: './confirmation-alert.component.html',
  styleUrls: ['./confirmation-alert.component.scss']
})
export class ConfirmationAlertComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmationAlertComponent>) { }

  ngOnInit(): void {
  }

  onClickCancel(): void {
    this.dialogRef.close("");
  }

  onConfirmation(): void {
    this.dialogRef.close("yes");
  }

}
