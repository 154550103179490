<div class="map_div">
    <div id="zonemap"></div>
</div>

<div class="zone_edit" [ngClass]="{'openEditZone' : toggleEditZone,'closeEditZone' : !toggleEditZone}">
    <div class="zone_expand_btn" [style.margin-left.px]="!!toggleEditZone ? '-50' : '-95'"
        (click)="toggleEditZone=!toggleEditZone">
        <span *ngIf="!toggleEditZone">Edit zone</span>
        <i *ngIf="!!toggleEditZone" class="toggle-icon material-icons">close</i>
    </div>

    <div class="zone_header">{{currentZone?.name}}
        <span *ngIf="currentZone?.isEdit" (click)="saveZone(currentZone)">Save</span>
        <span><i class="material-icons" (click)="editZone(currentZone)">edit</i></span>
    </div>

    <mat-form-field class="example-full-width search">
        <input type="tel" [(ngModel)]="searchZone" matInput placeholder="Search zone" autocomplete="off">
        <img matSuffix src="assets/images/search.svg" height="27" style="margin-top: -22px;padding-right: 5px;">
    </mat-form-field>

    <mat-divider></mat-divider>
    <ul class="edit_zone">
        <li *ngFor="let z of zones | zoneSearchFilter : searchZone">
            <mat-checkbox (change)="zoneListChanged($event,z);z.status=$event.checked;">{{z.name}}</mat-checkbox>
            <div class="zone-btns pull-right" *ngIf="z.isEditClicked">
                <button mat-stroked-button class="edit-btn" (click)="editZone(z);">Edit</button>
                <button mat-stroked-button color="warn" *ngIf="z.isEdit" (click)="saveZone(z)">Save</button>
            </div>
        </li>
    </ul>
</div>