import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";;
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/core/services/api.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-map-driver-vehicle',
  templateUrl: './map-driver-vehicle.component.html',
  styleUrls: ['./map-driver-vehicle.component.scss']
})
export class MapDriverVehicleComponent implements OnInit {

  public _mFormGroup: FormGroup;
  public mVehicle = new FormControl("", Validators.required);
  public mDriver = new FormControl("", Validators.required);

  public _mTitle = "Map Vehicle Driver";
  mVehicleList: any[] = [];
  mDriverList: any[] = [];
  selectedVehicleId: any;
  mTempVehicleList: any[] = [];
  searchSubject$ = new Subject<string>();
  empSearchFlag: boolean = false;
  vendorMasterId:any;

  constructor(
    public dialogRef: MatDialogRef<MapDriverVehicleComponent>,
    private fb: FormBuilder,
    private serviceLocalStorage: ServiceLocalStorage,
    public dialog: MatDialog,
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    private ngxLoader: NgxUiLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this._mFormGroup = this.fb.group({
      vehicle: this.mVehicle,
      driver: this.mDriver,

    });

    this.searchSubject$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(searchValue => {
      // this.selectedVehicleId.setValue(data.id);
      this.empSearchFlag = true;
      if (searchValue.length < 3) {
        this.empSearchFlag = false;
      }

      if (searchValue.length >= 3) {
        this.apiService.doGetRequestWithResponse(ApiConstants.getActiveOnCallVehiclesnew
           + '?vendorMasterId=' + this.data.vendorMasterId + '&vehicleTypeId=' + this.data.vehicleTypeId,
          success => {
            this.empSearchFlag = false;
            this.mVehicleList = success.body;
          },
          error => {
            this.empSearchFlag = false;
          }
        );
      }
    });

    this.mVehicle.valueChanges.subscribe(searchValue => {
      if (searchValue.length >= 3) {
        this.mTempVehicleList = this.mVehicleList.filter(vehicle => vehicle.registrationNo.toLowerCase().includes(searchValue.toLowerCase()));
      }
    });

  }

  ngOnInit() {
    this.requestServerAllVehicles();
    this.requestServerAllDrivers();
  }

  onClickCancel(): void {
    this.dialogRef.close();
  }

  requestServerAllVehicles() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getActiveOnCallVehiclesnew +
      "?vendorMasterId=" + this.data.vendorMasterId +
      "&vehicleTypeId=" + this.data.vehicleTypeId,
      success => {
        if (success) {

          this.mVehicleList = success.body;
        } else {
          this.notifyService.showError("No Vehicles Found", null);
        }
      },
      error => {
        this.notifyService.showError(error.message, null);
      }
    );
  }


  requestServerAllDrivers() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getActiveOnCallDrivers + "?vendorMasterId=" + this.data.vendorMasterId,
      success => {
        if (success) {

          this.mDriverList = success.body;

        } else {
          this.notifyService.showError("No Drivers Found", null);
        }
      },
      error => {
        this.notifyService.showError(error.message, null);
      }
    );
  }

  onMap() {
    if (this.mDriver.value && this.mVehicle.value) {
      let mapVehicleDriverId: any[] = [];
      mapVehicleDriverId.push(this.mDriver.value);
      this.ngxLoader.start();
      this.apiService.doPutRequestWithResponse(ApiConstants.updatevehicleDriver + "?vehicleid=" + this.selectedVehicleId +
        "&driverids=" + mapVehicleDriverId.toString(), '',
        success => {
          this.ngxLoader.stop();
          this.notifyService.showSuccess("Updated successfully", null);
          this.dialogRef.close();
        },
        error => {
          this.ngxLoader.stop();
        }
      );
    }

  }

  updateValue(data) {
    this.selectedVehicleId = data.id;
  }
  searchSubmit(data) {
    // this.selectedVehicleId.setValue(data.id);
    this.selectedVehicleId = data.id;

    // this.mEmployeeId.setValue(userSelected.userInfoDetails.employeeId);
    // this.mSites.setValue(userSelected.userInfoDetails.site.siteId);
    // this.getAllPrePopulatedLandmarks(this.mSites.value);
    // this.requestServerEscortListBySite(userSelected.userInfoDetails.site.siteId);
    // if (userSelected.userInfoDetails.userProject != null) {
    //   this.mProject.setValue(userSelected.userInfoDetails.userProject[0].projects.projectId);
    // }

    // this._BookedForUser = userSelected;
  }

}
