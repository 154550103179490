import { environment } from "src/environments/environment";
declare let L;

export class Constants {
  static readonly DATE_FMT = "dd/MMM/yyyy";
  static readonly DATE_TIME_FMT = `${Constants.DATE_FMT} hh:mm:ss`;
  static readonly DOWNLOAD_TEMPLATE_BASE_LINK =
    environment.baseTemplateDownloadUrl;
  static readonly OSRM = "OSRM";
  static readonly OSM_TILE =
    "https://maps.myatom.in/osm/{z}/{x}/{y}.png";
    // "https://uatmaps.myatom.in/osm/{z}/{x}/{y}.png"
  // static readonly OSM_TILE =
  //   "https://maps.agiledge.in/osm/{z}/{x}/{y}.png";
    static readonly OSM_CONTRIBUTORS=
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';
  static readonly OSM_NOMINATIM_Obj = {
    // url: "http://34.93.4.113:5000/nominatim/search?format=json&q={s}",
    url: "https://maps.myatom.in/nominatim/search?format=json&q={s}",
    // url: "https://maps.agiledge.in/nominatim/search?format=json&q={s}",
    //url: "https://nominatim.openstreetmap.org/search?format=json&q={s}",
    jsonpParam: "json_callback",
    propertyName: "display_name",
    propertyLoc: ["lat", "lon"],
    marker: L.circleMarker([0, 0], {
      radius: 25,
      color: "#e93f33",
      opacity: 0.8
    }),
    animate: true,
    autoCollapse: true,
    autoType: false,
    minLength: 2
  };

  // static readonly OSM_NOMINATIM_Obj1 = {
  //   url: "https://nominatim.openstreetmap.org/search?format=json&q={s}",
  //   jsonpParam: "json_callback",
  //   propertyName: "display_name",
  //   propertyLoc: ["lat", "lon"],
  //   marker: L.circleMarker([0, 0], {
  //     radius: 25,
  //     color: "#e93f33",
  //     opacity: 0.8
  //   }),
  //   animate: true,
  //   autoCollapse: true,
  //   autoType: false,
  //   minLength: 2
  // };
  // static readonly OSM_ROUTING_URL = "http://34.93.4.113:5000/route/v1";
  // static readonly OSM_ROUTING_URL = "https://map.agiledge.in/route/v1";
  // static readonly OSM_ROUTING_URL = "https://maps.myatom.in/route/v1";
  static readonly OSM_ROUTING_URL = "https://maps.myatom.in/route/v1";
  // static readonly OSM_ROUTING_URL = "https://maps.agiledge.in/route/v1";
}

