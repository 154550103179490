<div class="row">
    <div [ngClass]="{'col-md-9': isStats}">
        <div class="total_routing">
            <!-- <div class="routing_main_heading">Master Routing -->
            <ul ngbNav #nav="ngbNav" [activeId]="activeTabId" class="nav-tabs nav-tabs-custom g-nav-tabs-custom"
                style="padding-top: 7px;">
                <li [ngbNavItem]="1">
                    <a ngbNavLink> Master Routing</a>
                    <ng-template ngbNavContent>
                        <em (click)="isStats=!isStats" [matTooltip]="(isStats) ? 'Hide Stats' : 'Show Stats'"
                            [ngClass]="(isStats) ? 'fa-align-left' : 'fa-align-right'" class="fa is-stats"
                            aria-hidden="true"></em>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="p-1"></div>
            <!-- </div> -->

            <div class="routing_filter_div">
                <div class="row">
                    <div class="col-md-6">
                        <form [formGroup]="_mFormGroup">
                            <div class="row">
                                <div class="col-md-5 ms-3">
                                    <label>City<sup class="mandatory">*</sup></label>
                                    <select class="form-select margin-right-8px" placeholder="City"
                                        formControlName="location"
                                        (ngModelChange)="citySelected=true;onCityChange($event)">
                                        <option disabled value="">Choose City</option>
                                        <option *ngFor="let city of cities" [value]="city.locationId">
                                            {{ city.locationName }}
                                        </option>
                                    </select>
                                </div>

                                <div class="col-md-5 ms-3">
                                    <label>Site<sup class="mandatory">*</sup></label>
                                    <select class="form-select margin-right-8px" placeholder="Choose Site"
                                        formControlName="site" (change)="onSiteChange($event)">
                                        <option disabled value="">Choose Site</option>
                                        <option class="masterRouteUnCheckMatoption" *ngFor="let site of sites"
                                            [value]="site.siteId">
                                            {{ site.siteName}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-md-6">
                        <ul class="routing_top_icons">
                            <li class="mt-2" matTooltip="Clear map" *ngIf="selectedCity && selectedSite"
                                (click)="clearMap()"><em class="material-icons">refresh</em></li>

                            <li matTooltip="Site boundry Audit log" *ngIf="selectedCity && selectedSite"
                                (click)="showAudit()"><em class="fa fa-history" aria-hidden="true"></em></li>

                            <li matTooltip="Hide boundry" (click)="hideBoundary(false)"
                                *ngIf="selectedCity && selectedSite && showBoundary && isGeofence"><em
                                    class="fa fa-eye-slash" aria-hidden="true"></em></li>

                            <li matTooltip="View boundry" (click)="hideBoundary(true)"
                                *ngIf="!showBoundary && isGeofence"><em class="fa fa-eye" aria-hidden="true"></em></li>

                            <li matTooltip="Edit boundry"
                                *ngIf="selectedCity && selectedSite && isGeofence && !isGeofenceChanged && showBoundary"
                                (click)="editGeofence()"><em class='fa fa-edit' aria-hidden="true"></em></li>

                            <li matTooltip="Save boundry" *ngIf="selectedCity && selectedSite && isGeofenceChanged"
                                (click)="saveGeofence()"><em class="fa fa-save" aria-hidden="true"></em></li>

                            <li *ngIf="selectedCity && selectedSite" (click)="onAddApl($event)" matTooltip="Add APL"><em
                                    class="fa fa-plus" aria-hidden="true"></em></li>

                            <li class="mt-2" *ngIf="selectedCity" (click)="onUploadAPL()" matTooltip="Upload APL"><em
                                    class="material-icons-outlined">file_upload</em></li>

                            <li *ngIf="!isGeofence && selectedSite && !isGeofenceChanged" (click)="createGeofence()">
                                Add Site Boundary </li>

                            <li *ngIf="selectedCity && selectedSite" (click)="viewMasterZone=!viewMasterZone"
                                [ngClass]="{'active': viewMasterZone}">Master Zones</li>
                        </ul>

                        <div class="top_drop_down" [hidden]="!viewMasterZone">
                            <div class="col-md-12" *ngIf="!addZoneFlag">
                                <div fxLayout="row" class="subscription-report-search-container">
                                    <mat-form-field class="customField create_master_zone_width" appearance="outline">
                                        <input matInput placeholder="Create new master zone" type="text"
                                            autocomplete="off" maxlength="20" [(ngModel)]="masterZoneName"
                                            (keypress)="serviceSharedData.omit_special_char($event)">
                                    </mat-form-field>

                                    <ul class="zones_sub_icons margin-top">
                                        <li *ngIf="masterZoneName"
                                            (click)="saveMasteZone(masterZoneName);masterZoneName=''"><em
                                                class="fa fa-save" aria-hidden="true"></em></li>
                                        <li (click)="viewMasterZone=!viewMasterZone"><em class="fa fa-times-circle"
                                                aria-hidden="true"></em></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                            <div class="col-md-12" *ngIf="!addZoneFlag && masterZoneData">
                                <div fxLayout="row" class="subscription-report-search-container">
                                    <mat-form-field class="customField width" appearance="outline"
                                        *ngIf="!isMasterZoneEdit">
                                        <mat-select placeholder="Master Zones" [(ngModel)]="autoSelectedzone"
                                            (selectionChange)="masterZoneChanged($event)" class="font-size-13">
                                            <mat-option class="font-size-13" *ngFor="let mzone of masterzone"
                                                [value]="mzone.id">
                                                {{mzone.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field class="customField width" appearance="outline"
                                        *ngIf="isMasterZoneEdit">
                                        <input matInput placeholder="Master Zone Name" [(value)]="selectedzone.name"
                                            type="text" maxlength="20" (input)="editedZone=$event.target.value;"
                                            (keypress)="omit_special_char_And_String($event)">
                                    </mat-form-field>

                                    <ul class="zones_sub_icons margin-top" *ngIf="isMasterZoneEdit">
                                        <li (click)="updateMasterZone(editedZone)" matTooltip="Save Master Zone"><em
                                                class="fa fa-save" aria-hidden="true"></em></li>
                                        <li matTooltip="Cancel Master Zone renaming" (click)="cancelMasterZoneEdit()">
                                            <em class="fa fa-times-circle" aria-hidden="true"></em>
                                        </li>
                                    </ul>

                                    <ul class="zones_sub_icons margin-top" *ngIf="!isMasterZoneEdit">
                                        <li matTooltip="Show on map" *ngIf="!showzone" (click)="showZones()"><em
                                                class="fa fa-crosshairs" aria-hidden="true"></em></li>
                                        <li matTooltip="Hide" *ngIf="showzone" (click)="hideZones()"><em
                                                class="fa fa-eye-slash" aria-hidden="true"></em></li>
                                        <li matTooltip="Edit Master Zone" (click)="editMasterZone()"><em
                                                class="fa fa-edit" aria-hidden="true"></em></li>
                                        <li matTooltip="Remove Master Zone" (click)="removeMasterZone()"><em
                                                class="fa fa-trash" aria-hidden="true"></em></li>
                                        <li *ngIf="!addZoneFlag" matTooltip="Create new Zone"
                                            (click)="enablecreatezone();addZoneFlag=true;zoneName=null;zonebounds=[]">
                                            <em class="fa fa-plus" aria-hidden="true"></em>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                            <div class="col-md-12" *ngIf="addZoneFlag">
                                <div fxLayout="row" class="subscription-report-search-container">
                                    <mat-form-field class="customField create_master_zone_width" appearance="outline">
                                        <input matInput placeholder="Zone name" [(ngModel)]="zoneName"
                                            autocomplete="off">
                                    </mat-form-field>

                                    <ul class="zones_sub_icons margin-top">
                                        <li (click)="savezone(zoneName)" *ngIf="!editZoneFlag"><em class="fa fa-save"
                                                aria-hidden="true" matTooltip="Save Zone"></em></li>
                                        <li (click)="upadatezone()" *ngIf="editZoneFlag"><em class="fa fa-save"
                                                aria-hidden="true" matTooltip="Update Zone"></em></li>
                                        <li (click)="removeNewZoneControl();onReset();removeEditControlAndLayer();"><em
                                                class="fa fa-times-circle" aria-hidden="true"></em></li>
                                    </ul>
                                </div>
                            </div>

                            <div class="total_config" [hidden]="!(zones.length>0 && showzone)">
                                <div class="config_heading">Zone Master Config
                                    <span><em (click)="cloneMasterZone()" class="fa fa-clipboard"
                                            matTooltip="Clone master zone" aria-hidden="true"></em></span>
                                    <span><em (click)="onShowAllZone()"
                                            [matTooltip]="viewAllZone ? 'Hide all Zones' : 'Show all Zones'"
                                            [ngClass]="viewAllZone ? 'fa fa-eye' : 'fa fa-eye-slash'"
                                            aria-hidden="true"></em></span>
                                </div>
                                <ul class="config_list">
                                    <li *ngFor="let zone of zones; let i=index;">{{zone.name}}
                                        <span><em (click)="onShowZone(i)"
                                                [ngClass]="zone.viewFlag ? 'fa-eye eye-color' : 'fa-eye-slash'"
                                                class="fa" aria-hidden="true"></em></span>
                                        <span> <em class="fa fa-trash" (click)="deleteZone(zone.id)"
                                                aria-hidden="true"></em></span>
                                        <span><em class="fa fa-edit" (click)="editZone(zone.id, zone, i)"
                                                aria-hidden="true"></em></span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <input *ngIf="!isOsm" id="pac-input" class="controls" type="text" placeholder="Search your place here">
            <div class="routing_map" (mouseover)='onMouseOverOnMap()' id="map"></div>
        </div>
    </div>

    <div [hidden]="!isStats" [ngClass]="{'col-md-3 animate': isStats}">
        <div class="col-sm-12 col-md-12 total_routing">
            <div class="col-sm-12 col-md-12 routing_main_heading">{{selectedSiteObj?.siteName}} - Stats</div>
            <ul class="routing_stat" [hidden]="viewStatsDetail">
                <li (click)="getAreaLandmarkByType('total')">
                    <div class="stat_heading">
                        <div class="stat_left">Total AL</div>
                        <div class="stat_right">{{totalAlC}}</div>
                    </div>
                    <div class="total_stat_bar">
                        <div class="stat_process" [style.width.%]="100"></div>
                    </div>
                </li>

                <li (click)="getAreaLandmarkByType('H')">
                    <div class="stat_heading">
                        <div class="stat_left">Home Pin</div>
                        <div class="stat_right">{{homePointC}}</div>
                    </div>
                    <div class="total_stat_bar">
                        <div class="stat_process" [style.width.%]="(homePointC / totalAlC) * 100"></div>
                    </div>
                </li>

                <li (click)="getAreaLandmarkByType('C')">
                    <div class="stat_heading">
                        <div class="stat_left">Common Pin</div>
                        <div class="stat_right">{{commonPointC}}</div>
                    </div>
                    <div class="total_stat_bar">
                        <div class="stat_process" [style.width.%]="(commonPointC / totalAlC) * 100"></div>
                    </div>
                </li>

                <li (click)="getAreaLandmarkByType('exe')">
                    <div class="stat_heading">
                        <div class="stat_left">Out of Boundary</div>
                        <div class="stat_right">{{exceptionPointC}}</div>
                    </div>
                    <div class="total_stat_bar">
                        <div class="stat_process" [style.width.%]="(exceptionPointC / totalAlC) * 100"></div>
                    </div>
                </li>
            </ul>

            <div class="col-sm-12 col-md-12" *ngIf="viewStatsDetail">
                <div class="col-sm-12 col-md-12 routing_main_heading1" style="height: 81px;">
                    <div class="col-sm-6 col-md-6">
                        View GeoCodes</div>
                    <div class="col-sm-3 col-md-3"
                        style="position: relative; left: 180px; bottom: 41px; border-left: 1px solid #e4e4e4; height: 41px !important;">
                        <span (click)="downloadGeoCodes()"
                            style="position: relative; left: 1px; top: 6px; cursor: pointer;"><em
                                class="material-icons-outlined me-2" aria-hidden="true">file_download</em></span>
                    </div>
                    <div class="col-sm-3 col-md-3"
                        style="position: relative; left: 270px; bottom: 82px; border-left: 1px solid #e4e4e4; height: 41px !important;">
                        <span class="fa fa-times-circle" aria-hidden="true"
                            style="position: relative; left: 5px; top: 1px; cursor: pointer;"
                            (click)="viewStatsDetail=false;clearLandMarkList(); clearMarkers()"></span>
                    </div>
                </div>

                <div class="col-sm-12 col-md-12 masterrouteSearchPadding mt-3">
                    <input class="form-control icon s-input b-0" type="text" placeholder="Search" [(ngModel)]="alFilter"
                        autocomplete="off">
                </div>

                <ul class="geocode" style="position: relative; top: 10px;">
                    <li *ngFor="let l of landMarkList | alSearchFilter : alFilter">
                        <div class="geocode_area_H" *ngIf="l?.type == 'H'">{{l?.type}} - {{l?.area?.area}} -
                            <em (click)="onLocationArrow(l)" class="fa fa-location-arrow icon_pointer"
                                aria-hidden="true"></em> - <em (click)="onAddApl(l)" class="fa fa-edit
                            icon_pointer" aria-hidden="true"></em> -
                            <em (click)="deleteLandmark(l.id)" class="fa fa-trash icon_pointer" aria-hidden="true"></em>
                        </div>

                        <div class="geocode_area_C" *ngIf="l?.type == 'C'">{{l?.type}} -
                            {{l?.area?.area}} - <em (click)="onLocationArrow(l)" class="fa fa-location-arrow
                            icon_pointer" aria-hidden="true"></em> - <em (click)="onAddApl(l)"
                                class="fa fa-edit icon_pointer" aria-hidden="true"></em> -
                            <em (click)="deleteLandmark(l.id)" class="fa fa-trash icon_pointer" aria-hidden="true"></em>
                        </div>

                        <div class="geocode_area_S" *ngIf="l?.type == 'S'">{{l?.type}} - {{l?.area?.area}} -
                            <em (click)="onLocationArrow(l)" class="fa fa-location-arrow icon_pointer"
                                aria-hidden="true"></em> - <em (click)="onAddApl(l)" class="fa fa-edit
                            icon_pointer" aria-hidden="true"></em> -
                            <em (click)="deleteLandmark(l.id)" class="fa fa-trash icon_pointer" aria-hidden="true"></em>
                        </div>
                        <div class="geocode_address">{{l?.landmark}}</div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>