import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { ApiService } from 'src/app/core/services/api.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { ModelVehicleType } from 'src/app/shared/models/vehicle/vehicle-type';

@Component({
  selector: 'app-add-vehicle',
  templateUrl: './add-vehicle.component.html',
  styleUrls: ['./add-vehicle.component.scss']
})
export class AddVehicleComponent implements OnInit {

  public _mFormGroup: FormGroup;
  public mRegistrationNumber = new FormControl("", Validators.required);
  public mVehicleType = new FormControl("", Validators.required);

  public _mTitle = "Add Vehicle";
  _mVehicleTypes: ModelVehicleType[] = [];
  

  constructor(
    public dialogRef: MatDialogRef<AddVehicleComponent>,
    private fb: FormBuilder,
    private serviceLocalStorage: ServiceLocalStorage,
    public dialog: MatDialog,
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    private ngxLoader: NgxUiLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this._mFormGroup = this.fb.group({
      registrationNo: this.mRegistrationNumber,
      vehicleType: this.mVehicleType,
    })
  }

  ngOnInit() {
    this.requestServerAllVehicleTypes();
  }
  

  onClickCancel(): void {
    this.dialogRef.close();
  }

  requestServerAllVehicleTypes() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getActiveVehicleTypes + '?flag=' + true,
      success => {
        if (success.status == 200) {
          // this._mVehicleTypes = success.body.dataList.content;
          this._mVehicleTypes = success.body;

          // this._mVehicleTypes = this._mVehicleTypes.filter(
          //   vehicletype => vehicletype.status == AppConstants.ACTIVE_STATUS
          // );
        } else if (success.status == 204) {
          this.notifyService.showError("No Vehicles Found", null);
        }
      },
      error => {
        this.notifyService.showError(error.message, null);
      }
    );
  }

  discard_special_char_And_String(event) {
    var k;
    k = event.charCode; //         k = event.keyCode;  (Both can be used)
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  }

  onSubmit() { 
    
    if (this._mFormGroup.valid) { 
      let data1 = {
        "registrationNo":this.mRegistrationNumber.value,
        "vehicleType": {
          "id": this.mVehicleType.value
        },
        "vendorMaster": {
          "id": this.data.id
        },
        "status": "Active",
        "onCallBooking": true
      }

    this.ngxLoader.start();
      this.apiService.doPostRequestWithResponse(ApiConstants.addVehicle,
        data1,
        success => {
          if (success.status == 201) { 
            this.notifyService.showSuccess("vehicle added successfully!", null);
            this.ngxLoader.stop();
            this.dialogRef.close();
          }

        }, error => { 
          this.ngxLoader.stop();
          this.notifyService.showError(error.message, null);
        });
    }
  }

}
