<div class="container">
    <div class="row" style="padding: 3%;">
        <div class="card">
            <div class="card-body">
                <div class="registerCardHeader">
                    <h3 class="font-size-36 font-weight-bold login-heading">Create an account</h3>
                    <p class="text-muted-light font-size-14">Join us here!</p>
                </div>
                <div class="row mt-3">
                    <div class="col-12 col-md-6 ps-0">
                        <form [formGroup]="_mFormGroup">
                            <div class="row mt-3">
                                <div class="col-12 col-sm-6">
                                    <label>First Name<sup class="mandatory">*</sup></label>
                                    <input type="text" class="form-input" placeholder="Enter First Name"
                                        formControlName="mFirstName"
                                        (keypress)="discard_special_char_And_String($event)" maxlength="50"
                                        autocomplete="off" required>
                                    <div class="error-msg"
                                        *ngIf="_mFormGroup.get('mFirstName').hasError('required') && _mFormGroup.get('mFirstName').touched">
                                        Please enter first name.
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label>Last Name<sup class="mandatory">*</sup></label>
                                    <input type="text" class="form-input" placeholder="Enter Last Name"
                                        formControlName="mLastName" (keypress)="discard_special_char_And_String($event)"
                                        maxlength="15" autocomplete="off">
                                    <div class="error-msg"
                                        *ngIf="_mFormGroup.get('mLastName').hasError('required') && _mFormGroup.get('mLastName').touched">
                                        Please enter last name.
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-12 col-sm-6">
                                    <label>Display Name<sup class="mandatory">*</sup></label>
                                    <input type="text" class="form-input" placeholder="Enter Display Name"
                                        formControlName="mDisplayName"
                                        (keypress)="discard_special_char_And_String($event)" maxlength="50"
                                        autocomplete="off" required>
                                    <div class="error-msg"
                                        *ngIf="_mFormGroup.get('mDisplayName').hasError('required') && _mFormGroup.get('mDisplayName').touched">
                                        Please enter display name.
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label>Employee Id<sup class="mandatory">*</sup></label>
                                    <input type="text" class="form-input" placeholder="Enter Employee Id"
                                        formControlName="mEmployeeId" (keypress)="discard_special_char($event)"
                                        maxlength="12" autocomplete="off" required>
                                    <div class="error-msg"
                                        *ngIf="_mFormGroup.get('mEmployeeId').hasError('required') && _mFormGroup.get('mEmployeeId').touched">
                                        Please enter employee id.
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-12 col-sm-6">
                                    <label>Contact Number<sup class="mandatory">*</sup></label>
                                    <input class="form-input" type="text" maxlength="10"
                                        placeholder="Enter Contact Number" formControlName="mContactNumber"
                                        (keypress)="omit_number($event)" autocomplete="off" required>
                                    <div class="error-msg"
                                        *ngIf="_mFormGroup.get('mContactNumber').hasError('required') && _mFormGroup.get('mContactNumber').touched">
                                        Please enter contact number.
                                        <div *ngIf="_mFormGroup.get('mContactNumber').hasError('pattern')">
                                            Please enter valid contact number.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label>Email ID<sup class="mandatory">*</sup></label>
                                    <input class="form-input" placeholder="Enter EmailId" type="text" maxlength="50"
                                        formControlName="mEmailAddress" required (keyup)="checkEmail($event)"
                                        autocomplete="off">
                                    <div class="error-msg"
                                        *ngIf="_mFormGroup.get('mEmailAddress').hasError('required') && _mFormGroup.get('mEmailAddress').touched">
                                        Please enter email id.
                                        <div *ngIf="emailIsValid">
                                            Valid Email Id Required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-12 col-sm-6">
                                    <label>Gender<sup class="mandatory">*</sup></label>
                                    <select class="form-input" formControlName="mGender">
                                        <option disabled value="">Choose Gender</option>
                                        <option *ngFor="let gender of _mGender" [value]="gender.key">
                                            {{gender.value}}
                                        </option>
                                    </select>
                                    <div class="error-msg"
                                        *ngIf="_mFormGroup.get('mGender').hasError('required') && _mFormGroup.get('mGender').touched">
                                        Please select gender.
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label>Location<sup class="mandatory">*</sup></label>
                                    <select class="form-input" formControlName="mLocation"
                                        (change)="onLocationChange()">
                                        <option disabled value="">Choose Location</option>
                                        <option *ngFor="let location of _mLocationData" [value]="location.locationId">
                                            {{ location.locationName }}
                                        </option>
                                    </select>
                                    <div class="error-msg"
                                        *ngIf="_mFormGroup.get('mLocation').hasError('required') && _mFormGroup.get('mLocation').touched">
                                        Please select location.
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-12 col-sm-6">
                                    <label>Site<sup class="mandatory">*</sup></label>
                                    <select class="form-input" formControlName="mSiteName" (change)="onSiteChange()">
                                        <option disabled value="">Choose Site</option>
                                        <option *ngFor="let site of _mSitesData" [value]="site.siteId">
                                            {{ site.siteName }}
                                        </option>
                                    </select>
                                    <div class="error-msg"
                                        *ngIf="_mFormGroup.get('mSiteName').hasError('required') && _mFormGroup.get('mSiteName').touched">
                                        Please select site.
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label>Area</label>
                                    <select class="form-input" formControlName="mArea" (change)="getLandmarks()">
                                        <option disabled value="">Choose Area</option>
                                        <option *ngFor="let area of _mAreasData" [value]="area.id">
                                            {{ area.area }}
                                        </option>
                                    </select>
                                    <div class="error-msg"
                                        *ngIf="_mFormGroup.get('mArea').hasError('required') && _mFormGroup.get('mArea').touched">
                                        Please select area.
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-12 col-sm-6">
                                    <label>Common Point Landmark</label>
                                    <select class="form-input" formControlName="mLandmark"
                                        (change)="showCommonPointOnMap($event)">
                                        <option disabled value="">Choose Landmark</option>
                                        <option *ngFor="let data of _mLandmarksData" [value]="data">
                                            {{ data.landmark }}
                                        </option>
                                    </select>
                                    <div class="error-msg"
                                        *ngIf="_mFormGroup.get('mLandmark').hasError('required') && _mFormGroup.get('mLandmark').touched">
                                        Please select landmark.
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label>Address</label>
                                    <textarea class="form-input" placeholder="Enter Address" maxlength="200"
                                        formControlName="mAddress" aria-label="Default select example"></textarea>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-12">
                                    <div class="d-flex align-items-center">
                                        <span class="me-2"
                                            style="font-size: 14px; font-weight: 500; font-family: 'Poppins';">Is
                                            Contract employee</span>
                                        <ui-switch defaultBoColor="#dfdfdf" color="#34c38f"
                                            formControlName="mIsContractEmployee"></ui-switch>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-12 col-md-6 pe-0">
                        <div class="row mt-5">
                            <div class="col-12">
                                <div id="user-map"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12 col-md-6 pe-0">
                        <span class="indication">*Area & Common Point will be updated by Admin.</span>
                    </div>
                    <div class="col-12 col-md-6 text-end">
                        <button class="btn create-blue-btn-solid" (click)="onSubmit()"
                            [disabled]="_mFormGroup.invalid">Create Account</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>