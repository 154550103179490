<div>
  <div class="card">
    <div class="card-body">
      <div class="row" fxLayoutAlign="start center">
      <h4 class="mat-color-text--grey-700" fxFlex>{{_mTitle}}</h4>
      <button mat-icon-button color="accent" (click)="onClickCancel()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
    <div class="hr-line"></div>

    <form [formGroup]="_mFormGroup">
      <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
  
            <!-- <label>Registration No.</label>
            <input  class="form-input" placeholder="Registration No." maxlength="15" autocomplete="off"
              (keypress)="discard_special_char_And_String($event)" formControlName="registrationNo" required> -->
            <!-- <mat-error *ngIf="mRegistrationNumber.hasError('required')">
              Please enter registration number
            </mat-error> -->

            <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
              <mat-label>Registration No.</mat-label>
              <input  matInput placeholder="Registration No." maxlength="15" autocomplete="off"
              (keypress)="discard_special_char_And_String($event)" formControlName="registrationNo" required>
              <mat-error *ngIf="mRegistrationNumber.hasError('required')">
                Please enter registration number
              </mat-error>
            </mat-form-field>

        </div>
  
        <div class="col-sm-12 col-md-6">
            <!-- <label>Vehicle Type</label>
            <select class="form-select" placeholder="Vehicle type" formControlName="vehicleType" 
              required >
              <option value=''  hidden disabled>Choose Vehicle Type</option>
              <option *ngFor="let type of _mVehicleTypes" [value]="type.id">
                {{ type.vehicleType }}
              </option>
            </select> -->
            <!-- <mat-error *ngIf="mVehicleType.hasError('required')">
              Please select vehicle type
            </mat-error> -->

            <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
              <mat-label>Vehicle Type</mat-label>
              <mat-select placeholder="Vehicle type" formControlName="vehicleType" required>
                  <mat-option *ngFor="let type of _mVehicleTypes" [value]="type.id">
                      {{ type.vehicleType }}
                  </mat-option>
              </mat-select>
              <mat-error style="white-space: nowrap;" *ngIf="mVehicleType.hasError('required')">
                  Please select vehicle type
              </mat-error>
          </mat-form-field>
          
  
        </div>
      </div>
      
      <div class="row mt-4">
        <div class="col-sm-12 mt-5">
        
          <!-- <button mat-raised-button (click)="onClickCancel()" class="tdw-hover-button-secondary
                        tdw-button-padding-8 tdw-border-2">
            CANCEL
          </button> -->
       
          <button mat-raised-button (click)="onSubmit()" class="col-md-4 btn red-btn-solid"  style="float: right;">
            SUBMIT
          </button>
        </div>
  
      </div>
    </form>
    </div>
  </div>
  </div>