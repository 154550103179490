import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { ApiService } from 'src/app/core/services/api.service';

@Component({
  selector: 'app-show-category',
  templateUrl: './show-category.component.html',
  styleUrls: ['./show-category.component.scss']
})
export class ShowCategoryComponent implements OnInit {
  categoryData: any[] = [];

  constructor(
    private readonly apiService: ApiService,
    public dialogRef: MatDialogRef<ShowCategoryComponent>
  ) { }

  ngOnInit() {
    this.getAllCategory();
  }

  getAllCategory() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getAllCategory, success => {
        if (success.status == 200) {
          this.categoryData = success.body;
        } else {
          this.categoryData = [];
        }
      }, error => {
        console.log(error)
      });
  }

  onClickCancel(): void {
    this.dialogRef.close();
  }

}
