import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-vehicle-type-count',
  templateUrl: './vehicle-type-count.component.html',
  styleUrls: ['./vehicle-type-count.component.scss']
})
export class VehicleTypeCountComponent implements OnInit {
  vechileTypeCount: any;
  vechileNames: any;
  msg: string = 'Vehicle Type Count';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<VehicleTypeCountComponent>) { }

  ngOnInit() {
    this.vechileNames = this.data[0];
    this.vechileTypeCount = this.data[1];
  }

  onClickCancel(): void {
    this.dialogRef.close();
  }

}
