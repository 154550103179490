import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiService } from 'src/app/core/services/api.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ServiceSharedData } from 'src/app/core/services/shared-data.service';
import { ModelTripSheet } from 'src/app/shared/models/trip-sheet';
import { ModelVendorMaster } from 'src/app/shared/models/vendor/vendor-master';
import { Location } from "@angular/common";
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { VehicleTypeCountComponent } from '../vehicle-type-count/vehicle-type-count.component';

@Component({
  selector: 'app-allocate-vendor',
  templateUrl: './allocate-vendor.component.html',
  styleUrls: ['./allocate-vendor.component.scss']
})
export class AllocateVendorComponent implements OnInit {
  _vendorData: ModelVendorMaster[] = [];
  _tripSheet: ModelTripSheet[] = [];
  showVendorDiv: boolean = true;
  showVendorRatioDiv: boolean = false;
  allocateVendorDndShow: boolean = true;
  allocateVendorRatio: boolean = false;
  addRemoveFlag: boolean = false;
  vechileTypeCount: any = [];
  vechileNames: Array<{ name: string }> = [];
  _tripVechileNames: any = [];
  tripids: any = [];
  vTCount = [];
  siteid: number;
  shiftid: number;
  date: any;
  activeTabId = 1;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private readonly apiService: ApiService,
    private serviceSharedData: ServiceSharedData,
    private notifyService: NotificationService,
    private ngxLoader: NgxUiLoaderService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.siteid = params["siteId"];
      this.shiftid = params["shiftId"];
      this.date = params["date"];
    });
    this.tripids = this.serviceSharedData.getData("trips");
    this.getVendors();
  }

  onClickBack() {
    this.location.back();
  }

  getVendors() {
    const tripId = this.route.snapshot.params.tripId;
    this.ngxLoader.start();
    this.apiService.doGetRequestWithResponse(ApiConstants.getAllVendorDropDownBasedOnSite + this.siteid, success => {
      this.ngxLoader.stop();
      if (success.status == 200) {
        this._vendorData = success.body;
        this._vendorData.forEach((vendor, i) => {
          if (i == 0) {
            vendor.flag = true;
          } else {
            vendor.flag = false;
          }
          vendor.tripCount = 0;
          let innerlist: ModelTripSheet[] = [];
          vendor.trips = innerlist;
        });
        this.getTripSheets();
      } else {
        this.notifyService.showInfo("Oops ...Vendor Master Not found", null);
      }
    }, error => {
      this.ngxLoader.stop();
    });
  }

  getTripSheets() {
    this.ngxLoader.start();
    this.apiService.doGetRequestWithResponse(ApiConstants.getunassignedtrips + this.date + '&shiftid=' + this.shiftid + '&siteid=' + this.siteid +
      '&tripids=' + [this.tripids], success => {
        if (success.body != null) {
          this._tripSheet = success.body;
          this._tripVechileNames = JSON.parse(JSON.stringify(success.body));
        }
        this.ngxLoader.stop();
      }, error => {
        this.ngxLoader.stop();
      });
  }

  allocateVendor() {
    this.ngxLoader.start();
    let assignList: any[] = [];
    this._vendorData.forEach(vendor => {
      vendor.trips.forEach(trip => {
        if (trip.tripDetails.tripId != null) {
          assignList.push({
            tripId: trip.tripDetails.tripId,
            vendorId: vendor.id
          });
        }
      });
    });
    if (assignList.length > 0) {
      this.apiService.doPostRequestWithResponse(ApiConstants.allocatevendor, assignList, success => {
        this.ngxLoader.stop();
        if (success.status == 200) {
          this.ngOnInit();
          this.notifyService.showSuccess("Vendor allocated successfully", null);
        }
      }, error => {
        this.ngxLoader.stop();
        this.notifyService.showError(error.message, null);
      }
      );
    } else {
      this.ngxLoader.stop();
      this.notifyService.showWarning("Select atleast one trip", null);
    }
  }

  showVendorRatioDivision() {
    this.showVendorRatioDiv = true;
    this.showVendorDiv = false;
    this.allocateVendorDndShow = false;
    this.allocateVendorRatio = true;
  }

  showVendorDivision() {
    this.showVendorRatioDiv = false;
    this.showVendorDiv = true;
    this.allocateVendorDndShow = true;
    this.allocateVendorRatio = false;
  }

  addVendorAllocation(data) {
    this._tripSheet.forEach((element, index) => {
      if (element == data) this._tripSheet.splice(index, 1);
    });
    this._vendorData.forEach(element => {
      if (element.flag) {
        element.trips.push(data);
        element.tripCount = element.tripCount + 1;
        this.addRemoveFlag = true;
      }
    });
    this.getVechileCount();
  }

  getVechileCount() {
    this.vechileTypeCount = [];
    const vhNamesList = this._tripVechileNames.flatMap(el => el.vehicleType.vehicleType);
    this.vechileNames = vhNamesList.filter((el, pos) => vhNamesList.indexOf(el) === pos);
    this.vechileNames.sort((a: any, b: any) => a - b);
    this._vendorData.forEach((element, index) => {
      const obj: any = {};
      this.vechileNames.forEach((el: any) => {
        obj[el] = 0
      });
      obj.name = element.name;
      this.vechileTypeCount[index] = obj;
      element.trips.forEach(trip => {
        const vhName = trip.vehicleType.vehicleType;
        this.vechileTypeCount[index][vhName] = (this.vechileTypeCount[index][vhName] || 0) + 1;
      });
    });
  }

  removeVendorAllocation(vendor, data) {
    vendor.trips.forEach((element1, index) => {
      if (element1 == data) {
        vendor.trips.splice(index, 1);
        vendor.tripCount = vendor.tripCount - 1;
        this.addRemoveFlag = true;
      }
    });
    this._tripSheet.push(data);
    this.getVechileCount();
  }

  selectVendor(item) {
    this._vendorData.forEach(element => {
      element.flag = false;
    })
    this._vendorData.forEach(element => {
      if (element.name == item.name) {
        element.flag = true;
      }
    })
  }

  moveAll() {
    this._vendorData.forEach(element => {
      if (element.flag) {
        this._tripSheet.forEach((element1) => {
          element.trips.push(element1);
          element.tripCount = element.tripCount + 1;
        });
      }
    })
    this._tripSheet = [];
    this.getVechileCount();
  }

  onVehicleTypeCount() {
    if (this.addRemoveFlag) {
      this.vTCount = [];
      this.vTCount.push(this.vechileNames);
      this.vTCount.push(this.vechileTypeCount);
      this.openDialog(this.vTCount);
    } else {
      this.vTCount.push(this.vechileNames);
      this.vTCount.push(this.vechileTypeCount);
      this.openDialog(this.vTCount);
    }
  }

  openDialog(d): void {
    let dialogRef = this.dialog.open(VehicleTypeCountComponent, {
      width: '600px',
      height: '250px',
      disableClose: true,
      data: d
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
