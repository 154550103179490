<div class="mat-modal-dialog oncall-modal">
    <h2 mat-dialog-title>
        <span class="mtitle-prop">On Call Booking</span>
        <span class="material-icons-outlined"
            style="color:#747679; font-size: 35px; float: right; position: relative; top: 2px; cursor: pointer"
            (click)="onClickCancel()"> close</span>
    </h2>
    <mat-dialog-content>
        <form [formGroup]="_mFormGroup">
            <div class="row" *ngIf="!empFlag">
                <div class="col-sm-12">
                    <div class="row mt-4">
                        <div class="col-sm-12 col-md-4">
                            <mat-form-field class="example-full-width text-field-size filedProp" *ngIf="empRoleFlag"
                                appearance="outline">
                                <mat-label style="text-align: center;">Employee name</mat-label>
                                <input type="text" placeholder="Employee name" matInput formControlName="userName">
                            </mat-form-field>

                            <mat-form-field class="example-full-width text-field-size filedProp" *ngIf="!empRoleFlag"
                                appearance="outline">
                                <mat-label>Search Employee</mat-label>
                                <input style="font-size: 12px;" type="text" placeholder="Search Employee" matInput
                                    formControlName="userName" [matAutocomplete]="auto"
                                    (input)="searchSubject$.next($event.target.value)">
                                <img matSuffix src="assets/images/search.svg" height="27"
                                    style="margin-top: -22px;padding-right: 5px;">
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option *ngFor="let option of user" [value]="option.userInfoDetails.displayName"
                                        (onSelectionChange)="searchSubmit(option)">
                                        {{option.userInfoDetails.employeeId}}-{{option.userInfoDetails.displayName}}</mat-option>
                                </mat-autocomplete>
                                <mat-hint style="white-space: nowrap;">Search key >= 3 characters.</mat-hint>
                                <mat-progress-bar style="top:6px" class="example-margin" mode="indeterminate"
                                    *ngIf="empSearchFlag">
                                </mat-progress-bar>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
                                <mat-label>EmployeeId</mat-label>
                                <input matInput readonly placeholder="Employee id" type="text"
                                    formControlName="employeeId">
                            </mat-form-field>
                        </div>
                        <div *ngIf="allowtochoosebylocation" class="col-sm-12 col-md-4">
                            <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
                                <mat-label>Location</mat-label>
                                <mat-select placeholder="Location" formControlName="location" required>
                                    <mat-option *ngFor="let city of locationList" [value]="city.locationId">
                                        {{ city.locationName }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div *ngIf="!allowtochoosebylocation" class="col-sm-12 col-md-4">
                            <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
                                <mat-label>Site</mat-label>
                                <mat-select placeholder="Site" formControlName="site" required
                                    (selectionChange)="onSiteChange($event)">
                                    <mat-option *ngFor="let site of _mSitesData" [value]="site.siteId">
                                        {{ site.siteName }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="mSites.hasError('required')">
                                    Please select site
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-sm-12 col-md-4 col-lg-4  col-xl-4">
                            <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
                                <mat-label>Purpose</mat-label>
                                <mat-select placeholder="Purpose" formControlName="bookingType" required>
                                    <mat-option *ngFor="let booking of _mBookingTypes" [value]="booking.bookingType">
                                        {{ booking.bookingType}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="mBookingType.hasError('required')">
                                    Please select Purpose
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
                                <mat-label>Vendor Master</mat-label>
                                <mat-select placeholder="Vendor Master" formControlName="vendor"
                                    (selectionChange)="onVendorChange($event)" required>
                                    <mat-option *ngFor="let vendor of _mVendors"
                                        [value]="vendor.id">{{vendor.name}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="mVendor.hasError('required')">
                                    Please select vendor master
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <div class="d-flex">
                                <ui-switch defaultBoColor="#dfdfdf" color="#34c38f" formControlName="escortRequired"
                                    (click)="onEscortToggle()"></ui-switch>
                                <span
                                    style="position: relative; right: -19px; top: 4px; font-size: 14px; font-weight: 500; font-family: 'Poppins';">Escort
                                    required</span>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
                                <mat-label>Escort</mat-label>
                                <mat-select placeholder="Escort" formControlName="escort" required>
                                    <mat-option *ngFor="let escort of _mEscort" [value]="escort.id">
                                        {{ escort.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="mEscort.hasError('required')">
                                    Please select escort
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
                                <mat-label>Travel Date</mat-label>
                                <input matInput [min]="toDatePickerMin" (click)="picker1.open()"
                                    (dateChange)="dateChanged()" [matDatepicker]="picker1" placeholder="Travel date"
                                    required formControlName="travelDate" readonly="readonly">
                                <mat-datepicker-toggle matSuffix [for]="picker1">
                                    <img matDatepickerToggleIcon src="assets/images/bx-calendar.svg">
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <mat-form-field class="example-full-width text-field-size filedProp" *ngIf="!editFag"
                                appearance="outline">
                                <mat-label>Reporting Time</mat-label>
                                <mat-select placeholder="Reporting time" formControlName="reportingTime" required>
                                    <mat-option *ngFor="let time of reportingTimes" [value]="time.value">
                                        {{ time.value}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="mReportingTime.hasError('required')">
                                    Please select reporting time
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="example-full-width text-field-size filedProp" *ngIf="editFag"
                                appearance="outline">
                                <mat-label>Reporting Time</mat-label>
                                <mat-select placeholder="Reporting time" formControlName="reportingTime" required>
                                    <mat-option *ngFor="let time of _mEditReportingTimes" [value]="time.value">
                                        {{ time.value}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="mReportingTime.hasError('required')">
                                    Please select reporting time
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
                                <mat-label>Project</mat-label>
                                <mat-select placeholder="Process" formControlName="project" required>
                                    <mat-option *ngFor="let project of _mProjects" [value]="project.projectId">
                                        {{ project.projectName }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="mProject.hasError('required')">
                                    Please select process
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-6" *ngIf="!allowAdminDriverAloc">
                            <div class="d-flex">
                                <ui-switch defaultBoColor="#dfdfdf" color="#34c38f" formControlName="activeVehicleType"
                                    (click)="onVehicleTypeToggle()"></ui-switch>

                                <span
                                    style="position: relative; right: -19px; top: 4px; font-size: 14px; font-weight: 500; font-family: 'Poppins';">On
                                    Call Vehicle Type
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <!-- <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
                                <mat-label>Project</mat-label>
                                <mat-select placeholder="Process" formControlName="project" required>
                                    <mat-option *ngFor="let project of _mProjects" [value]="project.projectId">
                                        {{ project.projectName }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="mProject.hasError('required')">
                                    Please select process
                                </mat-error>
                            </mat-form-field>
                        </div> -->
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
                                <mat-label>Vehicle Type</mat-label>
                                <mat-select placeholder="Vehicle type" formControlName="vehicleType"
                                    (selectionChange)="onVehicleTypeChange($event)" required>
                                    <mat-option *ngFor="let type of _mVehicleTypes" [value]="type.id">
                                        {{ type.vehicleType }}
                                    </mat-option>
                                </mat-select>
                                <mat-error style="white-space: nowrap;" *ngIf="mVehicleType.hasError('required')">
                                    Please select vehicle type
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
                                <mat-label>No Of Employees</mat-label>
                                <input matInput placeholder="No Of Employees" type="text"
                                    (keypress)="omit_special_char_And_String($event)" maxlength="2" autocomplete="off"
                                    min="1" formControlName="noOfEmp">
                                <!-- <mat-error *ngIf="mNoOfEmp.hasError('required')">
                              Please Enter No Of Employees
                            </mat-error> -->
                            </mat-form-field>
                        </div>

                    </div>

                    <div class="row mt-4">

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
                                <mat-label>Vehicle</mat-label>
                                <input matInput style="font-size: 12px;" type="text" placeholder="Vehicle"
                                    autocomplete="off" [matAutocomplete]="processauto" formControlName="vehicle">
                                <img matSuffix src="assets/images/search.svg" height="27"
                                    style="margin-top: -22px;padding-right: 5px;">
                                <mat-autocomplete #processauto="matAutocomplete">
                                    <mat-option *ngFor="let item of mTempVehicleList" [value]="item.registrationNo"
                                        (onSelectionChange)="updateValue(item, 'vehicle')"> {{ item.registrationNo }}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>

                        </div>


                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
                                <mat-label>Driver</mat-label>
                                <mat-select placeholder="Driver" formControlName="driver">
                                    <mat-option *ngFor="let item of mDriverList" [value]="item.id">
                                        {{ item.name }}
                                    </mat-option>
                                </mat-select>
                                <!-- <mat-error *ngIf="mVehicleType.hasError('required')">
                              Please select vehicle type
                            </mat-error> -->
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row mt-4" *ngIf="!allowAdminDriverAloc">

                        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4" style="margin-bottom: 10px;">
                            <button class="btn red-btn-solid" matTooltip="Add Vehicle" (click)="addVehicle()"> Add
                                Vehicle </button>
                        </div>


                        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4" style="margin-bottom: 10px;">
                            <button class="btn red-btn-solid" matTooltip="Add  Drive" (click)="addDriver()"> Add Driver
                            </button>
                        </div>


                        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4" style="margin-bottom: 10px;">
                            <button class="btn red-btn-solid" matTooltip="Map Vehicle/Driver"
                                (click)="mapDriverVehicle()">Map
                                Vehicle/Driver</button>
                        </div>

                    </div>


                    <div class="row mt-4">
                        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
                                <mat-label>From</mat-label>
                                <mat-select placeholder="From Location*" formControlName="fromLoc"
                                    (selectionChange)="onFromLocationTypechange($event)" required>
                                    <mat-option *ngFor="let loc of locationTypes" [value]="loc">
                                        {{loc}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="mfromLoc.hasError('required')">
                                    Please select Location
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
                                <mat-label>From Landmark</mat-label>
                                <input type="text" placeholder="Landmark" matInput autocomplete="off"
                                    [matAutocomplete]="landmarkauto" formControlName="fromLandmark" required>
                                <img matSuffix src="assets/images/search.svg" height="27"
                                    style="margin-top: -22px;padding-right: 5px;">

                                <mat-autocomplete #landmarkauto="matAutocomplete">
                                    <mat-option *ngFor="let landmark of _mLandmarksData" [value]="landmark.landmark"
                                        (onSelectionChange)="fromLandmarkSearchSubmit(landmark)">{{landmark.landmark
                                        }}</mat-option>
                                </mat-autocomplete>
                                <!-- <mat-progress-bar style="top:6px" class="example-margin"
                          mode="indeterminate" *ngIf="landmarksLoading">
                        </mat-progress-bar> -->
                                <mat-error *ngIf="mFromLandmark.hasError('required')">
                                    Please select landmark
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <div class="d-flex" style="position: absolute; bottom: 21px;">
                                <button *ngIf="fromOther" class="map-oncall-button-enable"
                                    matTooltip="Choose From location from map" (click)="fromLoc()">Map</button>
                                <button *ngIf="!fromOther" class="map-oncall-button-disable"
                                    matTooltip="Choose From location from map" disabled="true">Map</button>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
                                <mat-label>To</mat-label>
                                <mat-select placeholder="To Location*" formControlName="toLoc"
                                    (selectionChange)="onToLocationTypechange($event)" required>
                                    <mat-option *ngFor="let loc of locationTypes" [value]="loc">
                                        {{loc}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="mToLoc.hasError('required')">
                                    Please select Location
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
                                <mat-label>To Landmark</mat-label>
                                <input type="text" placeholder="Landmark" matInput autocomplete="off"
                                    [matAutocomplete]="landmarkautoa" formControlName="toLandmark" required>
                                <img matSuffix src="assets/images/search.svg" height="27"
                                    style="margin-top: -22px;padding-right: 5px;">

                                <mat-autocomplete #landmarkautoa="matAutocomplete">
                                    <mat-option *ngFor="let landmark of _mLandmarksData" [value]="landmark.landmark"
                                        (onSelectionChange)="toLandmarkSearchSubmit(landmark)">{{landmark.landmark
                                        }}</mat-option>
                                </mat-autocomplete>
                                <!-- <mat-progress-bar style="top:6px" class="example-margin"
                          mode="indeterminate" *ngIf="landmarksLoading">
                        </mat-progress-bar> -->
                                <mat-error *ngIf="mToLandmark.hasError('required')">
                                    Please select landmark
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <div class="d-flex" style="position: absolute; bottom: 21px;">
                                <button *ngIf="toOther" class="map-oncall-button-enable"
                                    matTooltip="Choose From location from map" (click)="toLoc()">Map</button>
                                <button *ngIf="!toOther" class="map-oncall-button-disable"
                                    matTooltip="Choose From location from map" disabled="true">Map</button>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <mat-form-field class="example-full-width text-field-size-for-reason filedProp"
                                appearance="outline">
                                <mat-label>Additional Information</mat-label>
                                <textarea matInput placeholder="Additional Information" type="text"
                                    formControlName="reason" class="max-height"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="empFlag">
                <div class="col-sm-12">
                    <div class="row mt-4">
                        <div class="col-sm-12 col-md-4">
                            <mat-form-field class="example-full-width text-field-size filedProp" *ngIf="empRoleFlag"
                                appearance="outline">
                                <mat-label>Employee name</mat-label>
                                <input type="text" placeholder="Employee name" matInput formControlName="userName">
                            </mat-form-field>


                        </div>
                        <div class="col-sm-12 col-md-4">
                            <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
                                <mat-label>EmployeeId</mat-label>
                                <input matInput readonly placeholder="Employee id" type="text"
                                    formControlName="employeeId">
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
                                <mat-label>Site</mat-label>
                                <mat-select placeholder="Sites" formControlName="site" required
                                    (selectionChange)="onSiteChange($event)">
                                    <mat-option *ngFor="let site of _mSitesData" [value]="site.siteId">
                                        {{ site.siteName }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="mSites.hasError('required')">
                                    Please select site
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-sm-12 col-md-4 col-lg-4  col-xl-4">
                            <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
                                <mat-label>Purpose</mat-label>
                                <mat-select placeholder="Purpose" formControlName="bookingType" required>
                                    <mat-option *ngFor="let booking of _mBookingTypes" [value]="booking.bookingType">
                                        {{ booking.bookingType}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="mBookingType.hasError('required')">
                                    Please select Purpose
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
                                <mat-label>Travel Date</mat-label>
                                <input matInput [min]="toDatePickerMin" (click)="picker1.open()"
                                    (dateChange)="dateChanged()" [matDatepicker]="picker1" placeholder="Travel date"
                                    required formControlName="travelDate" readonly="readonly">
                                <mat-datepicker-toggle matSuffix [for]="picker1">
                                    <img matDatepickerToggleIcon src="assets/images/bx-calendar.svg">
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <mat-form-field class="example-full-width text-field-size filedProp" *ngIf="!editFag"
                                appearance="outline">
                                <mat-label>Reporting Time</mat-label>
                                <mat-select placeholder="Reporting time" formControlName="reportingTime" required>
                                    <mat-option *ngFor="let time of reportingTimes" [value]="time.value">
                                        {{ time.value}}
                                    </mat-option>
                                </mat-select>
                                <mat-error style="white-space: nowrap;" *ngIf="mReportingTime.hasError('required')">
                                    Please select reporting time
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
                                <mat-label>From</mat-label>
                                <mat-select placeholder="From Location*" formControlName="fromLoc"
                                    (selectionChange)="onFromLocationTypechange($event)" required>
                                    <mat-option *ngFor="let loc of locationTypes" [value]="loc">
                                        {{loc}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="mfromLoc.hasError('required')">
                                    Please select Location
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
                                <mat-label>From Landmark</mat-label>
                                <input type="text" placeholder="Landmark" matInput autocomplete="off"
                                    [matAutocomplete]="landmarkauto" formControlName="fromLandmark" required>
                                <img matSuffix src="assets/images/search.svg" height="27"
                                    style="margin-top: -22px;padding-right: 5px;">

                                <mat-autocomplete #landmarkauto="matAutocomplete">
                                    <mat-option *ngFor="let landmark of _mLandmarksData" [value]="landmark.landmark"
                                        (onSelectionChange)="fromLandmarkSearchSubmit(landmark)">{{landmark.landmark
                                        }}</mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="mFromLandmark.hasError('required')">
                                    Please select landmark
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <div class="d-flex" style="position: absolute; bottom: 21px;">
                                <button *ngIf="fromOther" class="map-oncall-button-enable"
                                    matTooltip="Choose From location from map" (click)="fromLoc()">Map</button>
                                <button *ngIf="!fromOther" class="map-oncall-button-disable"
                                    matTooltip="Choose From location from map" disabled="true">Map</button>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
                                <mat-label>To</mat-label>
                                <mat-select placeholder="To Location*" formControlName="toLoc"
                                    (selectionChange)="onToLocationTypechange($event)" required>
                                    <mat-option *ngFor="let loc of locationTypes" [value]="loc">
                                        {{loc}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="mToLoc.hasError('required')">
                                    Please select Location
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <mat-form-field class="example-full-width text-field-size filedProp" appearance="outline">
                                <mat-label>To Landmark</mat-label>
                                <input type="text" placeholder="Landmark" matInput autocomplete="off"
                                    [matAutocomplete]="landmarkautoa" formControlName="toLandmark" required>
                                <img matSuffix src="assets/images/search.svg" height="27"
                                    style="margin-top: -22px;padding-right: 5px;">

                                <mat-autocomplete #landmarkautoa="matAutocomplete">
                                    <mat-option *ngFor="let landmark of _mLandmarksData" [value]="landmark.landmark"
                                        (onSelectionChange)="toLandmarkSearchSubmit(landmark)">{{landmark.landmark
                                        }}</mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="mToLandmark.hasError('required')">
                                    Please select landmark
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <div class="d-flex" style="position: absolute; bottom: 21px;">
                                <button *ngIf="toOther" class="map-oncall-button-enable"
                                    matTooltip="Choose From location from map" (click)="toLoc()">Map</button>
                                <button *ngIf="!toOther" class="map-oncall-button-disable"
                                    matTooltip="Choose From location from map" disabled="true">Map</button>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <mat-form-field class="example-full-width text-field-size-for-reason filedProp"
                                appearance="outline">
                                <mat-label>Additional Information</mat-label>
                                <textarea matInput placeholder="Additional Information" type="text"
                                    formControlName="reason" class="max-height"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row d-flex" style="float: right;">
                <div class="col-sm-12">
                    <button (click)="onClickCancel()" class="btn footerBtn secondary-btn-solid me-3">
                        Cancel
                    </button>
                    <button class="btn footerBtn red-btn-solid" (click)="allocateVehicleDriver()">
                        Submit
                    </button>
                </div>
            </div>
        </form>
    </mat-dialog-content>
</div>