<div class="mat-modal-dialog routing-error-modal" *ngIf="dataList?.length > 0">
    <div *ngIf="this._type">
        <div class="er_icon"><i class="fa fa-check-circle"></i></div>
        <div class="oh_snap">Trip Successfully Generated</div>
    </div>
    <div *ngIf="!this._type">
        <div class="er_icon1"><i class="fa fa-exclamation-circle" aria-hidden="true"></i></div>
        <div class="oh_snap1">Oh Snap</div>
    </div>
    <div class="clearfix"></div>
    <div class="d-flex">
        <div class="col-md-4 no-padding">
            <div class="label_of_trip">Trip Date </div>
        </div>
        <div class="col-md-4 no-padding">
            <div class="label_of_trip">Site Name</div>
        </div>
        <div class="col-md-4 no-padding">
            <div class="label_of_trip">Shift Time</div>
        </div>
    </div>
    <div class="max-height">
        <div class="er_list" *ngFor="let error of dataList">
            <div class="row">
                <div class="col-md-4 no-padding">
                    <div class="trip_deta">{{error.TRIPDATE}}</div>
                </div>
                <div class="col-md-4 no-padding">
                    <div class="trip_deta">{{error.SITENAME}}</div>
                </div>
                <div class="col-md-4 no-padding">
                    <div class="trip_deta">{{error.SHIFTLOG}}</div>
                </div>
            </div>
            <div class="tdw-padding-v-4"></div>
            <div class="er_msg" *ngIf="error?.errorList?.length > 0">
                <ng-container *ngFor="let errorMsg of error.errorList">
                    <div *ngIf="errorMsg"><i class="fa fa-circle-o" aria-hidden="true"></i> {{errorMsg}}</div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="d-flex" style="justify-content: center; margin-top: 45px;">
        <button class="btn customBtn" (click)="close()">Dismiss</button>
    </div>
</div>