import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-routing-error',
  templateUrl: './routing-error.component.html',
  styleUrls: ['./routing-error.component.scss']
})
export class RoutingErrorComponent implements OnInit {
  _tdata: any;
  dialogData: any[] = [];
  dataList: any[] = [];
  _type: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<RoutingErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public d: any[],
  ) {
    if (d) {
      this._tdata = d;
    }
  }

  ngOnInit() {
    this.dialogData = this._tdata.data;
    this._type = this._tdata.type;
    this.setData();
  }

  setData() {
    this.dataList = [];
    this.dialogData.forEach(element => {
      let errorList: any = [];
      errorList.push(element.ERROR1);
      errorList.push(element.ERROR2);
      errorList.push(element.ERROR3);
      this.dataList.push({
        ERROR: element.ERROR,
        TRIPDATE: element.TRIPDATE,
        SITENAME: element.SITENAME,
        SHIFTLOG: element.SHIFTLOG,
        errorList: errorList
      });
    });
  }

  close() {
    this.dialogRef.close();
  }

}
